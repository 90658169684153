import '../../shared/css/typography.css';

export default function WaitingRoomDisclaimer({ isCandidate, recruiter }) {
  return (
    <div>
      <p className="policy_typography">
        If the {isCandidate ? 'interviewer' : 'candidate'} has not joined within
        <b> 10 minutes</b>, you may press the button below to send a reminder
        {isCandidate ? ` to your point of contact, ` : '.'}
        {isCandidate ? <b>{recruiter}</b> : null}
      </p>
    </div>
  );
}
