import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import NextButton from '../../shared/button/next';
import BackButton from '../../shared/button/back';
import ResetButton from '../../shared/button/resetButton';
import 'bootstrap/dist/css/bootstrap.css';
import '../../bountyCreateSteps/css/createBounty.css';
import '../../shared/css/typography.css';

import { useInvoicedGigForm } from '../../context/invoicedGigContext';

export const StepThreeBounty = ({ nextStep, prevStep, resetStep }) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    setFieldTouched,
    resetGigForm,
  } = useInvoicedGigForm();

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (currentErrors.importantLink) {
      setFieldTouched('importantLink');
      return;
    }
    nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  useEffect(() => {
    if (values._id) {
      setFieldTouched('importantLink');
    }
  }, []);

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };

  const Options = ['Full-time', 'Part-time', 'Contract', 'Temporary', 'Other'];

  const workPlace = ['On-site', 'Hybrid', 'Remote'];

  return (
    <Container>
      <Row>
        <p className="grey-bounty-setup-header">
          Add specific links and select type of gig
        </p>
      </Row>
      <Form>
        <Form.Group className="mb-4" controlId="steptwoGroup1">
          <Form.Label className="form_label bounty-type-label ps-0">
            Reference Links <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="importantLink"
            as="textarea"
            rows={3}
            placeholder="Add any links needed as a reference to work on your gig."
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.importantLink}
            isInvalid={!!errors.importantLink}
          />
          {touched.importantLink && errors.importantLink && (
            <Form.Control.Feedback type="invalid">
              {errors.importantLink}
            </Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">
            <i>Example:</i> https://github.com/snapbrillia or
            https://figma.com/snapbrillia or https://snapbrillia.com/careers
          </Form.Text>
        </Form.Group>
        {values.type === 'Job' && (
          <>
            <Form.Group className="mb-4" controlId="steptwoGroup1">
              <Form.Label className="form_label bounty-type-label ps-0">
                Location <span className="required">*</span>
              </Form.Label>
              <Form.Control
                className="gig-form-field"
                name="location"
                as="input"
                rows={3}
                placeholder="Add any locations need for the job application"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.location}
                isInvalid={!!errors.location}
              />
              {touched.location && errors.location && (
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted">
                <i></i> Add work location if needed
              </Form.Text>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-4" controlId="steptwoGroup1">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    Workplace Type <span className="required">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="workplaceType"
                    value={values.workplaceType}
                    onChange={handleChange}
                  >
                    {workPlace.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </Form.Select>

                  {touched.workPlaceType && errors.workplaceType && (
                    <Form.Control.Feedback type="invalid">
                      {errors.workplaceType}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-4" controlId="steptwoGroup1">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    Job Type <span className="required">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="jobType"
                    onChange={handleChange}
                    value={values.jobType}
                  >
                    {Options.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </Form.Select>

                  {touched.jobType && errors.jobType && (
                    <Form.Control.Feedback type="invalid">
                      {errors.jobType}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
        {/* <Form.Group className="mb-1" controlId="steptwoGroup4">
          <Form.Label className="form_label bounty-type-label ps-0">
            Make the gig private{' '}
          </Form.Label>

          <Form.Control as="checkbox" className="remove-radio-border">
            <Form.Check
              inline
              label="Select to make the gig private"
              name="isPrivate"
              type="checkbox"
              onChange={() => {
                handleChange({
                  target: {
                    name: 'isPrivate',
                    value: !values.isPrivate,
                  },
                });
              }}
              checked={values.isPrivate}
            />
          </Form.Control>
        </Form.Group> */}
        <Form.Group className="mb-4" controlId="steptwoGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Team Players or Workers{''}
            <span className="required">*</span>
          </Form.Label>

          <Form.Control as="div" className="remove-radio-border">
            <Form.Check
              inline
              defaultChecked={values.teamPreference === false}
              label="Worker[s]"
              name="bountyWorkPreference"
              type="radio"
              id="All"
              onChange={() => {
                handleChange({
                  target: { name: 'teamPreference', value: false },
                });
              }}
            />
            <Form.Check
              inline
              defaultChecked={values.teamPreference === true}
              label="Team Player"
              name="bountyWorkPreference"
              type="radio"
              id="TeamsOnly"
              onChange={() => {
                handleChange({
                  target: { name: 'teamPreference', value: true },
                });
              }}
            />
          </Form.Control>
          <Form.Text className="text-muted">
            Do you want teams only or allow both team player and worker to apply
            to your gig?
          </Form.Text>
        </Form.Group>

        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <BackButton onClick={back} />
              <NextButton
                onClick={next}
                type="button"
                disabled={errors.importantLink}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepThreeBounty;
