import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AllLineCharts from '../../charts/allLineCharts';
import TooltipIcon from '../../assets/icon/tooltip_icon.svg';
import '../../shared/css/typography.css';

const DisparityRate = ({
  bountyId,
  WSPId,
  dataType = 'veteran',
  status = 'completed',
  height = 60,
  width = 150,
  showAxis = false,
  disparity = 'NaN',
}) => {
  return (
    <Container
      style={{
        color: '#2d2b6f',
        fontFamily: 'Roboto, sarif',
        width: '100%',
      }}
    >
      <Row>
        <Col md={10}>
          <h2
            style={{
              fontSize: '18px',
              fontFamily: 'Roboto',
              color: '#2D2B6F',
            }}
          >
            Disparity Rating
          </h2>
        </Col>
        <Col md={2}>
          <div style={{ float: 'right' }}>
            <img src={TooltipIcon} alt="tooltip-icon" width={14} height={14} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <p
            style={{
              fontSize: '36px',
              fontWeight: 'bold',
              fontFamily: 'Roboto, sarif',
            }}
          >
            {disparity}
          </p>
        </Col>
        <Col md={7}>
          <AllLineCharts
            bountyId={bountyId}
            WSPId={WSPId}
            dataType={dataType}
            status={status}
            height={height}
            width={width}
            showAxis={showAxis}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DisparityRate;
