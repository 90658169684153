import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import CloseIcon from '../../assets/icon/snapbrillia_close_icon.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/selectAnalitycsCategory.css';

const SelectAnalitycsCategory = ({
  setOpen,
  setColumns,
  columns,
  setPieChartData,
}) => {
  const [chartData, setChartData] = useState({
    category: '',
    // status: '',
    chart: '',
  });

  const categories = [
    // 'All',
    'Gender',
    'Age',
    'Neurodiversity',
    'Impairment Status',
    'Veteran Status',
    'Ethnicity',
    'Veteran Status',
    'Education',
  ];
  const categoryValues = [
    // 'all',
    'gender',
    'age',
    'neurodivergence',
    'impairmentStatus',
    'veteranStatus',
    'ethnicity',
    'veteran',
    'degree',
  ];

  const chart = ['Pie', 'Bar', 'Donut'];

  const handleCategory = (value) => {
    let indexOfCategory = categories.indexOf(value);
    let category = categoryValues[indexOfCategory];
    setChartData({
      ...chartData,
      category: category,
    });
    setPieChartData({
      ...chartData,
      category: category,
    });
  };

  const handleChart = (value) => {
    setChartData({
      ...chartData,
      chart: value.toLowerCase(),
    });
    setPieChartData({
      ...chartData,
      chart: value.toLowerCase(),
    });

    let newCols = [];
    for (let i = 0; i < columns.length; i++) {
      newCols.push(
        columns[i].map((column) => {
          if (column.showSelectComponent === true) {
            column.showSelectComponent = false;
            column.fixed = true;
          }
          return column;
        })
      );
    }
    setColumns(newCols);
  };
  return (
    <Container>
      <Row className="justify-content-center align-items-center mb-3">
        <Col md={6} lg={5} xxl={6}>
          <span className="grid-cel-title">
            {!chartData.category
              ? 'Select a Category'
              : // : !chartData.status
                // ? 'Select a Status'
                'Select a Chart'}
          </span>
        </Col>
        <Col className="text-end">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="analictycs-select-icon"
            onClick={() => setOpen(false)}
          />
        </Col>
      </Row>
      <Row className="scrollable-select-menu">
        <Col xs={12} sm={6} className="select-category-list align-items-center">
          {!chartData.category
            ? categories.slice(0, 4).map((category, index) => (
                <li onClick={() => handleCategory(category)} key={index}>
                  {category}
                </li>
              ))
            : chart.slice(0, 4).map((charts, index) => (
                <li onClick={() => handleChart(charts)} key={index}>
                  {charts}
                </li>
              ))}
        </Col>
        <Col xs={12} sm={6} className="select-category-list">
          {!chartData.category
            ? categories.slice(4).map((category, index) => (
                <li onClick={() => handleCategory(category)} key={index}>
                  {category}
                </li>
              ))
            : chart.slice(0, 4).map((charts, index) => (
                <li onClick={() => handleChart(charts)} key={index}>
                  {charts}
                </li>
              ))}
        </Col>
      </Row>
    </Container>
  );
};

export default SelectAnalitycsCategory;
