/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import { useParams, Link } from '@reach/router';
import { Box, TableRow } from '@mui/material';
import { Button } from 'react-bootstrap';
import * as userAPI from '../../api/users';
import Back from '../../shared/button/back';
import AddBountiesFormButton from '../../shared/button/addBountiesForm';
import AddMemberModal from './addMemberModal';

const AllUsersTable = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const companyId = useParams()?.companyId;
  const getUsersData = async (search) => {
    setLoading(true);
    await userAPI
      .getAllUsers({ companyId, search })
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        setUsers([]);
      });

    setLoading(false);
  };

  const activate = async (id) => {
    const toastId = toast.loading('Activating...');
    userAPI.activate(id);
    getUsersData();
    toast.dismiss(toastId);
    toast('Activate User Successful');
  };

  const deactivate = async (id) => {
    const toastId = toast.loading('Deactivating...');
    userAPI.deactivate(id);
    getUsersData();
    toast.dismiss(toastId);
    toast('Deactivate User Successful');
  };

  useEffect(() => {
    getUsersData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.fullName || row.firstName + ' ' + row.lastName, //access nested data with dot notation
        header: 'Full Name',
        size: 150,
      },
      {
        accessorFn: (row) => row.email,
        header: 'Email',
        size: 50,
      },
      {
        accessorFn: (row) =>
          row?.companyRole?.charAt(0).toUpperCase() +
          row?.companyRole?.slice(1), //normal accessorKey
        header: 'Role',
        size: 100,
      },
      {
        accessorFn: (row) => row.companyId?.companyName,
        header: 'Company Name',
        size: 50,
      },
      {
        accessorFn: (row) =>
          row.companyId?.companyUserIds.length + ' Employees',
        header: 'Employees',
        size: 100,
      },
      {
        accessorFn: (row) => row.title,
        header: 'Title',
        size: 100,
      },
      {
        accessorFn: (row) => row.companyId?.opportunityIds?.length + ' Created',
        header: 'Opportunity Created',
        size: 100,
      },
      {
        accessorFn: (row) =>
          new Date(row?.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 150,
      },
    ],
    []
  );

  return (
    <>
      {companyId && (
        <>
          {showAddMember && (
            <AddMemberModal
              openAddMember={showAddMember}
              setOpenAddMember={setShowAddMember}
              companyId={companyId}
            />
          )}
          <Back onClick={() => window.history.go(-1)} />
          <div className="float-end">
            <AddBountiesFormButton
              text={'Add User'}
              handleClick={() => setShowAddMember(true)}
            />
          </div>
        </>
      )}
      <MaterialReactTable
        columns={columns}
        data={users}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 200, //set custom width
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
          },
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            {row.original.status !== 'frozen' ? (
              <Button
                onClick={async () => {
                  deactivate(row.original._id);
                }}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  activate(row.original._id);
                }}
              >
                Activate
              </Button>
            )}
          </Box>
        )}
      />
    </>
  );
};

export default AllUsersTable;
