import '../css/buttonStyles.css';
import { Spinner } from 'react-bootstrap';

export default function Savebutton({
  handleClick,
  isDisabled,
  isLoading = false,
  type,
  className,
}) {
  let newClass = className;
  if (!className) {
    newClass = `${isDisabled ? 'btn-disabled' : 'btn-primary'}`;
  }
  return (
    <button
      className={newClass}
      onClick={handleClick}
      disabled={isDisabled}
      type={type}
    >
      {isLoading ? (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        `Save`
      )}
    </button>
  );
}
