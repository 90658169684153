import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IntegrationSideMenu from '../../shared/menus/integrationSideMenu';
import CopyRight from '../../shared/copyright/copyright';
import Logo from '../../assets/SnapBetaLogo2.svg';
import Close from '../../assets/icon/snapbrillia_close_icon.svg';
import { Link } from '@reach/router';
import IntegrationsCard from './integrationsCard';
import '../../shared/css/bgColors.css';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';

export default function ATSIntegrations() {
  return (
    <Container className="primarybg" fluid>
      <div>
        <Row style={{ position: 'absolute', height: '100%', width: '100%' }}>
          <Col md={3}>
            <Row>
              <Col>
                <div className="close_modal_button">
                  <Link to="/dashboard">
                    <img src={Close} alt="close-icon" />
                  </Link>
                </div>
                <img src={Logo} alt="logo" />
              </Col>
            </Row>
            <Row>
              <Col>
                <IntegrationSideMenu />
              </Col>
            </Row>
            <Row>
              <Col>
                <CopyRight />
              </Col>
            </Row>
            {/* </div> */}
          </Col>
          <Col md={9} data-testid="main" className="pagemainbg componentborder">
            <Container>
              <Row>
                <Col>
                  <h1 className="h1 px-2 py-4 mb-4">App Integrations</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <IntegrationsCard />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
