import React, { useState, useContext, useMemo, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useTable, useRowState, useSortBy } from 'react-table';
import '../shared/css/typography.css';
import './css/teamMemberModal.css';
import '../shared/css/buttonStyles.css';
import CloseIcon from '../assets/icon/snapbrillia_close_icon.svg';

import { MemberModal } from './components/memberModalAPI';

//props: openListTeamMember
const ListTeamMemberModal = ({ setOpenListTeamMember }) => {
  const { members, handleRemoveMember } = useContext(MemberModal);
  // const [showModal, setShowModal] = useState(true);
  const [dataState, setDataState] = useState([]);

  const handleClose = () => setOpenListTeamMember(false);
  useEffect(() => {
    if (members.length === 0) {
      return;
    }
    const tableData = members.map((e, i) => ({
      col0: i + 1,
      col1: <div>{e.fullName}</div>,
      col2: e.companyRole || 'Member',
      col3: e.email,
      col4: (
        <div
          className="edit_label pointer"
          onClick={() => handleRemoveMember(e)}
        >
          Remove
        </div>
      ),
    }));
    setDataState(tableData);
    return () => {};
  }, [members]);
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col0',
      },
      {
        Header: 'Name',
        accessor: 'col1',
      },
      {
        Header: 'Role',
        accessor: 'col2',
      },
      {
        Header: 'Email',
        accessor: 'col3',
      },
      {
        Header: ' ',
        accessor: 'col4',
      },
    ],
    []
  );
  const data = useMemo(() => [...dataState], [dataState]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      useRowState
    );
  return (
    <Modal
      contentClassName="modal-main-container"
      onHide={handleClose}
      show={true}
      centered
      size="lg"
    >
      <Container className="p-2">
        <Row>
          <Row className="p-0">
            <Col>
              <Modal.Header className="member-modal-header text-center">
                <h2 className="h2 w-100 text-center">List Team Members</h2>
              </Modal.Header>
            </Col>
            <Col
              xs={1}
              className="pe-0 d-flex align-items-center justify-content-center"
            >
              <img
                src={CloseIcon}
                alt="close-icon"
                width={20}
                onClick={() => handleClose()}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="p-0">
              <table className="company_table" {...getTableProps()}>
                <thead className="d-flex company_tbody m-0 w-100">
                  {headerGroups.map((headerGroup, index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={index}
                      className="m-0 w-100"
                    >
                      {headerGroup.headers.map((column, index) =>
                        index === 0 ? (
                          <th
                            key={index}
                            className="text-left"
                            {...column.getHeaderProps()}
                            style={{
                              borderBottom: 'solid 1px black',
                              fontWeight: '500',
                              verticalAlign: 'bottom',
                            }}
                          >
                            {column.render('Header')}
                          </th>
                        ) : (
                          <th
                            key={index}
                            {...column.getHeaderProps()}
                            style={{
                              borderBottom: 'solid 1px black',
                              color: '#808080',
                              textAlign: 'center',
                              verticalAlign: 'bottom',
                              position: 'relative',
                            }}
                          >
                            {column.render('Header')}
                          </th>
                        )
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody
                  id="body"
                  className="company_tbody"
                  {...getTableBodyProps()}
                >
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={index}
                        className="m-0 w-100"
                      >
                        {row.cells.map((cell, index) => {
                          return index === 0 ? (
                            <td
                              className=""
                              {...cell.getCellProps()}
                              style={{
                                borderBottom: 'groove 2px #ececec',
                                fontSize: '14px',
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          ) : index === 4 ? (
                            <td
                              className=""
                              {...cell.getCellProps()}
                              style={{
                                borderBottom: 'groove 2px #ececec',
                                background: '',
                                textAlign: 'right',
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          ) : (
                            <td
                              className=""
                              {...cell.getCellProps()}
                              style={{
                                borderBottom: 'groove 2px #ececec',
                                background: '',
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Row>
      </Container>
    </Modal>
  );
};

export default ListTeamMemberModal;
