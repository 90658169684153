import React, { useEffect, useState, useRef } from 'react';
import '../css/chatModal.css';
import _ from 'lodash';
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from 'react-bootstrap';

const RecordScreenModal = ({ showModal, onClose, onStop }) => {
  const [media_stream, setMediaStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const blobs_recorded = [];
  const videoRef = useRef(null);

  const initializeMedia = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia();
    if (stream) {
      setMediaStream(stream);
      setInitialized(true);
    }
  };
  const onRecordingStart = (e) => {
    blobs_recorded.push(e.data);
  };
  const onRecordingStop = () => {
    const video_blob = new Blob(blobs_recorded, { type: 'video/webm' });
    onStop(video_blob);
    onClose();
  };
  const onStartClicked = (e) => {
    e.preventDefault();
    recorder.addEventListener('dataavailable', onRecordingStart);
    recorder.addEventListener('stop', onRecordingStop);
    recorder.start();
    e.stopPropagation();
  };
  const onStopClicked = (e) => {
    e.preventDefault();
    recorder.stop();
    e.stopPropagation();
  };
  const createRecorder = (stream) => {
    const media_recorder = new MediaRecorder(stream);
    setRecorder(media_recorder);
  };

  useEffect(() => {
    if (!initialized) {
      initializeMedia().then(() => {
        if (media_stream) {
          createRecorder(media_stream);
          videoRef.current.srcObject = media_stream;
        }
      });
    }
  });
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton>Capture Your Screen</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col xs={12}>
              <video width="400" autoPlay ref={videoRef}></video>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <button
          id="start-record"
          className="responsive-primary-btn"
          onClick={onStartClicked}
        >
          <i className="fa fa-play" aria-hidden="true"></i>
        </button>
        <button
          id="stop-record"
          className="responsive-primary-btn"
          onClick={onStopClicked}
        >
          <i className="fa fa-stop-circle-o" aria-hidden="true"></i>
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RecordScreenModal;
