import { Modal } from 'react-bootstrap';
import EmailUs from '../button/emailUs';
import TextUs from '../button/textUs';
import '../css/supportModal.css';
import { Row } from 'react-bootstrap';

const titleStyles = {
  position: 'absolute',
  left: '10%',
  top: '5%',
};

export default function SupportModal({ showModal, setShowModal }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      fullscreen="lg-down"
      size="sm"
    >
      <Modal.Header closeButton onClick={() => setShowModal(false)}>
        <div style={titleStyles} className="h1">
          Support
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 pt-2 m-0">
        <Row>
          <EmailUs />
        </Row>
        <Row>
          <TextUs />
        </Row>
      </Modal.Body>
    </Modal>
  );
}
