import '../css/growth.css';
import BarGraph from '../../assets/icon/Snapbrillia_subscriptionpage_growthimage.png';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CheckDot from '../../assets/icon/check_dot.svg';
import DropDown from '../../assets/icon/snapbrillia_dropdown_icon.svg';

// css
import '../css/subscription_card.css';

export default function GrowthSubscriptionModal() {
  const [showPayment, setShowPayment] = useState(false);

  const DropdownIndicator = (state) => {
    return (
      <components.DropdownIndicator {...state}>
        <div
          onTouchStart={() => {
            // e.stopPropagation();
            // e.preventDefault();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <img src={DropDown} alt="Drop down" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        color: state.isSelected || state.isFocused ? '#2d2b6f' : '#808080',
        padding: 10,
        fontWeight: 500,
        cursor: 'pointer',
      };
    },
    control: () => {
      return {
        borderWidth: 0,
        alignItems: 'center',
        borderRadius: 6,
        backgroundColor: '#f4f4f4',
        height: '30px',
        display: 'flex',
        color: '#2d2b6f',
        zIndex: 999,
        cursor: 'pointer',
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: '#2d2b6f',
        fontWeight: 500,
        zIndex: 999,
      };
    },
    menu: (provided, state) => {
      return {
        position: 'absolute',
        color: state.selectProps.menuColor,
        backgroundColor: '#fff',
        height: state.options.length * 41,
        boxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        transition: 'all 0.8s ease-in-out',
        opacity: 1,
        visibility: 'visible',
        zIndex: 9999,
        overflow: 'hidden',
        width: '100%',
      };
    },
    dropdownIndicator: () => {
      return {
        position: 'relative',
        right: '95%',
      };
    },
  };

  return (
    <Container>
      <div className="card-container">
        <Row>
          <Col>
            <h1 className="card-title mt-3">Growth</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <img className="growth-img" src={BarGraph} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="sub-heading mt-2">
              $499 <p className="growth-month">/ month</p>{' '}
            </h2>
          </Col>
        </Row>
        <Row className="row">
          <Col xs={1}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-5">
              Limited public source bounties (500,000$ per year)
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={1}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-5">
              Limited private bounties (500,000$ per year)
            </p>
          </Col>
        </Row>
        <Row>
          {showPayment ? (
            <Col className="mb-2 px-4">
              <div className="border-bottom border-2 border-dark pb-2 mx-1">
                Payment Method
                <Select
                  styles={customStyles}
                  isSearchable={false}
                  options={[
                    { value: 'USAA(...0197)', label: 'USAA(...0197)' },
                    { value: 'Yoroi', label: 'Yoroi' },
                  ]}
                  defaultValue={{
                    value: 'USAA(...0197)',
                    label: 'USAA(...0197)',
                  }}
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                ></Select>
              </div>
              <div className="mx-1">
                <div className="d-flex justify-content-between">
                  <span>Tax & Fees</span>
                  <span>$ 3.00</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>
                    <b>Total</b>
                  </span>
                  <span>
                    <b>$ 502.00</b>
                  </span>
                </div>
              </div>
            </Col>
          ) : (
            <Col>
              <p className="free-trial">
                Try our 30 day <strong>free</strong> trial
              </p>
            </Col>
          )}
        </Row>
        <Row>
          {showPayment ? (
            <Col className="pt-1">
              <span
                onClick={() => setShowPayment(false)}
                className="semi-bold ps-3 pointer"
              >
                <u>Cancel</u>
              </span>
            </Col>
          ) : undefined}
          <Col>
            <button
              onClick={() => setShowPayment(true)}
              className="bottom-button mb-3"
            >
              {showPayment ? 'Confirm Purchase' : 'Subscribe'}
            </button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
