/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { getOpportunities } from '../../api/opportunities';
import { useParams } from '@reach/router';
import { Col, Row } from 'react-bootstrap';
import JobCreateStepsModal from '../../shared/modals/JobCreateStepModal';
import BountyCreateStepsModal from '../../shared/modals/bountyCreateStepsModal';
import CartModal from './cartModal';
import ConnectCartModal from './connectCartModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../css/allBountiesTable.css';
import '../../shared/css/sharedStyles.css';
import EditSVG from '../../assets/icon/snapbrillia_edit_icon.svg';
import WalletSVG from '../../assets/icon/snapbrillia_wallet_bank_icon.svg';
import AddCardSVG from '../../assets/icon/snapbrillia_credit_card_icon.svg';
import AddBountyButton from '../../shared/button/addBounty';
import AddJobButton from '../../shared/button/addJobButton';
import { navigate } from '@reach/router';
import { compensation } from '../../shared/utils';
import { Typography, Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import UserCouponList from './UserCouponList';
import { useAuth } from '../../context/authContext';

const AllOpportunityTable = () => {
  const [bounties, setBounties] = useState([]);
  const [showCart, setCartModal] = useState(false);
  const [selectedBounty, setBounty] = useState(null);
  const [creditCart, setCreditCart] = useState(false);
  const [editBounty, setEditBounty] = useState();
  const [editJob, setEditJob] = useState();
  const [showModal, setShowModal] = useState(false);
  const [pay, setPay] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [cryptoPay, setCryptoPay] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const { auth } = useAuth();

  const [dataState, setDataState] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);

  const [bounty, setBounti] = useState({});
  const companyId = useParams()?.companyId;

  const closeModal = () => {
    setShowModal(false);
  };

  const closeJobModal = () => {
    setShowJobModal(false);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('snapbrillia-opportunity-admin.pdf');
  };

  const getBountiesData = async (filter, nextPage = 1) => {
    setLoading(true);
    let newPage = nextPage > page ? nextPage : page;
    await getOpportunities({
      page: newPage,
      limit: limit,
      filter: filter,
    })
      .then((response) => {
        setBounties(response.docs);
      })
      .catch((error) => {
        setBounties([]);
      });
    setLoading(false);
  };

  function capitolize(string = '') {
    const str1 = string.slice(1);
    const capitolized = string.charAt(0).toUpperCase() + str1;
    return capitolized;
  }

  const clickEdit = async (value, index) => {
    setEditBounty({ ...value, index: index });
    setShowModal(true);
  };
  const clickEditJob = async (value, index) => {
    setEditJob({ ...value, index: index });
    setShowJobModal(true);
  };

  useEffect(() => {
    getBountiesData();
  }, []);
  useEffect(() => {
    if (!showModal) {
      setEditBounty(null);
    }
  }, [showModal]);

  useEffect(() => {
    if (!showJobModal) {
      setEditJob(null);
    }
  }, [showJobModal]);
  //should be memoized or stable
  var startTime = performance.now();
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => (
          <div
            onClick={() => {
              row.type === 'Job'
                ? navigate(`/opportunity/${row._id}`)
                : navigate(`/opportunity/${row._id}`);
            }}
            className="pointer"
          >
            View
            <div className="positions">Public</div>
            <div className="bounty_open">
              <span style={{ fontSize: '13px' }}>•</span>
              <span>{row.type}</span>
            </div>
            {row.funded && row.status !== 'pending' && (
              <div className="bounty_open">
                <span style={{ fontSize: '13px' }}>•</span>
                <span>{capitolize(row.status)}</span>
              </div>
            )}
            {!row.funded && row.status !== 'pending' && (
              <div className="bounty_closed">
                <span style={{ fontSize: '13px' }}>•</span>
                <span>{'Not Funded'}</span>
              </div>
            )}
            {row.status === 'pending' && (
              <div className="bounty_closed">
                <span style={{ fontSize: '13px' }}>•</span>
                <span>{'Wait for confirmations'}</span>
              </div>
            )}
          </div>
        ), //access nested data with dot notation
        header: 'Details',
        size: 150,
      },
      {
        accessorFn: (row) => row.title, //access nested data with dot notation
        header: 'Title',
        size: 150,
      },
      {
        accessorFn: (row) => row.type,
        header: 'Type',
        size: 50,
      },
      //   {
      //     accessorFn: (row) => row.workplaceType, //normal accessorKey
      //     header: 'Creator Name',
      //     size: 100,
      //   },
      {
        accessorFn: (row) => row.workplaceType || 'None',
        header: 'Workplace Type',
        size: 50,
      },
      {
        accessorFn: (row) => row.jobListingType || 'Regular',
        header: 'Listing Type',
        size: 50,
      },
      {
        accessorFn: (row) =>
          new Date(row.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 100,
      },
      {
        accessorFn: (row) =>
          row?.maxCompensation && row.maxCompensation > 0
            ? row.minCompensation +
              ' - ' +
              row.maxCompensation +
              ' ' +
              row.rewardType +
              ' ' +
              compensation(row)
            : (row.rewardAmount || row.minCompensation) +
              ' ' +
              row.rewardType +
              ' ' +
              compensation(row),
        header: 'Compensation',
        size: 150,
      },
      {
        accessorFn: (row) => (
          <div style={{ display: 'inline-flex' }}>
            {!row.funded &&
              (row.type === 'Job' ? (
                <div
                  style={{ transform: 'scale(0.6)', cursor: 'pointer' }}
                  onClick={() => {
                    clickEditJob(row, row._id);
                    setPay(true);
                    setCryptoPay(
                      row?.paymentType === 'Bank'
                        ? false
                        : row?.paymentType === 'Crypto'
                    );
                  }}
                >
                  <img src={WalletSVG} alt="wallet-icon" />
                </div>
              ) : (
                <div
                  style={{ transform: 'scale(0.6)', cursor: 'pointer' }}
                  onClick={() => {
                    clickEdit(row, row._id);
                    setPay(true);
                    setCryptoPay(
                      row?.paymentType === 'Bank'
                        ? false
                        : row?.paymentType === 'Crypto'
                    );
                  }}
                >
                  <img src={WalletSVG} alt="wallet-icon" />
                </div>
              ))}
            {row.funded && row.type !== 'Job' && (
              <div
                style={{ transform: 'scale(0.6)', cursor: 'pointer' }}
                onClick={() => {
                  setBounty(row);
                  setCartModal(true);
                }}
              >
                <img src={AddCardSVG} alt="wallet-icon" />
              </div>
            )}
            {row.type === 'Gig' ? (
              <div
                style={{ transform: 'scale(0.6)', cursor: 'pointer' }}
                onClick={() => {
                  clickEdit(row, row._id);
                  setPay(false);
                }}
              >
                <img src={EditSVG} alt="edit-icon" />
              </div>
            ) : (
              <div
                style={{ transform: 'scale(0.6)', cursor: 'pointer' }}
                onClick={() => {
                  clickEditJob(row, row._id);
                  setPay(false);
                }}
              >
                <img src={EditSVG} alt="edit-icon" />
              </div>
            )}
          </div>
        ),
        header: 'Action',
        size: 150,
      },
    ],
    []
  );
  var endTime = performance.now();

  console.log(`Call to doSomething took ${endTime - startTime} milliseconds`);
  const tooltipText = (text) => {
    return (
      <Tooltip id="tooltip">
        <strong>{text}</strong>
      </Tooltip>
    );
  };
  return (
    <>
      <Col className="">
        <Row>
          <OverlayTrigger
            placement="top"
            overlay={tooltipText(
              'Suited for full-time, part-time, contract and agency assisted temporary hiring needs.'
            )}
          >
            <Col xl={4}>
              <div
                onClick={() => {
                  setShowJobModal(true);
                  setPay(false);
                }}
                className="add-bounty-shrink"
              >
                <AddJobButton />
              </div>
            </Col>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={tooltipText(
              'Suited for short and on-demand freelance, consultant and independent contractor hiring needs'
            )}
          >
            <Col xl={4}>
              <div
                onClick={() => {
                  setShowModal(true);
                  setPay(false);
                }}
                className="add-bounty-shrink"
              >
                <AddBountyButton />
              </div>
            </Col>
          </OverlayTrigger>
          {auth.user.companyRole === 'owner' && auth.company.subscribed && (
            <OverlayTrigger
              placement="top"
              overlay={tooltipText('View all coupon and also request a coupon')}
            >
              <Col xl={4}>
                <div
                  onClick={() => {
                    setShowCoupon(true);
                  }}
                  className="add-bounty-shrink"
                >
                  <AddBountyButton title={'View Coupon'} />
                </div>
              </Col>
            </OverlayTrigger>
          )}
        </Row>
      </Col>
      <MaterialReactTable
        columns={columns}
        data={bounties}
        enableFullScreenToggle={false}
        state={{
          isLoading,
        }}
        defaultColumn={{
          Cell: ({ renderedCellValue }) => {
            return <>{renderedCellValue}</>;
          },
        }}
        enableStickyHeader
        initialState={{ pagination: { pageSize: 10, pageIndex: 0 } }}
        memoMode="cells"
        muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <Typography component="span" variant="h4">
              Opportunities Created
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Page Rows
              </Button>
            </Box>
          </>
        )}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 200, //set custom width
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
          },
        }}
        renderDetailPanel={({ row }) => (
          <Row>
            <Col>
              <h5>Description</h5>
              <div>{row.original.taskDescription}</div>
            </Col>
            <Col>
              <h5>Skills Required</h5>
              <ul>
                {row.original?.skillIds?.map((item) => (
                  <div key={item.name}>
                    <li>{item.name}</li>
                  </div>
                ))}
              </ul>
            </Col>
          </Row>
        )}
      />
      {showModal && (
        <div>
          <BountyCreateStepsModal
            show={BountyCreateStepsModal}
            closeModal={(_id, isPrivate) => {
              closeModal();
              getBountiesData();
              if (_id && isPrivate) {
                navigate(`/bounties/${_id}/addCandidate`);
              }
            }}
            gig={editBounty}
            editSteps={pay ? 4 : 1}
            cryptoPay={cryptoPay}
            setCryptoPay={setCryptoPay}
          />
        </div>
      )}
      {showJobModal && (
        <div>
          <JobCreateStepsModal
            show={JobCreateStepsModal}
            closeJobModal={(_id, isPrivate) => {
              closeJobModal();
              getBountiesData();
              if (_id && isPrivate) {
                navigate(`/jobs/${_id}/addApplicant`);
              }
            }}
            job={editJob}
            editSteps={pay ? 4 : 1}
            cryptoPay={cryptoPay}
            setCryptoPay={setCryptoPay}
          />
        </div>
      )}
      {showCoupon && (
        <UserCouponList
          showCoupon={showCoupon}
          handleClose={() => {
            setShowCoupon(false);
          }}
        />
      )}
      {showCart &&
        (!creditCart ? (
          <CartModal
            setCartModal={(state) => {
              setCartModal(state);
              setBounty(null);
              //   setFilterBounties([]);
            }}
            reloadTable={() => {
              getBountiesData();
            }}
            changeCartType={() => {
              setCreditCart(true);
            }}
            bounty={selectedBounty}
          />
        ) : (
          <ConnectCartModal
            setCartModal={(state) => {
              setCartModal(state);
              setBounty(null);
              //   setFilterBounties([]);
            }}
            reloadTable={() => {
              getBountiesData();
            }}
            changeCartType={() => {
              setCreditCart(false);
            }}
            bounty={selectedBounty}
          />
        ))}
    </>
  );
};

export default AllOpportunityTable;
