import React from 'react';
import StepOne from './components/stepOne';
import StepTwo from './components/stepTwo';
import StepThree from './components/stepThree';
import StepFour from './components/stepFour';

import { InvoicedGigProvider } from '../context/invoicedGigContext';

export const InvoiceBountyCreateSteps = ({
  gig,
  closeModal,
  step,
  setStep,
  // companyName,
}) => {
  // Proceed to next step
  const nextStep = () => {
    setStep((x) => x + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep((x) => x - 1);
  };

  const resetStep = async () => {
    setStep(1);
  };
  return (
    <InvoicedGigProvider gig={gig}>
      {step === 1 && <StepOne nextStep={nextStep} resetStep={resetStep} />}
      {step === 2 && (
        <StepTwo
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
        />
      )}
      {step === 3 && (
        <StepThree
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
        />
      )}
      {step === 4 && (
        <StepFour
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
          closeModal={closeModal}
        />
      )}
    </InvoicedGigProvider>
  );
};
export default InvoiceBountyCreateSteps;
