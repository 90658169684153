import '../../shared/css/typography.css';
import { Row, Col } from 'react-bootstrap';

const AddMemberFormHeader = () => {
  return (
    <Row>
      <Col>
        <h3 className="formHeader">
          Any team members you would like to add now? You can always add more
          later.
        </h3>
      </Col>
    </Row>
  );
};

export default AddMemberFormHeader;
