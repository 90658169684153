import { Container, Row, Col } from 'react-bootstrap';
import { Fragment, useState, useEffect } from 'react';
import Clock from '../../assets/icon/snapbrillia_clock_icon.svg';
import Person from '../../assets/icon/snapbrillia_profile_personal_icon.svg';
import Analytics from '../../assets/icon/snapbrillia_softwareengineerdiversityrating_icon.svg';
import '../../shared/css/typography.css';
import '../css/timeLineOverview.css';
// import { getProjectDiversity } from '../../api/projects';
// the worksample project getting passed in needs be fetched using the route /api/work-sample-project/all to get all the required data
// if you pass in the workSamplePorject that is fetched using /api/work-sample-project/one/:id
const TimeLineOverview = ({ workSampleProject, candidates }) => {
  const statuses = ['New', 'Invited', 'In-Progress', 'Completed', 'Reviewed'];
  const [active, setActive] = useState(0);
  const [passed, setPassed] = useState(0);
  const [missed, setMissed] = useState(0);
  const [total, setTotal] = useState(0);
  const [diversity, setDiversity] = useState(0);
  const [hour] = useState(0);
  const [sliderCandidate, setSliderCandidate] = useState(workSampleProject.new);

  const changeStatusData = (index) => {
    if (index === 0) {
      setSliderCandidate(
        candidates.filter((candidate) => {
          return candidate.assessment.status === 'new';
        }).length
      );
    } else if (index === 1) {
      setSliderCandidate(
        candidates.filter((candidate) => {
          return candidate.assessment.status === 'invited';
        }).length
      );
    } else if (index === 2) {
      setSliderCandidate(
        candidates.filter((candidate) => {
          return candidate.assessment.status === 'in-progress';
        }).length
      );
    } else if (index === 3) {
      setSliderCandidate(
        candidates.filter((candidate) => {
          return candidate.assessment.status === 'completed';
        }).length
      );
    } else if (index === 4) {
      setSliderCandidate(
        candidates.filter((candidate) => {
          return candidate.assessment.status === 'reviewed';
        }).length
      );
    }
    setActive(index);
  };

  const initCandidate = async () => {
    if (candidates && candidates.length > 0) {
      setPassed(
        candidates.filter((candidate) => {
          return [
            'invited',
            'accepted',
            'in-progress',
            'completed',
            'reviewed',
          ].includes(candidate.assessment.status);
        }).length
      );
      setMissed(
        candidates.filter((candidate) => {
          return ['bounced', 'expired'].includes(candidate.assessment.status);
        }).length
      );
      setSliderCandidate(
        candidates.filter((candidate) => {
          return ['new'].includes(candidate.assessment.status);
        }).length
      );
    }
  };

  const initWorkSampleProject = async () => {
    setTotal(
      workSampleProject.candidateId ? workSampleProject.candidateId.length : 0
    );
    setDiversity(workSampleProject.diversity ? workSampleProject.diversity : 0);
  };
  useEffect(() => {
    initCandidate();
  }, [candidates]);

  useEffect(() => {
    initWorkSampleProject();
  }, [workSampleProject]);

  const convertPercentage = (percentage) => {
    let height = (percentage / (total - missed)) * 100;
    if (height === 0) {
      height = 1;
    }
    return height;
  };

  return (
    <Container fluid>
      <Row className="primarybg p-4 roudned-3" style={{ borderRadius: '10px' }}>
        <Col className="overview-leftside" xs={2}>
          <h3 className="overview-h3">Total</h3>
          <h1 data-testid="total-candidate" className="time-line-total-number">
            {total}
          </h1>
          <h5 className="overview-h5">Missed</h5>
          <span className="overview-missed" data-testid="missed-candidate">
            {missed}
          </span>
          <h5 className="overview-h5">Pass</h5>
          <span className="overview-passed" data-testid="passed-candidate">
            {passed}
          </span>
        </Col>
        <Col className="overview-rightside-col">
          <div className="overview-rightside">
            <div className="overview-graph">
              <div
                className="overview-new-graph"
                title={
                  candidates.filter((candidate) => {
                    return candidate.assessment.status === 'new';
                  }).length
                }
                style={{
                  bottom: `${convertPercentage(
                    candidates.filter((candidate) => {
                      return candidate.assessment.status === 'new';
                    }).length
                  )}%`,
                }}
              />
              <div
                data-toggle="tooltip"
                data-placement="right"
                title={
                  candidates.filter((candidate) => {
                    return candidate.assessment.status === 'invited';
                  }).length
                }
                className="overview-invited-graph"
                style={{
                  bottom: `${convertPercentage(
                    candidates.filter((candidate) => {
                      return candidate.assessment.status === 'invited';
                    }).length
                  )}%`,
                }}
              />
              <div
                className="overview-inProgress-graph"
                title={
                  candidates.filter((candidate) => {
                    return candidate.assessment.status === 'in-progress';
                  }).length
                }
                style={{
                  bottom: `${convertPercentage(
                    candidates.filter((candidate) => {
                      return candidate.assessment.status === 'in-progress';
                    }).length
                  )}%`,
                }}
              />
              <div
                className="overview-completed-graph"
                title={
                  candidates.filter((candidate) => {
                    return candidate.assessment.status === 'completed';
                  }).length
                }
                style={{
                  bottom: `${convertPercentage(
                    candidates.filter((candidate) => {
                      return candidate.assessment.status === 'completed';
                    }).length
                  )}%`,
                }}
              />
              <div
                className="overview-reviewed-graph"
                title={
                  candidates.filter((candidate) => {
                    return candidate.assessment.status === 'reviewed';
                  }).length
                }
                style={{
                  bottom: `${convertPercentage(
                    candidates.filter((candidate) => {
                      return candidate.assessment.status === 'reviewed';
                    }).length
                  )}%`,
                }}
              />
            </div>
            <div className="overview-divider" />
            <div className="overview-status-title-container">
              {statuses.map((status, index) => {
                return (
                  <Fragment key={index}>
                    <input
                      type="radio"
                      id={`overview-radio-${index}`}
                      name="label"
                    />
                    <label
                      className={
                        index === active
                          ? 'active-overview-status-title'
                          : 'overview-status-title'
                      }
                      onClick={() => changeStatusData(index)}
                      htmlFor={`overview-radio-${index}`}
                    >
                      {status}
                    </label>
                  </Fragment>
                );
              })}
              <div id="overview-slider" data-testid="overview-slider">
                <div>
                  <h1 className="h5">
                    <img src={Person} alt="person" />
                    {sliderCandidate}
                    <p className="label">Candidates</p>
                  </h1>
                </div>
                <div>
                  {/* need change later */}
                  <h1 className="h5">
                    <img src={Clock} alt="clock" />
                    {hour} hr
                    <p className="label">Average Time</p>
                  </h1>
                </div>
                <div>
                  <h1 className="h5">
                    {/* need change later */}
                    <img
                      src={Analytics}
                      alt="analytics"
                      height={24}
                      width={24}
                    />
                    {diversity} %<p className="label">Diversity Rating</p>
                  </h1>
                </div>
                <div />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TimeLineOverview;
