import '../css/typography.css';
import './css/dropDownWallet.css';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState, useEffect } from 'react';

import { ReactComponent as Bank } from '../../assets/icon/snapbrillia_wallet_bank_icon.svg';
import { ReactComponent as CryptoWallet } from '../../assets/icon/snapbrillia_crypto_wallet_icon.svg';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { createStripeSession, listStripeAccounts } from '../../api/sessions';
import { useCardanoWallet } from '../../context/walletContext';
import StripeModal from './stripeModal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
import { useAccount, useDisconnect } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';

export default function DropDownWallet() {
  const {
    walletConnected,
    connectWallet,
    address,
    disconnectWallet,
    supportedWallets,
    disconnectPhantomWallet,
    connectPhantomWallet,
    solanaConnected,
    solanaAddress,
  } = useCardanoWallet();
  const [linkToken, setLinkToken] = useState('');
  const [accounts, setAccounts] = useState([]);
  const { account, isReady } = useAccount();
  const disconnect = useDisconnect();
  const { open } = useWeb3Modal();

  const connectBankAccount = async () => {
    const { client_secret } = await createStripeSession();
    setLinkToken(client_secret);
  };

  const loadBankInfo = async () => {
    try {
      const { accounts } = await listStripeAccounts();
      if (accounts) {
        setAccounts(accounts);
      }
    } catch (error) {
      // toast(error.message);
    }
  };

  useEffect(() => {
    loadBankInfo();
  }, []);

  return (
    <>
      <Dropdown.Menu>
        <Dropdown.Header>Select Wallet</Dropdown.Header>
        <div className="wallet-dropdown-container">
          <Dropdown.Item
            eventKey="1"
            style={{ textAlign: 'left' }}
            className="wallet-dropdown-item"
            onClick={connectBankAccount}
          >
            <Bank className="Bank_icon" />
            <span className="wallet-dropdown-text">Connect your bank</span>
          </Dropdown.Item>
          {accounts.length > 0 &&
            accounts.map((account, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  eventKey="1"
                  style={{ textAlign: 'left' }}
                  className="wallet-dropdown-item"
                >
                  <span className="wallet-dropdown-text">
                    {`${account.institution_name} (...${account.last4})`}
                  </span>
                </Dropdown.Item>
              );
            })}
          <Dropdown.Item
            eventKey="2"
            style={{ textAlign: 'left' }}
            className="wallet-dropdown-item"
            onClick={disconnectWallet}
          >
            {walletConnected && (
              <>
                <CryptoWallet className="crypto_wallet_icon" />
                <span className="wallet-dropdown-text">
                  Disconnect Cardano Wallet
                </span>
              </>
            )}
          </Dropdown.Item>
          {!walletConnected &&
            supportedWallets.map((wallet, index) => {
              return (
                <div key={index}>
                  <Dropdown.Item
                    eventKey="1"
                    className="wallet-dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      connectWallet(wallet.value);
                    }}
                  >
                    <CryptoWallet className="crypto_wallet_icon" />
                    <span className="wallet-dropdown-text">
                      Connect Your {wallet.name} Wallet
                    </span>
                  </Dropdown.Item>
                </div>
              );
            })}
          {walletConnected && (
            <Dropdown.Item eventKey="1" className="wallet-dropdown-item">
              {' '}
              <span className="wallet-dropdown-text">
                {address.slice(0, 4)}.....{address.slice(-10)}
              </span>
            </Dropdown.Item>
          )}
          <Dropdown.Item
            eventKey="2"
            style={{ textAlign: 'left' }}
            className="wallet-dropdown-item"
            onClick={() => {
              return account?.isConnected ? disconnect() : open();
            }}
          >
            <CryptoWallet className="crypto_wallet_icon" />
            <span className="wallet-dropdown-text">
              {account?.isConnected
                ? 'Disconnect Ethereum Address'
                : 'Connect your Wallet Connect'}
            </span>
          </Dropdown.Item>
          {isReady && account?.isConnected && (
            <Dropdown.Item
              eventKey="1"
              style={{ textAlign: 'left' }}
              className="wallet-dropdown-item"
            >
              <span className="wallet-dropdown-text">
                {account.address.slice(0, 4)}.....{account.address.slice(-10)}
              </span>
            </Dropdown.Item>
          )}
          <Dropdown.Item
            eventKey="2"
            style={{ textAlign: 'left' }}
            className="wallet-dropdown-item"
            onClick={() => {
              solanaConnected
                ? disconnectPhantomWallet()
                : connectPhantomWallet();
            }}
          >
            <CryptoWallet className="crypto_wallet_icon" />
            <span className="wallet-dropdown-text">
              {solanaConnected
                ? 'Disconnect Phantom Address'
                : 'Connect your Phantom Wallet'}
            </span>
          </Dropdown.Item>
          {solanaConnected && (
            <Dropdown.Item
              eventKey="3"
              style={{ textAlign: 'left' }}
              className="wallet-dropdown-item"
            >
              <span className="wallet-dropdown-text">
                {solanaAddress.slice(0, 4)}.....{solanaAddress.slice(-10)}
              </span>
            </Dropdown.Item>
          )}
        </div>
      </Dropdown.Menu>
      <Elements stripe={stripePromise}>
        {linkToken && (
          <StripeModal clientSecret={linkToken} reloadAccount={loadBankInfo} />
        )}
      </Elements>
    </>
  );
}
