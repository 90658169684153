import React, { useState, useEffect } from 'react';
import Laptop from '../../assets/icon/snapbrillia_securityandloginlaptop_icon.svg';
import Phone from '../../assets/icon/snapbrillia_securityandloginphone_icon.svg';

import { Row, Col } from 'react-bootstrap';
import '../../shared/css/typography.css';
import '../css/profileSignedInDevices.css';
import { useAuth } from '../../context/authContext';
import { navigate } from '@reach/router';
import moment from 'moment';

const DeviceList = ({ devicesArr }) => {
  function formatDate(date) {
    let result = null;
    if (typeof date !== 'undefined' && date != null) {
      let now = moment(),
        updated = moment(new Date(date));
      result = updated.from(now);
    }
    return result;
  }

  return (
    <div className="device-bg mb-3">
      {devicesArr.map((device, idx) => {
        return (
          <Row key={idx} className="device_box mb-3">
            <Col xs={6} md={6} className="align-middle text-center">
              {device.isMobile ? (
                <img src={Phone} alt="phone-icon" />
              ) : (
                <img src={Laptop} alt="laptop-icon" />
              )}
            </Col>
            <Col xs={6} md={6}>
              <Row>
                <label className="paragraph-bold"> {device.name} </label>
              </Row>
              <Row>
                <label className="label "> {device.location}</label>
              </Row>
              <Row className="justify-content-md-center">
                <label className="m-md-3 device_lastused">
                  {' '}
                  {formatDate(device.date)}{' '}
                </label>
              </Row>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

const EmptyDeviceList = () => {
  return (
    <div>
      <Row>
        <div>No Devices were found</div>
      </Row>
    </div>
  );
};

const ProfileSignedInDevices = () => {
  const { auth, invalidateJwtTokens, fetchUserDevices } = useAuth();
  const [devices, setUserDevices] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  function logOutOfAllDevices(e) {
    e.preventDefault();
    invalidateJwtTokens(auth.user._id, () => {
      navigate('/signin');
    });
  }

  useEffect(() => {
    if (!isLoaded) {
      fetchUserDevices((data) => {
        setUserDevices(data);
        setLoaded(true);
      });
    }
  }, []);

  return (
    <div>
      <header className="h1 pb-4">Your Signed-in Devices</header>
      <div>
        {isLoaded ? <DeviceList devicesArr={devices} /> : <EmptyDeviceList />}
      </div>
      <div style={{ float: 'right' }}>
        <a className="device_logout" href="#" onClick={logOutOfAllDevices}>
          Log out of all devices
        </a>
      </div>
    </div>
  );
};
export default ProfileSignedInDevices;
