import React from 'react';
import { Col, Row, Container, Modal, ModalHeader } from 'react-bootstrap';
import './css/contactModal.css';

const ContactModal = ({ show, setShow }) => {
  return (
    <Modal show={show} onHide={setShow} size="lg">
      <Container className="contact-box">
        <Row>
          <ModalHeader className="contact-text">
            Contact Us
            <button onClick={() => setShow(false)} className="close">
              &times;
            </button>
          </ModalHeader>
          <p className="sub-text">
            Fill out the form below and we will be in contact with you shortly
            or click here to email us directly
          </p>
        </Row>
        <Row>
          <Col className="input-box-text-left">
            <p>
              First Name
              <textarea className="input-box"></textarea>
            </p>
          </Col>
          <Col>
            <p className="input-box-text-right">
              Last Name
              <textarea className="input-box"></textarea>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="input-box-text-left">
            <p>
              Email
              <textarea className="input-box"></textarea>
            </p>
          </Col>
          <Col>
            <p className="input-box-text-right">
              Phone (Optional)
              <textarea className="input-box"></textarea>
            </p>
          </Col>
        </Row>
        <Row>
          <p className="input-box-text-left">
            Notes (Optional)
            <textarea className="notes-input-box"></textarea>
          </p>
        </Row>
        <Col className="button-alignment">
          <button className="send-button">Send</button>
        </Col>
      </Container>
    </Modal>
  );
};
export default ContactModal;
