import React, { useRef } from 'react';
import '../css/uploadFiles.css';

const UploadFiles = ({ handleFile, setImportedFile, setUploadState }) => {
  const inputRef = useRef();

  const validateFile = (file) => {
    const validFileTypes = [
      'application/pdf',
      'text/plain',
      'application/msword',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const maxFileSize = 10000000;
    let validFiles = [];
    for (let i = 0; i < file.length; i++) {
      if (validFileTypes.includes(file[i].type) && file[i].size < maxFileSize) {
        validFiles.push(file[i]);
      }
    }
    if (validFiles.length) {
      handleFile(validFiles);
    } else {
      setImportedFile(true);
      setUploadState(4);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    if (file.length === 0) {
      return;
    }
    validateFile(file);
  };

  const clickUpload = (e) => {
    const file = e.target.files;
    if (file.length === 0) {
      return;
    }
    validateFile(file);
  };

  return (
    <div
      className="drop-container import-candidate-text"
      onDragOver={(e) => dragOver(e)}
      onDrop={(e) => fileDrop(e)}
      onClick={() => inputRef.current.click()}
    >
      <input
        type="file"
        id="imgupload"
        ref={inputRef}
        onChange={(e) => clickUpload(e)}
        style={{ display: 'none' }}
      />

      <p>
        <b>Drag</b> and <b>drop</b> your files here,
        <br />
        or <b>click</b> to select a file.
      </p>
    </div>
  );
};

export default UploadFiles;
