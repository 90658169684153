import { Container, Col, Row } from 'react-bootstrap';
import IntegrationSideMenu from '../shared/menus/integrationSideMenu';
import AllIntegrationsCard from './components/allIntegrationsCard';
import IntegrationsCard from './components/integrationsCard';
import { Link } from '@reach/router';
import Logo from '../assets/SnapBetaLogo2.svg';
import Close from '../assets/icon/snapbrillia_close_icon.svg';
import '../shared/css/bgColors.css';
import '../shared/css/typography.css';
import '../shared/css/sharedStyles.css';
import './greenhouse/css/allIntergrationsPage.css';
import { useState } from 'react';

export default function AllIntegrations() {
  const [component, setComponent] = useState(0);
  return (
    <Container className="primarybg" fluid>
      <Row>
        <Col xs={12} sm={12} md={3}>
          <Row className="pt-3">
            <Col>
              <div className="close_modal_button">
                <Link to="/dashboard">
                  <img src={Close} alt="close-icon" />
                </Link>
              </div>
            </Col>
            {/* <Row> */}
            <img src={Logo} alt="logo" />
            <IntegrationSideMenu
              component={component}
              setComponent={setComponent}
              className="py-5"
            />
            {/* <CopyRight /> */}
            {/* </Row> */}
          </Row>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={9}
          data-testid="main"
          className="pagemainbg componentborder allIntegration-page-bg"
        >
          {component === 0 && (
            <div>
              <h1 className="h1 p-4">App Integrations</h1>
              <Row>
                <Col xs={12} sm={12} md={6} className="py-2">
                  <Col md={11} className="mx-4">
                    <AllIntegrationsCard />
                  </Col>
                </Col>
                <Col xs={12} sm={12} md={6} className="py-2">
                  <Col md={11} className="mx-4">
                    <Container className="allIntegration-blank-card-bg"></Container>
                  </Col>
                </Col>
              </Row>
            </div>
          )}
          {component === 1 && (
            <div>
              <h1 className="h1 p-4">App Integrations</h1>
              <Row>
                <Col xs={11} sm={11} md={11} className="py-2 px-3">
                  <IntegrationsCard />
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
