import '../../shared/css/sharedStyles.css';
export default function B2CBountyButton(bountyId) {
  const goToB2C = () => {
    const url = `${process.env.REACT_APP_B2C_URL}/bounties/${bountyId.bountyId}`;
    window.open(url, '_blank');
  };
  return (
    <div>
      <button className="btn-edit-modal-link m-2" onClick={() => goToB2C()}>
        See Gig
      </button>
    </div>
  );
}
