import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../admin/css/userManagement.css';

import { navigate } from '@reach/router';
import RegisterUserView from './components/registerUserView';
import CreateBountyView from './components/bountiesView';

const SalesView = () => {
  const tabs = [
    {
      title: 'Users',
      component: <RegisterUserView />,
    },
    {
      title: 'Bounties',
      component: <CreateBountyView />,
    },
  ];
  const [component, setComponent] = useState(tabs[0]);
  const changeComponent = (index) => {
    setComponent(tabs[index]);
  };
  return (
    <div>
      <Container className="p-0 pt-3" fluid>
        <Row className="management-tab-title-container">
          {tabs.map((tab, index) => {
            return (
              <Col xs={6} key={index}>
                <label
                  className={
                    tab.title === component.title
                      ? 'management-active-tab-title management-title-view-tab'
                      : 'management-inactive-tab-title management-title-view-tab'
                  }
                  onClick={() => {
                    navigate('/personal-profile');
                    changeComponent(index);
                  }}
                  htmlFor={`radio-${index}`}
                >
                  {tab.title}
                  <span className={`${index > 0 && 'left-curve'}`}></span>
                </label>
              </Col>
            );
          })}
          <div id="tab-glider" />
        </Row>
        <Col
          className={
            component.title === 'Users'
              ? 'management-tabs-view-tab-overview'
              : 'management-tabs-view-tab'
          }
        >
          {' '}
          {component.component}
        </Col>
      </Container>
    </div>
  );
};
export default SalesView;
