import '../css/buttonStyles.css';

export default function AddBountiesFormButton({
  handleClick,
  text = 'Add Bounty',
}) {
  return (
    <button className="btn-primary" onClick={handleClick}>
      {text}{' '}
    </button>
  );
}
