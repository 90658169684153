import axios from 'axios';

export async function getBounty(bountyId) {
  const response = await axios.get(`/api/bounties/${bountyId}`);

  return response.data;
}

export async function getBounties({ page = 1, limit = 10, filter = 'all' }) {
  const response = await axios.get(
    `/api/bounties?page=${page}&limit=${limit}&status=${filter}`
  );
  return response.data;
}

export async function getJobs({ page = 1, limit = 100 }) {
  const response = await axios.get(`/api/bounties?page=${page}&limit=${limit}`);
  return response.data;
}

export async function createBounties(data) {
  const response = await axios.post(`/api/bounties/create`, data);
  return response.data;
}

export async function createInvoicedBounty(data) {
  const response = await axios.post(`/api/bounties/invoiced`, data);
  return response.data;
}

export async function addCandidate(data, isReInvite) {
  try {
    const response = await axios.post(
      `/api/bounties/add-candidate?isReInvite=${isReInvite ? 1 : 0}`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function editBounty(data) {
  const response = await axios.put(`/api/bounties/${data._id}`, data);
  return response.data;
}

export async function removeBounty(id) {
  try {
    const response = await axios.delete(`/api/bounties/${id}`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function reInviteBounty(id, data) {
  try {
    const response = await axios.post(`/api/bounties/${id}/re-invite`, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function reInviteGithub(id, data) {
  try {
    const response = await axios.post(
      `/api/bounties/${id}/re-invite-github`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getBountyCandidates(bountyId) {
  const response = await axios.get(`/api/bounties/${bountyId}/all-candidates`);

  return response.data;
}

export async function getBountySummary(bountyId) {
  const response = await axios.get(`/api/bounties/${bountyId}/summary`);

  return response.data;
}

export async function getBountiesSummary() {
  const response = await axios.get(`/api/bounties/summary`);
  return response.data;
}

export async function getBountyDiversity(bountyId, type) {
  const response = await axios.get(
    `/api/bounties/${bountyId}/diversity/${type}`
  );

  return response.data;
}

export async function getBountyBiasScore(bountyId) {
  const response = await axios.get(`/api/bounties/${bountyId}/biasScore`);

  return response.data;
}

export async function rejectBountyAssessment(id) {
  const response = await axios.post(`/api/bounty-assessment/${id}/reject`);

  return response.data;
}

export async function approveBountyAssessment(id) {
  const response = await axios.post(`/api/bounty-assessment/${id}/approve`);

  return response.data;
}
export async function exceptBountyAssessment(id) {
  const response = await axios.post(`/api/bounty-assessment/${id}/except`);

  return response.data;
}

export async function acceptBountyAssessment(id) {
  const response = await axios.post(`/api/bounty-assessment/${id}/accept`);

  return response.data;
}

export async function reviewBountyCandidate(bountyId, candidateId, review) {
  let data = { bountyId: bountyId, candidateId: candidateId, review: review };
  const response = await axios.post(`/api/bounties/review`, data);

  return response.data;
}

export async function getAllBountyCandidates(
  companyId,
  { gigId = '', search = undefined }
) {
  let response = [];
  if (!companyId) {
    response = await axios.get(
      `/api/bounty-candidate/get-all-bounty-candidates?gigId=${gigId}&searchPhrase=${search}`
    );
  } else {
    response = await axios.get(
      `/api/v1/opportunities/${gigId}/admin/${companyId}`
    );
  }

  return response.data;
}

export async function searchSkills(searchString) {
  const response = await axios.get('/api/skills?q=' + searchString);
  return response.data;
}

export async function verifyCoupon(code) {
  try {
    const response = await axios.post(`/api/bounties/verifyCoupon`, {
      code,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function activeCoupon(id, code) {
  try {
    const response = await axios.post(`/api/bounties/${id}/activeCoupon`, {
      code,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getAllBounties({ companyId = '', search = undefined }) {
  let response = undefined;
  if (!search) {
    response = await axios.get(`/api/bounties/get-all?companyId=${companyId}`);
  } else {
    response = await axios.get(
      `/api/bounties/get-all?companyId=${companyId}&searchPhrase=${search}`
    );
  }
  return response.data;
}

export async function rewardCandidate(id, bountyId, amount) {
  const response = await axios.post(
    `/api/bounty-assessment/${id}/reward/${bountyId}`,
    { amount: amount }
  );
  return response.data;
}

export async function issueCredentials(id) {
  const response = await axios.post(`/api/bounty-assessment/${id}/credentials`);
  return response.data;
}
export async function getAssessment(id) {
  const response = await axios.get(`/api/bounty-assessment/${id}`);
  return response.data;
}
export async function getBountyRewards(id) {
  const response = await axios.get(`/api/v1/bounties/${id}/rewards`);
  return response.data;
}
export async function closeCandidateApplications(id) {
  const response = await axios.put(`/api/v1/bounties/${id}/stop-applications`);
  return response.data;
}

export async function removeGigLogo(gigId) {
  const response = await axios.delete(`/api/bounties/${gigId}/logo`);
  return response.data;
}

export async function updateGigLogo(gigId, values) {
  const response = await axios.put(`/api/bounties/${gigId}/logo`, values, {
    withCredentials: true,
  });
  return response.data;
}

export async function activate(gigId) {
  const response = await axios.put(`/api/bounties/${gigId}/activate`);
  return response.data;
}

export async function deactivate(gigId) {
  const response = await axios.put(`/api/bounties/${gigId}/deactivate`);
  return response.data;
}
