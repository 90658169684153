import axios from 'axios';

export async function generateAdaBountyTx(
  rawUTxOs,
  changeAddress,
  lovelaceAmount,
  walletAddress
) {
  const response = await axios.post('/api/crypto/generate-bounty-ada-tx', {
    rawUTxOs,
    changeAddress,
    lovelaceAmount,
    walletAddress,
  });
  return response.data;
}

export async function sendAdaBounty(transaction, bountyId, vkeyWitness) {
  const response = await axios.post('/api/crypto/submit-bounty-ada-tx', {
    transaction,
    bountyId,
    vkeyWitness,
  });
  return response.data;
}

export async function generateCardanoTokenTx(
  rawUTxOs,
  changeAddress,
  asset,
  quantity,
  walletAddress
) {
  const response = await axios.post('/api/crypto/generate-bounty-token-tx', {
    rawUTxOs,
    changeAddress,
    asset,
    quantity,
    walletAddress,
  });
  return response.data;
}

export async function sendAdaTokenBounty(transaction, bountyId, vkeyWitness) {
  const response = await axios.post('/api/crypto/submit-bounty-token-tx', {
    transaction,
    bountyId,
    vkeyWitness,
  });
  return response.data;
}

export async function increaseAdaBountyApi(
  transaction,
  bountyId,
  increaseAmount,
  vkeyWitness
) {
  const response = await axios.post(
    '/api/crypto/submit-increase-bounty-ada-tx',
    {
      transaction,
      bountyId,
      increaseAmount,
      vkeyWitness,
    }
  );
  return response.data;
}

export async function sendSolanaBounty(transaction, bountyId) {
  const response = await axios.post('/api/crypto/create-solana-transaction', {
    transaction,
    bountyId,
  });
  return response.data;
}

export async function generateConsumeBountyCreditTx(
  consumeAmount,
  rawUtxos,
  walletInputAmount,
  projectOwnerAddress
) {
  const response = await axios.post(
    '/api/crypto/generate-consume-bounty-credit-tx',
    {
      consumeAmount,
      rawUtxos,
      walletInputAmount,
      projectOwnerAddress,
    }
  );
  return response.data;
}

export async function submitConsumeBountyCreditTx(
  transaction,
  bountyId,
  vKeyWitness,
  useBountyCreditAmount,
  projectOwnerAddress,
  isStaging
) {
  const response = await axios.post('/api/crypto/submit-bounty-credit-ada-tx', {
    transaction,
    bountyId,
    vKeyWitness,
    useBountyCreditAmount,
    projectOwnerAddress,
    isStaging,
  });
  return response.data;
}

export async function getSendAdaFee() {
  const response = await axios.get('/api/crypto/cardano-get-fee');
  return response.data;
}

export async function formatCardanoWalletInfo(
  rawUTxOs,
  changeAddress,
  isStaging
) {
  const response = await axios.post('/api/crypto/cardano-format-wallet-info', {
    rawUTxOs,
    changeAddress,
    isStaging,
  });
  return response.data;
}

export async function getSupportedCurrencies() {
  const response = await axios.get('/api/v1/crypto/');
  return response.data;
}

export async function getEstimateRange(fromCurrency, fromNetwork) {
  const response = await axios.get(
    `/api/v1/crypto/estimate-range?fromCurrency=${fromCurrency}&fromNetwork=${fromNetwork}`
  );
  return response.data;
}

export async function getEstimate(fromCurrency, fromNetwork, fromAmount) {
  const response = await axios.get(
    `/api/v1/crypto/estimate?fromCurrency=${fromCurrency}&fromNetwork=${fromNetwork}&fromAmount=${fromAmount}`
  );
  return response.data;
}

export async function getExchange(data) {
  const response = await axios.post(`/api/v1/crypto/exchange`, data);
  return response.data;
}
