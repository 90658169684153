import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import '../../css/discussion.css';
import { rewardCandidate } from '../../../api/bounties';
import { toast } from 'react-toastify';

const RewardCandidateModal = ({
  assessment,
  bounty,
  total,
  rewards,
  showModal,
  setShowModal,
}) => {
  const getTotalReward = (amount) => {
    if (total && total > 0) {
      return total + amount;
    } else {
      return amount;
    }
  };
  //event handlers
  const onClose = (e) => {
    setShowModal(false);
  };
  const onRewardCandidate = async (e) => {
    e.preventDefault();
    try {
      const totalRewards = getTotalReward(amount);
      if (totalRewards <= bounty.rewardAmount) {
        let response = await rewardCandidate(
          assessment._id,
          bounty._id,
          amount
        );
        toast(response);
      } else {
        toast(
          'Amount rewarded to candidates can not exceed total bounty reward'
        );
      }
    } catch (error) {
      toast('An error occured while rewarding candidate');
    }
    e.stopPropagation();
  };

  const onAmountChanged = (e) => {
    const calculated = remaining - parseFloat(e.target.value).toFixed(2);
    if (calculated > 0) {
      setAmount(calculated);
    }
  };

  //component state
  const [amount, setAmount] = useState(0.0);
  const [remaining] = useState(() => {
    let result = bounty.rewardAmount;
    if (total > 0) {
      result = bounty.rewardAmount - total;
    }
    return result;
  });

  //Component lifecycle events
  useEffect(() => {
    //TODO:Fill up remaining
  });
  return (
    <Modal show={showModal} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Reward Candidate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formAmount">
            <Form.Label>Reward Amount</Form.Label>
            <Form.Control
              id="txtRewardAmount"
              type="number"
              placeholder="0.00"
              onChange={onAmountChanged}
            />
            <Form.Text className="text-muted">
              {`Amount can not exceed ${bounty.rewardType}${remaining}`}
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onRewardCandidate}>
          Reward
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default RewardCandidateModal;
