import ChallengeTitleSection from './challengeTitleSection';
import { Container, Col, Row } from 'react-bootstrap';
import ChallengeResultSections from './challengeResultSections';

const ExpandedChallengeView = ({
  isPreffered,
  task,
  closeExpanded,
  goNext,
  goBack,
}) => {
  return (
    <Container className="expanded-challenge-container">
      <Row>
        <Col>
          <Row>
            <ChallengeTitleSection
              task={task}
              isPreffered={isPreffered}
              closeExpanded={closeExpanded}
            />
          </Row>
          <Row>
            <ChallengeResultSections
              task={task}
              goNext={goNext}
              goBack={goBack}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ExpandedChallengeView;
