import React, { useState } from 'react';
import JobStepOne from './components/jobStepOne';
import JobStepTwo from './components/jobStepTwo';
import JobStepThree from './components/jobStepThree';
import JobStepFour from './components/jobStepFour';
import JobStepFive from './components/jobStepFive';

import { JobProvider } from '../context/jobContext';

export const JobCreateSteps = ({
  job,
  closeJobModal,
  step,
  setStep,
  editSteps,
  cryptoPay,
  setCryptoPay,
}) => {
  // Proceed to next step
  const nextStep = () => {
    setStep((x) => x + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep((x) => x - 1);
  };

  const resetStep = async () => {
    setStep(1);
  };
  return (
    <JobProvider job={job}>
      {step === 1 && editSteps !== 4 && (
        <JobStepOne nextStep={nextStep} resetStep={resetStep} />
      )}
      {step === 2 && (
        <JobStepTwo
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
        />
      )}
      {step === 3 && (
        <JobStepThree
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
        />
      )}
      {/* editStep open the modal to step 4 to pay the unfunded */}
      {(step === 4 || editSteps === 4) && (
        <JobStepFour
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
          closeJobModal={closeJobModal}
          editSteps={editSteps}
          cryptoPay={cryptoPay}
          setCryptoPay={setCryptoPay}
        />
      )}
      {step === 5 && (
        <JobStepFive
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
          closeJobModal={closeJobModal}
        />
      )}
    </JobProvider>
  );
};
export default JobCreateSteps;
