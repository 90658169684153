import '../../shared/css/typography.css';

const AddCandidateFormHeader = () => {
  return (
    <div>
      <h3 className="formHeader">
        Here you can add a single candidate or upload a list of candidates to
        invite to your project and take the assessment
      </h3>
    </div>
  );
};

export default AddCandidateFormHeader;
