// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import HelpIcon from '../../../assets/icon/snapbrillia_help_icon.svg';
import '../../../shared/css/sharedStyles.css';
import '../css/greenhouseComponents.css';

const GreenhouseResume = () => {
  return (
    <div className="greenhouse-resume-component">
      <span className="greenhouse-resume-bold">
        <input
          type="checkbox"
          className=" greenhouse-resume-checkbox"
          id="attachResume"
        />
        <span className="greenhouse-resume-text-span">
          Attach Snapbrillia Resume
        </span>
        <img
          src={HelpIcon}
          alt="help-icon"
          className="greenhouse-fields-help-icon"
        />
      </span>
    </div>
  );
};

export default GreenhouseResume;
