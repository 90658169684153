export default function AddApplicantButton({ handleClick, style }) {
  return (
    <button
      onClick={handleClick}
      className="responsive-primary-btn"
      style={style}
    >
      Add Applicant
    </button>
  );
}
