// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import '../css/candidateFields.css';

export default function AddCandidateFields({
  candidateFields,
  setCandidateFields,
  formErrors,
}) {
  const handleChange = (e) => {
    e.preventDefault();
    setCandidateFields({ ...candidateFields, [e.target.name]: e.target.value });
  };

  const handleFullName = (e) => {
    setCandidateFields({ ...candidateFields, fullName: e.target.value });
  };

  return (
    <>
      <Row>
        <Col>
          <label className="form_label label_fields" htmlFor="fullName">
            Full Name <span className="required">*</span>
          </label>
          <input
            className="form_input input_fields"
            type="text"
            id="fullName"
            name="fullName"
            value={candidateFields?.fullName}
            onChange={(e) => handleFullName(e)}
          ></input>
          <span className="candidate-fields-errors">
            {formErrors?.fullName}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form_label label_fields" htmlFor="email">
            Email <span className="required">*</span>
          </label>
          <input
            className="form_input input_fields"
            id="email"
            name="email"
            value={candidateFields.email}
            onChange={(e) => handleChange(e)}
          ></input>
          <span className="candidate-fields-errors">{formErrors?.email}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form_label label_fields" htmlFor="linkedin">
            LinkedIn URL
          </label>
          <input
            className="form_input input_fields"
            id="linkedin"
            name="linkedin"
            value={candidateFields.linkedin}
            onChange={(e) => handleChange(e)}
          ></input>
          <span className="candidate-fields-errors">
            {formErrors?.linkedin}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form_label label_fields" htmlFor="github">
            Github URL
          </label>
          <input
            className="form_input input_fields"
            id="github"
            name="github"
            value={candidateFields.github}
            onChange={(e) => handleChange(e)}
          ></input>
          <span className="candidate-fields-errors">{formErrors?.github}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form_label label_fields" htmlFor="stackOverflow">
            StackOverflow URL
          </label>
          <input
            className="form_input input_fields"
            id="stackOverflow"
            name="stackOverflow"
            value={candidateFields.stackOverflow}
            onChange={(e) => handleChange(e)}
          ></input>
          <span className="candidate-fields-errors">
            {formErrors?.stackOverflow}
          </span>
        </Col>
      </Row>
    </>
  );
}
