import React, { useState } from 'react';
import SearchBar from './components/searchBar';
import './css/navBar.css';
import * as searchAPI from '../api/search';
const NavBar = () => {
  const [fetchedData, setFetchedData] = useState([]);

  const handleSearchProject = async (query) => {
    const project = await searchAPI.searchProject(query);
    return project;
  };

  // const handleSearch = async (event) => {
  //   const value = event.target.value.toLowerCase();
  //   if (value.trim() !== '') {
  //     const projects = await handleSearchProject(value);
  //     projects.map((project) => {
  //       project.link = `/project-page/${project._id}`;
  //       project.type = 'project';
  //     });
  //     const bounties = await searchAPI.searchBounty(value);
  //     bounties.map((bounty) => {
  //       bounty.link = `/bounties/${bounty._id}`;
  //       bounty.type = 'bounty';
  //     });
  //     const candidates = await searchAPI.searchBounty(value);
  //     candidates.map((candidate) => {
  //       // candidate.link = `/bounties/${candidate._id}`;
  //       candidate.type = 'candidate';
  //     });
  //     const bountyCandidates = await searchAPI.searchBounty(value);
  //     bountyCandidates.map((bounty) => {
  //       bounty.type = 'bounty candidate';
  //     });
  //     setFetchedData([...projects, ...bounties]);
  //   } else {
  //     setFetchedData([]);
  //   }
  // };

  return (
    <>
      <SearchBar />
    </>
  );
};

export default NavBar;
