import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { eeoFields } from './eeoField';
import { colors } from './colors';

export function EEOAreaChart({ chartData, pieChartData }) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const eeoData = eeoFields?.filter(
    (value) => value.name === pieChartData.category
  );
  const eeoLabel = eeoData[0]?.options?.map((values) => values.value);

  const selectedData = chartData?.counts?.filter(
    (value) => value.label === pieChartData.category
  );

  const dataSet = [];
  selectedData?.map((value) => {
    for (let index = 0; index < eeoLabel.length; index++) {
      for (const key in value.values) {
        if (key === eeoLabel[index]) {
          dataSet.push(value.values[key]);
        } else {
          dataSet.push(0);
        }
      }
    }
  });
  // console.log(dataSet);

  const data = {
    labels: eeoLabel,
    datasets: [
      {
        fill: true,
        label: '# participated candidate',
        data: dataSet,
        borderColor: colors.degree,
        backgroundColor: colors.gender,
      },
    ],
  };

  return <Doughnut data={data} />;
}
