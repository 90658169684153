import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/assessmentExplaination.css';
import '../../shared/css/typography.css';

// deleted props:{ handleRightArrow, handleLeftArrow }
const AssessmentFinished = () => {
  return (
    <Container className="assessment-right-component-container">
      <Row>
        <Col className="assessment-finished">
          <span className="h2">Thank You!</span>
          <p className="assessment-label-text">
            Your notes have been submitted and you may exist the assessment.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AssessmentFinished;
