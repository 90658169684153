import React from 'react';
import '../css/chat.css';
import {
  Col,
  Dropdown,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import moment from 'moment';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import getVideoCover from '../../utils/videoThumnails';

const ChatUserMessage = ({
  type,
  edit,
  editId,
  msg,
  canReply,
  setEdit,
  setEditId,
  replyMsg,
  setReply,
  msgEllipsis,
  setMessage,
  message,
  setReplyId,
  onEditMessage,
  image,
}) => {
  const messagesEndRef = useRef(null);
  const [show, setShow] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  const use = async () => {
    if (msg.messageType === 'video') {
      try {
        // get the frame at 1.5 seconds of the video file
        const cover = await getVideoCover(msg.message, 1.5);
        // print out the result image blob
        console.log(cover);
      } catch (ex) {
        console.log('ERROR: ', ex);
      }
    } else {
      return null;
    }
  };
  use();

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={
        <Popover id={`popover-positioned-top`}>
          <Popover.Header as="h3">{`Chat Options`}</Popover.Header>
          <Popover.Body>
            <ul
              style={{
                width: '100%',
              }}
            >
              <li
                onClick={() => {
                  setReply(true);
                  replyMsg(msg._id);
                  setReplyId(msg.recepient);
                }}
              >
                <Row>
                  <Col>
                    <i className="fa fa-reply"></i>
                  </Col>
                  <Col>
                    <strong>Reply</strong>
                  </Col>
                </Row>
              </li>
              {type === 'sender' && (
                <>
                  <li
                    onClick={() => {
                      setEdit(true);
                      setEditId(msg._id);
                    }}
                  >
                    <Row>
                      <Col>
                        <i className="fa fa-edit"></i>
                      </Col>
                      <Col>
                        <strong>Edit</strong>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col>
                        <i className="fa fa-edit"></i>
                      </Col>
                      <Col>
                        <strong>Delete</strong>
                      </Col>
                    </Row>
                  </li>
                </>
              )}
              {type === 'repaly' && (
                <li>
                  <Row>
                    <Col>
                      <i className="fa fa-edit"></i>
                    </Col>
                    <Col>
                      <strong>Mark</strong>
                    </Col>
                  </Row>
                </li>
              )}
            </ul>
          </Popover.Body>
        </Popover>
      }
    >
      {edit && editId === msg._id ? (
        <footer className="chatbox-popup__footer">
          <aside
            style={{
              flex: 10,
            }}
          >
            <textarea
              type="text"
              //contentEditable
              value={message || msg.message}
              onChange={(e) => {
                e.stopPropagation();
                setMessage(e.target.value);
              }}
              placeholder="Type your message here..."
              style={{
                minHeight: 40,
                maxHeight: 100,
              }}
            ></textarea>
          </aside>
          <aside
            style={{
              padding: 10,
            }}
          >
            <i className="fa fa-paperclip" aria-hidden="true"></i>
          </aside>
          <aside
            onClick={onEditMessage}
            style={{
              flex: 1,
              color: '#888',
              textAlign: 'center',
            }}
          >
            <i
              className="fa fa-paper-plane"
              aria-hidden="true"
              style={{
                fontSize: 25,
                color: '#3F3DC8',
              }}
            ></i>
          </aside>
        </footer>
      ) : (
        <li className={type} key={msg._id}>
          {msg.chatReply === true && (
            <div
              style={{
                width: '100%',
              }}
            >
              <p className="reply">
                {msg.chatReplyRecepient}
                <hr />
                {msgEllipsis(msg.chatReplyMessage, msg.message.length)}
              </p>
            </div>
          )}
          <p className="msg-page">
            {msg.messageType === 'video' ? (
              <img src={image} alt="new video" />
            ) : (
              <p>{msg.message}</p>
            )}
          </p>
          <span className="time">{moment(msg.updatedAt).format('LT')}</span>
          <div ref={messagesEndRef} />
        </li>
      )}
    </OverlayTrigger>
  );
};

export default ChatUserMessage;
