/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams, Link } from '@reach/router';
import * as companyAPI from '../../api/companies';
import Management from '../../assets/icon/snapbrillia_mentor_icon.svg';
import AllCandidatesTable from './allCandidatesTable';
import AllUsersTable from './allUsersTable';
import { Router } from '@reach/router';
import Upload from '../../shared/button/upload';
import AllOpportunitiesTable from './allOpportunitiesTable.jsx';
import ProfileImageModal from '../../shared/modals/profileImageModal';
import company from '../../assets/SnapBetaLogo2.svg';
import { useAuth } from '../../context/authContext';

const AllCompaniesTable = () => {
  const [companies, setCompanes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const inputPhoto = useRef();
  const companyId = useParams()?.companyId;
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { auth } = useAuth();

  const getCompnayData = async (search) => {
    setLoading(true);
    await companyAPI
      .getAllCompanies({ search })
      .then((response) => {
        setCompanes(response);
      })
      .catch((error) => {
        setCompanes([]);
      });

    setLoading(false);
  };

  const canUserUpdate = async () => {
    if (auth.user.companyRole !== 'owner' || !auth.user.admin) {
      toast.warning('You do not have permission!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return false;
    }
    return true;
  };

  const getTableDataUploadOnClickHandler = () => {
    setOpenEditModal(true);
  };

  const handleRemove = async () => {
    try {
      if (!canUserUpdate()) {
        return;
      }
      await companyAPI.removeCompanyLogo(auth.company._id);
    } catch (error) {}
    setCompanyLogo('');
  };

  const handleSave = async (fileName) => {
    try {
      // if (!canUserUpdate()) {
      //   return;
      // }
      console.log(companyInfo);
      console.log('here');
      await companyAPI.updateCompanyLogo(companyInfo, { logo: fileName });
    } catch (error) {}
  };

  const handleUpdateCompanyInfoAdmin = async (fileInfo) => {
    try {
      if (!canUserUpdate()) {
        console.log('here');
        return;
      }
      await companyAPI.updateCompanyLogo(companyInfo, {
        logo: fileInfo.url,
      });
      getCompnayData();
      toast.success('Your company information has been updated');
    } catch (error) {}
  };

  const createSuscriber = async (id) => {
    // create suscriber function to connect backend api
    await companyAPI
      .suscribeCompany(id)
      .then((data) => {
        getCompnayData();
        toast.success('Company has been made a suscriber');
      })
      .catch((error) => {
        toast.error('An error occurred');
      });
  };

  const createUnsuscriber = async (id) => {
    await companyAPI
      .unSuscribeCompany(id)
      .then((data) => {
        getCompnayData();
        toast.success('Company has been unsuscribed');
      })
      .catch((err) => {
        toast.error('An error occurred');
      });
  };

  useEffect(() => {
    getCompnayData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          row.companyLogo ? (
            <img
              src={row?.companyLogo ? row?.companyLogo : company}
              alt="company-logo"
              height="30"
            />
          ) : (
            ''
          ), //access nested data with dot notation
        header: 'Logo',
        size: 150,
      },
      {
        accessorFn: (row) => row?.companyName, //access nested data with dot notation
        header: 'Company',
        size: 150,
      },
      {
        accessorFn: (row) => (
          <>
            <Link to={`${row?._id}/opportunities`}>View</Link>
            <Link to={`${row?._id}/users`}>
              <img src={Management} alt="management-icon" className="m-2" />
            </Link>
          </>
        ),
        header: 'Name',
        size: 50,
      },
      {
        accessorFn: (row) => (
          <div>
            <Upload
              onClick={() => {
                getTableDataUploadOnClickHandler(row);
                setCompanyLogo(row?.companyLogo);
                setCompanyInfo(row?._id);
              }}
            />
          </div>
        ),
        header: 'Action',
        size: 100,
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={companies}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        enableRowActions
        renderRowActions={({ row }) =>
          !row.original?.subscribed ? (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                onClick={() => {
                  createSuscriber(row.original._id);
                }}
              >
                Make Suscriber
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                onClick={() => {
                  createUnsuscriber(row.original._id);
                }}
              >
                UnSuscribe
              </Button>
            </Box>
          )
        }
      />
      <ProfileImageModal
        show={openEditModal}
        closeModal={() => setOpenEditModal(false)}
        handleRemove={handleRemove}
        source={companyLogo ? companyLogo : company}
        handleSave={handleSave}
        setSource={setCompanyLogo}
        isCompany={true}
        handleUpdateCompanyInfoAdmin={handleUpdateCompanyInfoAdmin}
      />
    </>
  );
};

const AdminCompany = () => {
  return (
    <Router>
      <AllCompaniesTable path="/" />
      <AllOpportunitiesTable path="/:companyId/opportunities" />
      <AllUsersTable path="/:companyId/users" />
      <AllCandidatesTable path="/:companyId/opportunities/:gigId" />
    </Router>
  );
};

export default AdminCompany;
