import { Container, Row, Col } from 'react-bootstrap';
import '../../css/profileLayout.css';
import defaultProfile from '../../../assets/icon/snapbrillia_profile_personal_icon.svg';
// import StarIcon from '../../../assets/icon/snapbrillia_add_favorites_icon.svg';

function Profile({ candidate }) {
  return (
    <Container className="bg-light p-3 text-center">
      <Row>
        <Col xs={12}>
          <img
            src={defaultProfile}
            alt="profile"
            height={100}
            width={100}
            style={{ borderRadius: '50%' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col xs={12} className="primary profile-info-title text-center">
              {candidate.fullName}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
