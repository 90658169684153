import axios from 'axios';

export async function getAllCompanies({ search = '' }) {
  const response = await axios.get(
    `/api/companies/get-all?searchPhrase=${search}`
  );
  return response.data;
}

export async function getMember(companyId) {
  const response = await axios.get(`/api/companies/${companyId}/members`);
  return response.data;
}
export async function getCompany(companyId) {
  const response = await axios.get(`/api/companies/${companyId}`);
  return response.data;
}

export async function getPendingMember(companyId) {
  const response = await axios.get(`/api/companies/${companyId}/pendings`);
  return response.data;
}

export async function removeCompanyLogo(companyId) {
  const response = await axios.delete(`/api/companies/${companyId}/logo`);
  return response.data;
}

export async function updateCompanyLogo(companyId, values) {
  const response = await axios.put(`/api/companies/${companyId}/logo`, values, {
    withCredentials: true,
  });
  return response.data;
}

export async function suscribeCompany(companyId) {
  const response = await axios.post(`/api/companies/${companyId}/subscribe`);
  return response.data;
}

export async function unSuscribeCompany(companyId) {
  const response = await axios.post(`/api/companies/${companyId}/unsubscribe`);
  return response.data;
}

export async function countCompanyOpportunities(companyId) {
  const response = await axios.get(
    `/api/companies/${companyId}/opportunities-count`
  );
  return response.data;
}

export async function checkIfUserExist(email) {
  const response = await axios.get(
    `/api/user/check-user-exist-by-email/${email}`
  );
  return response.data;
}

export async function checkIsMemberInvite(email, companyId) {
  const response = await axios.get(
    `/api/user/check-is-member-invited-by-email/${companyId}/${email}/`
  );
  return response.data;
}

export async function updateCompanyInfo(companyId, values) {
  const response = await axios.put(
    `/api/companies/${companyId}/update`,
    values,
    {
      withCredentials: true,
    }
  );
  return response.data;
}
