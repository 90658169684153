import React, { useState } from 'react';

//COMPONENTS
import DropDown from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import { Container, Col, Row } from 'react-bootstrap';
import CandidateListItem from './candidateListItem';
import ShrinkedCandidateListItem from './shrinkedCandidateListItem';
import ExpandedCandidateFullView from './expandedCandidateFullView';
//CSS

import '../css/candidateList.css';

const Candidatelist = ({
  candidates,
  tasks,
  sendReviewCandidate,

  handleClickInvite,
}) => {
  const [candidatesCopy, setCandidatesCopy] = useState(candidates);

  const [show, setShow] = useState(false);
  const [expandedCandidate, setExpandedCandidate] = useState(null);

  const handleFilterCandidates = (target) => {
    if (target && target !== 'All') {
      setCandidatesCopy(
        candidates.filter(
          (candidate) =>
            candidate.assessment.status.toLowerCase() === target.toLowerCase()
        )
      );
    } else {
      setCandidatesCopy(candidates);
    }
  };

  const handleExpandCandidate = (e, candidate) => {
    const { tagName } = e.target;
    if (
      !['new', 'invited', 'accepted', 'expired'].includes(
        candidate.assessment.status
      )
    ) {
      if (tagName !== 'INPUT' && tagName !== 'BUTTON') {
        if (expandedCandidate) {
          return setExpandedCandidate(candidate);
        }
        setExpandedCandidate(candidate);
        setShow(!show);
      }
    }
  };

  return (
    <Container fluid className="p-0">
      <Col>
        <Row>
          <Col>
            <label className="label bold d-block fs-6 mb-2">Filter:</label>
            <select
              name="filtered-options"
              className="candidate-list-filter-input mb-2"
              onChange={(e) => handleFilterCandidates(e.target.value)}
              defaultValue="All"
            >
              <option disabled value="">
                In-progress, Accepted...
              </option>
              <option value="All">All</option>
              <option value="new">New</option>
              <option value="invited">Invited</option>
              <option value="accepted">Accepted</option>
              <option value="in-progress">In-progress</option>
              <option value="completed">Completed</option>
              <option value="reviewed">Reviewed</option>
              <option value="expired">Missed</option>
            </select>
            <img
              src={DropDown}
              alt="dropdown-icon"
              style={{ margin: '0 0 7px -20px', cursor: 'pointer' }}
            />
          </Col>
          <Col className="text-end">
            <label className="label bold d-block fs-6 mb-2">&nbsp; </label>
          </Col>
        </Row>

        <span className="filter-input-divider"></span>
        <Row>
          <Col className="candidates-filtered-list scrollable-candidate-list">
            {candidatesCopy.map((candidate, index) => (
              <Row
                className="mx-0 pointer"
                key={index}
                onClick={(e) => handleExpandCandidate(e, candidate)}
              >
                {show ? (
                  <ShrinkedCandidateListItem
                    active={
                      !!(
                        expandedCandidate &&
                        candidate.firstName === expandedCandidate.firstName
                      )
                    }
                    candidate={candidate}
                    handleExpandCandidate={handleExpandCandidate}
                  />
                ) : (
                  <CandidateListItem
                    candidate={candidate}
                    handleClickInvite={handleClickInvite}
                    handleExpandCandidate={handleExpandCandidate}
                  />
                )}
              </Row>
            ))}
          </Col>
          {show && (
            <Col md={show && 8} xxl={show && 9}>
              <ExpandedCandidateFullView
                candidate={expandedCandidate}
                tasks={tasks}
                setShow={setShow}
                setExpandedCandidate={setExpandedCandidate}
                sendReviewCandidate={sendReviewCandidate}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default Candidatelist;
