/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SnapbrilliaLogo from '../assets/SnapBetaLogo2.svg';
import SnapbrilliaIllustration from '../assets/illustrations/snapbrillia_setup_3_illustration.png';
import InvitesSent from './components/invitesSent';
import BountyContinue from './components/bountyContinue';
import './css/assessmentBountiesPage.css';

import { useOnBoarding } from '../context/onboardingContext';
import BountyCreateSteps from '../bountyCreateSteps/bountyCreateSteps';

const AssessmentBountiesPage = () => {
  const [linkTo, setLinkTo] = useState('');
  const [step, setStep] = useState(1);

  const {
    url,

    assessmentType,
    setAssessmentType,
    onboardingType,
  } = useOnBoarding();

  useEffect(() => {
    if (!onboardingType) {
      setAssessmentType('');
      setLinkTo('');
      return;
    }
    if (url) {
      if (assessmentType === 'AI Generated') {
        setLinkTo('/setup-ai-project');
      }
      if (assessmentType === 'Manual') {
        setLinkTo('/setup-manual-project');
      }
      if (assessmentType === 'Private' || assessmentType === 'Public') {
        setLinkTo('/setup-bounties');
      }
    }
  }, [onboardingType, assessmentType, url]);

  return (
    <Container className="assessment-bounties-main">
      <Row>
        <Col
          className="assessment-bounties-logo"
          style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}
        >
          <img src={SnapbrilliaLogo} alt="logo" />
        </Col>
      </Row>
      <Row className="assessment-bounties-content">
        <Col xs={12} md={6} className="position-relative ">
          <Col className="assessment-bounties-inviteSent">
            {step === 1 && <InvitesSent />}
            {step === 2 && <BountyContinue />}
            {step === 3 && <BountyContinue />}
            {step === 4 && <BountyContinue />}
          </Col>

          <div className="assessment-bounties-page-img-div">
            <img
              src={SnapbrilliaIllustration}
              alt="illustration"
              style={{ float: 'right' }}
              height={'80%'}
              width={'80%'}
            />
          </div>
        </Col>
        <Col xs={12} md={6} className="assessment-bounties-right-col">
          <BountyCreateSteps step={step} setStep={setStep} />
        </Col>
      </Row>
    </Container>
  );
};

export default AssessmentBountiesPage;
