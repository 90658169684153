import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckIcon from '../../assets//icon/snapbrillia_check_icon.svg';
import AboutRepo from './aboutRepo';

const BountiesIntroduction = ({ repo }) => {
  return (
    <Row>
      <Col className="d-flex align-items-center col-12">
        <img src={CheckIcon} alt="check-icon" />
        <span className="primary fs-3 medium ms-2">
          Perfect! Let&apos;s Finalize your bounty
        </span>
      </Col>
      <Col>
        <span className="primary fs-4 medium d-block my-4">
          Now let&apos;s go trough and create your first Bounty in order to add
          your Candidates
        </span>
      </Col>
      <AboutRepo repo={repo} />
    </Row>
  );
};

export default BountiesIntroduction;
