import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import '../../shared/css/buttonStyles.css';
import '../../shared/css/sharedStyles.css';
import './css/greenhouseLinkModal.css';

export default function GreenhouseLinkModal() {
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => setShowModal(false);

  return (
    <Modal
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      show={showModal}
    >
      <Container className="greenhouse-link-container">
        <Row>
          <Col>
            <Modal.Header
              className="greenhouse-link-modal-header-section"
              closeButton
            >
              <span className="greenhouse-link-modal-header-title">
                Link Your Greenhouse Harvest Account
              </span>
            </Modal.Header>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="greenhouse-link-modal-body">
              Select your Greenhouse Harvest account to link with your hireEZ
              account:
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="greenhouse-link-modal-select">
              <select className="label_fields input_fields">
                <option value="alice" selected>
                  Alice
                </option>
                <option value="bob">Bob</option>
                <option value="chris">Chris</option>
              </select>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={9}></Col>
          <Col xs={3}>
            <button className="greenhouse-link-modal-button"> Link</button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
