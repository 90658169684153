import axios from 'axios';

// gigs getting, creation and editing apis
export async function createOpportunity(data) {
  const response = await axios.post(`/api/v1/opportunities`, data);
  return response.data;
}

export async function getOpportunity(id) {
  const response = await axios.get(`/api/v1/opportunities/${id}`);
  return response.data;
}

export async function editOpportunity(id, data) {
  const response = await axios.put(`/api/v1/opportunities/${id}`, data);
  return response.data;
}

export async function createStripeIntents(bountyId, data) {
  try {
    const response = await axios.post(
      `/api/v1/opportunities/order/${bountyId}/stripe`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function confirmStripeIntents(intent) {
  try {
    const response = await axios.put(
      '/api/v1/opportunities/order/stripe/confirm',
      {
        intent,
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function confirmStripeIntentsTopUp(data) {
  try {
    const response = await axios.put(
      '/api/v1/opportunities/order/stripe/confirm',
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function createCryptoOrder(id, data) {
  try {
    const response = await axios.post(
      `/api/v1/opportunities/order/${id}/crypto`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getDashboardOpportunities() {
  const response = await axios.get(`/api/v1/opportunities?isDashboard=true`);
  return response.data;
}
export async function getOpportunities({
  page = 1,
  limit = 10,
  filter = 'All',
}) {
  const response = await axios.get(
    `/api/v1/opportunities?page=${page}&limit=${limit}&status=${filter}`
  );
  return response.data;
}

export async function getAssessments(id) {
  try {
    const response = await axios.get(`/api/v1/opportunities/${id}/assessments`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function inviteCandidate(id, data) {
  try {
    const response = await axios.post(
      `/api/v1/opportunities/${id}/invite`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

// opportunity Review

export async function opportunityReview(id, data) {
  try {
    const response = await axios.put(
      `/api/v1/opportunities/${id}/review`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getEmail(id, status) {
  const response = await axios.get(
    `/api/v1/opportunities/${id}/email/${status}`
  );
  return response.data;
}

// pay using coupon
export async function invoicedCoupon(id, data) {
  const response = await axios.post(
    `/api/v1/opportunities/order/${id}/invoiced`,
    data
  );
  return response.data;
}

// admin api call

export async function getCandidateByCompany(id, companyId) {
  const response = await axios.put(
    `/api/v1/opportunities/${id}/admin/${companyId}`
  );
  return response.data;
}

export async function activeAndDeactivate(id, data) {
  const response = await axios.put(`/api/v1/opportunities/${id}/admin`, data);
  return response.data;
}

export async function reInvite(id) {
  const response = await axios.put(
    `/api/v1/opportunities/${id}/admin-reinvite`
  );
  return response.data;
}

export async function getOpportunitiesAdmin(
  id,
  { page = 1, limit = 10, filter }
) {
  let response = [];
  if (!id) {
    response = await axios.get(
      `/api/v1/opportunities/admin?page=${page}&limit=${limit}&filter=${filter}`
    );
  } else {
    response = await axios.get(
      `/api/v1/opportunities/${id}/admin?page=${page}&limit=${limit}&filter=${filter}`
    );
  }

  return response.data;
}

// export async function getOpportunitiesByCompnay(
//   id,
//   { page = 1, limit = 10, filter }
// ) {
//   const
//   return response.data;
// }

// EEO for analytics

export async function getEEOOpportunity(id) {
  const response = await axios.get(`/api/eeo/${id}/opportunity`);
  return response.data;
}

export async function getEEOCompany(id) {
  const response = await axios.get(`/api/eeo/${id}/company`);
  return response.data;
}
