import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Logo from '../assets/SnapBetaLogo2.svg';
import NextButton from '../shared/button/next';
import Retakebutton from '../shared/button/retake';
import Skipbutton from '../shared/button/skip';
import Submitbutton from '../shared/button/submit';
import '../shared/css/bgColors.css';
import './css/eeoFormStyles.css';
import './css/eeoSubmitStyles.css';
import { eeoFields } from './eeoFields';
import EeoFormDisclaimer from './eeoFormDisclaimer';
import EeoFormDisclaimerModal from './eeoFormDisclaimerModal';
import EeoFormHeader from './eeoFormHeader';
import EeoFormStepper from './eeoFormStepper';
import EeoFormSubTitle from './eeoFormSubTitle';
import EeoSubmit from './eeoSubmit';
import 'query-string';
import { navigate } from '@reach/router';
import _ from 'lodash';

export default function EeoForm({ workSampleProjectId, candidateId }) {
  const [question, setQuestion] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [results, setResults] = useState({
    gender: [],
    ethnicity: [],
    age: '',
    veteranStatus: '',
    impairmentStatus: '',
    neurodivergence: '',
    degree: '',
    assessmentGitpodUrl: [],
    is_returning: null,
  });
  const [selected, setSelected] = useState({
    gender: [],
    ethnicity: [],
    age: '',
    veteranStatus: '',
    impairmentStatus: '',
    neurodivergence: '',
    degree: '',
  });
  const [radioChecked, setRadioChecked] = useState(false);
  const [radioNumber, setRadioNumber] = useState();
  const [gitpodUrl, setGitpodUrl] = useState();
  const [gitpodInstance, setGitpodInstance] = useState({
    workSampleProjectId: '',
    candidateId: '',
    repoName: '',
    firstName: '',
    lastName: '',
    branchName: '',
    fromBranch: 'main',
  });
  const [assessmentId, setAssessmentId] = useState();
  const [candidateInfo, setCandidateInfo] = useState();

  const handleNext = () => {
    if (question <= 6) {
      setQuestion(question + 1);
      setRadioChecked(false);
    } else if (question > 6) {
      // sessionStorage.setItem('isRetaking', false);
      return;
    }
  };

  //handles a single input, function will be used if the question > 2
  const handleString = (value, field, label, index) => {
    setResults({ ...results, [field]: value });
    setSelected({ ...selected, [field]: label });
    setRadioChecked(index);
  };

  //handles multiple inputs, function will be used if question < 2
  const handleArray = (value, field, label) => {
    let arr = results[field];
    let labelArr = selected[field];
    let testdata = _.uniq(labelArr, 'field');
    if (arr.indexOf(value) === -1) {
      arr.push(value);
      testdata.push(label);
    } else {
      arr.splice(arr.indexOf(value), 1);
      testdata.splice(testdata.indexOf(label), 1);
    }
    setResults({ ...results, [field]: arr });
    setSelected({ ...selected, [field]: testdata });
  };

  // fetch candidate and worksample project and set state
  const saveGitpodData = async () => {
    // get github repo name
    const getWorkSample = await axios
      .get('/api/work-sample-project/one/' + workSampleProjectId)
      .then((res) => {
        const repoName = res.data.githubRepoName;
        return repoName;
      });

    // get first name and last name of candidate
    const candidateName = await axios
      .get('/api/candidate/' + candidateId)
      .then((res) => {
        const candidateInfo = {
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          assessmentGitpodUrl: res.data.assessmentGitpodUrl,
        };

        if (res.data.assessmentGitpodUrl) {
          setCandidateInfo(candidateInfo.assessmentGitpodUrl);
        } else {
          setCandidateInfo([]);
        }

        // if eeo form has already been filled out, set results
        if (
          res.data.assessmentGitpodUrl &&
          res.data.assessmentGitpodUrl.length > 0
        ) {
          setResults({
            ...results,
            gender: res.data.gender,
            ethnicity: res.data.ethnicity,
            age: res.data.age,
            veteranStatus: res.data.veteranStatus,
            impairmentStatus: res.data.impairmentStatus,
            neurodivergence: res.data.neurodivergence,
            degree: res.data.education[0].degree[0],
            is_returning: res.data.is_returning,
            assessmentGitpodUrl: res.data.assessmentGitpodUrl,
          });
        }

        return candidateInfo;
      });

    // return assessmentId from WSP ID and Candidate ID
    await axios
      .get(`/api/assessment/get-one-by-candidate-id/${candidateId}`)
      .then((res) => {
        const allAssessments = res.data;
        setAssessmentId(allAssessments._id);
      });

    // update gitpodInstance to hold all relevant data
    setGitpodInstance({
      ...gitpodInstance,
      repoName: getWorkSample,
      workSampleProjectId: workSampleProjectId,
      firstName: candidateName.firstName,
      lastName: candidateName.lastName,
      candidateId: candidateId,
    });
  };

  const saveInstanceName = () => {
    const buildUrl = `${gitpodInstance?.candidateId}-${gitpodInstance.firstName}-${gitpodInstance.lastName}-${gitpodInstance.workSampleProjectId}`;
    setGitpodInstance({ ...gitpodInstance, branchName: buildUrl });
  };

  const handleSubmit = async () => {
    // if the candidate does not have a gitpod branch, one is created for them
    // if there is an existing url for their personalized assessment ide branch in the Candidate Mongo document, simply navigate to it
    if (candidateInfo.length === 0) {
      // createGitpodUrl();
      newGitpodBranch();
    } else {
      // find the link corresponding to the assessment the candidate is currently taking)
      const filteredAssessment = candidateInfo.filter((item) => {
        return item.assessmentId === assessmentId;
      });
      if (filteredAssessment.length === 0) {
        createGitpodUrl();
      } else {
        updateCandidateReturning();
        navigate(filteredAssessment[0].gitpodUrl);
      }
    }
  };

  // grab wsp id and candidate info from url on load
  useEffect(() => {
    saveGitpodData();
  }, []);
  // create branch name when gitpodinstance state changes
  useEffect(() => {
    saveInstanceName();
  }, [gitpodInstance.repoName]);
  // load the results state with the gitpodUrl on which happens on submit button click
  useEffect(() => {
    if (gitpodUrl) {
      const existingArray = candidateInfo;
      const newObject = {
        gitpodUrl: gitpodUrl,
        assessmentId: assessmentId,
        candidateId: candidateId,
        workSampleProjectId: workSampleProjectId,
      };
      existingArray.push(newObject);
      setResults({
        ...results,
        assessmentGitpodUrl: candidateInfo,
      });
    }
  }, [gitpodUrl]);

  useEffect(() => {
    if (question < 7) {
      setResults({ ...results, is_returning: false });
    }
  }, [question]);

  // set form stepper to question 6 if is_returning is true to show summary page
  useEffect(() => {
    if (results.is_returning === true) {
      setQuestion(7);
    }
  }, [results.is_returning]);

  // set selected based on what returns from database on load if this is not the first time eeo is being filled out
  useEffect(() => {
    if (results.is_returning === true) {
      let selectedGender = results.gender;
      let selectedEthnicity = results.ethnicity;
      let selectedAge = results.age;
      let selectedVeteran = results.veteranStatus;
      let selectedImpairment = results.impairmentStatus;
      let selectedNeuro = results.neurodivergence;
      let selectedDegree = results.degree;

      setSelected({
        gender: selectedGender,
        ethnicity: selectedEthnicity,
        age: selectedAge,
        veteranStatus: selectedVeteran,
        impairmentStatus: selectedImpairment,
        neurodivergence: selectedNeuro,
        degree: selectedDegree,
      });
    }
  }, [results]);

  // update candidate document with all eeo data including gitpodUrl
  const updateCandidate = async (url) => {
    const existingArray = candidateInfo;
    const newObject = {
      gitpodUrl: url,
      assessmentId: assessmentId,
      candidateId: candidateId,
      workSampleProjectId: workSampleProjectId,
    };
    existingArray.push(newObject);

    const update = await axios({
      method: 'put',
      url: '/api/candidate/update-eeo',
      data: {
        assessmentId: assessmentId,
        ...results,
        assessmentGitpodUrl: existingArray,
        is_returning: true,
      },
    });
    return update;
  };

  const updateCandidateReturning = async () => {
    const update = await axios({
      method: 'put',
      url: '/api/candidate/update-eeo',
      data: {
        assessmentId: assessmentId,
        ...results,
        is_returning: true,
      },
    });
    return update;
  };

  const createGitpodUrl = async () => {
    // create a new branch for the candidate and return the url
    const data = {
      name: gitpodInstance.branchName,
      repoName: gitpodInstance.repoName,
      from: gitpodInstance.fromBranch,
    };
    await axios({
      method: 'post',
      url: 'https://githubapp.snapbrillia.com/branches?token=secret_key_to_access_api',
      data: data,
    }).then((res) => {
      return setGitpodUrl(res.data.gitpod_url);
    });
  };

  const newGitpodBranch = async () => {
    const data = {
      name: gitpodInstance.branchName,
      repoName: gitpodInstance.repoName,
      from: gitpodInstance.fromBranch,
    };
    const response = await axios({
      method: 'post',
      url: 'https://githubapp.snapbrillia.com/branches?token=secret_key_to_access_api',
      data: data,
    }).then((res) => {
      setGitpodUrl(res.data.gitpod_url);
      updateCandidate(res.data.gitpod_url);
      navigate(res.data.gitpod_url);
    });

    return response;
  };

  const handleRetake = async () => {
    // update DB to have is_returning = false when retake button is pressed so that questions show instead of summary
    setQuestion(0);
  };

  const changeQuestion = (index) => {
    //takes the index of the question and moves it to that index
    setQuestion(index);
    //makes radio inputs false
    setRadioChecked(false);
  };

  // link radio checked index with the eeo options for each question. e.g. if yes is selected, set radioChecked to 0
  useEffect(() => {
    setRadioNumber();

    if (question > 6) {
      return;
    }

    if (
      results[eeoFields[question]?.name][0] === '<18' ||
      results[eeoFields[question]?.name] === '<18' ||
      results[eeoFields[question]?.name][0] === 'veteran' ||
      results[eeoFields[question]?.name] === 'veteran' ||
      results[eeoFields[question]?.name][0] === 'impaired' ||
      results[eeoFields[question]?.name] === 'impaired' ||
      results[eeoFields[question]?.name][0] === 'divergent' ||
      results[eeoFields[question]?.name] === 'divergent' ||
      results[eeoFields[question]?.name] === 'high-school'
    ) {
      setRadioNumber(0);
    }

    if (
      results[eeoFields[question]?.name][0] === '18-39' ||
      results[eeoFields[question]?.name] === '18-39' ||
      results[eeoFields[question]?.name][0] === 'non-veteran' ||
      results[eeoFields[question]?.name] === 'non-veteran' ||
      results[eeoFields[question]?.name][0] === 'non-impaired' ||
      results[eeoFields[question]?.name] === 'non-impaired' ||
      results[eeoFields[question]?.name][0] === 'nonDivergent' ||
      results[eeoFields[question]?.name] === 'nonDivergent' ||
      results[eeoFields[question]?.name] === 'trade-certification'
    ) {
      setRadioNumber(1);
    }

    if (
      results[eeoFields[question]?.name][0] === '40-54' ||
      results[eeoFields[question]?.name] === '40-54' ||
      results[eeoFields[question]?.name][0] === 'dnd' ||
      results[eeoFields[question]?.name] === 'dnd' ||
      results[eeoFields[question]?.name] === 'bootcamp'
    ) {
      setRadioNumber(2);
    }

    if (
      results[eeoFields[question]?.name][0] === '55<' ||
      results[eeoFields[question]?.name] === '55<' ||
      results[eeoFields[question]?.name] === 'associate'
    ) {
      setRadioNumber(3);
    }

    if (results[eeoFields[question]?.name] === 'bachelor') {
      setRadioNumber(4);
    }

    if (results[eeoFields[question]?.name] === 'master') {
      setRadioNumber(5);
    }

    if (results[eeoFields[question]?.name] === 'phd') {
      setRadioNumber(6);
    }

    if (
      (question === 2 && results[eeoFields[question]?.name][0] === 'dnd') ||
      results[eeoFields[question]?.name] === 'dnd'
    ) {
      setRadioNumber(4);
    }

    if (question === 6 && results[eeoFields[question]?.name] === 'dnd') {
      setRadioNumber(7);
    }

    if (
      (results[eeoFields[question]?.name] === 'dnd' && question === 3) ||
      (results[eeoFields[question]?.name] === 'dnd' && question === 4) ||
      (results[eeoFields[question]?.name] === 'dnd' && question === 5)
    ) {
      setRadioNumber(2);
    }
  }, [question, radioChecked]);

  const checkForSummary = () => {
    // if candidate already has gitpod url in database, show summary of previous eeo data
    if (results.is_returning === true) {
      return <EeoSubmit results={results} selected={selected} />;
    } else {
      return question <= 6 ? (
        <form className="eeo-options">
          <h3 className="eeo-question-title eeo-question semi-bold">
            {eeoFields[question].label}
          </h3>
          {/* will render the eeoform with it's fields if the question is <= 6, otherwise it will render the eeosubmit, passing the selected answers down to it */}
          {eeoFields[question]['options'].map((option, i) => {
            {
              /* changes it's checked state based on whether the question is < 2, there can be multiple check states for the first two questions */
            }
            return (
              <div className="eeo-option" key={i}>
                <input
                  type={question < 2 ? 'checkbox' : 'radio'}
                  name={`eeo-${eeoFields[question].name}`}
                  {...(question < 2
                    ? {
                        checked: results[eeoFields[question].name].includes(
                          option.value
                        ),
                      }
                    : {
                        checked: i === radioNumber,
                      })}
                  id={`eeo-fields${i}`}
                  value={''}
                  onChange={
                    question < 2
                      ? () =>
                          handleArray(
                            option.value,
                            eeoFields[question].name,
                            option.label
                          )
                      : () =>
                          handleString(
                            option.value,
                            eeoFields[question].name,
                            option.label,
                            i
                          )
                  }
                />
                <label className="h4 semi-bold eeo-label semi-bold">
                  {option.label}
                </label>
              </div>
            );
          })}
        </form>
      ) : (
        <EeoSubmit results={results} selected={selected} />
      );
    }
  };

  const checkForSkipButton = () => {
    // show submit button only on summary page
    if (question > 6 || results.is_returning === true) {
      return (
        <>
          <div onClick={handleRetake}>
            <Retakebutton />
          </div>{' '}
          <div onClick={handleSubmit}>
            <Submitbutton />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="eeo-form-btn" onClick={handleNext}>
            <Skipbutton />
          </div>
          <div className="eeo-form-btn" onClick={handleNext}>
            <NextButton />
          </div>
        </>
      );
    }
  };

  return (
    <Container className="primarybg" fluid>
      <Col md={12} style={{ float: 'left' }}>
        <img
          src={Logo}
          alt="logo"
          style={{ width: '193px' }}
          className="d-flex m-2 justify-content-start"
        />
        <EeoFormHeader />
        <EeoFormSubTitle />
        <EeoFormStepper
          question={question}
          changeQuestion={changeQuestion}
          className="d-none d-sm-block"
        />
        <Row
          className={question <= 6 ? 'eeo-form-section' : 'eeo-submit-section'}
        >
          <Col md={12}>{checkForSummary()}</Col>
        </Row>
        <Row className="fixed-bottom  eeo-form-bottom-row">
          <Col lg={9} className="d-none d-lg-block">
            <EeoFormDisclaimer />
          </Col>
          <Col lg={3} className=" d-lg-none eeo-form-disclaimer-modal-col">
            <EeoFormDisclaimerModal
              show={showDisclaimer}
              setShowDisclaimer={setShowDisclaimer}
            />
            <button
              onClick={() => setShowDisclaimer(true)}
              className="eeo-form-disclaimer-btn "
            >
              Disclaimer *
            </button>
          </Col>

          <Col
            lg={3}
            className="d-flex align-items-end eeo-form-btns justify-content-end"
          >
            {checkForSkipButton()}
          </Col>
        </Row>
      </Col>
    </Container>
  );
}
