import Modal from 'react-bootstrap/Modal';
import GeneralPolicyText from '../../policyPage/components/generalPolicyText';

export default function PrivacyPolicyModal({ show, closeModal }) {
  return (
    <Modal show={show} fullscreen={true} onHide={closeModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <GeneralPolicyText />
      </Modal.Body>
    </Modal>
  );
}
