import Modal from 'react-bootstrap/Modal';
import '../shared/css/sharedStyles.css';
import React, { useState } from 'react';
import RequestDemoHeader from './component/requestDemoHeader';
import RequestDemoForm from './component/requestDemoForm';
import RequestDemoCalendy from './component/requestDemoCalendy';
import { toast } from 'react-toastify';
export default function RequestDemoModal({ show, closeModal }) {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    email: '',
    fullName: '',
  });
  const handleScheduled = () => {
    toast.success(
      'A calendar invitation has been sent to your email. See you later.'
    );
    closeModal();
  };
  return (
    <Modal
      show={show}
      onHide={closeModal}
      enforceFocus={true}
      centered
      size="lg"
      style={{ zIndex: 9999 }}
    >
      <Modal.Header className="modal-header-removal-hr" closeButton>
        <RequestDemoHeader step={step} setStep={setStep} />
      </Modal.Header>
      <Modal.Body>
        {step == 0 ? (
          <RequestDemoForm
            step={step}
            setStep={setStep}
            setData={setValues}
            closeModal={closeModal}
          />
        ) : (
          <RequestDemoCalendy
            values={values}
            handleScheduled={handleScheduled}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
