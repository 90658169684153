import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Form, Row, Col } from 'react-bootstrap';
import '../css/addProjectFields.css';
//COMPONENTS
import Header from './header';
import { ReactComponent as ExpansionIcon } from '../../assets/icon/snapbrillia_description_drag.svg';
import { ReactComponent as DropDownIcon } from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import { SUPPORT_LANGUAGES } from '../../shared/constants';

const AddProjectFields = ({
  setShowModal,
  WSPFields,
  setWSPFields,
  handleChange,
  handleSubmit,
  isSubmit,
  repoInfo,
}) => {
  const [skills, setSkills] = useState([]);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (isSubmit) {
      checkData();
    }
  }, [isSubmit]);
  useEffect(() => {
    checkSelectLanguage();
  }, [repoInfo]);
  const checkData = async () => {
    const errors = await validate();
    if (Object.keys(errors).length < 1) {
      handleSubmit();
      return;
    }
    setErrors(errors);
    return;
  };
  const validate = () => {
    const {
      workSampleProjectName,
      jobTitle,
      experience,
      // mandatoryskillsTested,
    } = WSPFields;
    let errors = {};
    if (!workSampleProjectName || workSampleProjectName.length < 1) {
      errors.workSampleProjectName = 'Project name is required';
    }
    if (!jobTitle || jobTitle.length < 1) {
      errors.jobTitle = 'Project Title is required';
    }
    if (!experience || experience.length < 1) {
      errors.experience = 'Year of experience is required';
    }
    // if (!mandatoryskillsTested || mandatoryskillsTested.length < 1) {
    //   errors.mandatoryskillsTested = 'Skill is required';
    // }
    return errors;
  };

  const checkSelectLanguage = () => {
    let languages = [];
    let selectSkill = [];
    if (repoInfo.language) {
      if (typeof repoInfo.language === 'string') {
        languages.push(repoInfo.language);
      } else {
        languages = Object.keys(repoInfo.language);
      }

      const newSkills = [];
      languages.map((skill) => {
        newSkills.push({ name: skill, active: true });
        selectSkill.push(skill);
      });
      setWSPFields({ ...WSPFields, mandatoryskillsTested: selectSkill });
      setSkills(newSkills);
    } else {
      const newSkills = [];
      SUPPORT_LANGUAGES.map((skill) => {
        newSkills.push({ name: skill, active: false });
        selectSkill.push(skill);
      });
      setSkills(newSkills);
    }
  };

  const addSkill = (skill) => {
    let newSkills = [];
    let selectedSkill = [];
    skills.map((item) => {
      if (item.name === skill.name) {
        newSkills.push({ name: item.name, active: !skill.active });
        if (!skill.active) {
          selectedSkill.push(item.name);
        }
      } else {
        newSkills.push({ name: item.name, active: item.active });
        if (item.active) {
          selectedSkill.push(item.name);
        }
      }
    });
    setWSPFields({ ...WSPFields, mandatoryskillsTested: selectedSkill });
    setSkills(newSkills);
  };

  const handleLineBreak = (e) => {
    let value = e.target.value;

    if (value.length === 0) {
      e.target.rows = 1;
    }
    if (e.key === 'Enter' && e.target.rows < 12) {
      e.target.rows += 1;
    }

    if (e.keyCode === 8 && e.target.rows > 1) {
      if (e.target.value[e.target.selectionStart - 1] === '\n') {
        e.target.rows -= 1;
      }
    }
  };

  return (
    <Container className="add-project-form">
      <Header
        message={
          'Tell us a little bit about the position you are looking to fill.'
        }
        setShowModal={setShowModal}
      />
      <Form.Group>
        <Form.Label htmlFor="project">
          Project Name <span className="required">*</span>
          {errors.workSampleProjectName && (
            <p className="bounties-form-error">
              {errors.workSampleProjectName}
            </p>
          )}
        </Form.Label>
        <input
          type="text"
          id="project"
          name="workSampleProjectName"
          value={WSPFields.workSampleProjectName}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="job-title">
          Project Title <span className="required">*</span>
          {errors.jobTitle && (
            <p className="bounties-form-error">{errors.jobTitle}</p>
          )}
        </Form.Label>
        <input
          type="text"
          id="job-title"
          name="jobTitle"
          value={WSPFields.jobTitle}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="position-relative">
        <Form.Label htmlFor="job-desc">Project Description </Form.Label>
        <textarea
          id="job-desc"
          rows="1"
          onKeyDown={handleLineBreak}
          type="text"
          name="jobDescription"
          value={WSPFields.jobDescription}
          onChange={handleChange}
        ></textarea>
        <ExpansionIcon className="expand-icon-add-project" />
      </Form.Group>
      <Form.Group className="form-group-select">
        <Form.Label htmlFor="years-of-experience">
          How many years of Experience would you like the candidate to have?{' '}
          <span className="required">*</span>
          {errors.experience && (
            <p className="bounties-form-error">{errors.experience}</p>
          )}
        </Form.Label>

        <select
          id="years-of-experience"
          name="experience"
          value={WSPFields.experience}
          onChange={handleChange}
        >
          <option defaultValue={''}></option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option>3+</option>
        </select>

        <DropDownIcon className="dropdown-icon-add-project" />
      </Form.Group>
      <Form.Group>
        <Row>
          <Col className="add-project-labels">
            <label>
              Select the Skills you want the candidate to have already?{' '}
              <span className="required">*</span>
              {errors.mandatoryskillsTested && (
                <p className="bounties-form-error">
                  {errors.mandatoryskillsTested}
                </p>
              )}
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="add-project-labels">
            <div className="add-project-skills-wrapper">
              {skills.map((skill) => (
                <span
                  key={skill.key}
                  id={skill.name}
                  className={`add-project-skill ${
                    skill.active ? 'active-skill' : ''
                  }`}
                  onClick={() => {
                    addSkill(skill);
                  }}
                >
                  {skill.name}
                </span>
              ))}
            </div>
          </Col>
        </Row>
      </Form.Group>
    </Container>
  );
};
export default AddProjectFields;
