import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../../shared/css/bgColors.css';

//COMPONENTS
import TimeLineOverviewChart from '../../charts/components/timeLineOverviewChart';
import DisparityRate from '../../collectionBounties/components/disparityRate';
import ResponseRate from '../../collectionBounties/components/responseRate';
import Analytics from '../../collectionBounties/components/analytics';

const ProjectPageOverview = ({ candidates, workSampleProject }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <TimeLineOverviewChart
            workSampleProject={workSampleProject}
            candidates={candidates}
          />
        </Col>
      </Row>
      <Row className="mt-3 mx-2">
        <Col xs={12} md={4} lg={4}>
          <Row
            className="primarybg"
            style={{
              borderRadius: '10px',
              marginTop: '10px',
              marginRight: '10px',
              padding: '10px',
            }}
          >
            <Col>
              <DisparityRate
                WSPId={workSampleProject._id}
                disparity={workSampleProject.disparity}
              />
            </Col>
          </Row>
          <Row
            className="primarybg my-3"
            style={{
              borderRadius: '10px',
              marginTop: '10px',
              marginRight: '10px',
              padding: '10px',
            }}
          >
            <Col>
              <ResponseRate />
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          md={8}
          lg={8}
          className="primarybg"
          style={{
            borderRadius: '10px',
            margin: '10px 0',
            padding: '10px',
          }}
        >
          <Analytics />
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectPageOverview;
