import axios from 'axios';

export async function editMember(memberId, values) {
  const response = await axios.put(`/api/member/${memberId}/edit`, values);
  return response.data;
}

export async function removeMember(
  memberId,
  assessmentId,
  type = 'assessment'
) {
  const values = {
    memberId: memberId,
    assessmentId: assessmentId,
    type: type,
  };
  const response = await axios.post(`/api/member/remove-members`, values);
  return response.data;
}

export async function getMember(memberId) {
  const response = await axios.get(`/api/user/get-member/${memberId}`);
  return response.data.member;
}

export async function confirmMember(memberId) {
  // values is an array of objects matching the pendingUser requirements
  const response = await axios.post(`/api/member/confirm`, {
    memberId: memberId,
  });
  return response.data;
}

export async function inviteMember(companyId, values, isReinvite) {
  // values is an array of objects matching the pendingUser requirements
  const response = await axios.post(
    `/api/companies/${companyId}/invite-members?isReinvite=${
      isReinvite ? 1 : 0
    }`,
    values
  );
  return response.data;
}

export async function mergeCompany(companyId, email) {
  try {
    const response = await axios.post(`/api/companies/${companyId}/merge`, {
      email,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
