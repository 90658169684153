import React, { useEffect } from 'react';
import * as d3 from 'd3';

function PieChart(props) {
  const { data, outerRadius, innerRadius } = props;
  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  };
  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;
  useEffect(() => {
    drawChart();
  }, [data]);

  function drawChart() {
    const colorScale = d3
      .scaleOrdinal()
      .domain(['Fail', 'Success'])
      .range(['#FFFFFF', '#336CDA']);
    // Remove the old svg
    d3.select('#pie-container').select('svg').remove();
    // Create new svg
    const svg = d3
      .select('#pie-container')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);
    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
    const pieGenerator = d3
      .pie()
      .padAngle(0)
      .value((d) => d.value);
    var defs = svg.append('defs');
    var filter = defs
      .append('filter')
      .attr('id', 'drop-shadow')
      .attr('height', '130%');
    filter
      .append('feDropShadow')
      .attr('stdDeviation', 5)
      .attr('flood-color', '#808080')
      .attr('result', 'blur');
    filter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 0)
      .attr('dy', 0)
      .attr('result', 'offsetBlur');
    var feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    const arc = svg.selectAll().data(pieGenerator(data)).enter();
    arc
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (_, i) => colorScale(i))
      .style('filter', 'url(#drop-shadow)');
    arc
      .append('text')
      .style('fill', 'black')
      .text(
        (
          (data.find((data) => data.label === 'Success').value /
            (data.find((data) => data.label === 'Success').value +
              data.find((data) => data.label === 'Fail').value)) *
          100
        ).toString() + '%'
      )
      .attr('font-weight', 700)
      .attr('x', -16)
      .attr('y', 6);
  }

  return <div id="pie-container" />;
}

export default PieChart;
