import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CommunityImg from '../../assets/icon/community_icon.svg';
import CheckDot from '../../assets/icon/check_dot.svg';

//css
import '../css/subscription_card.css';

function Community() {
  return (
    <Container>
      <div className="card-container">
        <Row>
          <Col>
            <h2 className="card-title mt-3">Community</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <img className="community-img" src={CommunityImg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="sub-heading mt-2">Free</h1>
          </Col>
        </Row>
        <Row class="row mt-4">
          <Col xs={2}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-3">
              Limited public source bounties (500,000$ per year)
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="subscribed-txt">Subscribed</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Community;
