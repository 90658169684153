import React from 'react';
import '../css/bountyContinue.css';
import '../../shared/css/typography.css';

const BountyContinue = () => {
  return (
    <div className="assessment-bounties-continue-container">
      <h1 className="h1">Continuing with the bounty creation...</h1>
    </div>
  );
};

export default BountyContinue;
