import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';

import { useTable, useRowState, useSortBy, useFilters } from 'react-table';
import Select, { components } from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';
import { areEqual, FixedSizeList } from 'react-window';
import EditProjectModal from '../../projectModal/editProjectModal';
import * as projectAPI from '../../api/projects';
import SortUpSVG from '../../assets/icon/snapbrillia_sort_up_selected.svg';
import SortDownSVG from '../../assets/icon/snapbrillia_sort_down_selected.svg';
import UnselectedSVG from '../../assets/icon/snapbrillia_sort_unselected.svg';
import DropDown from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import { navigate } from '@reach/router';
import AddProjectButton from '../../shared/button/addProject';
import ProjectModal from '../../projectModal/projectModal';
import '../css/projectsTable.css';
import '../../shared/css/sharedStyles.css';

const RenderRow = ({
  rows,
  prepareRow,
  dataState,

  edit,
  open,
  touched,
  counter,
  fetchMoreData,
}) => {
  const scrollRef = useRef();
  const listRef = useRef();
  useEffect(() => {
    if (listRef) {
      open || touched || edit
        ? listRef.current.scrollTo(scrollRef.current.getScrollTop())
        : listRef.current.scrollTo(0);
    }
  }, [edit, open, touched, counter /* , currentPos */]);
  useEffect(() => {
    const index = Math.max(
      Math.floor(dataState.length * 0.8),
      dataState.length - 7
    );
    if (rows.length > 14) {
      listRef.current.scrollToItem(index, 'center');
    }
  }, [dataState]);
  const infiniteScroll = () => {
    const { top } = scrollRef.current.getValues();

    if (top > 0.99) {
      fetchMoreData();
    }
  };
  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) {
      return null;
    }
    if (filteredRefs.length === 0) {
      return filteredRefs[0];
    }
    return (inst) => {
      for (const ref of filteredRefs) {
        if (typeof ref === 'function') {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };

  // eslint-disable-next-line react/display-name
  const CustomScrollbars = React.memo(
    ({ onScroll, forwardedRef, children }) => {
      const refSetter = useCallback((scrollbarsRef) => {
        if (scrollbarsRef) {
          forwardedRef(scrollbarsRef.view);
        } else {
          forwardedRef(null);
        }
      }, []);

      return (
        <Scrollbars
          ref={mergeRefs(refSetter, scrollRef)}
          onScroll={onScroll}
          onScrollStop={infiniteScroll}
          autoHide
          autoHeightMax={'68vh'}
          autoHideDuration={1500}
          renderThumbVertical={() => (
            <div className="bounty-table-thumb-vertical" />
          )}
          style={{
            width: '96%',
            position: 'relative',
            height: '68vh',
            zIndex: 0,
          }}
        >
          {children}
        </Scrollbars>
      );
    },
    areEqual
  );
  // eslint-disable-next-line react/display-name
  const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => {
    return <CustomScrollbars {...props} forwardedRef={ref} />;
  });

  return (
    <FixedSizeList
      className="fixed-size-list"
      useIsScrolling
      width={960}
      height={800}
      itemCount={rows.length}
      onItemsRendered={() => {}}
      ref={listRef}
      itemData={dataState}
      itemSize={110}
      outerElementType={CustomScrollbarsVirtualList}
    >
      {({ index, style }) => {
        const row = rows[index];
        prepareRow(row);
        return (
          <tr key={index} style={style} {...row.getRowProps(style)}>
            {row.cells.map((cell, index) => {
              return index === 0 ? (
                <td
                  className="jobs"
                  {...cell.getCellProps()}
                  style={{
                    verticalAlign: 'middle',
                    paddingRight: '20px',
                    borderBottom: 'groove 2px #ececec',
                    background: '',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    width: '200px',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              ) : index === 6 ? (
                <td
                  className="data-cells"
                  {...cell.getCellProps()}
                  style={{
                    fontWeight: 'bold',
                    width: '150px',
                    borderBottom: 'groove 2px #ececec',
                    background: '',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              ) : (
                <td
                  className="data-cells"
                  {...cell.getCellProps()}
                  style={{
                    padding: '40px',
                    fontWeight: '500',
                    color: '#808080',
                    borderBottom: 'groove 2px #ececec',
                    background: '',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      }}
    </FixedSizeList>
  );
};

const ProjectsTable = ({ collapsed }) => {
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);

  const [counter, setCounter] = useState(0);
  const [curCol, setCurCol] = useState();
  const [dropdownSort, setDropdownSort] = useState('Alphabetical');
  const [open, setOpen] = useState([-1, -1, -1]);

  const [dataState, setDataState] = useState([]);
  const [nextPage, setNextPage] = useState(false);
  const [projects, setProjects] = useState([]);
  const [editProject, setEditProject] = useState();

  const filter = useRef();
  const active = useRef();
  const sort = useRef();

  const tdCell = useRef();

  const handleDeleteProject = async (workSampleProject) => {
    try {
      await projectAPI.deleteProject(workSampleProject._id);
      const reloadProjects = await projects.filter(
        (item) => item._id !== workSampleProject._id
      );
      setProjects(reloadProjects);
      toast.success('Successful!');
      setEdit(false);
    } catch (error) {}
  };
  const handleEditProject = async (workSampleProject) => {
    try {
      const response = await projectAPI.editProject(workSampleProject);
      const reloadProjects = await projects.map((item) => {
        if (item._id === workSampleProject._id) {
          return response;
        } else {
          return item;
        }
      });
      setProjects(reloadProjects);
      toast.success('Successful!');
    } catch (error) {}
  };

  const getProjectsData = async (page = 0) => {
    const { docs: projectsData, nextPage } = await projectAPI.getAllProjects({
      page: page,
    });
    if (nextPage) {
      setNextPage(nextPage);
    } else {
      setNextPage(false);
    }
    setProjects([...projects, ...projectsData]);
  };

  const addNewProject = async (project) => {
    setProjects([...projects, project]);
  };

  const handleClose = async () => {
    setShow(false);
  };

  useEffect(() => {
    getProjectsData();
  }, []);

  useEffect(() => {
    const tableData = projects.map((e) => ({
      col0: (
        <div onClick={() => navigate(`/project-page/${e._id}`)}>
          {e.name}
          <div className="positions">
            {new Date(e.createdAt).toLocaleString('en-US', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </div>
        </div>
      ),
      col1: e.new || 0,
      col2: e.invited || 0,
      col3: e.inProgress || 0,
      col4: e.reviewed || 0,
      col5: e.completed || 0,
      col6: (
        <div
          onClick={() => {
            setEdit(true);
            setEditProject(e);
          }}
          style={{
            color: '#808080',
            textDecoration: 'underline',
            fontWeight: '400',
            cursor: 'pointer',
          }}
        >
          Edit Project
        </div>
      ),
    }));
    setDataState(tableData);
  }, [projects]);

  const data = useMemo(() => [...dataState], [dataState]);

  const sortFn = useCallback(
    (a, b) => {
      return a.original.col0.props.children[0].toUpperCase() <
        b.original.col0.props.children[0].toUpperCase()
        ? -1
        : 1;
    },
    [dataState]
  );

  const filterFn = useCallback(
    (rows, colId, value) => {
      return value === 'Public' ||
        value === 'Private' ||
        value === 'Draft' ||
        value === 'Open' ||
        value === 'Closed'
        ? rows.filter((row) => {
            return row.original.col0.props.children[1].props.children === value;
          })
        : rows.filter((row) => {
            return (
              row.original.col0.props.children[2].props.children[1].props
                .children === value
            );
          });
    },
    [dataState]
  );

  const fetchMoreData = async () => {
    if (nextPage) {
      await getProjectsData(nextPage);
    }
    return;
  };

  const counterTrigger = (column, setSortBy) => {
    if (column.Header === curCol) {
      if ((counter + 1) % 3 === 0) {
        column.isSorted = false;
        column.isSortedDesc = undefined;
        setCounter(0);

        setSortBy([{ id: 'col0', desc: dropdownSort === 'Alphabetical(Z-A)' }]);
      } else {
        column.isSorted = true;
        column.isSortedDesc = counter === 0;
        setSortBy([{ id: column.id, desc: counter === 0 }]);
        setCounter(counter + 1);
      }
    } else {
      column.isSorted = true;
      column.isSortedDesc = true;
      setCurCol(column.Header);
      setCounter(1);
      setSortBy([{ id: column.id, desc: true }]);
    }
  };

  const onSorting = (e, column) => {
    // let x = column.getSortByToggleProps();
    if (e.value === 'alphabetical') {
      setDropdownSort('Alphabetical');
      setCurCol();
      column.isSorted = true;
      column.isSortedDesc = false;
    } else {
      setDropdownSort('Alphabetical(Z-A)');
      setCurCol();
      column.isSorted = true;
      column.isSortedDesc = true;
    }

    column.toggleSortBy(column.isSortedDesc);
  };

  const onFiltering = useCallback(
    (e, headerGroups) => {
      const { setFilter } = headerGroups[0].headers[0];
      // let val = typeof e === 'string' ? e : e.target.value;
      setFilter(e.value === 'all' ? undefined : e.label);
    },
    [dataState]
  );

  const columns = useMemo(
    () => [
      {
        Header: '',

        accessor: 'col0', // accessor is the "key" in the data
        sortType: sortFn,
        // Filter: onFiltering,
        sticky: 'left',
        filter: filterFn,
      },
      {
        Header: 'New',
        accessor: 'col1',
      },
      {
        Header: 'Invited',
        accessor: 'col2',
      },
      {
        Header: 'In-Progress',
        accessor: 'col3',
      },
      {
        Header: 'Reviewed',
        accessor: 'col4',
      },
      {
        Header: 'Completed',
        accessor: 'col5',
      },
      {
        Header: '',
        accessor: 'col6',
        sticky: 'right',
      },
    ],
    []
  );

  const options = [
    [
      { value: 'all', label: 'All' },
      { value: 'public', label: 'Public' },
      { value: 'private', label: 'Private' },
      {
        value: 'draft',
        label: 'Draft',
      },
      { value: 'open', label: 'Open' },
      { value: 'closed', label: 'Closed' },
    ],
    [{ value: 'all', label: 'All' }],
    [
      { value: 'alphabetical', label: 'Alphabetical' },
      { value: 'reversed', label: 'Alphabetical(Z-A)' },
    ],
  ];

  const DropdownIndicator = (state) => {
    return (
      <components.DropdownIndicator {...state}>
        <div
          onTouchStart={() => {
            // e.stopPropagation();
            // e.preventDefault();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <img src={DropDown} alt="dropdown-icon" />
        </div>
      </components.DropdownIndicator>
    );
  };
  const customStyles = (id) => {
    return {
      option: (provided, state) => {
        return {
          color: state.isSelected || state.isFocused ? '#2d2b6f' : '#808080',
          padding: 10,
          fontWeight: 500,
          cursor: 'pointer',
        };
      },
      control: () => {
        return {
          borderWidth: 0,
          alignItems: 'center',
          borderRadius: 6,
          backgroundColor: '#f4f4f4',
          height: '30px',
          display: 'flex',
          // flexWrap: 'wrap',
          color: '#2d2b6f',
          width: id === 2 ? '150px' : null,
          // maxWidth: '150px',
          // minWidth: id === 2 ? '100%' : null,
          zIndex: 999,
          cursor: 'pointer',
        };
      },
      singleValue: (provided) => {
        return {
          ...provided,
          color: '#2d2b6f',
          fontWeight: 500,
          zIndex: 999,
          // width: id === 2 ? '150px' : '70%',
        };
      },
      menu: (provided, state) => {
        return {
          position: 'absolute',
          width: id === 2 ? '150px' : '100%',
          maxWidth: '150px',
          color: state.selectProps.menuColor,
          backgroundColor: '#fff',
          height: open[id] === 1 ? state.options.length * 41 : '0px',
          boxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
          borderRadius: 4,
          transition: 'all 0.8s ease-in-out',
          opacity: open[id] === 1 ? 1 : 0,
          visibility: open[id] === 1 ? 'visible' : 'hidden',
          zIndex: 9999,
          overflow: 'hidden',
        };
      },
      dropdownIndicator: () => {
        return {
          position: 'relative',
          right: '95%',
          animation:
            open[id] === 1
              ? 'bounty-table-rotation 0.8s'
              : open[id] === 0
              ? 'bounty-table-recover 0.8s'
              : null,

          transform: open[id] === 1 ? 'rotate(180deg)' : null,
          top: open[id] === 1 ? '3px' : null,
        };
      },
    };
  };

  const {
    getTableProps,

    headerGroups,

    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'col0', desc: false }] },
      autoResetSortBy: false,
    },

    useFilters,
    useSortBy,
    useRowState
    // useSticky
  );
  const infiniteScroll = () => {};

  let handleCnt = 0;
  const [touched, setTouched] = useState([0, 0, 0]);

  const filterComponent = useMemo(() => {
    return (
      <div
        style={{
          position: 'relative',
          cursor: 'pointer',
          maxWidth: '150px',
        }}
        onTouchStart={() => {
          handleCnt++;
          setOpen([open[0] === 1 ? 0 : 1, -1, -1]);
          setTouched([1, 0, 0]);
          filter.current.focus();
        }}
        onClick={() => {
          if (!touched[0]) {
            handleCnt++;
            setOpen([open[0] === 1 ? 0 : 1, -1, -1]);
            filter.current.focus();
          }
        }}
      >
        <Select
          ref={filter}
          styles={customStyles(0)}
          isSearchable={false}
          onBlur={() => {
            if (handleCnt > 0) {
              handleCnt = 0;
              return;
            }
            if (open[1] === 1 || open[2] === 1) {
              return;
            }
            if (open[0] !== 0) {
              setOpen([open[0] === 1 ? 0 : open[0], -1, -1]);
            }
          }}
          onChange={(e) => {
            onFiltering(e, headerGroups);
          }}
          options={options[0]}
          menuIsOpen
          defaultValue={{ value: 'all', label: 'All' }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          className="bounty-type-select-box"
        ></Select>
      </div>
    );
  }, [open]);

  const lastActiveComponent = useMemo(() => {
    return (
      <div
        style={{
          position: 'relative',
          cursor: 'pointer',
          maxWidth: '150px',
        }}
        className="sort_label"
        onTouchStart={() => {
          handleCnt++;
          setOpen([open[0] === 1 ? 0 : -1, open[1] === 1 ? 0 : 1, -1]);
          setTouched([0, 1, 0]);
          active.current.focus();
        }}
        onClick={() => {
          if (!touched[1]) {
            handleCnt++;
            setOpen([open[0] === 1 ? 0 : -1, open[1] === 1 ? 0 : 1, -1]);
            active.current.focus();
          }
        }}
      >
        <Select
          ref={active}
          styles={customStyles(1)}
          isSearchable={false}
          onBlur={() => {
            if (handleCnt > 0) {
              handleCnt = 0;
              return;
            }
            if (open[0] === 1 || open[2] === 1) {
              return;
            }

            if (open[1] !== 0) {
              setOpen([-1, open[1] === 1 ? 0 : open[1], -1]);
            }
          }}
          onChange={() => {
            // onFiltering(e, headerGroups);
          }}
          options={options[1]}
          menuIsOpen
          defaultValue={{ value: 'all', label: 'All' }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          className="bounty-type-select-box"
        ></Select>
      </div>
    );
  }, [open]);

  const sortComponent = useMemo(() => {
    return (
      <div
        style={{
          position: 'relative',
          maxWidth: '150px',
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
          className=""
        >
          Sort:
        </div>
        <div
          style={{ position: 'relative', marginRight: '900px' }}
          onTouchStart={() => {
            handleCnt++;
            setOpen([-1, -1, open[2] === 1 ? 0 : 1]);
            setTouched([0, 0, 1]);
            sort.current.focus();
          }}
          onClick={() => {
            if (!touched[2]) {
              handleCnt++;
              setOpen([-1, -1, open[2] === 1 ? 0 : 1]);
              sort.current.focus();
            }
          }}
        >
          <Select
            ref={sort}
            styles={customStyles(2)}
            isSearchable={false}
            onBlur={() => {
              if (handleCnt > 0) {
                handleCnt = 0;
                return;
              }
              if (open[2] !== 0) {
                setOpen([-1, -1, open[2] === 1 ? 0 : open[2]]);
              }
            }}
            onChange={(e) => onSorting(e, headerGroups[0].headers[0])}
            options={options[2]}
            menuIsOpen
            defaultValue={{ value: 'alphabetical', label: 'Alphabetical' }}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            className="bounty-type-select-box"
          ></Select>
        </div>
      </div>
    );
  }, [open]);

  useEffect(() => {
    onFiltering(filter.current.getValue()[0], headerGroups);
    if (!tdCell.current) {
      return;
    }
  }, []);

  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) {
      return null;
    }
    if (filteredRefs.length === 0) {
      return filteredRefs[0];
    }
    return (inst) => {
      for (const ref of filteredRefs) {
        if (typeof ref === 'function') {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };

  // const [scrollHeight, setScrollHeight] = useState(null);
  const scrollRef = useRef();

  let itemStatusMap = {};
  // const listRef = React.createRef();

  const loadMoreItems = (startIndex, stopIndex) => {
    return new Promise((resolve) =>
      setTimeout(() => {
        for (let index = startIndex; index <= stopIndex; index++) {
          itemStatusMap[index] = 2;
        }
        setDataState(dataState.concat(dataState.slice(0, 7)));
        resolve();
      }, 2500)
    );
  };
  const isItemLoaded = (index) =>
    data[index] !== null && index < dataState.length;

  const RowComponent = useMemo(
    () => (
      <RenderRow
        rows={rows}
        prepareRow={prepareRow}
        tdCell={tdCell}
        scrollRef={scrollRef}
        infiniteScroll={infiniteScroll}
        dataState={dataState}
        setDataState={setDataState}
        collpased={collapsed}
        edit={edit}
        open={open}
        touched={touched}
        counter={counter}
        fetchMoreData={fetchMoreData}
      />
    ),
    [dataState, rows]
  );

  return (
    <div className="bounty-table-container">
      {edit && (
        <EditProjectModal
          openEditModal={edit}
          setOpenEditModal={setEdit}
          workSampleProject={editProject}
          handleDeleteProject={handleDeleteProject}
          handleEditProject={handleEditProject}
        />
      )}
      {show && (
        <ProjectModal
          setShow={setShow}
          show={show}
          handleClose={handleClose}
          addNewProject={addNewProject}
        />
      )}
      <Row className="mb-4 mb-sm-1">
        <Col>
          <Row className="text-nowrap">
            <Col className="sort_label">
              <div>{sortComponent}</div>
            </Col>
            <Col className="sort_label">
              <div className="">Filter:</div>
              {filterComponent}
            </Col>
            <Col className="sort_label">
              <div className="">Last Active:</div>
              <div style={{ position: 'relative' }}>{lastActiveComponent}</div>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            position: 'relative',
            top: '13px',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '4%',
          }}
        >
          <div className="add-bounty-shrink">
            <AddProjectButton showCreateProject={() => setShow(true)} />
          </div>
        </Col>
      </Row>

      <Scrollbars
        className="gx-layout-sider-scrollbar"
        autoHeight
        autoHeightMax={'80vh'}
        autoHideDuration={1500}
        renderThumbVertical={() => <div style={{ display: 'none' }} />}
        renderThumbHorizontal={(props) => (
          <div {...props} className="bounty-table-thumb-horizontal" />
        )}
        style={{
          width: '100%',
          position: 'relative',
          marginRight: '17px',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <table
          className="pb-2"
          style={{ borderCollapse: 'separate', borderSpacing: 0, width: '96%' }}
          {...getTableProps()}
        >
          <thead
            className="bg-white position-sticky top-0"
            style={{ zIndex: '999' }}
          >
            <tr {...headerGroups[0].getHeaderGroupProps()}>
              {headerGroups[0].headers.map((column, index) => {
                if (index === 0 || index === 6) {
                  return (
                    <th
                      key={index}
                      className="sort_label border-bottom border-dark pt-3 mt-2"
                      {...column.getHeaderProps()}
                    ></th>
                  );
                }
                return (
                  <th
                    key={index}
                    {...column.getHeaderProps()}
                    className="border-bottom border-dark bounty-table-th-middle pt-2 mt-2"
                    style={{
                      textAlign: 'center',
                      color: '#2d2b6f',
                      fontWeight: '500',
                      verticalAlign: 'bottom',
                      cursor: 'pointer',
                    }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    <span>
                      {column.Header === curCol ? (
                        counter === 1 ? (
                          <span className="sort">
                            <img src={SortDownSVG} alt="sort-down-icon" />
                          </span>
                        ) : counter === 2 ? (
                          <span className="sort">
                            <img src={SortUpSVG} alt="sort-up-icon" />
                          </span>
                        ) : (
                          <span className="sort">
                            <img src={UnselectedSVG} alt="unselected-icon" />
                          </span>
                        )
                      ) : (
                        <span className="sort">
                          <img src={UnselectedSVG} alt="unselected-icon" />
                        </span>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="position-relative">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        style={{
                          textAlign: i === 0 ? '' : 'center',
                          fontWeight: '500',
                          verticalAlign: 'middle',
                          borderBottom: 'groove 2px #ececec',
                          padding: '1rem',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Scrollbars>
    </div>
  );
};

export default ProjectsTable;
