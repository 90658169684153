import React, { createContext, useState, useEffect } from 'react';
import Dashboard from '../dashboard';
import { useAuth } from '../../context/authContext';
import * as BountyApi from '../../api/bounties';
import * as ProjectApi from '../../api/projects';
import * as CandidateApi from '../../api/candidates';
import { getDashboardOpportunities } from '../../api/opportunities';

export const CompaniesContext = createContext();

// props: companyId
const AllDashboardBackEnd = () => {
  const { auth } = useAuth();
  const [workSampleProjects, setWorkSampleProjects] = useState([]);
  const [bounties, setBounties] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [status] = useState([]);

  const loadDashboard = async () => {
    const data = await ProjectApi.getDashboardAssessment();
    setWorkSampleProjects(data);

    const { docs: bountiesData } = await getDashboardOpportunities({});
    setBounties(bountiesData);

    const candidatesData = await CandidateApi.getAllCandidates();
    setCandidates(candidatesData);
  };

  useEffect(() => {
    loadDashboard();
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        workSampleProjects,
        setWorkSampleProjects,
        status,
        candidates,
        bounties,
        setBounties,
      }}
    >
      <Dashboard />
    </CompaniesContext.Provider>
  );
};

export default AllDashboardBackEnd;
