import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { Link } from '@reach/router';

import ProfileSideMenu from '../shared/menus/profileSideMenu';
import PersonalProfilePhoto from './components/personalProfilePhoto';
import PersonalInfoFields from './components/personalInfoFields';
import CompanyUsers from './components/companyUsers';
import TwoFactorSetup from './components/twoFactorSetup';
import ChangeEmail from './components/changeEmail';
import ProfileSignedInDevices from '../projectPage/components/profileSignedInDevices';
import ProfileAccessibilityText from '../projectPage/components/profileAccessibilityText';
import Savebutton from '../shared/button/save';
import UserManagement from '../admin/userManagement';
import GrowthSubscriptionModal from '../subscription/components/growth';
import Enterprise from '../subscription/components/enterprise';
import Community from '../subscription/components/community';
import SalesView from '../sales/salesView';
import LogEntriesTable from '../admin/components/logsViewer';
import { ReactComponent as Bars } from '../assets/icon/snapbrillia_bars_icon.svg';
import { ReactComponent as SnapbrilliaLogo } from '../assets/snapbrillia_logo.svg';
import { ReactComponent as Close } from '../assets/icon/snapbrillia_close_icon.svg';
import { ReactComponent as Illustration } from '../assets/illustrations/snapbrillia_accessibility_illustration.svg';
// import { ReactComponent as Edit } from '../assets/icon/snapbrillia_edit_icon.svg';
// import { ReactComponent as Add } from '../assets/icon/snapbrillia_add_icon.svg';
// import { ReactComponent as Authy } from '../assets/icon/snapbrillia_authy_logo.svg';
import { useAuth } from '../context/authContext';
import { validatePersonalInfoFields } from '../utils/functions';
import '../shared/css/bgColors.css';
import './css/profilePersonal.css';
import * as userAPI from '../api/users';
import * as companyApi from '../api/companies';

const ProfilePersonal = () => {
  const [active, setActive] = useState(false);
  const { auth, update } = useAuth();
  const [component, setComponent] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [userFields, setUserFields] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSalesAdmin, setIsSalesAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyFields, setCompanyFields] = useState({});
  //Adding click handler onto logo for redirecting to landing page.
  useEffect(() => {
    let logo_image = document.querySelector('.assessment-bounties-logo');
    [logo_image] = logo_image.childNodes;
    [logo_image] = logo_image.childNodes;
    logo_image.classList.add('pointer');
    logo_image.addEventListener('click', () => {
      window.open('https://www.snapbrillia.com', '_self');
    });
  }, []);

  useEffect(() => {
    setUserFields({ ...userFields, companyName: auth.company.companyName });
  }, []);

  //add the mobile navbar
  const handleClick = () => {
    setActive(!active);
  };

  const handleSave = async (fileName) => {
    try {
      await userAPI.updateUserAvatar({ avatar: fileName });
    } catch (error) {}
  };

  const handleUpdateUserInfo = async () => {
    setLoading(true);
    const toastOption = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    };
    try {
      validatePersonalInfoFields(userFields);
      let user = await userAPI.updateUser(userFields);
      await companyApi.updateCompanyInfo(user.user.companyId, {
        companyName: userFields.companyName,
      });
      update(userFields);
      toast.success('Your profile information has been updated', toastOption);
    } catch (err) {
      toast.error('Check if phone number is formatted correctly', toastOption);
    } finally {
      setLoading(false);
      setIsDisabled(true);
    }
  };
  useEffect(() => {
    setUserFields(auth.user);
    setIsAdmin(auth.user?.admin);
    setIsSalesAdmin(auth.user?.salesAdmin);
    setCompanyFields(auth.company);
  }, [auth]);

  return (
    <>
      <Container className="primarybg" style={{ overflow: 'hidden' }} fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={3}
            className={active ? 'mobile-header active' : 'mobile-header'}
            style={{ position: 'relative', height: '100vh' }}
          >
            <Row>
              <Col
                className="assessment-bounties-logo"
                style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}
              >
                <SnapbrilliaLogo className="mt-1" />
              </Col>
            </Row>
            <ProfileSideMenu
              active={active}
              component={component}
              setComponent={setComponent}
              isAdmin={isAdmin}
              isSalesAdmin={isSalesAdmin}
            />
          </Col>
          <Col
            className="pagemainbg mainContent"
            style={{ overflow: 'scroll' }}
            xs={12}
            sm={12}
            md={9}
          >
            <div className="menu-icon" onClick={handleClick}>
              <Bars />
            </div>

            <div className="close_modal_button">
              <Link to="/dashboard">
                <Close />
              </Link>
            </div>
            {component === 1 && (
              <div>
                <Row>
                  <Col className="d-flex justify-content-start">
                    <label className="h1 pb-md-3 personal_title">
                      Personal Info
                    </label>
                  </Col>
                </Row>
                <Row className="personal_info">
                  <Col md={4} className="personal_profile_photo">
                    <div>
                      <PersonalProfilePhoto
                        handleSave={handleSave}
                        handleUpdateUserInfo={handleUpdateUserInfo}
                      />
                    </div>
                  </Col>
                  <Col className="personal_input_fields">
                    <PersonalInfoFields
                      userFields={userFields}
                      companyFields={companyFields}
                      setCompanyFields={setCompanyFields}
                      setUserFields={setUserFields}
                      auth={auth}
                      handleUpdateUserInfo={handleUpdateUserInfo}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <div style={{ display: component === 0 ? null : 'none' }}>
              <CompanyUsers
                companyFields={companyFields}
                setCompanyFields={setCompanyFields}
              />
            </div>
            {component === 2 && (
              <div>
                <label className="h1 security_title pb-5 pt-3">
                  {' '}
                  Security and Login{' '}
                </label>
                <Row>
                  <Col xs={12} md={8} className="px-5">
                    <Row>
                      <Col xs={5} md={5}>
                        <Row>
                          <ChangeEmail updatableEmail={userFields.email} />
                        </Row>
                      </Col>
                      <Col xs={7} md={7}>
                        <Row>
                          <label className="grey-title bold">
                            {' '}
                            Two-factor Authentication{' '}
                          </label>
                        </Row>
                        <Row className="mt-3 mb-4">
                          <Col>
                            <TwoFactorSetup />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* <Row className="mb-4">
                      <Col xs={8} md={8}>
                        <div>
                          <Authy />
                        </div>
                      </Col>
                      <Col xs={4} md={4} className="security_add_button">
                        <label className="grey-title">
                          Add
                          <div className="security_add_svg">
                            <Add />
                          </div>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8} md={8}>
                        <label style={{ fontWeight: '500' }}>2FA</label>
                      </Col>
                      <Col xs={4} md={4} className="security_add_button">
                        <label className="grey-title">
                          Add
                          <div className="security_add_svg">
                            <Add />
                          </div>
                        </label>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col xs={12} md={4} className="security_right px-4">
                    <ProfileSignedInDevices />
                  </Col>
                </Row>
              </div>
            )}
            {component === 3 && (
              <div>
                <div className="h1 personal_title">Accessibility</div>
                <div className="h4 statement_title">
                  SNAPBRILLIA&apos;S ACCESSIBILITY STATEMENT
                </div>
                <div className="accessibility_box">
                  <div className="accessibility_text">
                    <ProfileAccessibilityText />
                  </div>
                  <div className="accessibility_illustration">
                    <Illustration />
                  </div>
                </div>
              </div>
            )}
            {/* Component 4 after Subscribe page is finnished */}
            {component === undefined && (
              <div>
                <Row>
                  <Col className="d-flex justify-content-start">
                    <label className="pb-md-3 personal_title">
                      Subscription
                    </label>
                  </Col>
                </Row>
                <Row className="subscription-container mx-4">
                  <Col className="py-4">
                    <Row>
                      <Col sm={{ offset: 1 }}>
                        <div className="small-text">Current Plan</div>
                        <div className="primary bold">Community</div>
                      </Col>
                      <Col>
                        <div className="small-text">Status</div>
                        <div className="subscription-active subscription-fs semi-bold">
                          Active
                        </div>
                      </Col>
                      <Col>
                        <div className="small-text">Next Billing Date</div>
                        <div className="subscription-fs bold">Nov 20, 2022</div>
                      </Col>
                      <Col>
                        <div className="small-text">Price</div>
                        <div className="subscription-fs">
                          <b>Free</b> (monthly)
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xl={{ offset: 9 }}>
                        <div>
                          <u>Cancel Subscription</u>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-start">
                    <label className="mb-5 secondary_title">
                      Take Your Pick!
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Community />
                  </Col>
                  <Col md={4}>
                    <GrowthSubscriptionModal />
                  </Col>
                  <Col md={4}>
                    <Enterprise />
                  </Col>
                </Row>
              </div>
            )}
            {/* Component 5 after Subscribe page is finnished */}
            {component === 4 && isAdmin && (
              <Row>
                <UserManagement />
              </Row>
            )}
            {component === 5 && (
              <Row>
                <SalesView />
              </Row>
            )}
            {component === 6 && isAdmin && (
              <div>
                <Row>
                  <Col className="d-flex justify-content-start">
                    <label className="h1 pb-md-3 personal_title">
                      Logs Viewer
                    </label>
                  </Col>
                </Row>
                <Row>
                  <LogEntriesTable />
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfilePersonal;
