import '../css/buttonStyles.css';

export default function SubmitButton({ text, onClickHandler }) {
  return (
    <button
      className="btn-primary-medium"
      type="submit"
      onClick={onClickHandler}
    >
      {text}
    </button>
  );
}
