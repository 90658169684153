import axios from 'axios';

export async function sendEmailReview(to, param) {
  const response = await axios.post('/api/sendgrid/candidate/reviewed', {
    to,
    param,
  });

  return response;
}
