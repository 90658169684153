import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';

//COMPONENTS
import ChartGridAnalytics from '../../analyticPage/components/chartGridAnalytics';
import DiscussionBounty from './discussionBounty';
import BountiesPageOverview from './bountiesPageOverview';
import UsersBounties from './usersBounties';
import { getEEOOpportunity } from '../../api/opportunities';

const BountiesViewTabs = ({ bounty, action }) => {
  const tabs = [
    {
      title: 'Overview',
      component: <BountiesPageOverview bounty={bounty} />,
    },
    {
      title: 'Users',
      component: <UsersBounties {...{ bounty, action }} />,
    },
    {
      title: 'Diversity',
      component: (
        <ChartGridAnalytics
          rowsToDisplay={2}
          bountyId={bounty._id}
          urlRoutes={getEEOOpportunity}
        />
      ),
    },
    // {
    //   title: 'Discussions',
    //   component: <DiscussionBounty bounty={bounty} />,
    // },
  ];

  const [component, setComponent] = useState(tabs[1]);

  useEffect(() => {
    if (action && action === 'addCandidate') {
      setComponent(tabs[1]);
    }
  }, [action]);

  const changeComponent = (index) => {
    setComponent(tabs[index]);
  };

  return (
    <Container fluid>
      {/* <Row> */}
      <Row className="tab-title-container">
        {tabs.map((tab, index) => {
          return (
            <Col key={index} style={{ padding: '0' }}>
              <input type="radio" id={`radio-${index}`} name="label" />
              <label
                className={
                  tab.title === component.title ? 'active-tab-title' : ''
                }
                onClick={() => changeComponent(index)}
                htmlFor={`radio-${index}`}
              >
                {tab.title}
                <span className={`${index > 0 && 'left-curve'}`}></span>
              </label>
            </Col>
          );
        })}
        <div id="tab-glider" />
      </Row>
      <Row>
        <Col
          className={
            component.title === 'Overview'
              ? 'tabs-view-tab-overview'
              : 'tabs-view-tab'
          }
        >
          {component.component}
        </Col>
      </Row>
    </Container>
  );
};

export default BountiesViewTabs;
