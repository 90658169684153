import React from 'react';
import { Stepper, Step } from 'react-form-stepper';
import '../../eeoForm/css/eeoFormStyles.css';
import '../../shared/css/typography.css';
export default function RequestDemoHeader({ step = 0, setStep }) {
  const steps = ['Your details', 'Date & time'];

  return (
    <div className="" style={{ width: '100%' }}>
      <Stepper
        styleConfig={{
          activeTextColor: '#a900a6',
          activeBgColor: '#a900a6',
          completedBgColor: '#a900a6',
          completedTextColor: '#a900a6',
        }}
        connectorStyleConfig={{
          activeColor: '#a900a6',
          disabledColor: '#d3cdcd',
        }}
        connectorStateColors
        activeStep={step}
      >
        {steps.map((label, index) => {
          return (
            <Step
              label={label}
              key={index}
              onClick={() => {
                if (index === 0) {
                  setStep(0);
                }
              }}
            />
          );
        })}
      </Stepper>
    </div>
  );
}
