import './App.css';
import { Router } from '@reach/router';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignIn from './signIn/signIn';
import SignUp from './signUp/signUp';
import Validate from './twoFactor/validate';
import LinkAccount from './linkAccount/linkAccount';
import UpdateInformation from './updateInformation/updateInformation';
import SetUpAddTeam from './initialSetup/setUpAddTeam';
import SetUpAddCandidate from './initialSetup/setUpAddCandidate';
import SetUpAddProject from './initialSetup/setUpAddProject';
import AllIntegrations from './integrations/allIntegrationsPage';
import ATSIntegrations from './integrations/components/atsIntegrationsPage';
import AllProjectsBackEnd from './projectPage/components/allProjectsBackend';
import EditMemberModal from './memberModal/editMemberModal';
import AllDashboardBackEnd from './dashboard/components/allDashboardBackend';
import Policy from './policyPage/policy';
import ChartsDemo from './charts/chartsDemo';
import FeedbackModal from './shared/modals/feedbackModal';
import Notifications from './notifications/notifications';
import AssessmentResults from './assessmentResults/assessmentResults';
import Bounties from './bounties/bounties';
import EeoForm from './eeoForm/eeoForm';
import ProfilePersonal from './profilePages/profilePersonal';
import UsersBounties from './collectionBounties/components/usersBounties';
import BountiesViewTabs from './collectionBounties/components/bountiesViewTabs';
import BountyTopics from './collectionBounties/components/discussion/discussionDetail';

import CandidateModal from './candidateModal/candidateModal';
import AssessmentBountiesPage from './assessmentBounties/assessmentBountiesPage';
import AddTeamMemberModal from './memberModal/addTeamMemberModal';
import { AuthProvider } from './context/authContext';
import { OnboardingProvider } from './context/onboardingContext';
import { WalletProvider } from './context/walletContext';
import GreenhouseModal from './integrations/greenhouse/greenhouseModal';
import GreenhouseResume from './integrations/greenhouse/components/greenhouseResume';
import GreenhouseLinkModal from './integrations/greenhouse/greenhouseLinkModal';
import Home from './home/home';
import NotFound from './shared/errors/404';
import AssessmentTypeSelect from './assessmentBounties/components/assessmentTypeSelect';
import DisparityRate from './collectionBounties/components/disparityRate';
import AnalyticsPage from './analyticPage/analyticsPage';

import ResponseRate from './collectionBounties/components/responseRate';
import FeaturedBounties from './bounties/components/featuredBounties';

import BountyTypeSelection from './assessmentBounties/components/bountyTypeSelection';
import BountyPageTypeSelection from './assessmentBounties/components/bountyPageTypeSelection';
import Projects from './projects/projects';
import Assessments from './dashboard/components/assessments';
import Analytics from './collectionBounties/components/analytics';
import CandidateWaitingRoom from './waitingRooms/components/candidateWaitingRoom';
import Comments from './collectionBounties/components/comments';
import InterviewerWaitingRoom from './waitingRooms/components/interviewerWaitingRoom';
import CandidateLandingPage from './eeoForm/candidateLandingPage';
import { Container } from 'react-bootstrap';
import BountiesSetup from './bounties/bountiesSetup';
import CollectionBounties from './collectionBounties/collectionBounties';
import CollectionJob from './collectionJobs/collectionJob';
import GreenhouseDataMapping from './integrations/greenhouse/greenhouseDataMapping';
import GreenhouseMappingFields from './integrations/greenhouse/greenhouseMappingField';
import GreenhouseAllCandidateFields from './integrations/greenhouse/components/greenhouseAllCandidateFields';
import WaitingRooms from './waitingRooms/waitingRooms';
import DashboardBounties from './dashboard/components/dashboardBounties';
import ProtectRoute from './shared/ProtectedRouter';
import ConfirmUser from './comfirmUser/confirmUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactModal from './subscription/contactModal';
import GrowthSubscriptionModal from './subscription/components/growth';
import GrowthConfirmModal from './subscription/components/growthConfirm';
import MemberAcceptInvited from './memberModal/memberAcceptInvite';
import { Web3Modal } from '@web3modal/react';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import * as chain from 'wagmi/chains';

function App() {
  const chains = [chain[process.env.REACT_APP_CHAIN_NAME]];

  const { provider } = configureChains(chains, [
    w3mProvider({
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    }),
  ]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({
      appName: 'web3Modal',
      chains,
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
      version: 1,
    }),
    provider,
  });
  const ethereumClient = new EthereumClient(wagmiClient, chains);
  // const config = {
  //   projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  //   theme: 'dark',
  //   accentColor: 'default',
  //   ethereum: {
  //     appName: 'web3Modal',
  //     chains: [
  //       // chains.mainnet,
  //       // chains.polygon,
  //       // chains.goerli,
  //       chains[process.env.REACT_APP_CHAIN_NAME],
  //     ],
  //   },
  // };

  return (
    <Container fluid className="p-0">
      <WagmiConfig client={wagmiClient}>
        <AuthProvider>
          <OnboardingProvider>
            <WalletProvider>
              <Router>
                <Home path="/" />
                <MemberAcceptInvited path="/member-signup" />
                <ConfirmUser path="/user/confirm/:id" />
                <SignIn path="/signin" />
                <SignUp path="/signup" />
                <Policy path="/policy" />
                <ProtectRoute
                  component={UpdateInformation}
                  path="/update-info"
                />
                <ProtectRoute component={Validate} path="/totp-validate" />
                <ProtectRoute component={LinkAccount} path="/link-account" />
                <ProtectRoute
                  component={AllDashboardBackEnd}
                  path="/dashboard"
                />
                <ProtectRoute
                  component={DashboardBounties}
                  path="/dashboard-bounties"
                />
                <ProtectRoute component={SetUpAddTeam} path="/setup-team" />
                <ProtectRoute
                  component={AssessmentBountiesPage}
                  path="/setup-assessment"
                />
                <ProtectRoute
                  component={SetUpAddProject}
                  path="/setup-ai-project"
                />
                <ProtectRoute
                  component={SetUpAddCandidate}
                  path="/setup-candidate"
                />
                <ProtectRoute
                  component={BountiesSetup}
                  path="/setup-bounties"
                />
                <ProtectRoute component={Bounties} path="/opportunities" />
                <ProtectRoute
                  component={CollectionBounties}
                  path="/opportunity/:bountyId"
                />
                <ProtectRoute
                  component={CollectionJob}
                  path="/jobs/:bountyId"
                />
                <ProtectRoute
                  component={CollectionBounties}
                  path="/bounties/:bountyId/:action"
                />
                <ProtectRoute
                  component={CollectionJob}
                  path="/jobs/:bountyId/:action"
                />
                <ProtectRoute component={Projects} path="/projects" />
                <ProtectRoute component={Assessments} path="/assessments" />
                <ProtectRoute
                  component={AllProjectsBackEnd}
                  path="/project-page/:workSampleProjectId"
                />
                <ProtectRoute component={AnalyticsPage} path="/analytics" />
                <CandidateLandingPage path="candidate-landing-page/:workSampleProjectId/:candidateId" />
                <AllIntegrations path="/all-integrations" />
                <ATSIntegrations path="/ats-integrations" />
                <ChartsDemo path="/charts-demo" />
                <FeedbackModal path="/feedback-modal" />
                <EeoForm path="/eeo-form/:workSampleProjectId/:candidateId" />
                <Notifications path="/notifications" />
                <AssessmentResults path="/assessment-results" />
                <AddTeamMemberModal path="/add-member-modal" />
                <EditMemberModal path="/edit-member-modal" />
                <GreenhouseModal path="/greenhouse" />
                <CandidateModal path="/import-candidate-modal" />
                <ProtectRoute
                  component={ProfilePersonal}
                  path="/personal-profile/*"
                />
                <GreenhouseLinkModal path="/green-link" />
                <AssessmentTypeSelect path="/assessment-type-select" />
                <UsersBounties path="/users-bounties" />
                <DisparityRate path="/disparity-rate" />
                <BountiesViewTabs path="/bounties-view-tabs" />
                <ResponseRate path="/response-rate" />
                <AnalyticsPage path="/analytics" />
                <BountyTypeSelection path="type-select" />
                <BountyPageTypeSelection path="bp-type-select" />
                <Comments path="comments" />
                <BountyTopics path="/topics" />
                <Analytics path="/analytics-component" />
                <CandidateWaitingRoom path="waiting-room" />
                <InterviewerWaitingRoom path="/interviewer-waiting-room" />
                <GreenhouseResume path="/greenhouse-resume" />
                <GreenhouseDataMapping path="greenhouse-data-mapping" />
                <GreenhouseMappingFields path="greenhouse-mapping-fields" />
                <GreenhouseAllCandidateFields path="greenhouse-all-fields" />
                <WaitingRooms path="/waitingRooms/:id" />
                {/* <BountyPageTypeSelection path="type-selection"/> */}
                <FeaturedBounties path="featured-bounties" />
                <ContactModal path="contact-modal" />
                <GrowthSubscriptionModal path="/growth" showModal={true} />
                <GrowthConfirmModal path="/growth-confirm" showModal={true} />
                <NotFound path="*" />
              </Router>
              <ToastContainer />
              <Web3Modal
                projectId={process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID}
                ethereumClient={ethereumClient}
              />
            </WalletProvider>
          </OnboardingProvider>
        </AuthProvider>
      </WagmiConfig>
    </Container>
  );
}

export default App;
