import React, { useState, useEffect } from 'react';
import arrow from '../../assets/arrow.svg';
import { useCrypto } from '../context';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Col, Row } from 'react-bootstrap';
import { useGigForm } from '../../context/gigContext';
import { useJobForm } from '../../context/jobContext';
import * as Opportunity from '../../api/opportunities';

export const StepTwo = ({
  nextStep,
  closeModal,
  closeOrNext,
  type,
  cryptoPay,
}) => {
  const [accepted, setAccepted] = useState(false);
  const { val, loading, exchange, setLoading } = useCrypto();
  const [validDate, setValidDate] = useState(0);
  const [validDateSec, setValidDateSec] = useState(0);
  const { values, submitForm, handleChange } =
    type !== 'Job' ? useGigForm() : useJobForm();
  // console.log(values);
  // console.log(values);
  const runningTime = 20;
  const [percentage, setPercentage] = useState(0);

  const rate = (1 * values.cryptoInfo.toAmount) / values.cryptoInfo.fromAmount;

  // console.log(exchange.validUntil);

  useEffect(() => {
    const dateNow = Math.round(Date.now() / 1000);
    const dateNowValid = Math.round(
      new Date(values.cryptoInfo.validUntil) / 1000
    );
    const newDate = dateNowValid - dateNow;

    const inte = setInterval(() => {
      if (newDate > 0) {
        setValidDate(Math.round((newDate % 3600) / 60));
        setValidDateSec(newDate % 60);
        setPercentage((validDate / runningTime) * 100);
      }
    }, 1000);

    return () => clearInterval(inte);
  }, [validDate, validDateSec]);

  const submit = async () => {
    setLoading(true);
    handleChange({
      target: {
        name: 'paymentType',
        value: 'Crypto',
      },
    });
    const opportunity = await submitForm();
    await Opportunity.createCryptoOrder(opportunity._id, {
      currency: values.cryptoInfo.fromCurrency,
      amount: values.cryptoInfo.fromAmount,
      exchangeId: values.cryptoInfo.exchangeId,
    });
    setLoading(false);
    closeModal();
    closeOrNext();
  };

  const amountType = () => {
    let result = 0;
    if (type === 'Job') {
      result =
        Number(values.cryptoInfo.fromAmount) +
        Number(values.cryptoInfo.depositFee) +
        Number(values.cryptoInfo.withdrawalFee);
    } else {
      result =
        Number(values.cryptoInfo.fromAmount) +
        (Number(values.cryptoInfo.fromAmount) * 20) / 100 +
        Number(values.cryptoInfo.depositFee) +
        Number(values.cryptoInfo.withdrawalFee);
    }

    return Number(result);
  };

  return (
    <div className="second-step__info">
      <div className="tx-amount-info">
        <div className="tx-amount-info__from">
          <img
            src={values.cryptoInfo.selectedImg}
            className="tx-amount-info__coin-icon"
          />
          Total amount: {amountType()}
          {values.cryptoInfo.fromCurrency}
        </div>
        <img className="tx-amount-info__arrow" src={arrow} />
        <div className="tx-amount-info__to">
          ~ {values.cryptoInfo.toAmount}
          <img
            src={values.cryptoInfo.convertImg}
            className="tx-amount-info__coin-icon"
          />
          <span className="tx-ticker">{values.cryptoInfo.toCurrency}</span>
        </div>
      </div>
      <div className="second-step__main-info">
        <div className="main-info__block main-info__block-address">
          <div
            className="info-block__title"
            onClick={() => {
              navigator.clipboard.writeText(values.cryptoInfo.payinAddress);
              toast.success('Copied');
            }}
          >
            Recipient Wallet Address: {values.cryptoInfo.fromCurrency}
            <i
              className="fa-regular fa-copy"
              style={{
                fontSize: 20,
                color: '#d30ced',
                marginLeft: 10,
              }}
            ></i>
          </div>
          <div className="info-block__text">
            Address: {values.cryptoInfo.payinAddress}
          </div>
        </div>
        <div className="main-info__block main-info__block-time">
          <Row>
            <div className="info-block__title">
              Valid Until:{' '}
              {validDate === 0 && (
                <p
                  style={{
                    color: 'red',
                  }}
                >
                  Payment link has expired
                </p>
              )}
            </div>
            <div className="info-block__text">
              {validDate > 0 ? (
                <div style={{ width: 50, height: 50 }}>
                  {/* {validDate} {validDateSec} */}
                  <CircularProgressbar
                    value={percentage}
                    text={`${validDate}:${validDateSec}`}
                  />
                </div>
              ) : (
                <p className="info-block__text">
                  Payment have exceeded the time limit
                </p>
              )}
            </div>
          </Row>
        </div>
        <div className="main-info__block main-info__block-unit-price">
          <div className="info-block__title">Exchange Rate</div>
          <div className="unit-price-container">
            <span className="unit-price-rate__text unit-price-rate__text_from">
              1 $
            </span>
            <span className="unit-price-rate__text">~ {rate.toFixed(3)} </span>
          </div>
        </div>
        {exchange && (
          <div
            style={{
              height: 'auto',
              margin: '0 auto',
              maxWidth: 100,
              width: '100%',
            }}
          >
            <QRCode
              size={300}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={values.cryptoInfo?.payinAddress}
              viewBox={`0 0 256 256`}
            />
          </div>
        )}
      </div>
      {!cryptoPay && (
        <>
          <div className="second-step__next-button">
            <button
              className="custom-button"
              type="button"
              disabled={!accepted || loading}
              // onClick={submit}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => submit()}
            >
              {loading && <div className="lds-dual-ring loading_spinner" />}
              <span style={{ margin: '12px' }}>Create Opportunity</span>
            </button>
          </div>
          <div className="second-step__agreement">
            <label
              className="second-step__agreement-checkbox"
              htmlFor="changenow-terms-of-use"
            >
              <input
                id="changenow-terms-of-use"
                name="agreement"
                type="checkbox"
                checked={accepted}
                onChange={() => setAccepted((accepted) => !accepted)}
              />
              <span className="label__text">
                I&apos;ve read and agree to the provider&apos;s
                <a href="#" target="_blank" rel="noopener noreferrer">
                  Terms of use
                </a>{' '}
                and
                <a href="#" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>
        </>
      )}
    </div>
  );
};
