/* eslint-disable no-unused-vars */
// keep the state selection process here and render the pie chart svg immediately here as well with the values from the selection process
import React, { useState, Fragment, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import SelectAnalitycsCategory from './selectAnalitycsCategory';
import AllPieChart from '../../charts/allPieChart';
import { NewPieChart } from '../../charts/pieChart';
import { EEOBarChart } from '../../charts/EEOBarChart';
import { EEOLineChart } from '../../charts/EEOLineChart';
import { EEOAreaChart } from '../../charts/EEOAreaChart';

function PieChartGrid({
  bountyId,
  open,
  setOpen,
  column,
  index,
  columns,
  setColumns,
  rowsHeightVH,
  urlRoutes,
}) {
  const [pieChartData, setPieChartData] = useState('');
  const [chartData, setChartData] = useState({});

  const getData = async () => {
    urlRoutes(bountyId)
      .then((data) => {
        setChartData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOpenAnalitycs = (index) => {
    setOpen(true);
    let newCols = [];
    for (let i = 0; i < columns.length; i++) {
      newCols.push(
        columns[i].map((column) => {
          if (column.index === index) {
            column.showSelectComponent = true;
          } else {
            column.showSelectComponent = false;
          }
          return column;
        })
      );
    }
    setColumns(newCols);
  };
  return (
    <Fragment key={index}>
      {column.showSelectComponent && open ? (
        <Col className={`${column.className} selection-bg`} key={column.index}>
          <SelectAnalitycsCategory
            columns={columns}
            setColumns={setColumns}
            setOpen={setOpen}
            setPieChartData={setPieChartData}
          />
        </Col>
      ) : column.fixed ? (
        <Col
          xs={12}
          lg={4}
          className={`${column.className} selection-bg d-flex justify-content-center`}
        >
          {/* <AllPieChart
            width={400}
            height={300}
            bountyId={bountyId}
            status={pieChartData.status}
            dataType={pieChartData.category}
            InnerRadius={0}
            titlePosition="top"
            showStatus={true}
          /> */}
          {chartData?.counts?.length > 0 ? (
            pieChartData?.chart == 'pie' ? (
              <NewPieChart chartData={chartData} pieChartData={pieChartData} />
            ) : pieChartData?.chart == 'bar' ? (
              <EEOBarChart chartData={chartData} pieChartData={pieChartData} />
            ) : (
              <EEOAreaChart chartData={chartData} pieChartData={pieChartData} />
            )
          ) : (
            <>
              <div>
                <div className="main-piechart-div">
                  <div className="piechart-imp-div">
                    <h3>No Data to Display</h3>
                  </div>
                </div>
              </div>
            </>
          )}
        </Col>
      ) : (
        <Col
          md={12}
          lg={4}
          key={column.index}
          onClick={() => handleOpenAnalitycs(column.index)}
          className={column.hoverClassName}
          style={{
            height: `${rowsHeightVH}vh`,
          }}
        >
          <p className="plus-analitycs-sign">+</p>
        </Col>
      )}
    </Fragment>
  );
}

export default PieChartGrid;
