import Upload from '../../assets/icon/snapbrillia_upload_icon.svg';
import '../css/buttonStyles.css';

export default function UploadButton({ onClick }) {
  return (
    <button className="upload-button" onClick={onClick}>
      <img src={Upload} alt="upload-icon" /> Upload
    </button>
  );
}
