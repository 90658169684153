import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { navigate } from '@reach/router';
import * as userAPI from '../api/users';
import * as sessionAPI from '../api/sessions';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  function login(token, tokenType) {
    setLoading(true);
    sessionAPI
      .loginWithMagicToken(token, tokenType)
      .then((data) => {
        setAuth(data);
        if (data?.user?.requiretwoFactor) {
          return navigate('/totp-validate');
        }
        if (data.user.isFirstTime && !data?.user?.requiretwoFactor) {
          return navigate('/setup-team');
        }
        navigate('/opportunities');
      })
      .catch((error) => {
        toast(error.message);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  function fetchUserDevices(callback) {
    setLoading(true);
    sessionAPI
      .fetchDevices()
      .then((data) => {
        callback(data.message);
      })
      .catch((error) => {
        toast(error.message);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  function validateTotpToken(userId, token) {
    setLoading(true);
    sessionAPI
      .validateToken(userId, token)
      .then((data) => {
        if (data.message.token_validated) {
          if (data.message.isFirstTime) {
            return navigate('/setup-team');
          }
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        toast(error.message);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  function sendTotp(userId, mode) {
    setLoading(true);
    sessionAPI
      .sendToken(userId, mode)
      .then((data) => {
        toast(data.message);
      })
      .catch((error) => {
        toast(error.message);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  async function generateTotp(userId) {
    setLoading(true);
    let result = null;
    try {
      let token = await sessionAPI.generateTotpToken(userId);
      if (token) {
        result = token;
      }
    } catch (error) {
      toast(error.message);
      setError(error);
    } finally {
      setLoading(false);
    }
    return result;
  }

  async function verifyTotp(userId, totp) {
    setLoading(true);
    let result = null;
    try {
      let response = await sessionAPI.verifyTotpToken(userId, totp);
      if (response) {
        toast(response.data);
        result = response;
      }
    } catch (error) {
      toast(error.data);
      setError(error);
    } finally {
      setLoading(false);
    }
    return result;
  }

  async function disableTwoFactorVerification(userId) {
    setLoading(true);
    let result = null;
    try {
      let response = await sessionAPI.disableTwoFactor(userId);
      if (response) {
        toast(response.message);
      }
    } catch (error) {
      toast(error.message);
      setError(error);
    } finally {
      setLoading(false);
    }
    return result;
  }

  function linkAccount(token, tokenType, redirect) {
    setLoading(true);
    sessionAPI
      .linkAccount(token, tokenType)
      .then((data) => {
        setAuth(data);
        if (redirect) {
          navigate(redirect);
        }
      })
      .catch((error) => {
        toast(error.error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  function loginCallback(data) {
    setAuth(data);
    if (data.user.isFirstTime) {
      return navigate('/setup-team');
    }
    navigate('/dashboard');
  }

  function generateLink(email) {
    setLoading(true);
    sessionAPI
      .generateMagicLink(email)
      .then((data) => {
        toast(data.message);
      })
      .catch((error) => {
        toast(error.error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  function signup(values) {
    setLoading(true);
    userAPI
      .signup(values)
      .then((data) => {
        toast.info(data.message);
        /*setAuth(data);
        toast.info(
          'An email has been sent to your email address containing an activation link. Please click on the link to activate your account.'
        );
       navigate('/setup-team');*/
      })
      .catch((error) => {
        toast(error.message);
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  function update(values, redirect) {
    setLoading(true);
    userAPI
      .updateUser(values)
      .then((data) => {
        setAuth(data);
        if (redirect) {
          if (data.user.isFirstTime && !data?.user?.requiretwoFactor) {
            return navigate('/setup-team');
          }
          navigate('/dashboard');
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  function changeEmail(email, callback) {
    setLoading(true);
    sessionAPI
      .changeEmail(email)
      .then(() => {
        if (typeof callback !== 'undefined') {
          callback();
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  async function logout() {
    // deleted user in then(()) => ...
    await sessionAPI.logout();
    setAuth(undefined);
  }

  function invalidateJwtTokens(userId, callback) {
    sessionAPI.invalidateAllTokens(userId).then((data) => {
      if (typeof callback !== 'undefined') {
        callback(data);
      }
    });
  }

  function reloadUserInfo() {
    return userAPI
      .getCurrentUser()
      .then((data) => setAuth(data))
      .catch((error) => {
        // toast(error.message);
      })
      .finally(() => setLoadingInitial(false));
  }

  async function confirmUser(userId, email) {
    setLoading(true);
    try {
      let response = await userAPI.confirmUser({ userId, email });
      if (response) {
        toast('Your Account Is Activate, Have Fun!');
        await setAuth(response);
        navigate('/dashboard');
      }
    } catch (error) {
      toast(error.response.data.error);
      setError(error);
      navigate('/');
    } finally {
      setLoading(false);
    }
  }

  async function getUserInvitation(email) {
    let invitations = await userAPI.getInvitation({ email });
    return invitations;
  }

  async function skipInvitation(invitationId) {
    let invitations = await userAPI.skipInvitation({ invitationId });
    return invitations;
  }

  async function acceptInvitation(invitationId) {
    setLoading(true);
    userAPI
      .acceptInvitation({ invitationId })
      .then((data) => {
        setAuth(data);
        toast.success(
          `You Are ${data.user.companyRole} Of ${data.company.companyName}.`
        );
        navigate('/dashboard');
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }

  async function selectCompany(companyId) {
    setLoading(true);
    try {
      let data = await userAPI.selectCompany({ companyId });
      if (data) {
        await setAuth(data);
        window.location.reload();
      }
    } catch (error) {
      toast(error.response.data.error);
      setError(error);
      navigate('/');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    reloadUserInfo();
  }, []);

  const memoedValue = useMemo(
    () => ({
      auth,
      loading,
      error,
      confirmUser,
      generateLink,
      login,
      signup,
      logout,
      invalidateJwtTokens,
      fetchUserDevices,
      loginCallback,
      linkAccount,
      update,
      reloadUserInfo,
      validateTotpToken,
      sendTotp,
      generateTotp,
      verifyTotp,
      disableTwoFactorVerification,
      changeEmail,
      getUserInvitation,
      skipInvitation,
      acceptInvitation,
      selectCompany,
    }),
    [auth, loading, error]
  );
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, useAuth };
