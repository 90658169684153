/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Col, Modal, Row } from 'react-bootstrap';
import { getCompanyCoupon, CompanyRequestCoupon } from '../../api/coupon';
import '../../shared/css/typography.css';
import '../../shared/css/bgColors.css';
import '../../shared/css/sharedStyles.css';
import { toast } from 'react-toastify';

import { useAuth } from '../../context/authContext';

import AddBountyButton from '../../shared/button/addBounty';

const UserCouponList = ({ showCoupon, handleClose }) => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [btnDisable, setBtnDisbale] = useState(false);
  const { auth } = useAuth();

  const getCouponData = async (search) => {
    setLoading(true);
    await getCompanyCoupon(auth.company._id)
      .then((response) => {
        setCoupons(response);
      })
      .catch((error) => {
        setCoupons([]);
      });

    setLoading(false);
  };

  const requestCoupon = async () => {
    setLoading(true);
    await CompanyRequestCoupon()
      .then((data) => {
        toast('Request Sent Successfully');
        setBtnDisbale(true);
        setLoading(false);
      })
      .catch((error) => {
        toast('Request Failed');
      });
    setLoading(false);
  };

  let dateToday = new Date();

  const getStatus = (date) => {
    const future = new Date(date).getTime() / 1000;
    const now = new Date().getTime() / 1000;

    if (future > now) {
      return 'Active';
    } else {
      return 'Expired';
    }
  };

  useEffect(() => {
    getCouponData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.coupon, //access nested data with dot notation
        header: 'Coupon Code',
        size: 150,
      },
      {
        accessorFn: (row) =>
          `${row.isInvoiceCoupon ? 'Invoiced Coupon' : 'Discount Coupon'}`,
        header: 'Coupon Type',
        size: 50,
      },
      {
        accessorFn: (row) => getStatus(row?.expiry),
        header: 'Status',
        size: 50,
      },
      {
        accessorFn: (row) =>
          !row.isInvoiceCoupon ? `${row?.fee + '%'}` : '100%',
        header: 'Percentage',
        size: 50,
      },
      {
        accessorFn: (row) =>
          new Date(row?.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 150,
      },
      {
        accessorFn: (row) =>
          new Date(row?.expiry).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Expiry',
        size: 150,
      },
    ],
    []
  );

  return (
    <Modal size="lg" onHide={handleClose} backdrop="static" show={showCoupon}>
      <Row>
        <Col>
          <Modal.Header className="modalHeader" closeButton>
            <Modal.Title>
              <Row>
                <span className="h2">View Coupon List</span>
              </Row>
            </Modal.Title>
          </Modal.Header>
        </Col>
      </Row>
      <MaterialReactTable
        columns={columns}
        data={coupons}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
      />
    </Modal>
  );
};
export default UserCouponList;
