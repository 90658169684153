import axios from 'axios';

export async function sendRequestDemo(values) {
  const data = {
    fullName: values.fullName,
    title: values.title,
    companyName: values.companyName,
    email: values.email,
    phoneNumber: values.phoneNumber,
    companySize: values.companySize,
  };
  const response = await axios.post('/api/request-demo', data);
  return response.data;
}
