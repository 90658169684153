import React, { useEffect, useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import SaveButton from '../../shared/button/save';
import ResetButton from '../../shared/button/resetButton';
import 'bootstrap/dist/css/bootstrap.css';
import '../../bountyCreateSteps/css/createBounty.css';
import '../../shared/css/typography.css';
import { useAccount, useSendTransaction, useNetwork } from 'wagmi';

import { useInvoicedGigForm } from '../../context/invoicedGigContext';
import { useCardanoWallet } from '../../context/walletContext';
import { useAuth } from '../../context/authContext';

import Overlay from '../../shared/Overlays/OverlayWithChildren';
import BackButton from '../../shared/button/back';

export const StepFourBounty = ({
  nextStep,
  prevStep,
  closeModal,
  resetStep,
}) => {
  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };
  const { auth } = useAuth();
  const { assets: cardanoAssets, amountOfLovelaceInOneAda } =
    useCardanoWallet();

  const [loading, setLoading] = useState(false);
  const [elements] = useState();
  const [stripe] = useState();
  const [clientSecret, setClientSecret] = useState('');
  const [intent, setIntent] = useState('');
  const [fee, setFee] = useState(0.2);
  const { isLoading, error } = useSendTransaction({
    request: {
      to: process.env.REACT_APP_ETH_WALLET,
    },
  });
  const { network } = useNetwork();
  const [assets, setAssets] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState('Bank');

  const networks = useMemo(() => {
    return ['Cardano', 'Bank', 'Ethereum'];
  }, [network]);

  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    submitForm,
    resetGigForm,
  } = useInvoicedGigForm();

  const handleNetworkChange = async (value) => {
    let supportedAssets = [];
    if (value === 'Bank') {
      supportedAssets = [{ type: 'USD' }];
    } else if (value === 'Cardano') {
      supportedAssets = cardanoAssets.map((x) => ({
        type: x.assetName,
        tokenIdentifier: x.unit,
      }));
    } else if (value === 'Ethereum') {
      supportedAssets = [{ type: 'ETH' }];
    }
    setSelectedNetwork(value);
    setAssets(supportedAssets);
    if (supportedAssets.length) {
      handleChange({
        target: {
          name: 'rewardType',
          value: supportedAssets[0].type,
        },
      });
    }
  };

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const save = async (e) => {
    setLoading(true);
    handleChange({
      target: {
        name: 'fee',
        value: fee,
      },
    });
    e.preventDefault();
    await submitForm();
    setLoading(false);
    closeOrNext();
  };

  const closeOrNext = (funded) => {
    if (funded && values.useGithubApp) {
      nextStep();
    } else if (closeModal) {
      closeModal();
    }
  };

  const paymentType = ['Annually', 'Monthly', 'Hourly'];

  const workplaceTypeExp = {
    Remote: 'Employees work off-site',
    onSite: 'Employees comes to work in person',
    Hybrid: 'Employees work on-site and off-site',
  };

  return (
    <Container>
      <Row>
        <p className="grey-bounty-setup-header">
          Add time estimation, reward amount, &amp; your method of payment
        </p>
      </Row>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            {values.type === 'Job' && (
              <>
                <Form.Group as={Col} controlId="steptwoGroup2">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    Select Compensation Type <span className="required">*</span>
                  </Form.Label>
                  <Form.Select
                    name="compensationCycle"
                    onChange={handleChange}
                    disabled={loading}
                    value={values.compensationCycle}
                  >
                    {paymentType.map((n, index) => (
                      <option value={n} key={index}>
                        {n}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Group />
                </Form.Group>
                <Form.Group className="mb-4" controlId="steptwoGroup1">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    Add Minimum Estimated Compensation Amount{' '}
                    <span className="required">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Select
                      className="make-smaller-select-group"
                      name="rewardType"
                    >
                      <option>USD</option>
                    </Form.Select>
                    <Form.Control
                      className="gig-form-field border-right"
                      as="input"
                      type="number"
                      name="minCompensation"
                      onChange={handleChange}
                      value={values.minCompensation}
                      onBlur={handleBlur}
                      isInvalid={!!errors.minCompensation}
                      disabled={loading}
                    />
                    {touched.minCompensation && errors.minCompensation && (
                      <Form.Control.Feedback type="invalid">
                        {errors.minCompensation}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                  <Form.Text className="text-muted">
                    <i></i> Add employee minimum compensation amount
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-4" controlId="steptwoGroup1">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    Add Maximum Estimated Compensation Amount{' '}
                    <span className="required"></span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Select
                      className="make-smaller-select-group"
                      name="rewardType"
                    >
                      <option>USD</option>
                    </Form.Select>
                    <Form.Control
                      className="gig-form-field border-right"
                      as="input"
                      type="number"
                      name="maxCompensation"
                      onChange={handleChange}
                      value={values.maxCompensation}
                      onBlur={handleBlur}
                      isInvalid={!!errors.maxCompensation}
                      disabled={loading}
                    />
                    {touched.maxCompensation && errors.maxCompensation && (
                      <Form.Control.Feedback type="invalid">
                        {errors.maxCompensation}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                  <Form.Text className="text-muted">
                    <i></i> Add employee maximum compensation amount
                  </Form.Text>
                </Form.Group>
              </>
            )}
            <Form.Group as={Col} controlId="steptwoGroup1">
              <Form.Label className="form_label bounty-type-label ps-0">
                Time Estimation <span className="required">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Select
                  className="make-smaller-select-group"
                  name="timeEstimationType"
                  onChange={handleChange}
                  disabled={loading}
                  value={values.timeEstimationType}
                >
                  <option value="Hours">Hours</option>
                  <option value="Days">Days</option>
                  <option value="Weeks">Weeks</option>
                  <option value="Months">Months</option>
                </Form.Select>
                <Form.Control
                  className="gig-form-field"
                  as="input"
                  type="number"
                  name="timeEstimation"
                  onChange={handleChange}
                  value={values.timeEstimation}
                  onBlur={handleBlur}
                  isInvalid={!!errors.timeEstimation}
                  disabled={loading}
                />
                {touched.timeEstimation && errors.timeEstimation && (
                  <Form.Control.Feedback type="invalid">
                    {errors.timeEstimation}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
              <Form.Text className="text-muted">
                How much time do you expect this to take?
              </Form.Text>
            </Form.Group>
            {values.type === 'Gig' && (
              <Form.Group as={Col} controlId="steptwoGroup2">
                <Form.Label className="form_label bounty-type-label ps-0">
                  Select Network <span className="required">*</span>
                </Form.Label>
                <Form.Select
                  name="rewardNetwork"
                  onChange={(e) => handleNetworkChange(e.target.value)}
                  disabled={loading}
                  value={selectedNetwork}
                >
                  {networks.map((n, index) => (
                    <option value={n} key={index}>
                      {n}
                    </option>
                  ))}
                </Form.Select>
                <Form.Group />
                <Form.Label className="form_label bounty-type-label ps-0">
                  Budget Amount <span className="required">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Select
                    className="make-smaller-select-group"
                    name="rewardType"
                    onChange={handleChange}
                    value={values.rewardType}
                  >
                    {assets.map((currency, index) => (
                      <option key={index} value={currency.type}>
                        {currency.type}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control
                    className="gig-form-field"
                    as="input"
                    type="number"
                    name="rewardAmount"
                    onChange={handleChange}
                    value={values.rewardAmount}
                    onBlur={handleBlur}
                    isInvalid={!!errors.rewardAmount}
                  />
                  {touched.rewardAmount && errors.rewardAmount && (
                    <Form.Control.Feedback type="invalid">
                      {errors.rewardAmount}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
                <Form.Text className="text-muted">
                  Select to pay using USD, ₳DA, ETH, or Tokens.
                </Form.Text>
              </Form.Group>
            )}
            {/* <Form.Group as={Col} className="mb-4" controlId="steptwoGroup5">
              <Form.Label className="form_label bounty-type-label ps-0">
                Promo Code
              </Form.Label>
              <InputGroup>
                <Form.Control
                  className="gig-form-field"
                  as="input"
                  name="coupon"
                  onChange={handleChange}
                  value={values.coupon}
                  onBlur={handleBlur}
                  isInvalid={!!errors.coupon}
                  disabled={!canFund || values.validCoupon || loading}
                />
                <Button
                  variant="outline-secondary"
                  className="coupon-button"
                  disabled={values.validCoupon || loading}
                  onClick={validateCoupon}
                >
                  Apply
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-1" controlId="steptwoGroup2">
              <Form.Label className="form_label bounty-type-label ps-0">
                Add Snapbrillia Github App to use our secure browser IDE{' '}
                <span className="required">*</span>
              </Form.Label>

              <Form.Control as="checkbox" className="remove-radio-border">
                <Form.Check
                  inline
                  label="Select to add Snapbrillia Github App"
                  name="useGithubApp"
                  type="checkbox"
                  onChange={() => {
                    handleChange({
                      target: {
                        name: 'useGithubApp',
                        value: !values.useGithubApp,
                      },
                    });
                  }}
                  checked={values.useGithubApp}
                  disabled={(isEditGig && !canFund) || loading}
                />
              </Form.Control>
            </Form.Group> */}
          </Col>
          <Col xs={12} md={6}>
            <Card className="remove-card-bg">
              <Card.Body className="grey-bg h-100 d-inline-block">
                <Card.Text>
                  <Row>
                    <p className="grey-bounty-setup-header fs-6">Summary</p>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className="fs-7 mb-0">Gig Title:</p>
                      <p className="fs-7 mb-1">
                        <b
                          className="d-inline-block text-truncate"
                          style={{ maxWidth: '100%' }}
                        >
                          {values.title}
                        </b>
                      </p>
                      <p className="fs-7 mb-0">Gig Description:</p>
                      <Overlay text={values.taskDescription}>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '100%' }}
                          >
                            {values.taskDescription}
                          </b>
                        </p>
                      </Overlay>
                      <p className="fs-7 mb-0">Submission Requirements:</p>
                      <Overlay text={values.submissionRequirements}>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '100%' }}
                          >
                            {values.submissionRequirements}
                          </b>
                        </p>
                      </Overlay>
                      <p className="fs-7 mb-0">Acceptance Criteria:</p>
                      <Overlay text={values.acceptenceCriteria}>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '100%' }}
                          >
                            {values.acceptenceCriteria}
                          </b>
                        </p>
                      </Overlay>
                      {values.type === 'Job' && (
                        <>
                          <p className="fs-7 mb-0">Work Place Type:</p>
                          <p className="fs-7 mb-1">
                            <b
                              className="d-inline-block text-truncate"
                              style={{ maxWidth: '100%' }}
                            >
                              {values.workplaceType} :{' '}
                              {values.workplaceType === 'Remote'
                                ? workplaceTypeExp.Remote
                                : values.workplaceType === 'On-site'
                                ? workplaceTypeExp.onSite
                                : workplaceTypeExp.Hybrid}
                            </b>
                          </p>
                          <p className="fs-7 mb-0">Job Type:</p>
                          <p className="fs-7 mb-1">
                            <b
                              className="d-inline-block text-truncate"
                              style={{ maxWidth: '100%' }}
                            >
                              {values.jobType}
                            </b>
                          </p>
                        </>
                      )}
                      <p className="fs-7 mb-0">Skills:</p>
                      <p className="fs-7 mb-1">
                        <b
                          className="d-inline-block text-truncate"
                          style={{ maxWidth: '100%' }}
                        >
                          {values.selectedSkills?.join(', ')}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  {/* {bountyAmount > 0 && (
                    <Row className="text-end mt-4">
                      <Col xs={12} md={12}>
                        <p className="fs-7 mb-1">
                          <b>Here we will calculate the hourly rate</b>
                        </p>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '350px' }}
                          >
                            {values.timeEstimation || 0}{' '}
                            {values.timeEstimationType}
                          </b>
                        </p>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '350px' }}
                          >
                            {values.rewardType} {bountyAmount.toFixed(3)}
                          </b>
                        </p>
                        <p className="fs-7 mb-1">
                          {`Equates to roughly
                          ${
                            (bountyAmount / values.timeEstimation).toFixed(3) ||
                            0
                          }${` `}
                          ${
                            values.rewardType
                          } per ${values.timeEstimationType.replace(/.$/, '')}`}
                        </p>
                        <hr />
                        <p className="fs-7 mb-1">
                          <b>Here we will calculate the costs</b>
                        </p>
                        <p className="fs-7 mb-1">
                          <b>
                            {values.rewardType} {bountyAmount.toFixed(3)}
                          </b>
                        </p>
                        <p className="fs-7 mb-1">
                          X <b>{Math.round(fee * 100)}% Fee</b> (+
                          {(fee * bountyAmount).toFixed(3) || 0})
                        </p>
                        <p className="fs-7 mb-1">
                          + <b>Payment Fee</b> (+{paymentFee.toFixed(3) || 0})
                        </p>
                        <p className="mb-1">
                          <b>
                            Total Cost: {values.bountyRewardType}{' '}
                            {(
                              fee * bountyAmount +
                              bountyAmount +
                              paymentFee
                            ).toFixed(3)}
                          </b>
                        </p>
                      </Col>
                    </Row>
                  )} */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <BackButton onClick={back} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              {loading ? (
                <SaveButton
                  handleClick={save}
                  type="button"
                  className="btn-primary"
                  isDisabled={loading}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </SaveButton>
              ) : (
                <SaveButton
                  handleClick={save}
                  type="button"
                  className="btn-primary"
                  isDisabled={loading}
                />
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepFourBounty;
