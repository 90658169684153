import { buildTx, sendAdaBounty } from '../api/sessions';
import axios from 'axios';
import { toast } from 'react-toastify';

export const timeAgo = function (time) {
  var date = new Date(time);
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + ' Years Ago';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' Months Ago';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' Days Ago';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' Hours Ago';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' Minutes Ago';
  }
  return Math.floor(seconds) + ' Seconds Ago';
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const _hasSkills = (item) => {
  let result = false;
  if (item && item?.skills && item?.skills?.length > 0) {
    result = true;
  }
  if (item && item?.skillIds && item?.skillIds?.length > 0) {
    result = true;
  }
  return result;
};

export function formatGigOrJobSkills(item) {
  let result = '';
  if (_hasSkills(item)) {
    let skills = item?.skills ? item?.skills : item?.skillIds;
    if (skills.length < 2) {
      result = item?.skills ? `${skills[0]}` : `${skills[0].name}`;

      return result;
    }
    result = item?.skills
      ? `${skills[0]}, +${skills.length - 1} Others`
      : `${skills[0].name}, +${skills.length - 1} Others`;
  }
  return result;
}

export function compensation(arr) {
  if (arr.type === 'Job') {
    if (arr?.compensationCycle) {
      if (arr?.compensationCycle === 'Annually') {
        return '/yr';
      }
      if (arr?.compensationCycle === 'Hourly') {
        return '/hr';
      }
      if (arr?.compensationCycle === 'Monthly') {
        return '/mth';
      }
    } else {
      return '/yr';
    }
  }
  return '';
}
