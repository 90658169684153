import axios from 'axios';

const serializeFormData = (formData) => {
  var obj = {};
  for (var key of formData.keys()) {
    obj[key] = formData.get(key);
  }
  return obj;
};

export async function uploadAvatar(formData, isUpload) {
  if (!isUpload) {
    const four = await serializeFormData(formData);
    formData = four;
  }
  const response = await axios.post(`/files/avatar`, formData, {
    withCredentials: true,
  });
  return response.data;
}

export function snapbrilliaFile(file) {
  return `${process.env.REACT_APP_API_URL}/assets/${file}`;
}

export async function uploadFile(formData) {
  try {
    const response = await axios.post(`/api/files`, formData);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function uploadLogo(formData) {
  try {
    const response = await axios.post(
      `/api/v1/files/images?type=logo`,
      formData
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
