import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SelectMenu from '../../shared/formItems/selectMenu';
import '../css/usersBounties.css';
import '../../shared/css/typography.css';
import UserBounty from './userBounty';
import UserProfile from './userProfile';
import * as BountyApi from '../../api/bounties';
import * as OpportunitiesApi from '../../api/opportunities';
import AddCandidateButton from '../../shared/button/addCandidate';
import BountyAddCandidateModal from '../../candidateModal/bountyAddCandidateModal';
import { Spinner } from 'react-bootstrap';
import CloseApplicationsModal from './closeApplicationsWarning';
import SendEmail from './sendEmail';
import SendRejectEmail from './SendRejectEmail';
import { navigate } from '@reach/router';

const UsersBounties = ({ bounty, action }) => {
  const [updateReview, setUpdateReview] = useState(false);
  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [selected, setSelected] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showAddCandidate, setShowAddCandidate] = useState(false);
  const [showCloseApplication, setShowCloseApplications] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendRejectEmail, setSendRejectEmail] = useState(false);
  const [applicantId, setApplicantId] = useState('');
  const [jobId, setJobId] = useState('');

  useEffect(() => {
    setShowAddCandidate(action === 'addCandidate');
  }, [action]);

  const getAllBountyCandidates = async () => {
    try {
      setLoading(true);
      const candidates = await OpportunitiesApi.getAssessments(bounty._id);
      setLoading(false);
      setUsers(candidates);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAddCandidate = async (candidate) => {
    getAllBountyCandidates();
    setShowAddCandidate(false);
    navigate(`/opportunity/${bounty._id}`);
  };

  useEffect(() => {
    getAllBountyCandidates();
  }, [clicked]);

  useEffect(() => {
    if (filter) {
      setFilteredUsers(
        users.filter((user) => user.assessment.status === filter.toLowerCase())
      );
      setUserProfile(null);
      setSelected(null);
    }
    if (!filter || filter === 'All') {
      setFilteredUsers(users);
      setUserProfile(null);
      setSelected(null);
    }
  }, [filter, users]);

  const closeProfile = (reload = false) => {
    if (updateReview) {
      setUsers(updateReview);
      setUpdateReview(false);
    }
    if (reload) {
      getAllBountyCandidates();
    }
    setUserProfile(null);
    setSelected(null);
  };

  const onCloseApplicationsClicked = async (e) => {
    e.preventDefault();
    setShowCloseApplications(true);
  };
  return (
    <Container fluid className="users-bounties-main-container p-0">
      <Row className="align-items-center">
        <Col xs={6} xl={6}>
          <div>
            <label className="label h4 bold my-2 mx-1" htmlFor="filter">
              Filter:
            </label>
            <div className="users-bounties-filter-select" id="filter">
              <SelectMenu
                value={filter}
                setValue={setFilter}
                list={[
                  { item: 'All', key: 0 },
                  { item: 'In-Progress', key: 1 },
                  { item: 'In-Review', key: 2 },
                  { item: 'Accepted', key: 3 },
                  { item: 'Rejected', key: 4 },
                  { item: 'Invited', key: 4 },
                ]}
                placeHolder="In-Progress, In-Review, Accepted"
              />
            </div>
          </div>
        </Col>
        <Col xs={3} xl={3}>
          {bounty.status === 'Open' && bounty.funded && (
            <button
              style={{ marginTop: '28px' }}
              className="responsive-primary-btn"
              data-bounty={bounty._id}
              onClick={onCloseApplicationsClicked}
            >
              Close Applications
            </button>
          )}
        </Col>
        <Col xs={3} xl={3}>
          {bounty.status === 'Open' && bounty.funded && (
            <AddCandidateButton
              style={{ marginTop: '28px' }}
              handleClick={() => setShowAddCandidate(true)}
            />
          )}
          {showAddCandidate && (
            <BountyAddCandidateModal
              bounty={bounty}
              handleClose={() => setShowAddCandidate(false)}
              showModal={showAddCandidate}
              handleAddCandidate={handleAddCandidate}
            />
          )}
        </Col>
        <Col />
        <Col className="col-12 discussion-divider" />
      </Row>
      <Row className="users-bounties-list justify-content-center">
        {loading && (
          <>
            <Spinner
              className="mt-3"
              as="span"
              animation="grow"
              role="status"
              aria-hidden="true"
            />
          </>
        )}
        {filteredUsers.map((user) => (
          <UserBounty
            bounty={bounty}
            selected={selected}
            setSelected={setSelected}
            setUserProfile={setUserProfile}
            key={user._id}
            user={user}
            setShowUserProfile={setShowUserProfile}
            setJobId={setJobId}
            setApplicantId={setApplicantId}
          />
        ))}
        {showUserProfile && (
          <UserProfile
            bounty={bounty}
            userProfile={userProfile}
            closeProfile={closeProfile}
            setShowUserProfile={setShowUserProfile}
            setClicked={setClicked}
            setSendEmail={setSendEmail}
            setSendRejectEmail={setSendRejectEmail}
          />
        )}
        {sendEmail && (
          <SendEmail
            sendEmail={sendEmail}
            userProfile={userProfile}
            setSendEmail={setSendEmail}
          />
        )}
        {sendRejectEmail && (
          <SendRejectEmail
            sendRejectEmail={sendRejectEmail}
            userProfile={userProfile}
            setSendRejectEmail={setSendRejectEmail}
          />
        )}
      </Row>
      <Row>
        {showCloseApplication && (
          <CloseApplicationsModal
            showModal={showCloseApplication}
            closeModal={() => setShowCloseApplications(false)}
            bounty={bounty}
          ></CloseApplicationsModal>
        )}
      </Row>
    </Container>
  );
};

export default UsersBounties;
