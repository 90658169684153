import { Container, Row, Col } from 'react-bootstrap';
import './css/emailAssessmentMenu.css';
import '../css/sharedStyles.css';
import React, { useState } from 'react';

const EmailAssessmentMenu = ({ changeTitle }) => {
  const [active, setActive] = useState(0);

  const changeActive = (title, index) => {
    setActive(index);
    const type = index ? 'interviewer' : 'candidate';
    changeTitle(title, type);
  };

  const checkActive = (index) => {
    let isActive;
    if (index === active) {
      isActive = true;
    } else {
      isActive = false;
    }
    return isActive;
  };

  return (
    <Container>
      <Row className="email-menu">
        <Col className="email-header">
          <span className="grey-title">Select Email Template</span>
          <span className="email-menu-custom">Add Custom</span>
        </Col>
        <Col
          className={
            checkActive(0) ? 'active-email-menu' : 'inactive-email-menu'
          }
          onClick={() => changeActive('Assess Email', 0)}
        >
          <span className="viewed-title">Assess</span>
        </Col>
        {/* <Col
          className={
            checkActive(1) ? 'active-email-menu' : 'inactive-email-menu'
          }
          onClick={() => changeActive('Interview Email', 1)}
        >
          <span className="viewed-title">Interview</span>
        </Col> */}
      </Row>
    </Container>
  );
};

export default EmailAssessmentMenu;
