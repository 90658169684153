import React, { createContext, useContext } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as BountyApi from '../api/bounties';
import { createSalesOpportunity } from '../api/sales';
import { toast } from 'react-toastify';

const InvoicedGigContext = createContext();
const useInvoicedGigForm = () => useContext(InvoicedGigContext);

const InvoicedGigProvider = ({ children, gig }) => {
  const linkValidator = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
  );
  const skillsName = gig ? gig?.skillIds?.map((value) => value.name) : [];
  const defaultGig = {
    _id: '',
    title: '',
    type: 'Gig',
    url: '',
    repoBranch: '',
    taskDescription: '',
    submissionRequirements:
      '- 100% code coverage \n- Well-commented and documented code \n- Make a quick post in the gig discussion to ping the reviewers \n- System architecture or data flow diagrams (if applicable)',
    acceptenceCriteria:
      '- Pass all unit tests and successful builds \n- Brief summary of work done and to be done \n- Add a section to request special things for reviewers to note \n- Specify what the PR is about and list all types of changes that has been performed (e.g. bug fix, hotfix, refactor)',
    importantLink: '',
    timeEstimation: '',
    coupon: '',
    validCoupon: false,
    rewardAmount: 1000,
    rewardType: 'USD',
    rewardNetwork: 'USD',
    timeEstimationType: 'Hours',
    skills: skillsName ? [...skillsName] : [],
    selectedSkills: skillsName ? [...skillsName] : [],
    teamPreference: true,
    permissionLess: true,
    jobListingType: 'Regular',
    useGithubApp: false,
    isPrivate: false,
    companyId: '',
    companyName: '',
    minCompensation: 0,
    maxCompensation: 0,
    compensationType: '',
    compensationCycle: 'Annually',
    workplaceType: 'On-site',
    jobType: 'Full-time',
    location: 'Global',
    paymentType: 'Bank',
    cryptoInfo: {
      name: '',
      fromCurrency: 'ada',
      fromNetwork: 'ada',
      fromAmount: 0,
      toAmount: 0,
      toCurrency: 'usdt',
      toNetwork: 'usdt',
      selectedImg:
        'https://content-api.changenow.io/uploads/ada_fb42809541.svg',
      toAddress: '',
      rateId: '',
      withdrawalFee: '',
      depositFee: '',
      exchangeId: 1,
    },
  };

  const gigForm = useFormik({
    enableReinitialize: true,
    initialValues: gig ? { ...defaultGig, ...gig } : defaultGig,
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      importantLink: Yup.string()
        .matches(linkValidator, 'Must be a valid link')
        .required('Reference Link is required'),
      taskDescription: Yup.string().required('Gig Description is required'),
      submissionRequirements: Yup.string().required(
        'Submission Requirements is required'
      ),
      acceptenceCriteria: Yup.string().required(
        'Acceptance Criteria is required'
      ),
      timeEstimation: Yup.string().required('Time Estimation is required'),
      timeEstimationType: Yup.string().required('Estimation Type is required'),
      rewardType: Yup.string().required('Reward Type is required'),
      // rewardAmount: Yup.number().when(['validCoupon'], {
      //   is: (validCoupon) => !validCoupon,
      //   then: Yup.number()
      //     .min(0.001, 'Minimum amount is 0.001')
      //     .required('Reward Amount is required'),
      //   otherwise: Yup.number()
      //     .min(0.001, 'Minimum amount is 0.001')
      //     .required('Reward Amount is required'),
      // }),
      teamPreference: Yup.bool().required('Team Preference is required'),
      permissionLess: Yup.bool().required('Permission is required'),
      skills: Yup.array().of(Yup.string()).min(1, 'Skills is required'),
      companyName: Yup.string().required('Company Name is required'),
      // url: Yup.string().when('useGithubApp', {
      //   is: true,
      //   then: Yup.string().required('Github Url is required'),
      // }),
    }),
    onSubmit: async (values) => {
      const body = { ...values };
      const bounty = await createSalesOpportunity(body);
      if (bounty) {
        toast.success(
          `successfuly created bounty ${bounty.title} for ${values.companyName}`
        );
      } else {
        toast.error(`No company owner found`);
      }
      return bounty;
    },
  });

  const resetGigForm = async () => {
    gigForm.resetForm();
  };

  const updateGig = async (data) => {
    const body = {
      ...data,
      _id: gigForm.values._id,
    };
    const { bounty } = await BountyApi.editBounty(body);
    return bounty;
  };

  const createGig = async (data) => {
    const body = {
      ...data,
    };
    const { bounty } = await createSalesOpportunity(body);
    return bounty;
  };

  const verifyCoupon = async (code) => {
    return await BountyApi.verifyCoupon(code);
  };

  const activeCoupon = async (code) => {
    if (gigForm.values._id) {
      return await BountyApi.activeCoupon(gigForm.values._id, code);
    }
    return;
  };

  return (
    <InvoicedGigContext.Provider
      value={{
        ...gigForm,
        isEditGig: gigForm.values._id,
        updateGig,
        createGig,
        verifyCoupon,
        activeCoupon,
        resetGigForm,
      }}
    >
      {children}
    </InvoicedGigContext.Provider>
  );
};

export { InvoicedGigProvider, InvoicedGigContext, useInvoicedGigForm };
