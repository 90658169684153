import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { capitalize } from 'lodash';
import { toast } from 'react-toastify';

import SnapbrilliaBountiesIcon from '../../assets/icon/snapbrillia_bounties_icon.svg';
import '../css/userBounty.css';
import {
  issueCredentials,
  getAssessment,
  getBountyRewards,
} from '../../api/bounties';
import { useOnBoarding } from '../../context/onboardingContext';
import RewardCandidateModal from '../components/candidateProfile/rewardCandidateModal';
import ChatModal from '../components/chatModal';
import Chat from './Chat';

const UserBounty = ({
  bounty,
  user,
  selected,
  setSelected,
  setUserProfile,
  setShowUserProfile,
}) => {
  const { reInviteCandidate } = useOnBoarding();
  const { fullName, email } = user.candidateId;
  const { status, position, review, rewarded } = user;
  // const [loading, setLoading] = useState(false);
  const [initials] = useState(() => {
    let splitName = fullName == '' ? email : fullName?.split(' ');
    if (!splitName) {
      splitName = fullName == ' ' ? email : fullName?.split(' ');
    }
    return `${splitName[0] ? splitName[0][0] : ''}${
      splitName[1] ? splitName[1][0] : ''
    }`;
  });
  //Reward Candidate state
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [rewardableAssessment, setRewardableAssessment] = useState(null);
  const [targetBounty, setTargetBounty] = useState(null);
  const [showChatButton] = useState(() => {
    return status === 'Accepted';
  });
  const [showChatModal, setShowChart] = useState(false);

  const handleSelected = (e) => {
    e.preventDefault();
    user.initials = initials;
    setSelected(user._id);
    setUserProfile(user);
    e.stopPropagation();
  };
  const onRewardClicked = async (e) => {
    e.stopPropagation();
    const assessmentId = e.target.getAttribute('data-assessment');
    const assessment = await getAssessment(assessmentId);
    if (assessment && !rewarded) {
      setRewardableAssessment(assessment);
      const bountyId = e.target.getAttribute('data-bounty');
      const bounty = await getBountyRewards(bountyId);
      if (bounty) {
        setTargetBounty(bounty);
      }
      setShowRewardModal(true);
    }
    e.preventDefault();
  };
  const onIssueCredentialsClicked = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const assessmentId = e.target.getAttribute('data-assessment');
    if (assessmentId) {
      try {
        let response = await issueCredentials(assessmentId);
        toast(response);
      } catch (error) {
        toast('Issue Credentials Failed');
      }
    }
  };
  const showRewardButton = () => {
    return (
      status === 'Completed' &&
      review === 'Accepted' &&
      (position === 'Mentor' || position === 'Individual') &&
      !rewarded
    );
  };
  const showIssueCredentials = (assessment) => {
    return status === 'Completed' && rewarded;
  };

  const handleReInviteCandidate = async (bounty, user) => {
    await reInviteCandidate(bounty, user, true);
    toast('Reinvite Candidate is successful');
  };
  const handleCloseChatModal = () => {
    setShowChart(false);
  };
  return (
    <Row onClick={handleSelected} className="users-bounties-container pointer">
      <Col
        md={4}
        className={
          selected === user._id
            ? 'users-bounties-main-col-1-selected users-bounties-main-col-1'
            : 'users-bounties-main-col-1'
        }
        onClick={() => setShowUserProfile(true)}
      >
        <Col md={3}>
          <span className="users-bounties-circle">
            <span>{initials}</span>
          </span>
        </Col>
        <Col>
          <Row>
            <Col>
              <p className="users-bounties-FullName">
                {fullName === '' || fullName === ' ' ? email : fullName}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="users-bounties-col-2-p">
                <p
                  className="users-bounties-status"
                  style={{ marginRight: '10px' }}
                >
                  {review === 'None' ? capitalize(status) : 'Completed'}
                </p>

                <p style={{ marginLeft: '10px' }}>
                  {position === 'Individual' ? (
                    <b>Individual Contributor</b>
                  ) : (
                    <b>Team {position}</b>
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
      <Col md={2}>
        <p className="grey-title">Submitted:</p>
        {capitalize(status) === 'Submitted' ||
        capitalize(status) === 'Completed' ? (
          <p>True</p>
        ) : (
          <p>False</p>
        )}
      </Col>
      <Col md={2}>
        <p className="grey-title">Status</p>
        {capitalize(status)}
      </Col>
      <Col md={2}>
        <p className="grey-title">Review</p>
        {review || 'None'}
      </Col>

      <Col>
        <Row>
          {status !== 'Completed' && (
            <div className="users-bounties-ide-button">
              <button
                className="users-bounties-launch-ide"
                onClick={(e) => {
                  e.preventDefault();
                  setShowChart(true);
                  e.stopPropagation();
                }}
              >
                Chat
              </button>
            </div>
          )}
          {status !== 'Accepted' && bounty?.useGithubApp == true && (
            <div className="users-bounties-ide-button">
              <button
                className="users-bounties-launch-ide"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(user?.ideUrl, '_blank');
                }}
              >
                Launch IDE
              </button>
            </div>
          )}
          {showRewardButton(user) && (
            <div className="users-bounties-ide-button">
              <button
                className="users-bounties-launch-ide"
                data-assessment={user._id}
                data-bounty={bounty._id}
                onClick={onRewardClicked}
              >
                Reward Candidate
              </button>
            </div>
          )}
          {showIssueCredentials(user) && (
            <div className="users-bounties-ide-button">
              <button
                className="users-bounties-launch-ide"
                data-assessment={user._id}
                onClick={onIssueCredentialsClicked}
              >
                Issue Credentials
              </button>
            </div>
          )}
        </Row>
      </Col>
      <Col>
        {showRewardModal && (
          <RewardCandidateModal
            assessment={rewardableAssessment}
            bounty={targetBounty.bounty}
            total={targetBounty.rewarded}
            rewards={targetBounty.rewards}
            showModal={showRewardModal}
            setShowModal={setShowRewardModal}
          ></RewardCandidateModal>
        )}
        {showChatModal && (
          <Chat
            showModal={showChatModal}
            onClose={handleCloseChatModal}
            user={user}
            bounty={bounty}
          />
        )}
      </Col>
    </Row>
  );
};

export default UserBounty;
