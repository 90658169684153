import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAuth } from '../context/authContext';
import { useLocation } from '@reach/router';
import qs from 'query-string';
import React, { useEffect } from 'react';
import Logo from '../assets/snapbrillia_logo_white.svg';
import ColoredLogo from '../assets/SnapBetaLogo2.svg';
import '../shared/css/bgColors.css';
import '../shared/css/textColors.css';
import '../shared/css/typography.css';
import './css/signInStyles.css';

export default function LinkAccount() {
  const { linkAccount } = useAuth();
  const search = useLocation().search;
  const token = qs.parse(search).token;
  const tokenType = qs.parse(search).stytch_token_type;

  useEffect(() => {
    if (token && tokenType) {
      if (tokenType === 'oauth') {
        linkAccount(token, tokenType, process.env.REACT_APP_GITHUB_APP_URL);
      }
    }
  }, []);

  return (
    <Container className="signinmainbg sign-in-page" fluid>
      <Row>
        <Col md={4}>
          <img
            src={Logo}
            alt="logo"
            className="d-none d-xl-block d-md-block d-lg-block signin-logo-lg"
          />
          <section className="h-75 ui-text-white d-none d-xl-flex d-md-flex d-lg-flex signin-header w-75 d-flex flex-column justify-content-center align-items-center mx-auto">
            <h3>
              Your unfair advantage to hiring the
              <span className="extra-bold"> best and the brightest, now.</span>
            </h3>
          </section>
        </Col>
        <Col
          className=" d-flex vh-100 justify-content-center primarybg componentborder align-items-center"
          md={8}
        >
          <Row className="w-100">
            <Col md={12}>
              <img
                src={ColoredLogo}
                alt="colored-logo"
                className="fixed-top ms-3 d-xl-none d-lg-none d-md-none d-block"
              />
              <Container>
                <Row className="sign-in-heading-row w-100 mx-auto">
                  <Col md={12}>
                    <h1 className="h1 sign-in-heading extra-bold text-center">
                      Link Account
                    </h1>
                    <p className="h4 label w-10 mx-auto sign-in-heading-small text-center">
                      Please wait
                    </p>
                    <Row>
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
