export const valueAreaList = [
  { label: 'Afghanistan', flag: '🇦🇫', value: 'AF', countryCode: '+93' },
  { label: 'Åland Islands', flag: '🇦🇽', value: 'AX', countryCode: '+358' },
  { label: 'Albania', flag: '🇦🇱', value: 'AL', countryCode: '+355' },
  { label: 'Algeria', flag: '🇩🇿', value: 'DZ', countryCode: '+213' },
  { label: 'American Samoa', flag: '🇦🇸', value: 'AS', countryCode: '+1684' },
  { label: 'Andorra', flag: '🇦🇩', value: 'AD', countryCode: '+376' },
  { label: 'Angola', flag: '🇦🇴', value: 'AO', countryCode: '+244' },
  { label: 'Anguilla', flag: '🇦🇮', value: 'AI', countryCode: '+1264' },
  { label: 'Antarctica', flag: '🇦🇶', value: 'AQ', countryCode: '+672' },
  {
    label: 'Antigua and Barbuda',
    flag: '🇦🇬',
    value: 'AG',
    countryCode: '+1268',
  },
  { label: 'Argentina', flag: '🇦🇷', value: 'AR', countryCode: '+54' },
  { label: 'Armenia', flag: '🇦🇲', value: 'AM', countryCode: '+374' },
  { label: 'Aruba', flag: '🇦🇼', value: 'AW', countryCode: '+297' },
  { label: 'Australia', flag: '🇦🇺', value: 'AU', countryCode: '+61' },
  { label: 'Austria', flag: '🇦🇹', value: 'AT', countryCode: '+43' },
  { label: 'Azerbaijan', flag: '🇦🇿', value: 'AZ', countryCode: '+994' },
  { label: 'Bahamas', flag: '🇧🇸', value: 'BS', countryCode: '+1242' },
  { label: 'Bahrain', flag: '🇧🇭', value: 'BH', countryCode: '+973' },
  { label: 'Bangladesh', flag: '🇧🇩', value: 'BD', countryCode: '+880' },
  { label: 'Barbados', flag: '🇧🇧', value: 'BB', countryCode: '+1246' },
  { label: 'Belarus', flag: '🇧🇾', value: 'BY', countryCode: '+375' },
  { label: 'Belgium', flag: '🇧🇪', value: 'BE', countryCode: '+32' },
  { label: 'Belize', flag: '🇧🇿', value: 'BZ', countryCode: '+501' },
  { label: 'Benin', flag: '🇧🇯', value: 'BJ', countryCode: '+229' },
  { label: 'Bermuda', flag: '🇧🇲', value: 'BM', countryCode: '+1441' },
  { label: 'Bhutan', flag: '🇧🇹', value: 'BT', countryCode: '+975' },
  {
    label: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    value: 'BO',
    countryCode: '+591',
  },
  {
    label: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    value: 'BA',
    countryCode: '+387',
  },
  { label: 'Botswana', flag: '🇧🇼', value: 'BW', countryCode: '+267' },
  { label: 'Bouvet Island', flag: '🇧🇻', value: 'BV', countryCode: '+47' },
  { label: 'Brazil', flag: '🇧🇷', value: 'BR', countryCode: '+55' },
  {
    label: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    value: 'IO',
    countryCode: '+246',
  },
  { label: 'Brunei Darussalam', flag: '🇧🇳', value: 'BN', countryCode: '+673' },
  { label: 'Bulgaria', flag: '🇧🇬', value: 'BG', countryCode: '+359' },
  { label: 'Burkina Faso', flag: '🇧🇫', value: 'BF', countryCode: '+226' },
  { label: 'Burundi', flag: '🇧🇮', value: 'BI', countryCode: '+257' },
  { label: 'Cambodia', flag: '🇰🇭', value: 'KH', countryCode: '+855' },
  { label: 'Cameroon', flag: '🇨🇲', value: 'CM', countryCode: '+237' },
  { label: 'Canada', flag: '🇨🇦', value: 'CA', countryCode: '+1' },
  { label: 'Cape Verde', flag: '🇨🇻', value: 'CV', countryCode: '+238' },
  { label: 'Cayman Islands', flag: '🇰🇾', value: 'KY', countryCode: '+345' },
  {
    label: 'Central African Republic',
    flag: '🇨🇫',
    value: 'CF',
    countryCode: '+236',
  },
  { label: 'Chad', flag: '🇹🇩', value: 'TD', countryCode: '+235' },
  { label: 'Chile', flag: '🇨🇱', value: 'CL', countryCode: '+56' },
  { label: 'China', flag: '🇨🇳', value: 'CN', countryCode: '+86' },
  { label: 'Christmas Island', flag: '🇨🇽', value: 'CX', countryCode: '+61' },
  {
    label: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    value: 'CC',
    countryCode: '+61',
  },
  { label: 'Colombia', flag: '🇨🇴', value: 'CO', countryCode: '+57' },
  { label: 'Comoros', flag: '🇰🇲', value: 'KM', countryCode: '+269' },
  { label: 'Congo', flag: '🇨🇬', value: 'CG', countryCode: '+242' },
  {
    label: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    value: 'CD',
    countryCode: '+243',
  },
  { label: 'Cook Islands', flag: '🇨🇰', value: 'CK', countryCode: '+682' },
  { label: 'Costa Rica', flag: '🇨🇷', value: 'CR', countryCode: '+506' },
  { label: "Cote d'Ivoire", flag: '🇨🇮', value: 'CI', countryCode: '+225' },
  { label: 'Croatia', flag: '🇭🇷', value: 'HR', countryCode: '+385' },
  { label: 'Cuba', flag: '🇨🇺', value: 'CU', countryCode: '+53' },
  { label: 'Cyprus', flag: '🇨🇾', value: 'CY', countryCode: '+357' },
  { label: 'Czech Republic', flag: '🇨🇿', value: 'CZ', countryCode: '+420' },
  { label: 'Denmark', flag: '🇩🇰', value: 'DK', countryCode: '+45' },
  { label: 'Djibouti', flag: '🇩🇯', value: 'DJ', countryCode: '+253' },
  { label: 'Dominica', flag: '🇩🇲', value: 'DM', countryCode: '+1767' },
  {
    label: 'Dominican Republic',
    flag: '🇩🇴',
    value: 'DO',
    countryCode: '+1849',
  },
  { label: 'Ecuador', flag: '🇪🇨', value: 'EC', countryCode: '+593' },
  { label: 'Egypt', flag: '🇪🇬', value: 'EG', countryCode: '+20' },
  { label: 'El Salvador', flag: '🇸🇻', value: 'SV', countryCode: '+503' },
  { label: 'Equatorial Guinea', flag: '🇬🇶', value: 'GQ', countryCode: '+240' },
  { label: 'Eritrea', flag: '🇪🇷', value: 'ER', countryCode: '+291' },
  { label: 'Estonia', flag: '🇪🇪', value: 'EE', countryCode: '+372' },
  { label: 'Ethiopia', flag: '🇪🇹', value: 'ET', countryCode: '+251' },
  {
    label: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    value: 'FK',
    countryCode: '+500',
  },
  { label: 'Faroe Islands', flag: '🇫🇴', value: 'FO', countryCode: '+298' },
  { label: 'Fiji', flag: '🇫🇯', value: 'FJ', countryCode: '+679' },
  { label: 'Finland', flag: '🇫🇮', value: 'FI', countryCode: '+358' },
  { label: 'France', flag: '🇫🇷', value: 'FR', countryCode: '+33' },
  { label: 'French Guiana', flag: '🇬🇫', value: 'GF', countryCode: '+594' },
  { label: 'French Polynesia', flag: '🇵🇫', value: 'PF', countryCode: '+689' },
  {
    label: 'French Southern Territories',
    flag: '🇹🇫',
    value: 'TF',
    countryCode: '+262',
  },
  { label: 'Gabon', flag: '🇬🇦', value: 'GA', countryCode: '+241' },
  { label: 'Gambia', flag: '🇬🇲', value: 'GM', countryCode: '+220' },
  { label: 'Georgia', flag: '🇬🇪', value: 'GE', countryCode: '+995' },
  { label: 'Germany', flag: '🇩🇪', value: 'DE', countryCode: '+49' },
  { label: 'Ghana', flag: '🇬🇭', value: 'GH', countryCode: '+233' },
  { label: 'Gibraltar', flag: '🇬🇮', value: 'GI', countryCode: '+350' },
  { label: 'Greece', flag: '🇬🇷', value: 'GR', countryCode: '+30' },
  { label: 'Greenland', flag: '🇬🇱', value: 'GL', countryCode: '+299' },
  { label: 'Grenada', flag: '🇬🇩', value: 'GD', countryCode: '+1473' },
  { label: 'Guadeloupe', flag: '🇬🇵', value: 'GP', countryCode: '+590' },
  { label: 'Guam', flag: '🇬🇺', value: 'GU', countryCode: '+1671' },
  { label: 'Guatemala', flag: '🇬🇹', value: 'GT', countryCode: '+502' },
  { label: 'Guernsey', flag: '🇬🇬', value: 'GG', countryCode: '+44' },
  { label: 'Guinea', flag: '🇬🇳', value: 'GN', countryCode: '+224' },
  { label: 'Guinea-Bissau', flag: '🇬🇼', value: 'GW', countryCode: '+245' },
  { label: 'Guyana', flag: '🇬🇾', value: 'GY', countryCode: '+592' },
  { label: 'Haiti', flag: '🇭🇹', value: 'HT', countryCode: '+509' },
  {
    label: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    value: 'HM',
    countryCode: '+672',
  },
  {
    label: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    value: 'VA',
    countryCode: '+379',
  },
  { label: 'Honduras', flag: '🇭🇳', value: 'HN', countryCode: '+504' },
  { label: 'Hong Kong', flag: '🇭🇰', value: 'HK', countryCode: '+852' },
  { label: 'Hungary', flag: '🇭🇺', value: 'HU', countryCode: '+36' },
  { label: 'Iceland', flag: '🇮🇸', value: 'IS', countryCode: '+354' },
  { label: 'India', flag: '🇮🇳', value: 'IN', countryCode: '+91' },
  { label: 'Indonesia', flag: '🇮🇩', value: 'ID', countryCode: '+62' },
  {
    label: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    value: 'IR',
    countryCode: '+98',
  },
  { label: 'Iraq', flag: '🇮🇶', value: 'IQ', countryCode: '+964' },
  { label: 'Ireland', flag: '🇮🇪', value: 'IE', countryCode: '+353' },
  { label: 'Isle of Man', flag: '🇮🇲', value: 'IM', countryCode: '+44' },
  { label: 'Israel', flag: '🇮🇱', value: 'IL', countryCode: '+972' },
  { label: 'Italy', flag: '🇮🇹', value: 'IT', countryCode: '+39' },
  { label: 'Jamaica', flag: '🇯🇲', value: 'JM', countryCode: '+1876' },
  { label: 'Japan', flag: '🇯🇵', value: 'JP', countryCode: '+81' },
  { label: 'Jersey', flag: '🇯🇪', value: 'JE', countryCode: '+44' },
  { label: 'Jordan', flag: '🇯🇴', value: 'JO', countryCode: '+962' },
  { label: 'Kazakhstan', flag: '🇰🇿', value: 'KZ', countryCode: '+7' },
  { label: 'Kenya', flag: '🇰🇪', value: 'KE', countryCode: '+254' },
  { label: 'Kiribati', flag: '🇰🇮', value: 'KI', countryCode: '+686' },
  {
    label: "Korea, Democratic People's Republic of Korea",
    flag: '🇰🇵',
    value: 'KP',
    countryCode: '+850',
  },
  {
    label: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    value: 'KR',
    countryCode: '+82',
  },
  { label: 'Kosovo', flag: '🇽🇰', value: 'XK', countryCode: '+383' },
  { label: 'Kuwait', flag: '🇰🇼', value: 'KW', countryCode: '+965' },
  { label: 'Kyrgyzstan', flag: '🇰🇬', value: 'KG', countryCode: '+996' },
  { label: 'Laos', flag: '🇱🇦', value: 'LA', countryCode: '+856' },
  { label: 'Latvia', flag: '🇱🇻', value: 'LV', countryCode: '+371' },
  { label: 'Lebanon', flag: '🇱🇧', value: 'LB', countryCode: '+961' },
  { label: 'Lesotho', flag: '🇱🇸', value: 'LS', countryCode: '+266' },
  { label: 'Liberia', flag: '🇱🇷', value: 'LR', countryCode: '+231' },
  {
    label: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    value: 'LY',
    countryCode: '+218',
  },
  { label: 'Liechtenstein', flag: '🇱🇮', value: 'LI', countryCode: '+423' },
  { label: 'Lithuania', flag: '🇱🇹', value: 'LT', countryCode: '+370' },
  { label: 'Luxembourg', flag: '🇱🇺', value: 'LU', countryCode: '+352' },
  { label: 'Macao', flag: '🇲🇴', value: 'MO', countryCode: '+853' },
  { label: 'Macedonia', flag: '🇲🇰', value: 'MK', countryCode: '+389' },
  { label: 'Madagascar', flag: '🇲🇬', value: 'MG', countryCode: '+261' },
  { label: 'Malawi', flag: '🇲🇼', value: 'MW', countryCode: '+265' },
  { label: 'Malaysia', flag: '🇲🇾', value: 'MY', countryCode: '+60' },
  { label: 'Maldives', flag: '🇲🇻', value: 'MV', countryCode: '+960' },
  { label: 'Mali', flag: '🇲🇱', value: 'ML', countryCode: '+223' },
  { label: 'Malta', flag: '🇲🇹', value: 'MT', countryCode: '+356' },
  { label: 'Marshall Islands', flag: '🇲🇭', value: 'MH', countryCode: '+692' },
  { label: 'Martinique', flag: '🇲🇶', value: 'MQ', countryCode: '+596' },
  { label: 'Mauritania', flag: '🇲🇷', value: 'MR', countryCode: '+222' },
  { label: 'Mauritius', flag: '🇲🇺', value: 'MU', countryCode: '+230' },
  { label: 'Mayotte', flag: '🇾🇹', value: 'YT', countryCode: '+262' },
  { label: 'Mexico', flag: '🇲🇽', value: 'MX', countryCode: '+52' },
  {
    label: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    value: 'FM',
    countryCode: '+691',
  },
  { label: 'Moldova', flag: '🇲🇩', value: 'MD', countryCode: '+373' },
  { label: 'Monaco', flag: '🇲🇨', value: 'MC', countryCode: '+377' },
  { label: 'Mongolia', flag: '🇲🇳', value: 'MN', countryCode: '+976' },
  { label: 'Montenegro', flag: '🇲🇪', value: 'ME', countryCode: '+382' },
  { label: 'Montserrat', flag: '🇲🇸', value: 'MS', countryCode: '+1664' },
  { label: 'Morocco', flag: '🇲🇦', value: 'MA', countryCode: '+212' },
  { label: 'Mozambique', flag: '🇲🇿', value: 'MZ', countryCode: '+258' },
  { label: 'Myanmar', flag: '🇲🇲', value: 'MM', countryCode: '+95' },
  { label: 'Namibia', flag: '🇳🇦', value: 'NA', countryCode: '+264' },
  { label: 'Nauru', flag: '🇳🇷', value: 'NR', countryCode: '+674' },
  { label: 'Nepal', flag: '🇳🇵', value: 'NP', countryCode: '+977' },
  { label: 'Netherlands', flag: '🇳🇱', value: 'NL', countryCode: '+31' },
  { label: 'Netherlands Antilles', flag: '', value: 'AN', countryCode: '+599' },
  { label: 'New Caledonia', flag: '🇳🇨', value: 'NC', countryCode: '+687' },
  { label: 'New Zealand', flag: '🇳🇿', value: 'NZ', countryCode: '+64' },
  { label: 'Nicaragua', flag: '🇳🇮', value: 'NI', countryCode: '+505' },
  { label: 'Niger', flag: '🇳🇪', value: 'NE', countryCode: '+227' },
  { label: 'Nigeria', flag: '🇳🇬', value: 'NG', countryCode: '+234' },
  { label: 'Niue', flag: '🇳🇺', value: 'NU', countryCode: '+683' },
  { label: 'Norfolk Island', flag: '🇳🇫', value: 'NF', countryCode: '+672' },
  {
    label: 'Northern Mariana Islands',
    flag: '🇲🇵',
    value: 'MP',
    countryCode: '+1670',
  },
  { label: 'Norway', flag: '🇳🇴', value: 'NO', countryCode: '+47' },
  { label: 'Oman', flag: '🇴🇲', value: 'OM', countryCode: '+968' },
  { label: 'Pakistan', flag: '🇵🇰', value: 'PK', countryCode: '+92' },
  { label: 'Palau', flag: '🇵🇼', value: 'PW', countryCode: '+680' },
  {
    label: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    value: 'PS',
    countryCode: '+970',
  },
  { label: 'Panama', flag: '🇵🇦', value: 'PA', countryCode: '+507' },
  { label: 'Papua New Guinea', flag: '🇵🇬', value: 'PG', countryCode: '+675' },
  { label: 'Paraguay', flag: '🇵🇾', value: 'PY', countryCode: '+595' },
  { label: 'Peru', flag: '🇵🇪', value: 'PE', countryCode: '+51' },
  { label: 'Philippines', flag: '🇵🇭', value: 'PH', countryCode: '+63' },
  { label: 'Pitcairn', flag: '🇵🇳', value: 'PN', countryCode: '+64' },
  { label: 'Poland', flag: '🇵🇱', value: 'PL', countryCode: '+48' },
  { label: 'Portugal', flag: '🇵🇹', value: 'PT', countryCode: '+351' },
  { label: 'Puerto Rico', flag: '🇵🇷', value: 'PR', countryCode: '+1939' },
  { label: 'Qatar', flag: '🇶🇦', value: 'QA', countryCode: '+974' },
  { label: 'Romania', flag: '🇷🇴', value: 'RO', countryCode: '+40' },
  { label: 'Russia', flag: '🇷🇺', value: 'RU', countryCode: '+7' },
  { label: 'Rwanda', flag: '🇷🇼', value: 'RW', countryCode: '+250' },
  { label: 'Reunion', flag: '🇷🇪', value: 'RE', countryCode: '+262' },
  { label: 'Saint Barthelemy', flag: '🇧🇱', value: 'BL', countryCode: '+590' },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    value: 'SH',
    countryCode: '+290',
  },
  {
    label: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    value: 'KN',
    countryCode: '+1869',
  },
  { label: 'Saint Lucia', flag: '🇱🇨', value: 'LC', countryCode: '+1758' },
  { label: 'Saint Martin', flag: '🇲🇫', value: 'MF', countryCode: '+590' },
  {
    label: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    value: 'PM',
    countryCode: '+508',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    value: 'VC',
    countryCode: '+1784',
  },
  { label: 'Samoa', flag: '🇼🇸', value: 'WS', countryCode: '+685' },
  { label: 'San Marino', flag: '🇸🇲', value: 'SM', countryCode: '+378' },
  {
    label: 'Sao Tome and Principe',
    flag: '🇸🇹',
    value: 'ST',
    countryCode: '+239',
  },
  { label: 'Saudi Arabia', flag: '🇸🇦', value: 'SA', countryCode: '+966' },
  { label: 'Senegal', flag: '🇸🇳', value: 'SN', countryCode: '+221' },
  { label: 'Serbia', flag: '🇷🇸', value: 'RS', countryCode: '+381' },
  { label: 'Seychelles', flag: '🇸🇨', value: 'SC', countryCode: '+248' },
  { label: 'Sierra Leone', flag: '🇸🇱', value: 'SL', countryCode: '+232' },
  { label: 'Singapore', flag: '🇸🇬', value: 'SG', countryCode: '+65' },
  { label: 'Slovakia', flag: '🇸🇰', value: 'SK', countryCode: '+421' },
  { label: 'Slovenia', flag: '🇸🇮', value: 'SI', countryCode: '+386' },
  { label: 'Solomon Islands', flag: '🇸🇧', value: 'SB', countryCode: '+677' },
  { label: 'Somalia', flag: '🇸🇴', value: 'SO', countryCode: '+252' },
  { label: 'South Africa', flag: '🇿🇦', value: 'ZA', countryCode: '+27' },
  { label: 'South Sudan', flag: '🇸🇸', value: 'SS', countryCode: '+211' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    value: 'GS',
    countryCode: '+500',
  },
  { label: 'Spain', flag: '🇪🇸', value: 'ES', countryCode: '+34' },
  { label: 'Sri Lanka', flag: '🇱🇰', value: 'LK', countryCode: '+94' },
  { label: 'Sudan', flag: '🇸🇩', value: 'SD', countryCode: '+249' },
  { label: 'Suriname', flag: '🇸🇷', value: 'SR', countryCode: '+597' },
  {
    label: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    value: 'SJ',
    countryCode: '+47',
  },
  { label: 'Eswatini', flag: '🇸🇿', value: 'SZ', countryCode: '+268' },
  { label: 'Sweden', flag: '🇸🇪', value: 'SE', countryCode: '+46' },
  { label: 'Switzerland', flag: '🇨🇭', value: 'CH', countryCode: '+41' },
  {
    label: 'Syrian Arab Republic',
    flag: '🇸🇾',
    value: 'SY',
    countryCode: '+963',
  },
  { label: 'Taiwan', flag: '🇹🇼', value: 'TW', countryCode: '+886' },
  { label: 'Tajikistan', flag: '🇹🇯', value: 'TJ', countryCode: '+992' },
  {
    label: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    value: 'TZ',
    countryCode: '+255',
  },
  { label: 'Thailand', flag: '🇹🇭', value: 'TH', countryCode: '+66' },
  { label: 'Timor-Leste', flag: '🇹🇱', value: 'TL', countryCode: '+670' },
  { label: 'Togo', flag: '🇹🇬', value: 'TG', countryCode: '+228' },
  { label: 'Tokelau', flag: '🇹🇰', value: 'TK', countryCode: '+690' },
  { label: 'Tonga', flag: '🇹🇴', value: 'TO', countryCode: '+676' },
  {
    label: 'Trinidad and Tobago',
    flag: '🇹🇹',
    value: 'TT',
    countryCode: '+1868',
  },
  { label: 'Tunisia', flag: '🇹🇳', value: 'TN', countryCode: '+216' },
  { label: 'Turkey', flag: '🇹🇷', value: 'TR', countryCode: '+90' },
  { label: 'Turkmenistan', flag: '🇹🇲', value: 'TM', countryCode: '+993' },
  {
    label: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    value: 'TC',
    countryCode: '+1649',
  },
  { label: 'Tuvalu', flag: '🇹🇻', value: 'TV', countryCode: '+688' },
  { label: 'Uganda', flag: '🇺🇬', value: 'UG', countryCode: '+256' },
  { label: 'Ukraine', flag: '🇺🇦', value: 'UA', countryCode: '+380' },
  {
    label: 'United Arab Emirates',
    flag: '🇦🇪',
    value: 'AE',
    countryCode: '+971',
  },
  { label: 'United Kingdom', flag: '🇬🇧', value: 'GB', countryCode: '+44' },
  { label: 'United States', flag: '🇺🇸', value: 'US', countryCode: '+1' },
  { label: 'Uruguay', flag: '🇺🇾', value: 'UY', countryCode: '+598' },
  { label: 'Uzbekistan', flag: '🇺🇿', value: 'UZ', countryCode: '+998' },
  { label: 'Vanuatu', flag: '🇻🇺', value: 'VU', countryCode: '+678' },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    value: 'VE',
    countryCode: '+58',
  },
  { label: 'Vietnam', flag: '🇻🇳', value: 'VN', countryCode: '+84' },
  {
    label: 'Virgin Islands, British',
    flag: '🇻🇬',
    value: 'VG',
    countryCode: '+1284',
  },
  {
    label: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    value: 'VI',
    countryCode: '+1340',
  },
  { label: 'Wallis and Futuna', flag: '🇼🇫', value: 'WF', countryCode: '+681' },
  { label: 'Yemen', flag: '🇾🇪', value: 'YE', countryCode: '+967' },
  { label: 'Zambia', flag: '🇿🇲', value: 'ZM', countryCode: '+260' },
  { label: 'Zimbabwe', flag: '🇿🇼', value: 'ZW', countryCode: '+263' },
];
