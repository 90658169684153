import '../../shared/css/typography.css';
import '../../memberModal/css/teamMemberModal.css';
import '../../shared/css/buttonStyles.css';
import React from 'react';
import CandidateModal from '../candidateModal';

export default function AddCandidateModal({
  openAddCandidate,
  setOpenAddCandidate,
  handleClose,
  workSampleProjectId,
  handleAddCandidate,
  handleAddCandidates,
}) {
  return (
    <CandidateModal
      openAddCandidate={openAddCandidate}
      setOpenAddCandidate={setOpenAddCandidate}
      action="add"
      closeModal={handleClose}
      workSampleProjectId={workSampleProjectId}
      handleAddCandidate={handleAddCandidate}
      handleAddCandidates={handleAddCandidates}
    />
  );
}
