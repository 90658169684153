import Modal from 'react-bootstrap/Modal';

export default function LogOutModal({ show, closeModal, handleLogOut }) {
  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton className="h2 modal-header border-0">
        {' '}
        Do you want to sign out?{' '}
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-between ">
        <button className="btn-secondary" onClick={closeModal}>
          No
        </button>
        <button className="btn-primary" onClick={handleLogOut}>
          Yes
        </button>
      </Modal.Body>
    </Modal>
  );
}
