import React, { useState, useEffect } from 'react';
import { Dropdown } from '../Dropdown/Dropdown';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCrypto } from '../context';
import { useGigForm } from '../../context/gigContext';
import { useJobForm } from '../../context/jobContext';
import { getSupportedCurrencies } from '../../api/crypto';
import './loader.css';

const StepOne = ({ nextStep, closeModal, type }) => {
  const {
    val,
    loading,
    setValues,
    getEstimates,
    amountRange,
    getEstimateRanges,
    amount,
    setAmount,
    error,
    setError,
    createExchange,
    setExchange,
    setLoading,
  } = useCrypto();
  const { handleChange, values, errors } =
    type !== 'Job' ? useGigForm() : useJobForm();
  // console.log(values);
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getCurrencies = () => {
      setLoading(true);
      setLoaded(true);
      getSupportedCurrencies()
        .then((currencies) => {
          setCryptoCurrencies(currencies);
          // console.log(currencies);
          setLoading(false);
          setLoaded(false);
        })
        .catch((err) => {
          setLoading(false);
          setLoaded(false);
          toast.error('Network error, please try again');
          closeModal();
        });
    };
    getCurrencies();
    return () => {};
  }, []);

  const valueWithFee = (value) => {
    return (value * (100 + 20)) / 100;
  };

  const checkEstimate = (e) => {
    setValues({
      fromCurrency: e.ticker,
      fromNetwork: e.network,
      toAddress: e.address,
      selectedImg: e.image,
    });
    handleChange({
      target: {
        name: 'cryptoInfo',
        value: {
          ...values.cryptoInfo,
          selectedImg: e.image,
          fromCurrency: e.ticker,
          fromNetwork: e.network,
        },
      },
    });
    handleChange({
      target: {
        name: 'rewardType',
        value: e.ticker,
      },
    });
    getEstimateRanges(e.ticker, e.network);
    getEstimates(e.ticker, e.network, amount);
  };

  useEffect(() => {
    const SUPPORTED_TOPUP = [
      {
        ticker: 'usdt',
        name: 'Tether (USDT)',
        link: 'usdt',
        network: 'ethereum',
        image: 'https://content-api.changenow.io/uploads/ada_fb42809541.svg',
      },
    ];
  }, [loading]);

  useEffect(() => {
    if (
      (amount < amountRange?.minAmount || amount > amountRange?.maxAmount) &&
      !loading
    ) {
      setError(true);
      handleChange({
        target: {
          name: 'cryptoInfo',
          value: {
            ...values.cryptoInfo,
            toAmount: 0,
          },
        },
      });
      if (amount < amountRange?.minAmount) {
        toast.error(
          `Amount should not be less than ${amountRange?.minAmount} ${val.fromCurrency}`
        );
      } else {
        toast.error(
          `Amount should not be greater than ${amountRange?.maxAmount} ${val.fromCurrency}`
        );
      }
    } else {
      setError(false);
      // toast.info('Error cleared, please proceed');
    }
  }, [amount, amountRange]);

  useEffect(() => {
    if (errors.timeEstimation) {
      toast.error(errors.timeEstimation);
      closeModal();
    } else if (errors.minCompensation) {
      toast.error(errors.minCompensation);
      closeModal();
    } else {
      getEstimateRanges(
        values.cryptoInfo.fromCurrency,
        values.cryptoInfo.fromNetwork
      );
      getEstimates(
        values.cryptoInfo.fromCurrency,
        values.cryptoInfo.fromNetwork,
        values.cryptoInfo.fromAmount
      );
    }
  }, [values.fromAmount]);

  const amountType = () => {
    let result = 0;
    if (type === 'Job') {
      result =
        Number(values.cryptoInfo.fromAmount) +
        Number(values.cryptoInfo.depositFee) +
        Number(values.cryptoInfo.withdrawalFee);
    } else {
      result =
        Number(values.cryptoInfo.fromAmount) +
        (Number(values.cryptoInfo.fromAmount) * 20) / 100 +
        Number(values.cryptoInfo.depositFee) +
        Number(values.cryptoInfo.withdrawalFee);
    }

    return Number(result);
  };

  return loaded ? (
    <div className="first-step__data-exchange data-exchange skeleton">
      <div className="data-exchange__settings data-exchange__settings_desktop">
        <div className="settings__selectors">
          <Row>
            <Col>
              <div className="selectors__select-box select-box select-box_big">
                <div className="select-box__input">
                  <div className="skeleton-row">
                    <Row>
                      <Col>
                        <div className="skeleton-text"></div>
                        <div className="skeleton-img"></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="selectors__select-box select-box select-box_big">
                <div className="select-box__input">
                  <div className="skeleton-row">
                    <Row>
                      <Col>
                        <div className="skeleton-text"></div>
                        <div className="skeleton-img"></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  ) : (
    <div className="first-step__data-exchange data-exchange">
      <div className="data-exchange__settings data-exchange__settings_desktop">
        <div className="settings__rates"></div>
        <div className="settings__selectors">
          <div className="selectors__select-box select-box select-box_big">
            <div className="select-box__input">
              <label className="select-box__input-comment">
                Opportunity Amount
              </label>
              {loaded ? (
                <div className="select-box__input-field">
                  <div className="lds-dual-ring loading_spinner" />
                </div>
              ) : (
                <input
                  name="toAmount"
                  className="select-box__input-field"
                  autoComplete="off"
                  inputMode="decimal"
                  type="number"
                  // disabled={loading}
                  value={amount}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: 'cryptoInfo',
                        value: {
                          ...values.cryptoInfo,
                          fromAmount: e.target.value,
                        },
                      },
                    });
                    handleChange({
                      target: {
                        name: 'rewardType',
                        value: values.cryptoInfo.fromCurrency,
                      },
                    });
                    if (type === 'Job') {
                      handleChange({
                        target: {
                          name: 'jobFee',
                          value: e.target.value,
                        },
                      });
                    } else {
                      handleChange({
                        target: {
                          name: 'rewardAmount',
                          value: e.target.value,
                        },
                      });
                    }
                    setAmount(e.target.value);
                    setValues({
                      ...val,
                      fromAmount: e.target.value,
                    });
                  }}
                  onKeyUp={() => {
                    if (
                      amount < amountRange.minAmount ||
                      amount > amountRange.maxAmount
                    ) {
                      setError(true);
                    } else {
                      getEstimates(
                        values.cryptoInfo.fromCurrency,
                        values.cryptoInfo.fromNetwork,
                        values.cryptoInfo.fromAmount
                      );
                    }
                  }}
                />
              )}
            </div>
            <Dropdown
              value={values.cryptoInfo.fromCurrency}
              currencies={cryptoCurrencies.filter((x) => {
                return !x.isFiat;
              })}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'rewardType',
                    value: e.network,
                  },
                });
                checkEstimate(e);
                // console.log(e);
              }}
              disabled={loading}
            />
          </div>
          <div className="size-box"></div>
          <div className="selectors__select-box select-box select-box_big">
            <div className="select-box__input">
              <label className="select-box__input-comment">Value in USD</label>
              {loading ? (
                <div className="select-box__input-field">
                  <div className="lds-dual-ring loading_spinner" />
                </div>
              ) : (
                <input
                  className="select-box__input-field custom-input-field__input-wrapper_disabled"
                  disabled={true}
                  value={values.cryptoInfo.toAmount}
                />
              )}
            </div>
            <Dropdown
              value={values.cryptoInfo.toCurrency}
              currencies={cryptoCurrencies.filter((x) => {
                return !x.isFiat;
              })}
              onChange={(e) => {
                // setValues({
                //   ...val,
                //   toCurrency: e.ticker,
                //   convertImg: e.image,
                // });
              }}
              disabled={true}
              disabledSearch={true}
            />
          </div>
        </div>
        {amountRange && (
          <Row>
            <Col lg={6} sm={6} xl={6} xxl={6} md={6}>
              <p>minimum amount: $200</p>
            </Col>
          </Row>
        )}
        {values?.cryptoInfo?.fromAmount && (
          <Row>
            <Col lg={6} sm={6} xl={6} xxl={6} md={6}>
              <p>Payable Amount Calculation:</p>
            </Col>
            <Col lg={6} sm={6} xl={6} xxl={6} md={6}>
              <p>
                <p className="select-box__input-comment">
                  Budget Amount: {values.cryptoInfo.fromAmount}{' '}
                  {values.cryptoInfo.fromCurrency} <br />
                  Creation Fee:{' '}
                  {type !== 'Job' &&
                    (Number(values.cryptoInfo.fromAmount) * 20) / 100 +
                      ' (+20)'}
                  <br />
                  Network Fee:{' '}
                  {Number(values.cryptoInfo.depositFee) +
                    Number(values.cryptoInfo.withdrawalFee)}{' '}
                  <br />
                  Total Amount: {amountType().toFixed(6)}{' '}
                  {values.cryptoInfo.fromCurrency}
                </p>
              </p>
            </Col>
          </Row>
        )}
      </div>
      <div className="data-exchange__button">
        <button
          className="custom-button"
          type="button"
          disabled={error || loading}
          onClick={async () => {
            if (values.cryptoInfo.toAmount > 200) {
              const data = await createExchange();
              // console.log(data);
              if (data.load) {
                handleChange({
                  target: {
                    name: 'cryptoInfo',
                    value: {
                      ...values.cryptoInfo,
                      payinAddress: data.response.payinAddress,
                      payoutAddress: data.response.payoutAddress,
                      validUntil: data.response.validUntil,
                      exchangeId: data.response.id,
                    },
                  },
                });
                nextStep();
                setExchange(data.response);
              }
            } else {
              toast.error('Amount must be greater than $200');
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepOne;
