import React, { useState } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import FinishButton from '../../shared/button/finishBounty';
import ResetButton from '../../shared/button/resetButton';
import SwitchToGeneralGig from '../../shared/button/switchToGeneralGig';
import AddSnapbrilliaGithubApp from '../../shared/button/addSnapbrilliaGithubApp';
import SnapbrilliaGithubGif from '../../assets/gifs/snapbrillia_add_github_app.gif';
import BackButton from '../../shared/button/back';
import CreateButton from '../../shared/button/create';
import Warning from './warning';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/createBounty.css';
import '../../shared/css/typography.css';

import { useGigForm } from '../../context/gigContext';
import useGithubApp from '../../hooks/useGithubApp';
import { useAuth } from '../../context/authContext';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';

export const StepFiveBounty = ({
  // nextStep,
  prevStep,
  closeModal,
  resetStep,
}) => {
  // deleted setFieldTouched from useGigForm()
  const { handleChange, values, updateGig } = useGigForm();
  const [githubRepoName, setGithubRepoName] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();

  const {
    openPopup,
    branches,
    setUrl,
    linkGithubUrl,
    githubAppUrl,
    repos,
    createRepo,
    resetGigForm,
  } = useGithubApp();

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };
  const handleChangeUrl = (e) => {
    handleChange({
      target: { name: 'url', value: e.target.value },
    });
    setUrl(e.target.value);
  };

  const handleBranchChange = (e) => {
    handleChange({
      target: { name: 'repoBranch', value: e.target.value },
    });
  };

  const update = async (e) => {
    e.preventDefault();
    if (!values.url) {
      return;
    }
    await updateGig({
      url: values.url,
    });
    if (closeModal) {
      closeModal();
    } else {
      navigate('/bounties');
    }
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const confirm = () => {
    if (!values.url) {
      return;
    }
    setShow(true);
  };

  const createNewRepo = async () => {
    setLoading(true);
    if (!githubRepoName) {
      return toast('Invalid repo name');
    }
    try {
      const newRepo = await createRepo(githubRepoName);
      setLoading(false);
      if (newRepo) {
        toast.success('Create Repository Successfully');
        await handleChange({
          target: { name: 'url', value: newRepo.html_url },
        });
        setUrl(newRepo.html_url);
        setGithubRepoName(newRepo.html_url);
      }
    } catch (err) {
      return toast("Can't create new repository");
    }
  };

  const switchToGeneralGig = async (e) => {
    e.preventDefault();
    setLoading(true);
    await updateGig({
      useGithubApp: false,
    });
    toast('Switch to general gig successfully!');
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <>
      {show && (
        <Warning
          show={show}
          setShow={setShow}
          title={'Warning'}
          message={`You selected "${values.url}" for this gig. Please confirm before moving forward. You can't change repository later.`}
          onSuccess={update}
        />
      )}
      <Container>
        <Row>
          <Row>
            <p className="grey-bounty-setup-header">
              Adding the Snapbrillia Github App to your Github allows you to
              utilize our secure browser IDE which means none of your code will
              be on the persons computer while they work on your gig, so you can
              rest assure that your codebase is safe and secure.
            </p>
          </Row>
          <Col xs={12} md={7}>
            {!auth.user.githubId && (
              <>
                <Row>
                  <p className="grey-bounty-setup-header mt-4 fs-6">
                    Click the link below to get started
                  </p>
                </Row>
                <AddSnapbrilliaGithubApp onClick={() => openPopup()} />
                <Row>
                  <p className="grey-bounty-setup-header mt-3 fs-6">
                    Once the app is connected, you can choose which repository
                    and which branch you would like the work to be conducted on.
                  </p>
                </Row>
              </>
            )}
            {repos.length === 0 && auth.user.githubId && (
              <div className="add-git-gen-style">
                Don&apos;t see your Github URL? Please install our{' '}
                <a
                  href={!auth.user.githubId ? linkGithubUrl : githubAppUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Snapbrillia Github Application{' '}
                </a>
                to continue or select <b>Get Repository</b> above. If it does
                not show up after you connect, please refresh.
              </div>
            )}
            {repos.length >= 0 && auth.user.githubId && (
              <>
                <Row className="gx-1">
                  <Col className="assessment-type-input-label-col">
                    <label className="assessment-git-bucket-label">
                      Select Github URL *
                    </label>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => openPopup()}
                    >
                      Get Repository
                    </span>
                  </Col>
                  <Col xs={12} className="">
                    <Form.Select
                      name="url"
                      className="px-2"
                      onChange={handleChangeUrl}
                      value={values.url}
                    >
                      <option value="" key={-1} disabled={true}>
                        Please select a repo
                      </option>
                      {repos.map((repo, index) => {
                        return (
                          <option value={repo.github_url} key={index}>
                            {repo.github_url}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  {!values.url && (
                    <Form.Control.Feedback className="bounty-field-error text-danger">
                      {'Github Url is required'}
                    </Form.Control.Feedback>
                  )}
                </Row>
                {repos.length > 0 &&
                  branches.length > 0 &&
                  auth.user.githubId && (
                    <>
                      <Col
                        xs={8}
                        className="form_label bounty-type-label mb-0 ps-0"
                      >
                        Select Branch to work from<span>&nbsp;*</span>
                      </Col>
                      <select
                        name="repoBranch"
                        className="assessment-type-input px-2"
                        onChange={handleBranchChange}
                      >
                        {branches.map((branch, index) => {
                          return (
                            <option value={branch.name} key={index}>
                              {branch.name}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                <Row>
                  <p className="grey-bounty-setup-header mt-3 fs-6 mb-1">
                    Or Add New
                  </p>
                </Row>
                <Form.Group className="mb-4" controlId="stepOneGroup1">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    New Github RepoName <span className="required">*</span>
                  </Form.Label>
                  {!loading && (
                    <Form.Control
                      className="gig-form-field"
                      name="name"
                      type="text"
                      onChange={(e) => setGithubRepoName(e.target.value)}
                      value={githubRepoName}
                    />
                  )}
                </Form.Group>
                <SwitchToGeneralGig
                  type="button"
                  onClick={switchToGeneralGig}
                  isLoading={loading}
                />
                <div className="float-end">
                  <CreateButton onClick={createNewRepo} type="button" />
                </div>
              </>
            )}
          </Col>
          <Col xs={12} md={5}>
            <img
              className="img-fluid"
              src={SnapbrilliaGithubGif}
              alt="Snapbrillia Github Gif"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <BackButton onClick={back} />
              <FinishButton
                onClick={confirm}
                type="button"
                isLoading={loading}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StepFiveBounty;
