import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/topics.css';
import '../../../shared/css/typography.css';
import '../../../shared/css/textColors.css';
import '../../../shared/css/bgColors.css';
import SnapbrilliaBlueUpChevron from '../../../assets/icon/snapbrillia_blue_up_chevron.svg';
import Comments from '../comments';

// props: user;
const BountyDiscussion = ({ discussion }) => {
  const [showComments, setShowComments] = useState(true);

  useEffect(() => {
    setShowComments(true);
    return () => {
      setShowComments(true);
    };
  }, []);

  useEffect(() => {
    setShowComments(true);
  }, [discussion]);

  return (
    <Container>
      <Row className="topic-admin-name">
        <Col md={2}>
          <div className="topic-title-container">
            <span className="topic-admin-name-circle">
              <span>
                {discussion.userId.fullName
                  .match(/(^\S\S?|\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </span>
            </span>
            <h4 className="h4 topic-author-name">
              {discussion.userId.fullName}
            </h4>
            <span className="topic-grey-text">{discussion.userId.title}</span>
          </div>
        </Col>
        <Col>
          <Row>
            <Col>
              <p className="h2">{discussion.title}</p>
            </Col>
            <Col md={1}>
              <div onClick={() => setShowComments(!showComments)}>
                <img
                  src={SnapbrilliaBlueUpChevron}
                  alt="SnapbrilliaBlueUpChevron"
                  className={showComments ? 'discussion-chevron-down' : ''}
                />
              </div>
            </Col>
          </Row>
          <Row id={`${discussion._id}`}>
            <Col>
              <div>
                <p className="topic-grey-text">{discussion.description}</p>
              </div>
            </Col>
          </Row>
          <Row>{showComments && <Comments discussion={discussion} />}</Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BountyDiscussion;
