import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import { getUserCardanoWallet } from '../api/sessions';
import {
  sendAdaBounty,
  increaseAdaBountyApi,
  sendAdaTokenBounty,
  formatCardanoWalletInfo,
  generateAdaBountyTx,
  generateCardanoTokenTx,
  submitConsumeBountyCreditTx,
} from '../api/crypto';
import { addCardanoWallet, clearCardanoWallet } from '../api/users';

const WalletContext = createContext();
const useCardanoWallet = () => useContext(WalletContext);

const WalletProvider = ({ children }) => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [cardanoWalletName, setCardanoWalletName] = useState('');
  const [connecting, setConnecting] = useState(false);
  const [address, setAddress] = useState('');
  const [solanaAddress, setSolanaAddress] = useState('');
  const [solanaConnected, setSolanaConnected] = useState(false);
  const [assets, setAssets] = useState([
    {
      assetName: '₳DA',
      unit: '',
    },
  ]);
  const supportedWallets = [
    {
      name: 'Nami',
      value: 'nami',
    },
    {
      name: 'Eternl',
      value: 'eternl',
    },
    {
      name: 'Flint',
      value: 'flint',
    },
    {
      name: 'NuFi',
      value: 'nufi',
    },
    {
      name: 'Typhon',
      value: 'typhoncip30',
    },
    {
      name: 'Gero',
      value: 'gerowallet',
    },
  ];
  const [bountyCredit, setBountyCredit] = useState(0);
  const amountOfLovelaceInOneAda = 1000000;
  const solanaProvider = window.phantom?.solana;

  const getEnviorment = () => {
    const url = window.location.href;
    if (url.includes('staging') || url.includes('localhost')) {
      return true;
    } else {
      return false;
    }
  };

  const connectWallet = useCallback(async (walletName) => {
    setConnecting(true);
    try {
      const walletApi = await getWalletApi(walletName);
      if (!walletApi) {
        return;
      }
      await getCardanoWalletInfo(walletApi, walletName);
      await addCardanoWallet(walletName);
    } catch (error) {
      toast('Failed to connect to wallet');
    }
    setConnecting(false);
  }, []);

  const getWalletApi = useCallback(async (walletName) => {
    try {
      const walletFound = !!window?.cardano?.[walletName];
      if (!walletFound) {
        toast('Wallet not found');
        return;
      }
      const walletApi = await window.cardano[walletName].enable();
      return walletApi;
    } catch (error) {
      if (walletName === 'eternl') {
        toast('Please enable the Eternl wallet to connect to DApps');
      } else {
        toast('Failed to connect to wallet');
      }
    }
  }, []);

  const disconnectWallet = useCallback(async () => {
    setWalletConnected(false);
    setAddress('');
    setCardanoWalletName('');
    setWalletConnected(false);
    setAssets([
      {
        assetName: '₳DA',
        unit: '',
      },
    ]);
    await clearCardanoWallet();
  }, []);

  const getCardanoWalletInfo = async (walletApi, walletName) => {
    const isStaging = getEnviorment();
    const rawUtxos = await walletApi.getUtxos();
    const changeAddress = await walletApi.getChangeAddress();
    const cardanoWalletInfo = await formatCardanoWalletInfo(
      rawUtxos,
      changeAddress,
      isStaging
    );
    setAssets([
      {
        assetName: '₳DA',
        unit: '',
      },
      ...cardanoWalletInfo.assets,
    ]);
    setWalletConnected(true);
    setBountyCredit(cardanoWalletInfo.walletBountyCredit);
    setCardanoWalletName(walletName);
    setAddress(cardanoWalletInfo.walletAddress);
  };

  useEffect(() => {
    async function init() {
      await reconnectUserWallet();
    }
    init();
  }, []);

  const reconnectUserWallet = useCallback(async () => {
    const walletName = await getUserCardanoWallet();
    if (walletName) {
      const wallet = await getWalletApi(walletName);
      await getCardanoWalletInfo(wallet, walletName);
    }
  }, []);

  const payAdaTokenBounty = useCallback(
    async (unit, quantity, bountyId, walletName, walletAddress) => {
      const wallet = await getWalletApi(walletName);
      const rawUtxos = await wallet.getUtxos();
      const changeAddress = await wallet.getChangeAddress();
      const constructedTx = await generateCardanoTokenTx(
        rawUtxos,
        changeAddress,
        unit,
        quantity,
        walletAddress
      );
      const vkeyWitness = await wallet.signTx(constructedTx);
      const txHash = await sendAdaTokenBounty(
        constructedTx,
        bountyId,
        vkeyWitness
      );
      toast('Bounty charged successfully!');
      return txHash;
    },
    []
  );

  const payAdaBounty = async (
    bountyAmount,
    bountyId,
    walletName,
    useBountyCredit,
    useBountyCreditAmount,
    walletAddress
  ) => {
    try {
      const lovelaceAmount = Math.trunc(
        bountyAmount * amountOfLovelaceInOneAda
      );
      const remainingFee =
        (bountyAmount - useBountyCreditAmount) * amountOfLovelaceInOneAda;
      const wallet = await getWalletApi(walletName);
      const rawUtxos = await wallet.getUtxos();
      const changeAddress = await wallet.getChangeAddress();
      let txHash;
      if (useBountyCredit) {
        txHash = await consumeAdaBountyCredit(
          useBountyCreditAmount,
          rawUtxos,
          remainingFee,
          walletAddress,
          bountyId,
          changeAddress,
          wallet
        );
      } else {
        const constructedTx = await generateAdaBountyTx(
          rawUtxos,
          changeAddress,
          lovelaceAmount,
          walletAddress
        );
        const vkeyWitness = await wallet.signTx(constructedTx);
        txHash = await sendAdaBounty(constructedTx, bountyId, vkeyWitness);
      }
      toast('Bounty charged successfully!');
      return txHash;
    } catch (error) {
      toast(error.response?.data?.error || 'Bounty charged failed!');
    }
  };

  const consumeAdaBountyCredit = async (
    useBountyCreditAmount,
    rawUtxos,
    remainingFee,
    walletAddress,
    bountyId,
    changeAddress,
    wallet
  ) => {
    const isStaging = getEnviorment();
    const constructedTx = await generateAdaBountyTx(
      rawUtxos,
      changeAddress,
      remainingFee,
      walletAddress
    );
    const vKeyWitness = await wallet.signTx(constructedTx);
    const txHash = await submitConsumeBountyCreditTx(
      constructedTx,
      bountyId,
      vKeyWitness,
      useBountyCreditAmount,
      walletAddress,
      isStaging
    );
    if (txHash.netWorkBusy) {
      toast(
        'Network is busy but we have added your transaction to the queue.It will be processed within 10 minutes. If it is not processed in 10 minutes, please contact support.'
      );
    }
    return txHash;
  };

  const increaseAdaBounty = useCallback(
    async (
      lovelaceAmount,
      bountyId,
      increaseAmount,
      walletName,
      walletAddress
    ) => {
      const wallet = await getWalletApi(walletName);
      const rawUtxos = await wallet.getUtxos();
      const changeAddress = await wallet.getChangeAddress();
      const constructedTx = await generateAdaBountyTx(
        rawUtxos,
        changeAddress,
        lovelaceAmount,
        walletAddress
      );
      const vkeyWitness = await wallet.signTx(constructedTx);
      const txHash = await increaseAdaBountyApi(
        constructedTx,
        bountyId,
        increaseAmount,
        vkeyWitness
      );
      toast('Bounty amount increased successfully!');
      return txHash;
    },
    []
  );

  const connectPhantomWallet = useCallback(async () => {
    if (!solanaProvider) {
      window.open('https://phantom.app/', '_blank');
      return;
    }
    await solanaProvider.connect();
  }, []);

  const disconnectPhantomWallet = useCallback(async () => {
    solanaProvider.disconnect();
  }, []);

  useEffect(() => {
    if (solanaProvider) {
      solanaProvider.on('connect', (publicKey) => {
        setSolanaAddress(publicKey.toString());
        setSolanaConnected(true);
      });
      solanaProvider.on('disconnect', () => {
        setSolanaAddress('');
        setSolanaConnected(false);
      });
    }
  }, [solanaAddress]);

  return (
    <WalletContext.Provider
      value={{
        assets,
        address,
        connecting,
        walletConnected,
        connectWallet,
        disconnectWallet,
        supportedWallets,
        payAdaBounty,
        increaseAdaBounty,
        payAdaTokenBounty,
        cardanoWalletName,
        bountyCredit,
        amountOfLovelaceInOneAda,
        connectPhantomWallet,
        disconnectPhantomWallet,
        solanaAddress,
        solanaConnected,
        solanaProvider,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export { WalletProvider, useCardanoWallet, WalletContext };
