import { GoogleLogin } from '@react-oauth/google';

export default function GoogleForm({ login }) {
  return (
    <GoogleLogin
      theme="filled_blue"
      width={window.innerWidth * 0.2 + 45}
      size="large"
      onSuccess={(credentialResponse) => {
        login(credentialResponse.credential, 'oauth');
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  );
}
