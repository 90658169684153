import React, { createContext, useContext } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as BountyApi from '../api/bounties';
import * as JobApi from '../api/opportunities';
import { useAuth } from './authContext';

const JobContext = createContext();
const useJobForm = () => useContext(JobContext);

const JobProvider = ({ children, job }) => {
  const skillNames = job ? job?.skillIds?.map((value) => value.name) : [];
  const { auth } = useAuth();
  const linkValidator = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /(^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$)|(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))/
  );
  const isContainLink = new RegExp(/^((?!(http|https|ftp)).)*$/);

  const defaultJob = {
    _id: '',
    title: '',
    taskDescription: '',
    submissionRequirements:
      '- W-2 only or Contract-to-Hire only \n - US Citizens or Green Card Holders Only',
    acceptenceCriteria:
      '- Build and maintain strong relationships with existing clients to foster repeat business \n -Negotiate and close sales deals to meet or exceed sales targets.\n -Provide exceptional customer service and resolve any issues or concerns promptly\n',
    importantLink: '',
    timeEstimation: '',
    coupon: '',
    validCoupon: false,
    minCompensation: 0,
    maxCompensation: '',
    rewardType: 'USD',
    jobListingType: 'Regular',
    type: 'Job',
    timeEstimationType: 'Hours',
    logo: null,
    skills: skillNames ? [...skillNames] : [],
    selectedSkills: skillNames ? [...skillNames] : [],
    jobFee: 200,
    companyId: auth?.company?._id,
    userId: auth?.user?._id,
    workplaceType: 'On-site',
    jobType: 'Full-time',
    location: '',
    rewardNetwork: '',
    rewardAmount: '',
    isTech: false,
    compensationCycle: 'Annually',
    paymentType: 'Bank',
    cryptoInfo: {
      name: '',
      fromCurrency: 'ada',
      fromNetwork: 'ada',
      fromAmount: 0,
      toAmount: 0,
      toCurrency: 'usdt',
      toNetwork: 'usdt',
      selectedImg:
        'https://content-api.changenow.io/uploads/ada_fb42809541.svg',
      toAddress: '',
      rateId: '',
      withdrawalFee: '',
      depositFee: '',
      exchangeId: 1,
    },
  };

  const jobForm = useFormik({
    enableReinitialize: true,
    initialValues: job ? { ...defaultJob, ...job } : defaultJob,
    validationSchema: Yup.object({
      title: Yup.string()
        .matches(
          /^((?![^a-zA-Z0-9,:; ./-]).)*$/,
          'Title is invalid ( should not contain special character )'
        )
        .min(2, 'Title must contain at least 2 characters')
        .matches(isContainLink, 'Title must not contain a link')
        .trim()
        .required('Title is required'),
      importantLink: Yup.string()
        .matches(linkValidator, 'Must be a valid link')
        .required('Reference Link is required'),
      taskDescription: Yup.string()
        .min(20, 'Job description must contain at least 20 characters')
        .trim()
        .required('Job description is required'),
      submissionRequirements: Yup.string()
        .min(2, 'Submission Requirements must contain at least 2 characters')
        .trim()
        .required('Submission Requirements is required'),
      rewardType: Yup.string().required('Compensation Type is required'),
      minCompensation: Yup.number()
        .min(1, 'Minimum amount cannot be less than 1')
        .required('Minimum Compensation Amount is required'),
      maxCompensation: Yup.number().min(1, 'Invalid compensation Amount'),
      jobFee: Yup.number().when(['validCoupon'], {
        is: (validCoupon) => !validCoupon,
        then: Yup.number()
          .min(200, 'Minimum amount is 200')
          .required('Job Processing Fee is required'),
        otherwise: Yup.number().required('Job Processing Fee is required'),
      }),
      selectedSkills: Yup.array().of(Yup.string()).min(1, 'Skills is required'),
      location: Yup.string().required('Location is required'),
    }),
    onSubmit: async (values) => {
      if (values._id) {
        let bounty = await JobApi.editOpportunity(values._id, values);
        if (!bounty) {
          bounty = await JobApi.getOpportunity(values._id);
        }
        return bounty;
      } else {
        const body = {
          ...values,
        };
        const bounty = await JobApi.createOpportunity(body);
        jobForm.handleChange({
          target: { name: '_id', value: bounty._id },
        });
        return bounty;
      }
    },
  });

  const resetJobForm = async () => {
    jobForm.resetForm();
  };

  const updateJob = async (data) => {
    const body = {
      ...data,
      _id: jobForm.values._id,
    };
    const { bounty } = await JobApi.editOpportunity(body._id, body);
    return bounty;
  };

  const createJob = async (data) => {
    const body = {
      ...data,
    };
    const { bounty } = await JobApi.createOpportunity(body);
    return bounty;
  };

  return (
    <JobContext.Provider
      value={{
        ...jobForm,
        isEditJob: jobForm.values._id,
        updateJob,
        createJob,
        // verifyCoupon,
        // activeCoupon,
        resetJobForm,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

export { JobProvider, JobContext, useJobForm };
