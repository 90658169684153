import axios from 'axios';

export async function getChats(opportunityId, participant) {
  const response = await axios.get(
    `/api/v1/chats/business?opportunityId=${opportunityId}&participant=${participant}`
  );
  return response.data;
}
export async function getLatestChats(opportunityId, participant) {
  const response = await axios.get(
    `/api/v1/chats/business?opportunityId=${opportunityId}&participant=${participant}`
  );
  return response.data;
}
export async function sendChat(chatData) {
  const response = await axios.post('/api/v1/chats/business', chatData);
  return response.data;
}
export async function editChat(chatData, id) {
  const response = await axios.put(`/api/v1/chats/business/${id}`, chatData);
  return response.data;
}
export async function upload(formData) {
  const response = await axios.post('/api/v1/chats/business/upload', formData);
  return response.data;
}
export async function download(file) {
  const response = await axios.get(`/api/v1/chats/business/download/${file}`, {
    responseType: 'blob',
  });
  return response.data;
}
