import { useState } from 'react';
import { createSalesUser } from '../../api/sales';
import { toast } from 'react-toastify';

const RegisterUserViewModel = () => {
  const [submitted, setSubmitted] = useState(false);
  // const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const _mapToRegisterUserData = (values) => {
    if (!values) {
      return null;
    }
    return {
      fullName: values.fullName,
      title: values.title,
      company: values.companyName,
      email: values.email,
      acceptTerms: true,
    };
  };

  async function submitUserRegistration(values) {
    let result = undefined;
    try {
      createSalesUser(_mapToRegisterUserData(values))
        .then((data) => {
          toast.success(`User Created successfully`);
          result = data;
        })
        .catch((error) => {
          toast.error(`User already exist`);
        });
      setSubmitted(true);
    } catch (error) {
      setError(error);
    }
    return result;
  }

  return {
    submitted,
    error,
    submitUserRegistration,
  };
};
export default RegisterUserViewModel;
