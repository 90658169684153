import React, { useEffect } from 'react';
import { useAuth } from '../context/authContext';
import { useLocation } from '@reach/router';
import qs from 'query-string';

const ConfirmUser = ({ id }) => {
  const { confirmUser } = useAuth();

  const search = useLocation().search;
  const email = qs.parse(search).email;

  useEffect(() => {
    confirmUser(id, email);
  }, [id]);
  return <div></div>;
};
export default ConfirmUser;
