import axios from 'axios';

// jobs axios request

export async function createJob(data) {
  const response = await axios.post(`/api/v1/jobs`, data);
  return response.data;
}

export async function editJob(data, id) {
  const response = await axios.put(`/api/v1/jobs/${id}`, data);
  return response.data;
}

export async function getUserJob(id) {
  const response = await axios.get(`/api/v1/jobs/${id}`);
  return response.data;
}

export async function createJobApplicant(id, data) {
  const response = await axios.post(`/api/v1/jobs/${id}/applicant`, data);
  return response.data;
}

export async function editJobApplication(id, jobId, data) {
  const response = await axios.put(
    `/api/v1/jobapplicant/${jobId}/application/${id}`,
    data
  );
  return response.data;
}

export async function createJobPaymentFees(id, data) {
  const response = await axios.post(`/api/v1/jobs/${id}/payment/stripe`, data);
  return response.data;
}

export async function confirmJobPaymentFees(id, data) {
  const response = await axios.put(`/api/v1/jobs/${id}/payment/stripe`, data);
  return response.data;
}

// jobs ->> fields and skills axios requests

// export async function createField(data) {
//   const response = await axios.post(`/api/v1/field`, data);
//   return response.data;
// }

// export async function GetField() {
//   const response = await axios.get(`/api/v1/field`);
//   return response.data;
// }

export async function createSkill(data) {
  const response = await axios.post(`/api/v1/skills`, data);
  return response.data;
}

export async function getSkill() {
  const response = await axios.get(`/api/v1/skills`);
  return response.data;
}

// get job applicants

export async function getApplicants(id) {
  const response = await axios.get(`/api/v1/jobs/${id}/applicants`);
  return response.data;
}

// email requests

export async function getEmail(id, applicant, status) {
  const response = await axios.get(
    `/api/v1/jobs/${id}/applicants/${applicant}/template/${status}`
  );
  return response.data;
}

export async function sendEmailNoti(id, applicant, data) {
  const response = await axios.post(
    `/api/v1/jobs/${id}/applicants/${applicant}/email`,
    data
  );
  return response.data;
}
