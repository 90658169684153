import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchIcon from '../../assets/icon/snapbrillia_search_icon.svg';
import '../../shared/css/bgColors.css';
import '../css/searchBarStyles.css';
import SearchBarIcons from './searchBarIcons';
import { navigate } from '@reach/router';

export default function SearchBar() {
  return (
    <Container className="pagemainbg search-container">
      <Row className="position-relative">
        <Col xs={5} md={5} xxl={5}>
          {/* <input
            className="input-search mb-md-3 mb-xxl-0"
            placeholder="Search"
            type="text"
            onChange={(e) => handleSearch(e)}
          />
          <img src={SearchIcon} alt="share-icon" className="search-bar-icon" />
          {fetchedData.length > 0 && (
            <div className="navbar-fetched-items">
              {fetchedData.map((data, i) => (
                <span
                  className="navbar-search-item"
                  key={i}
                  onClick={() => {
                    navigate(`${data.link}`);
                  }}
                >
                  {data.name} ({data.type})
                </span>
              ))}
            </div>
          )} */}
        </Col>

        <SearchBarIcons />
      </Row>
    </Container>
  );
}
