import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BountiesIntroduction from './components/bountiesIntroduction';
import Logo from '../assets/SnapBetaLogo2.svg';

import BountiesForm from './components/bountiesForm.jsx';
import { getRepository } from '../api/githubApp.js';
import { useOnBoarding } from '../context/onboardingContext';
import { navigate } from '@reach/router';

const BountiesSetup = () => {
  const { url, repoInfo: selectedRepo } = useOnBoarding();
  const [repo, setRepo] = useState(null);
  useEffect(() => {
    const loadRepo = async () => {
      try {
        const fullRepoInfo = await getRepository(url);
        setRepo({
          ...fullRepoInfo,
          repoBranch: selectedRepo.repoBranch,
        });
      } catch (err) {
        setRepo({
          ...selectedRepo,
          languages: {},
        });
      }
    };
    if (!url) {
      navigate('/setup-assessment');
    } else {
      loadRepo();
    }
  }, [url]);
  return (
    <>
      {repo && (
        <Container fluid className="setup-team-main-container">
          <Row>
            <Col
              style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}
            >
              <img src={Logo} alt="logo" />
            </Col>
          </Row>
          <Row className="setup-add-team-content">
            <Col xs={12} md={6} className="setup-add-project-left-col">
              <BountiesIntroduction repo={repo} />
            </Col>
            <Col xs={12} md={6} className="setup-add-project-right-col">
              <BountiesForm repo={repo} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default BountiesSetup;
