import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from '@reach/router';

//COMPONENTS
import PrimarySidebar from '../shared/sidebar/primarySidebar';
import PrimaryTopBar from '../shared/topbar/PrimaryTopBar';
import { default as SearchBar } from '../searchNavbar/navbar';
import ChartGridAnalytics from './components/chartGridAnalytics';
import { getEEOCompany } from '../api/opportunities';
import { useAuth } from '../context/authContext';

const AnalyticsPage = () => {
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(state ? state.from : false);
  const { auth } = useAuth();

  return (
    <Container
      style={{
        overflow: 'auto',
      }}
      className=""
      fluid
    >
      <Row className="d-flex flex-row flex-nowrap vw-100">
        <PrimarySidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          component="analytics"
        />

        <Col
          id="main-panel"
          xs={12}
          md={10}
          lg={10}
          style={{
            width: collapsed ? '93vw' : null,
            transition: 'all 0.8s ease-in-out',
            zIndex: 100,
          }}
          className="bounties_main_panel primary-main-panel pagemainbg"
        >
          <PrimaryTopBar component="dashboard" />
          <Row>
            <h1 className="primary-title h1">Analytics</h1>
          </Row>
          <div className="bounty_status_bar  d-none d-sm-none d-md-block d-lg-block">
            <SearchBar />
          </div>
          <Row
            style={{ paddingBottom: '20px' }}
            className="project-page-tabs bounties_masked_overflow"
          >
            <div
              style={{
                backgroundColor: '#fff',
                borderRadius: '20px',
                boxShadow: '1px 1px 10px 5px #dedede',
                width: '93%',
                height: '100%',
                maxWidth: '93%',
                position: 'relative',
                top: '40px',
                left: '4%',
                paddingBottom: '10px',
              }}
            >
              <ChartGridAnalytics
                bountyId={auth.user.companyId}
                rowsToDisplay={3}
                rowsHeight={200}
                showTitle={true}
                urlRoutes={getEEOCompany}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AnalyticsPage;
