import React from 'react';
import '../../shared/css/typography.css';
import { Container, Row, Col } from 'react-bootstrap';
import AllBarCharts from '../../charts/allBarCharts';

const ResponseRate = ({
  bountyId,
  WSPId,
  status = 'all',
  width = 250,
  height = 100,
  vertical = false,
  dataType = 'responseRate',
  showAxis = false,
  showTitle = false,
}) => {
  return (
    <Container>
      <Row>
        <Col style={{ marginBottom: '0px' }}>
          <h2
            style={{
              fontSize: '18px',
              fontFamily: 'Roboto',
              color: '#2D2B6F',
            }}
          >
            Response Rate
          </h2>
        </Col>
      </Row>
      <Row style={{ paddingTop: '0px', marginTop: '0px' }}>
        <Col>
          <AllBarCharts
            vertical={vertical}
            width={width}
            height={height}
            bountyId={bountyId}
            WSPId={WSPId}
            dataType={dataType}
            status={status}
            showAxis={showAxis}
            showTitle={showTitle}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ResponseRate;
