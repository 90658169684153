import React from 'react';
import ExcelFileIcon from '../../assets/icon/snapbrillia_excel_icon.svg';
import GreenCheckIcon from '../../assets/icon/snapbrillia_check_icon.svg';
import RedCrossIcon from '../../assets/icon/snapbrillia_red_cross_icon.svg';

import '../css/analyzingFilesResults.css';

const AnalyzingFilesResults = ({ uploadState, fileNames, errors, success }) => {
  return (
    <div>
      <div className="analyzing-files-top" style={{ marginBottom: '20px' }}>
        <img src={ExcelFileIcon} alt="excelfile-icon" />
        <span style={{ marginLeft: '10px', fontSize: '16px' }}>
          {fileNames}
        </span>
      </div>
      {uploadState === 0 && (
        <div className="analyzing-files-status">
          <span>Analyzing Data...</span>
        </div>
      )}
      {uploadState === 1 && (
        <div className="analyzing-results-success">
          <img
            src={GreenCheckIcon}
            alt="greencheck-icon"
            height={30}
            width={30}
          />
          <div>
            <p className="analyzing-paragraph-text">
              <span className="analyzing-results-success-text">Success</span>
              <br />
              {`Found ${success} rows of candidate data with no errors`}
            </p>
          </div>
        </div>
      )}
      {uploadState === 2 && (
        <div className="analyzing-results-errors">
          <img src={RedCrossIcon} alt="redcross-icon" height={30} width={30} />
          <div>
            <p className="analyzing-paragraph-text">
              <span className="analyzing-results-error-text">Errors</span>
              <br />
              {`Found ${errors} rows of candidate data with errors`}
            </p>
          </div>
        </div>
      )}
      {uploadState === 3 && (
        <div className="analyzing-results-mix">
          <div>
            <p className="analyzing-results-mix-text">Warning</p>
            <p className="analyzing-results-mix-paragraph">
              <img
                src={GreenCheckIcon}
                alt="greencheck-icon"
                height={15}
                width={15}
              />
              <span
                style={{ marginLeft: '10px' }}
              >{`Found ${success} rows of candidate data with no errors`}</span>
              <br />
              <img
                src={RedCrossIcon}
                alt="redcross-icon"
                height={15}
                width={15}
              />
              <span
                style={{ marginLeft: '10px' }}
              >{`Found ${errors} rows of candidate data with errors`}</span>
            </p>
            <p style={{ marginLeft: '23px' }}>
              Select Ignore All Import Errors to Proceed...
            </p>
          </div>
        </div>
      )}
      {uploadState === 4 && (
        <div className="analyzing-results-errors">
          <img src={RedCrossIcon} alt="redcross-icon" height={30} width={30} />
          <div>
            <p className="analyzing-paragraph-text">
              Invalid File Type, please use one of these files.
              <br />
              Resumes - PDF, DOC, DOCX, and TXT
              <br />
              Spreadsheets - CSV, XLSX, and XLS
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyzingFilesResults;
