import React from 'react';
import '../css/projectDetails.css';
import { Container, Row, Col } from 'react-bootstrap';
import { correctlyCap, formatDate } from '../../utils/functions';

const ProjectDetailHeader = ({ workSampleProject }) => {
  const expiredDate = (date) => {
    const currentDate = new Date(date);
    currentDate.setTime(
      currentDate.getTime() +
        workSampleProject.daysToComplete * 24 * 60 * 60 * 1000
    );
    return currentDate;
  };

  const createdAt = formatDate(workSampleProject.createdAt);
  const expiration = formatDate(expiredDate(workSampleProject.createdAt));
  const duration = workSampleProject.duration;
  const skills = workSampleProject.mandatoryskillsTested;

  return (
    <Container>
      <Row>
        <Col md={3}>
          <h6 className="project-details-title" data-testid="date">
            {createdAt}
          </h6>
          <p className="label">Date Created</p>
        </Col>
        <Col md={3}>
          <h6 className="project-details-title" data-testid="expiration">
            {expiration}
          </h6>
          <p className="label">Expiration</p>
        </Col>
        <Col md={3}>
          <h6 className="project-details-title" data-testid="duration">
            {duration === 1 ? `${duration} Minute` : `${duration} Minutes`}
          </h6>
          <p className="label">Time Allowed</p>
        </Col>
        <Col md={3}>
          {skills?.map((skill, index) => {
            return (
              <p
                className="skillpills"
                key={index}
                data-testid={`skills-${index}`}
              >
                {correctlyCap(skill)}
              </p>
            );
          })}
          <p className="grey-title text-start ms-2">Skills</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectDetailHeader;
