import { useState } from 'react';
import '../css/sharedStyles.css';
import '../../policyPage/css/policyComponents.css';

export default function TermsOfServiceSideMenu({
  setPolicy,
  setTitle,
  setHash,
  policy,
}) {
  const [active, setActive] = useState(0);

  const handleClick = (policy, title, hash) => {
    setPolicy(policy);
    setActive(policy);
    setTitle(title);
    setHash(hash);
  };

  return (
    <div className="menulabel">
      <div
        className={
          active === 0
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(0, 'Use Policies', '#Term-of-Use')}
      >
        <p data-testid="termsofuse_sidebar" style={{ marginLeft: '15px' }}>
          Terms of Use
        </p>
      </div>
      <div
        className={
          active === 1
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() =>
          handleClick(1, 'General Privacy Policy', '#General-Privacy-Policy')
        }
      >
        <p
          data-testid="generalprivacypolicy_sidebar"
          style={{ lineHeight: '1', marginLeft: '15px' }}
        >
          General Privacy
          <br /> Policy
        </p>
      </div>
      <div
        className={
          active === 2
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(2, 'Cookie Policy', '#Cookie-Policy')}
      >
        <p data-testid="cookiepolicy_sidebar" style={{ marginLeft: '15px' }}>
          Cookie Policy
        </p>
      </div>
      <div
        className={
          active === 3
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() =>
          handleClick(
            3,
            'California Consumer Privacy Notice',
            '#California-Consumer-Privacy-Notice'
          )
        }
      >
        <p
          data-testid="californiaconsumerprivacynotice_sidebar"
          style={{ lineHeight: '1', marginLeft: '15px' }}
        >
          California Consumer
          <br />
          Privacy Notice
        </p>
      </div>
      <div
        className={
          active === 4
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() =>
          handleClick(4, 'EU User Privacy Policy', '#EU-User-Privacy-Policy')
        }
      >
        <p
          data-testid="euuserprivacypolicy_sidebar"
          style={{ lineHeight: '1', marginLeft: '15px' }}
        >
          EU User Privacy <br />
          Policy
        </p>
      </div>
      <div
        className={
          active === 5
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() =>
          handleClick(
            5,
            'Data Processing and Security Addendum',
            '#Data-Processing-and-Security-Addendum'
          )
        }
      >
        <p
          data-testid="dataprocessingandsecurityaddendum_sidebar"
          style={{ lineHeight: '1', marginLeft: '15px' }}
        >
          Data Processing and Security <br /> Addendum
        </p>
      </div>
      <div
        className={
          active === 6
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() =>
          handleClick(
            6,
            'Vulnerability Disclosure',
            '#Vulnerability-Disclosure'
          )
        }
      >
        <p
          data-testid="vulnerabilitydisclosure_sidebar"
          style={{ lineHeight: '1', marginLeft: '15px' }}
        >
          Vulnerability <br /> Disclosure
        </p>
      </div>
      <div
        className={
          active === 7
            ? 'menu_item_selected left-align-side-bar'
            : 'left-align-side-bar'
        }
        onClick={() => handleClick(7, 'MSA Policy')}
      >
        <p
          data-testid="msa_policy_sidebar"
          style={{ lineHeight: '1', marginLeft: '15px' }}
        >
          MSA Policy
        </p>
      </div>
    </div>
  );
}
