export const inviteInterviewerEmail = `
<div style="display:flex">
    <p style="line-height:0">Hi {{fullName}},</p>
</div>
<p style="text-align:left">
    Here’s the link to join as interviewer for {{jobTitle}} at {{companyName}}.
    Please make sure you have <strong>{{duration}} minutes</strong> and that you are in an environment conducive to a code pairing interview at the appointed time. Have fun!
</p>
<p style="text-align:left">You have been invited to to join as interviewer for {{jobTitle}} at {{companyName}}.
    Please make sure you have <strong>{{duration}}</strong> <strong>minutes</strong> and that you are in an environment conducive to completing this work sample.
    Have fun!
</p>
<div style="background:#f1f2f6;padding:8px">
    <div style="text-align:center">
        <p>Duration: <strong>{{duration}} minutes</strong></p>
    </div>
    <center>
        <a href="{{assessmentLink}}" style="background:#A900A6;color:#fff;text-decoration:none;padding:8px;margin:24px 0;border-radius:16px">Start Work Sample</a>
    </center>
    <div style="text-align:center">
        <p>You can also use <a rel="noopener noreferrer" href="{{assessmentLink}}">the link</a> to access the work sample at any time. <br>Clicking this link will not start the timer.</p>
    </div>
</div><br>
I recommend accessing this work sample on a Chrome browser and a computer with a solid internet connection.
<div style="display:flex">
    <p style="line-height:0">Thank you,</p>
</div>
<address style="text-align:left">
    <img src="{{companyLogo}}" alt="company-logo" height="70">
    <p>
        {{senderName}}<br>
        {{title}} at <span>{{companyName}}</span>
    </p>
</address>
<p style="text-align:left">
    ---
</p>
<p style="text-align:left">
    IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.
</p>`;
