import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '../../assets//icon/snapbrillia_edit_icon.svg';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import '../css/bountiesIntroduction.css';
import { useOnBoarding } from '../../context/onboardingContext';
const colorBase = {};
const AboutRepo = ({ repo }) => {
  const { url } = useOnBoarding();
  const totalLines =
    Object.values(repo.languages).reduce((a, b) => a + b, 0) || 1;
  const [bountiData, setBountiData] = useState({
    repoName: {
      title: repo.name,
      isEditing: false,
    },
    about: {
      desc: repo.description,
      isEditing: false,
    },
    languages: repo.languages,
    repoUrl: {
      url: url,
      isEditing: false,
    },
  });

  const { repoName, about, languages, repoUrl } = bountiData;

  const repoRef = useRef();
  const aboutRef = useRef();
  const repoUrlRef = useRef();

  const getRandomColor = (language) => {
    if (language in colorBase) {
      return colorBase[language];
    } else {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      colorBase[language] = color;
      return color;
    }
  };

  useEffect(() => {
    if (repoName.isEditing) {
      repoRef.current.focus();
    }
    if (about.isEditing) {
      aboutRef.current.focus();
    }
    if (repoUrl.isEditing) {
      repoUrlRef.current.focus();
    }
  }, [repoName.isEditing, about.isEditing, repoUrl.isEditing]);

  const handleChange = (e) => {
    setBountiData({
      ...bountiData,
      [e.target.id]: {
        [e.target.name]: e.target.value,
        isEditing: true,
      },
    });
  };

  const handleEditClick = (value) => {
    switch (value) {
      case 'repoName':
        setBountiData({
          ...bountiData,
          repoName: {
            ...repoName,
            isEditing: !repoName.isEditing,
          },
        });
        break;

      case 'about':
        setBountiData({
          ...bountiData,
          about: {
            ...about,
            isEditing: !about.isEditing,
          },
        });
        break;

      case 'repoUrl':
        setBountiData({
          ...bountiData,
          repoUrl: {
            ...repoUrl,
            isEditing: !repoUrl.isEditing,
          },
        });
        break;

      default:
        return;
    }
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col className="col-2">
          <span className="extra-small-text">Repo Title:</span>
        </Col>
        <Col>
          <span
            id="repoName"
            onClick={() => handleEditClick('repoName')}
            className="extra-small-text pointer"
          >
            {repoName.isEditing ? 'Save' : 'Edit'}{' '}
            <img
              src={EditIcon}
              alt="edit-icon"
              className="bounti-intro-edit-icon"
            />
          </span>
        </Col>
        <span className="extra-small-text">
          {repoName.isEditing ? (
            <input
              className="edit-input-field"
              type="text"
              value={repoName.title}
              ref={repoRef}
              name="title"
              id="repoName"
              onChange={handleChange}
            />
          ) : (
            <span className="primary fs-5">{repoName.title}</span>
          )}
        </span>
      </Row>
      <Row className="mb-5">
        <Col className="col-2">
          <span className="extra-small-text">About:</span>
        </Col>
        <Col>
          <span
            onClick={() => handleEditClick('about')}
            className="extra-small-text pointer"
          >
            {about.isEditing ? 'Save' : 'Edit'}{' '}
            <img
              src={EditIcon}
              alt="edit-icon"
              className="bounti-intro-edit-icon"
            />
          </span>
        </Col>
        <span className="extra-small-text">
          {about.isEditing ? (
            <input
              className="edit-input-field"
              type="text"
              value={about.desc}
              ref={aboutRef}
              name="desc"
              id="about"
              onChange={handleChange}
            />
          ) : (
            <span className="primary fs-6">
              {about.desc || 'No description, website, or topics provided'}
            </span>
          )}
        </span>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={11} xl={8}>
          <span className="extra-small-text d-block mb-2">Languages:</span>
          <ProgressBar>
            {Object.keys(languages).map((language) => {
              return (
                <ProgressBar
                  key={language}
                  style={{ backgroundColor: getRandomColor(language) }}
                  now={(languages[language] / totalLines) * 100}
                />
              );
            })}
          </ProgressBar>
        </Col>
      </Row>
      <Row className="mt-2">
        {Object.keys(languages).map((language) => {
          return (
            <Col xs={12} lg={4} xxl={3} key={`${language}-detail`}>
              <span className="primary">
                <span className="bounties-dot dot-js"></span>
                {`${language} `}
                <span className="small-text">
                  {Math.round((languages[language] / totalLines) * 100)}%
                </span>
              </span>
            </Col>
          );
        })}
      </Row>
      <Row className="mt-5">
        <Col className="col-2">
          <span className="extra-small-text">Repo URL:</span>
        </Col>
        {/* <Col>
          <span
            onClick={() => handleEditClick('repoUrl')}
            className="extra-small-text pointer"
          >
            {repoUrl.isEditing ? 'Save' : 'Edit'}{' '}
            <EditIcon className="bounti-intro-edit-icon" />
          </span>
        </Col> */}
        <span className="primary">
          {repoUrl.isEditing ? (
            <input
              className="edit-input-field"
              type="text"
              value={repoUrl.url}
              ref={repoUrlRef}
              name="url"
              id="repoUrl"
              onChange={handleChange}
            />
          ) : (
            <a href={`${url}`} target="_blank" rel="noreferrer">
              <span className="primary">{repoUrl.url}</span>
            </a>
          )}
        </span>
      </Row>
    </Container>
  );
};

export default AboutRepo;
