import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PrimarySideMenu from '../../shared/menus/primarySideMenu';
// import RecentlyViewedSideMenu from '../../shared/menus/recentlyViewedSideMenu';
// import RecentlyEndedSideMenu from '../../shared/menus/recentlyEndedSideMenu';
import CopyRight from '../../shared/copyright/copyright';

import { ReactComponent as SnapbrilliaLogo } from '../../assets/icon/snapbrillia_icon.svg';
// import { ReactComponent as SnapbrilliaIcon } from '../../assets/icon/snapbrillia_icon.svg';
import { ReactComponent as CollapseButton } from '../../assets/icon/snapbrillia_expandcontract_icon.svg';
import { ReactComponent as Bars } from '../../assets/icon/snapbrillia_bars_icon.svg';
import './css/primaryTopBar.css';

const PrimaryTopBar = ({ component }) => {
  const [active, setActive] = useState(false);
  //Adding click handler onto logo for redirecting to landing page.
  useEffect(() => {
    let logo_image = document.querySelector('.logo-with-text');
    [logo_image] = logo_image.childNodes;
    [logo_image] = logo_image.childNodes;
    logo_image.classList.add('pointer');
    logo_image.addEventListener('click', () => {
      window.open('https://www.snapbrillia.com', '_self');
    });
  }, []);

  //add the mobile navbar
  const handleClick = () => {
    setActive(!active);
  };

  return (
    <Container className="primary-topbar" style={{ overflow: 'hidden' }} fluid>
      <Row>
        <Col xs={9}>
          <div
            className="logo-with-text"
            style={{
              position: 'relative',
              maxWidth: '200pt',
              maxHeight: '58pt',
              marginTop: '10px',
            }}
          >
            <SnapbrilliaLogo alt="snapbrillia-logo" />
          </div>
        </Col>

        <Col className="top-icon" onClick={handleClick}>
          <Bars />
        </Col>
        <Col
          xs={12}
          style={{
            position: 'relative',
          }}
          className={
            active
              ? 'mobile-dashboard-menu active profile_sidemenu'
              : 'mobile-dashboard-menu profile_sidemenu'
          }
        >
          <div
            style={{
              position: 'relative',
            }}
          >
            <PrimarySideMenu component={component} />
          </div>
        </Col>
      </Row>
      <Row className="primary-menu-tabs"></Row>
    </Container>
  );
};

export default PrimaryTopBar;
