import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GreenHouseIcon from '../../assets/integrations/greenhouse_integration_logo.svg';
import '../../shared/css/sharedStyles.css';
import '../greenhouse/css/allIntergrationsPage.css';

export default function AllIntegrationsCard() {
  return (
    <Container className="allIntegration-card-bg">
      <Row>
        <Col className="p-2">
          <img src={GreenHouseIcon} alt="greenhouse-icon" />
        </Col>
        <Col className="greenhouse-connect-text py-3">Connect</Col>
      </Row>
      <div className="integration_description_text">
        Get Top Talent With A Powerful Sourcing Strategy. Greenhouse Helps You
        Be Great At Hiring. Improve ROI for New Hires. Achieve Hiring Goals.
        Conduct Better Interviews. 100+ Partner Integrations. Impress
        Candidates. Maintain Company Culture.
      </div>
      <div className="integration_description_text pb-3">
        Status:&nbsp;
        <span className="bold">Not Connected</span>
      </div>
    </Container>
  );
}
