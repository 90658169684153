import React, { createContext, useState, useMemo, useContext } from 'react';
import {
  getEstimate,
  getEstimateRange,
  getExchange,
  getSupportedCurrencies,
} from '../api/crypto';
import { useGigForm } from '../context/gigContext';
import { useJobForm } from '../context/jobContext';

const CryptoContext = createContext();

const useCrypto = () => useContext(CryptoContext);

const ContextViews = ({ children, type }) => {
  const [amount, setAmount] = useState(0);
  const [val, setValues] = useState({
    fromCurrency: 'ada',
    fromNetwork: 'ada',
    toCurrency: 'USDT',
    toNetwork: 'ethereum',
    selectedImg: 'https://content-api.changenow.io/uploads/ada_fb42809541.svg',
    convertImg: '',
    flow: '',
    fromAmount: 1000,
    toAmount: '',
    toAddress: '',
    rateId: '',
    withdrawalFee: '',
    depositFee: '',
  });
  const { handleChange, values } = type !== 'Job' ? useGigForm() : useJobForm();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [amountRange, setAmountRange] = useState({
    minAmount: 0,
    maxAmount: 0,
  });
  const [error, setError] = useState(false);
  const [exchange, setExchange] = useState({});

  const getEstimates = (fromCurrency, fromNetwork, fromAmount) => {
    setLoading(true);
    // console.log(val);
    getEstimate(fromCurrency, fromNetwork, fromAmount)
      .then((response) => {
        setLoading(false);
        handleChange({
          target: {
            name: 'cryptoInfo',
            value: {
              ...values.cryptoInfo,
              toAmount: response.toAmount,
              rateId: response.rateId,
              withdrawalFee: response.withdrawalFee,
              depositFee: response.depositFee,
            },
          },
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getEstimateRanges = (fromCurrency, fromNetwork) => {
    setLoading(true);
    getEstimateRange(fromCurrency, fromNetwork)
      .then((response) => {
        setLoading(false);
        setAmountRange(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const amountType = () => {
    let result = 0;
    if (type === 'Job') {
      result =
        Number(values.cryptoInfo.fromAmount) +
        Number(values.cryptoInfo.depositFee) +
        Number(values.cryptoInfo.withdrawalFee);
    } else {
      result =
        Number(values.cryptoInfo.fromAmount) +
        (Number(values.cryptoInfo.fromAmount) * 20) / 100 +
        Number(values.cryptoInfo.depositFee) +
        Number(values.cryptoInfo.withdrawalFee);
    }

    return Number(result);
  };

  const createExchange = async () => {
    setLoading(true);
    const response = await getExchange({
      fromCurrency: values.cryptoInfo.fromCurrency,
      fromNetwork: values.cryptoInfo.fromNetwork,
      fromAmount: amountType(),

      rateId: values.cryptoInfo.rateId,
    });
    setExchange(response);
    setLoading(false);
    return { load: true, response };
  };

  const memoedValue = useMemo(
    () => ({
      val: val,
      loading,
      setValues,
      getEstimates,
      setLoading,
      getEstimateRanges,
      error,
      setError,
      amount,
      setAmount,
      createExchange,
      amountRange,
      exchange,
      loaded,
    }),
    [loading, amount, error, val, amountRange, exchange]
  );

  return (
    <CryptoContext.Provider
      value={{
        val: val,
        loading,
        setLoading,
        setValues,
        getEstimates,
        getEstimateRanges,
        error,
        setError,
        amount,
        setAmount,
        createExchange,
        amountRange,
        exchange,
        setExchange,
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};
export { CryptoContext, ContextViews, useCrypto };
