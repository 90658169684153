import Modal from 'react-bootstrap/Modal';
import '../css/sharedStyles.css';
import InvoiceBountySteps from '../../invoicedBountySteps/invoiceBountySteps';
import { useState } from 'react';

export default function InvoiceBountyModal({
  show,
  closeModal,
  gig,
  companyName,
}) {
  const [step, setStep] = useState(1);
  return (
    <Modal show={show} onHide={closeModal} size="xl" centered>
      <Modal.Header
        className="modal-header-removal-hr"
        closeButton
      ></Modal.Header>
      <Modal.Body>
        <InvoiceBountySteps
          closeModal={closeModal}
          gig={gig}
          step={step}
          setStep={setStep}
          companyName={companyName}
        />
      </Modal.Body>
    </Modal>
  );
}
