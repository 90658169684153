import React from 'react';

const InterviewerWelcomingMessage = () => {
  return (
    <h1 className="candidate-waiting-room-title">
      The candidate will join you momentarily.
    </h1>
  );
};

export default InterviewerWelcomingMessage;
