import '../../shared/css/typography.css';

const AddCandidateMessage = () => {
  return (
    <div>
      <h3 className="h3 text-start mb-3">
        All tasks will be geared towards the skills and years of experience
        required.
      </h3>
      <h3 className="h3 text-start mb-3">
        After a candidate completes an Assessment, you will be able to see the
        task and answers they gave along with their score.
      </h3>
      <h3 className="h3 text-start mb-3">Time to invite some candidates!</h3>
    </div>
  );
};

export default AddCandidateMessage;
