//Components
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import StarIcon from '../assets/icon/snapbrillia_add_favorites_icon.svg';
import OnChainBountiesView from '../onchainReputation/components/onChainBountiesView';
import OnChainGrantsView from '../onchainReputation/components/onChainGrantsView';
import OnChainSkillsView from '../onchainReputation/components/onChainSkillsView';
//View model
import useViewModel from '../onchainReputation/viewModels/onChainResumeViewModel';
//CSS
import '../onchainReputation/css/onchainReputation.css';

const OnChainResumeView = ({ candidateId }) => {
  const { loaded, resume, getOnChainResume } = useViewModel();

  useEffect(() => {
    if (!loaded) {
      getOnChainResume(candidateId);
    }
  }, [candidateId, loaded]);

  return (
    <Container>
      {loaded && (
        <Row className="shadow py-3 rounded-2 px-2">
          <h5 className="grey-title p-0">
            <span className="bold">Snapbrillia</span> On-Chain Reputation
          </h5>
          <Col
            style={{ height: '130px' }}
            xs={12}
            className="on-chain-content-bg rounded-2 my-2 py-3"
          >
            <Row className="align-items-center">
              {resume.profilePhoto && (
                <Col xs={2}>
                  <img
                    src={resume.profilePhoto}
                    alt="profile"
                    height={100}
                    width={100}
                    style={{ borderRadius: '50%' }}
                  />
                </Col>
              )}

              <Col>
                <h3 className="primary bold">{resume.name}</h3>
                {[1, 2, 3, 4, 5].map(() => (
                  <span key={Math.random().toString(32)} className="mx-1 stars">
                    <img
                      src={StarIcon}
                      alt="star-icon"
                      width={18}
                      height={18}
                      // eslint-disable-next-line react/no-unknown-property
                      fill="#ffd800"
                    />
                  </span>
                ))}
              </Col>
              <Col className="grey-title text-end">
                <>
                  <p className="m-0">Total Participation</p>
                  <span className="d-block primary bold mb-2">
                    {resume.totalParticipation} Bounties
                  </span>
                </>
                <>
                  <p className="m-0">Total Contribution</p>
                  <span className="d-block primary bold">
                    {resume.totalContributions} Grants
                  </span>
                </>
              </Col>
            </Row>
          </Col>
          <Col
            className="on-chain-content-bg on-chain-bounties-wrapper rounded-2 me-2 py-2"
            xs={6}
          >
            <OnChainBountiesView bounties={resume.onChainBounties} />
          </Col>
          <Col className="p-0">
            <Col className="on-chain-skill-wrapper on-chain-content-bg rounded-2 pt-2">
              <OnChainSkillsView skills={resume.onChainSkills} />
            </Col>
            <Col className="on-chain-content-bg on-chain-grants-wrapper rounded-2 mt-2 py-2">
              <OnChainGrantsView grants={resume.onChainGrants} />
            </Col>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default OnChainResumeView;
