import * as yup from 'yup';
import { useAuth } from '../../context/authContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import { useState, useEffect } from 'react';
import EnableTwoFactorButton from '../../shared/button/enableTwoFactor';
import DisableTwoFactorButton from '../../shared/button/disableTwoFactor';
import { navigate } from '@reach/router';
import { Card, ListGroup } from 'react-bootstrap';

const EnableTwoFactor = ({
  qrImage,
  setupToken,
  enableTwoFactorHandler,
  sendTotpHandler,
  setTotpTokenHandler,
}) => {
  return (
    <Card>
      <Card.Img variant="top" src={qrImage} />
      <ListGroup className="list-group-flush">
        <ListGroup.Item className="text-center">{setupToken}</ListGroup.Item>
        <ListGroup.Item className="text-center">
          <Card.Link href="#" onClick={sendTotpHandler}>
            click here to receive your TOTP code via email.
          </Card.Link>
        </ListGroup.Item>
      </ListGroup>
      <Card.Body>
        <Form>
          <Form.Control
            type="text"
            placeholder="______"
            onChange={(e) => setTotpTokenHandler(e.target.value)}
          />
          <div className="btn-verified" style={{ marginTop: '10px' }}>
            <EnableTwoFactorButton onClickHandler={enableTwoFactorHandler} />
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

const DisableTwoFactor = ({ disableTwoFactorHandler }) => {
  return (
    <Container className="mx-auto">
      <Row>
        <div className="col-md-12 text-center">
          <DisableTwoFactorButton onClickHandler={disableTwoFactorHandler} />
        </div>
      </Row>
    </Container>
  );
};

const TwoFactorSetup = () => {
  const [setupToken, setSetupToken] = useState('');
  const [totpToken, setTotpToken] = useState('');
  const [qrImage, setQrImage] = useState('');
  const {
    auth,
    sendTotp,
    generateTotp,
    verifyTotp,
    disableTwoFactorVerification,
    logout,
  } = useAuth();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(
    auth?.user?.requiretwoFactor
  );
  const validationSchema = yup.object().shape({
    token: yup.number().required('Required').positive().integer(),
  });

  async function disableTwoFactor(e) {
    e.preventDefault();
    await disableTwoFactorVerification(auth.user._id);
    setTwoFactorEnabled(false);
    await logout();
    navigate('/signin');
  }
  async function sendTokenEmail(e) {
    e.preventDefault();
    await sendTotp(auth.user._id, 'email');
  }
  async function verifyTwoFactorToken(e) {
    e.preventDefault();
    if (await validationSchema.isValid({ token: totpToken })) {
      let response = await verifyTotp(auth.user._id, totpToken);
      if (response) {
        setTwoFactorEnabled(true);
        await logout();
        navigate('/signin');
      }
    }
  }

  useEffect(() => {
    if (!twoFactorEnabled) {
      generateTotp(auth.user._id).then((data) => {
        setSetupToken(data.secret);
        setQrImage(data.image);
      });
    }
  }, []);
  return (
    <Container className="mx-auto">
      {twoFactorEnabled ? (
        <DisableTwoFactor disableTwoFactorHandler={disableTwoFactor} />
      ) : (
        <EnableTwoFactor
          qrImage={qrImage}
          setupToken={setupToken}
          sendTotpHandler={sendTokenEmail}
          enableTwoFactorHandler={verifyTwoFactorToken}
          setTotpTokenHandler={setTotpToken}
        />
      )}
    </Container>
  );
};
export default TwoFactorSetup;
