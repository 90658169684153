import '../css/buttonStyles.css';

export default function ValidateTotpButton({ onClickHandler }) {
  return (
    <button
      className="btn-primary-medium"
      type="submit"
      onClick={onClickHandler}
    >
      Validate Code
    </button>
  );
}
