import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import ChartsBackend from './components/chartsBackend';
import { chartColors } from './components/chartColors';

// information regarding params for this chart can be found in the client-side README.md
// examples can be found in './chartsDemo.jsx'

function AllLineCharts({
  bountyId,
  WSPId,
  dataType,
  status,
  height,
  width,
  showAxis,
  dataType2,
}) {
  // use ref to allow d3 to have access to DOM
  const d3LineChart = useRef();

  const [isEmpty, setIsEmpty] = useState();
  const [xTickNumber, setXTickNumber] = useState([]);
  const [yTickValue, setYTickValue] = useState([]);

  // get data
  const { data, axisLabels, axisTitles, chartTitle } = ChartsBackend({
    bountyId,
    WSPId,
    dataType,
    dataType2,
    status,
  });

  // determine if data is empty
  useEffect(() => {
    let sum = data.reduce((a, b) => a + b, 0);
    if (sum === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [data]);

  // choose random color from the array
  const colors = chartColors;
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  //get the largest number in dataset for the domain and round up to nearest 10
  const domainHigh = Math.ceil(Math.max(...data) / 10) * 10;

  // get number of ticks in the x axis and the values for the ticks in the y axis
  useEffect(() => {
    // get number of ticks needed for x axis depending on dataType
    const xTickValues = [];
    for (let i = 0; i < axisLabels.length; i++) {
      xTickValues.push(i);
    }
    setXTickNumber(xTickValues);

    const yTickValues = [];
    for (let i = 1; i < 6; i++) {
      const interval = domainHigh / 5;
      yTickValues.push(interval * i);
    }
    setYTickValue(yTickValues);
  }, [axisLabels.length, domainHigh]);

  // re-render anytime data changes
  useEffect(() => {
    // set up margin for title
    const margin = { top: 30, right: 30, bottom: 30, left: 30 };

    // set up svg, get current value of ref and give it attributes to append
    const svg = d3
      .select(d3LineChart.current)
      .attr('width', width)
      .attr('height', height)
      .style('overflow', 'visible')
      .attr('class', 'svgContainer');

    // set up scaling
    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([0, width]);
    const yScale = d3.scaleLinear().domain([0, domainHigh]).range([height, 0]);
    const generateScaledLine = d3
      .line()
      .x((d, i) => xScale(i))
      .y(yScale)
      .curve(d3.curveCardinal);

    // show axes if showAxis is passed in as true
    if (showAxis === true) {
      // set up bottom axis
      const xAxis = d3.axisBottom(xScale);
      // hide bottom axis label if width is too small
      if (width >= 350) {
        xAxis.tickValues(xTickNumber).tickFormat((i) => axisLabels[i]);
      } else {
        xAxis.tickValues(xTickNumber).tickFormat('');
      }

      // set up y axis
      const yAxis = d3.axisLeft(yScale).ticks(5).tickValues(yTickValue);

      // clear all elements before every render or re-render to get a fresh display
      svg.selectAll('*').remove();

      // set up text for title
      svg
        .append('text')
        .attr('class', 'h3')
        .style('fill', colors[2])
        .attr('text-weight', 700)
        .attr('x', width / 2)
        .attr('y', 0 - margin.top)
        .attr('text-anchor', 'middle')
        .text(chartTitle);

      // render on the bottom by appending a group and setting up the elements
      svg.append('g').call(xAxis).attr('transform', `translate(0, ${height})`);
      // render y axis
      svg.append('g').call(yAxis);

      // set up title for x axis
      svg
        .append('text')
        .attr('class', 'x label')
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('y', height + margin.top + 10)
        .text(axisTitles[1]);

      // set up title for y axis
      svg
        .append('text')
        .attr('class', 'y label')
        .attr('text-anchor', 'middle')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2)
        .attr('y', -margin.left - 10)
        .text(axisTitles[0]);

      // set up data for svg by grabbing data, and passing it into scaledLine for the line to render
      svg
        .selectAll('.line')
        .data([data])
        .join('path')
        .attr('d', (d) => generateScaledLine(d))
        .attr('fill', 'none')
        .attr('stroke', randomColor)
        .attr('stroke-width', '5px');
    } else {
      // clear all elements before every render or re-render
      svg.selectAll('*').remove();
      // set up data for svg by grabbing data, and passing it into scaledLine for the line to render
      svg
        .selectAll('.line')
        .data([data])
        .join('path')
        .attr('d', (d) => generateScaledLine(d))
        .attr('fill', 'none')
        .attr('stroke', randomColor)
        .attr('stroke-width', '5px');
    }

    // set up data for svg by grabbing data, and passing it into scaledLine for the line to render
    d3.select()
      .selectAll('.line')
      .data([data])
      .join('path')
      .attr('d', (d) => generateScaledLine(d))
      .attr('fill', 'none')
      .attr('stroke', randomColor)
      .attr('stroke-width', '5px');

    // handle click event to display data
    const focusText = svg
      .append('text')
      .attr('class', 'clickedData')
      .attr('text-anchor', 'middle')
      .style('opacity', 0);

    const focus = svg
      .append('circle')
      .style('fill', 'none')
      .attr('stroke', 'black')
      .attr('r', 8.5)
      .attr('class', 'clickedData')
      .style('opacity', 0);

    // create text to display on data point when clicked
    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', () => {
        focus.style('opacity', 1);
        focusText.style('opacity', 1);
      })
      .on('mousemove', (event) => {
        // clears any previous text
        // svg.selectAll(".clickedData").remove();
        // gets the closest index of axisLabels on click
        const clickedIndex = Math.round(xScale.invert(d3.pointer(event)[0]));
        // get the number of ticks on the x axis, see which tick is closest to the pointer on click event
        const i = d3.bisect(xTickNumber, clickedIndex);
        const nearestValue = data[i - 1];
        const nearestLabel = axisLabels[i - 1];
        const displayText = `${nearestLabel} ${nearestValue}`;
        // bring in focus text by giving opacity and position
        focusText
          .text(displayText)
          .attr('x', xScale(xTickNumber[i - 1]))
          .attr('y', yScale(nearestValue) - 30);

        focus
          .attr('cx', xScale(xTickNumber[i - 1]))
          .attr('cy', yScale(nearestValue));
      })
      .on('mouseout', () => {
        focusText.style('opacity', 0);
        focus.style('opacity', 0);
      });
  }, [
    axisLabels,
    data,
    height,
    randomColor,
    showAxis,
    axisTitles,
    chartTitle,
    width,
    isEmpty,
    domainHigh,
    xTickNumber,
    yTickValue,
    colors,
  ]);

  if (isEmpty === true) {
    return <h3>No Data to Display</h3>;
  }

  return <svg ref={d3LineChart}></svg>;
}

export default AllLineCharts;
