import axios from 'axios';
import { isNil, noop } from 'lodash';

export async function getCurrentUser() {
  let result = noop();
  try {
    const response = await axios.get('/api/user');
    if (!isNil(response)) {
      result = response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return result;
}

export async function getAllUsers({ companyId = '', search = undefined }) {
  const response = await axios.get(
    `/api/user/get-all?companyId=${companyId}&searchPhrase=${search}`
  );
  return response.data;
}

export async function getInvitation({ email }) {
  const response = await axios.post(`/api/user/get-invitation`, {
    email: email,
  });
  return response.data;
}

export async function skipInvitation({ invitationId }) {
  try {
    const response = await axios.post(`/api/user/skip-invitation`, {
      id: invitationId,
    });
    return response.data;
  } catch (error) {}
}

export async function acceptInvitation({ invitationId }) {
  const response = await axios.post(`/api/user/accept-invitation`, {
    id: invitationId,
  });
  return response.data;
}

export async function signup({ fullName, title, companyName, email }) {
  try {
    const response = await axios.post('/api/register', {
      fullName,
      title,
      companyName,
      email,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function confirmUser({ userId, email }) {
  const response = await axios.post('/api/user/confirm', {
    userId,
    email,
  });
  return response.data;
}

export async function updateUser(values) {
  const response = await axios.put(`/api/user/update`, values, {
    withCredentials: true,
  });
  return response.data;
}

export async function updateUserAvatar(values) {
  const response = await axios.put(`/api/user/avatar`, values, {
    withCredentials: true,
  });
  return response.data;
}

export async function activate(userId) {
  axios
    .post(`/api/user/enable-account`, {
      userId,
    })

    .catch(() => {});
}

export async function deactivate(userId) {
  axios
    .post(`/api/user/disable-account`, {
      userId,
    })

    .catch(() => {});
}

export async function generateOtpSecret(data) {
  let result = null;
  try {
    const response = await axios.post('/api/totp/generate', data);
    if (typeof response !== 'undefined' && response != null) {
      result = { key: response.secret.toLowerCase(), qrcode: response.image };
    }
  } catch (exception) {}
  return result;
}

export async function verify2FAToken(data) {
  let result = false;
  try {
    const response = await axios.post('/api/totp/verify', data);
    if (typeof response !== 'undefined' && response != null) {
      result = response.token_validated;
    }
  } catch (exception) {}
  return result;
}

export async function addCardanoWallet(walletName) {
  const response = await axios.post('/api/user/add-wallet', {
    walletName,
  });
  return response.data;
}

export async function clearCardanoWallet() {
  const response = await axios.put('/api/user/disconnect-wallet');
  return response.data;
}

export async function selectCompany({ companyId }) {
  const response = await axios.post('/api/user/select-company', {
    companyId,
  });
  return response.data;
}
