import React, { useState, useRef } from 'react';
import '../../shared/css/typography.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { Col, Row } from 'react-bootstrap';
import defaultProfile from '../../assets/icon/snapbrillia_profile_personal_icon.svg';
import NinhProfile from '../../assets/profile-photo.jpeg';

export default function RequestDemoCalendy({
  step = 0,
  handleScheduled,
  values,
}) {
  const users = [
    {
      fullName: 'Ninh Tran',
      url: 'https://calendly.com/ninhtran/connect',
      avatar: NinhProfile,
      time: '30 mins',
    },
    // {
    //   fullName: 'Tu Tran',
    //   url: 'https://calendly.com/tutran-1',
    //   avatar: '',
    //   time: '30 mins',
    // },
  ];
  const [widgetHeight, setWidgetHeight] = useState(800);
  const [selectUrl, setSelectUrl] = useState(
    users.length === 1 ? users[0].url : null
  );
  const widgetRef = useRef();

  return (
    <Scrollbars
      autoHide
      autoHeight
      autoHeightMin={900}
      autoHeightMax={widgetHeight}
      autoHideDuration={800}
      thumbMinSize={30}
      renderThumbVertical={() => <div className="thumb-vertical" />}
      style={{
        width: '83%',
        maxWidth: 1350,
        height: 500,
        borderRadius: '2px',
        overflowY: 'hidden',
      }}
    >
      <div
        className="bounty-type-masked-overflow"
        style={{ height: '2560', overflowY: 'hidden' }}
      >
        {selectUrl ? (
          <CalendlyEventListener
            onDateAndTimeSelected={() => {
              setWidgetHeight(1000);
            }}
            onEventTypeViewed={() => {
              setWidgetHeight(2250);
            }}
            onProfilePageViewed={() => {
              setWidgetHeight(2750);
            }}
            onEventScheduled={(e) => {
              handleScheduled();
            }}
          >
            <InlineWidget
              className="bounty-type-masked-overflow"
              ref={widgetRef}
              prefill={{
                email: values.email,
                name: values.fullName,
              }}
              url={selectUrl}
              rootElement={document.getElementById('root')}
              styles={{
                height: 2650,
                width: '95%',
                overflow: 'hidden',
              }}
            />
          </CalendlyEventListener>
        ) : (
          <>
            <Row>
              <Col>
                <h5 className="text-center primary mb-5 bold">
                  Choose Person Demo for you
                </h5>
              </Col>
            </Row>
            <Row>
              {users.map((item, index) => {
                return (
                  <Col
                    className="pointer"
                    onClick={() => {
                      setSelectUrl(item.url);
                    }}
                    md={6}
                    key={index}
                  >
                    <span className="d-block text-center primary medium mb-2">
                      <img
                        src={item.avatar ? item.avatar : defaultProfile}
                        alt="profile"
                        height={100}
                        width={100}
                        style={{ borderRadius: '50%' }}
                      />
                    </span>
                    <span className="text-center d-block  primary medium mb-2">
                      {item.fullName}
                    </span>
                    <span className="text-center d-block  primary medium mb-2">
                      {item.time}
                    </span>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </div>
    </Scrollbars>
  );
}
