import { useState, useEffect, useCallback } from 'react';
import * as ProjectApi from '../../api/projects';
import * as BountyApi from '../../api/bounties';

function ReviewRate(
  bountyId,
  WSPId,
  dataType,
  setData,
  setAxisLabels,
  setAxisTitles,
  setChartTitle
) {
  // list assessment phases and the candidates within these phases for response rate
  const [summary, setSummary] = useState();

  // fetch candidate response rate from backend
  const getCandidateReviewRate = useCallback(async () => {
    try {
      const { data: reviewRate } = bountyId
        ? await BountyApi.getBountySummary(bountyId)
        : await ProjectApi.getProjectSummary(WSPId);

      setSummary(reviewRate);
    } catch (err) {}
  }, [WSPId, bountyId]);

  useEffect(() => {
    getCandidateReviewRate();
  }, [getCandidateReviewRate]);

  useEffect(() => {
    // use optional chaining (?.) to avoid short-circuiting the code if summary is undefined (which it will be on load until data is fetched)
    const all = summary?.all;
    const invited =
      summary?.invited +
      summary?.viewed +
      summary?.accepted +
      summary?.inProgress +
      summary?.completed +
      summary?.reviewed +
      summary?.bounced +
      summary?.expired;
    const viewed =
      summary?.viewed +
      summary?.accepted +
      summary?.inProgress +
      summary?.completed +
      summary?.reviewed;
    const accepted =
      summary?.accepted +
      summary?.inProgress +
      summary?.completed +
      summary?.reviewed;
    const inProgress =
      summary?.inProgress + summary?.completed + summary?.reviewed;
    const completed = summary?.completed + summary?.reviewed;
    const reviewed = summary?.reviewed;

    const invitedP = Math.round((reviewed / all) * 100) || 0;
    const bouncedP = Math.round((summary?.bounced / invited) * 100) || 0;
    const expiredP = Math.round((summary?.expired / invited) * 100) || 0;
    const viewedP = Math.round((viewed / invited) * 100) || 0;
    const acceptedP = Math.round((accepted / viewed) * 100) || 0;
    const inProgressP = Math.round((inProgress / accepted) * 100) || 0;
    const completedP = Math.round((completed / inProgress) * 100) || 0;
    const reviewedP = Math.round((reviewed / completed) * 100) || 0;

    setData([
      invitedP,
      bouncedP,
      expiredP,
      viewedP,
      acceptedP,
      inProgressP,
      completedP,
      reviewedP,
    ]);
    setAxisLabels([
      'Invited',
      'Bounced',
      'Expired',
      'Viewed',
      'Accepted',
      'In Progress',
      'Completed',
      'Reviewed',
    ]);
    setAxisTitles(['% of Candidates', 'Assessment Phases']);
    setChartTitle('Review Rate');
  }, [
    dataType,
    setAxisLabels,
    setAxisTitles,
    setChartTitle,
    setData,
    summary?.accepted,
    summary?.all,
    summary?.bounced,
    summary?.completed,
    summary?.expired,
    summary?.inProgress,
    summary?.invited,
    summary?.reviewed,
    summary?.viewed,
  ]);
}

export default ReviewRate;
