import '../../shared/css/typography.css';
import Check from '../../assets/icon/snapbrillia_check_icon.svg';

const AddProjectWelcomeMessage = () => {
  return (
    <div>
      <div>
        <h1 className="h1">
          <img src={Check} alt="check-icon" />
          <span style={{ marginLeft: '15px' }}>Perfect!</span>
        </h1>
      </div>
      <h3
        className="h3 setup-add-project-welcome-message"
        style={{ marginTop: '33px' }}
      >
        Now let&apos;s go through and create your first Project in order to add
        your candidates.
      </h3>
    </div>
  );
};

export default AddProjectWelcomeMessage;
