import React, { useState, useRef, useEffect, useContext } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select, { components } from 'react-select';
import { navigate } from '@reach/router';

import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { Scrollbars } from 'react-custom-scrollbars';
import ScheduleCalendly from '../../shared/button/scheduleCalendly';
import { dropdownContext } from '../../bounties/components/bountiesAddButtonModal';
import DropDown from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import Close from '../../assets/icon/snapbrillia_close_icon.svg';
import '../css/bountyTypeSelection.css';
import '../../shared/css/sharedStyles.css';
import useGithubApp from '../../hooks/useGithubApp';
import { useAuth } from '../../context/authContext';

const BountyPageTypeSelection = ({
  bountyField,
  handleChange,
  setBountyField,
  handleNextChange,
  handleNextDisable,
}) => {
  const { setDropdownValue } = useContext(dropdownContext);
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(bountyField.type || 'public');
  const [selected, setSelected] = useState({
    value: 'public',
    label: 'Public',
    name: 'type',
  });
  const [touched, setTouched] = useState(false);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const [widgetHeight, setWidgetHeight] = useState(800);
  const widgetRef = useRef();
  const { auth } = useAuth();
  const {
    githubAppUrl,
    openPopup,
    repos,
    branches,
    setUrl,
    linkGithubUrl,
    loadRepository,
  } = useGithubApp();

  useEffect(() => {
    if (value) {
      options.map((x) => {
        if (x.value === value) {
          setSelected(x);
        }
      });
      if (value === 'concierge') {
        handleNextChange(false);
      } else {
        handleNextDisable(true);
        handleNextChange(true);
      }
      if (bountyField.url) {
        handleNextDisable(false);
      } else {
        handleNextDisable(true);
      }
    }
  }, [value]);
  useEffect(() => {
    if (bountyField.url) {
      handleNextDisable(false);
    } else {
      if (repos.length) {
        InitRepo();
      } else {
        handleNextDisable(true);
      }
    }
  }, [repos]);

  useEffect(() => {
    if (branches.length) {
      handleChange({
        name: 'repoBranch',
        value: branches[0].name,
      });
    }
  }, [branches]);

  const InitRepo = async () => {
    handleChange({
      name: 'url',
      value: repos[0].github_url,
    });
    handleChangeSelectedRepo(repos[0].github_url);
    setUrl(repos[0].github_url);
  };

  const handleChangeSelectedRepo = async (url) => {
    const repo = await loadRepository(url);
    if (repo) {
      const data = {
        repoName: repo.name,
        repoDescription: repo.description,
        languages: repo.languages,
      };
      setBountyField({ ...bountyField, ...data });
    }
  };

  useEffect(() => {
    if (repos.length) {
      repos.map((repo) => {
        if (bountyField.url === repo.github_url) {
          handleChangeSelectedRepo(repo.github_url);
        }
      });
    }
  }, [bountyField.url]);

  const options = [
    { value: 'public', label: 'Public', name: 'type' },
    { value: 'private', label: 'Private', name: 'type' },
    {
      value: 'concierge',
      label: 'Concierge',
      name: 'type',
    },
  ];

  const DropdownIndicator = (state) => {
    return (
      <components.DropdownIndicator {...state}>
        <div>
          <img src={DropDown} alt="dropdown" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const renderVal = (option, { context }) => {
    return context === 'menu' && option.value === 'concierge' ? (
      <div>
        Concierge<span> (Done For You)</span>
      </div>
    ) : (
      option.label
    );
  };

  const onCloseModal = () => {
    setIsCalendlyOpen(false);
    setWidgetHeight(800);
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        color: state.isSelected || state.isFocused ? '#2d2b6f' : '#808080',
        padding: 10,
        fontWeight: 500,
        cursor: 'pointer',
      };
    },
    control: () => {
      return {
        borderWidth: 0,
        alignItems: 'center',
        borderRadius: 6,
        backgroundColor: '#f4f4f4',
        height: '40px',
        display: 'flex',
        flexWrap: 'wrap',
        color: '#2d2b6f',
        width: '165px',
        cursor: 'pointer',
      };
    },
    singleValue: (provided) => {
      return { ...provided, color: '#2d2b6f', fontWeight: 500 };
    },
    menu: (provided, state) => {
      return {
        position: 'absolute',
        color: state.selectProps.menuColor,
        backgroundColor: '#fff',
        height: open ? '140px' : '0px',
        boxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        transition: 'all 0.8s ease-in-out',
        opacity: open ? 1 : 0,
        visibility: open ? 'visible' : 'hidden',
        width: '170px',
        zIndex: '9999',
        overflow: 'visible',
      };
    },
    dropdownIndicator: () => {
      return {
        position: 'relative',
        right: '95%',
        animation: open ? 'rotation 0.8s' : 'recover 0.8s',
        transform: open ? 'rotate(180deg)' : null,
        top: open ? '3px' : null,
      };
    },
  };
  let handleCnt = 0;
  return (
    <Row style={{}}>
      {isCalendlyOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: '100',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, .8)  ',
            animation: 'open 3s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="close_modal_button">
            <img src={Close} alt="close-icon" onClick={() => onCloseModal()} />
          </div>

          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={900}
            autoHeightMax={widgetHeight}
            autoHideDuration={800}
            thumbMinSize={30}
            renderThumbVertical={() => <div className="thumb-vertical" />}
            style={{
              width: '83%',
              maxWidth: 1350,
              height: 500,
              borderRadius: '2px',
              overflowY: 'hidden',
            }}
          >
            <div
              className="bounty-type-masked-overflow"
              style={{ height: '2560', overflowY: 'hidden' }}
            >
              <CalendlyEventListener
                onDateAndTimeSelected={() => {
                  setWidgetHeight(1000);
                }}
                onEventTypeViewed={() => {
                  setWidgetHeight(2250);
                }}
                onProfilePageViewed={() => {
                  setWidgetHeight(2750);
                }}
                onEventScheduled={() => {
                  navigate('/dashboard'); //don't get to test this feature yet
                }}
              >
                <InlineWidget
                  className="bounty-type-masked-overflow"
                  ref={widgetRef}
                  url="https://calendly.com/ninhtran/connect"
                  onModalClose={() => setIsCalendlyOpen(false)}
                  rootElement={document.getElementById('root')}
                  styles={{
                    height: 2650,
                    width: '95%',
                    overflow: 'hidden',
                  }}
                />
              </CalendlyEventListener>
            </div>
          </Scrollbars>
        </div>
      )}
      <Row className="bounty-row-center" style={{ transform: 'scale(0.98)' }}>
        <Col lg={4}>
          <Row>
            <Col
              style={{ marginLeft: 0, paddingLeft: 0 }}
              className="form_label bounty-type-label"
            >
              Gig Type
            </Col>
          </Row>
          <Row>
            <div
              style={{ width: '165px', padding: 0 }}
              onTouchStart={() => {
                handleCnt++;
                setOpen(!open);
                setTouched(true);
                selectRef.current.focus();
              }}
              onClick={() => {
                if (!touched) {
                  handleCnt++;
                  setOpen(!open);
                  selectRef.current.focus();
                }
              }}
            >
              <Select
                ref={selectRef}
                styles={customStyles}
                isSearchable={false}
                onBlur={() => {
                  if (handleCnt > 0) {
                    handleCnt = 0;
                    return;
                  }
                  if (open) {
                    setOpen(!open);
                  }
                }}
                onChange={(e) => {
                  setValue(e.value);
                  handleChange(e);
                  setDropdownValue(e.value);
                }}
                options={options}
                menuIsOpen
                defaultValue={{
                  value: 'public',
                  label: 'Public',
                  name: 'type',
                }}
                value={selected}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                formatOptionLabel={renderVal}
                className="bounty-type-select-box"
              ></Select>
            </div>
          </Row>
        </Col>

        {(value === 'public' || value === 'private') && (
          <Col lg={8}>
            <Row>
              <Col
                style={{ paddingLeft: 0 }}
                xs={8}
                className="form_label bounty-type-label"
              >
                Select GitHub URL<span>&nbsp;*</span>
              </Col>
              <Col
                // className="bounty-type-label"
                style={{
                  color: '#a900a6',
                  fontWeight: '700',
                  marginTop: '9px',
                  textAlign: 'right',
                }}
              >
                {repos.length === 0 && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => openPopup()}
                  >
                    Get Repository
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              {repos.length > 0 && (
                <select
                  name="url"
                  className="bounty-type-input-box"
                  value={bountyField.url}
                  onChange={(e) => {
                    handleNextDisable(false);
                    handleChange(e);
                    setUrl(e.target.value);
                  }}
                >
                  {repos.map((repo, index) => {
                    return (
                      <option value={repo.github_url} key={index}>
                        {repo.github_url}
                      </option>
                    );
                  })}
                </select>
              )}
            </Row>
            <Row>
              {branches.length > 0 && (
                <Col
                  style={{ paddingLeft: 0 }}
                  xs={8}
                  className="form_label bounty-type-label"
                >
                  Select Branch to work from<span>&nbsp;*</span>
                </Col>
              )}
              {branches.length > 0 && (
                <select
                  name="repoBranch"
                  className="bounty-type-input-box"
                  value={bountyField.repoBranch}
                  onChange={(e) => {
                    handleNextDisable(false);
                    handleChange(e);
                  }}
                >
                  {branches.map((branch, index) => {
                    return (
                      <option value={branch.name} key={index}>
                        {branch.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </Row>
            <Row>
              {repos.length <= 0 && (
                <div
                  style={{
                    fontSize: '12px',
                    paddingLeft: '0px',
                    marginBottom: '10px',
                    color: '#2d2b6f',
                    width: '100%',
                  }}
                >
                  Don&apos;t see your Github URL? Please install our{' '}
                  <a
                    href={!auth.user.githubId ? linkGithubUrl : githubAppUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Snapbrillia Github Application{' '}
                  </a>
                  to continue or select <b>Get Repository</b> above. If it does
                  not show up after you connect, please refresh.
                </div>
              )}
            </Row>
          </Col>
        )}
        {value === 'concierge' && (
          <Col xs={7}>
            <Row style={{ height: '40px' }}>
              <Col className="form_label bounty-concierge-label"></Col>
            </Row>
            <Row>
              <Col
                // md={4}
                style={{
                  maxWidth: '400px',
                  minWidth: '240px',
                  position: 'relative',
                  left: '50px',
                  top: '5px',
                }}
                className=""
              >
                <ScheduleCalendly
                  onClick={(e) => {
                    e.preventDefault();
                    setIsCalendlyOpen(true);
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Row>
  );
};

export default BountyPageTypeSelection;
