import '../css/buttonStyles.css';

export default function AddProjectButton({ showCreateProject }) {
  return (
    <button
      className="btn-primary"
      style={{
        minWidth: '180px',
        // padding: '5px 20px',
      }}
      onClick={showCreateProject}
    >
      Add Assessment
    </button>
  );
}
