import React from 'react';
import { Spinner } from 'react-bootstrap';

const MSAPolicy = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });
  return (
    <div className="policy-wrapper policy-typography">
      <div className="main-policy-container" data-testid="msaPolicyComponent">
        {loading && (
          <>
            <div className="text-center">
              <Spinner
                className="mb-3 text-center"
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
            </div>
          </>
        )}
        <iframe
          style={{
            borderStyle: 'none',
            width: '100%',
            height: '95%',
            paddingLeft: '10%',
          }}
          title="MSA"
          src="https://docs.google.com/document/d/e/2PACX-1vQcnPXMu-r7Z-MMx9wSUziQcc2hCJP2D774VkYSbsbMBhmgcElRyYMe1aLf6IHEKOhJPmI9PJxVCMkQ/pub?embedded=true"
        ></iframe>
      </div>
    </div>
  );
};

export default MSAPolicy;
