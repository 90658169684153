import '../../shared/css/typography.css';

export default function InterviewerRoomDisclaimer() {
  return (
    <div>
      <p className="h3">
        If the candidate has not joined within <b>10 minutes</b>, you may press
        the button below to send a reminder to your point of contact,{' '}
        <b>{'candidates name'}</b>.
      </p>
    </div>
  );
}
