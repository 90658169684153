import { useEffect, useRef } from 'react';
import './css/dropDownCompany.css';
import '../../shared/css/typography.css';
//COMPONENTS
import { Col, Container, Row } from 'react-bootstrap';
import { navigate } from '@reach/router';
import Dropdown from 'react-bootstrap/Dropdown';
//CSS
import './css/notification.css';
import * as NotificationAPI from '../../api/notification';
import { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { timeAgo } from '../../utils/functions';

const DropDownCompany = ({ showCompany, setShowCompany, companies }) => {
  const componentRef = useRef();
  const { auth, selectCompany } = useAuth();
  const handleOutsideClick = (componentRef, e) => {
    if (e.target.classList.contains('nav-bar-icon')) {
      return;
    }
    return componentRef.current && !componentRef.current.contains(e.target)
      ? setShowCompany(false)
      : null;
  };
  useEffect(() => {
    document.addEventListener('onclick', (e) =>
      handleOutsideClick(componentRef, e)
    );
    return () => document.removeEventListener('onclick', handleOutsideClick);
  }, []);

  return (
    <>
      <Dropdown.Menu>
        <Dropdown.Header>Select company</Dropdown.Header>
        <div className="company-dropdown-container">
          {companies.length > 0 &&
            companies.map((company, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  eventKey="1"
                  style={{ textAlign: 'left' }}
                  className={`company-dropdown-item ${
                    company._id === auth.user.companyId ? `current` : `ahihi`
                  }`}
                  onClick={() => {
                    company._id !== auth.user.companyId &&
                      selectCompany(company._id);
                  }}
                >
                  <span className="company-dropdown-text position-relative">
                    {`${company.companyName} (${company.companyRole})`}{' '}
                    {company._id === auth.user.companyId && (
                      <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2">
                        <span className="visually-hidden">current</span>
                      </span>
                    )}
                  </span>
                </Dropdown.Item>
              );
            })}
        </div>
      </Dropdown.Menu>
    </>
  );
};

export default DropDownCompany;
