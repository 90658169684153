import React, { useState } from 'react';
import company from '../../assets/SnapBetaLogo2.svg';
import RemoveProfileImageModal from '../../shared/modals/removeProfileImageModal';
import ProfileImageModal from '../../shared/modals/profileImageModal';
import { toast } from 'react-toastify';

const CompanyProfilePhoto = ({
  auth,
  companyLogo,
  setCompanyLogo,
  handleSave,
  handleRemove,
  handleUpdateCompanyInfo,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div className="d-flex flex-row justify-content-center align-items-center ">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="shadow p-0 mb-3 bg-white rounded-circle company-profile">
          <img
            // className="shadow p-3 mb-5 bg-white rounded-circle"
            src={companyLogo ? companyLogo : company}
            //alt="Company Logo"
            width="400"
            height="400"
            style={{ borderRadius: '50%' }}
          />
          <div className="flex-column justify-content-center align-items-center edit-company-profile">
            <span
              style={{ border: 'none' }}
              className="flex removeReplace fw-400"
              onClick={() => handleRemove(true)}
              disabled={companyLogo === undefined || companyLogo === ''}
            >
              Remove
            </span>
            <span
              className="flex removeReplace fw-400"
              onClick={() => {
                if (auth.user.companyRole === 'owner' || auth.user.admin) {
                  setOpenEditModal(true);
                } else {
                  toast.error(
                    'Only Company Owner and Admin can change the Image'
                  );
                }
              }}
            >
              Replace
            </span>
          </div>
          <RemoveProfileImageModal
            show={showConfirm}
            closeModal={() => setShowConfirm(false)}
            handleRemove={handleRemove}
          />
          <ProfileImageModal
            show={openEditModal}
            closeModal={() => setOpenEditModal(false)}
            handleRemove={handleRemove}
            source={companyLogo ? companyLogo : company}
            handleSave={handleSave}
            setSource={setCompanyLogo}
            isCompany={true}
            handleUpdateCompanyInfo={handleUpdateCompanyInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyProfilePhoto;
