import { ReactComponent as EmailUs } from '../../assets/icon/snapbrillia_email_us_icon.svg';
// import data from '../../__test__/__testData__/testData';
import '../css/buttonStyles.css';
import '../css/supportStyles.css';

export default function EmailUsButton() {
  return (
    <a
      className="bold primary btn-primary email-us text-decoration-none mt-2"
      target="_blank"
      rel="noreferrer"
      href={`mailto:support@snapbrillia.com`}
    >
      <EmailUs />
      <span className="emailUsIcon">Email Us</span>
    </a>
  );
}
