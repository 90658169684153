import React from 'react';
import '../css/buttonStyles.css';

const ScheduleCalendly = ({ type, className, onClick }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`btn-primary ${className}`}
    >
      Schedule a Meeting
    </button>
  );
};

export default ScheduleCalendly;
