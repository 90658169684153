import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import Edit from '../../assets/icon/snapbrillia_edit_w_text.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const EditSkills = ({ workSampleProject }) => {
  return (
    <div>
      {workSampleProject && workSampleProject.mandatoryskillsTested && (
        <>
          <label className="label form_label">Skills:</label>
          <img src={Edit} alt="edit-icon" className="text_spacing" />
          <Row>
            <Col xs={2}>
              {workSampleProject.mandatoryskillsTested.map((skill, index) => {
                return (
                  <div className="pills skillpills h5" key={index}>
                    {skill}
                  </div>
                );
              })}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default EditSkills;
