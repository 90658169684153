import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import NextButton from '../../shared/button/next';
import BackButton from '../../shared/button/back';
import ResetButton from '../../shared/button/resetButton';
import 'bootstrap/dist/css/bootstrap.css';
import '../../bountyCreateSteps/css/createBounty.css';
import '../../shared/css/typography.css';
import * as BountyApi from '../../api/bounties';
import * as JobApi from '../../api/jobs';

import { useInvoicedGigForm } from '../../context/invoicedGigContext';

export const StepTwoBounty = ({ nextStep, prevStep, resetStep }) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    setFieldTouched,
    resetGigForm,
  } = useInvoicedGigForm();
  const [skillSet, setSKillSet] = useState([]);

  const getSkill = async () => {
    const skill = await JobApi.getSkill();
    if (skill?.length > 0 || skill) {
      setSKillSet(skill);
      return null;
    }
    setSKillSet([]);
    return null;
  };

  const promiseOptions = async (inputValue) => {
    const data = await BountyApi.searchSkills(inputValue);
    let results = [];
    let isFind = false;
    for (let x of data) {
      if (
        x.toUpperCase() === inputValue.toUpperCase() ||
        x.toUpperCase() ===
          (inputValue + ' (Programming Language)').toUpperCase()
      ) {
        isFind = true;
      }
      results.push({
        label: x,
        value: x,
      });
    }
    if (!isFind) {
      results.unshift({
        label: inputValue,
        value: inputValue,
      });
    }
    // console.log(results);
    return results;
  };

  const createSkill = async (skillName) => {
    const skill = await JobApi.createSkill({ name: skillName });
    if (skill?.length > 0 || skill) {
      setSKillSet([...skillSet, skill]);
    }
    setSKillSet(skillSet);
  };

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (
      currentErrors.skills ||
      currentErrors.acceptenceCriteria ||
      currentErrors.submissionRequirements
    ) {
      setFieldTouched('acceptenceCriteria');
      setFieldTouched('submissionRequirements');
      setFieldTouched('skills');
      return;
    }
    nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };

  useEffect(() => {
    getSkill();
  }, []);

  return (
    <Container>
      <Row>
        <p className="grey-bounty-setup-header">
          Adjust the following to fit your specific needs
        </p>
      </Row>
      <Form>
        <Form.Group className="mb-4" controlId="steptwoGroup3">
          <Form.Label className="form_label bounty-type-label ps-0">
            Skills <span className="required">*</span>
          </Form.Label>
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions={skillSet?.map((x) => ({
              label: x.name,
              value: x.name,
            }))}
            value={values?.selectedSkills.map((x) => ({
              label: x,
              value: x,
            }))}
            closeMenuOnSelect={true}
            maxMenuHeight={500}
            placeholder={
              'Begin typing a skill to select it from the dropdown list'
            }
            onChange={(e) => {
              handleChange({
                target: {
                  name: 'selectedSkills',
                  value: e.map((e) => {
                    createSkill(e.label);
                    return e.label;
                  }),
                },
              });
              handleChange({
                target: {
                  name: 'skills',
                  value: e.map((e) => {
                    return e.label;
                  }),
                },
              });
            }}
            loadOptions={promiseOptions}
          />
          <Form.Text className="text-muted">
            <i>Info:</i> select list of skills needed for this job or search for
            your prefered skills and add them
          </Form.Text>
          {touched.selectedSkills && errors.selectedSkills && (
            <Form.Control.Feedback className="bounty-field-error text-danger">
              {errors.selectedSkills}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-4" controlId="steptwoGroup1">
          <Form.Label className="form_label bounty-type-label ps-0">
            Submission Requirements <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="submissionRequirements"
            as="textarea"
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.submissionRequirements}
            isInvalid={!!errors.submissionRequirements}
          />
          {touched.submissionRequirements && errors.submissionRequirements && (
            <Form.Control.Feedback type="invalid">
              {errors.submissionRequirements}
            </Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">Edit to fit your needs</Form.Text>
        </Form.Group>
        <Form.Group className="mb-4" controlId="steptwoGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Acceptance Criteria <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="acceptenceCriteria"
            as="textarea"
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.acceptenceCriteria}
            isInvalid={!!errors.acceptenceCriteria}
          />
          {touched.acceptenceCriteria && errors.acceptenceCriteria && (
            <Form.Control.Feedback type="invalid">
              {errors.acceptenceCriteria}
            </Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">Edit to fit your needs</Form.Text>
        </Form.Group>
        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <BackButton onClick={back} />
              <NextButton
                onClick={next}
                type="button"
                disabled={
                  errors.languages ||
                  errors.acceptenceCriteria ||
                  errors.submissionRequirements
                }
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepTwoBounty;
