import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../shared/css/typography.css';
import '../shared/css/bgColors.css';
import '../shared/css/sharedStyles.css';

import NextButton from '../shared/button/next';
import AddCandidateWelcomeMessage from './components/addCandidateWelcomeMessage';
import AddBountyCandidateWelcomeMessage from './components/addBountyCandidateWelcomeMessage';
import AddBountyCandidateMessage from './components/addBountyCandidateMessage';
import AddCandidateMessage from './components/addCandidateMessage';
import AddCandidateFormHeader from './components/addCandidateFormHeader';
import AddBountyCandidateFormHeader from './components/addBountyCandidateFormHeader';
import ImportUploadTemplate from './components/importUploadTemplate';
import AddCandidateFields from '../candidateModal/components/addCandidateFields';
import EditSkills from './components/editSkills';

import Logo from '../assets/SnapBetaLogo2.svg';
import IllustrationsFinal from '../assets/illustrations/snapbrillia_setup_final_illustration.png';
import { validateCandidateFields } from '../utils/functions';
import { useOnBoarding } from '../context/onboardingContext';
import BackButton from '../shared/button/back';
import { toast } from 'react-toastify';

const SetUpAddCandidate = () => {
  const { workSampleProject, addCandidate, skipOnboarding, bounty } =
    useOnBoarding();

  const [candidateFormErrors, setCandidateFormErrors] = useState({});

  const [candidateFields, setCandidateFields] = useState({
    fullName: '',
    email: '',
    linkedin: '',
    github: '',
    stackOverflow: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorList = validateCandidateFields(candidateFields);
    setCandidateFormErrors(errorList);
    if (Object.keys(errorList).length === 0) {
      try {
        await addCandidate(candidateFields);
        toast('Add Candidate Successful');
      } catch (err) {
        toast(err.error);
      }
    }
  };

  return (
    <Container className="setup-team-main-container" fluid>
      <Row>
        <Col style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}>
          <img src={Logo} alt="logo" />
        </Col>
      </Row>
      <Row className="setup-add-team-content">
        <Col xs={12} md={6}>
          <Row>
            <Col md={12}>
              {bounty._id !== '' ? (
                <AddBountyCandidateWelcomeMessage />
              ) : (
                <AddCandidateWelcomeMessage />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {bounty._id !== '' ? (
                <AddBountyCandidateMessage />
              ) : (
                <AddCandidateMessage />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <EditSkills workSampleProject={workSampleProject} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 4 }}>
              <img src={IllustrationsFinal} alt="illustration" />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <form
            className="add-candidate-form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <Row>
              <Col md={12}>
                {bounty._id !== '' ? (
                  <AddBountyCandidateFormHeader />
                ) : (
                  <AddCandidateFormHeader />
                )}
              </Col>
              <Col md={12}>
                <AddCandidateFields
                  candidateFields={candidateFields}
                  setCandidateFields={setCandidateFields}
                  formErrors={candidateFormErrors}
                />
              </Col>
            </Row>
            <Row>
              <ImportUploadTemplate />
            </Row>
          </form>
          <Row className="setup-add-project-button">
            <Col className="p-0">
              <span className="me-5">
                <BackButton
                  text={'skip'}
                  type="button"
                  onClick={skipOnboarding}
                />
              </span>
              <NextButton onClick={handleSubmit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SetUpAddCandidate;
