import React from 'react';
import { Redirect } from '@reach/router';
import { useAuth } from '../context/authContext';

const ProtectedRoute = ({
  component: Component,
  path: path,
  ...otherProps
}) => {
  const { auth } = useAuth();
  if (!auth) {
    localStorage.setItem('redirect', window.location);
    return <Redirect to="/signin" noThrow />;
  }
  if (!auth.company || !auth.company._id) {
    if (path === '/update-info') {
      return <Component {...otherProps} />;
    }
    return <Redirect to="/update-info" noThrow />;
  }
  return <Component {...otherProps} />;
};

export default ProtectedRoute;
