import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/userProfile.css';
import '../../shared/css/typography.css';
import '../../shared/css/textColors.css';
import '../../shared/css/bgColors.css';
// import SelectMenu from '../../shared/formItems/selectMenu';
import SnapbrilliaCloseIcon from '../../assets/icon/snapbrillia_close_icon.svg';
import { formatDate } from '../../utils/functions';
import * as OpportunityApi from '../../api/opportunities';
import { timeAgo } from '../../shared/utils';
import ButtonComponent from './candidateProfile/buttonComponent';

const UserProfile = ({
  userProfile,
  bounty,
  setShowUserProfile,
  setClicked,
  setSendEmail,
  setSendRejectEmail,
}) => {
  const [review, setReview] = useState(userProfile?.status);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const getData = async () => {
    setReview(userProfile.status);
  };
  const canBeAccepted = (status) => {
    let result = false;
    if (typeof status !== 'undefined' && status != null) {
      result = status === 'Requested' || status === 'In-Progress';
    }
    return result;
  };
  const canBeRejected = (status) => {
    let result = false;
    if (typeof status !== 'undefined' && status != null) {
      result =
        status === 'Invited' ||
        status === 'Requested' ||
        status === 'In-Progress';
    }
    return result;
  };
  useEffect(() => {
    getData();
    setShowAccept(canBeAccepted(userProfile.status));
    setShowReject(canBeRejected(userProfile.status));
  }, [userProfile]);

  const handleApprove = (e) => {
    e.preventDefault();
    // if (userProfile.opportunityId.type !== 'Job') {
    //   OpportunityApi.opportunityReview(userProfile?._id, {
    //     review: 'Accept',
    //   }).then(() => {
    //     setClicked(true);
    //     setShowUserProfile(false);
    //   });
    // } else {

    // }
    setSendEmail(true);
  };
  const handleExcept = (e) => {
    e.preventDefault();
    // if (userProfile.opportunityId.type !== 'Job') {
    //   OpportunityApi.opportunityReview(userProfile?._id, {
    //     review: 'Reject',
    //   }).then(() => {
    //     setClicked(false);
    //     setShowUserProfile(false);
    //   });
    // } else {

    // }
    setSendRejectEmail(true);
  };

  return (
    <Container className="user-profile-container">
      <Row className="user-profile-row-1">
        <Col md={2}>
          <span className="users-profile-circle">
            <span>{userProfile?.initials}</span>
          </span>
        </Col>
        <Col>
          <Row>
            <Col>
              <p className="h1">
                {userProfile?.candidateId?.fullName === '' ||
                userProfile?.candidateId?.fullName === ' '
                  ? userProfile?.candidateId?.email
                  : userProfile?.candidateId?.fullName}{' '}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="users-profile-row1-col2-p2">
                Date Started: {formatDate(userProfile.createdAt)}
              </p>
              {!['Completed', 'Rejected'].includes(userProfile.status) ? (
                <div className="user-profile-centered-div">
                  <div>
                    {userProfile.status === 'Submitted' && (
                      <>
                        <button
                          className="users-profile-launch-ide-reject mx-1"
                          onClick={handleExcept}
                        >
                          Reject
                        </button>
                        <button
                          className="users-profile-launch-ide mx-1"
                          onClick={handleApprove}
                        >
                          Mark as Completed
                        </button>
                      </>
                    )}
                  </div>
                  {(userProfile.status === 'Rejected' ||
                    userProfile.status === 'Completed') && (
                    <div>
                      {userProfile.assessment?.status === 'Rejected' && (
                        <button className="users-profile-launch-ide-reject mx-1">
                          Rejected
                        </button>
                      )}
                      {userProfile.assessment?.status === 'Completed' && (
                        <>
                          <button className="users-profile-launch-ide mx-1">
                            Completed
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <span>{userProfile.status}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={2} className="user-profile-row1-col-relative">
          <div
            className="user-profile-close-icon"
            onClick={() => setShowUserProfile(false)}
          >
            <img
              src={SnapbrilliaCloseIcon}
              alt="close-icon"
              height={25}
              width={25}
            />
          </div>
        </Col>
      </Row>

      <Row className="user-profile-row3-col  cardbg">
        <Col xs={12} className="bg-light">
          <ButtonComponent candidate={userProfile} />
        </Col>
      </Row>
      <Row className="user-profile-row3-col  cardbg">
        <Col>
          <h5 className="h5">Candidate Submission Note</h5>
          <p>{userProfile?.submission?.comment}</p>
        </Col>
        <Col className="users-profile-row2-cols">
          <div style={{ maxHeight: '300px', height: '200px' }}>
            <h5 className="h5">Candidate Upload File</h5>
            <p>
              <a
                href={userProfile?.submission?.files}
                download="proposed_file_name"
              >
                Download
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
