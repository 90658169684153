import React from 'react';
import '../css/importCheckBoxes.css';
import CheckMark from '../../assets/icon/snapbrillia_checkbox_checkmark.svg';

const ImportCheckBoxes = ({ checked, setChecked }) => {
  return (
    <>
      <div
        className="import-checkbox-container"
        style={{ marginBottom: '20px' }}
      >
        <div
          onClick={() => setChecked({ ...checked, ignore: !checked.ignore })}
          className={
            checked.ignore ? 'import-checkbox-fill' : 'import-checkbox-empty'
          }
        >
          <input
            className="import-input-checkbox"
            type="checkbox"
            id="importErrors"
          />
          {checked.ignore && (
            <img src={CheckMark} alt="checkmark" width={18} height={18} />
          )}
        </div>
        <label className="import-checkbox-label" htmlFor="#importErrors">
          {' '}
          Ignore all Import Errors
        </label>
      </div>

      <div className="import-checkbox-container">
        <div
          onClick={() => setChecked({ ...checked, read: !checked.read })}
          className={
            checked.read ? 'import-checkbox-fill' : 'import-checkbox-empty'
          }
        >
          <input
            className="import-input-checkbox"
            type="checkbox"
            id="readAndAgree"
          />
          {checked.read && (
            <img src={CheckMark} alt="checkmark" width={18} height={18} />
          )}
        </div>
        <label className="import-checkbox-label" htmlFor="readAndAgree">
          I have read and agree to{' '}
          <a
            href="/"
            style={{ color: '#2d2b6f', textDecoration: 'none' }}
            target="_blank"
          >
            <b>Snapbrillia&apos;s Data Processing Addendum</b>
          </a>
        </label>
      </div>
    </>
  );
};

export default ImportCheckBoxes;
