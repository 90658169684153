/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useAccount } from 'wagmi';
import '../../shared/css/typography.css';
import '../../shared/css/buttonStyles.css';
import '../css/cart.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeModal from '../../shared/menus/stripeModal.jsx';
import companyDefaultLogo from '../../assets/snapbrillia_no_company_logo.png';
import { createStripeSession, listStripeAccounts } from '../../api/sessions';
import DropDownWallet from '../../shared/menus/dropDownWallet';
import { Dropdown } from 'react-bootstrap';
import { getSendAdaFee } from '../../api/crypto';
import { convertCurrency } from '../../utils/functions';
import { useCardanoWallet } from '../../context/walletContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function Cart({
  cart,
  setPayment,
  changeCartType,
  extraAmount,
  setExtraAmount,
  paymentFee,
  setPaymentFee,
  payment,
  platformFee,
}) {
  const [showWalletDropDown, setShowWalletDropDown] = useState(false);
  const { address } = useCardanoWallet();
  const [linkToken, setLinkToken] = useState('');
  const componentRef = useRef();
  const [accounts, setAccounts] = useState([]);
  const { account, isReady } = useAccount();
  const [adaAccount, setAdaAccount] = useState('');
  const [ethAccount, setEthAccount] = useState('');

  const connectBankAccount = async () => {
    const { client_secret } = await createStripeSession();
    setLinkToken(client_secret);
  };

  const reloadAccount = async () => {
    try {
      let accountInfo = '';
      if (account && cart[0].rewardType === '₳DA') {
        const fee = await getSendAdaFee();
        accountInfo = address;
        setPaymentFee(fee);
        setAdaAccount(address);
      } else if (!account && cart[0].rewardType === 'USD') {
        const stripeAccounts = await listStripeAccounts();
        accountInfo = stripeAccounts.accounts[0].id;
        setAccounts([...stripeAccounts.accounts]);
        setPaymentFee(0);
      } else {
        // setEthAccount('');
      }
      setPayment({
        paymentInfo: accountInfo,
        paymentType: cart[0].rewardType,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (account && cart[0].cryptoInfo?.name === 'Ethereum') {
      setEthAccount(account);
      setPayment({
        paymentInfo: account.address,
        paymentType: cart[0].rewardType,
      });
    }
  }, [isReady]);

  const handleChange = async (value) => {
    setPayment((prevState) => ({ ...prevState, paymentInfo: value }));
  };

  const handleOutsideClick = (componentRef, e) => {
    if (e.target.classList.contains('wallet-menu')) {
      return;
    }
    return componentRef.current && !componentRef.current.contains(e.target)
      ? setShowWalletDropDown(false)
      : null;
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleOutsideClick(componentRef, e)
    );
    return () => document.removeEventListener('mouse', handleOutsideClick);
  }, []);

  useEffect(() => {
    reloadAccount();
  }, []);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <>
      <Container fluid>
        <div
          ref={componentRef}
          style={{ display: `${showWalletDropDown ? '' : 'none'}` }}
          onClick={() => {
            setShowWalletDropDown(false);
          }}
        >
          <Dropdown show={showWalletDropDown}>
            <DropDownWallet className="wallet-menu" />
          </Dropdown>
        </div>
        <Modal.Header className="no-border" closeButton>
          <span className="h1">Adding Funds</span>
        </Modal.Header>
        <Modal.Body className="scrollable-cart">
          {cart.map((e, index) => {
            return (
              <Row className="row-border mb-4" key={index}>
                <Col md={7} sm={12} xs={12}>
                  <Row>
                    <Col md={'auto'} xs={'auto'}>
                      <img
                        src={companyDefaultLogo}
                        className="small-cart-img"
                      />
                    </Col>
                    <Col md={8} xs={8}>
                      <Row>
                        <span className="item-title">{e.name}</span>
                      </Row>
                      <Row>
                        <span className="grey-labels underline">
                          {e.creator}
                        </span>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} xs={9}>
                  <Row>
                    <label className="form_label label_fields">
                      Select form of Payment
                    </label>
                    <select
                      className="input_fields form_input select-down-icon"
                      id={'dropdown'}
                      onChange={(e) => {
                        e.preventDefault();
                        handleChange(e.target.value);
                      }}
                    >
                      <option
                        className="input_fields"
                        value=""
                        key="-1"
                        disabled={true}
                      >
                        Select Account
                      </option>
                      {accounts.map((account, index) => {
                        return (
                          <option
                            className="input_fields"
                            value={account.id}
                            key={index}
                          >
                            {`${account.institution_name} ... ${account.last4}`}
                          </option>
                        );
                      })}
                      {adaAccount.length > 0 && (
                        <option
                          className="input_fields"
                          value={adaAccount}
                          key={1}
                        >
                          {adaAccount.slice(0, 4)}...
                          {adaAccount.slice(-6)}
                        </option>
                      )}
                      {ethAccount && (
                        <option
                          className="input_fields"
                          value={ethAccount.address}
                          key={1}
                        >
                          {ethAccount.address.slice(0, 4)}...
                          {ethAccount.address.slice(-6)}
                        </option>
                      )}
                    </select>
                  </Row>
                  {!payment.paymentType === 'ADA' && (
                    <Row>
                      <a
                        className="wallet-dropdown-text mt-2 pointer"
                        onClick={changeCartType}
                      >
                        Or pay with credit card
                      </a>
                    </Row>
                  )}
                </Col>
                <Col className="remove-align pb-2" md={7} xs={3}></Col>
                <Col md={5} xs={9}>
                  <Row>
                    <label className="form_label label_fields">Amount</label>
                  </Row>
                  <Row>
                    <input
                      value={extraAmount}
                      className="form_input input_fields mb-2"
                      type="number"
                      min="0.0001"
                      id={'input'}
                      onKeyPress={preventMinus}
                      onChange={(e) => setExtraAmount(e.target.value)}
                    />
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
        <Row className="footer-padding">
          <Col className="text-end">
            {`Transaction Fee: ${convertCurrency(
              paymentFee,
              payment.paymentType
            )}`}
            <br />
            {` Fee (20%): ${convertCurrency(
              (extraAmount * 0.2).toFixed(2),
              payment.paymentType
            )}`}
            <br />
            {` Total: ${convertCurrency(
              (extraAmount * platformFee + paymentFee).toFixed(2),
              payment.paymentType
            )}`}
          </Col>
        </Row>
      </Container>
      <Elements stripe={stripePromise}>
        {linkToken && (
          <StripeModal clientSecret={linkToken} reloadAccount={reloadAccount} />
        )}
      </Elements>
    </>
  );
}
