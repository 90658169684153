import { select, scaleBand, scaleLinear, axisBottom, axisLeft, max } from 'd3';
//This file is implemention of bar chart used for allbarchart.jsx

//Color picker function Which is pick different colors 24 differrent color
export function HorizontalBarChart(data, width, height, svg, barcolors) {
  // remove all previous elements for fresh re-render
  svg.selectAll('g').remove();
  // X AND Y VALUES OF CHART
  const xValue = (d) => d.statistics;
  const yValue = (d) => d.values;

  const margin = { top: 20, right: 20, bottom: height / 10, left: width / 3 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  //SET X VALUES
  const xScale = scaleLinear()
    .domain([0, max(data, xValue)])
    .range([0, innerWidth]);
  //SET Y VALUES
  const yScale = scaleBand()
    .domain(data.map(yValue))
    .range([0, innerHeight])
    .padding(0.6);
  //SET BAR FOR DIFFRENT VALUES
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .style('font-size', innerHeight / yScale.domain().length);

  //Y AXIS LABLE
  g.append('g')
    .call(axisLeft(yScale))
    .style('font-size', function () {
      if (innerHeight / yScale.domain().length < 10) {
        ////Axis Font-size
        return innerHeight / yScale.domain().length;
      } else {
        return 10;
      }
    })
    .append('text')
    .attr('class', 'axis-label')
    .attr('y', -margin.left + margin.left / 10)
    .attr('x', -innerHeight / 2)
    .attr('fill', 'black')
    .attr('transform', 'rotate(-90)')
    .attr('text-anchor', 'middle')
    .style('font-size', margin.left / 15);

  //REMOVE EXTRA AND UNUSED LINES OF Y AXIS
  g.selectAll('.domain, .tick line').remove();

  //X AXIS LABLE
  g.append('g')
    .call(
      axisBottom(xScale)
        .tickSize((-innerHeight + margin.top) * 1.15)
        .ticks(5)
    ) //Gridline
    .style('font-size', function () {
      //Axis Font-size
      if (innerHeight / yScale.domain().length < 10) {
        return innerHeight / yScale.domain().length;
      } else {
        return 10;
      }
    })
    .style('opacity', '0.2')
    .attr('transform', `translate(0,${innerHeight})`);
  g.selectAll('.domain, path').remove(); //remove x-axis Line
  //CREATE BAR FOR ALL VALUES
  g.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('y', (d) => yScale(yValue(d)))
    .attr('id', function (d, i) {
      return i;
    }) //Assign id to rect
    .attr('width', (d) => xScale(xValue(d)))
    .attr('height', yScale.bandwidth())
    .attr('fill', function (d, i) {
      return barcolors[i];
    }) //Assign different color to rect
    .attr('rx', yScale.bandwidth() / 2) //boreder-radius
    .on('mouseover', function (d, i) {
      tooltip
        .html(`${i.values}: ${i.statistics}`)
        .style('visibility', 'visible');
    })
    .on('mousemove', function () {
      tooltip
        // eslint-disable-next-line no-restricted-globals
        .style('top', event.pageY - 10 + 'px')
        // eslint-disable-next-line no-restricted-globals
        .style('left', event.pageX + 10 + 'px');
    })
    .on('mouseout', function () {
      tooltip.html(``).style('visibility', 'hidden');
    });

  //------------------------------Toottipbox for hover effect----------------------------
  const tooltip = select('body')
    .append('div')
    .attr('x', innerWidth / 2)
    .attr('y', margin.top / 2)
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('color', ' black')
    .style('padding', '10px')
    .style('border', '1px solid #808080')
    .style('background', 'white')
    .style('border-radius', '5px');
}
export default HorizontalBarChart;
