import React from 'react';
//COMPONENTS
import { Container, Row, Col } from 'react-bootstrap';
import BackIcon from '../../assets/icon/snapbrillia_softwareengineerback_icon.svg';
import NextIcon from '../../assets/icon/snapbrillia_softwareengineernext_icon.svg';
//CSS
import '../../shared/css/bgColors.css';
import '../css/candidateResultsSection.css';

// props: tasks
const CandidateRetultsSection = () => {
  // const [currentTask, setCurrentTask] = useState(tasks[0]);
  // const [currentTaskId, setCurrentTaskId] = useState(0);
  // const [currentInputCode, setCurrentInputCode] = useState(null);
  // const taskLength = tasks.length - 1;

  return (
    <Container>
      <Row className="cardbg rounded">
        <Row className="mb-4 mt-2 justify-content-center">
          <Col>
            <span className="h5 bold"></span>
          </Col>
          <Col className="text-end">
            <img src={BackIcon} alt="back-icon" className="pointer me-3" />
            <img src={NextIcon} alt="next-icon" className="pointer" />
          </Col>
        </Row>
        <Row>
          <p className="grey-title"></p>
        </Row>
        <span className="divider my-3 ms-1"></span>
        <Row className="scrollable me-4">
          <Row className="mb-4">
            <Col>
              <span className="h5 bold ms-2">Result:</span>
            </Col>
            <Col>
              <span className="h5 bold ms-2">Candidate/Interviewer Notes</span>
            </Col>
          </Row>

          <Row>
            {/*RESULTS*/}
            <Col>
              <Row className="ms-2 mb-3">
                <Col className="task-error-bg rounded-3">
                  <p className="paragraph my-1">Create a Variable called</p>
                  <p className="grey-title ms-4">
                    Create a baribale called &quot;VarInOne&quot; with the type
                    &quot;integer&quot; and console log your function.
                  </p>
                </Col>
              </Row>
            </Col>
            {/*CANDIDATE/INTERVIEWER NOTES*/}
            <Col>
              <Row className="ms-2 mb-3">
                <Col className="task-success-bg rounded-3">
                  <p className="paragraph my-1">Create a Variable called</p>
                  <p className="grey-title ms-4">
                    Create a baribale called &quot;VarInOne&quot; with the type
                    &quot;integer&quot; and console log your function.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Row>
    </Container>
  );
};

export default CandidateRetultsSection;
