import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'react-bootstrap';

const VideoRecorderModal = ({ showModal, onStop, onCloseRecorder }) => {
  const [camera_stream, setCameraStream] = useState(null);
  const [media_recorder, setMediaRecorder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [recordingStarted, setRecordingStarted] = useState(false);
  const blobs_recorded = [];
  const videoRef = useRef(null);

  const getUserMedia = (cb) => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        setCameraStream(stream);
        videoRef.current.srcObject = camera_stream;
        cb();
      });
  };
  const onRecordingStart = (e) => {
    blobs_recorded.push(e.data);
  };
  const onRecordingStop = () => {
    const video_blob = new Blob(blobs_recorded, { type: 'video/webm' });
    onStop(video_blob);
    onCloseRecorder();
  };
  const _initializeMedia = () => {
    const mediaRecorder = new MediaRecorder(camera_stream, {
      mimeType: 'video/webm',
    });
    mediaRecorder.addEventListener('dataavailable', onRecordingStart);
    mediaRecorder.addEventListener('stop', onRecordingStop);
    mediaRecorder.start(1000);
    setMediaRecorder(mediaRecorder);
    setRecordingStarted(true);
  };
  const onPlayClicked = (e) => {
    e.preventDefault();
    _initializeMedia();
    e.stopPropagation();
  };
  const onStopClicked = (e) => {
    e.preventDefault();
    media_recorder.stop();
    setRecordingStarted(false);
    e.stopPropagation();
  };
  useEffect(() => {
    if (!loaded) {
      getUserMedia(() => setLoaded(true));
    }
  });
  return (
    <Modal
      onHide={() => {
        onCloseRecorder();
        onStopClicked();
      }}
      show={showModal}
    >
      <ModalHeader closeButton>Record Video Message</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <video width="400" autoPlay ref={videoRef}></video>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {!recordingStarted && (
          <button
            id="start-record"
            className="responsive-primary-btn"
            onClick={onPlayClicked}
          >
            <i className="fa fa-play" aria-hidden="true"></i>
          </button>
        )}
        {recordingStarted && (
          <button
            id="stop-record"
            className="responsive-primary-btn"
            onClick={onStopClicked}
          >
            <i className="fa fa-stop-circle-o" aria-hidden="true"></i>
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default VideoRecorderModal;
