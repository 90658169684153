export const chartColors = [
  '#1484a5',
  '#f7c85f',
  '#6f4e7b',
  '#9dd966',
  '#ca462e',
  '#fda056',
  '#8dded1',
  '#595EE9',
  '#6E46BE',
];
