import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import _ from 'lodash';
// import { ReactComponent as Apps } from '../../assets/icon/snapbrillia_apps_icon.svg';
import { ReactComponent as Help } from '../../assets/icon/snapbrillia_help_icon.svg';
import { ReactComponent as Notifications } from '../../assets/icon/snapbrillia_notifications_icon.svg';
import { ReactComponent as Companies } from '../../assets/icon/snapbrillia_profile_company_icon.svg';
import { ReactComponent as Feedback } from '../../assets/icon/snapbrillia_feedback_icon.svg';
import { ReactComponent as Wallet } from '../../assets/icon/snapbrillia_wallet_icon.svg';
import { ReactComponent as Power } from '../../assets/icon/snapbrillia_power_icon.svg';
import DropDownWallet from '../../shared/menus/dropDownWallet';
import Notification from '../../shared/menus/notification';
import DropDownCompany from '../../shared/menus/company';
import '../css/searchBarStyles.css';
import { navigate } from '@reach/router';
import FeedbackModal from '../../shared/modals/feedbackModal';
import SupportModal from '../../shared/modals/supportModal';
import LogOutModal from '../../shared/modals/LogOutModal';

import { useAuth } from '../../context/authContext';
import { UserImage } from '../../shared/images';
import * as NotificationAPI from '../../api/notification';

const SearchBarIcons = () => {
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationNumber, setNotificationNumber] = useState(0);
  const { logout } = useAuth();
  const loadNotifications = async () => {
    try {
      const { docs } = await NotificationAPI.getListNotifications();
      setNotifications(docs);

      const read = docs.filter(
        (noti) => noti.isRead === false && noti.typeUser === 'b2b'
      );
      setNotificationNumber(read.length);
    } catch (err) {}
  };

  const handleLogOut = async () => {
    await logout();
    return navigate('/signin');
  };

  const { auth } = useAuth();
  let notiRef = useRef();

  useEffect(() => {
    loadNotifications();
    let handler = (event) => {
      if (!notiRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <>
      <Col xs={3} md={3} xxl={3} className="p-0">
        <div className="search-icons-company ">
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-light"
              className="border-0 p-0 btn btn-outline w-100 search-icons-company-toggle"
            >
              <span className="search-icons-company-name">
                {_.truncate(auth.company.companyName, 10)}
              </span>
            </Dropdown.Toggle>
            <DropDownCompany
              showCompany={showCompany}
              setShowCompany={setShowCompany}
              companies={auth.companies}
            />
          </Dropdown>
        </div>
      </Col>
      <Col xs={4} md={4} xxl={4}>
        <div className="primarybg search-icons">
          {/* <Button variant="outline-light" className="border-0 p-0">
          <Apps
            className="search-bar-icon-clickable d-inline"
            onClick={() => navigate('/all-integrations')}
          />
        </Button> */}
          <Button variant="outline-light" className="border-0 p-0">
            <Help
              className="search-bar-icon-clickable"
              onClick={() => setShowSupportModal(!showSupportModal)}
            />
          </Button>
          <Button variant="outline-light" className="border-0 p-0">
            <Feedback
              className="search-bar-icon-clickable"
              onClick={() => setShowFeedbackModal(!showFeedbackModal)}
            />
          </Button>
          <Dropdown ref={notiRef}>
            {notificationNumber !== 0 && (
              <div className="notify-counter">
                {notificationNumber > 9 ? '9+' : notificationNumber}
              </div>
            )}

            <Notifications
              onClick={() => setShowNotifications(!showNotifications)}
              className="search-bar-icon-clickable"
            />

            <Notification
              showNotifications={showNotifications}
              loadNotify={loadNotifications}
            />
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="outline-light" className="border-0 p-0">
              <Wallet className="search-bar-icon-clickable" />
            </Dropdown.Toggle>
            <DropDownWallet className="wallet-menu" />
          </Dropdown>

          <img
            onClick={() => navigate('/personal-profile')}
            className="search-profile-photo ms-1"
            src={UserImage(auth.user)}
            alt="profile"
          />
          {showSupportModal && (
            <SupportModal
              showModal={showSupportModal}
              setShowModal={setShowSupportModal}
            />
          )}
          {showFeedbackModal && (
            <FeedbackModal
              showModal={showFeedbackModal}
              setShowModal={setShowFeedbackModal}
            />
          )}
          <Button variant="outline-light" className="border-0 p-0">
            <Power
              className="search-bar-icon-clickable"
              onClick={() => setShowLogOut(!showLogOut)}
            />
          </Button>
          <LogOutModal
            show={showLogOut}
            closeModal={() => setShowLogOut(false)}
            handleLogOut={handleLogOut}
          />
        </div>
      </Col>
    </>
  );
};

export default SearchBarIcons;
