import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Candidatelist from './candidatesList';
import ChallengeListItem from './challengeListItems';
import '../css/tabsView.css';
import InviteEmailModal from '../../emailPageAssessment/inviteEmailModal';

//COMPONENTS
import ChartGridAnalitycs from '../../analyticPage/components/chartGridAnalytics';
import ProjectPageOverview from '../components/projectPageOverview';
const TabsView = ({
  auth,
  tasks,
  workSampleProject,
  candidates,
  setCandidates,
  sendReviewCandidate,
  handleOpenAddCandidate,
  interviewers,
}) => {
  const [candidatesSelected, setCandidatesSelected] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const handleEmailSent = async (assessment) => {
    const newCandidates = candidates.map((candidate) => {
      if (assessment.candidateId === candidate._id) {
        candidate.assessment = assessment;
      }
      return candidate;
    });
    await setCandidates(newCandidates);
  };
  const handleClickInvite = async (candidate) => {
    setCandidatesSelected([candidate]);
    setShowInviteModal(true);
  };

  const tabs = [
    {
      title: 'Overview',
      component: (
        <ProjectPageOverview
          workSampleProject={workSampleProject}
          candidates={candidates}
        />
      ),
    },
    {
      title: 'Candidates',
      component: (
        <Candidatelist
          candidates={candidates}
          tasks={tasks}
          handleClickInvite={handleClickInvite}
          sendReviewCandidate={sendReviewCandidate}
          handleOpenAddCandidate={handleOpenAddCandidate}
          workSampleProject={workSampleProject}
          user={auth.user}
        />
      ),
    },
    {
      title: 'Diversity',
      component: <ChartGridAnalitycs rowsToDisplay={2} showTitle={true} />,
    },
    workSampleProject.type === 'custom'
      ? {
          title: 'Challenges',
          component: (
            <ChallengeListItem
              tasks={tasks}
              workSampleProject={workSampleProject}
            />
          ),
        }
      : '',
  ];

  const [component, setComponent] = useState(tabs[0]);
  const handleUpdateCandidates = async () => {
    let currentTab = 0;
    tabs.map((tab, index) => {
      if (tab.title === component.title) {
        currentTab = index;
      }
    });
    let switchTabIndex = currentTab > 1 ? 0 : 2;
    await changeComponent(switchTabIndex);
    await changeComponent(currentTab);
  };
  const changeComponent = (index) => {
    setComponent(tabs[index]);
  };
  useEffect(() => {
    handleUpdateCandidates();
  }, [candidates]);

  return (
    <Container fluid>
      <Row className="tab-title-container">
        {tabs.map((tab, index) => {
          return (
            <Col key={index}>
              <label
                className={
                  tab.title === component.title ? 'active-tab-title' : ''
                }
                onClick={() => changeComponent(index)}
                htmlFor={`radio-${index}`}
              >
                {tab.title}
                <span className={`${index > 0 && 'left-curve'}`}></span>
              </label>
            </Col>
          );
        })}
        <div id="tab-glider" />
      </Row>
      <Col
        className={
          component.title === 'Overview'
            ? 'tabs-view-tab-overview'
            : 'tabs-view-tab'
        }
      >
        {component.component}
      </Col>
      {showInviteModal && (
        <InviteEmailModal
          candidateList={candidatesSelected}
          workSampleProject={workSampleProject}
          auth={auth}
          interviewers={interviewers}
          setShowInviteModal={setShowInviteModal}
          handleEmailSent={handleEmailSent}
        />
      )}
    </Container>
  );
};

export default TabsView;
