import '../css/buttonStyles.css';
import React from 'react';

export default function AddJobButton() {
  return (
    <div>
      <button
        style={
          {
            // minWidth: '180px',
            // padding: '5px 20px',
          }
        }
        className="btn-primary"
      >
        <i
          className="fa-solid fa-circle-info"
          style={{
            color: 'grey',
            marginRight: '10px',
          }}
        ></i>
        Add Job
      </button>
    </div>
  );
}
