import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import '../../as';
// import { getRepositories } from '../../api/githubApp';
import { useAuth } from '../../context/authContext';
import useGithubApp from '../../hooks/useGithubApp';

const AssessmentAddGitBucket = ({ setUrl, setRepoInfo }) => {
  const { auth } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const {
    // githubAppUrl,
    openPopup,
    repos,
    // branches,
    linkGithubUrl,
    loadRepositories,
  } = useGithubApp();
  useEffect(() => {
    loadRepositories();
  }, [auth]);

  const handleChange = (e) => {
    const repo = repos.find((repo) => repo.github_url === e.target.value);
    setRepoInfo(repo);
    setUrl(e.target.value);
  };

  useEffect(() => {
    if (repos.length > 0) {
      setLoaded(true);
      setRepoInfo(repos[0]);
      setUrl(repos[0].github_url);
    }
  }, [repos]);

  return (
    <Col className="assessment-git-bucket position-relative">
      <Row>
        <Col className="assessment-type-input-label-col">
          <label className="assessment-git-bucket-label">
            Add GitHub URL *
          </label>
          {repos.length === 0 && (
            <span style={{ cursor: 'pointer' }} onClick={() => openPopup()}>
              Get repository
            </span>
          )}
        </Col>
      </Row>
      {!auth.user.githubId && (
        <p className="assessment-type-p px-2">
          Please install our{' '}
          <a href={linkGithubUrl} target="_blank" rel="noreferrer">
            Snapbrillia Github Application
          </a>
        </p>
      )}
      {loaded && repos.length === 0 && auth.user.githubId && (
        <p className="assessment-type-p px-2">
          Please install our{' '}
          <a
            href={process.env.REACT_APP_GITHUB_APP_URL}
            target="_blank"
            rel="noreferrer"
          >
            Snapbrillia Github Application
          </a>
        </p>
      )}
      {loaded && repos.length > 0 && auth.user.githubId && (
        <select
          name="url"
          className="assessment-type-input px-2"
          onChange={handleChange}
        >
          {repos.map((repo, index) => {
            return (
              <option value={repo.github_url} key={index} className={repo._id}>
                {repo.github_url}
              </option>
            );
          })}
        </select>
      )}
    </Col>
  );
};

export default AssessmentAddGitBucket;
