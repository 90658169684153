/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';
import { Button, Col, Row } from 'react-bootstrap';
import CouponForm from './CouponForm';
import { getAllCoupon } from '../../api/coupon';
import CouponResult from './couponResult';
import { useParams, Link } from '@reach/router';
import AddBountiesFormButton from '../../shared/button/addBountiesForm';
import moment from 'moment';

const AllCouponTable = () => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showAddCandidate, setShowAddCandidate] = useState(false);
  const [editCoupon, setEditCoupon] = useState({});
  const [result, setResult] = useState({});
  const companyId = useParams()?.companyId;
  const gigId = useParams()?.gigId;
  let today = new Date();
  let dateYear = new Date(today.setFullYear(today.getFullYear() + 1));
  const [values, setValues] = useState({
    expiry: moment(dateYear).format('YYYY-MM-DD'),
  });
  const getCouponData = async (search) => {
    setLoading(true);
    await getAllCoupon()
      .then((response) => {
        setCoupons(response);
      })
      .catch((error) => {
        setCoupons([]);
      });

    setLoading(false);
  };

  let dateToday = new Date();
  const clickEdit = (data, index) => {
    setEditCoupon(data);
    setValues({
      useCount: data.useCount || 0,
      expiry:
        data.expiry ||
        new Date(dateToday.setFullYear(dateToday.getFullYear() + 1)),
      isInvoiceCoupon: data.isInvoiceCoupon || false,
      fee: data.fee || 0,
      companyId: '',
    });
    setShowAddCandidate(true);
  };

  const getStatus = (date) => {
    const future = new Date(date).getTime() / 1000;
    const now = new Date().getTime() / 1000;

    if (future > now) {
      return 'Active';
    } else {
      return 'Expired';
    }
  };

  useEffect(() => {
    getCouponData();
  }, []);
  //should be memoized or stable
  var startTime = performance.now();
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.coupon, //access nested data with dot notation
        header: 'Coupon Code',
        size: 150,
      },
      {
        accessorFn: (row) =>
          `${row.isInvoiceCoupon ? 'Invoiced Coupon' : 'Discount Coupon'}`,
        header: 'Coupon Type',
        size: 50,
      },
      {
        accessorFn: (row) => getStatus(row?.expiry),
        header: 'Status',
        size: 50,
      },
      {
        accessorFn: (row) => row?.companyId?.companyName,
        header: 'Company',
        size: 50,
      },
      {
        accessorFn: (row) =>
          !row.isInvoiceCoupon ? `${row?.fee + '%'}` : '100%',
        header: 'Percentage',
        size: 50,
      },
      {
        accessorFn: (row) =>
          new Date(row?.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 150,
      },
      {
        accessorFn: (row) =>
          new Date(row?.expiry).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Expiry',
        size: 150,
      },
    ],
    []
  );
  var endTime = performance.now();

  // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`);
  return (
    <>
      <div className="float-end">
        <AddBountiesFormButton
          text={'Create Coupon'}
          handleClick={() => setShowAddCandidate(true)}
        />
      </div>
      <CouponForm
        handleClose={() => {
          setShowAddCandidate(false);
          setEditCoupon({});
          setValues({});
        }}
        showModal={showAddCandidate}
        setResult={setResult}
        setShowResult={setShowResult}
        editCoupon={editCoupon}
        values={values}
        setValues={setValues}
        handleCreateCoupon={() => {
          getCouponData();
          setShowAddCandidate(false);
        }}
      />
      <CouponResult
        handleClose={() => setShowResult(false)}
        showModal={showResult}
        result={result}
      />
      <MaterialReactTable
        columns={columns}
        data={coupons}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        enableRowActions
        renderRowActions={({ row }) =>
          getStatus(row.original.expiry) !== 'Expired' && (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                onClick={() => {
                  clickEdit(row.original, row.index);
                }}
              >
                Edit Coupon
              </Button>
            </Box>
          )
        }
      />
    </>
  );
};

export default AllCouponTable;
