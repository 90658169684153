import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { validateMemberFields } from '../../utils/functions';
import * as memberAPI from '../../api/members';
import * as userAPI from '../../api/users';

export const MemberModal = createContext();

const MemberModalAPI = ({
  children,
  memberId,
  companyId,
  WorkSampleProjectId,
  members,
  setMembers,
  handleClose,
}) => {
  const [member, setMember] = useState({});
  const [memberFields, setMemberFields] = useState({
    fullName: '',
    title: 'member',
    companyRole: 'member',
    email: '',
    blind: true,
    workSampleProjectId: WorkSampleProjectId,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitForm, setSubmitForm] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateMemberFields(memberFields));
    setSubmitForm(true);
  };

  const getMember = async () => {
    const memberData = await memberAPI.getMember(memberId);
    await setMember(memberData);
    await setMemberFields({ ...memberData, role: memberData.companyRole });
  };

  const editMember = async () => {
    try {
      const { message, user } = await memberAPI.editMember(
        memberId,
        memberFields
      );
      let newMembersList = await members.map((item) => {
        if (item._id === user._id) {
          return user;
        }
        return item;
      });
      setMembers(newMembersList);
      toast(message);
      handleClose();
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleRemoveMember = async (memberRemove) => {
    try {
      await memberAPI.removeMember(memberRemove._id, WorkSampleProjectId);
      var newMembersList = members.filter(function (item) {
        return item._id != memberRemove._id;
      });
      if (newMembersList.length < 1) {
        handleClose();
      }
      setMembers(newMembersList);
    } catch (error) {}
  };

  const handleActiveMember = async (memberActive) => {
    try {
      await userAPI.activate(memberActive._id);
    } catch (error) {}
  };

  const handleDeactiveMember = async (memberDeactive) => {
    try {
      await userAPI.deactivate(memberDeactive._id);
    } catch (error) {}
  };

  const inviteMember = async () => {
    const response = await memberAPI.inviteMember(companyId, [memberFields]);
    if (!response.invitedMember) {
      toast.error(
        'Member is invited or belong to others company. Please try other!'
      );
      return false;
    }
    await setMembers([...members, response.invitedMember]);
    toast(response.message);
    return true;
  };

  useEffect(() => {
    if (memberId) {
      getMember();
    }
  }, [memberId]);

  return (
    <MemberModal.Provider
      value={{
        getMember,
        editMember,
        setMember,
        handleFormSubmit,
        member,
        setMemberFields,
        memberFields,
        setFormErrors,
        formErrors,
        submitForm,
        setSubmitForm,
        inviteMember,
        handleRemoveMember,
        members,
        handleActiveMember,
        handleDeactiveMember,
        companyId,
      }}
    >
      {children}
    </MemberModal.Provider>
  );
};

export default MemberModalAPI;
