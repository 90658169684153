import React, { useState } from 'react';
import AddAssessmentTypeSelection from './components/addAssessmentTypeSelection';
import AddProjectFields from './components/addProjectFieldsModal';
import { Form, Modal, Container, Row, Col } from 'react-bootstrap';
import './css/addAssessmentTypeSelection.css';
import './css/addProjectFields.css';
import NextButton from '../shared/button/next';
import BackButton from '../shared/button/back';
import CandidateModal from '../candidateModal/candidateModal';
import * as ProjectApi from '../api/projects';
import { toast } from 'react-toastify';
const ProjectModal = ({ show, setShow, addNewProject }) => {
  const [step, setStep] = useState(1);
  const [isSubmit, setIsSubmit] = useState(0);
  const [url, setUrl] = useState('');
  const [repoInfo, setRepoInfo] = useState({});
  const [WSPFields, setWSPFields] = useState({ type: 'ai' });
  const handleChange = (source) => {
    setWSPFields({ ...WSPFields, [source.target.name]: source.target.value });
  };

  const handleSubmit = async () => {
    const body = {
      ...WSPFields,
      githubUrl: repoInfo.github_url,
      githubID: repoInfo._id,
      githubRepoName: repoInfo.full_name,
    };
    const { project } = await ProjectApi.createProject(body);
    if (project) {
      addNewProject(project);
      toast.success('Successful!');
      handleClose();
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleNext = async () => {
    if (step === 1) {
      if (url) {
        setIsSubmit(0);
        return setStep(2);
      } else {
        toast.error('You Need To Add GitHub URL!');
      }
    }
    if (step === 2) {
      setIsSubmit(isSubmit + 1);
    }
  };
  const handleBack = () => {
    if (step === 1) {
      return;
    }
    setIsSubmit(0);
    setStep(step - 1);
  };

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Form>
          {step === 1 && (
            <AddAssessmentTypeSelection
              setShowModal={setShow}
              setStep={setStep}
              WSPFields={WSPFields}
              setWSPFields={setWSPFields}
              handleChange={handleChange}
              setUrl={setUrl}
              url={url}
              setRepoInfo={setRepoInfo}
            />
          )}
          {step === 2 && (
            <AddProjectFields
              setShowModal={setShow}
              setStep={setStep}
              WSPFields={WSPFields}
              setWSPFields={setWSPFields}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              url={url}
              repoInfo={repoInfo}
              isSubmit={isSubmit}
            />
          )}
          {step === 3 && (
            <CandidateModal
              openAddCandidate={show}
              setOpenAddCandidate={handleClose}
              action="add"
            />
          )}
        </Form>
        <Modal.Footer className="bounty-footer no-border">
          <Row className="pb-0">
            <Col className="p-0">
              <span className="me-5">
                {step !== 1 && <BackButton onClick={handleBack} />}
              </span>
              <NextButton onClick={handleNext} />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProjectModal;
