import '../css/buttonStyles.css';
import { Spinner } from 'react-bootstrap';

export default function SwitchGig(props) {
  return (
    <button
      className="btn-secondary p-0 ml-2"
      {...props}
      disabled={props?.isLoading || false}
    >
      {props?.isLoading ? (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        `Switch to General Gig`
      )}
    </button>
  );
}
