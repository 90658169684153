import React, { useState, useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import { Spinner } from 'react-bootstrap';
const Loading = () => {
  return (
    <Container
      style={{
        overflow: 'hidden',
      }}
      className=" vh-80"
    >
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center py-5">
          <Spinner
            as="span"
            animation="grow"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        </div>
      </div>
    </Container>
  );
};
export default Loading;
