import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import defaultProfile from '../../assets/icon/snapbrillia_profile_personal_icon.svg';
import RemoveProfileImageModal from '../../shared/modals/removeProfileImageModal';
import ProfileImageModal from '../../shared/modals/profileImageModal';
import '../../shared/css/sharedStyles.css';
import * as userApi from '../../api/users';
import { useAuth } from '../../context/authContext';
import '../css/avatarButtons.css';

const PersonalProfilePhoto = ({ handleSave, handleUpdateUserInfo }) => {
  const { auth } = useAuth();
  const [source, setSource] = useState('default');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleRemove = async () => {
    await userApi.updateUserAvatar({ avatar: '' });
    setSource('default');
    toast.success('Your profile picture has been removed', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
    setShowConfirm(false);
  };
  useEffect(() => {
    if (auth.user?.avatar) {
      setSource('custom');
    }
  }, [auth.user.avatar]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="shadow p-0 mb-3 bg-white rounded-circle profile">
        <img
          src={source === 'default' ? defaultProfile : auth.user.avatar}
          alt="profile"
          height={200}
          width={200}
          style={{ borderRadius: '50%' }}
        />
        <div className="flex-column justify-content-center align-items-center edit-profile">
          <div className="avatar-buttons-container">
            <div>
              {/* prettier-ignore */}
              <button 
                style={{ 
                  borderRight: '1px solid #ccc', 
                  color: source === 'default' ? '#ccc': '#fff'
                }} 
                onClick={() => setShowConfirm(true)}
                disabled={source === 'default'}
              >Remove</button>
            </div>
            <div>
              <button onClick={() => setOpenEditModal(true)}>Replace</button>
            </div>
          </div>
        </div>
      </div>

      <label className="form_label">
        <input className="checkbox" type="checkbox" />
        <span className="p-2 primary fw-500">
          Use as profile picture on emails
        </span>
      </label>
      <RemoveProfileImageModal
        show={showConfirm}
        closeModal={() => setShowConfirm(false)}
        handleRemove={handleRemove}
      />
      <ProfileImageModal
        show={openEditModal}
        closeModal={() => setOpenEditModal(false)}
        handleRemove={handleRemove}
        source={source === 'default' ? defaultProfile : auth.user.avatar}
        handleSave={handleSave}
        setSource={setSource}
        handleUpdateUserInfo={handleUpdateUserInfo}
      />
    </div>
  );
};
export default PersonalProfilePhoto;
