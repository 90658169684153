import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../css/assessmentAddGitBucket.css';
import { useAuth } from '../../context/authContext';
import useGithubApp from '../../hooks/useGithubApp';

const AssessmentAddGitBucket = ({ setUrl, setRepoInfo, repoInfo }) => {
  const { auth } = useAuth();

  const {
    openPopup,
    branches,
    setUrl: setGithubUrl,
    linkGithubUrl,
    githubAppUrl,
    repos,
    org,
    orgs,
    setOrg,
  } = useGithubApp();

  const handleChange = (e) => {
    const repo = repos.find((repo) => repo.github_url === e.target.value);
    setRepoInfo(repo);
    setUrl(e.target.value);
    setGithubUrl(e.target.value);
  };

  const handleChangeOrg = (e) => {
    const org = orgs.find((o) => o.id == e.target.value);
    setOrg(org);
  };

  const handleBranchChange = (e) => {
    setRepoInfo({
      ...repoInfo,
      repoBranch: e.target.value,
    });
  };

  useEffect(() => {
    if (repos.length > 0) {
      setRepoInfo(repos[0]);
      setUrl(repos[0].github_url);
      setGithubUrl(repos[0].github_url);
    }
  }, [repos]);

  useEffect(() => {
    if (branches.length > 0) {
      setRepoInfo({
        ...repoInfo,
        repoBranch: branches[0].name,
      });
    }
  }, [branches]);
  return (
    <Col className="assessment-git-bucket position-relative">
      <Row>
        <Col className="assessment-type-input-label-col">
          <label className="assessment-git-bucket-label">
            Select Github URL *
          </label>
          <span style={{ cursor: 'pointer' }} onClick={() => openPopup()}>
            Get Repository
          </span>
        </Col>
      </Row>
      {!auth.user.githubId && (
        <div className="add-git-gen-style">
          Don&apos;t see your Github URL? Please install our{' '}
          <a
            href={!auth.user.githubId ? linkGithubUrl : githubAppUrl}
            target="_blank"
            rel="noreferrer"
          >
            Snapbrillia Github Application{' '}
          </a>
          to continue or select <b>Get Repository</b> above. If it does not show
          up after you connect, please refresh.
        </div>
      )}
      {repos.length === 0 && !org.id && auth.user.githubId && (
        <div className="add-git-gen-style">
          Don&apos;t see your Github URL? Please install our{' '}
          <a
            href={!auth.user.githubId ? linkGithubUrl : githubAppUrl}
            target="_blank"
            rel="noreferrer"
          >
            Snapbrillia Github Application{' '}
          </a>
          to continue or select <b>Get Repository</b> above. If it does not show
          up after you connect, please refresh.
        </div>
      )}
      {repos.length >= 0 && auth.user.githubId && (
        <Row className="gx-1">
          <Col xs={3} className="">
            <select
              name="url"
              className="assessment-type-input px-2"
              onChange={handleChangeOrg}
            >
              {orgs.map((org, index) => {
                return (
                  <option value={org.id} key={index}>
                    {org.login}
                  </option>
                );
              })}
            </select>
          </Col>
          <Col xs={9} className="">
            <select
              name="url"
              className="assessment-type-input px-2"
              onChange={handleChange}
            >
              {repos.map((repo, index) => {
                return (
                  <option
                    value={repo.github_url}
                    key={index}
                    className={repo._id}
                  >
                    {repo.github_url}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
      )}
      {repos.length > 0 && branches.length > 0 && auth.user.githubId && (
        <Col xs={8} className="form_label bounty-type-label mb-0 ps-0">
          Select Branch to work from<span>&nbsp;*</span>
        </Col>
      )}
      {repos.length > 0 && branches.length > 0 && auth.user.githubId && (
        <select
          name="repoBranch"
          className="assessment-type-input px-2"
          onChange={handleBranchChange}
        >
          {branches.map((branch, index) => {
            return (
              <option value={branch.name} key={index}>
                {branch.name}
              </option>
            );
          })}
        </select>
      )}
    </Col>
  );
};

export default AssessmentAddGitBucket;
