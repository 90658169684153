import LineChart from './allLineCharts';
import BarChart from './allBarCharts';
import PieChart from './allPieChart';
function ChartsDemo() {
  // switch out id for a worksampleproject id from your local mongodb to test
  const id = '628e5ab273849ba1c7bd0613';
  return (
    <>
      {/* diversity rating example line chart with no axis */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <LineChart
          WSPId={id}
          dataType="diversityRating"
          dataType2="female"
          height={150}
          width={250}
          showAxis={true}
        />
      </div>

      {/* line chart with axes */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <LineChart
          WSPId={id}
          dataType="responseRate"
          status="reviewed"
          height={200}
          width={350}
          showAxis={true}
        />
      </div>

      {/* vertical bar chart hidden title and axes */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <BarChart
          vertical={true}
          width={300}
          height={200}
          WSPId={id}
          dataType="responseRate"
          status="all"
          showAxis={false}
          showTitle={false}
        />
      </div>

      {/* vertical bar chart title visible, axes showing */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <BarChart
          vertical={true}
          width={300}
          height={200}
          WSPId={id}
          dataType="age"
          status="all"
          showAxis={true}
          showTitle={true}
        />
      </div>

      {/* vertical bar chart title visible, axes hidden */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <BarChart
          vertical={true}
          width={300}
          height={200}
          WSPId={id}
          dataType="diversityRating"
          dataType2="all"
          showAxis={true}
          showTitle={true}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <BarChart width={300} height={200} WSPId={id} dataType="responseRate" />
      </div>

      {/* horizontal bar chart hiding title */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <BarChart
          width={300}
          height={200}
          WSPId={id}
          dataType="ability"
          status="all"
          showTitle={false}
        />
      </div>

      {/* horizontal bar chart showing title */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <BarChart
          width={300}
          height={200}
          WSPId={id}
          dataType="gender"
          status="all"
          showTitle={true}
        />
      </div>

      {/* pie chart show subtitle, legend left aligned, title positioned on top */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <PieChart
          width={550}
          height={350}
          WSPId={id}
          dataType="responseRate"
          innerRadius={0}
          alignLegend="left"
          titlePosition="top"
          showStatus={true}
        />
      </div>

      {/* donut chart no title (simply omit titlePosition param) and no subtitle */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <PieChart
          width={550}
          height={400}
          WSPId={id}
          dataType="degree"
          status="all"
          innerRadius={30}
          alignLegend="left"
          showStatus={false}
        />
      </div>

      {/* pie charts with bottom title positioning bottom and both left and right legend alignment */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <PieChart
          width={550}
          height={400}
          WSPId={id}
          dataType="gender"
          status="all"
          innerRadius={0}
          alignLegend="left"
          titlePosition="bottom"
        />
        <PieChart
          width={550}
          height={400}
          WSPId={id}
          dataType="ethnicity"
          status="all"
          innerRadius={0}
          alignLegend="right"
          titlePosition="bottom"
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <PieChart
          width={500}
          height={400}
          WSPId={id}
          dataType="reviewRate"
          titlePosition="top"
        />
      </div>
    </>
  );
}

export default ChartsDemo;
