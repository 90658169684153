import React from 'react';
import { Stepper, Step } from 'react-form-stepper';
import '../../eeoForm/css/eeoFormStyles.css';
import '../../shared/css/typography.css';
import { Row, Col, Container, ToastHeader } from 'react-bootstrap';
import Next from '../../shared/button/next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as requestDemoApi from '../../api/requestDemo';
import { toast } from 'react-toastify';
const UpdateInformationFormInput = ({
  id,
  label,
  type = 'text',
  placeholder,
  onChange,
  error,
  touched,
  value,
  disabled,
}) => {
  return (
    <section className="signup-form-section">
      <Row className="mb-1">
        <Col md={12}>
          <label
            htmlFor={label}
            className="form_label label_fields signup-form-label"
          >
            {label}
          </label>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={12}>
          <input
            className="form_input input_fields px-1"
            id={id}
            placeholder={' ' + placeholder}
            type={type}
            onChange={onChange}
            value={value}
            disabled={disabled}
          ></input>
        </Col>
        {touched && Boolean(error) && (
          <span className="error-message">{error}</span>
        )}
      </Row>
    </section>
  );
};

export default function requestDemoForm({ setStep, setData }) {
  const validationSchema = yup.object({
    fullName: yup.string().required('Full name is required!'),
    title: yup.string().required('Title is required!'),
    companyName: yup.string().required('Company Name is required!'),
    email: yup.string().required('Email is required!'),
  });
  const companySizeOptions = [
    { value: '1-1000', label: '1-1000 Employees' },
    { value: '1001-5000', label: '1001-5000 Employees' },
    { value: '5000+', label: '5000+ Employees' },
  ];
  const sendRequestDemo = async (values) => {
    try {
      const data = await requestDemoApi.sendRequestDemo(values);
      toast.success('thank you for your request we will respond soon.');
    } catch (error) {}
  };
  const handleNext = () => {
    setStep(1);
  };
  const {
    handleChange,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    values,
    resetForm,
  } = useFormik({
    initialValues: {
      fullName: '',
      title: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      companySize: '1-1000',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await sendRequestDemo(values);
        setData({ email: values.email, fullName: values.fullName });
        handleNext();
        resetForm();
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Container className="">
      <Col>
        <section className="signup-header">
          <form onSubmit={handleSubmit}>
            <UpdateInformationFormInput
              id="fullName"
              label={'Full Name'}
              placeholder="Enter your full name"
              onChange={handleChange}
              value={values.fullName}
              touched={touched.fullName}
              error={errors.fullName}
            />
            <UpdateInformationFormInput
              id="title"
              label={'Job Title'}
              placeholder="Enter your job title"
              onChange={handleChange}
              value={values.title}
              touched={touched.title}
              error={errors.title}
            />
            <UpdateInformationFormInput
              id="companyName"
              label={'Company Name'}
              placeholder="Enter your company name"
              onChange={handleChange}
              value={values.companyName}
              touched={touched.companyName}
              error={errors.companyName}
            />
            <UpdateInformationFormInput
              id="email"
              label={'Email Address'}
              type={'email'}
              placeholder="Enter your email address"
              onChange={handleChange}
              value={values.email}
              touched={touched.email}
              error={errors.email}
            />

            <UpdateInformationFormInput
              id="phoneNumber"
              label={'Phone Number'}
              placeholder="Enter your phone number"
              onChange={handleChange}
              value={values.phoneNumber}
              touched={touched.phoneNumber}
              error={errors.phoneNumber}
            />
            <Col>
              <Row>
                <label className="grey-title bold">Company Size</label>
              </Row>
              <Row>
                <label>
                  <select
                    className="label_fields form_input paragraph select-company"
                    onChange={handleChange}
                    value={values.companySize}
                    name="companySize"
                  >
                    {companySizeOptions.map((item, index) => {
                      return (
                        <option
                          value={item.value}
                          key={index}
                          className="paragraph"
                          selected={item.value === values.companySize}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </Row>
            </Col>
            <Col className=" signup-bottom-float mt-3">
              <Next />
            </Col>
          </form>
        </section>
      </Col>
    </Container>
  );
}
