import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HelpIcon from '../../../assets/icon/snapbrillia_help_icon.svg';
import '../../../shared/css/sharedStyles.css';
import '../css/greenhouseComponents.css';
import React from 'react';

import SearchIcon from '../../../assets/icon/snapbrillia_search_icon.svg';
import '../../../shared/css/bgColors.css';

// var alertPlaceholder = document.getElementById('liveAlertPlaceholder');
// var alertTrigger = document.getElementById('liveAlertBtn');

// function alert(message) {
//   var wrapper = document.createElement('div');
//   wrapper.innerHTML =
//     '<div class="alert alert-success alert-dismissible" role="alert">' +
//     message +
//     '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button </div>';
//   alertPlaceholder.append(wrapper);
// }

// if (alertTrigger) {
//   alertTrigger.addEventListener('click', function () {
//     alert('Nice, you triggered this alert message!', 'success');
//   });
// }

const GreenhouseAllCandidateFields = ({
  handleSearch,
  fullNameStyle,
  setfullNameStyle,
  firstNameStyle,
  setfirstNameStyle,
  lastNameStyle,
  setlastNameStyle,
  primaryEmailStyle,
  setprimaryEmailStyle,
  extraEmail1Style,
  setextraEmail1Style,
  extraEmail2Style,
  setextraEmail2Style,
  phoneNumberStyle,
  setphoneNumberStyle,
  profileHighlightStyle,
  setprofileHighlightStyle,
  locationStyle,
  setlocationStyle,
  locationCountyStyle,
  setlocationCountyStyle,
}) => {
  const changefullNameStyle = () => {
    if (fullNameStyle === 1) {
      setfullNameStyle(2);
    } else {
      setfullNameStyle(1);
    }
  };

  const changefirstNameStyle = () => {
    if (firstNameStyle === 1) {
      setfirstNameStyle(2);
    } else {
      setfirstNameStyle(1);
    }
  };

  const changelastNameStyle = () => {
    if (lastNameStyle === 1) {
      setlastNameStyle(2);
    } else {
      setlastNameStyle(1);
    }
  };

  const changeprimaryEmailStyle = () => {
    if (primaryEmailStyle === 1) {
      setprimaryEmailStyle(2);
    } else {
      setprimaryEmailStyle(1);
    }
  };

  const changeextraEmail1Style = () => {
    if (extraEmail1Style === 1) {
      setextraEmail1Style(2);
    } else {
      setextraEmail1Style(1);
    }
  };

  const changeextraEmail2Style = () => {
    if (extraEmail2Style === 1) {
      setextraEmail2Style(2);
    } else {
      setextraEmail2Style(1);
    }
  };

  const changephoneNumberStyle = () => {
    if (phoneNumberStyle === 1) {
      setphoneNumberStyle(2);
    } else {
      setphoneNumberStyle(1);
    }
  };

  const changeprofileHighlightStyle = () => {
    if (profileHighlightStyle === 1) {
      setprofileHighlightStyle(2);
    } else {
      setprofileHighlightStyle(1);
    }
  };

  const changelocationStyle = () => {
    if (locationStyle === 1) {
      setlocationStyle(2);
    } else {
      setlocationStyle(1);
    }
  };

  const changelocationCountyStyle = () => {
    if (locationCountyStyle === 1) {
      setlocationCountyStyle(2);
    } else {
      setlocationCountyStyle(1);
    }
  };

  return (
    <Container className="greenhouse-fields-background greenhouse-fields-search-container">
      <Row className="position-relative greenhouse-fields-padding">
        <Col>
          <input
            className=""
            placeholder="Search Fields"
            type="text"
            onChange={(e) => handleSearch(e)}
          />
          <img src={SearchIcon} alt="search-icon" className="search-bar-icon" />
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <label className="form_label label_fields" for="greenhouse_full_name">
            Full Name
          </label>
          <input
            className=""
            type="text"
            id="greenhouse_full_name"
            name="greenhouse_full_name"
          ></input> */}
          <div
            className={
              fullNameStyle === 2 ? 'alert alert-success' : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_full_name"
            name="greenhouse_full_name"
            onClick={changefullNameStyle}
          >
            Full Name
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              firstNameStyle === 2 ? 'alert alert-success' : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_first_name"
            name="greenhouse_first_name"
            onClick={changefirstNameStyle}
          >
            First Name
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              lastNameStyle === 2 ? 'alert alert-success' : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_last_name"
            name="greenhouse_last_name"
            onClick={changelastNameStyle}
          >
            Last Name
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              primaryEmailStyle === 2
                ? 'alert alert-success'
                : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_primary_email"
            name="greenhouse_primary_email"
            onClick={changeprimaryEmailStyle}
          >
            Primary Email Address
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              extraEmail1Style === 2
                ? 'alert alert-success'
                : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_extra_email_1"
            name="greenhouse_extra_email_1"
            onClick={changeextraEmail1Style}
          >
            Extra Email Address 1
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              extraEmail2Style === 2
                ? 'alert alert-success'
                : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_extra_email_2"
            name="greenhouse_extra_email_2"
            onClick={changeextraEmail2Style}
          >
            Extra Email Address 2
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              phoneNumberStyle === 2
                ? 'alert alert-success'
                : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_phone_number"
            name="greenhouse_phone_number"
            onClick={changephoneNumberStyle}
          >
            Phone Number
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              profileHighlightStyle === 2
                ? 'alert alert-success'
                : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_profile_highlight"
            name="greenhouse_profile_highlight"
            onClick={changeprofileHighlightStyle}
          >
            Profile Highlight{' '}
            <img
              src={HelpIcon}
              alt="help-icon"
              className="greenhouse-fields-help-icon"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              locationStyle === 2 ? 'alert alert-success' : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_location"
            name="greenhouse_location"
            onClick={changelocationStyle}
          >
            Location
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={
              locationCountyStyle === 2
                ? 'alert alert-success'
                : 'alert alert-light'
            }
            role="alert"
            id="greenhouse_location_county"
            name="greenhouse_location_county"
            onClick={changelocationCountyStyle}
          >
            Location County
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GreenhouseAllCandidateFields;
