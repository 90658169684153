import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SignUpButton from '../../shared/button/signUp';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import '../../signUp/css/signUpForm.css';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import useViewModel from '../viewModels/registerUserViewModel';
import { useState } from 'react';
import InvoiceBountyModal from '../../shared/modals/InvoiceBountyModal';

const SignUpFormInput = ({
  id,
  label,
  type = 'text',
  placeholder,
  onChange,
  error,
  touched,
}) => {
  return (
    <section className="signup-form-section">
      <Row className="mb-1">
        <Col md={12}>
          <label
            htmlFor={label}
            className="form_label label_fields signup-form-label text-label"
          >
            {label}
          </label>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={12}>
          <input
            className="form_input input_fields px-1"
            id={id}
            placeholder={' ' + placeholder}
            type={type}
            onChange={onChange}
          ></input>
        </Col>
        {touched && Boolean(error) && (
          <span className="error-message m-1">{error}</span>
        )}
      </Row>
    </section>
  );
};

export default function SignUpForm() {
  const { submitUserRegistration } = useViewModel();
  const [showModal, setShowModal] = useState();
  const [user, setUser] = useState(null);
  const closeModal = () => {
    setShowModal(false);
  };

  const validationSchema = yup.object({
    fullName: yup.string().required('Full Name is required!'),
    title: yup.string().required('Title is required!'),
    companyName: yup.string().required('Company Name is required!'),
    email: yup
      .string()
      .email('Email is not valid!')
      .required('Email is required!'),
  });

  const { handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues: {
      fullName: '',
      title: '',
      companyName: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      submitUserRegistration(values)
        .then((data) => {
          setUser(data);
          setShowModal(false);
        })
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <div className="mx-auto">
      {showModal && (
        <div>
          <InvoiceBountyModal
            show={InvoiceBountyModal}
            closeModal={() => {
              closeModal();
            }}
          />
        </div>
      )}
      <Col xs={12} sm={10}>
        <form onSubmit={handleSubmit}>
          <SignUpFormInput
            id="fullName"
            label={'Full Name'}
            placeholder="Enter your full name"
            onChange={handleChange}
            touched={touched.fullName}
            error={errors.fullName}
          />
          <SignUpFormInput
            id="title"
            label={'Job Title'}
            placeholder="Enter your job title"
            onChange={handleChange}
            touched={touched.title}
            error={errors.title}
          />
          <SignUpFormInput
            id="companyName"
            label={'Company Name'}
            placeholder="Enter your company name"
            onChange={handleChange}
            touched={touched.companyName}
            error={errors.companyName}
          />
          <SignUpFormInput
            id="email"
            label={'Email Address'}
            type={'email'}
            placeholder="Enter your email address"
            onChange={handleChange}
            touched={touched.email}
            error={errors.email}
          />
          <Row className="signup-bottom-section">
            {touched.acceptTerms && Boolean(errors.acceptTerms) && (
              <span className="error-message">{errors.acceptTerms}</span>
            )}
          </Row>
          <Col className=" signup-bottom-float ">
            <SignUpButton />
          </Col>
        </form>
      </Col>
    </div>
  );
}
