import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { capitalize } from 'lodash';
import { toast } from 'react-toastify';

import SnapbrilliaBountiesIcon from '../../assets/icon/snapbrillia_bounties_icon.svg';
import '../../collectionBounties/css/userBounty.css';
import { issueCredentials } from '../../api/bounties';
import { useOnBoarding } from '../../context/onboardingContext';
import Chat from '../../collectionBounties/components/Chat';

const UserJob = ({
  user,
  selected,
  setSelected,
  setUserProfile,
  setApplicantId,
  setJobId,
  setShowProfile,
}) => {
  const { reInviteCandidate } = useOnBoarding();
  const { fullName, assessment, email } = user.candidateId;
  const { status, position, review, rewarded } = user;
  const bounty = user.opportunityId;
  // const [loading, setLoading] = useState(false);
  const [initials] = useState(() => {
    let splitName = fullName == '' ? email : fullName?.split(' ');
    if (!splitName) {
      splitName = fullName == ' ' ? email : fullName?.split(' ');
    }
    return `${splitName[0] ? splitName[0][0] : ''}${
      splitName[1] ? splitName[1][0] : ''
    }`;
  });
  //Reward Candidate state

  const [showChatModal, setShowChart] = useState(false);

  const handleSelected = (e) => {
    e.preventDefault();
    user.initials = initials;
    setSelected(user?._id);
    setUserProfile(user?.candidateId);
    setApplicantId(user?._id), setJobId(user.opportunityId._id);
    e.stopPropagation();
  };

  const onIssueCredentialsClicked = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const assessmentId = e.target.getAttribute('data-assessment');
    if (assessmentId) {
      try {
        let response = await issueCredentials(assessmentId);
        toast(response);
      } catch (error) {
        toast('Issue Credentials Failed');
      }
    }
  };

  const showIssueCredentials = (assessment) => {
    return status === 'completed';
  };

  const handleCloseChatModal = () => {
    setShowChart(false);
  };
  return (
    <Row onClick={handleSelected} className="users-bounties-container pointer">
      <Col
        md={4}
        className={
          selected === user._id
            ? 'users-bounties-main-col-1-selected users-bounties-main-col-1'
            : 'users-bounties-main-col-1'
        }
      >
        <Col md={3}>
          <span className="users-bounties-circle">
            <span>{initials}</span>
          </span>
        </Col>
        <Col onClick={() => setShowProfile(true)}>
          <Row>
            <Col>
              <p className="users-bounties-FullName">
                {fullName === '' || fullName === ' ' ? email : fullName}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="users-bounties-col-2-p">
                <p
                  className="users-bounties-status"
                  style={{ marginRight: '10px' }}
                >
                  {capitalize(status)}
                </p>
                <p>
                  <img
                    src={SnapbrilliaBountiesIcon}
                    alt="bounties-icon"
                    height={12}
                  />
                </p>
                <p style={{ marginLeft: '10px' }}>
                  <b>Individual Contributor</b>
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
      <Col md={2}>
        <p className="grey-title">Submitted:</p>
        {capitalize(status) === 'Submitted' ||
        capitalize(status) === 'Completed' ? (
          <p>True</p>
        ) : (
          <p>False</p>
        )}
      </Col>
      {/* <Col md={2}>
        <p className="grey-title">Active</p>
        <p>True</p>
      </Col> */}
      <Col md={2}>
        <p className="grey-title">Status</p>
        {status}
      </Col>
      {user.status === 'Completed' && (
        <Col md={2}>
          <p className="grey-title">Review</p>
          {user?.review}
        </Col>
      )}
      <Col>
        {status !== 'Completed' && (
          <div className="users-bounties-ide-button">
            <button
              className="users-bounties-launch-ide"
              onClick={(e) => {
                e.preventDefault();
                setShowChart(true);
                e.stopPropagation();
              }}
            >
              Chat
            </button>
          </div>
        )}
        {showIssueCredentials(assessment) && (
          <div className="users-bounties-ide-button">
            <button
              className="users-bounties-launch-ide"
              data-assessment={assessment._id}
              onClick={onIssueCredentialsClicked}
            >
              Issue Credentials
            </button>
          </div>
        )}
      </Col>
      <Col>
        {showChatModal && (
          <Chat
            showModal={showChatModal}
            onClose={handleCloseChatModal}
            user={user.candidateId}
            bounty={bounty}
          />
        )}
      </Col>
    </Row>
  );
};

export default UserJob;
