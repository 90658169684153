const data = {
  allWorkSampleProjectInfo: {
    _id: '620a8d599a9a853e6849139e',
    experience: ['0-2'],
    mandatoryskillsTested: ['C++'],
    optionalskillsTested: [],
    duration: 1,
    daysToComplete: 7,
    type: 'ai',
    candidateId: [
      '620a8d6b9a9a853e684913c4',
      '620a8d929a9a853e68491413',
      '620a8dba9a9a853e6849149f',
      '620a8de89a9a853e6849150b',
      '620a8e099a9a853e6849153c',
      '620a8e1e9a9a853e6849158b',
    ],
    taskId: [
      '61e0518b1b08b71ef453d4ca',
      '61e0518b1b08b71ef453d4e7',
      '61d9a59e2a4c0a58046bfe3c',
      '61d9a59e2a4c0a58046bfe4e',
      '61e0518b1b08b71ef453d4ce',
      '61e0518b1b08b71ef453d4bc',
      '61d9a59e2a4c0a58046bfe48',
      '61e0518b1b08b71ef453d4f2',
      '61d9a59e2a4c0a58046bfe40',
      '61e0518b1b08b71ef453d4df',
      '61e0518b1b08b71ef453d4ea',
      '61d9a59e2a4c0a58046bfe36',
      '61e0518b1b08b71ef453d4ee',
      '61e0518b1b08b71ef453d4e3',
      '61e0518b1b08b71ef453d4f6',
    ],
    deleted: false,
    name: 'Adad',
    jobTitle: 'Ada',
    jobUrl: '',
    jobDescription: '',
    userId: '61c3a17ca775296e40928758',
    companyId: '61c3a17ca775296e40928756',
    createdAt: '2022-02-14T17:11:53.214Z',
    updatedAt: '2022-02-14T17:15:10.431Z',
    __v: 6,
    new: 1,
    invited: 2,
    viewed: 0,
    accepted: 1,
    inProgress: 0,
    completed: 1,
    reviewed: 0,
    bounced: 1,
    expired: 0,
  },
  assessment: {
    _id: '61ca0c17109f0a5ea8a5d302',
    status: 'reviewed',
    workSampleValuationList: [
      '61ca0c17109f0a5ea8a5d2f6',
      '61ca0c17109f0a5ea8a5d2f8',
      '61ca0c17109f0a5ea8a5d2fa',
      '61ca0c17109f0a5ea8a5d2fc',
      '61ca0c17109f0a5ea8a5d2fe',
      '61ca0c17109f0a5ea8a5d300',
    ],
    workSampleScore: 3,
    deleted: false,
    candidateId: '61c3b253d30d155174e0239b',
    workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
    inputCode: ['1', '2', '3', '4'],
    createdAt: '2021-12-27T18:55:19.054Z',
    updatedAt: '2021-12-27T20:16:53.341Z',
    __v: 0,
    invitedBy: 'Shan',
    startedAt: '2021-12-27T18:58:11.360Z',
    completedAt: '2021-12-27T19:29:13.913Z',
    timeTaken: 1,
    viewedEmailSend: '2021-12-27T19:00:01.554Z',
    result: 'thumbs-up',
    reviewedEmailSend: '2021-12-27T20:12:15.003Z',
    workSampleProjectName: 'Adda',
  },
  candidate: {
    emailExpirationTime: null,
    tags: [],
    expertise: [],
    skills: [],
    gender: [''],
    ethnicity: [''],
    age: [''],
    veteranStatus: [''],
    impairmentStatus: [''],
    neurodivergence: [''],
    expiredAt: '2021-12-29T23:18:25.902Z',
    deleted: false,
    _id: '61c3b253d30d155174e0239b',
    firstName: 'Shan Yu',
    lastName: 'Zhang',
    title: '',
    email: 'shanyuzhang123@gmail.com',
    phone: '',
    stage: null,
    linkedin: '',
    github: '',
    education: [
      {
        degree: [''],
        _id: '61ca0cc3109f0a5ea8a5d369',
      },
    ],
    createdAt: '2021-12-22T23:18:43.022Z',
    updatedAt: '2021-12-27T18:58:11.026Z',
    __v: 2,
  },
  candidateList: [
    {
      emailExpirationTime: null,
      tags: [],
      expertise: [],
      skills: [],
      gender: [],
      ethnicity: [],
      age: [],
      veteranStatus: [],
      impairmentStatus: [],
      neurodivergence: [],
      deleted: false,
      _id: '6205985273eb7a1af470dd11',
      firstName: 'Addaad',
      lastName: 'Daad',
      title: '',
      email: 'shanyuzhang123@gmail.com',
      phone: '',
      stage: null,
      linkedin: '',
      github: '',
      education: [],
      createdAt: '2022-02-10T22:57:22.546Z',
      updatedAt: '2022-02-10T22:57:22.546Z',
      __v: 0,
      assessment: {
        status: 'new',
      },
    },
    {
      emailExpirationTime: null,
      tags: [],
      expertise: [],
      skills: [],
      gender: [],
      ethnicity: [],
      age: [],
      veteranStatus: [],
      impairmentStatus: [],
      neurodivergence: [],
      deleted: false,
      _id: '6205985d73eb7a1af470dd29',
      firstName: 'Adadda',
      lastName: 'Daad',
      title: '',
      email: 'szhang12341@gmail.com',
      phone: '',
      stage: null,
      linkedin: '',
      github: '',
      education: [],
      createdAt: '2022-02-10T22:57:33.692Z',
      updatedAt: '2022-02-10T22:57:33.692Z',
      __v: 0,
      assessment: {
        status: 'new',
      },
    },
  ],
  WSProject: {
    experience: ['0-2'],
    mandatoryskillsTested: ['javascript', 'python3', 'c++', 'sqlite'],
    optionalskillsTested: [],
    duration: 60,
    daysToComplete: 7,
    type: 'ai',
    candidateId: ['61c3b253d30d155174e0239b'],
    taskId: [
      '61a969a6b84e154698e72715',
      '61abd68cec606947e4576fa2',
      '61abd68cec606947e4576fa7',
      '61abd68cec606947e4576fb6',
    ],
    deleted: false,
    _id: '61ca0c0d109f0a5ea8a5d2cd',
    name: 'Adda',
    jobTitle: 'Dev Ops',
    jobUrl: '',
    jobDescription: 'daad',
    userId: '61c3a17ca775296e40928758',
    companyId: '61c3a17ca775296e40928756',
    createdAt: '2021-12-27T18:55:09.652Z',
    updatedAt: '2021-12-27T18:55:14.858Z',
    __v: 1,
  },
  user: {
    assessmentId: [],
    candidateIds: [],
    WorkSampleProjectIds: [
      '61c3a1a0a775296e40928778',
      '61ca0c0d109f0a5ea8a5d2cd',
    ],
    _id: '61c3a17ca775296e40928758',
    firstName: 'Shan Yu',
    lastName: 'Zhang',
    title: 'CEO',
    email: 'shanyuzhang123@gmail.com',
    status: 'active',
    admin: false,
    companyRole: 'owner',
    companyId: '61c3a17ca775296e40928756',
    createdAt: '2021-12-22T22:06:52.201Z',
    updatedAt: '2021-12-27T18:55:09.679Z',
    __v: 2,
  },
  valuations: [
    {
      status: 'invited',
      deleted: false,
      _id: '61ca0c17109f0a5ea8a5d2f6',
      workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
      candidateId: '61c3b253d30d155174e0239b',
      taskId: '61a969a6b84e154698e72715',
      createdAt: '2021-12-27T18:55:19.036Z',
      updatedAt: '2021-12-27T18:55:19.036Z',
      __v: 0,
    },
    {
      status: 'invited',
      deleted: false,
      _id: '61ca0c17109f0a5ea8a5d2f8',
      workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
      candidateId: '61c3b253d30d155174e0239b',
      taskId: '61abd68cec606947e4576fa2',
      createdAt: '2021-12-27T18:55:19.039Z',
      updatedAt: '2021-12-27T18:55:19.039Z',
      __v: 0,
    },
    {
      status: 'invited',
      deleted: false,
      _id: '61ca0c17109f0a5ea8a5d2fa',
      workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
      candidateId: '61c3b253d30d155174e0239b',
      taskId: '61abd68cec606947e4576fa7',
      createdAt: '2021-12-27T18:55:19.041Z',
      updatedAt: '2021-12-27T18:55:19.041Z',
      __v: 0,
    },
    {
      status: 'invited',
      deleted: false,
      _id: '61ca0c17109f0a5ea8a5d2fc',
      workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
      candidateId: '61c3b253d30d155174e0239b',
      taskId: '61abd68cec606947e4576fac',
      createdAt: '2021-12-27T18:55:19.043Z',
      updatedAt: '2021-12-27T18:55:19.043Z',
      __v: 0,
    },
    {
      status: 'invited',
      deleted: false,
      _id: '61ca0c17109f0a5ea8a5d2fe',
      workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
      candidateId: '61c3b253d30d155174e0239b',
      taskId: '61abd68cec606947e4576fb1',
      createdAt: '2021-12-27T18:55:19.047Z',
      updatedAt: '2021-12-27T18:55:19.047Z',
      __v: 0,
    },
    {
      status: 'invited',
      deleted: false,
      _id: '61ca0c17109f0a5ea8a5d300',
      workSampleProjectId: '61ca0c0d109f0a5ea8a5d2cd',
      candidateId: '61c3b253d30d155174e0239b',
      taskId: '61abd68cec606947e4576fb6',
      createdAt: '2021-12-27T18:55:19.051Z',
      updatedAt: '2021-12-27T18:55:19.051Z',
      __v: 0,
    },
  ],
  tasks: [
    {
      type: 'codeChallenge',
      scope: 'community',
      difficulty: 1,
      _id: '61a969a6b84e154698e72715',
      expectedDuration: 15,
      skillTested: 'javascript',
      title: 'Double Number',
      funcName: 'doubleNumber',
      problemStatement: '"\\nfunction square(num) {\\n}"',
      instructions: 'Double the input of five and return the output',
      testCases: [
        {
          _id: '61a969a6b84e154698e72716',
          input: 5,
          expectedOutput: 10,
        },
      ],
      __v: 0,
      createdAt: '2021-12-03T00:49:42.463Z',
      updatedAt: '2021-12-03T00:49:42.463Z',
    },
    {
      type: 'codeChallenge',
      scope: 'community',
      difficulty: 2,
      _id: '61abd68cec606947e4576fa2',
      skillTested: 'javascript',
      expectedDuration: 10,
      title: 'Square Number',
      funcName: 'square',
      problemStatement: '"function square(varInOne){\\n}"',
      instructions:
        "Create a variable called \"varInOne\" with the type 'integer' and console log your function, passing 'varInOne' as a parameter.",
      testCases: [
        {
          _id: '61abd68cec606947e4576fa3',
          input:
            '[{"variableInstantiation":"var varInOne = 1;","variableName":"varInOne"}]',
          expectedOutput: '1',
          createdAt: '2021-12-04T20:58:52.281Z',
          updatedAt: '2021-12-04T20:58:52.281Z',
        },
        {
          _id: '61abd68cec606947e4576fa4',
          input:
            '[{"variableInstantiation":"var varInOne = 10;","variableName":"varInOne"}]',
          expectedOutput: '100',
          createdAt: '2021-12-04T20:58:52.281Z',
          updatedAt: '2021-12-04T20:58:52.281Z',
        },
        {
          _id: '61abd68cec606947e4576fa5',
          input:
            '[{"variableInstantiation":"var varInOne = 16;","variableName":"varInOne"}]',
          expectedOutput: '256',
          createdAt: '2021-12-04T20:58:52.281Z',
          updatedAt: '2021-12-04T20:58:52.281Z',
        },
      ],
      createdAt: '2021-12-04T20:58:52.281Z',
      updatedAt: '2021-12-04T20:58:52.281Z',
      __v: 0,
    },
    {
      type: 'codeChallenge',
      scope: 'community',
      difficulty: 1,
      _id: '61abd68cec606947e4576fa7',
      skillTested: 'javascript',
      expectedDuration: 5,
      title: 'Convert Objects To Arrays',
      funcName: 'toArray',
      problemStatement: '"\\nfunction toArray(obj) {\\n\\n}"',
      instructions:
        'Write a function that converts an object into an array, where each element represents a key-value pair in the form of an array. Return an empty array if the object is empty.',
      testCases: [
        {
          _id: '61abd68cec606947e4576fa8',
          input:
            '[{"variableInstantiation":"var varInOne = { a: 1, b: 2 };","variableName":"varInOne"}]',
          expectedOutput: "[ [ 'a', 1 ], [ 'b', 2 ] ]",
          createdAt: '2021-12-04T20:58:52.286Z',
          updatedAt: '2021-12-04T20:58:52.286Z',
        },
        {
          _id: '61abd68cec606947e4576fa9',
          input:
            '[{"variableInstantiation":"var varInOne = { shrimp: 15, tots: 12 };","variableName":"varInOne"}]',
          expectedOutput: "[ [ 'shrimp', 15 ], [ 'tots', 12 ] ]",
          createdAt: '2021-12-04T20:58:52.286Z',
          updatedAt: '2021-12-04T20:58:52.286Z',
        },
        {
          _id: '61abd68cec606947e4576faa',
          input:
            '[{"variableInstantiation":"var varInOne = {};","variableName":"varInOne"}]',
          expectedOutput: '[]',
          createdAt: '2021-12-04T20:58:52.287Z',
          updatedAt: '2021-12-04T20:58:52.287Z',
        },
      ],
      createdAt: '2021-12-04T20:58:52.287Z',
      updatedAt: '2021-12-04T20:58:52.287Z',
      __v: 0,
    },
    {
      type: 'codeChallenge',
      scope: 'community',
      difficulty: 2,
      _id: '61abd68cec606947e4576fac',
      skillTested: 'javascript',
      expectedDuration: 5,
      title: 'Online Shopping',
      funcName: 'freeShipping',
      problemStatement: '"\\nfunction freeShipping(order) {\\n\\n}"',
      instructions:
        'Create a function that determines whether a shopping order is eligible for free shipping. An order is eligible for free shipping if the total cost of items purchased exceeds $50.00.\nIgnore tax or additional fees when calculating the total order cost.',
      testCases: [
        {
          _id: '61abd68cec606947e4576fad',
          input:
            '[{"variableInstantiation":"var varInOne = { \'Shampoo\': 5.99, \'Rubber Ducks\': 15.99 };","variableName":"varInOne"}]',
          expectedOutput: 'false',
          createdAt: '2021-12-04T20:58:52.290Z',
          updatedAt: '2021-12-04T20:58:52.290Z',
        },
        {
          _id: '61abd68cec606947e4576fae',
          input:
            '[{"variableInstantiation":"var varInOne = { \'Flatscreen TV\': 399.99 };","variableName":"varInOne"}]',
          expectedOutput: 'true',
          createdAt: '2021-12-04T20:58:52.290Z',
          updatedAt: '2021-12-04T20:58:52.290Z',
        },
        {
          _id: '61abd68cec606947e4576faf',
          input:
            '[{"variableInstantiation":"var varInOne = { \'Monopoly\': 11.99, \'Secret Hitler\': 35.99, \'Bananagrams\': 13.99 };","variableName":"varInOne"}]',
          expectedOutput: 'true',
          createdAt: '2021-12-04T20:58:52.290Z',
          updatedAt: '2021-12-04T20:58:52.290Z',
        },
      ],
      createdAt: '2021-12-04T20:58:52.290Z',
      updatedAt: '2021-12-04T20:58:52.290Z',
      __v: 0,
    },
    {
      type: 'codeChallenge',
      scope: 'community',
      difficulty: 1,
      _id: '61abd68cec606947e4576fb1',
      skillTested: 'javascript',
      expectedDuration: 5,
      title: 'Special Arrays',
      funcName: 'isSpecialArray',
      problemStatement: '"\\nfunction isSpecialArray(arr) {\\n\\n}"',
      instructions:
        'An array is special if every even index contains an even number and every odd index contains an odd number. Create a function that returns true if an array is special, and false otherwise.',
      testCases: [
        {
          _id: '61abd68cec606947e4576fb2',
          input:
            '[{"variableInstantiation":"var varInOne = [2, 7, 4, 9, 6, 1, 6, 3];","variableName":"varInOne"}]',
          expectedOutput: 'true',
          createdAt: '2021-12-04T20:58:52.293Z',
          updatedAt: '2021-12-04T20:58:52.293Z',
        },
        {
          _id: '61abd68cec606947e4576fb3',
          input:
            '[{"variableInstantiation":"var varInOne =[2, 7, 9, 1, 6, 1, 6, 3];","variableName":"varInOne"}]',
          expectedOutput: 'false',
          createdAt: '2021-12-04T20:58:52.293Z',
          updatedAt: '2021-12-04T20:58:52.293Z',
        },
        {
          _id: '61abd68cec606947e4576fb4',
          input:
            '[{"variableInstantiation":"var varInOne =[2, 7, 8, 8, 6, 1, 6, 3];","variableName":"varInOne"}]',
          expectedOutput: 'false',
          createdAt: '2021-12-04T20:58:52.294Z',
          updatedAt: '2021-12-04T20:58:52.294Z',
        },
      ],
      createdAt: '2021-12-04T20:58:52.294Z',
      updatedAt: '2021-12-04T20:58:52.294Z',
      __v: 0,
    },
    {
      type: 'codeChallenge',
      scope: 'community',
      difficulty: 2,
      _id: '61abd68cec606947e4576fb6',
      skillTested: 'javascript',
      expectedDuration: 5,
      title: 'Sort Two Arrays To An Object',
      funcName: 'arraysToObject',
      problemStatement: '"\\nfunction arraysToObject(arr1, arr2) {\\n\\n}"',
      instructions:
        "Create a function that takes two arrays of numbers as arguments.\\n The first array should be sorted ascending and the second array should be sorted descending. Then create an object with the keys set to the indexes' values from the first array, and the indexes of the second array as values of those keys.\\nfunction\n    example: arraysToObject([3, 9, 7, 1], [1, 6, 5, 8]) => {1: 8, 3: 6, 7: 5, 9: 1}.\\n There are no cases of empty arrays, and both arrays will have the same number of indexes.",
      testCases: [
        {
          _id: '61abd68cec606947e4576fb7',
          input:
            '[{"variableInstantiation":"var varInOne = [3, 9, 7, 1];","variableName":"varInOne"},{"variableInstantiation":"var varInTwo = [1, 6, 5, 8];","variableName":"varInTwo"}]',
          expectedOutput: "{ '1': 8, '3': 6, '7': 5, '9': 1 }",
          createdAt: '2021-12-04T20:58:52.297Z',
          updatedAt: '2021-12-04T20:58:52.297Z',
        },
        {
          _id: '61abd68cec606947e4576fb8',
          input:
            '[{"variableInstantiation":"var varInOne = [13, 9, 17, 6];","variableName":"varInOne"},{"variableInstantiation":"var varInTwo = [12, 3, 15, 4];","variableName":"varInTwo"}]',
          expectedOutput: "{ '6': 15, '9': 12, '13': 4, '17': 3 }",
          createdAt: '2021-12-04T20:58:52.297Z',
          updatedAt: '2021-12-04T20:58:52.297Z',
        },
        {
          _id: '61abd68cec606947e4576fb9',
          input:
            '[{"variableInstantiation":"var varInOne = [22, 200, 75, 0];","variableName":"varInOne"},{"variableInstantiation":"var varInTwo = [89, 67, 85, 59];","variableName":"varInTwo"}]',
          expectedOutput: "{ '0': 89, '22': 85, '75': 67, '200': 59 }",
          createdAt: '2021-12-04T20:58:52.297Z',
          updatedAt: '2021-12-04T20:58:52.297Z',
        },
      ],
      createdAt: '2021-12-04T20:58:52.297Z',
      updatedAt: '2021-12-04T20:58:52.297Z',
      __v: 0,
    },
  ],
  company: {
    userIds: ['61c3a17ca775296e40928758'],
    WorkSampleProjectIds: [
      '61c3a1a0a775296e40928778',
      '61ca0c0d109f0a5ea8a5d2cd',
    ],
    candidateIds: [
      '61c3a1a6a775296e40928792',
      '61c3a440a26f6f25b4b5bae8',
      '61c3a6dd2aef611eec1ca76b',
      '61c3add214456f5044022de0',
      '61c3add914456f5044022df8',
      '61c3b12f5487cc6ec4fa4323',
      '61c3b1355487cc6ec4fa433b',
      '61c3b253d30d155174e0239b',
      '61c3b2b0d30d155174e02418',
      '61c4e3b7d6656c2db032bba6',
    ],
    ownerId: [],
    _id: '61c3a17ca775296e40928756',
    companyName: 'Snapbrilla',
    companySize: '1-1000',
    createdAt: '2021-12-22T22:06:52.087Z',
    updatedAt: '2021-12-27T18:55:09.669Z',
    __v: 13,
  },
};

export default data;
