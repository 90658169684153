import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../shared/css/typography.css';

const CompanyProfileData = ({
  setCompanyInfo,
  companyInfo,
  handleUpdate,
  auth,
}) => {
  const styles = {
    verticalPadding: {
      paddingTop: '1vh',
      paddingBottom: '1vh',
      paddingLeft: '1vw',
      paddingRight: '2vw',
    },
    text: {
      textAlign: 'left',
    },
  };
  const handleChangeOption = (e) => {
    handleUpdate(e);
  };
  const handleChange = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const companySizeOptions = [
    { value: '1-1000', label: '1-1000 Employees' },
    { value: '1001-5000', label: '1001-5000 Employees' },
    { value: '5000+', label: '5000+ Employees' },
  ];
  return (
    <div style={styles.text}>
      <Row>
        <Col>
          <Row style={styles.verticalPadding}>
            <label className="grey-title bold">Company Name</label>
          </Row>
          <Row style={styles.verticalPadding}>
            <label>
              <input
                className="label_fields form_input paragraph"
                type="text"
                name="companyName"
                onChange={handleChange}
                value={companyInfo.companyName}
                disabled={
                  auth.user.companyRole !== 'owner' || !auth.user.isAdmin
                }
                onBlur={handleUpdate}
              />
            </label>
          </Row>
        </Col>
        <Col>
          <Row style={styles.verticalPadding}>
            <label className="grey-title bold">Company Size</label>
          </Row>
          <Row style={styles.verticalPadding}>
            <label>
              <select
                className="label_fields form_input paragraph no-padding"
                onChange={handleChangeOption}
                name="companySize"
                disabled={auth.user.companyRole !== 'owner'}
              >
                {companySizeOptions.map((item, index) => {
                  return (
                    <option
                      value={item.value}
                      key={index}
                      className="paragraph"
                      selected={item.value === companyInfo.companySize}
                    >
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </label>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row style={styles.verticalPadding}>
            <label className="grey-title bold">Company Contact Email</label>
          </Row>
          <Row style={styles.verticalPadding}>
            <label className="paragraph">
              <input
                className={`label_fields form_input paragraph`}
                type="text"
                name="companyEmail"
                onChange={handleChange}
                value={companyInfo.companyEmail}
                onBlur={handleUpdate}
                disabled={auth.user.companyRole !== 'owner'}
              />
            </label>
          </Row>
        </Col>
        <Col>
          <Row style={styles.verticalPadding}>
            <label className="grey-title bold">Company Location</label>
          </Row>
          <Row style={styles.verticalPadding}>
            <label className="paragraph">
              <input
                className="label_fields form_input paragraph"
                type="text"
                name="address"
                onChange={handleChange}
                value={companyInfo.address}
                disabled={auth.user.companyRole !== 'owner'}
                onBlur={handleUpdate}
              />
            </label>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyProfileData;
