import '../../shared/css/typography.css';

const AddProjectFormHeader = () => {
  return (
    <div>
      <h3 className="formHeader">
        Tell us a little bit about the position you are looking to fill.
      </h3>
    </div>
  );
};

export default AddProjectFormHeader;
