import React, { useState, useEffect } from 'react';
import { Link, useParams } from '@reach/router';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import WaitingRoomWelcomeMessage from './components/waitingRoomWelcomeMessage';
import WaitingRoomMessage from './components/waitingRoomMessage';
import WaitingRoomDisclaimer from './components/waitingRoomDisclaimer';
import LoginAsRecruiterButton from '../shared/button/loginAsRecruiter';
import Sendreminderbutton from '../shared/button/sendReminder';
import axios from 'axios';

import SnapbrilliaLogo from '../assets/SnapBetaLogo2.svg';

import './css/waitingRooms.css';

const WaitingRooms = () => {
  const assessmentId = useParams().id;
  // const [assessmentId, setAssessmentId] = useState(address.id);
  const [isCandidate, setIsCandidate] = useState('-1');
  const [recruiter, setRecruiter] = useState('');
  const [position, setPosition] = useState('');
  const [company, setCompany] = useState('');

  const fetchData = async () => {
    const assessment = await axios
      .get(`/api/assessment/${assessmentId}`)
      .then((res) => {
        return res.data;
      });
    const createdBy = await axios
      .get(`/api/user/get-one-by-id/${assessment.createdBy}`)
      .then((res) => {
        return res.data.user;
      });

    const workSampleProject = await axios
      .get(`/api/work-sample-project/one/${assessment.workSampleProjectId}`)
      .then((res) => {
        return res.data;
      });

    const user = await axios.get(`/api/user`).then((res) => {
      return res.data;
    });

    if (assessment.candidateId === user._id) {
      const company = await axios
        .get(`/api/company/${workSampleProject.companyId}`)
        .then((res) => {
          return res.data.companyName;
        });
      setCompany(company);
      setRecruiter(createdBy.firstName + ' ' + createdBy.lastName);
      // setRecruiter(user.fullName); //drop the last line and use this for the new build
      setIsCandidate(true);
    } else {
      setIsCandidate(false);
    }
    setPosition(workSampleProject.jobTitle);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container
      style={{ visibility: isCandidate === '-1' ? 'hidden' : null }}
      fluid
    >
      <Row className="waitingRooms-container">
        <Row>
          <div>
            <img src={SnapbrilliaLogo} alt="snapbrillia-logo" />
          </div>
        </Row>
        <Row className="waitingRooms-welcome">
          <WaitingRoomWelcomeMessage
            isCandidate={isCandidate}
            company={company}
          />
        </Row>
        <Row className="waitingRooms-message">
          <WaitingRoomMessage isCandidate={isCandidate} position={position} />
        </Row>
      </Row>
      <Row className="waitingRooms-bottom">
        <Col className="waitingRooms-disclaimer" xs={12} xl={8}>
          <WaitingRoomDisclaimer
            isCandidate={isCandidate}
            recruiter={recruiter}
          />
        </Col>
        <Col
          style={{ visibility: isCandidate ? 'hidden' : null }}
          xs={6}
          xl={2}
          className="waitingRooms-recruiterLogin"
        >
          <Link to="/signin">
            <LoginAsRecruiterButton />
          </Link>
        </Col>
        <Col xs={6} xl={2} className="waitingRooms-sendReminder">
          <Sendreminderbutton />
        </Col>
      </Row>
    </Container>
  );
};

export default WaitingRooms;
