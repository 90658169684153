import { useState, useEffect } from 'react';
import { anyCandidateList } from './dataReducer';
import { useCallback } from 'react';
import * as ProjectApi from '../../api/projects';
import * as BountyApi from '../../api/bounties';

function DiversityData(
  bountyId,
  WSPId,
  dataType,
  status,
  setData,
  setAxisLabels,
  setAxisTitles,
  setChartTitle
) {
  // list of candidates, which holds booleans for each data type, cleaned up by the anyCandidateList function in dataReducer for diversity analytics
  const [candidateList, setCandidateList] = useState([]);
  // fetch diversity data from backend
  const getDiversityData = useCallback(async () => {
    try {
      // comment out until connected to backend
      let diversityData;
      if (bountyId) {
        if (bountyId === 'all') {
          diversityData = await BountyApi.getAllBountyCandidates({});
        } else {
          diversityData = await BountyApi.getBountyCandidates(bountyId);
        }
      } else {
        diversityData = await ProjectApi.getProjectCandidates(WSPId);
      }
      // temporary data
      // const diversityData = dummyDiversityData;
      if (diversityData) {
        const candidatesFilteredList = anyCandidateList(diversityData, null, [
          'veteran',
          'non-veteran',
          'veteranStatusUnknown',
        ]);
        setCandidateList(candidatesFilteredList);
      }
    } catch (err) {
      console.log(err);
    }
  }, [WSPId, bountyId]);

  // get all data from api calls for the work sample project via WSPId on load and on change
  useEffect(() => {
    getDiversityData();
  }, [getDiversityData]);

  // set state of data based on the dataType prop passed in
  useEffect(() => {
    if (dataType === 'ethnicity' && status === 'all') {
      const nativeAmerican = candidateList.filter(
        (candidate) => candidate['native-american'] === true
      ).length;

      const asian = candidateList.filter(
        (candidate) => candidate['asian'] === true
      ).length;

      const black = candidateList.filter(
        (candidate) => candidate['black'] === true
      ).length;

      const hispanic = candidateList.filter(
        (candidate) => candidate['hispanic-or-latinx'] === true
      ).length;

      const pacific = candidateList.filter(
        (candidate) => candidate['pacific-islander'] === true
      ).length;

      const white = candidateList.filter(
        (candidate) => candidate['white-european'] === true
      ).length;

      const multiRacial = candidateList.filter(
        (candidate) => candidate['multi-racial'] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) => candidate['ethnicityUnknown'] === true
      ).length;

      setData([
        nativeAmerican,
        asian,
        black,
        hispanic,
        pacific,
        white,
        multiRacial,
        unknown,
      ]);
      setAxisLabels([
        'Native American',
        'Asian',
        'Black',
        'Hispanic',
        'Pacific Islander',
        'White',
        'Multi-racial',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Ethnicity']);
      setChartTitle('Ethnicity');
    } else if (dataType === 'ethnicity' && status !== 'all') {
      const nativeAmerican = candidateList.filter(
        (candidate) =>
          candidate['native-american'] === true &&
          candidate[`${status}`] === true
      ).length;

      const asian = candidateList.filter(
        (candidate) =>
          candidate['asian'] === true && candidate[`${status}`] === true
      ).length;

      const black = candidateList.filter(
        (candidate) =>
          candidate['black'] === true && candidate[`${status}`] === true
      ).length;

      const hispanic = candidateList.filter(
        (candidate) =>
          candidate['hispanic-or-latinx'] === true &&
          candidate[`${status}`] === true
      ).length;

      const pacific = candidateList.filter(
        (candidate) =>
          candidate['pacific-islander'] === true &&
          candidate[`${status}`] === true
      ).length;

      const white = candidateList.filter(
        (candidate) =>
          candidate['white-european'] === true &&
          candidate[`${status}`] === true
      ).length;

      const multiRacial = candidateList.filter(
        (candidate) =>
          candidate['multi-racial'] === true && candidate[`${status}`] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) =>
          candidate['ethnicityUnknown'] === true &&
          candidate[`${status}`] === true
      ).length;

      setData([
        nativeAmerican,
        asian,
        black,
        hispanic,
        pacific,
        white,
        multiRacial,
        unknown,
      ]);
      setAxisLabels([
        'Native American',
        'Asian',
        'Black',
        'Hispanic',
        'Pacific Islander',
        'White',
        'Multi-racial',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Ethnicity']);
      setChartTitle('Ethnicity');
    } else if (dataType === 'gender' && status === 'all') {
      const female = candidateList.filter(
        (candidate) => candidate['female'] === true
      ).length;

      const male = candidateList.filter(
        (candidate) => candidate['male'] === true
      ).length;

      const nonBinary = candidateList.filter(
        (candidate) => candidate['non-binary'] === true
      ).length;

      const anotherGender = candidateList.filter(
        (candidate) => candidate['another-gender'] === true
      ).length;

      const genderFluid = candidateList.filter(
        (candidate) => candidate['gender-fluid'] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) => candidate['ethnicityUnknown'] === true
      ).length;

      setData([female, male, nonBinary, anotherGender, genderFluid, unknown]);
      setAxisLabels([
        'Female',
        'Male',
        'Nonbinary',
        'Another Gender',
        'Genderfluid',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Gender']);
      setChartTitle('Gender');
    } else if (dataType === 'gender' && status !== 'all') {
      const female = candidateList.filter(
        (candidate) =>
          candidate['female'] === true && candidate[`${status}`] === true
      ).length;

      const male = candidateList.filter(
        (candidate) =>
          candidate['male'] === true && candidate[`${status}`] === true
      ).length;

      const nonBinary = candidateList.filter(
        (candidate) =>
          candidate['non-binary'] === true && candidate[`${status}`] === true
      ).length;

      const anotherGender = candidateList.filter(
        (candidate) =>
          candidate['another-gender'] === true &&
          candidate[`${status}`] === true
      ).length;

      const genderFluid = candidateList.filter(
        (candidate) =>
          candidate['gender-fluid'] === true && candidate[`${status}`] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) =>
          candidate['ethnicityUnknown'] === true &&
          candidate[`${status}`] === true
      ).length;

      setData([female, male, nonBinary, anotherGender, genderFluid, unknown]);
      setAxisLabels([
        'Female',
        'Male',
        'Nonbinary',
        'Another Gender',
        'Genderfluid',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Gender']);
      setChartTitle('Gender');
    } else if (dataType === 'age' && status === 'all') {
      const eighteen = candidateList.filter(
        (candidate) => candidate['<18'] === true
      ).length;

      const thirtyNine = candidateList.filter(
        (candidate) => candidate['18-39'] === true
      ).length;

      const forty = candidateList.filter(
        (candidate) => candidate['40-54'] === true
      ).length;

      const overFiftyFive = candidateList.filter(
        (candidate) => candidate['55<'] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) => candidate['ageUnknown'] === true
      ).length;

      setData([eighteen, thirtyNine, forty, overFiftyFive, unknown]);
      setAxisLabels([
        '18 and under',
        '18-39',
        '40-54',
        '55 and over',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Age']);
      setChartTitle('Age');
    } else if (dataType === 'age' && status !== 'all') {
      const eighteen = candidateList.filter(
        (candidate) =>
          candidate['<18'] === true && candidate[`${status}`] === true
      ).length;

      const thirtyNine = candidateList.filter(
        (candidate) =>
          candidate['18-39'] === true && candidate[`${status}`] === true
      ).length;

      const forty = candidateList.filter(
        (candidate) =>
          candidate['40-54'] === true && candidate[`${status}`] === true
      ).length;

      const overFiftyFive = candidateList.filter(
        (candidate) =>
          candidate['55<'] === true && candidate[`${status}`] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) =>
          candidate['ageUnknown'] === true && candidate[`${status}`] === true
      ).length;

      setData([eighteen, thirtyNine, forty, overFiftyFive, unknown]);
      setAxisLabels([
        '18 and under',
        '18-39',
        '40-54',
        '55 and over',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Age']);
      setChartTitle('Age');
    } else if (dataType === 'veteran' && status === 'all') {
      const veteran = candidateList.filter(
        (candidate) => candidate.veteran === true
      ).length;

      const nonVeteran = candidateList.filter(
        (candidate) => candidate['non-veteran'] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) => candidate.veteranStatusUnknown === true
      ).length;

      setData([veteran, nonVeteran, unknown]);
      setAxisLabels(['Veteran', 'Non-Veteran', 'Unknown']);
      setAxisTitles(['# of Candidates', 'Veteran']);
      setChartTitle('Veteran');
    } else if (dataType === 'veteran' && status !== 'all') {
      const veteran = candidateList.filter(
        (candidate) =>
          candidate.veteran === true && candidate[`${status}`] === true
      ).length;

      const nonVeteran = candidateList.filter(
        (candidate) =>
          candidate['non-veteran'] === true && candidate[`${status}`] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) =>
          candidate.veteranStatusUnknown === true &&
          candidate[`${status}`] === true
      ).length;

      setData([veteran, nonVeteran, unknown]);
      setAxisLabels(['Veteran', 'Non-Veteran', 'Unknown']);
      setAxisTitles(['# of Candidates', 'Veteran']);
      setChartTitle('Veteran');
    } else if (dataType === 'ability' && status === 'all') {
      const impairedNumber = candidateList.filter(
        (candidate) => candidate['impaired'] === true
      ).length;

      const nonImpairedNumber = candidateList.filter(
        (candidate) => candidate['non-impaired'] === true
      ).length;

      const unknownNumber = candidateList.filter(
        (candidate) => candidate['impairmentStatusUnknown'] === true
      ).length;

      setData([impairedNumber, nonImpairedNumber, unknownNumber]);
      setAxisLabels(['Impaired', 'Non-Impaired', 'Unknown']);
      setAxisTitles(['# of Candidates', 'Ability']);
      setChartTitle('Ability');
    } else if (dataType === 'ability' && status !== 'all') {
      const impairedNumber = candidateList.filter(
        (candidate) =>
          candidate['impaired'] === true && candidate[`${status}`] === true
      ).length;

      const nonImpairedNumber = candidateList.filter(
        (candidate) =>
          candidate['non-impaired'] === true && candidate[`${status}`] === true
      ).length;

      const unknownNumber = candidateList.filter(
        (candidate) =>
          candidate['impairmentStatusUnknown'] === true &&
          candidate[`${status}`] === true
      ).length;

      setData([impairedNumber, nonImpairedNumber, unknownNumber]);
      setAxisLabels(['Impaired', 'Non-Impaired', 'Unknown']);
      setAxisTitles(['# of Candidates', 'Ability']);
      setChartTitle('Ability');
    } else if (dataType === 'degree' && status === 'all') {
      const highSchool = candidateList.filter(
        (candidate) => candidate['high-school'] === true
      ).length;

      const trade = candidateList.filter(
        (candidate) => candidate['trade-certification'] === true
      ).length;

      const bootcamp = candidateList.filter(
        (candidate) => candidate['bootcamp'] === true
      ).length;

      const associate = candidateList.filter(
        (candidate) => candidate['associate'] === true
      ).length;

      const bachelor = candidateList.filter(
        (candidate) => candidate['bachelor'] === true
      ).length;

      const master = candidateList.filter(
        (candidate) => candidate['master'] === true
      ).length;

      const phd = candidateList.filter(
        (candidate) => candidate['phd'] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) => candidate['educationUnknown'] === true
      ).length;

      setData([
        highSchool,
        trade,
        bootcamp,
        associate,
        bachelor,
        master,
        phd,
        unknown,
      ]);
      setAxisLabels([
        'High School',
        'Trade',
        'Bootcamp',
        'Associate',
        'Bachelor',
        'Master',
        'PhD',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Education']);
      setChartTitle('Education');
    } else if (dataType === 'degree' && status !== 'all') {
      const highSchool = candidateList.filter(
        (candidate) =>
          candidate['high-school'] === true && candidate[`${status}`] === true
      ).length;

      const trade = candidateList.filter(
        (candidate) =>
          candidate['trade-certification'] === true &&
          candidate[`${status}`] === true
      ).length;

      const bootcamp = candidateList.filter(
        (candidate) =>
          candidate['bootcamp'] === true && candidate[`${status}`] === true
      ).length;

      const associate = candidateList.filter(
        (candidate) =>
          candidate['associate'] === true && candidate[`${status}`] === true
      ).length;

      const bachelor = candidateList.filter(
        (candidate) =>
          candidate['bachelor'] === true && candidate[`${status}`] === true
      ).length;

      const master = candidateList.filter(
        (candidate) =>
          candidate['master'] === true && candidate[`${status}`] === true
      ).length;

      const phd = candidateList.filter(
        (candidate) =>
          candidate['phd'] === true && candidate[`${status}`] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) =>
          candidate['educationUnknown'] === true &&
          candidate[`${status}`] === true
      ).length;

      setData([
        highSchool,
        trade,
        bootcamp,
        associate,
        bachelor,
        master,
        phd,
        unknown,
      ]);
      setAxisLabels([
        'High School',
        'Trade',
        'Bootcamp',
        'Associate',
        'Bachelor',
        'Master',
        'PhD',
        'Unknown',
      ]);
      setAxisTitles(['# of Candidates', 'Education']);
      setChartTitle('Education');
    } else if (dataType === 'neuro' && status === 'all') {
      const divergent = candidateList.filter(
        (candidate) => candidate['divergent'] === true
      ).length;

      const nonDivergent = candidateList.filter(
        (candidate) => candidate['nonDivergent'] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) => candidate['neurodivergenceUnknown'] === true
      ).length;

      setData([divergent, nonDivergent, unknown]);
      setAxisLabels(['Divergent', 'Non-Divergent', 'Unknown']);
      setAxisTitles(['# of Candidates', 'Neurodivergence']);
      setChartTitle('Neurodivergence');
    } else if (dataType === 'neuro' && status !== 'all') {
      const divergent = candidateList.filter(
        (candidate) =>
          candidate['divergent'] === true && candidate[`${status}`] === true
      ).length;

      const nonDivergent = candidateList.filter(
        (candidate) =>
          candidate['nonDivergent'] === true && candidate[`${status}`] === true
      ).length;

      const unknown = candidateList.filter(
        (candidate) =>
          candidate['neurodivergenceUnknown'] === true &&
          candidate[`${status}`] === true
      ).length;

      setData([divergent, nonDivergent, unknown]);
      setAxisLabels(['Divergent', 'Non-Divergent', 'Unknown']);
      setAxisTitles(['# of Candidates', 'Neurodivergence']);
      setChartTitle('Neurodivergence');
    }
  }, [
    dataType,
    status,
    candidateList,
    setData,
    setAxisLabels,
    setAxisTitles,
    setChartTitle,
  ]);
}

export default DiversityData;
