import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from '@reach/router';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CompaniesContext } from './components/allDashboardBackend';
import '../shared/css/bgColors.css';
import './css/dashboard.css';

//COMPONENTS
import PrimaryTopBar from '../shared/topbar/PrimaryTopBar';
import PrimarySidebar from '../shared/sidebar/primarySidebar';
import ProjectModal from '../projectModal/projectModal';
import { default as SearchBar } from '../searchNavbar/navbar';
import ChartGridAnalytics from '../analyticPage/components/chartGridAnalytics';
// import Assessments from './components/assessments';
import DashboardBounties from './components/dashboardBounties';
import { getEEOCompany } from '../api/opportunities';
import { useAuth } from '../context/authContext';
const Dashboard = () => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [collapsed, setCollapsed] = useState(state ? state.from : false);
  const { auth } = useAuth();
  const handleClose = () => {
    setShow(false);
  };
  const { bounties } = useContext(CompaniesContext);
  useEffect(() => {
    function handleResize() {
      let timer = 0;

      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        document
          .getElementById('main-panel')
          .classList.add('bounties-stop-transitions');
        document
          .getElementById('sidebar')
          .classList.add('bounties-stop-transitions');
      }
      timer = setTimeout(() => {
        document
          .getElementById('main-panel')
          .classList.remove('bounties-stop-transitions');
        document
          .getElementById('sidebar')
          .classList.remove('bounties-stop-transitions');
        timer = null;
      }, 100);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container className="no-overflow" fluid>
      {show && (
        <ProjectModal show={show} handleClose={handleClose} setShow={setShow} />
      )}
      <Row className="d-flex flex-row flex-nowrap vw-100">
        <PrimarySidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          component="dashboard"
        />
        <Col
          id="main-panel"
          xs={12}
          md={10}
          lg={10}
          style={{
            // position: 'relative',
            width: collapsed ? '93vw' : null,
            height: '100vh',
            overflow: 'scroll',
            transition: 'all 0.8s ease-in-out',
            zIndex: 100,
          }}
          className="dashboard-main-panel primary-main-panel pagemainbg"
        >
          <PrimaryTopBar component="dashboard" />
          <Row>
            <h1 className="primary-title h1">Dashboard</h1>
          </Row>
          <div className="bounty_status_bar  d-none d-sm-none d-md-block d-lg-block">
            <SearchBar />
          </div>
          <div className="dashboard-container mx-4">
            <Row>
              {/* <Col md={5} className="pt-5 px-4">
                <Assessments assessments={workSampleProjects} />
              </Col> */}
              <Col md={12} className="pt-3 px-3">
                <DashboardBounties bounties={bounties} />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="px-4">
                <div className="analytics pb-1 mt-5 mb-1">
                  <div className="h2 p-4">Analytics</div>
                  <div className="analytics-graphs inside-analitycs-wrapper mb-3 mx-3">
                    <ChartGridAnalytics
                      showTitle={false}
                      bountyId={auth.user.companyId}
                      urlRoutes={getEEOCompany}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
