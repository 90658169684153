import React from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../shared/css/typography.css';
import '../css/success.css';
import finishedcart from '../../assets/illustrations/snapbrillia_astronaut_illustration.png';
import { convertCurrency } from '../../utils/functions';

export default function Success({
  cart,
  extraAmount,
  walletName,
  paymentFee,
  platformFee,
  payment,
}) {
  return (
    <Container>
      <Modal.Header className="no-border" closeButton>
        <span className="h1">Success!</span>
      </Modal.Header>
      <Modal.Body>
        <Row className="checkout scrollable-success mx-2">
          <Col>
            {cart.map((cartItem, index) => {
              return (
                <div key={index}>
                  <Row className="mb-3 row-border">
                    <Col>
                      <Row className="d-none d-md-block">
                        <Col className="text-end">
                          <span className="grey-labels">Payment method</span>
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col md={8} sm={12} xs={12}>
                          <Row>
                            <Col md={'auto'} xs={'auto'}>
                              <img
                                src={cartItem.logo}
                                className="small-cart-img"
                              />
                            </Col>
                            <Col md={8} xs={8}>
                              <Row>
                                <span className="item-title">
                                  {cartItem.name}
                                </span>
                              </Row>
                              <Row>
                                <span className="grey-labels">
                                  {cartItem.creator}
                                </span>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4} xs={12} className="text-end">
                          <Row className="text-end d-md-none mt-2">
                            <span className="grey-labels">Payment method</span>
                          </Row>
                          <Row>
                            <span className="grey-labels bold text-end">
                              {payment.paymentType === 'ADA'
                                ? walletName
                                : cartItem.payment}
                            </span>
                          </Row>
                          <Row>
                            <span className="text-end">
                              {convertCurrency(
                                (
                                  extraAmount * platformFee +
                                  paymentFee
                                ).toFixed(3),
                                payment.paymentType
                              )}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="h1 image-center">Thank You!</Col>
        </Row>
        <Row>
          <Col className="image-center">
            <span className="contribution">
              Your Contribution Was Successful
            </span>
          </Col>
        </Row>
      </Modal.Body>
      <Row>
        <Col className="image-center">
          <img src={finishedcart} className="img-dim" />
        </Col>
      </Row>
    </Container>
  );
}
