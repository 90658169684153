import { useState } from 'react';
import DiversityData from './diversityData';
import ResponseRateData from './responseRateData';
import DiversityRatingData from './diversityRatingData';
// import DisparityRatingData from './disparityRatingData';
import ReviewRateData from './reviewRateData';

const ChartsBackend = ({ bountyId, WSPId, dataType, dataType2, status }) => {
  const [data, setData] = useState([]);
  const [axisLabels, setAxisLabels] = useState([]);
  const [axisTitles, setAxisTitles] = useState([]);
  const [chartTitle, setChartTitle] = useState();
  if (
    dataType === 'ethnicity' ||
    dataType === 'gender' ||
    dataType === 'degree' ||
    dataType === 'neuro' ||
    dataType === 'ability' ||
    dataType === 'veteran' ||
    dataType === 'age'
  ) {
    DiversityData(
      bountyId,
      WSPId,
      dataType,
      status,
      setData,
      setAxisLabels,
      setAxisTitles,
      setChartTitle
    );
  }

  if (dataType === 'responseRate') {
    ResponseRateData(
      bountyId,
      WSPId,
      dataType,
      setData,
      setAxisLabels,
      setAxisTitles,
      setChartTitle
    );
  }

  if (dataType === 'diversityRating') {
    DiversityRatingData(
      bountyId,
      WSPId,
      dataType,
      dataType2,
      setData,
      setAxisLabels,
      setAxisTitles,
      setChartTitle
    );
  }

  if (dataType === 'reviewRate') {
    ReviewRateData(
      bountyId,
      WSPId,
      dataType,
      setData,
      setAxisLabels,
      setAxisTitles,
      setChartTitle
    );
  }

  // waiting for backend to change from thumbs up/down to a different rating system
  // if (dataType === '') {
  //   DisparityRatingData(
  //     WSPId,
  //     dataType,
  //     status,
  //     setData,
  //     setAxisLabels,
  //     setAxisTitles,
  //     setChartTitle
  //   );
  // }

  return { data, axisLabels, axisTitles, chartTitle };
};

export default ChartsBackend;
