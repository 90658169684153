import { select, scaleBand, scaleOrdinal, pie, arc } from 'd3';
import { chartColors } from './chartColors';
export function PieChartImplementation(
  data,
  width,
  height,
  svg,
  barcolors,
  InnerRadius,
  alignLegend
) {
  const margin = { top: 50, right: 50, bottom: 50, left: 50 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const innerRadius = InnerRadius;
  const outerRadius = Math.min(innerWidth, innerHeight) / 2;

  // Compute values.
  // Compute values.
  const N = scaleBand().domain(data.map((d) => d.values));
  const I = data.map((d) => d.statistics);
  let totalItem = 0;
  for (let i = 0; i < data.length; i++) {
    totalItem = totalItem + I[i];
  }
  // Construct scales.
  const color = scaleOrdinal(barcolors);

  const defs = svg.append('defs');
  const filter = defs
    .append('filter')
    .attr('id', 'drop-shadow')
    .attr('height', '130%');
  filter
    .append('feGaussianBlur')
    .attr('in', 'SourceAlpha')
    .attr('stdDeviation', 3)
    .attr('result', 'blur');

  filter
    .append('feOffset')
    .attr('in', 'blur')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offsetBlur');
  const feMerge = filter.append('feMerge');

  feMerge.append('feMergeNode').attr('in', 'offsetBlur');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

  // Construct arcs.
  const piee = pie();
  const g = svg.append('g');
  if (alignLegend === 'right') {
    g.attr('transform', 'translate(' + width / 3 + ',' + height / 2.5 + ')');
  } else if (alignLegend === 'left') {
    g.attr('transform', 'translate(' + width / 1.5 + ',' + height / 2.5 + ')');
  }

  // Generate the arcs
  const arcc = arc().innerRadius(innerRadius).outerRadius(outerRadius);
  //Generate groups
  const arcs = g
    .selectAll('arcc')
    .data(piee(I))
    .enter()
    .append('g')
    .attr('class', 'arc')
    .attr('id', function (d, i) {
      return i;
    });

  arcs
    .append('path')
    .attr('fill', function (d, i) {
      return color(i);
    })
    .attr('d', arcc)
    .on('mouseover', function (d, i) {
      select(this)
        .attr('stroke', '#D4CAA3')
        .attr('stroke-width', '2px')
        .style('filter', 'url(#drop-shadow)');
      select(this)
        .transition()
        .duration(200)
        .attr('transform', function (d) {
          const dist = 2;
          d.midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle;
          const x = Math.sin(d.midAngle) * dist;
          const y = Math.cos(d.midAngle) * dist;
          return 'translate(' + x + ',' + y + ')';
        });

      let arcIndex = 0;
      const color = select(this).attr('fill');
      for (let i = 0; i < barcolors.length; i++) {
        if (color === barcolors[i]) {
          arcIndex = i;
        }
      }

      tooltip
        .html(`${N.domain()[arcIndex]}: ${i.value}`)
        .style('visibility', 'visible');
    })
    .on('mousemove', function () {
      tooltip
        // eslint-disable-next-line no-restricted-globals
        .style('top', event.pageY - 10 + 'px')
        // eslint-disable-next-line no-restricted-globals
        .style('left', event.pageX + 10 + 'px');
    })
    .on('mouseout', function () {
      tooltip.html(``).style('visibility', 'hidden');
      select(this).attr('stroke', 'none').style('filter', 'none');

      select(this)
        .transition()
        .duration(200)
        .attr('transform', 'translate(0,0)');
    });

  const legend = svg.append('g');
  const colors = chartColors;
  if (alignLegend === 'right') {
    // render legend colors
    legend
      .selectAll(null)
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 5)
      .attr('cx', innerWidth + 90) // x position of the circle
      .attr('cy', (d, i) => {
        return innerHeight / 2.25 - 5 + i * 18;
      }) // y position of the circle, leaving 18px of space between each
      .attr('fill', (d, i) => {
        return colors[i];
      });

    // render legend text
    legend
      .selectAll(null)
      .data(data)
      .enter()
      .append('text')
      .text((d) => {
        return d.values;
      })
      .attr('x', innerWidth + 80) // x position of the axis label text
      .attr('y', (d, i) => {
        return innerHeight / 2.25 + i * 18;
      }) // y position of the axis label text, leaving 18px of space between each
      .style('font-family', 'sans-serif')
      .style('font-size', `12px`)
      .style('text-anchor', 'end');
  } else if (alignLegend === 'left') {
    // render legend colors
    legend
      .selectAll(null)
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 5)
      .attr('cx', innerWidth * 0.05) // x position of the circle
      .attr('cy', (d, i) => {
        return innerHeight / 2.25 - 5 + i * 18;
      }) // y position of the circle, leaving 20px of space between each
      .attr('fill', (d, i) => {
        return colors[i];
      });

    // render legend text
    legend
      .selectAll(null)
      .data(data)
      .enter()
      .append('text')
      .text((d) => {
        return d.values;
      })
      .attr('x', innerWidth * 0.1) // x position of the axis label text
      .attr('y', (d, i) => {
        return innerHeight / 2.25 + i * 18;
      }) // y position of the axis label text, leaving 20px of space between each
      .style('font-family', 'sans-serif')
      .style('font-size', `12px`);
  }

  const tooltip = select('body')
    .append('div')
    .attr('x', innerWidth / 2)
    .attr('y', margin.top / 2)
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('color', ' black')
    .style('padding', '10px')
    .style('border', '1px solid #808080')
    .style('background', 'white')
    .style('border-radius', '5px');
}
