import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
const BarChart = ({ bounties }) => {
  const filteredBounties = bounties.filter((item) => item.type === 'Gig');
  const filteredJobs = bounties.filter((item) => item.type === 'Job');

  const assessmentGig = filteredBounties.map(
    (item) => item.assessmentIds.length
  );
  const assessmentJob = filteredJobs.map((item) => item.assessmentIds.length);

  const getItems = (condition) => {
    return bounties.filter(
      (item) => item.type === condition.type && item.status === condition.status
    );
  };
  const reviewJobAssessment = () => {
    const reviewJobAssessmentFilter = filteredJobs.map((item) =>
      item.assessmentIds.map((ass) => {
        return ass.review;
      })
    );
    let accepted = 0;
    let rejected = 0;
    for (let index = 0; index < reviewJobAssessmentFilter.length; index++) {
      const element = reviewJobAssessmentFilter[index];
      let elementOccurrences = _.countBy(element);
      // console.log('elementOccurrences Job', elementOccurrences);
      if (_.isNumber(elementOccurrences.Accepted)) {
        accepted += elementOccurrences.Accepted;
      }
      if (_.isNumber(elementOccurrences.Rejected)) {
        rejected += elementOccurrences.Rejected;
      }
    }
    return {
      accepted: accepted,
      rejected: rejected,
    };
  };
  const reviewGigAssessment = () => {
    const reviewGigAssessmentFilter = filteredBounties.map((item) =>
      item.assessmentIds.map((ass) => {
        return ass.review;
      })
    );
    let accepted = 0;
    let rejected = 0;
    // console.log('reviewGigAssessmentFilter', reviewGigAssessmentFilter);
    for (let index = 0; index < reviewGigAssessmentFilter.length; index++) {
      const element = reviewGigAssessmentFilter[index];
      let elementOccurrences = _.countBy(element);
      // console.log('elementOccurrences Gig', elementOccurrences);
      if (_.isNumber(elementOccurrences.Accepted)) {
        accepted += elementOccurrences.Accepted;
      }
      if (_.isNumber(elementOccurrences.Rejected)) {
        rejected += elementOccurrences.Rejected;
      }
    }
    return {
      accepted: accepted,
      rejected: rejected,
    };
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'User Activity Chart',
      },
    },
  };

  const labels = [
    'Created',
    'Open',
    'Completed',
    'Applicants',
    'Accepted',
    'Rejected',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Gigs',
        data: [
          filteredBounties?.length,
          getItems({ type: 'Gig', status: 'Open' }).length,
          getItems({ type: 'Gig', status: 'Completed' }).length,
          assessmentGig.reduce((a, b) => a + b, 0),
          reviewGigAssessment().accepted,
          reviewGigAssessment().rejected,
        ],
        backgroundColor: 'rgb(190, 37, 188)',
      },
      {
        label: 'Jobs',
        data: [
          filteredJobs?.length,
          getItems({ type: 'Job', status: 'Open' }).length,
          getItems({ type: 'Job', status: 'Completed' }).length,
          assessmentJob.reduce((a, b) => a + b, 0),
          reviewJobAssessment().accepted,
          reviewJobAssessment().rejected,
        ],
        backgroundColor: 'rgb(21, 7, 67)',
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
