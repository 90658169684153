import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import SnapbrilliaDropdownIcon from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import { capitalize } from 'lodash';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import '../css/memberFields.css';

const AddMemberFields = ({
  setMemberFields,
  memberFields,
  formErrors,
  person,
}) => {
  const ref = useRef();
  const [select, setSelect] = useState(false);

  const handleSelectEvent = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setSelect(false);
    }
  };

  // const handleBlind = () => {
  //   setMemberFields({ ...memberFields, blind: !memberFields.blind });
  // };

  const handleFullName = (e) => {
    setMemberFields({ ...memberFields, fullName: e.target.value });
  };

  const handleRole = (e) => {
    setMemberFields({ ...memberFields, role: e.target.id });
  };

  const handleEmail = (e) => {
    setMemberFields({ ...memberFields, email: e.target.value });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleSelectEvent);

    return () => {
      document.removeEventListener('mousedown', handleSelectEvent);
    };
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} md={8}>
          <label className="form_label label_fields">
            Full Name {person && `(Person ${person})`}
          </label>
          <input
            className="input_fields form_input px-2 member-fields-input-bg"
            type="text"
            id="full-name"
            value={memberFields.fullName}
            onChange={(e) => handleFullName(e)}
          />
          <p className="member-fields-error-text">{formErrors?.fullName}</p>
        </Col>
        <Col xs={12} md={4} className="member-fields-select-column">
          <label className="form_label label_fields">Role</label>
          <div className="member-fields-dropdown">
            <div
              onBlur={handleSelectEvent}
              onClick={() => setSelect(!select)}
              ref={ref}
              className={
                select
                  ? 'member-fields-dropdown-select member-fields-no-border'
                  : 'member-fields-dropdown-select'
              }
            >
              <span className="member-fields-dropdown-option">
                {capitalize(memberFields?.role)}
              </span>
              {select && (
                <div className="member-fields-select-list">
                  <div
                    onClick={(e) => handleRole(e)}
                    className="member-fields-select-item"
                    id="member"
                  >
                    Member
                  </div>
                  {/* <div
                    onClick={(e) => handleRole(e)}
                    className="member-fields-select-item"
                    id="interviewer"
                  >
                    Interviewer
                  </div> */}
                </div>
              )}
              <div className="dropdown-down-arrow">
                {!select && (
                  <img
                    src={SnapbrilliaDropdownIcon}
                    alt="SnapbrilliaDropdownIcon"
                  />
                )}
              </div>
              <div className="dropdown-up-arrow">
                {select && (
                  <img
                    src={SnapbrilliaDropdownIcon}
                    alt="SnapbrilliaDropdownIcon"
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <label className="form_label label_fields">Email</label>
          <input
            className="form_input input_fields px-2 member-fields-input-bg"
            type="text"
            value={memberFields?.email}
            onChange={(e) => handleEmail(e)}
          />
          <p className="member-fields-error-text">{formErrors?.email}</p>
        </Col>
        {/*<Col>
          <label className="form_label label_fields ">Blind</label>
          <div onClick={handleBlind} className="member-fields-switch">
            <span
              className={
                memberFields?.blind
                  ? 'member-fields-switch-on'
                  : 'member-fields-switch-off'
              }
            >
              On
            </span>
            <span
              className={
                memberFields?.blind
                  ? 'member-fields-switch-off'
                  : 'member-fields-switch-on'
              }
            >
              Off
            </span>
          </div>
            </Col>*/}
      </Row>
    </>
  );
};

export default AddMemberFields;
