import Container from 'react-bootstrap/Container';
import GreenHouseIcon from '../../assets/integrations/greenhouse_integration_logo.svg';
import '../../shared/css/sharedStyles.css';
export default function IntegrationsCard() {
  return (
    <Container className="greenhouse-card-container mx-4">
      <div className="greenhouse-left-container">
        <img src={GreenHouseIcon} alt="greenhouse-icon" />
        <div className="integration_description_text">
          Get Top Talent With A Powerful Sourcing Strategy. Greenhouse Helps You
          Be Great At Hiring. Improve ROI for New Hires. Achieve Hiring Goals.
          Conduct Better Interviews. 100+ Partner Integrations. Impress
          Candidates. Maintain Company Culture.
        </div>
        <span className="integration_description_text">
          Status: <b>Not Connected</b>
        </span>
      </div>
      <span className="connect-button">Connect</span>
    </Container>
  );
}
