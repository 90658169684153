export function correctlyCap(skill) {
  let formatedSkill;
  if (skill === 'javascript') {
    formatedSkill = 'JavaScript';
  } else if (skill === 'python3') {
    formatedSkill = 'Python';
  } else if (skill === 'sqlite') {
    formatedSkill = 'SQLite';
  } else {
    formatedSkill = skill[0].toUpperCase() + skill.slice(1);
  }
  return formatedSkill;
}

export const timeAgo = function (time) {
  var date = new Date(time);
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + ' Years Ago';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' Months Ago';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' Days Ago';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' Hours Ago';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' Minutes Ago';
  }
  return Math.floor(seconds) + ' Seconds Ago';
};

export function formatDate(date) {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  if (day < 10) {
    return `${month}/0${day}/${year}`;
  } else {
    return `${month}/${day}/${year}`;
  }
}

export function convertDifficulty(difficulty) {
  let diff;
  if (difficulty === 1) {
    diff = 'Easy';
  } else if (difficulty === 2) {
    diff = 'Medium';
  } else if (difficulty === 3) {
    diff = 'Hard';
  } else {
    diff = 'Jedi';
  }
  return diff;
}

export function changeTaskColor(difficulty) {
  let color;
  if (difficulty === 1) {
    color = '43CD69';
  } else if (difficulty === 2) {
    color = 'FAA81A';
  } else {
    color = 'CD4343';
  }
  return color;
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = [];
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j;
      } else {
        if (j > 0) {
          var newValue = costs[j - 1];
          // eslint-disable-next-line max-depth
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue;
    }
  }
  return costs[s2.length];
}

export function similarity(s1, s2) {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

export const validateMemberFields = (values) => {
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!values.fullName) {
    errors.fullName = 'Full Name is required!';
  }
  if (!values.role) {
    errors.role = 'Role is required!';
  }
  if (!values.email) {
    errors.email = 'Email is required!';
  } else if (!regex.test(values.email)) {
    errors.email = 'This is not a valid email format!';
  }
  return errors;
};

export const validatePersonalInfoFields = (values) => {
  if (values.phoneNumber > 999999999999999 || values.phoneNumber < 99999999) {
    throw new Error('Invalid Phone Number Length');
  }
};

export const validateCandidateFields = (values) => {
  const errors = {};
  /* eslint-disable no-useless-escape */
  const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]+)?$/;
  const linkedin =
    /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm;
  const github =
    /^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([A-Za-z0-9]{1,})+\/?$/i;
  const stackOverflow =
    /((https?:\/\/)?((www|\w\w)\.)?stackoverflow\.com\/users\/)([0-9]{1,})+\/([a-zA-Z0-9_-]{1,})/g;
  if (!values.fullName) {
    errors.fullName = 'Full Name is required!';
  }
  if (!values.email) {
    errors.email = 'Email is required!';
  } else if (!regex.test(values.email)) {
    errors.email = 'This is not a valid email format!';
  }
  if (values.linkedin && !linkedin.test(values.linkedin)) {
    errors.linkedin = 'This is not a valid LinkedIn url!';
  }
  if (values.github && !github.test(values.github)) {
    errors.github = 'This is not a valid Github url!';
  }
  if (values.stackOverflow && !stackOverflow.test(values.stackOverflow)) {
    errors.stackOverflow = 'This is not a valid StackOverflow url!';
  }
  return errors;
};

export function convertCurrency(amount, currency) {
  let result = amount;
  if (amount > 1) {
    result = Number(parseFloat(amount).toFixed(3)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  switch (currency) {
    case 'USD':
      result = `$ ${result}`;
      break;
    case 'ETH':
      result = `${result} ETH`;
      break;
    case 'LINK':
      result = `${result} LINK`;
      break;
    case 'USDT':
      result = `${result} USDT`;
      break;
    case 'MATIC':
      result = `${result} MATIC`;
      break;
    case '₳DA':
      result = `${result} ₳DA`;
      break;
    default:
      result = `0.00`;
  }

  return result;
}

export const textCapitalizeCase = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
