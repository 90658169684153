import React, { useEffect, useState } from 'react';
import '../css/chatModal.css';
import BountyLogo from '../../assets/snapbrillia_no_company_logo.png';
import { useAuth } from '../../context/authContext';
import _ from 'lodash';
import moment from 'moment';
import { getChats, getLatestChats, sendChat } from '../../api/chat';
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'react-bootstrap';

const ChatBody = ({ messages }) => {
  return (
    <div className="chatbox__row chatbox__row_fullheight">
      <div className="chatbox__content">
        {messages.length > 0 && (
          <div className="chatbox__content">
            {messages.map((value, index) => (
              <div key={value._id + index}>
                <div className="message client">
                  <div className="message__head">
                    <span className="message__note">{value.sender}</span>
                  </div>
                  <div className="message__base-right">
                    <div className="message__avatar avatar">
                      <a href="#" className="avatar__wrap">
                        <img
                          className="avatar__img"
                          src={BountyLogo}
                          width="35"
                          height="35"
                          alt="avatar"
                        />
                      </a>
                    </div>
                    <div className="message__textbox">
                      <span className="message__text">{value.message}</span>
                      <p className="date">
                        {moment(value.createdAt).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
const ChatModal = ({ showModal, onClose, user, bounty }) => {
  const [message, setMessage] = useState('');
  const { auth } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [interval, setTimeInterval] = useState(null);

  const inflateChat = () => {
    const result = {
      fromId: auth.user._id,
      from: auth.user.fullName,
      body: message,
      toId: user._id,
      to: user.fullName,
      bountyId: bounty._id,
      bountyName: bounty.name,
      attachments: [],
    };
    return result;
  };
  const getLastRead = () => {
    let result = Date.now();
    if (messages.length > 0) {
      const last = messages[messages.length - 1];
      if (!_.isNil(last)) {
        result = new Date(last.createdAt).getTime();
      }
    }
    return result;
  };
  const fetchNewChats = () => {
    const lastRead = getLastRead();
    getLatestChats(bounty._id, user._id, lastRead).then((chats) => {
      if (_.isArray(chats) && !_.isEmpty(chats)) {
        setMessages(() => [...messages, chats]);
      }
    });
  };
  //UI Control events
  const onCloseChat = () => {
    if (!_.isNil(interval)) {
      clearInterval(interval);
    }
    setTimeInterval(null);
    onClose();
  };
  const onSendMessage = (e) => {
    e.preventDefault();
    sendChat(inflateChat()).then((chat) => {
      setMessages(() => [...messages, chat]);
      setMessage('');
    });
    e.stopPropagation();
  };

  useEffect(() => {
    if (!loaded) {
      getChats(bounty._id, user._id).then((chats) => {
        setMessages(chats);
        if (_.isNil(interval)) {
          setTimeInterval(setInterval(fetchNewChats, 10000));
        }
        setLoaded(true);
      });
    }
  });

  return (
    <Modal
      show={showModal}
      onHide={onCloseChat}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton>
        <h5>{user.fullName}</h5>
      </ModalHeader>
      <ModalBody>
        <ChatBody messages={messages} />
      </ModalBody>
      <ModalFooter>
        <Container>
          <div className="chatbox__row">
            <div className="enter">
              <div className="enter__submit">
                <button
                  onClick={onSendMessage}
                  className="button button_id_submit"
                  type="submit"
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
              <div className="enter__textarea">
                <input
                  type="text"
                  name="enterMessage"
                  value={message}
                  id="enterMessage"
                  cols="30"
                  rows="5"
                  placeholder="Say something..."
                  onChange={(e) => {
                    e.stopPropagation();
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </ModalFooter>
    </Modal>
  );
};

export default ChatModal;
