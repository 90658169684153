// keep the plus sign in this file when the columns are mapped and created
// remove the selection process and the rendered pie chart svg to another component
// call this grid with the values pre-set in this file
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/chartGridAnalytics.css';
import PieChartGrid from './pieChartGrid';
import { exportComponentAsJPEG } from 'react-component-export-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ChartGridAnalytics = ({
  rowsToDisplay = 1,
  rowsHeightVH,
  showTitle,
  bountyId,
  urlRoutes,
}) => {
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    generateCols();
  }, [bountyId]);

  if (window.innerWidth <= 991) {
    rowsToDisplay = 1;
  }

  const generateCols = () => {
    let cols = [];

    for (let i = 0; i < rowsToDisplay; i++) {
      let currentCol = [];

      for (let j = 0; j < 3; j++) {
        let border =
          rowsToDisplay === 1 && j === 0
            ? 'top-left bottom-left'
            : rowsToDisplay === 1 && j === 2
            ? 'top-right bottom-right'
            : i === 0 && j === 0
            ? 'top-left'
            : i === 0 && j === 2
            ? 'top-right'
            : i === rowsToDisplay - 1 && j === 0
            ? 'bottom-left'
            : i === rowsToDisplay - 1 && j === 2
            ? 'bottom-right'
            : '';
        let col = {
          index: Math.random(),
          className: `analitycs-grid-col ${border}`,
          hoverClassName: `analitycs-grid-col hover ${border} `,
          showSelectComponent: false,
          fixed: false,
        };
        currentCol.push(col);
      }
      cols.push(currentCol);
    }
    setColumns(cols);
  };

  const nodeRef = useRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [11.5, 8],
  };
  const exportPDF = () => {
    html2canvas(nodeRef.current, options).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF(options);
      let ratio = canvas.width / canvas.height;
      let width = pdf.internal.pageSize.getWidth() * 0.825;
      let height = width / ratio;
      pdf.addImage(imgData, 'PNG', 1, 0, width, height);
      pdf.save('projectImpairmentStatus.pdf');
    });
  };

  return (
    <Container fluid className={'p-0'}>
      {showTitle === 'true' || showTitle === true ? (
        <Row className=" my-md-3 align-items-center ">
          <Col xs={12} md={2} xxl={2}>
            <span className="bold fs-5 d-block m-xxl-0 primary">
              Export as:
            </span>
          </Col>
          <Col xs={6} md={4} xl={4} xxl={3}>
            <span className="analityc-export-formats" onClick={exportPDF}>
              PDF
            </span>
          </Col>
          <Col xs={6} md={4} xl={4} xxl={3}>
            <span
              className="analityc-export-formats"
              onClick={() => exportComponentAsJPEG(nodeRef)}
            >
              JPG
            </span>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <div
        className={`${rowsToDisplay !== 1 && 'inside-analitycs-wrapper '}`}
        ref={nodeRef}
      >
        {columns.map((arr, index) => {
          return (
            <Row className="analitycs-row" key={index}>
              {arr.map((column, index) => {
                return (
                  <PieChartGrid
                    bountyId={bountyId}
                    key={index}
                    open={open}
                    setOpen={setOpen}
                    column={column}
                    columns={columns}
                    setColumns={setColumns}
                    rowsHeightVH={rowsHeightVH}
                    urlRoutes={urlRoutes}
                  />
                );
              })}
            </Row>
          );
        })}
      </div>
    </Container>
  );
};

export default ChartGridAnalytics;
