import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import '../../shared/css/buttonStyles.css';
import '../../shared/css/typography.css';
import './css/greenhouseMappingFields.css';

import GreenhouseAllCandidateFields from './components/greenhouseAllCandidateFields';
import GreenhouseResume from './components/greenhouseResume';
import GreenhouseMappingFields from './greenhouseMappingField';

export default function GreenhouseDataMapping() {
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => setShowModal(false);

  const [fullNameStyle, setfullNameStyle] = useState(1);
  const [firstNameStyle, setfirstNameStyle] = useState(1);
  const [lastNameStyle, setlastNameStyle] = useState(1);
  const [primaryEmailStyle, setprimaryEmailStyle] = useState(1);
  const [extraEmail1Style, setextraEmail1Style] = useState(1);
  const [extraEmail2Style, setextraEmail2Style] = useState(1);
  const [phoneNumberStyle, setphoneNumberStyle] = useState(1);
  const [profileHighlightStyle, setprofileHighlightStyle] = useState(1);
  const [locationStyle, setlocationStyle] = useState(1);
  const [locationCountyStyle, setlocationCountyStyle] = useState(1);

  return (
    <Modal
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      scrollable={true}
      show={showModal}
    >
      <Modal.Header className="no-border" closeButton />
      <Modal.Body>
        <Container className="close-bar">
          <Row className="bold">
            <Col>Data Field Mapping</Col>
          </Row>
          <Row className="row-padding">
            <Col>
              Customize data fields to be synced from Snapbrillia to Greenhouse
              Harvest.
            </Col>
          </Row>
          <Row className="grey-background bold row-padding">
            <Col className="grey-row-padding">
              Snapbrillia Candidate Profile
            </Col>
          </Row>
          <Row>
            <Col>All Fields in Snapbrillia</Col>
            <Col>Field Mapping from Snapbrillia to Greenhouse Harvest</Col>
          </Row>
          <Row>
            <Col>
              <GreenhouseAllCandidateFields
                fullNameStyle={fullNameStyle}
                setfullNameStyle={setfullNameStyle}
                firstNameStyle={firstNameStyle}
                setfirstNameStyle={setfirstNameStyle}
                lastNameStyle={lastNameStyle}
                setlastNameStyle={setlastNameStyle}
                primaryEmailStyle={primaryEmailStyle}
                setprimaryEmailStyle={setprimaryEmailStyle}
                extraEmail1Style={extraEmail1Style}
                setextraEmail1Style={setextraEmail1Style}
                extraEmail2Style={extraEmail2Style}
                setextraEmail2Style={setextraEmail2Style}
                phoneNumberStyle={phoneNumberStyle}
                setphoneNumberStyle={setphoneNumberStyle}
                profileHighlightStyle={profileHighlightStyle}
                setprofileHighlightStyle={setprofileHighlightStyle}
                locationStyle={locationStyle}
                setlocationStyle={setlocationStyle}
                locationCountyStyle={locationCountyStyle}
                setlocationCountyStyle={setlocationCountyStyle}
              />
            </Col>
            <Col>
              <GreenhouseMappingFields
                fullNameStyle={fullNameStyle}
                firstNameStyle={firstNameStyle}
                lastNameStyle={lastNameStyle}
                primaryEmailStyle={primaryEmailStyle}
                extraEmail1Style={extraEmail1Style}
                extraEmail2Style={extraEmail2Style}
                phoneNumberStyle={phoneNumberStyle}
                profileHighlightStyle={profileHighlightStyle}
                locationStyle={locationStyle}
                locationCountStyle={locationCountyStyle}
              />
            </Col>
          </Row>
          <Row>
            <GreenhouseResume />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="no-border" closeButton />
    </Modal>
  );
}
