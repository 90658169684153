import Container from 'react-bootstrap/Container';
import { Form, Row } from 'react-bootstrap';
import Header from './header';
import AssessmentAddGitBucket from './assessmentAddGitBucket';
import DropDowns from './dropDowns';
export default function AddAssessmentTypeSelection({
  setShowModal,
  handleChange,
  url,
  setUrl,
  setRepoInfo,
  WSPFields,
}) {
  return (
    <Container>
      <Header
        message={
          "Now it's time for the best part! Pick your assessment type below."
        }
        setShowModal={setShowModal}
      />
      <Form.Group className="add-assessment-group">
        <input
          type="radio"
          name="type"
          id="AI"
          value="ai"
          onChange={handleChange}
          className="add-assessment-radio"
          defaultChecked
        />
        <label htmlFor="AI" className="add-assessment-label">
          AI Generated Challenges
        </label>
      </Form.Group>
      <p className="assessment-ai-desc">
        Let us pick you from an infinite(yes!) number of assessments. We&apos;ll
        auto generate the coding challenges with unique tasks based on the
        mandatory and optional skills you require.
      </p>
      {WSPFields.type === 'ai' && (
        <AssessmentAddGitBucket
          setUrl={setUrl}
          url={url}
          setRepoInfo={setRepoInfo}
        />
      )}

      <Form.Group className="add-assessment-group">
        <input
          type="radio"
          name="type"
          id="manual"
          value="custom"
          onChange={handleChange}
          className="add-assessment-radio"
        />
        <label htmlFor="manual" className="add-assessment-label">
          Manually Add Your Challenges
        </label>
      </Form.Group>
      <p className="assessment-ai-desc">
        Add or create your own coding challenges with tasks related to the
        mandatory and optional skills you require. this will take you to an IDE
        to input code, tasks, and answers.{' '}
        <span style={{ display: 'block' }}>
          You can also share these coding challenges.
        </span>
      </p>
      {WSPFields.type !== 'ai' && (
        <>
          {/* <label className="select-input-label">Github url</label> */}
          {/* <input
            className="assessment-type-input px-2"
            type="text"
            name="url"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            required
          /> */}
          <AssessmentAddGitBucket
            setUrl={setUrl}
            url={url}
            setRepoInfo={setRepoInfo}
          />
        </>
      )}
      <Row className="pt-5">
        <DropDowns handleChange={handleChange} WSPFields={WSPFields} />
      </Row>
    </Container>
  );
}
