import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SelectMenu from '../../shared/formItems/selectMenu';
import '../css/assessmentTypeSelect.css';
import AssessmentAddGitBucket from './assessmentAddGitBucket';

const AssessmentTypeSelect = ({
  assessmentType,
  setAssessmentType,
  url,
  setUrl,
  setRepoInfo,
  repoInfo,
}) => {
  const list = [
    { item: 'AI Generated', key: 1 },
    { item: 'Manual', key: 2 },
  ];
  useEffect(() => {
    setAssessmentType(list[0].item);
  }, []);
  return (
    <Container className="assessment-type-container">
      <Row className="assessment-type-select">
        <Col xs={5} md={4}>
          <label className="assessment-git-bucket-label">Assessment Type</label>
          <div className="assessment-type-label mb-2">
            <SelectMenu
              value={assessmentType}
              setValue={setAssessmentType}
              list={list}
            />
          </div>
        </Col>
        <Col xs={12} md={8} className="">
          {assessmentType === 'AI Generated' && (
            <AssessmentAddGitBucket
              {...{ url, setUrl, setRepoInfo, repoInfo }}
            />
          )}
          {assessmentType === 'Manual' && (
            <AssessmentAddGitBucket
              {...{ url, setUrl, setRepoInfo, repoInfo }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(AssessmentTypeSelect);
