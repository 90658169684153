import '../css/sharedStyles.css';
import './css/integrationSideMenuStyles.css';
import CopyRight from '../../shared/copyright/copyright';

export default function IntegrationSideMenu({ component, setComponent }) {
  return (
    <div className="menulabel profile-menu-bar pt-4">
      <div
        className={
          component === 0
            ? 'menu_item_selected profile_menu_general'
            : 'profile_menu_general'
        }
        onClick={() => setComponent(0)}
      >
        {/* <Link to="/all-integrations"> */}
        <span className={component === 0 ? 'px-0 bold' : 'px-0'}>All</span>
        {/* </Link> */}
      </div>
      <div
        className={
          component === 1
            ? 'menu_item_selected profile_menu_general'
            : 'profile_menu_general'
        }
        onClick={() => setComponent(1)}
      >
        {/* <Link to="/ats-integrations"> */}
        <span className={component === 1 ? 'px-0 bold' : 'px-0'}>ATS</span>
        {/* </Link> */}
      </div>
      <div
        className={
          component === 2
            ? 'menu_item_selected profile_menu_general'
            : 'profile_menu_general'
        }
        onClick={() => setComponent(2)}
      >
        <span className={component === 2 ? 'px-0 bold' : 'px-0'}>
          CRM<small>(Coming Soon)</small>
        </span>
      </div>
      <div
        className={
          component === 3
            ? 'menu_item_selected profile_menu_general'
            : 'profile_menu_general'
        }
        onClick={() => setComponent(3)}
      >
        <span className={component === 3 ? 'px-0 bold' : 'px-0'}>
          Scheduling<small>(Coming Soon)</small>
        </span>
      </div>
      <div className="profile_copyright">
        <CopyRight />
      </div>
    </div>
  );
}
