export default [
  {
    name: 'Tether',
    symbol: 'USDT',
    contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
  {
    name: 'Chainlink',
    symbol: 'LINK',
    contract: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  },
  {
    name: 'Polygon',
    symbol: 'MATIC',
    contract: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  },
];
