import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Invite from '../../shared/button/invite';
import MergeCompany from '../../shared/button/mergeCompany';
import CloseIcon from '../../assets/icon/snapbrillia_close_icon.svg';
import * as memberAPI from '../../api/members';
import { toast } from 'react-toastify';

const AddMemberModal = ({ companyId, openAddMember, setOpenAddMember }) => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [role, setRole] = useState('member');
  const handleClose = () => setOpenAddMember(false);

  const handleSubmit = async () => {
    if (!email || !fullName || !role) {
      toast.error('Invalid data!');
      return handleClose();
    }
    const response = await memberAPI.inviteMember(companyId, [
      {
        email,
        fullName,
        role,
      },
    ]);
    if (!response.invitedMember) {
      toast.error(
        'Member is invited or belong to others company. Please try other!'
      );
      return handleClose();
    }
    toast(response.message);
    handleClose();
  };
  const mergeCompany = async () => {
    if (!email) {
      toast.error('Invalid data!');
      return handleClose();
    }
    const response = await memberAPI.mergeCompany(companyId, email.trim());
    toast(response.message);
    handleClose();
  };
  return (
    <Modal
      contentClassName="modal-main-container"
      onHide={handleClose}
      backdrop="static"
      show={openAddMember}
      centered
    >
      <Container>
        <Row>
          <Row className="p-0">
            <Col>
              <Modal.Header className="member-modal-header">
                <h2 className="h2">Add Team Member</h2>
              </Modal.Header>
            </Col>
            <Col
              xs={1}
              className="pe-0 d-flex align-items-center justify-content-center"
            >
              <img
                src={CloseIcon}
                alt="close-icon"
                width={20}
                onClick={() => handleClose()}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          </Row>
          <form className="pt-0 pb-0">
            <Row>
              <Col xs={12} md={8}>
                <label className="form_label label_fields">Full Name</label>
                <input
                  className="input_fields form_input px-2 member-fields-input-bg"
                  type="text"
                  id="full-name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Col>
              <Col xs={12} md={4} className="member-fields-select-column">
                <label className="form_label label_fields">Role</label>
                <select
                  onChange={(e) => setRole(e.target.value)}
                  className="member-fields-dropdown-select"
                  name="role"
                  value={role}
                >
                  <option value={'member'}>Member</option>
                  <option value={'interviewer'}>Interviewer</option>
                </select>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <label className="form_label label_fields">Email</label>
                <input
                  className="form_input input_fields px-2 member-fields-input-bg"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={9}>
                <MergeCompany
                  type="button"
                  onClick={mergeCompany}
                  className="member-admin-button"
                />
              </Col>
              <Col xs={3}>
                <Invite
                  className="member-modal-button"
                  handleClick={handleSubmit}
                />
              </Col>
            </Row>
          </form>
        </Row>
      </Container>
    </Modal>
  );
};

export default AddMemberModal;
