import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable, useRowState, useSortBy } from 'react-table';
import { Scrollbars } from 'react-custom-scrollbars';
import * as memberAPI from '../../api/members';
import { toast } from 'react-toastify';

import SortUpSVG from '../../assets/icon/snapbrillia_sort_up_selected.svg';
import SortDownSVG from '../../assets/icon/snapbrillia_sort_down_selected.svg';
import UnselectedSVG from '../../assets/icon/snapbrillia_sort_unselected.svg';
import '../css/companyProfileMemberTable.css';
import '../../shared/css/sharedStyles.css';

const CompanyProfileMemberTable = ({
  companyId,
  members,
  setEditMember,
  handleShowModal,
}) => {
  const [counter, setCounter] = useState(0);
  const [curCol, setCurCol] = useState();
  const [dropdownSort, setDropdownSort] = useState('Alphabetical');
  const [dataState, setDataState] = useState([]);
  const handleSelectMember = (member) => {
    setEditMember(member);
    handleShowModal();
  };
  const handleReInvite = async (e) => {
    const response = await memberAPI.inviteMember(companyId, [e], true);
    toast(response.message);
  };

  useEffect(() => {
    if (members.length === 0) {
      return;
    }
    const tableData = members.map((e) => ({
      col0: (
        <div>
          {e.fullName}
          <div className="positions">
            {e.companyRole}{' '}
            {e.status ? (
              ``
            ) : (
              <div className="d-inline-block">
                <span className="badge bg-warning text-dark">Pending</span>
                <button
                  className="responsive-primary-btn btn-reinvite"
                  onClick={() => handleReInvite(e)}
                >
                  Re-Invite
                </button>
              </div>
            )}
          </div>
          <div className="positions">{e.email}</div>
        </div>
      ),

      col1: e.WorkSampleProjectIds?.length || 0,
      col2: e.candidateIds?.length || 0,
      col3: e.status || 'pending',
      col4: (
        <div
          className="edit_label pointer"
          onClick={() => handleSelectMember(e)}
        >
          Edit
        </div>
      ),
    }));
    setDataState(tableData);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);
  const data = useMemo(() => [...dataState], [dataState]);
  /* const sortFn = useMemo((a, b) => {
    return -1;
    // return a < b ? -1 : 1;
  }, []); */
  const sortFn = useCallback(
    (a, b) => {
      return a.original.col0.props.children[0].toUpperCase() <
        b.original.col0.props.children[0].toUpperCase()
        ? -1
        : 1;
    },
    [dataState]
  );

  // const fetchMoreData = () => {
  //   setTimeout(() => {
  //     //setDataState([dataState, ...dataState]);
  //     // setDataState(dataState.concat(dataState.slice(0, 6)));
  //     // setItems(items.concat(makeData(2)));
  //   }, 500);
  // };

  const counterTrigger = (column, setSortBy) => {
    if (column.Header === curCol) {
      if ((counter + 1) % 3 === 0) {
        column.isSorted = false;
        column.isSortedDesc = undefined;
        setCounter(0);

        setSortBy([{ id: 'col0', desc: dropdownSort === 'Alphabetical(Z-A)' }]);
      } else {
        column.isSorted = true;
        column.isSortedDesc = counter === 0;
        setSortBy([{ id: column.id, desc: counter === 0 }]);
        setCounter(counter + 1);
      }
    } else {
      column.isSorted = true;
      column.isSortedDesc = true;
      setCurCol(column.Header);
      setCounter(1);
      setSortBy([{ id: column.id, desc: true }]);
    }
  };

  const onSorting = (e, column) => {
    // let x = column.getSortByToggleProps();
    if (e.target.value === 'Alphabetical') {
      setDropdownSort('Alphabetical');
      setCurCol();
      column.isSorted = true;
      column.isSortedDesc = false;
      // column.toggleSortBy(column.isSortedDesc);
    } else {
      setDropdownSort('Alphabetical(Z-A)');
      setCurCol();
      column.isSorted = true;
      column.isSortedDesc = true;
    }

    // setDataState(data);
    column.toggleSortBy(column.isSortedDesc);
  };

  const columns = useMemo(
    () => [
      {
        Header: '',

        accessor: 'col0', // accessor is the "key" in the data
        sortType: sortFn,
      },
      {
        Header: 'Projects Created',
        accessor: 'col1',
      },
      {
        Header: 'Number of Invitations',
        accessor: 'col2',
      },
      {
        Header: 'Status',
        accessor: 'col3',
      },
      {
        Header: '',
        accessor: 'col4',
      },
      {
        Header: '',
        accessor: 'col5',
      },
    ],
    []
  );

  const {
    getTableProps,
    // getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setSortBy,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'col0', desc: false }] },
      autoResetSortBy: false,
    },
    useSortBy,
    useRowState
  );

  return (
    <div className="company-table-container">
      <Scrollbars
        className="gx-layout-sider-scrollbar"
        autoHeight
        autoHeightMax={'50vh'}
        autoHideDuration={1500}
        renderThumbVertical={() => <div style={{ display: 'none' }} />}
        renderThumbHorizontal={(props) => (
          <div {...props} className="bounty-table-thumb-horizontal" />
        )}
        style={{
          width: '100%',
          position: 'relative',
          marginRight: '17px',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <table
          className="pb-2"
          style={{
            borderCollapse: 'separate',
            borderSpacing: 0,
            width: '96%',
          }}
          {...getTableProps()}
        >
          <thead
            className="bg-white position-sticky top-0"
            style={{ zIndex: '999' }}
          >
            <tr {...headerGroups[0].getHeaderGroupProps()}>
              {headerGroups[0].headers.map((column, index) => {
                if (index === 0) {
                  return (
                    <th
                      key={index}
                      className="sort_label border-bottom border-dark"
                      {...column.getHeaderProps()}
                    >
                      <div className="sort_label">
                        <div className="">Sort</div>

                        <select
                          className="select_box"
                          /* onChange={() =>
                            column.toggleSortBy(!column.isSortedDesc)
                          } */
                          onChange={(e) => onSorting(e, column)}
                        >
                          <option className="dropdown_text">
                            Alphabetical
                          </option>
                          <option className="dropdown_text">
                            Alphabetical(Z-A)
                          </option>
                        </select>
                      </div>
                    </th>
                  );
                }
                return (
                  <th
                    key={index}
                    {...column.getHeaderProps()}
                    className="border-bottom border-dark bounty-table-th-middle"
                    style={{
                      textAlign: 'center',
                      color: '#2d2b6f',
                      fontWeight: '500',
                      verticalAlign: 'bottom',
                      cursor: 'pointer',
                    }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    <span>
                      {column.Header === curCol ? (
                        counter === 1 ? (
                          <span className="sort">
                            <img src={SortDownSVG} alt="sort-down-icon" />
                          </span>
                        ) : counter === 2 ? (
                          <span className="sort">
                            <img src={SortUpSVG} alt="sort-up-icon" />
                          </span>
                        ) : (
                          <span className="sort">
                            <img src={UnselectedSVG} alt="unselected-icon" />
                          </span>
                        )
                      ) : (
                        <span className="sort">
                          <img src={UnselectedSVG} alt="unselected-icon" />
                        </span>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        style={{
                          textAlign: i === 0 ? '' : 'center',
                          fontWeight: '500',
                          verticalAlign: 'middle',
                          borderBottom: 'groove 2px #ececec',
                          padding: '1rem',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Scrollbars>
    </div>
  );
};

export default CompanyProfileMemberTable;
