import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AddMemberFields from './components/addMemberFields';
import Invite from '../shared/button/invite';
import '../shared/css/typography.css';
import './css/teamMemberModal.css';
import '../shared/css/buttonStyles.css';
import CloseIcon from '../assets/icon/snapbrillia_close_icon.svg';
import * as companyAPI from '../api/companies';

import { MemberModal } from './components/memberModalAPI';
import { toast } from 'react-toastify';

// Props: openAddTeamMember
const AddTeamMemberModal = ({ setOpenAddTeamMember }) => {
  const {
    setMemberFields,
    memberFields,
    formErrors,
    submitForm,
    handleFormSubmit,
    inviteMember,
    companyId,
  } = useContext(MemberModal);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setOpenAddTeamMember(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const emailAlreadyExist = await companyAPI.checkIsMemberInvite(
        memberFields.email,
        companyId
      );
      if (emailAlreadyExist.exist) {
        setLoading(false);
        return toast.error('User already invited!');
      }
      const isInvited = await inviteMember();
      if (isInvited) {
        handleClose();
      }
    } catch (error) {}

    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitForm) {
      handleSubmit();
    }
  }, [formErrors]);

  return (
    <Modal
      contentClassName="modal-main-container"
      onHide={handleClose}
      backdrop="static"
      show={true}
      centered
    >
      <Container>
        <Row>
          <Row className="p-0">
            <Col>
              <Modal.Header className="member-modal-header">
                <h2 className="h2">Add Team Member</h2>
              </Modal.Header>
            </Col>
            <Col
              xs={1}
              className="pe-0 d-flex align-items-center justify-content-center"
            >
              <img
                src={CloseIcon}
                alt="close-icon"
                width={20}
                onClick={() => handleClose()}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          </Row>
          <form onSubmit={handleFormSubmit} className="pt-0 pb-0">
            <AddMemberFields
              {...{
                setMemberFields,
                memberFields,
                formErrors,
              }}
            />

            <Row>
              <Col xs={9}></Col>
              <Col xs={3}>
                <Invite
                  className={`member-modal-button ${
                    memberFields.email === '' ? 'btn-disabled' : ''
                  }`}
                  handleClick={handleSubmit}
                  isLoading={loading}
                />
              </Col>
            </Row>
          </form>
        </Row>
      </Container>
    </Modal>
  );
};

export default AddTeamMemberModal;
