import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
// import DropDownIcon from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import SelectMenu from '../../shared/formItems/selectMenu';

export default function DropDowns({ handleChange, WSPFields }) {
  const [difficulty, setDifficulty] = useState('Medium');
  const [daysToComplete, setDaysToComplete] = useState('2 Weeks');
  const handleChangeDifficult = async (value) => {
    handleChange({
      target: { name: 'difficulty', value: value },
    });
    await options[1].map(async (item) => {
      if (item.key === value) {
        setDifficulty(item.item);
      }
    });
  };
  const handleChangeDaysToComplete = async (value) => {
    handleChange({
      target: { name: 'daysToComplete', value: value },
    });
    await options[0].map(async (item) => {
      if (item.key === value) {
        setDaysToComplete(item.item);
      }
    });
  };
  const init = async () => {
    if (WSPFields.difficulty) {
      await options[1].map(async (item) => {
        if (item.key === WSPFields.difficulty) {
          setDifficulty(item.item);
        }
      });
    }
    if (WSPFields.daysToComplete) {
      await options[0].map(async (item) => {
        if (item.key === WSPFields.daysToComplete) {
          setDaysToComplete(item.item);
        }
      });
    }
  };
  useEffect(() => {
    init();
  }, []);

  const options = [
    [
      { key: '14', item: '2 Weeks' },
      { key: '21', item: '3 Weeks' },
      { key: '30', item: '4 Weeks' },
    ],
    [
      { key: 'easy', item: 'Easy' },
      { key: 'medium', item: 'Medium' },
      { key: 'difficult', item: 'Difficult' },
    ],
  ];
  return (
    <>
      <Form.Group className="assessment-selects">
        <div style={{ flex: 1, position: 'relative' }}>
          <label className="select-input-label">Expiration</label>

          <SelectMenu
            value={daysToComplete}
            setKey={handleChangeDaysToComplete}
            list={options[0]}
          />
        </div>

        <div style={{ flex: 1, position: 'relative' }}>
          <label className="select-input-label">Difficulty</label>
          <SelectMenu
            value={difficulty}
            setKey={handleChangeDifficult}
            list={options[1]}
          />
        </div>
      </Form.Group>
    </>
  );
}
