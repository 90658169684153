/* eslint-disable no-unused-vars */
import axios from 'axios';

// verify coupon code
export async function verifyCoupon(coupon) {
  const response = await axios.get(`/api/v1/coupons/${coupon}`);
  return response.data;
}

export async function createCoupon(data) {
  const response = await axios.post(`/api/v1/coupons`, data);
  return response.data;
}

export async function editCouponForm(id, data) {
  const response = await axios.put(`/api/v1/coupons/${id}`, data);
  return response.data;
}

export async function getAllCoupon() {
  const response = await axios.get(`/api/v1/coupons`);
  return response.data;
}

export async function CompanyRequestCoupon() {
  const response = await axios.post(`/api/v1/coupons/request-coupon`);
  return response.data;
}

export async function getCompanyCoupon(id) {
  const response = await axios.get(`/api/v1/coupons/${id}/company`);
  return response.data;
}
