import React, { useEffect, useState } from 'react';
import '../css/chat.css';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../context/authContext';
import _ from 'lodash';
import moment from 'moment';
import {
  getChats,
  getLatestChats,
  sendChat,
  editChat,
  upload,
} from '../../api/chat';
import { useRef } from 'react';
import VideoRecorderModal from './videoRecordModal';
import ChatUserFooter from './ChatUserFooter';
import ChatUserMessage from './ChatUserMessage';
import RecordScreenModal from './recordScreenModal';

const Chat = ({ showModal, onClose, user, bounty }) => {
  const [message, setMessage] = useState('');
  const { auth } = useAuth();
  const [mini, setMini] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [interval, setTimeInterval] = useState(null);
  const [reply, setReply] = useState(false);
  const [replyId, setReplyId] = useState('');
  const [replyTo, setReplyTo] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState('');
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [showScreenRecorder, setShowScreenRecorder] = useState(false);

  const messagesEndRef = useRef(null);
  const [image, setImage] = useState('');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  var nowDate = new Date();
  var date =
    nowDate.getFullYear() +
    '/' +
    (nowDate.getMonth() + 1) +
    '/' +
    nowDate.getDate();

  const inflateChat = () => {
    const result = {
      senderId: auth.user._id,
      sender: auth.user.fullName,
      message: message,
      recepientId: user.candidateId._id,
      recepient: user.candidateId.fullName,
      // replyTo: replyTo[0]?.message || '',
      opportunityId: bounty._id,
      bountyName: bounty.title,
      attachments: [],
      group_date: date,
      chatReply: reply,
      chatReplyMessage: replyTo[0]?.message || '',
      chatReplyRecepient: replyId || '',
      isEdited: edit,
    };
    return result;
  };
  const inflateVideoChat = (videoLocation) => {
    const result = {
      senderId: auth.user._id,
      sender: auth.user.fullName,
      message: videoLocation,
      recepientId: user.candidateId._id,
      recepient: user.candidateId.fullName,
      replyTo: replyTo[0]?.message || '',
      opportunityId: bounty._id,
      bountyName: bounty.title,
      type: 'video',
      attachments: [],
      group_date: date,
      chatReply: reply,
      chatReplyMessage: replyTo[0]?.message || '',
      chatReplyRecepient: replyId || '',
      isEdited: edit,
    };
    return result;
  };
  const getLastRead = () => {
    let result = Date.now();
    if (messages.length > 0) {
      const last = messages[messages.length - 1];
      if (!_.isNil(last)) {
        result = new Date(last.createdAt).getTime();
      }
    }
    return result;
  };
  const fetchNewChats = async () => {
    const lastRead = getLastRead();
    await getChats(bounty._id, user.candidateId._id).then((chats) => {
      if (_.isArray(chats) && !_.isEmpty(chats)) {
        setMessages(chats);
      }
    });
  };
  //UI Control events
  const onCloseChat = () => {
    if (!_.isNil(interval)) {
      clearInterval(interval);
    }
    setTimeInterval(null);
    onClose();
  };
  const onSendMessage = async (e) => {
    e.preventDefault();
    await sendChat(inflateChat()).then((chat) => {
      setMessages([...messages, chat]);
      setMessage('');
    });
    e.stopPropagation();
    setReply(false);
    setReplyTo([]);
  };
  const onEditMessage = (e) => {
    e.preventDefault();
    editChat(inflateChat(), editId).then((chat) => {
      const edited = messages.filter((msg) => msg._id !== editId);
      setMessages([chat, ...edited]);
      setMessage('');
    });
    e.stopPropagation();
    setEdit(false);
    setEditId('');
  };

  const closeReply = () => {
    setReply(false);
    setReplyTo([]);
    setReplyId('');
  };

  useEffect(() => {
    const getMsg = async () => {
      if (!loaded) {
        await getChats(bounty._id, user.candidateId._id).then((chats) => {
          setMessages(chats);
          if (_.isNil(interval)) {
            setTimeInterval(setInterval(fetchNewChats, 10000));
          }
          setLoaded(true);
        });
      }
    };
    getMsg();
  });

  useEffect(() => {
    scrollToBottom();
  }, [messages, message]);

  function groupItems(array, property) {
    if (array.length === 0) {
      return [];
    } else {
      return array?.reduce(function (groups, item) {
        var name = item[property];
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
      }, {});
    }
  }
  const groupChat = [];
  const groups = groupItems(messages, 'group_date');
  for (var key in groups) {
    var group = groups[key];
    groupChat[key] = group;
  }

  const replyMsg = (id) => {
    const msg = messages.filter((message) => message._id === id);
    setReplyTo(msg);
  };
  const onRecodingFinished = (blob) => {
    const form = new FormData();
    form.append('file', blob, 'recording.webm');
    upload(form).then((uploaded) => {
      sendChat(inflateVideoChat(uploaded)).then((chat) => {
        setMessages(() => [...messages, chat]);
      });
    });
  };
  function msgEllipsis(
    str,
    maxLength,
    { side = 'end', ellipsis = '...' } = {}
  ) {
    if (str.length > maxLength) {
      switch (side) {
        case 'start':
          return ellipsis + str.slice(-(maxLength - ellipsis.length));
        case 'end':
        default:
          return str.slice(0, maxLength - ellipsis.length) + ellipsis;
      }
    }
    return str;
  }

  const momentDate = (dateGiven) => {
    let date = moment(dateGiven);
    if (moment().diff(date, 'days') >= 2) {
      return date.fromNow(); // '2 days ago' etc.
    }
    return date.calendar().split(' ')[0];
  };
  return (
    <Modal
      show={showModal}
      onHide={onCloseChat}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="new-user">
        <section
          className="chatbox-popup"
          style={{
            height: groupChat.length < 0 ? 300 : mini ? 300 : 550,
          }}
        >
          <header className="chatbox-popup__header">
            <aside
              style={{
                flex: 3,
              }}
            >
              <i
                className="fa fa-user-circle fa-4x chatbox-popup__avatar"
                aria-hidden="true"
              ></i>
            </aside>
            <aside
              style={{
                flex: 10,
              }}
            >
              <h1>{user.fullName}</h1>Contractor for oppotunity ({' '}
              {bounty.name || bounty.title} )
            </aside>
            <aside
              style={{
                flex: 1,
              }}
            >
              <button
                className="chatbox-maximize"
                onClick={() => setMini(!mini)}
              >
                <i
                  className={
                    mini ? 'fa fa-window-restore' : 'fa fa-window-maximize'
                  }
                  aria-hidden="true"
                ></i>
              </button>
            </aside>
            <aside
              style={{
                flex: 1,
              }}
              className="chatbox-close"
              onClick={() => onClose()}
            >
              <i className="fa fa-close" aria-hidden="true"></i>
            </aside>
          </header>
          <main className="chatbox-popup__main">
            <p
              style={{
                textAlign: 'center',
              }}
            >
              We make it simple and seamless for
              <br /> bussiness and people to talk to each
              <br /> other. Chat with your contractor.
            </p>

            {groupChat.length > 0 && (
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Start a new conversation with {user.fullName}
              </p>
            )}
            <div className="msg-body">
              <ul>
                {Object.entries(groupChat).map(([k, v], index) => (
                  <div key={index}>
                    <li key={index}>
                      {momentDate(k) === 'Today' ? (
                        <div className="dividers">
                          <h6>{momentDate(k)}</h6>
                        </div>
                      ) : momentDate(k) === 'Yesterday' ? (
                        <div className="dividers">
                          <h6>{momentDate(k)}</h6>
                        </div>
                      ) : (
                        <div className="dividers">
                          <h6>{moment(k).format('DD MMMM YYYY')}</h6>
                        </div>
                      )}
                    </li>
                    {v.map((msg) =>
                      msg.chatReplyRecepient === 'You' ? (
                        <ChatUserMessage
                          key={msg._id}
                          type="repaly"
                          edit={edit}
                          editId={editId}
                          msg={msg}
                          setReplyId={setReplyId}
                          replyMsg={replyMsg}
                          msgEllipsis={msgEllipsis}
                          setMessage={setMessage}
                          message={message}
                          onEditMessage={onEditMessage}
                          setReply={setReply}
                          image={image}
                        />
                      ) : (
                        <ChatUserMessage
                          key={msg._id}
                          type="sender"
                          edit={edit}
                          editId={editId}
                          msg={msg}
                          setReplyId={setReplyId}
                          setEdit={setEdit}
                          setEditId={setEditId}
                          replyMsg={replyMsg}
                          msgEllipsis={msgEllipsis}
                          setMessage={setMessage}
                          message={message}
                          onEditMessage={onEditMessage}
                          setReply={setReply}
                          image={image}
                        />
                      )
                    )}
                  </div>
                ))}
              </ul>
            </div>
          </main>
          <ChatUserFooter
            onSendMessage={onSendMessage}
            replyTo={replyTo}
            closeReply={closeReply}
            message={message}
            reply={reply}
            setMessage={setMessage}
            msgEllipsis={msgEllipsis}
            setShowVideoRecorder={setShowVideoRecorder}
          />
        </section>
        {showVideoRecorder && (
          <VideoRecorderModal
            showModal={showVideoRecorder}
            onStop={onRecodingFinished}
            onCloseRecorder={() => setShowVideoRecorder(false)}
          ></VideoRecorderModal>
        )}
        {showScreenRecorder && (
          <RecordScreenModal
            showModal={showScreenRecorder}
            onClose={() => setShowScreenRecorder(false)}
            onStop={onRecodingFinished}
          ></RecordScreenModal>
        )}
      </div>
    </Modal>
  );
};

export default Chat;
