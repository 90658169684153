import { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/projectPages.css';
import { ProjectsContext } from './components/allProjectsBackend';
import { useLocation } from '@reach/router';

//COMPONENTS
import EditProjectLink from './components/editProjectLink';
import AddTeamMemberLink from './components/addTeamMemberLink';
import NavBar from '../searchNavbar/navbar';
import EditModal from '../projectModal/editProjectModal';
import AddcandidateModal from '../candidateModal/components/addCandidateModal';
import AddTeamMemberModal from '../memberModal/addTeamMemberModal';
import ListTeamMemberModal from '../memberModal/listTeamMemberModal';
import ProjectDetailsHeader from './components/projectDetailHeader';
import ProjectTitle from './components/projectTitles';
import TabsView from './components/tabsView';
import MemberModalAPI from '../memberModal/components/memberModalAPI';
import PrimarySidebar from '../shared/sidebar/primarySidebar';
import AddCandidateButton from '../shared/button/addCandidate';

const ProjectPage = () => {
  const {
    workSampleProject,
    tasks,
    candidates,
    setCandidates,
    sendReviewCandidate,
    handleDeleteProject,
    handleEditProject,
    handleAddCandidate,
    auth,
    allMembers,
    setAllMembers,
    interviewers,
    handleAddCandidates,
  } = useContext(ProjectsContext);
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(state ? state.from : false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddTeamMember, setOpenAddTeamMember] = useState(false);
  const [openListTeamMember, setOpenListTeamMember] = useState(false);
  const [showAddCandidate, setShowAddCandidate] = useState(false);
  // const [showSideBar, setShowSideBar] = useState(false);

  // const handleClose = () => setShowSideBar(false);
  const listMembers = () => {
    setOpenListTeamMember(true);
  };
  return (
    <Container fluid style={{ overflow: 'hidden' }}>
      <Row className="d-flex flex-row flex-nowrap vw-100">
        <PrimarySidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          component="projects"
        />
        <Col
          md={12}
          lg={10}
          xl={9}
          style={{
            transition: 'all 0.8s ease-in-out',
            zIndex: 100,
          }}
          className="bounties_main_panel primary-main-panel flex-grow-1 pagemainbg"
        >
          <Row className="py-4 ms-5">
            <Col md={3} lg={3} xxl={3}>
              <ProjectTitle workSampleProject={workSampleProject} />
            </Col>
            <Col md={1} lg={1} xxl={1}>
              <EditProjectLink
                setOpenEditModal={setOpenEditModal}
                className="edit-project-text"
              />
              {openEditModal && (
                <EditModal
                  openEditModal={openEditModal}
                  setOpenEditModal={setOpenEditModal}
                  workSampleProject={workSampleProject}
                  handleDeleteProject={handleDeleteProject}
                  handleEditProject={handleEditProject}
                />
              )}
            </Col>
            <Col md={3} lg={3} xxl={3}>
              <AddTeamMemberLink
                setOpenAddTeamMember={setOpenAddTeamMember}
                members={allMembers}
                listMembers={listMembers}
              />
              {openAddTeamMember && (
                <MemberModalAPI
                  companyId={auth.company._id}
                  members={allMembers}
                  setMembers={setAllMembers}
                  WorkSampleProjectId={workSampleProject._id}
                >
                  <AddTeamMemberModal
                    openAddTeamMember={openAddTeamMember}
                    setOpenAddTeamMember={setOpenAddTeamMember}
                  />
                </MemberModalAPI>
              )}
              {openListTeamMember && (
                <MemberModalAPI
                  companyId={auth.company._id}
                  members={allMembers}
                  setMembers={setAllMembers}
                  WorkSampleProjectId={workSampleProject._id}
                  handleClose={() => setOpenListTeamMember(false)}
                >
                  <ListTeamMemberModal
                    openListTeamMember={openListTeamMember}
                    setOpenListTeamMember={setOpenListTeamMember}
                  />
                </MemberModalAPI>
              )}
            </Col>
            <Col className="bounty_status_bar d-none d-sm-none d-md-block d-lg-block">
              <NavBar />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={3} lg={3} xl={3} xxl={2} className="mb-3 ms-5">
              <AddCandidateButton
                handleClick={() => setShowAddCandidate(true)}
              />
              {showAddCandidate && (
                <AddcandidateModal
                  handleClose={() => setShowAddCandidate(false)}
                  showModal={showAddCandidate}
                  handleAddCandidate={handleAddCandidate}
                  handleAddCandidates={handleAddCandidates}
                />
              )}
            </Col>
            <Col md={7} lg={7} xl={6} xxl={6}>
              <ProjectDetailsHeader workSampleProject={workSampleProject} />
            </Col>
          </Row>
          <Row className="mx-2 mt-2">
            <Col>
              <TabsView
                auth={auth}
                tasks={tasks}
                interviewers={interviewers}
                workSampleProject={workSampleProject}
                candidates={candidates}
                setCandidates={setCandidates}
                sendReviewCandidate={sendReviewCandidate}
                handleOpenAddCandidate={() => {
                  setShowAddCandidate(true);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectPage;
