/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Button,
  Spinner,
} from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import PayButton from '../../shared/button/pay';
import SaveButton from '../../shared/button/save';
import ResetButton from '../../shared/button/resetButton';
import NextButton from '../../shared/button/next';
import BackButton from '../../shared/button/back';
import BankSVG from '../../assets/icon/snapbrillia_wallet_bank_icon.svg';
import CreditCardSVG from '../../assets/icon/snapbrillia_credit_card_icon.svg';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/createBounty.css';
import '../../shared/css/typography.css';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import {
  Elements,
  PaymentElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { useSendTransaction } from 'wagmi';

import { useGigForm } from '../../context/gigContext';
import StripeModal from '../../shared/menus/stripeModal.jsx';
import { useAuth } from '../../context/authContext';
import {
  createStripeSession,
  listStripeAccounts,
  createStripeConnectIntents,
} from '../../api/sessions';
import * as GigApi from '../../api/opportunities';
import Overlay from '../../shared/Overlays/OverlayWithChildren';
// import { getSendAdaFee } from '../../api/crypto';

import { verifyCoupon } from '../../api/coupon';
import { StepperWrapper } from '../../cryptoModal/StepperWrapper';
import '../../shared/css/buttonStyles.css';
import { CompanyRequestCoupon } from '../../api/coupon';
import { countCompanyOpportunities } from '../../api/companies';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export const StepFourBounty = ({
  nextStep,
  prevStep,
  closeModal,
  resetStep,
  editSteps,
  cryptoPay,
  setCryptoPay,
}) => {
  const { auth } = useAuth();
  const [sendAdaFee, setSendAdaFee] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [linkToken, setLinkToken] = useState('');
  const [payment, setPayment] = useState('');
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState();
  const [stripe, setStripe] = useState();
  const [clientSecret, setClientSecret] = useState('');
  const [intent, setIntent] = useState('');
  const [fee, setFee] = useState(0.2);
  const [useBountyCredit, setUseBountyCredit] = useState(false);
  const [options, setOptions] = useState(null);
  const [payCoupon, setPayCoupon] = useState(false);
  const [showRequestButton, setShowRequestButton] = useState(false);

  const [showCoupon, setShowCoupon] = useState(false);
  const [proFee, setProFee] = useState(0);
  const { data, isLoading } = useSendTransaction({
    request: {
      to: process.env.REACT_APP_ETH_WALLET,
    },
  });
  const [couponData, setCouponData] = useState({});
  const [consumeBountyCreditAmount, setConsumeBountyCreditAmount] = useState(0);

  const [assets, setAssets] = useState([{ type: 'USD', tokenIdentifier: '' }]);
  const NETWORKS = {
    Bank: 'Bank',
    Crypto: 'Crypto',
  };

  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    setFieldTouched,
    submitForm,
    isEditGig,
    activeCoupon,
    resetGigForm,
  } = useGigForm();
  const [selectedNetwork, setSelectedNetwork] = useState(
    cryptoPay ? NETWORKS.Crypto : NETWORKS.Bank
  );

  const listingType = ['Regular', 'SuperCharge'];

  const handleNetworkChange = async (value) => {
    let supportedAssets = [];
    if (value === NETWORKS.Bank) {
      supportedAssets = [{ type: 'USD' }];
    }
    handleChange({
      target: {
        name: 'cryptoInfo',
        value: { ...values.cryptoInfo, name: value },
      },
    });
    handleChange({
      target: {
        name: 'rewardNetwork',
        value: value,
      },
    });
    setSelectedNetwork(value);
    setAssets(supportedAssets);
    if (supportedAssets.length) {
      const selectedType = supportedAssets.find(
        (x) => x.type === values.rewardType
      );
      handleChange({
        target: {
          name: 'rewardType',
          value: selectedType ? selectedType.type : supportedAssets[0].type,
        },
      });
    }
  };
  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };

  const connectBankAccount = async () => {
    const { client_secret } = await createStripeSession();
    setLinkToken(client_secret);
  };

  const canFund = useMemo(() => {
    return !values.funded && values.status !== 'pending';
  }, [values]);

  const reloadBankAccount = async () => {
    try {
      const stripeData = await listStripeAccounts();
      setAccounts(stripeData?.accounts || []);
      return stripeData;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    reloadBankAccount();
    handleNetworkChange(cryptoPay ? NETWORKS.Crypto : NETWORKS.Bank);
  }, []);

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (currentErrors.rewardAmount || currentErrors.timeEstimation) {
      setFieldTouched('rewardAmount');
      setFieldTouched('timeEstimation');
      return;
    }
    setLoading(true);
    console.log('here');
    handleChange({
      target: {
        name: 'paymentType',
        value: 'Bank',
      },
    });
    const bounty = await submitForm();
    // console.log(bounty);
    try {
      if (canFund && !payment) {
        setLoading(false);
        toast('Please select an account');
        return;
      }
      await chargeBounty(bounty);
      if (selectedNetwork !== NETWORKS.Crypto) {
        closeOrNext(true, bounty._id, bounty.isPrivate);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast(error.response?.data?.error || error.message);
      setLoading(false);
    }
  };

  const save = async (e) => {
    e.preventDefault();
    handleChange({
      target: {
        name: 'paymentType',
        value: 'Bank',
      },
    });
    await submitForm();
    closeOrNext();
  };

  const closeOrNext = (funded, _id, isPrivate) => {
    if (funded && values.useGithubApp) {
      nextStep();
    } else if (closeModal) {
      closeModal(_id, isPrivate);
      setCryptoPay(false);
    }
  };

  const chargeBounty = async (bounty) => {
    if (!canFund) {
      return;
    }
    if (selectedNetwork === NETWORKS.Bank) {
      await bankCharge(bounty);
    }
  };

  const chargeCoupon = async (opportunity) => {
    await GigApi.invoicedCoupon(opportunity._id, {
      coupon: couponData.coupon,
    });
  };

  const paymentFee = useMemo(() => {
    if (selectedNetwork === NETWORKS.Bank) {
      if (clientSecret) {
        return values.rewardAmount * 0.029 + 0.3;
      } else {
        const achFee = values.rewardAmount * 0.08;
        return achFee > 5 ? 5 : achFee;
      }
    } else {
      return sendAdaFee;
    }
  }, [values.rewardType, values.rewardAmount, clientSecret]);

  const bankCharge = async (bounty) => {
    const paymentIntent = await GigApi.createStripeIntents(bounty._id, {
      account: payment,
      amount:
        couponData.isValid && couponData.isInvoiceCoupon && showCoupon
          ? Number(values.rewardAmount) + Number(paymentFee)
          : Number(values.rewardAmount) + Number(proFee) + Number(paymentFee),
    });
    await GigApi.confirmStripeIntents(paymentIntent.paymentIntentId);
  };

  const creditCharge = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (currentErrors.rewardAmount || currentErrors.timeEstimation) {
      setFieldTouched('rewardAmount');
      setFieldTouched('timeEstimation');
      return;
    }
    setLoading(true);
    const bounty = await submitForm();
    const data = await createStripeConnectIntents(bounty._id, payment);
    setClientSecret(data.clientSecret);
    setIntent(data.paymentIntentId);
    setOptions({
      clientSecret: data.clientSecret,
      appearance: {
        theme: 'stripe',
      },
    });
    setLoading(false);
  };

  const validateCoupon = async () => {
    try {
      const data = await verifyCoupon(values.coupon);
      setCouponData(data);
      if (data.isValid) {
        setShowCoupon(true);
        handleChange({
          target: { name: 'validCoupon', value: data.isValid },
        });
        handleChange({
          target: { name: 'coupon', value: data.coupon },
        });
        if (data.isInvoiceCoupon) {
          toast('Coupon Invoice Added');
          setPayCoupon(true);
        } else {
          toast('Coupon Added');
          setPayCoupon(false);
        }
      } else if (!data) {
        toast('Coupon not found or already used');
      }
    } catch (error) {
      toast('Coupon not found or already used');
    }
  };

  const RemoveCoupon = () => {
    setPayCoupon(false);
    setShowCoupon(false);
    handleChange({
      target: { name: 'validCoupon', value: false },
    });
    handleChange({
      target: { name: 'coupon', value: '' },
    });
    toast('Coupon Invoice Removed');
  };

  const RemoveDiscount = () => {
    setPayCoupon(false);
    setShowCoupon(false);
    handleChange({
      target: { name: 'validCoupon', value: false },
    });
    handleChange({
      target: { name: 'coupon', value: '' },
    });
    toast('Coupon Discount Removed');
  };

  const processingFee = (val) => {
    const fees = val ? (val * 20) / 100 : 0;
    if (couponData.isValid && !couponData.isInvoiceCoupon && showCoupon) {
      const discount = (couponData.fee * fees) / 100;
      const feePay = fees > discount ? fees - discount : discount - fees;
      setProFee(feePay);
    } else {
      setProFee(fees);
    }
  };

  const requestCoupon = async (e) => {
    setLoading(true);
    await CompanyRequestCoupon()
      .then((data) => {
        toast('Request Sent Successfully');
        handleChange({
          target: {
            name: 'coupon',
            value: data.coupon,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        toast('Request Failed');
      });
    setLoading(false);
  };

  const countOpportunities = async () => {
    await countCompanyOpportunities(auth.company._id)
      .then((data) => {
        if (data > 0) {
          setShowRequestButton(false);
        } else {
          setShowRequestButton(true);
        }
      })
      .catch((error) => {
        setShowRequestButton(false);
      });
  };

  useEffect(() => {
    countOpportunities();
  }, []);

  useEffect(() => {
    processingFee(values.rewardAmount);
  }, [values.rewardAmount, validateCoupon, RemoveDiscount, RemoveCoupon]);

  return (
    <Container>
      <Elements stripe={stripePromise}>
        {linkToken && (
          <StripeModal
            clientSecret={linkToken}
            reloadAccount={reloadBankAccount}
          />
        )}
      </Elements>
      <Row>
        <p className="grey-bounty-setup-header">
          Add time estimation, reward amount, &amp; your method of payment
        </p>
      </Row>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group as={Col} controlId="steptwoGroup1">
              <Form.Label className="form_label bounty-type-label ps-0">
                Time Estimation <span className="required">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Select
                  className="make-smaller-select-group"
                  name="timeEstimationType"
                  onChange={handleChange}
                  disabled={loading}
                  value={values.timeEstimationType}
                >
                  <option value="Hours">Hours</option>
                  <option value="Days">Days</option>
                  <option value="Weeks">Weeks</option>
                  <option value="Months">Months</option>
                </Form.Select>
                <Form.Control
                  className="gig-form-field border-right"
                  as="input"
                  type="number"
                  name="timeEstimation"
                  onChange={handleChange}
                  value={values.timeEstimation}
                  onBlur={handleBlur}
                  isInvalid={!!errors.timeEstimation}
                  disabled={loading}
                />
                {touched.timeEstimation && errors.timeEstimation && (
                  <Form.Control.Feedback type="invalid">
                    {errors.timeEstimation}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
              <Form.Text className="text-muted">
                How much time do you expect this to take?
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} controlId="steptwoGroup2">
              <Form.Label className="form_label bounty-type-label ps-0">
                Select Network <span className="required">*</span>
              </Form.Label>
              <Form.Select
                name="rewardNetwork"
                onChange={(e) => handleNetworkChange(e.target.value)}
                disabled={loading}
                value={selectedNetwork}
              >
                {Object.keys(NETWORKS).map((n, index) => (
                  <option value={n} key={index}>
                    {n}
                  </option>
                ))}
              </Form.Select>
              <Form.Group />
              <Form.Label className="form_label bounty-type-label ps-0">
                Budget Amount <span className="required">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Select
                  className="make-smaller-select-group"
                  name="rewardType"
                  onChange={handleChange}
                  value={values.rewardType}
                  disabled={!canFund || loading}
                >
                  {assets.map((currency, index) => (
                    <option key={index} value={currency.type}>
                      {currency.type}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control
                  className="gig-form-field border-right"
                  as="input"
                  type="number"
                  name="rewardAmount"
                  onChange={handleChange}
                  value={values.rewardAmount}
                  onBlur={handleBlur}
                  isInvalid={!!errors.rewardAmount}
                  disabled={!canFund || loading}
                />
                {touched.rewardAmount && errors.rewardAmount && (
                  <Form.Control.Feedback type="invalid">
                    {errors.rewardAmount}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
              <Form.Text className="text-muted">
                Select to pay using USD, ₳DA, ETH, SOL, or Tokens.
              </Form.Text>
            </Form.Group>
            {showRequestButton && (
              <Col>
                <br />
                <button
                  style={{
                    width: '100%',
                    // padding: '5px 20px',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    requestCoupon(e);
                  }}
                  className="btn-primary"
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Request Free Coupon'
                  )}
                </button>
              </Col>
            )}
            <Form.Group as={Col} className="mb-4" controlId="steptwoGroup5">
              <Form.Label className="form_label bounty-type-label ps-0">
                Coupon Code
              </Form.Label>
              <InputGroup>
                <Form.Control
                  className="gig-form-field border-left"
                  as="input"
                  name="coupon"
                  onChange={handleChange}
                  value={values.coupon}
                  onBlur={handleBlur}
                  isInvalid={!!errors.coupon}
                  disabled={payCoupon}
                />
                {!payCoupon && !showCoupon ? (
                  <Button
                    variant="outline-secondary"
                    className="coupon-button"
                    disabled={payCoupon}
                    onClick={validateCoupon}
                  >
                    Apply
                  </Button>
                ) : couponData.isValid && !couponData.isInvoiceCoupon ? (
                  <Button
                    variant="outline-secondary"
                    className="coupon-button"
                    onClick={() => {
                      RemoveDiscount();
                      processingFee(values.rewardAmount);
                    }}
                  >
                    Remove Discount
                  </Button>
                ) : (
                  <Button
                    variant="outline-secondary"
                    className="coupon-button"
                    onClick={() => {
                      RemoveCoupon();
                      processingFee(values.rewardAmount);
                    }}
                  >
                    Remove Coupon
                  </Button>
                )}
              </InputGroup>
            </Form.Group>

            {canFund && (
              <Row>
                <Form.Label className="form_label bounty-type-label grey-bounty-setup-header mt-3 fs-6">
                  Select how you would like to pay for this gig to go live
                </Form.Label>
              </Row>
            )}
            {canFund && selectedNetwork === NETWORKS.Bank && (
              <>
                <Row className="mb-3">
                  {accounts.length > 0 && (
                    <select
                      className="input_fields form_input select-down-icon select-input"
                      id={'dropdown'}
                      disabled={loading}
                      onChange={(e) => {
                        e.preventDefault();
                        setPayment(e.target.value);
                        setClientSecret('');
                      }}
                    >
                      <option className="input_fields" value="" key="-1">
                        Select Connected Account
                      </option>
                      {accounts.map((account, index) => {
                        return (
                          <option
                            className="input_fields"
                            value={account.id}
                            key={index}
                          >
                            {`${account.institution_name} (...${account.last4})`}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  <Row>
                    <Form.Label className="form_label bounty-type-label grey-bounty-setup-header mt-3 fs-6">
                      Or Add New
                    </Form.Label>
                  </Row>
                </Row>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <ElementsConsumer>
                      {({ stripe, elements }) => {
                        setElements(elements);
                        setStripe(stripe);
                        return (
                          <form id="payment-form">
                            <PaymentElement
                              id="payment-element"
                              onReady={() => {
                                setLoading(false);
                              }}
                            />
                          </form>
                        );
                      }}
                    </ElementsConsumer>
                  </Elements>
                )}
                <Row className="mb-3 mt-2">
                  <Col xs={12} md={6}>
                    <Card
                      bg="primary"
                      text="light"
                      className="pointer"
                      onClick={connectBankAccount}
                    >
                      <Card.Body>
                        <Row className="bank-row">
                          <Col xs={12} md={4}>
                            <img src={BankSVG} alt="bank-icon" />
                          </Col>
                          <Col xs={12} md={8}>
                            <Card.Text className="fs-7">
                              Pay via Bank Account
                            </Card.Text>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={6}>
                    <Card
                      bg="info"
                      text="light"
                      className="pointer"
                      onClick={creditCharge}
                    >
                      <Card.Body>
                        <Row>
                          <Col xs={12} md={4}>
                            <img src={CreditCardSVG} alt="credit-card-icon" />
                          </Col>
                          <Col xs={12} md={8}>
                            <Card.Text className="fs-7">
                              Pay via Debit / Credit Card
                            </Card.Text>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {canFund && selectedNetwork === NETWORKS.Crypto && (
              <StepperWrapper
                type="Gig"
                closeOrNext={closeOrNext}
                closeModal={() => {
                  setSelectedNetwork(NETWORKS.Bank);
                  handleChange({
                    target: { name: 'rewardType', value: 'USD' },
                  });
                  handleChange({
                    target: { name: 'rewardNetwork', value: 'Bank' },
                  });
                  handleChange({
                    target: { name: 'rewardAmount', value: 1000 },
                  });
                  setAssets([{ type: 'USD', tokenIdentifier: '' }]);
                  if (cryptoPay) {
                    setCryptoPay(false);
                    closeOrNext();
                  }
                }}
                cryptoPay={cryptoPay}
              />
            )}
          </Col>
          <Col xs={12} md={6}>
            <Card className="remove-card-bg">
              <Card.Body className="grey-bg h-100 d-inline-block">
                <Card.Text>
                  <Row>
                    <p className="grey-bounty-setup-header fs-6">Summary</p>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className="fs-7 mb-0">Gig Title:</p>
                      <p className="fs-7 mb-1">
                        <b
                          className="d-inline-block text-truncate"
                          style={{ maxWidth: '100%' }}
                        >
                          {values.title}
                        </b>
                      </p>
                      <p className="fs-7 mb-0">Gig Description:</p>
                      <Overlay text={values.taskDescription}>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '100%' }}
                          >
                            {values.taskDescription}
                          </b>
                        </p>
                      </Overlay>
                      <p className="fs-7 mb-0">Submission Requirements:</p>
                      <Overlay text={values.submissionRequirements}>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '100%' }}
                          >
                            {values.submissionRequirements}
                          </b>
                        </p>
                      </Overlay>
                      <p className="fs-7 mb-0">Acceptance Criteria:</p>
                      <Overlay text={values.acceptenceCriteria}>
                        <p className="fs-7 mb-1">
                          <b
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: '100%' }}
                          >
                            {values.acceptenceCriteria}
                          </b>
                        </p>
                      </Overlay>
                      <p className="fs-7 mb-0">Skills:</p>
                      <p className="fs-7 mb-1">
                        <b
                          className="d-inline-block text-truncate"
                          style={{ maxWidth: '100%' }}
                        >
                          {values.selectedSkills?.join(', ')}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  {selectedNetwork !== NETWORKS.Crypto && (
                    <>
                      <Row className="text-end mt-4">
                        <Col xs={12} md={12}>
                          <hr />
                          <p className="fs-7 mb-1">
                            <b>Here we will calculate the costs</b>
                          </p>

                          <>
                            <p className="fs-7 mb-1">
                              <b>
                                Reward Amount: {values?.rewardAmount}{' '}
                                {values.rewardType}
                              </b>
                            </p>
                            {/* <p className="fs-7 mb-1">
                              X <b>{Math.round(fee * 100)}% Fee</b> (+
                              {(fee * bountyAmount).toFixed(2) || 0})
                            </p> */}

                            <p className="fs-7 mb-1">
                              + <b>Processing Fee: </b> (+
                              {paymentFee ? paymentFee : 0 || 0}{' '}
                              {values.rewardType})
                            </p>
                            <p className="fs-7 mb-1">
                              + <b>Listing Fee (20%): </b> (+
                              {values?.rewardAmount
                                ? (values?.rewardAmount * 20) / 100
                                : 0 || 0}{' '}
                              {values.rewardType})
                            </p>

                            <p className="mb-1">
                              {payCoupon && <b>Fee paid using coupon</b>}
                              {showCoupon &&
                                !couponData.isInvoiceCoupon &&
                                couponData.isValid && (
                                  <b>
                                    Processing Fee disoount coupon applied{' '}
                                    {`(${couponData.fee}%)`}
                                  </b>
                                )}
                              <br />
                              <b>
                                Total Cost:{' '}
                                {couponData.isValid &&
                                couponData.isInvoiceCoupon &&
                                showCoupon
                                  ? values.rewardType +
                                    ' ' +
                                    Number(values?.rewardAmount)
                                  : couponData.isValid &&
                                    !couponData.isInvoiceCoupon &&
                                    showCoupon
                                  ? couponData.fee >= 100
                                    ? '0.00' + values.rewardType
                                    : Number(values?.rewardAmount) +
                                      Number(proFee) +
                                      Number(paymentFee) +
                                      values.rewardType
                                  : values.rewardType +
                                    ' ' +
                                    (Number(values?.rewardAmount) +
                                      Number(paymentFee) +
                                      Number(proFee))}{' '}
                              </b>
                            </p>
                          </>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {editSteps !== 4 ? (
          <Row>
            <Col xs={12} md={5}>
              <div className="mt-4">
                <ResetButton handleClick={reset} />
                <SaveButton
                  handleClick={save}
                  type="button"
                  className="btn-secondary"
                  isDisabled={loading}
                />
              </div>
            </Col>
            <Col xs={12} md={7}>
              <div className="float-end mt-4">
                <BackButton onClick={back} />
                {canFund &&
                  (loading || isLoading ? (
                    <PayButton className="btn-primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </PayButton>
                  ) : (
                    <PayButton
                      onClick={next}
                      type="button"
                      disabled={loading || isLoading}
                    />
                  ))}
                {!canFund && values.useGithubApp && !values.url && (
                  <NextButton
                    onClick={next}
                    type="button"
                    disabled={loading || isLoading}
                  />
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={5}></Col>
            <Col xs={12} md={7}>
              <div className="float-end mt-4">
                {canFund &&
                  (loading || isLoading ? (
                    <PayButton className="btn-primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </PayButton>
                  ) : (
                    <PayButton
                      onClick={next}
                      type="button"
                      disabled={loading || isLoading}
                    />
                  ))}
                {!canFund && values.useGithubApp && !values.url && (
                  <NextButton
                    onClick={next}
                    type="button"
                    disabled={loading || isLoading}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
};

export default StepFourBounty;
