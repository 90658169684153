import '../../shared/css/typography.css';

const AddTeamWelcomeMessage = ({ user }) => {
  return (
    <div>
      <h1 className="h1">Well Hello, {user}!</h1>
      <h3 className="h3 small-text" style={{ marginTop: '33px' }}>
        Alright {user}, let&apos;s invite your team and bring you one step
        closer to hiring the best and brightest, now.
        {/* <strong className="bold"> best and brightest, now.</strong> */}
      </h3>
    </div>
  );
};

export default AddTeamWelcomeMessage;
