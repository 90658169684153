import React from 'react';
import Close from '../../assets/icon/snapbrillia_close_icon.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import '../css/typography.css';
// import "./feedbackModal.css";
import { Modal } from 'react-bootstrap';

/* const divStyles = {
  borderRadius: '20px',
  backgroundColor: 'white',
  height: '90%',
  left: '25%',
  width: '500px',
  minWidth: '800px',
  position: 'fixed',
  boxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
}; */

const titleStyles = {
  position: 'absolute',
  left: '10%',
  top: '5%',
};

const iframeStyles = {
  position: 'absolute',
  left: '6.3%',
  // top: '15%',
  width: '85%',
  height: '550px',
};

const closeSVGStyles = {
  position: 'absolute',
  right: '10%',
  top: '5.5%',
  transform: 'scale(1.5)',
  cursor: 'pointer',
  zIndex: '999',
};

const FeedbackModal = ({ setShowModal }) => {
  return (
    <Modal
      // backdrop="static"
      centered
      keyboard={false}
      onHide={() => setShowModal(false)}
      show={true}
      size="lg"
      fullscreen="lg-down"
    >
      <div /* style={divStyles} */>
        <div style={titleStyles} className="h1">
          Feedback
        </div>

        <img
          src={Close}
          alt="close-icon"
          style={closeSVGStyles}
          onClick={() => setShowModal(false)}
        />
        <Scrollbars
          // className="bounty-type-masked-overflow"
          autoHide
          autoHeight
          autoHeightMin={'67.5vh'}
          autoHeightMax={'67.5vh'}
          autoHideDuration={800}
          // thumbMinSize={100}
          renderThumbVertical={() => (
            <div
              // className="bounty-table-thumb-vertical"
              style={{
                position: 'relative',
                display: 'block',
                width: '9px',
                cursor: 'pointer',
                borderRadius: '20px',
                backgroundColor: 'rgb(73, 72, 72, 0.5)',
                right: '40px',
              }}
            />
          )}
          renderView={(props) => <div {...props} className="view" />}
          style={{
            // width: '83%',
            marginTop: '15%',
            maxWidth: 800,
            height: 800,
            borderRadius: '2px',
            overflowY: 'hidden',
          }}
        >
          {/* <div style={{ height: '5000px' }}> */}
          <iframe
            scrolling="yes"
            title="feedback"
            style={iframeStyles}
            src="https://docs.google.com/forms/d/e/1FAIpQLSd7iumYwPQYJnhSHNlk82UByH0KGCNa3omnD_8peCoSdhwE6A/viewform?embedded=true"
          ></iframe>
          {/* </div> */}
        </Scrollbars>
      </div>
    </Modal>
  );
};
export default FeedbackModal;
