import React from 'react';
import '../css/importCandidateText.css';

const ImportCandidateText = ({ className }) => {
  return (
    <p className={`candidate-main-content-text ${className}`}>
      Import candidates with a single spreadsheet file or upload up to 20
      resumes in PDF, DOC, DOCX, and TXT or CSV, XLSX, and XLS file types for
      spreadsheet upload. The first line of the spreadsheet must be the COLUMN
      HEADERS for the file. Each row in the spreadsheet must include an email or
      a social link. Upload your file or start from Snapbrillia&apos;s Candidate
      Import Template Maximum single file size for upload is 10MB. If you have
      bigger files please contact your support:{' '}
      <a href="/" style={{ color: '#a900a6', textDecoration: 'none' }}>
        support@snapbrillia.com
      </a>
    </p>
  );
};

export default ImportCandidateText;
