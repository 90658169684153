import '../css/buttonStyles.css';
import { Spinner } from 'react-bootstrap';

export default function Usethisemailbutton({
  onClick,
  isLoading = false,
  text = 'Use this Email',
  className = '',
}) {
  return (
    <button
      className={`btn-primary-medium signup-btn ${className}`}
      type="button"
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <span className="signup-text">{text}</span>
      )}
    </button>
  );
}
