import Modal from 'react-bootstrap/Modal';
import TermsOfUseText from '../../policyPage/components/termsOfUseText';

export default function TermsOfUseModal({ show, closeModal }) {
  return (
    <Modal show={show} fullscreen={true} onHide={closeModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <TermsOfUseText />
      </Modal.Body>
    </Modal>
  );
}
