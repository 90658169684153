import React from 'react';
import cookieReport from './cookiereport.htm';
import '../css/policyComponents.css';

/*eslint-disable max-lines*/

const CookiePolicyText = () => {
  return (
    <div className="policy-wrapper policy-typography">
      <div
        className="main-policy-container"
        data-testid="cookiePolicyComponent"
      >
        <div>
          <p className="ck32 ck33">
            <span className="ck8 ck28" />
          </p>
        </div>
        <p className="ck15">
          <span className="ck13 ">Cookie Policy</span>
        </p>
        <p className="ck16">
          <span className=" ">
            This website uses cookies and similar technologies such as HTML
            cookies and pixel tags (together “cookies”). We use cookies to help
            deliver the content of our Sites for authentication purposes (to
            keep track of the fact that you have logged in) and to analyze use
            of and improve the Sites and Services. We also use cookies to
            personalize content and ads, to provide social media features. You
            can learn more about how we use Personal Information in our Privacy
            Policy.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            We also allow certain social media, advertising and analytics
            partners to set cookies and collect information about your use of
            the Sites and Services. These third parties may collect your
            browsing and click history, including the functions you use and
            searches you perform using the Services; browser agent name and
            version; web pages you view; links you click; your IP address; the
            length of time you visit our Sites and or use our Services; and the
            referring URL, or the webpage that led you to our Sites. These third
            parties may combine information they collect from our Sites and
            Services with other information that you’ve provided to them, or
            that they’ve collected from your use of other websites and services.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            This Site uses different types of cookies (Necessary, Preferences,
            Statistics, Marketing). You can change or withdraw your consent to
            our use of cookies at any time, or opt-out of Preferences,
            Statistics, and Marketing cookies.
          </span>
        </p>
        <p className="ck25">
          <span className=" ">
            Your current state: Use necessary cookies only.{' '}
          </span>
        </p>
        <p className="ck25">
          <span className="ck29">
            <a
              target="_blank"
              rel="noreferrer"
              className="ck24 "
              href="https://www.google.com/url?q=https://snapbrillia.com/%23&sa=D&source=editors&ust=1631060500130000&usg=AOvVaw1JzB6nD1J0A_Xf9tPhEjYM"
            >
              Change your consent
            </a>
          </span>
        </p>
        <p className="ck16 ck32">
          <span className="ck8 ck7" />
        </p>
        <p className="ck16">
          <span className=" ">
            You can also set your web browser to refuse all cookies or to
            indicate when a cookie is being sent. The “help” feature of the menu
            bar on most browsers will tell you how to stop accepting new
            cookies, how to receive notification of new cookies and how to
            disable existing cookies. However, certain features of the Sites or
            Services may not work if you delete or disable cookies.
          </span>
        </p>
        <p className="ck16">
          <span className=" ">
            The cookies we use are explained further below.
          </span>
        </p>
        <iframe className="w-100 cookie-report-iframe" src={cookieReport} />
      </div>
    </div>
  );
};

export default CookiePolicyText;
