import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../assets/snapbrillia_logo_white.svg';
import ColoredLogo from '../assets/SnapBetaLogo2.svg';
import SignInForm from './components/signInForm';
import '../shared/css/bgColors.css';
import '../shared/css/textColors.css';
import '../shared/css/typography.css';
import './css/signInStyles.css';
import { Link } from '@reach/router';
import { GoogleOAuthProvider } from '@react-oauth/google';
export default function SignIn() {
  return (
    <Container className="signinmainbg sign-in-page" fluid>
      <Row>
        <Col md={3}>
          <img
            src={Logo}
            alt="logo"
            className="d-none d-xl-block d-md-block d-lg-block signin-logo-lg"
          />
          <section className="h-75 ui-text-white d-none d-xl-flex d-md-flex d-lg-flex signin-header w-75 d-flex flex-column justify-content-center align-items-center mx-auto">
            <h3>
              Your unfair advantage to hiring the
              <span className="extra-bold"> best and the brightest, now.</span>
            </h3>
          </section>
        </Col>
        <Col
          className="d-flex vh-100 justify-content-center primarybg componentborder align-items-center"
          md={9}
        >
          <div className="w-100 h-100 overflow-scroll">
            <Row className="w-100">
              <Col md={12}>
                <img
                  src={ColoredLogo}
                  alt="colored-logo"
                  className="sm:mx-3 d-xl-none d-lg-none d-md-none d-block"
                />
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <SignInForm />
                </GoogleOAuthProvider>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
