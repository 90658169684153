import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import '../../shared/css/buttonStyles.css';
import './css/greenhouseMappingFields.css';

import CloseIcon from '../../assets/icon/snapbrillia_black_close_icon.svg';
import ArrowFowardIcon from '../../assets/icon/snapbrillia_arrow_forward_icon.svg';

export default function GreenhouseMappingFields({
  fullNameStyle,
  firstNameStyle,
  lastNameStyle,
  primaryEmailStyle,
  extraEmail1Style,
  extraEmail2Style,
  phoneNumberStyle,
  // profileHighlightStyle,
  locationStyle,
  // locationCountStyle,
}) {
  return (
    <Container className="scroll-bar">
      <Col>
        <Row className="row-padding">
          <Col md={{ span: 5, offset: 1 }} xs={6}>
            Snapbrillia Profile Fields
          </Col>
          <Col>Greenhouse Harvest Profile Fields</Col>
        </Row>
        <Row
          className={
            firstNameStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            First Name
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>First Name</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            lastNameStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Last Name
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Last Name</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            primaryEmailStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Primary Email Address
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Email Addresses</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            extraEmail1Style === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Extra Email Address 1
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Email Addresses</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            extraEmail2Style === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Extra Email Address 2
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Email Addresses</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            phoneNumberStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Phone Number
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Phone Number</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            locationStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Location
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Addresses</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            fullNameStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Current Position Company Name
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Company</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            fullNameStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Position Title
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Employment Title</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
        <Row
          className={
            fullNameStyle === 2
              ? 'row-padding visible'
              : 'row-padding invisible'
          }
        >
          <Col md={{ span: 4, offset: 1 }} xs={4}>
            Position Company Name
          </Col>
          <Col md={1} xs={1}>
            <img src={ArrowFowardIcon} alt="arrow-icon" />
          </Col>
          <Col md={4} xs={5}>
            <select>
              <option selected>Employment Company</option>
            </select>
          </Col>
          <Col md={1} xs={1}>
            <img src={CloseIcon} alt="close-icon" />
          </Col>
        </Row>
      </Col>
    </Container>
  );
}
