import { createContext, useState, useEffect } from 'react';
import ProjectPage from '../projectPage';
import * as projectAPI from '../../api/projects';
import * as sendEmailAPI from '../../api/sendEmail';
// import * as memberAPI from '../../api/members';
import { useAuth } from '../../context/authContext';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';

export const ProjectsContext = createContext();

const AllProjectsBackEnd = ({ workSampleProjectId }) => {
  const [workSampleProject, setWorkSampleProject] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [interviewers, setInterviewers] = useState([]);
  const { auth } = useAuth();

  const getWorkSampleProjectInfo = async () => {
    const WSProject = await projectAPI.getProjectInfo(workSampleProjectId);
    setWorkSampleProject(WSProject);
    await initDiversity();
  };

  const getMembers = async () => {
    const { members, pendingMembers } = await projectAPI.getMembers(
      workSampleProjectId
    );
    await setAllMembers([...members, ...pendingMembers]);
    const membersData = [...members, ...pendingMembers];
    const interviewers = await membersData.filter(
      (item) => item.companyRole === 'interviewer'
    );
    await setInterviewers(interviewers);
  };

  const getTasks = async () => {
    const { docs: tasks } = await projectAPI.getProjectTasks(
      workSampleProjectId
    );
    setTasks(tasks);
  };

  const getCandidates = async () => {
    const candidates = await projectAPI.getProjectCandidates(
      workSampleProjectId
    );
    setCandidates(candidates);
  };

  const handleAddCandidate = async (candidateField) => {
    candidateField.workSampleProjectId = workSampleProject._id;
    try {
      const response = await projectAPI.addCandidate(candidateField);
      let newCandidate = response.candidate;
      newCandidate.assessment = { status: 'new' };
      await setCandidates([...candidates, newCandidate]);
      if (!workSampleProject.candidateId) {
        workSampleProject.candidateId = [];
      }
      workSampleProject.candidateId.push(newCandidate._id);
      setWorkSampleProject(workSampleProject);
    } catch (error) {}
  };

  const handleAddCandidates = async (candidateFields) => {
    try {
      const response = await projectAPI.addCandidates(
        workSampleProject._id,
        candidateFields
      );
      let newCandidates = response.candidates;
      if (!workSampleProject.candidateId) {
        workSampleProject.candidateId = [];
      }
      await newCandidates.map((newCandidate) => {
        newCandidate.assessment = { status: 'new' };
        workSampleProject.candidateId.push(newCandidate._id);
      });
      await setCandidates([...candidates, ...newCandidates]);
      setWorkSampleProject(workSampleProject);
      return newCandidates;
    } catch (error) {}
  };

  const sendEmailReview = async (candidate) => {
    const { firstName, email, assessment } = candidate;

    var to = {
      name: firstName,
      email: email,
    };

    var param = {
      WSProject: workSampleProject,
      assessment: assessment,
      candidate: candidate,
      company: auth.company,
    };
    await sendEmailAPI.sendEmailReview(to, param);
  };

  const sendReviewCandidate = async (candidate, result) => {
    const assessment = await projectAPI.reviewCandidate(candidate, result);
    const newCandidates = candidates.map((item) => {
      if (item._id === candidate._id) {
        item.assessment = assessment;
      }
      return item;
    });
    setCandidates(newCandidates);
    await sendEmailReview(candidate);
  };

  const handleDeleteProject = async () => {
    try {
      await projectAPI.deleteProject(workSampleProject._id);
      toast.success('Successful!');
      navigate(`/projects`);
    } catch (error) {}
  };

  const handleEditProject = async (workSampleProjectEdit) => {
    const response = await projectAPI.editProject(workSampleProjectEdit);
    if (!response) {
      return;
    }
    setWorkSampleProject(workSampleProjectEdit);
    toast.success('Successful!');
  };

  const initDiversity = async () => {
    if (workSampleProject && workSampleProject.length > 0) {
      const { data } = await projectAPI.getProjectDiversity(
        workSampleProject._id,
        'male'
      );
      if (data && data.length > 0) {
        setWorkSampleProject({ ...workSampleProject, ['disparity']: data });
      } else {
        setWorkSampleProject({ ...workSampleProject, ['disparity']: 'NaN' });
      }
    }
  };
  useEffect(() => {
    getWorkSampleProjectInfo();
    getCandidates();
    getTasks();
    getMembers();
  }, [workSampleProjectId]);

  return (
    <ProjectsContext.Provider
      value={{
        workSampleProject,
        candidates,
        setCandidates,
        tasks,
        getWorkSampleProjectInfo,
        sendReviewCandidate,
        handleDeleteProject,
        handleEditProject,
        handleAddCandidate,
        auth,
        allMembers,
        setAllMembers,
        interviewers,
        handleAddCandidates,
      }}
    >
      <ProjectPage />
    </ProjectsContext.Provider>
  );
};

export default AllProjectsBackEnd;
