import React from 'react';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { formatDate } from '../../utils/functions';
import '../css/candidateInterviewSections.css';

const CandidateInterviewSection = ({ assessment }) => {
  const percentage =
    (assessment.workSampleScore / assessment.workSampleValuationList.length) *
    100;

  const totalTested = assessment.workSampleValuationList.length;
  const totalPassed = assessment.workSampleScore;
  const totalAttempted = assessment.inputCode.length;
  const startedAt = new Date(assessment.startedAt);
  const completedAt = new Date(assessment.completedAt);
  const totalDuration = Math.round((completedAt - startedAt) / 60000);

  const averageChallengeDuration = new Date(
    (completedAt - startedAt) / totalAttempted
  );

  return (
    <Container>
      <Row>
        <h1 className="h4 bold">Interview</h1>
      </Row>
      <Row>
        <Col className="cardbg pt-2 me-2 rouded-3">
          <h1 className="h5">Details</h1>
          <h1 className="grey-title">Date:</h1>
          <p data-testid="date" className="primary">
            {formatDate(assessment.startedAt)}
          </p>
          <h1 className="grey-title">Invited By:</h1>
          <p data-testid="interviewer" className="primary">
            {assessment.invitedBy}
          </p>
        </Col>
        <Col className="cardbg me-2 rounded-3" xs={6}>
          <h1 className="h5">Score</h1>
          <h1 className="h4 text-end bold" data-testid="percentage">
            {percentage}%
          </h1>
          <ProgressBar
            now={percentage}
            className="progress"
            style={{ height: '10px', marginBottom: '10px' }}
          />
          <div className="attempted-container">
            <h1 className="grey-title">Challenges Attempted</h1>
            <p className="text" data-testid="attempted">
              {totalAttempted} out of {totalTested}
            </p>
          </div>
          <h1 className="grey-title">Challenges passed:</h1>
          <p className="passed" data-testid="passed">
            {totalPassed} out of {totalTested}
          </p>
        </Col>
        <Col className="cardbg rounded-3">
          <h1 className="h5">Duration</h1>
          <h1 className="grey-title">Total Duration:</h1>
          <p data-testid="duration" className="text">
            {totalDuration === 1
              ? `${totalDuration} Minute`
              : `${totalDuration} Minutes`}
          </p>
          <h1 className="grey-title">Average Challenge Duration:</h1>
          <p data-testid="average-duration" className="text">
            {(averageChallengeDuration.getMinutes() === 1) | 0
              ? `${averageChallengeDuration.getMinutes()} Minute, ${averageChallengeDuration.getSeconds()} Seconds`
              : `${averageChallengeDuration.getMinutes()} Minutes, ${averageChallengeDuration.getSeconds()} Seconds`}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CandidateInterviewSection;
