import React, { useState, useRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { Scrollbars } from 'react-custom-scrollbars';
import ScheduleCalendly from '../../shared/button/scheduleCalendly';

import Close from '../../assets/icon/snapbrillia_close_icon.svg';
import '../css/bountyTypeSelection.css';
import '../../shared/css/sharedStyles.css';
import SelectMenu from '../../shared/formItems/selectMenu';
import AssessmentAddGitBucket from './assessmentAddGitBucket';
import { navigate } from '@reach/router';

const BountyTypeSelection = ({
  assessmentType,
  setAssessmentType,
  url,
  setUrl,
  setRepoInfo,
  repoInfo,
}) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const [widgetHeight, setWidgetHeight] = useState(800);
  const widgetRef = useRef();

  const list = [
    { item: 'Public', key: 3 },
    { item: 'Private', key: 4 },
    {
      item: `Concierge`,
      key: 5,
    },
  ];
  useEffect(() => {
    setAssessmentType(list[0].item);
  }, []);

  const onCloseModal = () => {
    setIsCalendlyOpen(false);
    setWidgetHeight(800);
  };

  return (
    <Row style={{ fontFamily: 'Roboto, serif' }} className="pe-0">
      {isCalendlyOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: '100',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, .8)  ',
            animation: 'open 3s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="close_modal_button">
            <img src={Close} alt="close-icon" onClick={() => onCloseModal()} />
          </div>

          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={900}
            autoHeightMax={widgetHeight}
            autoHideDuration={800}
            thumbMinSize={30}
            renderThumbVertical={() => <div className="thumb-vertical" />}
            style={{
              width: '83%',
              maxWidth: 1350,
              height: 500,
              borderRadius: '2px',
              overflowY: 'hidden',
            }}
          >
            <div
              className="bounty-type-masked-overflow"
              style={{ height: '2560', overflowY: 'hidden' }}
            >
              <CalendlyEventListener
                onDateAndTimeSelected={() => {
                  setWidgetHeight(1000);
                }}
                onEventTypeViewed={() => {
                  setWidgetHeight(2250);
                }}
                onProfilePageViewed={() => {
                  setWidgetHeight(2750);
                }}
                onEventScheduled={() => {
                  navigate('/dashboard');
                }}
              >
                <InlineWidget
                  className="bounty-type-masked-overflow"
                  ref={widgetRef}
                  url="https://calendly.com/ninhtran/connect"
                  onModalClose={() => {
                    setIsCalendlyOpen(false);
                  }}
                  rootElement={document.getElementById('root')}
                  styles={{
                    height: 2650,
                    width: '95%',
                    overflow: 'hidden',
                  }}
                />
              </CalendlyEventListener>
            </div>
          </Scrollbars>
        </div>
      )}
      <Col xs={5} md={4}>
        <Row>
          <Col>
            <label className="assessment-git-bucket-label">Gig Type</label>
            <div className="assessment-type-label bounty-select-text mb-2">
              <SelectMenu
                value={assessmentType}
                setValue={setAssessmentType}
                list={list}
              />
            </div>
          </Col>
        </Row>
      </Col>

      {(assessmentType === 'Public' || assessmentType === 'Private') && (
        <AssessmentAddGitBucket {...{ url, setUrl, setRepoInfo, repoInfo }} />
      )}
      {assessmentType === 'Concierge' && (
        <Col>
          <Row style={{ height: '100%' }}>
            <Col
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
              }}
            >
              <ScheduleCalendly
                onClick={(e) => {
                  e.preventDefault();
                  setIsCalendlyOpen(true);
                }}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default BountyTypeSelection;
