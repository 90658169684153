import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../assets/snapbrillia_logo_white.svg';
import ColoredLogo from '../assets/SnapBetaLogo2.svg';
import SignUpForm from './components/signUpForm';
import '../shared/css/bgColors.css';
import '../shared/css/textColors.css';
import '../shared/css/typography.css';
import './css/signUpForm.css';

export default function SignUp() {
  return (
    <Container className="signinmainbg vh-100" fluid>
      <Row>
        <Col md={3}>
          <img
            src={Logo}
            alt="logo"
            className="d-none d-xl-block d-md-block d-lg-block signin-logo-lg"
          />
          <section className="h-75 ui-text-white d-none d-xl-flex d-md-flex d-lg-flex signin-header w-75 d-flex flex-column justify-content-center align-items-center mx-auto">
            <h3>
              Your unfair advantage to hiring the
              <span className="extra-bold"> best and the brightest, now.</span>
            </h3>
          </section>
        </Col>
        <Col
          className="primarybg componentborder d-flex flex-column justify-content-center align-items-center vh-100"
          md={9}
        >
          <Row className="w-100 mx-auto justify-content-center">
            <Col md={10}>
              <img
                src={ColoredLogo}
                alt="colored-logo"
                className="fixed-top ms-3 d-xl-none d-lg-none d-md-none d-block"
              />
              <SignUpForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
