import { Container, Modal, Row, Col } from 'react-bootstrap';
import '../css/growthConfirm.css';
import BarGraph from '../../assets/icon/Snapbrillia_subscriptionpage_growthimage.png';

export default function GrowthConfirmModal({ showModal, setShowModal }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      fullscreen="true"
      scrollable="false"
    >
      {/* <Modal.Header closeButton onClick={() => setShowModal(false)} /> */}
      <Modal.Body>
        <span>
          <h3 className="growth-modal-header">Growth</h3>
        </span>
        <img src={BarGraph} className="w-25 bargraph" />
        <span>
          <h3 className="growth-price">
            $499 <p className="growth-month">/ month</p>
          </h3>
        </span>
        <ul className="growth-list">
          <li>Limited public course bounties</li>
          <p className="growth-amount">(500,000$ per year)</p>
          <li>Limited private bounties</li>
          <p className="growth-amount">(100,000$ per year)</p>
        </ul>
        <Container className="payment-container">
          <Row>
            <label className="payment-method">Payment Method</label>
          </Row>
          <select
            className="input_fields form_input select-down-icon"
            id="dropdown"
          >
            <Row>
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.8607 0.40625L7.51315 6.19113L2.1656 0.40625L0.522888 2.18718L7.51315 9.76563L14.5034 2.18718L12.8607 0.40625Z"
                  fill="#7F7F7F"
                ></path>
              </svg>
            </Row>
            {/* <option className="input_fields" value="">
              {Select }
            </option> */}
            <option
              className="input_fields"
              value="fca_1LLNfvGDFI9EOWwPX0oU36W4"
            >
              USAA (...0197)
            </option>
            <option
              className="input_fields"
              value="fca_1LLNfvGDFI9EOWwPE9TGz2eY"
            >
              USAA (...2227)
            </option>
            <option
              className="input_fields"
              value="fca_1LLNfvGDFI9EOWwPODkNhryu"
            >
              USAA (...1116)
            </option>
          </select>
          <br></br>
          <hr className="style1"></hr>
          <Row className="row tax-fee">
            <Col className="col-sm-9">Tax & Fees</Col>
            <Col className="col-sm-3 text-end">$ 3.00</Col>
          </Row>
          <Row className="total">
            <Col className="col-md-9">Total</Col>
            <Col className="col-md-3 text-end">$ 502.00</Col>
          </Row>
          <Row>
            <Col className="col-md-3">
              <button className="cancel_button btn-link">Cancel</button>
            </Col>
            <Col className="col-md-9 text-end">
              <button
                className="flex-shrink-0 btn-primary confirmPurchase "
                onClick={() => setShowModal(true)}
              >
                Confirm Purchase
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
