import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/typography.css';
import '../css/personalInfoFields.css';
import { useEffect } from 'react';
import { valueAreaList } from '../../utils/areaCode';
import Savebutton from '../../shared/button/save';
import Select from 'react-select';
import _ from 'lodash';
import { title } from 'process';
const PersonalInfoFields = ({
  userFields,
  companyFields,
  setUserFields,
  auth,
  handleUpdateUserInfo,
  loading,
}) => {
  const validate = /^[a-zA-Z\s-0-9]*$/;
  const validatePhone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const [titleValidate, setTitleValidate] = useState(false);
  const [fullnameValidate, setFullNameValidate] = useState(false);
  const [phoneValidate, setPhoneValidate] = useState(false);
  const [companyValidate, setCompanyValidate] = useState(false);
  const [countryCode, setCountryCode] = useState(userFields?.countryCode);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isChange, setIsChange] = useState(false);

  const validateField = () => {
    setTitleValidate(validate.test(userFields?.title));
    setFullNameValidate(validate.test(userFields?.fullName));
    setCompanyValidate(validate.test(userFields?.companyName));
    setPhoneValidate(validatePhone.test(userFields?.phoneNumber));
    console.log(title);
    if (
      auth.user.title === userFields.title &&
      auth.user.fullName === userFields.fullName &&
      auth.user.companyName === userFields.companyName &&
      auth.user.phoneNumber === userFields.phoneNumber
    ) {
      setIsChange(false);
    } else {
      setIsChange(true);
    }
    if (
      validate.test(userFields?.title) &&
      validate.test(userFields?.fullName) &&
      validate.test(userFields?.companyName) &&
      validatePhone.test(userFields?.phoneNumber)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleChange = (source) => {
    setUserFields({ ...userFields, [source.target.name]: source.target.value });
    setIsChange(true);
    validateField();
  };

  const handleChangeCountryCode = (source) => {
    setUserFields({
      ...userFields,
      [source.target.name]: source.target.value,
    });
    setCountryCode(source.target.value);
    setIsChange(true);
    validateField();
  };

  useEffect(() => {
    validateField();
  }, [userFields]);
  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <label className="form_label label_fields">Full Name</label>
            <input
              className={
                fullnameValidate && userFields?.fullName !== ''
                  ? 'input_fields p-2'
                  : 'input_fields p-2 input-validation-error'
              }
              type="text"
              name="fullName"
              value={userFields?.fullName}
              onChange={handleChange}
            />
            {!fullnameValidate && (
              <span className="error-input">
                Invalid characters in fullname field
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <label className="form_label label_fields">Title</label>
            <input
              className={
                userFields?.title !== ''
                  ? 'input_fields p-2'
                  : 'input_fields p-2 input-validation-error'
              }
              type="text"
              name="title"
              value={userFields?.title}
              onChange={handleChange}
            />
          </Col>
          <Col md={5}>
            <label className="form_label label_fields">Company</label>
            <Col md={2}>
              <label className="primary">{companyFields.companyName}</label>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <Row>
              <label className="form_label label_fields mt-2">
                Phone Number
              </label>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={4} className="form_phone">
                <select
                  className={
                    countryCode !== ''
                      ? 'input_fields p-2 selectpicker'
                      : 'input_fields p-2 selectpicker input-validation-error'
                  }
                  name="countryCode"
                  value={userFields?.countryCode}
                  onChange={handleChangeCountryCode}
                >
                  <option value=""></option>
                  {valueAreaList.map((value) => (
                    <option key={value.label} value={value.value}>
                      {value.flag} {value.countryCode} {value.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={8}>
                <input
                  className={
                    phoneValidate && userFields?.phoneNumber !== ''
                      ? 'input_fields p-2'
                      : 'input_fields p-2 input-validation-error'
                  }
                  type="tel"
                  value={userFields?.phoneNumber}
                  name="phoneNumber"
                  onChange={handleChange}
                  placeholder="(555)-555-5555"
                />
                {!phoneValidate && (
                  <>
                    <span className="error-input">
                      Please enter your phone number!
                    </span>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row>
              <label className="form_label label_fields mt-2">
                Your Status
              </label>
            </Row>
            <Row>
              {auth.user.admin ? (
                <Col md={2}>
                  <label className="primary">Admin</label>
                </Col>
              ) : (
                <Col md={2}>
                  <label className="primary">
                    {auth.user.companyRole.charAt(0).toUpperCase() +
                      auth.user.companyRole.slice(1)}
                  </label>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-md-end save_button">
            {isChange && (
              <Savebutton
                isDisabled={isDisabled}
                isLoading={loading}
                handleClick={() => {
                  handleUpdateUserInfo();
                  setIsDisabled(true);
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PersonalInfoFields;
