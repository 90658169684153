import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../shared/css/sharedStyles.css';

const AddTeamMemberLink = ({ setOpenAddTeamMember, members, listMembers }) => {
  return (
    <Row className="">
      <Col md={8} xxl={8}>
        <button
          onClick={() => {
            setOpenAddTeamMember(true);
          }}
          className="add-team-member-link m-0 edit-project-text"
        >
          Add Team Member(s)
        </button>
      </Col>
      {members ? (
        <Col
          md={4}
          xxl={4}
          className={`text-center members-icons ${
            listMembers ? 'cursor-pointer' : ''
          }`}
          onClick={() => {
            if (listMembers) {
              listMembers();
            }
          }}
        >
          {members.map((item, index) => {
            if (index === 0) {
              if (members.length > 3) {
                return (
                  <>
                    <span key={index} className="team-memeber-link-badge one">
                      + {members.length - 3}
                    </span>
                    <span key={index} className="team-memeber-link-badge two">
                      {item.fullName
                        .match(/(^\S\S?|\b\S)?/g)
                        .join('')
                        .match(/(^\S|\S$)?/g)
                        .join('')
                        .toUpperCase()}
                    </span>
                  </>
                );
              } else {
                return (
                  <span key={index} className="team-memeber-link-badge two">
                    {item.fullName
                      .match(/(^\S\S?|\b\S)?/g)
                      .join('')
                      .match(/(^\S|\S$)?/g)
                      .join('')
                      .toUpperCase()}
                  </span>
                );
              }
            }
            if (index === 1) {
              return (
                <span key={index} className="team-memeber-link-badge three">
                  {item.fullName
                    .match(/(^\S\S?|\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}
                </span>
              );
            }
            if (index === 2) {
              return (
                <span key={index} className="team-memeber-link-badge four">
                  {item.fullName
                    .match(/(^\S\S?|\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}
                </span>
              );
            }
          })}
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default AddTeamMemberLink;
