import { React, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/assessments.css';
import PieChart from './assessmentsChart';

export default function Assessments({ assessments }) {
  const [assessmentsAnalyze, setAssessmentsAnalyze] = useState({
    inProgress: 0,
    completed: 0,
    participants: 0,
    activeParticipants: 0,
  });
  const [pieData, setPieData] = useState([
    {
      label: 'Fail',
      value: 0,
    },
    { label: 'Success', value: 0 },
  ]);
  const countAssessmentsAnalyze = async () => {
    let reAnalyze = {
      inProgress: 0,
      completed: 0,
      participants: 0,
      activeParticipants: 0,
      fail: 0,
    };
    await assessments.map((item) => {
      reAnalyze.inProgress += item.inProgress;
      reAnalyze.activeParticipants += item.accepted;
      reAnalyze.activeParticipants += item.inProgress;
      reAnalyze.activeParticipants += item.reviewed;
      reAnalyze.fail += item.bounced + item.expired;
      reAnalyze.completed += item.completed + item.reviewed;
      reAnalyze.participants +=
        reAnalyze.activeParticipants + item.new + item.invited;
    });
    setPieData([
      {
        label: 'Fail',
        value: reAnalyze.fail,
      },
      { label: 'Success', value: reAnalyze.completed },
    ]);
    setAssessmentsAnalyze(reAnalyze);
  };
  useEffect(() => {
    countAssessmentsAnalyze();
  }, [assessments]);
  return (
    <Container fluid className="assessments-bg">
      <Row className="h2 p-4">Assessments</Row>
      <Row className="px-4">
        <Col xs={7} md={7}>
          <Row className="small-text assessments-font-style assessments-text">
            In-Progress
          </Row>
          <Row className="assessments-font-style assessments-in-progess-num">
            {assessmentsAnalyze.inProgress}
          </Row>
          <Row className="small-text assessments-font-style assessments-text">
            Completed
          </Row>
          <Row className="assessments-font-style primary assessments-completed-num">
            {assessmentsAnalyze.completed}
          </Row>
        </Col>
        <Col xs={5} md={5}>
          <Row className="small-text assessments-font-style assessments-text">
            Success Rate
          </Row>
          <Row className="assessments-pie-chart-align">
            <PieChart data={pieData} outerRadius={60} innerRadius={36} />
          </Row>
        </Col>
      </Row>
      <Row className="px-4">
        <Col xs={7} md={7}>
          <Row className="small-text assessments-font-style assessments-text">
            Active Participants
          </Row>
          <Row className="assessments-font-style primary assessments-participants-num">
            {assessmentsAnalyze.activeParticipants}
          </Row>
        </Col>
        <Col xs={5} md={5}>
          <Row className="small-text assessments-font-style assessments-text">
            Total Participants
          </Row>
          <Row className="assessments-font-style primary assessments-participants-num">
            {assessmentsAnalyze.participants}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
