import React from 'react';

//COMPONENTS
import { Container, Row } from 'react-bootstrap';
import CandidateResultsSection from './candidateResultsSection';
import CandidateInterViewSection from './candidateInterviewSections';
import CandidateTitleSection from './candidateTitleSection';

const ExpandedCandidateFullView = ({
  candidate,
  setShow,
  setExpandedCandidate,
  tasks,
  sendReviewCandidate,
}) => {
  return (
    <Container
      className="primarybg shadow p-4"
      style={{ borderRadius: '30px', margin: '-92px 0 0 30px ' }}
    >
      <Row className="mb-3">
        <CandidateTitleSection
          candidate={candidate}
          setShow={setShow}
          setExpandedCandidate={setExpandedCandidate}
          sendReviewCandidate={sendReviewCandidate}
        />
      </Row>
      <Row className="mb-3">
        <CandidateInterViewSection assessment={candidate.assessment} />
      </Row>
      <Row>
        <CandidateResultsSection
          tasks={tasks}
          assessment={candidate.assessment}
        />
      </Row>
    </Container>
  );
};

export default ExpandedCandidateFullView;
