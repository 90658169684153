import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SignUpButton from '../../shared/button/signUp';
import PrivacyPolicyModal from '../../shared/modals/privacyPolicyModal';
import TermsOfUseModal from '../../shared/modals/termsOfUseModal';
import { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import '../css/signUpForm.css';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../context/authContext';

const SignUpFormInput = ({
  id,
  label,
  type = 'text',
  placeholder,
  onChange,
  error,
  touched,
}) => {
  return (
    <section className="signup-form-section">
      <Row className="mb-1">
        <Col md={12}>
          <label
            htmlFor={label}
            className="form_label label_fields signup-form-label text-label"
          >
            {label}
          </label>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={12}>
          <input
            className="form_input input_fields px-1"
            id={id}
            placeholder={' ' + placeholder}
            type={type}
            onChange={onChange}
          ></input>
        </Col>
        {touched && Boolean(error) && (
          <span className="error-message m-1">{error}</span>
        )}
      </Row>
    </section>
  );
};

export default function SignUpForm() {
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const { signup } = useAuth();

  const validationSchema = yup.object({
    fullName: yup.string().required('Full Name is required!'),
    title: yup.string().required('Title is required!'),
    companyName: yup.string().required('Company Name is required!'),
    email: yup
      .string()
      .email('Email is not valid!')
      .required('Email is required!'),
    acceptTerms: yup
      .bool()
      .oneOf([true], 'Accept Terms & Conditions is required!'),
  });

  const { handleChange, touched, errors, handleSubmit, setFieldValue, values } =
    useFormik({
      initialValues: {
        fullName: '',
        title: '',
        companyName: '',
        email: '',
        acceptTerms: true,
      },
      validationSchema: validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await signup(values);
        } finally {
          setSubmitting(false);
        }
      },
    });

  const closeModal = () => {
    setTermsOfUse(false);
    setPrivacyPolicy(false);
  };
  useEffect(() => {
    // On load click on the acceptTerms by default
    window.onload = function () {
      document.getElementById('acceptTerms').click();
    };
  });
  return (
    <div className="mx-auto">
      <PrivacyPolicyModal show={privacyPolicy} closeModal={closeModal} />
      <TermsOfUseModal show={termsOfUse} closeModal={closeModal} />
      <Col xs={12} sm={10} className="mx-3 signup-top-section">
        <section className="signup-header">
          <h1 className="h1">Create Account</h1>
          <span className="form_label text-label">
            Already have an account?{' '}
            <Link to="/signin" className="text-decoration-none">
              <span className="signup-get-started text-label">Login</span>
            </Link>
          </span>
        </section>
      </Col>
      <Col xs={12} sm={10}>
        <form onSubmit={handleSubmit}>
          <SignUpFormInput
            id="fullName"
            label={'Full Name'}
            placeholder="Enter your full name"
            onChange={handleChange}
            touched={touched.fullName}
            error={errors.fullName}
          />
          <SignUpFormInput
            id="title"
            label={'Job Title'}
            placeholder="Enter your job title"
            onChange={handleChange}
            touched={touched.title}
            error={errors.title}
          />
          <SignUpFormInput
            id="companyName"
            label={'Company Name'}
            placeholder="Enter your company name"
            onChange={handleChange}
            touched={touched.companyName}
            error={errors.companyName}
          />
          <SignUpFormInput
            id="email"
            label={'Email Address'}
            type={'email'}
            placeholder="Enter your email address"
            onChange={handleChange}
            touched={touched.email}
            error={errors.email}
          />
          <Row className="signup-bottom-section">
            <Col md={12}>
              <div>
                <span className="small-text text-signup">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="acceptTerms"
                    checked={values.acceptTerms}
                    onChange={(e) =>
                      setFieldValue('acceptTerms', e.target.checked)
                    }
                  />{' '}
                  I have read and accepted to{' '}
                </span>
                <span
                  className="primary bold cursor-pointer text-label"
                  onClick={() => setTermsOfUse(true)}
                >
                  Terms of Use
                </span>{' '}
                <span className="text-signup">and</span>{' '}
                <span
                  className="primary bold cursor-pointer text-label"
                  onClick={() => setPrivacyPolicy(true)}
                >
                  Privacy Policy
                </span>
              </div>
            </Col>
            {touched.acceptTerms && Boolean(errors.acceptTerms) && (
              <span className="error-message">{errors.acceptTerms}</span>
            )}
          </Row>
          <Col className=" signup-bottom-float ">
            <SignUpButton />
          </Col>
        </form>
      </Col>
    </div>
  );
}
