import '../css/buttonStyles.css';

export default function EnableTwoFactorButton({ onClickHandler }) {
  return (
    <button
      className="btn-primary-medium"
      type="submit"
      onClick={onClickHandler}
    >
      Verify Token
    </button>
  );
}
