import Modal from 'react-bootstrap/Modal';

export default function deleteConfirmModal({
  show,
  closeModal,
  text,
  handleDelete,
}) {
  return (
    <Modal
      show={show}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="h2 modal-header border-0">
        {' '}
        Do you want to delete {text} ?{' '}
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-between ">
        <button className="btn-secondary" onClick={closeModal}>
          No
        </button>
        <button className="btn-primary" onClick={handleDelete}>
          Yes
        </button>
      </Modal.Body>
    </Modal>
  );
}
