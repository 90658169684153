import React from 'react';
import StepOne from './components/stepOne';
import StepTwo from './components/stepTwo';
import StepThree from './components/stepThree';
import StepFour from './components/stepFour';
import StepFive from './components/stepFive';

import { GigProvider } from '../context/gigContext';

export const BountyCreateSteps = ({
  gig,
  closeModal,
  step,
  setStep,
  editSteps,
  cryptoPay,
  setCryptoPay,
}) => {
  // Proceed to next step
  const nextStep = () => {
    setStep((x) => x + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep((x) => x - 1);
  };

  const resetStep = async () => {
    setStep(1);
  };
  return (
    <GigProvider gig={gig}>
      {step === 1 && editSteps !== 4 && (
        <StepOne nextStep={nextStep} resetStep={resetStep} />
      )}
      {step === 2 && (
        <StepTwo
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
        />
      )}
      {step === 3 && (
        <StepThree
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
        />
      )}
      {/* editStep open the modal to step 4 to pay the unfunded */}
      {(step === 4 || editSteps === 4) && (
        <StepFour
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
          closeModal={closeModal}
          editSteps={editSteps}
          cryptoPay={cryptoPay}
          setCryptoPay={setCryptoPay}
        />
      )}
      {step === 5 && (
        <StepFive
          nextStep={nextStep}
          resetStep={resetStep}
          prevStep={prevStep}
          closeModal={closeModal}
        />
      )}
    </GigProvider>
  );
};
export default BountyCreateSteps;
