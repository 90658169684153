import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

// import { ReactComponent as DropDownIcon } from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import { ReactComponent as ExpansionIcon } from '../../assets/icon/snapbrillia_description_drag.svg';
// import { ReactComponent as CloseIcon } from '../../assets/icon/snapbrillia_close_icon.svg';
import { Form } from 'react-bootstrap';
import '../css/addProjectFields.css';
import '../../shared/css/sharedStyles.css';
import SelectMenu from '../../shared/formItems/selectMenu';

const ProjectFields = ({ setFormValues, formErrors, url, repoInfo }) => {
  const [skills, setSkills] = useState([]);
  const yearsExp = [
    { item: '1', key: 1 },
    { item: '2', key: 2 },
    { item: '3', key: 3 },
    { item: '3+', key: 4 },
  ];
  const projectNameRef = useRef();
  const [exp, setExp] = useState('');
  const [projectForm, setProjectForm] = useState({
    name: '',
    jobTitle: '',
    jobDescription: '',
    experience: '',
    mandatoryskillsTested: [],
    url: '',
  });

  const handleChange = (e) => {
    setProjectForm({
      ...projectForm,
      [e.target.id]: e.target.value,
      url: url,
    });
  };

  const checkSelectLanguage = async () => {
    let languages = [];
    let selectSkill = [];
    if (repoInfo.language) {
      if (typeof repoInfo.language === 'string') {
        languages.push(repoInfo.language);
      } else {
        languages = Object.keys(repoInfo.language);
      }
      let newSkills = [];
      languages.map((skill) => {
        newSkills.push({ name: skill, active: true });
        selectSkill.push(skill);
      });
      setProjectForm({
        ...projectForm,
        mandatoryskillsTested: selectSkill,
      });
      await setSkills(newSkills);
      console.log(skills);
    }
  };
  useEffect(() => {
    setProjectForm({ ...projectForm, experience: exp });
  }, [exp]);

  useEffect(() => {
    projectNameRef.current.focus();
  }, []);

  useEffect(() => {
    checkSelectLanguage();
  }, [repoInfo]);
  useEffect(() => {
    setFormValues(projectForm);
  }, [projectForm]);

  const handleLineBreak = (e) => {
    let value = e.target.value;

    if (value.length === 0) {
      e.target.rows = 1;
    }
    if (e.key === 'Enter' && e.target.rows < 12) {
      e.target.rows += 1;
    }

    if (e.keyCode === 8 && e.target.rows > 1) {
      if (e.target.value[e.target.selectionStart - 1] === '\n') {
        e.target.rows -= 1;
      }
    }
  };

  return (
    <div className="">
      <div className="add-project-form">
        <Form.Group>
          <Row>
            <Col className="add-project-labels">
              <label htmlFor="name">
                Project Name <span className="required">*</span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="add-project-labels">
              <input
                onChange={(e) => handleChange(e)}
                id="name"
                value={projectForm.name}
                type="text"
                ref={projectNameRef}
              />
              <div className="add-project-error">{formErrors.name}</div>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="add-project-labels">
              <label htmlFor="jobTitle">
                Job Title <span className="required">*</span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="add-project-labels">
              <input
                onChange={(e) => handleChange(e)}
                value={projectForm.jobTitle}
                id="jobTitle"
                type="text"
              />
              <div className="add-project-error">{formErrors.jobTitle}</div>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="position-relative">
          <Row>
            <Col className="add-project-labels">
              <label htmlFor="jobDescription">Job Description </label>
            </Col>
          </Row>
          <Row>
            <Col className="add-project-labels">
              <textarea
                onChange={(e) => handleChange(e)}
                value={projectForm.jobDescription}
                onKeyDown={handleLineBreak}
                id="jobDescription"
                rows="1"
                type="text"
              />
            </Col>
          </Row>
          <ExpansionIcon className="expand-icon-add-project" />
        </Form.Group>
        <Form.Group className="form-group-select">
          <Row>
            <Col className="add-project-labels">
              <label htmlFor="years-of-experience">
                How many years of Experience would you like the candidate to
                have? <span className="required">*</span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col
              className="add-project-labels"
              style={{ marginBottom: '8px' }}
              id="years-of-experience"
            >
              <SelectMenu list={yearsExp} value={exp} setValue={setExp} />
            </Col>
          </Row>
          <Row>
            <Col className="add-project-labels">
              <div className="add-project-error">{formErrors.experience}</div>
            </Col>
          </Row>
        </Form.Group>
        <Row>
          <Col className="add-project-labels">
            <label>
              Select the Skills you want the candidate to have already?{' '}
              <span className="required">*</span>
            </label>
          </Col>
        </Row>
        <Row>
          <Col className="add-project-labels">
            <div className="add-project-skills-wrapper">
              {skills.map((skill) => (
                <span
                  key={skill.key}
                  id={skill.name}
                  className={`add-project-skill ${
                    skill.active ? 'active-skill' : ''
                  }`}
                >
                  {skill.name}
                </span>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="add-project-labels">
            <div className="add-project-error">
              {formErrors.mandatoryskillsTested}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ProjectFields;
