import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { PieChartImplementation } from './components/pieChartImplemention';
import { select } from 'd3';
import ChartsBackend from './components/chartsBackend';
import './css/pieChart.css';
import { chartColors } from './components/chartColors';

// information regarding params for this chart can be found in the client-side README.md
// examples can be found in './chartsDemo.jsx'

var barcolors = chartColors;

function colorpicker(length) {
  let i = barcolors.length;
  let colorcode = 0;
  while (i < length) {
    barcolors[i] = `hsla(${colorcode},80%,58%)`;

    if (colorcode % 60 === 0) {
      colorcode = colorcode + 60;
    } else {
      colorcode = colorcode + 120;
    }
    if (colorcode >= 360) {
      colorcode = colorcode - 360 + 15;
      if (colorcode % 60 === 0) {
        colorcode = colorcode + 15;
      }
    }
    i++;
  }
}
const PieChart = ({
  bountyId,
  WSPId,
  status,
  width,
  height,
  innerRadius,
  dataType,
  columns,
  // currentColumn,
  setColumns,
  pieCharTitle,
  // setOpen,
  alignLegend,
  titlePosition,
  showStatus,
  dataType2,
}) => {
  // const {
  //   WSPId,
  //   status,
  //   width,
  //   height,
  //   innerRadius,
  //   dataType,
  //   columns,
  //   currentColumn,
  //   setColumns,
  //   pieCharTitle,
  //   setOpen,
  //   alignLegend,
  //   titlePosition,
  //   showStatus,
  //   dataType2,
  // } = props;
  const { data, axisLabels, axisTitles, chartTitle } = ChartsBackend({
    bountyId,
    WSPId,
    dataType,
    dataType2,
    status,
  });
  const svgRef = useRef();
  const errorRef = useRef();
  const titleRef = useRef();
  const subtitleRef = useRef();
  const mainDivRef = useRef();
  const [isEmpty, setIsEmpty] = useState();

  //set default values in case nothing is passed in
  if (!innerRadius) {
    innerRadius = 0;
  }
  if (!alignLegend) {
    alignLegend = 'left';
  }

  // determine if data is empty
  useEffect(() => {
    let sum = data.reduce((a, b) => a + b, 0);
    if (sum === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [data]);

  useEffect(() => {
    const svg = select(svgRef.current); //Get svg tag
    const title = select(titleRef.current); //Get title tag
    const subtitle = select(subtitleRef.current);
    const mainDiv = select(mainDivRef.current);

    // clear any elements from previous render if any before re-render
    svg.selectAll('*').remove();

    //Values and Statics arrays Length match then show chart
    if (isEmpty === false) {
      colorpicker(axisLabels.length);
      // data is compine object of Values and statics
      var dataJson = [];
      for (let i = 0; i < data.length; i++) {
        dataJson.push({
          statistics: data[i],
          values: axisLabels[i],
        });
      }
      //chart function;
      PieChartImplementation(
        dataJson,
        (3 * width) / 5,
        (3 * height) / 5,
        svg,
        barcolors,
        innerRadius,
        alignLegend
      );
      title.select('h3').remove();
      title.append('h3').text(chartTitle).style('color', barcolors[0]);
      // .style('color', barcolors[2]);
      subtitle.select('span').remove();
      if (status) {
        subtitle
          .append('span')
          .text(status.charAt(0).toUpperCase() + status.slice(1));
      }
      mainDiv.attr('width', width).attr('height', height);
    }
    //Show error
    else {
      const div = select(errorRef.current);
      div
        .append('p')
        .text(
          'Something wrong with your Values and Statics arrays Length does not match Length should be same for both array'
        )
        .style('font-size', width / 60)
        .attr('y', height / 2)
        .style('color', 'Red');
    }
  }, [
    data,
    axisLabels,
    axisTitles,
    chartTitle,
    width,
    height,
    innerRadius,
    alignLegend,
    pieCharTitle,
    isEmpty,
    status,
  ]);

  const centeredHeader = (3 * height) / 5;
  const handleClosePieChart = (currentColumn) => {
    let newCols = [];
    for (let i = 0; i < columns.length; i++) {
      newCols.push(
        columns[i].map((column) => {
          if (currentColumn === column.index) {
            column.fixed = false;
          }
          return column;
        })
      );
    }
    setColumns(newCols);
  };

  const positionedTitleRender = () => {
    if (titlePosition === 'bottom' && alignLegend === 'left') {
      return (
        <div
          className="piechart-header"
          style={{ right: `${(centeredHeader / 2) * 0.55}px` }}
          ref={titleRef}
        ></div>
      );
    } else if (titlePosition === 'bottom' && alignLegend === 'right') {
      return (
        <div
          className="piechart-header"
          style={{ left: `${(centeredHeader / 2) * 0.45}px` }}
          ref={titleRef}
        ></div>
      );
    } else if (titlePosition === 'top') {
      return (
        <div>
          <div style={{ positon: 'absolute', bottom: 0 }} ref={titleRef}></div>
          {showSubtitle()}
        </div>
      );
    }
  };

  const showSubtitle = () => {
    if (showStatus === true) {
      return <span ref={subtitleRef}></span>;
    } else {
      return (
        <div>
          <p></p>
        </div>
      );
    }
  };

  /* if (isEmpty === true) {
    return <h3>No Data to Display</h3>;
  }
 */
  return (
    <>
      <div>
        <div className="main-piechart-div" ref={mainDivRef}>
          {positionedTitleRender()}
          <div className="piechart-imp-div">
            {isEmpty ? <h3>No Data to Display</h3> : null}
            <svg
              ref={svgRef}
              id="PieChart"
              width={(3 * width) / 5}
              height={(3 * height) / 5}
            ></svg>
          </div>
        </div>
      </div>
    </>
  );

  // commenting out for reference
  // return (
  //   <>
  //     <div>
  //       <div className="main-piechart-div" ref={mainDivRef}>
  //         <div className="pie-chart-title-section">
  //           <div>
  //             <span
  //               className="analictycs-select-icon apps-analytics-icon"
  //               id="drag"
  //             ></span>
  //             <span className="analictycs-select-icon share-analytics-icon"></span>
  //             <span
  //               onClick={() => handleClosePieChart(currentColumn)}
  //               className="analictycs-select-icon close-analytics-icon"
  //             ></span>
  //           </div>
  //           <div>
  //             <div
  //               className="piechart-header"
  //               style={{ right: `${(centeredHeader / 2) * 0.6}px` }}
  //               ref={titleRef}
  //             ></div>
  //             {/* <span ref={subtitleRef}></span> */}
  //           </div>
  //         </div>

  //         <div className="piechart-imp-div">
  //           <svg
  //             ref={svgRef}
  //             id="PieChart"
  //             width={(3 * width) / 5}
  //             height={(3 * height) / 5}
  //           ></svg>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default PieChart;
