import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import GreenhouseModalFields from './greenhouseModalFields';
import GreenHouseIcon from '../../assets/integrations/greenhouse_integration_logo.svg';
import '../../shared/css/buttonStyles.css';
import './css/greenhouseModal.css';

export default function GreenhouseModal() {
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => setShowModal(false);

  return (
    <Modal
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      show={showModal}
    >
      <Container className="greenhouse-container">
        <Row>
          <Col>
            <Modal.Header closeButton />
          </Col>
        </Row>

        <Row>
          <Col className="greenhouse-modal-title-section">
            <p className="greenhouse-modal-title">
              <img src={GreenHouseIcon} alt="greenhouse-icon" /> Connect with
              Greenhouse-harvest
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="greenhouse-modal-body">
            <p>
              Greenhouse-harvest requires company-wide authentication before
              other members can login with their personal account. By logging
              in, you are also authorizing other members to connect to
              Greenhouse-harvest.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>
              <GreenhouseModalFields />
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={9}></Col>

          <Col xs={3}>
            <button className="greenhouse-modal-button"> Login</button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
