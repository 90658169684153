import React, { useState, useEffect } from 'react';
import { Modal } from './Modal/Modal.jsx';
import { Stepper } from './Stepper/Stepper.jsx';
import close from '../assets/close.svg';
import { ContextViews } from './context';
const StepperWrapper = ({ closeModal, closeOrNext, type, cryptoPay }) => {
  const [step, setStep] = useState(cryptoPay ? 2 : 1);

  const stepInfos = [
    {
      title: 'Enter payment amount',
      description: 'Please enter the details of your payment',
    },
    {
      title: 'Confirm your payment',
      description: 'Please confirm the details of your payment',
    },
  ];

  const nextStep = () => {
    setStep((x) => x + 1);
  };

  return (
    <ContextViews type={type}>
      <Modal>
        <div className="stepper">
          <div className="stepper__step">
            <div className="first-step">
              <div className="stepper__header-panel header-panel">
                <div className="header-panel__content">
                  {stepInfos[step - 1].description}
                </div>
                <button
                  className="header-panel__button-close"
                  type="button"
                  onClick={() => closeModal()}
                >
                  <img src={close} alt="close icon" />
                </button>
              </div>
              {step === 1 && (
                <div className="first-step__tabs-selector">
                  <div className="tabs">{/* title */}</div>
                </div>
              )}

              <Stepper
                type={type}
                closeOrNext={closeOrNext}
                closeModal={closeModal}
                step={step}
                nextStep={nextStep}
                cryptoPay={cryptoPay}
              />
            </div>
          </div>
        </div>
        {!cryptoPay && (
          <div className="stepper__stepper-steps stepper-steps">
            {stepInfos.map((stepInfo, index) => {
              const stepNumber = index + 1;
              return (
                <div
                  key={index}
                  className={`stepper-steps__step ${
                    stepNumber === step ? 'stepper-steps__step_active' : ''
                  }`}
                  onClick={() => {
                    if (step === 2 && stepNumber === 1) {
                      setStep(1);
                    }
                  }}
                >
                  <div className="step__number">{stepNumber}</div>
                  <div className="step__title">{stepInfo.title}</div>
                </div>
              );
            })}
          </div>
        )}
      </Modal>
    </ContextViews>
  );
};

export { StepperWrapper };
