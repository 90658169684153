import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/comments.css';
import '../../shared/css/typography.css';
import Send from '../../shared/button/send';
import BlueThumbsUp from '../../assets/icon/blue_thumbs_up.svg';
import RedThumbsUp from '../../assets/icon/red_thumbs_down.svg';
// import PaperClip from '../../assets/icon/paper_clip.svg';
import * as discussionApi from '../../api/discussions';
import moment from 'moment';

const Comments = ({ discussion, handleUpdateTopic }) => {
  const [comments, setComments] = useState([]);
  const [commentField, setCommentField] = useState(null);
  // const [validated, setValidated] = useState(false);

  const getAllComment = async () => {
    let data = await discussionApi.getAllCommentByDiscussion(discussion._id);
    setComments(data);
  };
  const handleLike = async () => {
    // deleted comment, type in args
  };
  const createComment = async () => {
    const data = {
      comment: commentField,
      discussionId: discussion._id,
      topicId: discussion.topicId._id,
    };
    const newComment = await discussionApi.createComment(data);
    if (newComment) {
      setCommentField(null);
      setComments([...comments, newComment]);
      if (typeof handleUpdateTopic !== 'undefined') {
        handleUpdateTopic(discussion.topicId, newComment, 'comment');
      }
    }
  };

  const handleComment = async () => {
    if (commentField) {
      await createComment();
    }
  };

  useEffect(() => {
    getAllComment();
  }, [discussion]);

  return (
    <Container className="comments-main-container">
      <Row>
        <Col>
          <p>Comments:</p>
        </Col>
      </Row>
      {comments.map((comment, index) => (
        <Row className="comments-wrapper" key={index}>
          <Col className="comments-row2-col1" md={2}>
            <span className="comments-initials-circle">
              {comment.userId
                ? `${comment.userId?.fullName
                    .match(/(^\S\S?|\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}`
                : `${comment.candidateId?.fullName
                    .match(/(^\S\S?|\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}`}
            </span>
          </Col>
          <Col>
            <div className="comments-content-container">
              <h6 className="comments-user-name">
                {(comment.userId || comment.candidateId)?.fullName}
              </h6>
              <p className="comments-date">
                {moment(comment.createdAt).fromNow()}
              </p>
            </div>
            <p className="comments-text-content">{`${comment.comment}`}</p>
            <img
              src={BlueThumbsUp}
              alt="thumbs-up"
              height={15}
              width={15}
              style={{ marginRight: '10px' }}
              onClick={() => handleLike(comment, 'like')}
            />
            <img
              src={RedThumbsUp}
              alt="thumbs-up"
              height={15}
              width={15}
              style={{ marginRight: '10px' }}
              onClick={() => handleLike(comment, 'dislike')}
            />
            <span className="comments-reply-link">reply</span>
          </Col>
        </Row>
      ))}
      <Row>
        <Col>
          <div className="comments-send-container">
            <input
              type="text"
              placeholder="Comment"
              className="comments-input-text"
              value={commentField || ''}
              onChange={(e) => {
                setCommentField(e.target.value);
              }}
            />
            <span className="comments-paper-clip-span">
              {/* <img
                src={PaperClip}
                alt="paper-clip-icon"
                height={20}
                width={20}
              /> */}
            </span>
            <Send className="comments-send-button" onClick={handleComment} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Comments;
