import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Webcam from 'react-webcam';
import Cropper from 'react-easy-crop';
import Modal from 'react-bootstrap/Modal';
import Save from '../../shared/button/save.jsx';
import '../../shared/css/typography.css';
import * as fileApi from '../../api/files';
import { getImageByUrl, getCroppedImg } from '../images.js';

// add comment for testing bitbucket credentials

export default function ProfileImageModal({
  show,
  closeModal,
  source,
  setSource,
  handleSave,
  isCompany = false,
  handleUpdateUserInfo,
  handleUpdateCompanyInfo,
  handleUpdateCompanyInfoAdmin,
  handleUpdateGig,
}) {
  const [isUpload, setIsUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(source);
  const [avatar, setAvatar] = useState(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII='
  );
  const [step, setStep] = useState(0);
  const hiddenFileInput = useRef(null);
  const [pictureFrame, setPictureFrame] = useState('50%');
  const webcamRef = React.useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log('imageSrc');
    // setAvatar(imageSrc);
    // setAvatar(imageSrc);
    console.log(avatar);
    setSelectedFile(imageSrc);
    setAvatar(imageSrc);
    setStep(3);
  }, [webcamRef]);

  useEffect(() => {
    setAvatar(source);
    setSelectedFile(source);
  }, [source]);

  useEffect(() => {
    setAvatar(source);
    setIsUpload(false);
    backStep();
    setSelectedFile(source);
  }, [!show]);

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
    let reader = new FileReader();
    reader.onload = function (e) {
      setAvatar(e.target.result);
      setStep(1);
      setPictureFrame('5%');
    };
    reader.readAsDataURL(e.target.files[0]);
    setIsUpload(true);
  };
  const handleFileUpload = async () => {
    const canvas = await getCroppedImg(avatar, croppedArea);
    // console.log('canvas', canvas);
    canvas.toBlob(
      async (blob) => {
        const formData = new FormData();
        if (handleUpdateUserInfo) {
          formData.append('avatar', blob);
          if (isCompany) {
            formData.append('isCompany', 'true');
          }

          const { filename } = await fileApi.uploadAvatar(formData, isUpload);
          handleSave(filename);
          setSource(avatar);
          handleUpdateUserInfo();
        }
        if (handleUpdateCompanyInfo) {
          formData.append('type', 'logo');
          formData.append('file', blob);
          const fileInfo = await fileApi.uploadFile(formData, isUpload);
          handleUpdateCompanyInfo(fileInfo);
          setSource(getImageByUrl(fileInfo.url));
        }
        if (handleUpdateCompanyInfoAdmin) {
          formData.append('type', 'logo');
          formData.append('file', blob);
          const fileInfo = await fileApi.uploadLogo(formData, isUpload);
          handleUpdateCompanyInfoAdmin(fileInfo);
          setSource(getImageByUrl(fileInfo.url));
        }
        if (handleUpdateGig) {
          formData.append('type', 'logo');
          formData.append('file', blob);
          formData.append('isGig', 'true');
          const fileInfo = await fileApi.uploadLogo(formData, isUpload);
          handleUpdateGig(fileInfo);
          setSource(getImageByUrl(fileInfo.url));
        }
        closeModal();
      },
      'image/jpeg',
      0.9
    );
  };
  const handleButtonUpload = () => {
    hiddenFileInput.current.click();
  };
  // const handleTakePhoto = () => {
  //   // console.log('handleTakePhoto');
  // };
  const handleLabelClick = () => {
    console.log('handleLabelClick', avatar);
    if (step === 1) {
      handleButtonUpload();
    }
    if (step === 3) {
      setStep(2);
    }
  };
  const backStep = () => {
    setSelectedFile(null);
    setAvatar(source);
    setStep(0);
    setPictureFrame('50%');
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton className="h1">
        {' '}
        Replace Profile Picture{' '}
      </Modal.Header>
      <Modal.Body className="d-flex flex-column modal-dialog-centered">
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileSelect}
          onClick={(e) => (e.target.value = null)}
          style={{ display: 'none' }}
          accept={'image/jpeg, image/png, image/jpg'}
        />
        <label className="paragraph mb-md-5">
          Your Snapbrillia profile picture will display at 32 x 32 pixels with
          an aspect ratio of 1:1. However, in order to maintain a high quality
          profile picture, we recommend uploading at a higher resolution.
        </label>
        {(step < 2 || step === 3) && (
          <>
            <div className="cropper-container">
              <Cropper
                image={avatar}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                showGrid={false}
                objectFit={'auto-cover'}
                onCropComplete={onCropComplete}
                classes={{
                  containerClassName: 'cropper-container-mask',
                }}
              />
              <div className="cropper-mask"></div>
              <div className="cropper-controls">
                <span
                  onClick={() => {
                    if (Number(zoom) > 1.1) {
                      setZoom(`${Number(zoom) - 0.1}`);
                    }
                  }}
                >
                  -
                </span>
                <Form.Range
                  className="cropper-slider"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e) => setZoom(e.target.value)}
                />
                <span
                  onClick={() => {
                    if (Number(zoom) < 3) {
                      setZoom(`${Number(zoom) + 0.1}`);
                    }
                  }}
                >
                  +
                </span>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <Webcam
            audio={false}
            height={400}
            width={400}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              height: 720,
            }}
          />
        )}

        <label className="link pointer" onClick={handleLabelClick}>
          {' '}
          {step === 0
            ? ' '
            : step === 1
            ? ' Use a different image'
            : step === 2
            ? ' '
            : 'Retake'}{' '}
        </label>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {step === 0 && (
          <>
            <button className="btn-primary" onClick={handleButtonUpload}>
              Upload{' '}
            </button>
            <button
              className="signinbuttons"
              onClick={() => {
                setStep(2);
                setPictureFrame('5%');
              }}
            >
              {' '}
              Take Photo{' '}
            </button>
          </>
        )}
        {(step === 1 || step === 3 || step === 4) && (
          <>
            <button className="btn-secondary" onClick={backStep}>
              Cancel{' '}
            </button>
            <Save handleClick={handleFileUpload} />
          </>
        )}
        {step === 2 && (
          <>
            <button className="btn-secondary" onClick={backStep}>
              Cancel{' '}
            </button>
            <button className="btn-primary" onClick={capture}>
              {' '}
              Capture{' '}
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
