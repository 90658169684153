import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

import { Container, Row, Col, Navbar, Offcanvas } from 'react-bootstrap';

//COMPONENTS
import NotFound from '../shared/errors/404';
import Loading from '../shared/Overlays/loading';
import EditBountyLink from '../collectionBounties/components/editBountyLink';
import NavBar from '../searchNavbar/navbar';
import BountyTitle from '../collectionBounties/components/bountyTitle';
import * as jobApi from '../api/opportunities';
import PrimarySidebar from '../shared/sidebar/primarySidebar';
import B2CBountyButton from '../collectionBounties/components/b2cBountyButton';
import '../bounties/css/chat.css';
import JobsViewTabs from './components/JobsViewTabs';

import JobCreateStepsModal from '../shared/modals/JobCreateStepModal';

const CollectionJob = ({ bountyId, action }) => {
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(state ? state.from : false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);
  const [page, setPage] = useState(1);
  const [bounty, setBounty] = useState({});
  const [close, setClose] = useState(true);

  // console.log(bountyId);

  const getBountyInfo = async () => {
    const response = await jobApi.getOpportunity(bountyId);
    // console.log(response);
    if (response) {
      setBounty(response);
    }
  };
  useEffect(() => {
    getBountyInfo().finally(() => setLoading(false));
  }, [bountyId]);

  const handleClose = () => setShowSideBar(false);

  const closeModal = () => {
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (!openEditModal) {
      setPage(1);
    }
  }, [openEditModal]);

  return (
    <Container fluid style={{ overflow: 'hidden' }}>
      <Row className="d-flex flex-row flex-nowrap vw-100">
        <PrimarySidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          component="bounties"
        />
        <Col
          xs={12}
          md={10}
          lg={10}
          style={{
            width: collapsed ? '93vw' : null,
            transition: 'all 0.8s ease-in-out',
            zIndex: 100,
          }}
          className="bounties_main_panel primary-main-panel pagemainbg"
        >
          {loading && <Loading />}
          {!loading && bounty && (
            <>
              <Row className="align-items-center my-4 my-xxl-3">
                <Col md={1} xs={2} className="text-center d-lg-none">
                  <Navbar expand="lg">
                    <Navbar.Toggle
                      onClick={() => setShowSideBar(true)}
                      aria-controls="navbarScroll"
                    />
                  </Navbar>
                  <Offcanvas show={showSideBar} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>NavBar</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>Navbar&apos;s body</Offcanvas.Body>
                  </Offcanvas>
                </Col>
                <Col xs={6} md={3} lg={4} xxl={3} className="ps-5">
                  <BountyTitle bountyTitle={bounty.name} />
                </Col>
                {/* <Col md={3} lg={2} xxl={3}> */}
                {/* <AddTeamMemberLink setOpenAddTeamMember={setOpenAddTeamMember} />
              {openAddTeamMember && (
                <AddTeamMemberModal
                  openAddTeamMember={openAddTeamMember}
                  setOpenAddTeamMember={setOpenAddTeamMember}
                />
              )} */}
                {/* </Col> */}
                <Col
                  md={5}
                  className="bounty_status_bar d-none d-sm-none d-md-block d-lg-block"
                >
                  {close && <NavBar />}
                </Col>
              </Row>

              <Row className="mx-3 mt-4">
                <Col>
                  <JobsViewTabs {...{ bounty, action, bountyId }} />
                </Col>
              </Row>
            </>
          )}
          {!loading && !bounty && (
            <>
              <Row className="align-items-center my-4 my-xxl-3">
                <Col md={1} xs={2} className="text-center d-lg-none">
                  <Navbar expand="lg">
                    <Navbar.Toggle
                      onClick={() => setShowSideBar(true)}
                      aria-controls="navbarScroll"
                    />
                  </Navbar>
                  <Offcanvas show={showSideBar} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>NavBar</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>Navbar&apos;s body</Offcanvas.Body>
                  </Offcanvas>
                </Col>
                <Col
                  md={5}
                  className="bounty_status_bar d-none d-sm-none d-md-block d-lg-block"
                >
                  <NavBar />
                </Col>
              </Row>
              <Row className="mx-3 mt-4">
                <Col>
                  <NotFound />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CollectionJob;
