import { Row, Col, Container } from 'react-bootstrap';
import grantsImage from '../../assets/openSource.png';
//CSS
//import '../../bounties/css/improveToolTip.css';

export default function onChainGrantsView({ grants }) {
  return (
    <Container>
      <h4 className="bold ">Grants</h4>
      {Object.entries(grants).map((grant) =>
        grant[1] === true ? (
          <Row
            key={Math.random().toString(32)}
            className="align-items-center primarybg rounded mb-3"
          >
            <Col xs={2} className="me-2">
              <img
                src={grantsImage}
                alt="profile"
                height={50}
                width={50}
                style={{ borderRadius: '50%' }}
              />
            </Col>
            <Col xs={6}>
              <span className="grey-title bold primary">
                Decentralized Reputation Engine
              </span>
              <span className="grey-title d-block">Creator</span>
            </Col>
            <Col className="text-end">
              <span className="d-block grey-title">test</span>
              <span className="grey-title brand-primary bold">$5.00</span>
            </Col>
          </Row>
        ) : null
      )}
    </Container>
  );
}
