import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import OnChainIcon from '../../../assets/icon/snapbrillia_on_chain_icon.svg';
import ResumeIcon from '../../../assets/icon/snapbrillia_resume_icon.svg';
import Google from '../../../assets/icon/snapbrillia_google_actual_icon.png';
import Github from '../../../assets/icon/snapbrillia_github_actual_icon.svg';
import Linkedin from '../../../assets/icon/snapbrillia_linkedin_actual_icon.svg';
import Discord from '../../../assets/icon/snapbrillia_discord_icon.png';
import '../../css/buttonComponent.css';
import { snapbrilliaFile } from '../../../api/files';
import OnChainResumeModal from '../../../onchainReputation/onChainResumeModal';

export default function ButtonComponent({ candidate }) {
  const [showOnChainResume, setShowOnChainResume] = useState(false);
  const openInNewTab = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const closeOnChainResume = () => {
    setShowOnChainResume(false);
  };
  return (
    <Container className="bg-light p-3 mb-3">
      <Row className="mb-1 d-flex justify-content-between">
        <Col>
          <div className="d-flex flex-column justify-content-between ">
            <div
              className={`button-component-subtitle text-muted ${
                candidate.candidateId.resume ? ' cursor-pointer' : ''
              } mb-3`}
              onClick={() =>
                candidate.resume
                  ? openInNewTab(snapbrilliaFile(candidate.candidateId.resume))
                  : false
              }
            >
              <img src={ResumeIcon} width={40} alt="resume-icon" />
              <span className="mentor-conf-modal-resume-btn-fs border-0 primary semi-bold text-wrap p-2 text-decoration-none bold">
                Resume
              </span>
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-column justify-content-between ">
            <div
              className="button-component-subtitle text-muted  mb-3"
              onClick={() => {
                setShowOnChainResume(true);
              }}
            >
              <img src={OnChainIcon} width={40} alt="resume-icon" />
              <span className="mentor-conf-modal-resume-btn-fs border-0 primary semi-bold text-wrap p-2 text-decoration-none bold">
                On-Chain Reputation
              </span>
            </div>
            <OnChainResumeModal
              id={candidate._id}
              handleClose={closeOnChainResume}
              showModal={showOnChainResume}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-between">
        <Col>
          <div className="d-flex flex-column justify-content-between ">
            {candidate.githubId && (
              <div
                className={`button-component-subtitle text-muted ${
                  candidate.githubId ? ' cursor-pointer' : ''
                } mb-3`}
                onClick={() => openInNewTab(candidate.candidateId.github)}
              >
                <img src={Github} width={40} alt="resume-icon" />
                <span className="mentor-conf-modal-resume-btn-fs border-0 primary semi-bold text-wrap p-2 text-decoration-none bold">
                  Github &nbsp;
                  <span
                    className={` ${
                      candidate.candidateId.githubId
                        ? 'text-success'
                        : 'text-muted'
                    }`}
                  >
                    {candidate.candidateId.githubId
                      ? '(Connected)'
                      : '(Not Connected)'}
                  </span>
                </span>
              </div>
            )}
            {candidate.candidateId.googleId && (
              <div
                className={`button-component-subtitle text-muted ${
                  candidate.candidateId.googleId ? '' : ''
                } mb-3`}
              >
                <img src={Google} width={40} alt="resume-icon" />
                <span className="mentor-conf-modal-resume-btn-fs border-0 primary semi-bold text-wrap p-2 text-decoration-none bold">
                  Google &nbsp;
                  <span
                    className={` ${
                      candidate.candidateId.googleId
                        ? 'text-success'
                        : 'text-muted'
                    }`}
                  >
                    {candidate.candidateId.googleId
                      ? '(Connected)'
                      : '(Not Connected)'}
                  </span>
                </span>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-column justify-content-between ">
            {candidate.candidateId.linkedinId && (
              <div
                className={`button-component-subtitle text-muted ${
                  candidate.candidateId.linkedinId ? ' cursor-pointer' : ''
                } mb-3`}
                onClick={() => {
                  if (candidate.candidateId.linkedin) {
                    openInNewTab(candidate.candidateId.linkedin);
                  }
                }}
              >
                <img src={Linkedin} width={40} alt="resume-icon" />
                <span className="mentor-conf-modal-resume-btn-fs border-0 primary semi-bold text-wrap p-2 text-decoration-none bold">
                  Linkedin &nbsp;
                  <span
                    className={` ${
                      candidate.candidateId.linkedinId
                        ? 'text-success'
                        : 'text-muted'
                    }`}
                  >
                    {candidate.candidateId.linkedinId
                      ? '(Connected)'
                      : '(Not Connected)'}
                  </span>
                </span>
              </div>
            )}
            {candidate.candidateId.discordId && (
              <div
                className={`button-component-subtitle text-muted ${
                  candidate.candidateId.discordId ? ' cursor-pointer' : ''
                } mb-3`}
              >
                <img src={Discord} width={40} alt="resume-icon" />
                <span className="mentor-conf-modal-resume-btn-fs border-0 primary semi-bold text-wrap p-2 text-decoration-none bold">
                  Discord &nbsp;
                  <span
                    className={` ${
                      candidate.candidateId.discordId
                        ? 'text-success'
                        : 'text-muted'
                    }`}
                  >
                    {candidate.candidateId.discordId
                      ? '(Connected)'
                      : '(Not Connected)'}
                  </span>
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
