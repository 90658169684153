import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { format } from 'date-fns';
import '../css/candidateTitleSection.css';
import ProjectIcon from '../../assets/icon/snapbrillia_softwareengineerproject_icon.svg';
import CloseIcon from '../../assets/icon/snapbrillia_close_icon.svg';
import ShareIcon from '../../assets/icon/snapbrillia_share_icon.svg';
import EditIcon from '../../assets/icon/snapbrillia_edit_icon.svg';
import DropDownIcon from '../../assets/icon/snapbrillia_dropdown_icon.svg';

const CandidateTitleSection = ({
  setShow,
  setExpandedCandidate,
  sendReviewCandidate,
  candidate,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  useEffect(() => {
    setSelectedOption(
      ['great-fit', 'good-fit', 'maybe', 'reject'].includes(
        candidate.assessment.result
      )
        ? candidate.assessment.result
        : ''
    );
  }, [candidate]);
  const handleClose = () => {
    setShow(false);
    setExpandedCandidate(null);
  };
  const handleSelectedOption = (value) => {
    setSelectedOption(value);
    sendReviewCandidate(candidate, value);
  };
  return (
    <Container>
      <Row>
        <Col>
          <Row className="align-items-center position-relative">
            <Col md={4} lg={3} xxl={2} className="me-md-2">
              <p className="candidate-title-profile fs-2">
                {candidate.fullName
                  .match(/(^\S\S?|\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </p>
            </Col>
            <Col md={12} xxl={9}>
              <h3>{`${candidate.firstName} ${candidate.lastName}`}</h3>
              <p className="d-inline me-3">{candidate.assessment.status}</p>
              <img
                src={ProjectIcon}
                alt="project-icon"
                className="candidate-title-suitcase-icon"
              />
              <span>{`${candidate.projects.length} ${
                candidate.projects.length === 1 ? 'Project' : 'Projects'
              }`}</span>
              <p className="grey-title fs-6 mb-2">
                Date applied:{' '}
                {format(new Date(candidate.createdAt), 'MM/dd/yyyy')}
              </p>
              <span className="primary bold">This candidate is a: </span>
              <select
                className="title-options ms-lg-1"
                name="review"
                value={selectedOption}
                onChange={(e) => handleSelectedOption(e.target.value)}
                disabled={candidate.assessment.status === 'in-progress'}
              >
                <option disabled value="">
                  Select
                </option>
                <option value="great-fit">Great Fit</option>
                <option value="good-fit">Good Fit</option>
                <option value="maybe">Maybe</option>
                <option value="reject">Reject</option>
              </select>
              <img
                src={DropDownIcon}
                alt="dropdown-icon"
                style={{
                  marginLeft: '-20px',
                  marginBottom: '3px',
                  cursor: 'pointer',
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col md="auto" xxl={4} className="text-end">
          <Col className="mb-5 mt-2">
            <img
              src={ShareIcon}
              alt="share-icon"
              className="candidate-title-share-icon"
            />
            <img
              src={EditIcon}
              alt="edit-icon"
              className="candidate-title-edit-icon"
            />
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={handleClose}
              className="candidate-title-close-icon"
            />
          </Col>

          <Col>
            <button className="btn-primary">Re-Invite</button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default CandidateTitleSection;
