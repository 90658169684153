import '../../shared/css/typography.css';

const ProjectTitle = ({ workSampleProject }) => {
  return (
    <h2 className="h2 m-0" data-testid="title">
      {workSampleProject.jobTitle || 'SoftWare Engineer'}
    </h2>
  );
};

export default ProjectTitle;
