import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactComponent as Edit } from '../../assets/icon/snapbrillia_edit_icon.svg';
import Form from 'react-bootstrap/Form';
import SubmitButton from '../../shared/button/submitButton';
import { useAuth } from '../../context/authContext';
import { navigate } from '@reach/router';

const DisplayEmail = ({ email, onEditClickHandler }) => {
  return (
    <Container>
      <Col>
        <Row>
          <Col>
            <label className="grey-title bold"> Email </label>
          </Col>
        </Row>
        <Row className="mt-3 mb-4">
          <div className="profile_edit" onClick={(e) => onEditClickHandler(e)}>
            <Row className="edit_email">
              <Col>
                <label className="paragraph"> {email} </label>
              </Col>
              <Col>
                <Edit />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Row>
      </Col>
    </Container>
  );
};

const EditEmail = ({ email, onEditDoneHandler, onChangeHandler }) => {
  return (
    <Container>
      <Row>
        <Col>
          <label className="grey-title bold"> Email </label>
        </Col>
      </Row>
      <Row>
        <Form>
          <Form.Control
            type="text"
            placeholder={email}
            onChange={(e) => onChangeHandler(e.target.value)}
          />
        </Form>
      </Row>
      <Row>
        <div className="col-md-12 text-center">
          <SubmitButton
            text="Change Email"
            onClickHandler={onEditDoneHandler}
          />
        </div>
      </Row>
    </Container>
  );
};

const ChangeEmail = ({ updatableEmail }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [email, setEmail] = useState('');
  const { changeEmail } = useAuth();

  const onEditEmailClicked = (e) => {
    e.preventDefault();
    setShowEdit(true);
  };
  const onEditEmailFinished = (e) => {
    e.preventDefault();
    changeEmail(email, () => {
      setShowEdit(false);
      navigate('/signin');
    });
  };

  useEffect(() => {
    setEmail(updatableEmail);
    setShowEdit(false);
  }, []);

  return (
    <Container className="mx-auto">
      {showEdit ? (
        <EditEmail
          email={email}
          onChangeHandler={setEmail}
          onEditDoneHandler={onEditEmailFinished}
        />
      ) : (
        <DisplayEmail email={email} onEditClickHandler={onEditEmailClicked} />
      )}
    </Container>
  );
};
export default ChangeEmail;
