import Modal from 'react-bootstrap/Modal';

export default function showInvitationModal({
  show,
  invitation,
  handleJoin,
  handleSkip,
  loading = false,
}) {
  return (
    <Modal
      show={show}
      onHide={handleSkip}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="h2 modal-header border-0">
        {' '}
        You Are Invited to Join {invitation?.companyId?.companyName} as{' '}
        {invitation?.companyRole}, Do you want to join?{' '}
      </Modal.Header>
      <Modal.Body className="initial-flex justify-content-between ">
        <button
          className="btn-secondary"
          onClick={handleSkip}
          disabled={loading}
        >
          Skip
        </button>
        <button className="btn-primary" onClick={handleJoin} disabled={loading}>
          {loading ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            `Join`
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
}
