import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
//Components
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import SuitCaseIcon from '../../assets/icon/snapbrillia_softwareengineerproject_icon.svg';
//CSS
import '../../shared/css/sharedStyles.css';
import '../css/candidateListItem.css';

const CandidateListItem = ({
  candidate,
  handleExpandCandidate,
  handleClickInvite,
}) => {
  const [isCheked, setIsChecked] = useState(false);
  const [ranadomColor, setRandomColor] = useState('');
  const [progress] = useState(
    !['new', 'invited', 'accepted'].includes(candidate.assessment.status)
      ? `100`
      : '0'
  );
  const randomBgColor = () => {
    let value = () => Math.floor(Math.random() * 256);
    return `rgb(${value()},${value()},${value()})`;
  };

  useEffect(() => {
    setRandomColor(randomBgColor());
  }, []);
  return (
    <Container>
      <Row className="my-4 align-items-center justify-content-center">
        <Col md={6} lg={5} xl={5} xxl={4}>
          <Row className="text-md-center text-xl-start align-items-center">
            <Col className="col-1 custom-check-box me-md-1">
              <input
                type="checkbox"
                checked={isCheked}
                onChange={() => setIsChecked(!isCheked)}
              />
            </Col>
            <Col className="position-relative col-1 me-lg-3">
              {candidate.assessment?.type &&
                candidate.assessment?.type === 'live' && (
                  <span className="status">Live</span>
                )}
              <p
                className="candidate-list-item-profile"
                style={{ backgroundColor: `${ranadomColor}` }}
              >
                {candidate.fullName
                  .match(/(^\S\S?|\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </p>
            </Col>
            <Col
              className="ms-lg-4 ms-md-3  pointer"
              md={8}
              lg={7}
              xl={7}
              xxl={7}
            >
              <span className="fs-5 primary bold d-block m-0">{`${candidate.firstName} ${candidate.lastName}`}</span>
              <p className="grey-title fs-6 me-2 d-md-inline mb-lg-0">
                {candidate.assessment.status}
              </p>
              <img src={SuitCaseIcon} alt="suit-case-icon" className="mb-1" />
              <span>
                {`${candidate?.projects ? candidate?.projects?.length : 1} `}
                {`${
                  candidate?.projects
                    ? candidate?.projects.length > 1
                      ? 'Projects'
                      : 'Project'
                    : 'Project'
                }`}
              </span>
            </Col>
          </Row>
        </Col>
        <Col md={2} lg={3} xl={3}>
          <Row>
            <Col className="grey-title fs-6">Score</Col>
            <Col className="h5 bold text-end m-0 mt-1">{progress} %</Col>
          </Row>
          <ProgressBar style={{ height: '10px' }} now={progress} />
        </Col>
        <Col md={2} lg={2} xl={2} className="text-center">
          <p className="grey-title fs-6 m-0">Date Applied:</p>
          <span className="semi-bold ms-1">
            {!['new'].includes(candidate.assessment.status) &&
              format(new Date(candidate.assessment.createdAt), 'MM/dd/yyyy')}
          </span>
        </Col>
        <Col md={2} lg={2} className="text-center">
          {['in-progress', 'completed', 'reviewed'].includes(
            candidate.assessment.status
          ) ? (
            <button
              className="responsive-list-items-button"
              onClick={(e) => handleExpandCandidate(e, candidate)}
            >
              Review
            </button>
          ) : ['new'].includes(candidate.assessment.status) ? (
            <button
              className="responsive-list-items-button"
              onClick={() => handleClickInvite(candidate)}
            >
              Invite
            </button>
          ) : (
            <button
              className="responsive-list-items-button"
              onClick={() => handleClickInvite(candidate)}
            >
              Re-Invite
            </button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CandidateListItem;
