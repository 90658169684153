import '../css/buttonStyles.css';
import { Spinner } from 'react-bootstrap';

export default function InviteButton({
  className,
  handleClick,
  isLoading = false,
  title,
}) {
  return (
    <button
      className={`btn-primary ${className} `}
      onClick={handleClick}
      type="button"
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        title || 'Invite'
      )}
    </button>
  );
}
