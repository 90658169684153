import { useState, useEffect, useCallback } from 'react';
import * as ProjectApi from '../../api/projects';
import * as BountyApi from '../../api/bounties';

function ResponseRateData(
  bountyId,
  WSPId,
  dataType,
  setData,
  setAxisLabels,
  setAxisTitles,
  setChartTitle
) {
  // list assessment phases and the candidates within these phases for response rate
  const [summary, setSummary] = useState();
  // fetch candidate response rate from backend
  const getCandidateResponseRate = useCallback(async () => {
    try {
      if (bountyId) {
        if (bountyId === 'all') {
          const response = await BountyApi.getBountiesSummary();
          setSummary(response);
        } else {
          const response = await BountyApi.getBountySummary(bountyId);
          setSummary(response);
        }
      } else if (WSPId) {
        const { data: responseRate } = await ProjectApi.getProjectSummary(
          WSPId
        );
        setSummary(responseRate);
      }
    } catch (err) {}
  }, [WSPId, bountyId]);

  useEffect(() => {
    getCandidateResponseRate();
  }, [getCandidateResponseRate]);

  useEffect(() => {
    // use optional chaining (?.) to avoid short-circuiting the code if summary is undefined (which it will be on load until data is fetched)
    // const all = summary?.all;
    const invited =
      summary?.invited +
      summary?.viewed +
      summary?.accepted +
      summary?.inProgress +
      summary?.completed +
      summary?.reviewed;
    const viewed =
      summary?.viewed +
      summary?.accepted +
      summary?.inProgress +
      summary?.completed +
      summary?.reviewed;
    const accepted =
      summary?.accepted +
      summary?.inProgress +
      summary?.completed +
      summary?.reviewed;
    const completed = summary?.completed + summary?.reviewed;
    const bouncedEmails = summary?.bounced;
    const expired = summary?.expired;
    const invitedCandidates = Math.round(invited) || 0;
    const emailOpened = Math.round(viewed) || 0;
    const acceptedLink = Math.round(accepted) || 0;
    const needReview = Math.round(completed) || 0;
    const bouncedStatus = Math.round(bouncedEmails) || 0;
    const expiredStatus = Math.round(expired) || 0;

    setData([
      invitedCandidates,
      emailOpened,
      acceptedLink,
      needReview,
      bouncedStatus,
      expiredStatus,
    ]);
    setAxisLabels([
      'Invited',
      'Email Opened',
      'Clicked Link',
      'Completed',
      'Bounced',
      'Expired',
    ]);
    setAxisTitles(['% Response Rates', 'Assessment Phases']);
    setChartTitle('Response Rate');
  }, [dataType, setAxisLabels, setAxisTitles, setChartTitle, setData, summary]);
}

export default ResponseRateData;
