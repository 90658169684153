import React, { useState, useEffect } from 'react';
import CompanyProfilePhoto from './companyProfilePhoto';
import CompanyProfileData from './companyProfileData';
import AddMemberButton from '../../shared/button/addMember';
import CompanyProfileMemberTable from './companyProfileMemberTable';
import MemberModalAPI from '../../memberModal/components/memberModalAPI';
import AddTeamMemberModal from '../../memberModal/addTeamMemberModal';
import * as companyAPI from '../../api/companies';
import { useAuth } from '../../context/authContext';
import EditMemberModal from '../../memberModal/editMemberModal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
const CompanyUser = () => {
  const { auth } = useAuth();

  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [editMember, setEditMember] = useState(null);
  const [members, setMembers] = useState([]);

  const canUserUpdate = async () => {
    if (auth.user.companyRole !== 'owner' || !auth.user.admin) {
      toast.warning('You do not have permission!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return false;
    }
    return true;
  };
  const handleOpenEdit = async () => {
    if (!canUserUpdate()) {
      setShowEditModal(true);
    }
  };
  const handleRemove = async () => {
    try {
      if (!canUserUpdate()) {
        return;
      }
      await companyAPI.removeCompanyLogo(auth.company._id);
    } catch (error) {}
    setCompanyLogo('');
  };

  const handleSave = async (fileName) => {
    try {
      if (!canUserUpdate()) {
        return;
      }
      await companyAPI.updateCompanyLogo(companyInfo._id, { logo: fileName });
    } catch (error) {}
  };

  const handleUpdateCompanyInfo = async (fileInfo) => {
    try {
      if (!canUserUpdate()) {
        return;
      }
      await companyAPI.updateCompanyLogo(companyInfo._id, {
        logo: fileInfo.url,
      });
      const company = await companyAPI.getCompany(auth.company._id);
      setCompanyLogo(company.company.companyLogo);
      toast.success('Your company information has been updated', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } catch (error) {}
  };

  const setUpDate = async () => {
    const company = await companyAPI.getCompany(auth.company._id);
    setCompanyInfo(company.company);
    setCompanyLogo(company.company.companyLogo);
    const memberData = await companyAPI.getMember(auth.company._id);
    setMembers(memberData);
    const pendingMembers = await companyAPI.getPendingMember(auth.company._id);
    setMembers([...memberData, ...pendingMembers]);
  };

  const handleUpdate = async (source) => {
    if (companyInfo[source.target.name] === source.target.value) {
      return;
    }
    await companyAPI.updateCompanyInfo(companyInfo._id, {
      [source.target.name]: source.target.value,
    });
    setCompanyInfo({
      ...companyInfo,
      [source.target.name]: source.target.value,
    });
  };

  useEffect(() => {
    setUpDate();
  }, []);

  const showModal = () => {
    if (!canUserUpdate()) {
      setShow(true);
    }
  };
  return (
    <Container className="company_user_masked_overflow">
      <Row>
        <Col xs={7} className="h1 personal_title col-md-5">
          Company Info
          <Col className="company_photo">
            <CompanyProfilePhoto
              auth={auth}
              companyLogo={companyLogo}
              setCompanyLogo={setCompanyLogo}
              handleSave={handleSave}
              handleRemove={handleRemove}
              handleUpdateCompanyInfo={handleUpdateCompanyInfo}
            />
          </Col>
        </Col>
        <Col>
          <span className="company_profile">
            <CompanyProfileData
              auth={auth}
              companyInfo={companyInfo}
              handleUpdate={handleUpdate}
              setCompanyInfo={setCompanyInfo}
            />
          </span>
        </Col>
      </Row>

      <div className="company_table_div ">
        <div className="h1 company_table_title">Team Members</div>
        <div className="add_member_button">
          <AddMemberButton handleClick={showModal} />
          {show && (
            <MemberModalAPI
              companyId={companyInfo._id}
              members={members}
              setMembers={setMembers}
            >
              <AddTeamMemberModal
                openAddTeamMember={show}
                setOpenAddTeamMember={setShow}
              />
            </MemberModalAPI>
          )}
        </div>
        <div className="company_table">
          <CompanyProfileMemberTable
            companyId={companyInfo._id}
            members={members}
            setMembers={setMembers}
            setEditMember={setEditMember}
            handleShowModal={handleOpenEdit}
          />
          {showEditModal && (
            <MemberModalAPI
              companyId={companyInfo._id}
              members={members}
              memberId={editMember._id}
              setMembers={setMembers}
              handleClose={() => setShowEditModal(false)}
            >
              <EditMemberModal
                show={showEditModal}
                setShow={() => setShowEditModal(false)}
              />
            </MemberModalAPI>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CompanyUser;
