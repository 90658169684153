import React, { createContext, useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';
import { useAuth } from '../context/authContext';
import * as BountyApi from '../api/bounties';
import * as ProjectApi from '../api/projects';
import * as MemberApi from '../api/members';
const OnboardingContext = createContext();
const useOnBoarding = () => useContext(OnboardingContext);

const OnboardingProvider = ({ children }) => {
  const { auth } = useAuth();
  const [user, setUser] = useState();
  const [url, setUrl] = useState('');
  const [repoInfo, setRepoInfo] = useState({});
  const [onboardingType, setOnboardingType] = useState('Bounty');
  const [assessmentType, setAssessmentType] = useState('');

  useEffect(() => {
    if (auth) {
      setUser(auth.user);
    }
  }, [auth]);

  const [workSampleProject, setWorkSampleProject] = useState({
    _id: '',
  });

  const [bounty, setBounty] = useState({
    _id: '',
  });

  const createWorkSampleProject = async (formData) => {
    const parsedGithubUrl = url;
    const parsedGithubID = repoInfo._id;
    const parsedGithubRepoName = `Snapbrillia/${repoInfo.name}`;
    const body = {
      ...formData,
      workSampleProjectName: formData.name,
      githubUrl: parsedGithubUrl,
      githubID: parsedGithubID,
      githubRepoName: parsedGithubRepoName,
    };
    const { project } = await ProjectApi.createProject(body);
    setWorkSampleProject(project);
    navigate('/setup-candidate');
  };

  const createBounty = async (formData) => {
    const body = {
      ...formData,
      type: assessmentType.toLowerCase(),
      url: url,
    };
    const { bounty } = await BountyApi.createBounties(body);
    setBounty(bounty);
    navigate('/setup-candidate');
  };

  const addMembers = async (members) => {
    const companyId = user.companyId;
    const values = members.filter((x) => x.fullName && x.email);
    if (values.length) {
      await MemberApi.inviteMember(companyId, values);
    }
    navigate('/setup-assessment');
  };

  const addCandidate = async (formData) => {
    if (bounty && bounty._id) {
      const body = {
        ...formData,
        bountyId: bounty._id,
      };
      const candidate = await BountyApi.addCandidate(body);
      return candidate;
    }
    if (workSampleProject && workSampleProject._id) {
      const body = {
        ...formData,
        candidateEmail: formData.email,
        workSampleProjectId: workSampleProject._id,
      };
      const candidate = await ProjectApi.addCandidate(body);
      return candidate;
    }
    // Navigate to the dashboard when user don't have any bounty or project in onBoarding.
    navigate('/dashboard');
  };

  const reInviteCandidate = async (bounty, user, isReInvite) => {
    if (bounty && bounty._id && isReInvite) {
      const body = {
        ...user,
        bountyId: bounty._id,
      };
      const candidate = await BountyApi.addCandidate(body, isReInvite);
      return candidate;
    }
  };
  const uploadResume = async (file) => {
    const formData = new FormData();
    formData.append('resume', file);
    if (bounty && bounty._id) {
      formData.append('bountyId', bounty._id);
      return;
    }
    if (workSampleProject && workSampleProject._id) {
      formData.append('workSampleProjectId', workSampleProject._id);
      await ProjectApi.uploadResume(formData);
      return;
    }
    navigate('/dashboard');
  };

  const bulkUploadCandidate = async (candidates) => {
    const body = {
      candidates,
    };
    if (bounty && bounty._id) {
      body.bountyId = bounty._id;
      return;
    }
    if (workSampleProject && workSampleProject._id) {
      body.workSampleProjectId = workSampleProject._id;
      await ProjectApi.bulkCandidateUpload(body);
      return;
    }
    navigate('/dashboard');
  };

  const skipOnboarding = async () => {
    navigate('/dashboard');
  };

  return (
    <OnboardingContext.Provider
      value={{
        addMembers,
        skipOnboarding,
        setUrl,
        url,
        assessmentType,
        setAssessmentType,
        onboardingType,
        setOnboardingType,
        workSampleProject,
        createWorkSampleProject,
        createBounty,
        addCandidate,
        setBounty,
        bounty,
        setRepoInfo,
        repoInfo,
        bulkUploadCandidate,
        uploadResume,
        reInviteCandidate,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingProvider, OnboardingContext, useOnBoarding };
