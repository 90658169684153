import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../shared/css/typography.css';
import '../css/featuredBounties.css';

import Clock from '../../assets/icon/snapbrillia_clock_icon.svg';
import Community from '../../assets/icon/snapbrillia_community_icon.svg';

export default function FeaturedBounties() {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Featured Bounties</h3>
        </Col>
      </Row>
      <Row>
        <Col className="bounties left-bounty">
          <Row>
            <Col>
              <span className="bounty-title">
                Agoric-Axelar Cross-Chain Bounty
              </span>
            </Col>
            <Col md={4}>
              <Row>
                <span className="reward-title right-column">
                  Bounty Reward:
                </span>
              </Row>
              <Row>
                <span className="reward-amount right-column">$9604.80</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={Clock} alt="clock-icon" />
              <span className="bounty-link">Agoric</span>
            </Col>
            <Col md={4} className="right-column">
              <span className="view-bounty-link">View Bounty</span>
            </Col>
          </Row>
        </Col>
        <Col className="bounties right-bounty">
          <Row>
            <Col>
              <span className="bounty-title">
                Improve Tooltip and Clickable Links
              </span>
            </Col>
            <Col md={4}>
              <Row>
                <span className="reward-title right-column">
                  Bounty Reward:
                </span>
              </Row>
              <Row>
                <span className="reward-amount right-column">$9604.80</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={Community} alt="community-icon" />
              <span className="bounty-link">Hyperledger-labs</span>
            </Col>
            <Col md={4} className="right-column">
              <span className="view-bounty-link right-column">View Bounty</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
