import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../assets/snapbrillia_logo_white.svg';
import ColoredLogo from '../assets/SnapBetaLogo2.svg';
import ValidateForm from './components/validateForm';
import '../shared/css/bgColors.css';
import '../shared/css/textColors.css';
import '../shared/css/typography.css';
import { Link } from '@reach/router';

export default function Validate() {
  return (
    <Container className="signinmainbg sign-in-page" fluid>
      <Row>
        <Col md={4}>
          <img
            src={Logo}
            alt="logo"
            className="d-none d-xl-block d-md-block d-lg-block signin-logo-lg"
          />
          <section className="h-75 ui-text-white d-none d-xl-flex d-md-flex d-lg-flex signin-header w-75 d-flex flex-column justify-content-center align-items-center mx-auto">
            <h3>
              Your unfair advantage to hiring the
              <span className="extra-bold"> best and the brightest, now.</span>
            </h3>
          </section>
        </Col>
        <Col
          className=" d-flex vh-100 justify-content-center primarybg componentborder align-items-center"
          md={8}
        >
          <Row className="w-100">
            <Col md={12}>
              <img
                src={ColoredLogo}
                alt="colored-logo"
                className="fixed-top ms-3 d-xl-none d-lg-none d-md-none d-block"
              />
              <ValidateForm />
              <Row>
                {((account) => {
                  if (!account) {
                    return (
                      <Col md={12}>
                        <span className="mb-1 small-text signinsmall fixed-bottom">
                          Don&apos;t have an account get started?{' '}
                          <Link to="/signup" className="signin-none-underline">
                            <span className="login-small-text bold">
                              Sign up
                            </span>
                          </Link>
                        </span>
                      </Col>
                    );
                  } else {
                    return (
                      <Col md={12}>
                        <span className="mb-1 small-text signinsmall fixed-bottom">
                          Already have an account?{' '}
                          <Link to="/signin" className="signin-none-underline">
                            <span className="login-small-text bold">
                              Sign In
                            </span>
                          </Link>
                        </span>
                      </Col>
                    );
                  }
                })()}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
