import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import DropDownIcon from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import React, { useState } from 'react';
import '../css/challengeListItem.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/typography.css';
import {
  convertDifficulty,
  changeTaskColor,
  correctlyCap,
} from '../../utils/functions';
import ExpandedChallengeView from './expandedChallengeFullView';

// right now data that is displayed is hard coded , after we connect everything the commented code can be uncommented
const ChallengeListItem = ({ workSampleProject, tasks }) => {
  const [expandView, setExpandView] = useState();
  const [isPreffered, setIsPreffered] = useState(false);
  const [task, setTask] = useState({});
  const [titleColRatio, setTitleColRatio] = useState(3);
  const [index, setIndex] = useState(0);

  //checks if task is in prfered already and display buton accordingly
  const hasBeenAdded = (taskId) => {
    const result = workSampleProject.taskId.some(
      (checkTask) => taskId === checkTask
    );
    return result;
  };

  const openExpanded = (index, isPreffered) => {
    setExpandView(true);
    setIndex(index);
    setTask(tasks[index]);
    setIsPreffered(isPreffered);
    setTitleColRatio(4);
  };

  const goNext = () => {
    if (index !== tasks.length - 1) {
      setTask(tasks[index + 1]);
      setIndex(index + 1);
    }
  };

  const goBack = () => {
    if (index > 0) {
      setTask(tasks[index - 1]);
      setIndex(index - 1);
    }
  };

  const closeExpanded = () => {
    setExpandView(false);
    setTitleColRatio(3);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="col-2 me-2">
          <label htmlFor="cars" className="challenge-list-filter-label">
            Sort By:
            <img
              src={DropDownIcon}
              alt="dropdown-icon"
              className="filter-challenge-dropdown-icon"
            />
          </label>
          <select name="cars" className="challenge-filter-field">
            <option>Date</option>
          </select>
        </Col>
        <Col className="col-2 me-2">
          <label className="challenge-list-filter-label">
            Language:
            <img
              src={DropDownIcon}
              alt="dropdown-icon"
              className="filter-challenge-dropdown-icon"
            />
          </label>

          <select className="challenge-filter-field">
            <option>JavaScript</option>
          </select>
        </Col>
        <Col className="col-2 me-2">
          <label className="challenge-list-filter-label">
            Difficulty:
            <img
              src={DropDownIcon}
              alt="dropdown-icon"
              className="filter-challenge-dropdown-icon"
            />
          </label>

          <select className="challenge-filter-field">
            <option>Easy</option>
          </select>
        </Col>
      </Row>
      <div className="challenge-list-divider"></div>
      <Row>
        <Col className="challenge-list-scroll">
          {tasks?.map((task, index) => {
            return (
              <Container
                fluid
                className="list-container"
                onClick={() => openExpanded(index, hasBeenAdded(task._id))}
                key={index}
              >
                <Row>
                  <Col xs={titleColRatio}>
                    <h6 className="h6" data-testid={`title-${index}`}>
                      {task.title}
                    </h6>
                    {hasBeenAdded(task._id) ? (
                      <p className="label">Added</p>
                    ) : (
                      <p className="label">Removed</p>
                    )}
                  </Col>
                  {expandView ? (
                    ''
                  ) : (
                    <Col xs={3}>
                      <div className="score">
                        <p className="label">Average Test Score</p>
                        <h6 className="h6" data-testid={`score-${index}`}>
                          5%
                        </h6>
                      </div>
                      <div>
                        <ProgressBar now={5} style={{ height: '5px' }} />
                      </div>
                    </Col>
                  )}
                  <Col>
                    <div className="challenge-list-center-text">
                      <p className="label">Language</p>
                      <p
                        className="challenge-list-skillpills"
                        data-testid={`language-${index}`}
                      >
                        {correctlyCap(task.skillTested)}
                      </p>
                    </div>
                  </Col>
                  {expandView ? (
                    ''
                  ) : (
                    <Col>
                      <div className="challenge-list-center-text">
                        <p className="label">Difficulty</p>
                        <p
                          className="h6"
                          style={{
                            color: `#${changeTaskColor(task.difficulty)}`,
                          }}
                          data-testid={`difficulty-${index}`}
                        >
                          {convertDifficulty(task.difficulty)}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col className="challenge-list-buttons">
                    {hasBeenAdded(task._id) ? (
                      <button className="remove-button ">Remove</button>
                    ) : (
                      <button className="add-button ">Add</button>
                    )}
                  </Col>
                </Row>
              </Container>
            );
          })}
        </Col>
        {expandView ? (
          <Col>
            <ExpandedChallengeView
              task={task}
              isPreffered={isPreffered}
              closeExpanded={closeExpanded}
              goBack={goBack}
              goNext={goNext}
            />
          </Col>
        ) : (
          ''
        )}
      </Row>
    </Container>
  );
};

export default ChallengeListItem;
