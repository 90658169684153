import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import EnterpriseImg from '../../assets/icon/enterprise_img.svg';
import CheckDot from '../../assets/icon/check_dot.svg';
import ContactModal from '../contactModal';
// css
import '../css/subscription_card.css';

function Enterprise() {
  const [showContactModal, setShowContactModal] = useState(false);

  const handleShowContactModal = () => {
    setShowContactModal(true);
  };

  return (
    <Container>
      <div className="card-container">
        <Row>
          <Col>
            <h2 className="card-title mt-3">Enterprise</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <img className="enterprise-img" src={EnterpriseImg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="sub-heading mt-2">Custom</h1>
          </Col>
        </Row>
        <Row className="row mt-3">
          <Col xs={2}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-3">
              Unlimited private and public sourse bouties
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={1}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-5">Concierge services</p>
          </Col>
        </Row>
        <Row>
          <Col xs={1}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-5">No annual platform fee</p>
          </Col>
        </Row>
        <Row>
          <Col xs={1}>
            <img className="ms-5 mb-3" src={CheckDot} />
          </Col>
          <Col xs={10}>
            <p className="list-item ms-5">No transaction fees</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              onClick={handleShowContactModal}
              id="contact"
              className="bottom-button mb-3"
            >
              Contact Us
            </button>
          </Col>
        </Row>
      </div>
      <ContactModal show={showContactModal} setShow={setShowContactModal} />
    </Container>
  );
}

export default Enterprise;
