import React from 'react';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { formatDate } from '../../utils/functions';
import '../css/assessmentOverview.css';
import '../../shared/css/typography.css';

const AssessmentOverview = ({ assessment, company, WSProject }) => {
  const percentage =
    (assessment.workSampleScore / assessment.workSampleValuationList.length) *
    100;

  const totalTested = assessment.workSampleValuationList.length;
  const totalPassed = assessment.workSampleScore;
  const totalAttempted = assessment.inputCode.length;
  const startedAt = new Date(assessment.startedAt);
  const completedAt = new Date(assessment.completedAt);
  const totalDuration = Math.round((completedAt - startedAt) / 60000);

  const averageChallengeDuration = new Date(
    (completedAt - startedAt) / totalAttempted
  );

  return (
    <Container className="assessment-left-container">
      <div className="interviewWrapper">
        <Row>
          <Col className="assessment-box-container assessment-candidate-interview-inside">
            <Row>
              <Col>
                <h3 className="h4 bold pb-2">Details</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h1 className="text-titles-1">Company Name:</h1>
                <p data-testid="interviewer" className="assessment-text-reg ">
                  {company.companyName}
                </p>
              </Col>
              <Col>
                <h1 className="text-titles-1">Invited By:</h1>
                <p data-testid="interviewer" className="assessment-text-reg ">
                  {assessment.invitedBy}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h1 className="text-titles-1">Job Title:</h1>
                <p data-testid="date" className="assessment-text-reg ">
                  {WSProject.jobTitle}
                </p>
              </Col>
              <Col>
                <h1 className="text-titles-1">Date:</h1>
                <p data-testid="date" className="assessment-text-reg ">
                  {formatDate(assessment.startedAt)}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="assessment-box-container assessment-candidate-interview-inside">
            <h1 className="h4 bold">Score</h1>
            <Row>
              <Col>
                <h1
                  className="assessment-results-percentage bold"
                  data-testid="percentage"
                >
                  {percentage}%
                </h1>
              </Col>
            </Row>
            <ProgressBar
              now={percentage}
              style={{ height: '10px', marginBottom: '10px' }}
            />
            <Row>
              <Col>
                <h1 className="text-titles-1">Challenges passed:</h1>
                <p
                  className="assessment-text-reg assessment-results-passed "
                  data-testid="passed"
                >
                  {totalPassed} out of {totalTested}
                </p>
              </Col>
              <Col>
                <h1 className="text-titles-1">Challenges Attempted</h1>
                <p className="assessment-text-reg " data-testid="attempted">
                  {totalAttempted} out of {totalTested}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="assessment-box-container assessment-candidate-interview-inside">
            <Row>
              <Col>
                <h1 className="h4 bold pb-2">Duration</h1>
              </Col>
            </Row>
            <Row className="assessment-results-duration">
              <Col>
                <h1 className="text-titles-1">Total Duration:</h1>
                <p data-testid="duration" className="assessment-text-reg">
                  {totalDuration === 1
                    ? `${totalDuration} Minute`
                    : `${totalDuration} Minutes`}
                </p>
              </Col>
              <Col>
                <h1 className="text-titles-1">Average Challenge Duration:</h1>
                <p
                  data-testid="average-duration"
                  className="assessment-text-reg "
                >
                  {(averageChallengeDuration.getMinutes() === 1) | 0
                    ? `${averageChallengeDuration.getMinutes()} Minute, ${averageChallengeDuration.getSeconds()} Seconds`
                    : `${averageChallengeDuration.getMinutes()} Minutes, ${averageChallengeDuration.getSeconds()} Seconds`}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AssessmentOverview;
