import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
// import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import NextButton from '../../shared/button/next';
import ResetButton from '../../shared/button/resetButton';
import 'bootstrap/dist/css/bootstrap.css';
import '../../bountyCreateSteps/css/createBounty.css';
import '../../shared/css/typography.css';
import { useInvoicedGigForm } from '../../context/invoicedGigContext';
import { useAuth } from '../../context/authContext';
import { getAllSalesCompanies } from '../../api/sales';

export const StepOneBounty = ({
  nextStep,
  prevStep,
  resetStep,
  // companyName,
}) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    resetGigForm,
    setFieldTouched,
  } = useInvoicedGigForm();
  const { auth } = useAuth();
  const [types, setType] = useState('Gig');

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (
      currentErrors.title ||
      currentErrors.companyName ||
      currentErrors.taskDescription
    ) {
      setFieldTouched('title');
      setFieldTouched('taskDescription');
      setFieldTouched('companyName');
      return;
    }
    nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };
  const getCompanies = async () => {
    let result = [];
    let companies = await getAllSalesCompanies();
    // console.log(companies);
    for (let i = 0; i < companies.length; i++) {
      let company = companies[i];
      result.push({ label: company.name, value: company.id });
    }
    return result;
  };
  return (
    <Container>
      <Form>
        <Form.Group className="mb-4" controlId="steptwoGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Select opportunity Type{''}
            <span className="required">*</span>
          </Form.Label>

          <Form.Control as="div" className="remove-radio-border">
            <Form.Check
              inline
              defaultChecked={values.type === 'Job'}
              label="Job"
              name="type"
              type="radio"
              id="Job"
              onChange={() => {
                handleChange({
                  target: { name: 'type', value: 'Job' },
                });
                setType('Job');
              }}
            />
            <Form.Check
              inline
              defaultChecked={values.type === 'Gig'}
              label="Gig"
              name="type"
              type="radio"
              id="Gig"
              onChange={() => {
                handleChange({
                  target: { name: 'type', value: 'Gig' },
                });
                setType('Gig');
              }}
            />
          </Form.Control>
          <Form.Text className="text-muted">
            Select the type of opportunuty you want to create
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-4" controlId="stepOneGroup1">
          <Form.Label className="form_label bounty-type-label ps-0">
            Company <span className="required">*</span>
          </Form.Label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            closeMenuOnSelect={true}
            maxMenuHeight={500}
            onChange={(e) => {
              handleChange({
                target: { name: 'companyName', value: e.label },
              });
              handleChange({
                target: { name: 'companyId', value: e.value },
              });
            }}
            loadOptions={getCompanies}
          />
          {touched.companyName && errors.companyName && (
            <Form.Control.Feedback type="invalid">
              {errors.companyName}
            </Form.Control.Feedback>
          )}
          <Form.Label className="form_label bounty-type-label ps-0">
            {values.type === 'Gig' ? ' Gig Title' : ' Job Title'}{' '}
            <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="title"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            isInvalid={!!errors.title}
          />
          {touched.title && errors.title && (
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          )}
          {values.type === 'Gig' ? (
            <Form.Label className="text-muted">
              <i>Examples:</i> &quot;Develop a web3 wallet browser extension for
              my cryptocurrency&quot;, &quot;Design a brand style guide for my
              company&quot;, or &quot;Help design our serverless
              architecture&quot;
            </Form.Label>
          ) : (
            <Form.Label className="text-muted">
              <i>Examples:</i> &quot;Senior Sales Representative&quot;,
              &quot;Associate Product Manager&quot;, or &quot;Health
              Educator&quot;
            </Form.Label>
          )}
        </Form.Group>
        <Form.Group className="mb-4" controlId="stepOneGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Gig Description <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="taskDescription"
            as="textarea"
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.taskDescription}
            isInvalid={!!errors.taskDescription}
          />
          {touched.taskDescription && errors.taskDescription && (
            <Form.Control.Feedback type="invalid">
              {errors.taskDescription}
            </Form.Control.Feedback>
          )}
          {values.type === 'Gig' ? (
            <Form.Label className="text-muted">
              <i>Example:</i> &quot;We would like to implement a withdrawal fee.
              When a staking user withdraws their funds from the pool or claims
              rewards, we would like to implement a flat fee of 0.5% that goes
              to an address of choice. This would involve, in part, adding
              off-chain transaction code that produces a new utxo-out to this
              new address.&quot;
            </Form.Label>
          ) : (
            <Form.Label className="text-muted">
              <i>Example:</i> &quot;We would like a sales representative with
              good people skills, product marketing and sales techniques and
              negotiation skills to join our growing team to help drive revenue.
              Basic computing skills are needed to use our tools, including our
              CRM system, which allows us to efficiently track and manage
              customer interactions, leads and other opportunities. Being able
              to think quickly on your feet is a plus.&quot;
            </Form.Label>
          )}
        </Form.Group>
        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <NextButton
                onClick={next}
                type="button"
                disabled={
                  errors.taskDescription || errors.name || errors.companyName
                }
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepOneBounty;
