import React, { useState, useRef } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import Save from '../../shared/button/save';
import Copyright from '../../assets/icon/snapbrillia_help_icon.svg';
import ExpansionIcon from '../../assets/icon/snapbrillia_description_drag.svg';
import DropDownIcon from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import '../css/editProjectFields.css';

const EditProjectFields = ({ WSPFields, setWSPFields, handleSave }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [errorStatus, setErrorStatus] = useState([-1, -1]);
  const [saveClicked] = useState(0);
  const formRef = useRef();
  // let saveClicked = 0;
  const inputDetection = (source) => {
    // if (errorStatus.every((e) => e === -1)) return 1;
    let current = [...errorStatus];

    let detected = 0;
    if (formRef.current.elements[0].value === '') {
      current[0] = 1;
      detected = 1;
    } else if (current[0] === 1) {
      current[0] = 0;
    }
    if (formRef.current.elements[1].value === '') {
      current[1] = 1;
      detected = 1;
    } else if (current[1] === 1) {
      current[1] = 0;
    }

    if (saveClicked || source === 'save') {
      setErrorStatus(current);
      // setErrorDisplayed(detected);
    }
    if (!detected) {
      handleChange(source);
    }
    return detected;
  };
  const handleChange = (source) => {
    setWSPFields({ ...WSPFields, [source.target.name]: source.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave();
  };

  const handleLineBreak = (e) => {
    let value = e.target.value;

    if (value.length === 0) {
      e.target.rows = 1;
    }
    if (e.key === 'Enter' && e.target.rows < 12) {
      e.target.rows += 1;
    }

    if (e.keyCode === 8 && e.target.rows > 1) {
      if (e.target.value[e.target.selectionStart - 1] === '\n') {
        e.target.rows -= 1;
      }
    }
  };

  return (
    <Container>
      <Form
        className="edit-project-fields-wrapper position-relative"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <Form.Group
          className={`${
            errorStatus[0] === 1
              ? 'edit-fields-group-error'
              : 'edit-fields-group'
          }`}
        >
          <Form.Label className="mb-2 edit-fields-label" htmlFor="project-name">
            Project Name:
          </Form.Label>
          <span className="required">&nbsp;*</span>
          <input
            value={WSPFields.name}
            onChange={inputDetection}
            id="project-name"
            type="text"
            name="name"
            placeholder="Development"
          />
          <div
            style={{
              color: '#cf2828',
              display: errorStatus[0] === 1 ? null : 'none',
            }}
          >
            Project Name is required
          </div>
        </Form.Group>
        <Form.Group
          className={`${
            errorStatus[1] === 1
              ? 'edit-fields-group-error'
              : 'edit-fields-group'
          }`}
        >
          <Form.Label className="mb-2 edit-fields-label" htmlFor="job-title">
            Job Title:
          </Form.Label>
          <span className="required">&nbsp;*</span>
          <input
            onChange={inputDetection}
            type="text"
            placeholder="Software Engineer"
            id="job-title"
            name="jobTitle"
            value={WSPFields.jobTitle}
          />
          <div
            style={{
              color: '#cf2828',
              display: errorStatus[1] === 1 ? null : 'none',
            }}
          >
            Job Title is required
          </div>
        </Form.Group>
        <Form.Group className="edit-fields-group">
          <Form.Label className="mb-2 edit-fields-label" htmlFor="job-desc">
            Job Description:
          </Form.Label>
          <textarea
            onKeyDown={handleLineBreak}
            rows="1"
            id="job-desc"
            name="jobDescription"
            onChange={inputDetection}
            value={WSPFields.jobDescription}
          >
            {WSPFields.jobDescription}
          </textarea>
          <img
            src={ExpansionIcon}
            alt="expand-icon"
            className="edit-fields-expan-icon"
          />
        </Form.Group>
        {/* Tu Comment need check with Beatrice */}
        {/* <Form.Group className="edit-fields-group">
          <Form.Label
            className="mb-2 edit-fields-label"
            htmlFor="time-allotted"
          >
            Time allotted:
          </Form.Label>
          <select
            id="time-allotted"
            name="duration"
            value={WSPFields.duration}
            onChange={handleChange}
          >
            <option value="15">15 minutes</option>
            <option value="30">30 minutes</option>
            <option value="45">45 minutes</option>
            <option value="60">60 minutes</option>
          </select>
          <img
            src={DropDownIcon}
            alt="dropdown-icon"
            className="edit-fields-dropdown-icon"
          />
        </Form.Group> */}
        <Form.Group className="edit-fields-group">
          <Form.Label className="mb-2 edit-fields-label" htmlFor="expiration">
            Expiration:
          </Form.Label>
          <select
            id="expiration"
            name="daysToComplete"
            value={WSPFields.daysToComplete}
            onChange={handleChange}
          >
            <option value="14">2 Weeks</option>
            <option value="21">3 Weeks</option>
            <option value="30">4 Weeks</option>
          </select>
          <img
            src={DropDownIcon}
            alt="dropdown-icon"
            className="edit-fields-dropdown-icon"
          />
        </Form.Group>
        <Form.Group className="edit-fields-group">
          <Form.Label className="mb-2 edit-fields-label" htmlFor="difficulty">
            Difficulty:
          </Form.Label>
          <select
            id="difficulty"
            name="difficulty"
            value={WSPFields.difficulty}
            onChange={handleChange}
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="difficult">Difficulty</option>
          </select>
          <img
            src={DropDownIcon}
            alt="dropdown-icon"
            className="edit-fields-dropdown-icon"
          />
        </Form.Group>
        <Form.Group className="edit-fields-group">
          <Form.Label className="mb-2 edit-fields-label">
            Project Type
          </Form.Label>
          <Row className="edit-fields-group">
            {WSPFields.type === 'ai' ? (
              <Col>
                <Row className="align-items-center">
                  <Col className="col-1">
                    <input
                      type="radio"
                      name="type"
                      className="edit-radio-btn"
                      id="ai-generated"
                      value="ai"
                      defaultChecked={WSPFields.type === 'ai'}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label
                      className="edit-fields-label mb-1"
                      htmlFor="ai-generated"
                    >
                      AI-Generated
                    </Form.Label>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col>
                <Row className="align-items-center">
                  <Col className="col-1">
                    <input
                      type="radio"
                      name="project-type"
                      value="custom"
                      className="edit-radio-btn"
                      id="manual"
                      defaultChecked={WSPFields.type !== 'ai'}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label
                      className="mb-1 edit-fields-label"
                      htmlFor="manual"
                    >
                      Manual
                    </Form.Label>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Form.Group>
        <Row className="cardbg rounded py-3">
          <Col>
            <span className="grey-title medium d-block">
              Years of experience
            </span>
            {WSPFields.experience.map((item, index) => (
              <span className="skillpills" key={index}>
                {item}
              </span>
            ))}
          </Col>
          {/* <Col>
            <span className="grey-title medium d-block">Skills</span>
            {WSPFields.mandatoryskillsTested.map((item, index) => (
              <span className="skillpills" key={index}>
                {item}
              </span>
            ))}
            {WSPFields.optionalskillsTested.map((item, index) => (
              <span className="skillpills" key={index}>
                {item}
              </span>
            ))}
          </Col> */}
        </Row>
        <img
          src={Copyright}
          alt="copyright"
          onMouseOver={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
          className="edit-fields-info-icon"
        />
        {showInfo && (
          <div className="edit-fields-info">
            <p>
              This section is locked and no editable to ensure fairness and
              uniformity of coding test under OFCCP. Please create another
              project to modify
            </p>
          </div>
        )}
        <Row className="float-end">
          <Save className="mt-4" onClick={handleSubmit} />
        </Row>
      </Form>
    </Container>
  );
};

export default EditProjectFields;
