import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as SnapbrilliaDropdownIcon } from '../../assets/icon/snapbrillia_dropdown_icon.svg';
import './css/selectMenu.css';

const SelectMenu = ({ value, setValue, setKey, list, placeHolder }) => {
  const ref = useRef();
  const [select, setSelect] = useState(false);
  const handleSelectEvent = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setSelect(false);
    }
  };

  const handleRole = (e) => {
    if (setKey) {
      setKey(e.target.id);
    } else {
      setValue(e.target.id);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleSelectEvent);

    return () => {
      document.removeEventListener('mousedown', handleSelectEvent);
    };
  }, []);

  return (
    <div
      role="listbox"
      aria-expanded={select}
      aria-label={`selection menu with ${list.length} items`}
      onBlur={handleSelectEvent}
      onClick={() => setSelect(!select)}
      ref={ref}
      className={
        select
          ? 'select-menu-dropdown-select select-menu-dropdown-no-border'
          : 'select-menu-dropdown-select'
      }
    >
      <span
        aria-placeholder={placeHolder}
        className="select-menu-dropdown-value"
      >
        {value ? value : placeHolder}
      </span>
      {select && (
        <div className="select-menu-select-list">
          {list.map((item, idx) => (
            <div
              role="listitem"
              key={idx}
              onClick={(e) => handleRole(e)}
              className="select-menu-select-item"
              id={setKey ? item.key : item.item}
            >
              {item.item}
            </div>
          ))}
        </div>
      )}
      <div className="select-menu-arrow">
        {!select && <SnapbrilliaDropdownIcon />}
      </div>
      <div className="select-menu-up-arrow select-menu-arrow">
        {select && <SnapbrilliaDropdownIcon />}
      </div>
    </div>
  );
};

export default SelectMenu;
