import React, { useRef, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PrimarySideMenu from '../../shared/menus/primarySideMenu';
// import RecentlyViewedSideMenu from '../../shared/menus/recentlyViewedSideMenu';
// import RecentlyEndedSideMenu from '../../shared/menus/recentlyEndedSideMenu';
import CopyRight from '../../shared/copyright/copyright';

import { ReactComponent as SnapbrilliaLogo } from '../../assets/snapbrillia_logo.svg';
// import { ReactComponent as SnapbrilliaIcon } from '../../assets/icon/snapbrillia_icon.svg';
import { ReactComponent as CollapseButton } from '../../assets/icon/snapbrillia_expandcontract_icon.svg';

import './css/primarySidebar.css';

const PrimarySidebar = ({ collapsed, setCollapsed, component }) => {
  const sidebarRef = useRef();

  //Adding click handler onto logo for redirecting to landing page.
  useEffect(() => {
    let logo_image = document.querySelector('.logo-with-text');
    [logo_image] = logo_image.childNodes;
    [logo_image] = logo_image.childNodes;
    logo_image.classList.add('pointer');
    logo_image.addEventListener('click', () => {
      window.open('https://www.snapbrillia.com', '_self');
    });
  }, []);

  return (
    <div
      id="sidebar"
      ref={sidebarRef}
      className="primary-sidebar col-2 col-md-2 col-lg-2"
      style={{
        position: 'relative',
        width: collapsed ? '7vw' : '15vw',
        minWidth: collapsed ? '50px' : '180px',
        maxWidth: collapsed ? '100px' : '100%',
        animation: 'sidebar-collapse 0.5s',
        transition: 'all 0.9s ease-in-out',
        paddingLeft: 0,
        minHeight: '760px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          left: 35,
          top: `calc(50vh - 35px)`,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 0,
        }}
      >
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="primary-collapse-button"
          style={{
            position: 'relative',
            transform: collapsed ? 'rotate(180deg)' : null,
            transition: 'all 0.8s ease-in-out',
            cursor: 'pointer',
            zIndex: '200',
          }}
        >
          <CollapseButton />
        </div>
      </div>
      <Row
        style={{
          marginTop: '10px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Col>
          <div
            className="logo-with-text"
            style={{
              opacity: collapsed ? 0 : null,
              transition: 'all 0.8s ease-in-out, visibility 0s',
              transform: collapsed ? 'translate(calc(-9vw + 76.5px))' : null, //icon uncollpased - icon collapsed
              position: 'relative',
              width: '15vw',
              textAlign: 'center',
            }}
          >
            <SnapbrilliaLogo alt="snapbrillia-logo" />
          </div>
        </Col>
      </Row>
      <Row
        className="primary-menu-tabs mb-5"
        style={{
          marginLeft: collapsed ? '0' : '1rem',
        }}
      >
        <Col
          style={{
            position: 'relative',
          }}
          className="profile_sidemenu"
        >
          <div
            style={{
              position: 'relative',
            }}
          >
            <PrimarySideMenu component={component} collapsed={collapsed} />
          </div>
        </Col>
      </Row>

      <Col className="d-flex flex-column justify-content-around align-items-center mx-4">
        <Row
          style={{
            position: 'relative',
          }}
          className="primary-recents"
        >
          <Col
            className="primary-recents-col"
            style={{
              visibility: collapsed ? 'hidden' : 'visible',
              opacity: collapsed ? '0' : null,
              transition: 'all 0.8s linear',
              transform: collapsed ? 'translate(-5.0vw)' : null,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              height: '55vh',
              minHeight: '335px',
            }}
          >
            {/* <Row className="primary-recently-viewed mb-3">
              <RecentlyViewedSideMenu />
            </Row>
            <Row className="mb-3">
              <RecentlyEndedSideMenu />
            </Row> */}
          </Col>
        </Row>
      </Col>
      <div
        style={{
          visibility: collapsed ? 'hidden' : 'visible',
          opacity: collapsed ? '0' : null,
          transition: 'all 0.8s linear',
          transform: collapsed ? 'translate(-5.0vw)' : null,
        }}
        className="sidebar-copyright mt-3"
      >
        <CopyRight />
      </div>
    </div>
  );
};

export default PrimarySidebar;
