/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
// import { Link } from '@reach/router';
import '../../shared/css/typography.css';
import '../../shared/css/buttonStyles.css';
import Cart from './cart';
import Success from './success';
import '../css/cartModal.css';
import { toast } from 'react-toastify';
import { useSendTransaction, useContractWrite } from 'wagmi';
import { utils } from 'ethers';
import {
  createStripeIntents,
  confirmStripeIntents,
  confirmStripeIntentsTopUp,
} from '../../api/opportunities';
import { createCryptoIntents } from '../../api/sessions';
import ethTokens from '../../utils/ethToken';
import { useCardanoWallet } from '../../context/walletContext';

export default function CartModal({
  bounty,
  setCartModal,
  reloadTable,
  changeCartType,
}) {
  const [intent, setIntent] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [payment, setPayment] = useState({
    paymentInfo: '',
    paymentType: '',
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const {
    increaseAdaBounty,
    cardanoWalletName,
    address,
    amountOfLovelaceInOneAda,
  } = useCardanoWallet();
  const [walletName, setWalletName] = useState('');
  const [extraAmount, setExtraAmount] = useState(0);
  const [paymentFee, setPaymentFee] = useState(0);
  const platformFee = 1.2;
  // deleted isLoading from useSendTransaction
  const { data, isSuccess, sendTransaction, error } = useSendTransaction({
    request: {
      to: process.env.REACT_APP_ETH_WALLET,
    },
  });
  const [contractAddress, setContractAddress] = useState('');
  const [tranferAmount, setTransferAmount] = useState('');
  const {
    data: dataContract,
    isSuccess: isSuccessContract,
    // isLoading: isLoadingContract,
    error: errorContract,
    write,
  } = useContractWrite({
    address: contractAddress,
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'transfer',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
    functionName: 'transfer',
    args: [process.env.REACT_APP_ETH_WALLET, tranferAmount],
  });
  const handleClose = () => {
    setShowModal(false);
    setCartModal(false);
    reloadTable();
    setPage(1);
  };

  const charge = async () => {
    if (!payment.paymentInfo.length > 0) {
      return toast('Please select a payment method');
    }
    if (!extraAmount) {
      return toast('Please set extra funds');
    }
    let paymentIntent = '';
    if (['ETH', 'USDT', 'LINK', 'MATIC'].includes(payment.paymentType)) {
      paymentIntent = {};
    } else if (payment.paymentType === '₳DA') {
      paymentIntent = {};
    } else {
      paymentIntent = await createStripeIntents(bounty._id, {
        account: payment.paymentInfo,
        amount: extraAmount,
      });
    }
    setIntent(paymentIntent);
    setLoading(false);
  };

  useEffect(() => {
    setTransferAmount(
      utils.parseUnits(`${extraAmount * platformFee}`, 'ether')
    );
    const tokenInfo = ethTokens.find((x) => x.symbol === payment.paymentType);
    if (tokenInfo) {
      setContractAddress(tokenInfo.contract);
    }
  }, [payment, extraAmount]);

  const confirmPayment = async () => {
    setLoading(true);
    if (payment.paymentType === '₳DA') {
      await sendAda();
    } else if (['ETH'].includes(payment.paymentType)) {
      sendTransaction({
        request: {
          to: process.env.REACT_APP_ETH_WALLET,
          value: utils.parseUnits(`${extraAmount}`, 'ether'),
        },
      });
    } else if (['USDT', 'LINK', 'MATIC'].includes(payment.paymentType)) {
      write?.();
      return;
    } else {
      await confirmStripeIntentsTopUp({
        intent: intent.paymentIntentId,
        amount: extraAmount,
      });
      reloadTable();
      setPage(2);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      // deleted wait from data
      const { hash } = data;
      const createCryptoPayment = async () => {
        await createCryptoIntents(bounty._id, extraAmount * platformFee, hash);
        handleClose();
        reloadTable();
      };
      createCryptoPayment();
    } else if (error) {
      toast(error.error?.message);
    }
  }, [error, data, isSuccess]);

  useEffect(() => {
    if (dataContract) {
      // deleted wait from dataContract
      const { hash } = dataContract;
      const createCryptoPayment = async () => {
        await createCryptoIntents(bounty._id, extraAmount * platformFee, hash);
        handleClose();
        reloadTable();
      };
      createCryptoPayment();
    } else if (errorContract) {
      toast(errorContract.error?.message);
    }
  }, [errorContract, dataContract, isSuccessContract]);

  const sendAda = async () => {
    try {
      const lovelaceAmount =
        extraAmount * platformFee * amountOfLovelaceInOneAda;
      const txHash = await increaseAdaBounty(
        lovelaceAmount,
        bounty._id,
        extraAmount,
        cardanoWalletName,
        address
      );
      if (txHash) {
        reloadTable();
        setPage(2);
      }
      setWalletName(walletName.charAt(0).toUpperCase() + walletName.slice(1));
      return txHash;
    } catch (error) {
      toast(error.response?.data?.error || error.message);
    }
  };

  return (
    <div style={{ position: 'absolute' }}>
      <Modal
        onHide={handleClose}
        backdrop="static"
        show={showModal}
        centered
        contentClassName="modal-radius"
        size="lg"
        dialogClassName={'small-cart-bg'}
      >
        {page === 1 && (
          <>
            <Cart
              cart={[bounty]}
              platformFee={platformFee}
              setPayment={setPayment}
              changeCartType={changeCartType}
              extraAmount={extraAmount}
              setExtraAmount={setExtraAmount}
              setPaymentFee={setPaymentFee}
              paymentFee={paymentFee}
              payment={payment}
            />
            <Container>
              <Row className="footer-padding small-cart-footer-bg">
                <Col className="text-end">
                  {loading && <span>...Loading</span>}
                  {!loading && !intent && (
                    <button className="btn-primary" onClick={charge}>
                      Purchase
                    </button>
                  )}
                  {!loading && intent && (
                    <button className="btn-primary" onClick={confirmPayment}>
                      Confirm
                    </button>
                  )}
                </Col>
              </Row>
            </Container>
          </>
        )}
        {page === 2 && (
          <Success
            cart={[bounty]}
            platformFee={platformFee}
            extraAmount={extraAmount}
            walletName={walletName}
            paymentFee={paymentFee}
            payment={payment}
          />
        )}
      </Modal>
    </div>
  );
}
