import { ReactComponent as TextUs } from '../../assets/icon/snapbrillia_text_us_icon.svg';
import '../css/supportStyles.css';

export default function TextUsButton() {
  let width = screen.width / 1.5;
  let height = screen.height / 1.5;
  let left = screen.width / 2 - width / 2;
  let top = screen.height / 2 - height / 2;
  return (
    <button
      className="btn-primary text-us"
      onClick={() =>
        window.open(
          'https://discord.gg/vJuPXu86vS',
          '_blank',
          `width=${width}, height=${height}, left=${left}, top=${top}`
        )
      }
    >
      <TextUs />
      <span className="textUsIcon">Text Us</span>
    </button>
  );
}
