import * as yup from 'yup';
import { useAuth } from '../../context/authContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import { useState } from 'react';
import ValidateTotpButton from '../../shared/button/validateTotp';

const ValidateForm = () => {
  const [token, setToken] = useState('');
  const { auth, validateTotpToken, sendTotp } = useAuth();
  //Validation
  const validationSchema = yup.object().shape({
    token: yup.number().required('Required').positive().integer(),
  });
  //UI event handlers
  async function handleSubmit(e) {
    e.preventDefault();
    if (await validationSchema.isValid({ token: token })) {
      await validateTotpToken(auth.user._id, token);
    }
  }
  async function sendTotpemail(e) {
    e.preventDefault();
    await sendTotp(auth.user._id, 'email');
  }

  return (
    <div className="mx-auto">
      <Container>
        <Row>
          <h1 className="h1 sign-in-heading extra-bold text-center">
            2-Step Verification
          </h1>
          <p className="h4 label w-10 mx-auto sign-in-heading-small text-center">
            Snapbrillia would like to verify it is you accessing the account.
            <br />
            Please input your 6 digit TOTP code from your authenticator
            application or as received in your inbox.
            <br />
            Click{' '}
            <a href="#" onClick={sendTotpemail}>
              here
            </a>{' '}
            to receive your token via email
          </p>
        </Row>
        <Row>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Token</Form.Label>
              <Form.Control
                type="text"
                placeholder="______"
                onChange={(e) => setToken(e.target.value)}
              />
            </Form.Group>
            <div className="btn-verified">
              <ValidateTotpButton onClickHandler={handleSubmit} />
            </div>
          </Form>
        </Row>
      </Container>
    </div>
  );
};
export default ValidateForm;
