import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import AssessmentInvite from './components/emailTinyMCE';
import Logo from '../assets/SnapBetaLogo2.svg';
import EmailAssessmentMenu from '../shared/menus/emailAssessmentMenu';
import CopyRight from '../shared/copyright/copyright';
import '../shared/css/typography.css';
import './css/emailPage.css';
import '../shared/css/typography.css';
import '../shared/css/buttonStyles.css';

const InviteEmailModal = ({
  candidateList,
  interviewers,
  workSampleProject,
  auth,
  handleEmailSent,
  setShowInviteModal,
}) => {
  const [showModal, setShowModal] = useState(true);
  const [title, setTab] = useState('Assess Email');
  const [type, setType] = useState('candidate'); //candidate - interviewer

  const changeTitle = (title, type) => {
    setTab(title);
    setType(type);
  };
  const handleClose = () => {
    setShowModal(false);
    setShowInviteModal(false);
  };
  const handleSent = async (assessment) => {
    handleEmailSent(assessment);
    handleClose();
  };

  return (
    <Modal
      contentClassName="candidate-modal-container import-candidate-text"
      onHide={handleClose}
      backdrop="static"
      show={showModal}
      size="lg"
      fullscreen={true}
    >
      <Modal.Header className="candidate-modal-header" closeButton>
        <Row>
          <Col></Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col xs={2} className="email-page-left-side">
              <Row className="email-page-left-top-side">
                <img src={Logo} alt="logo" className="email-page-logo" />
              </Row>
              <Row className="email-page-left-menu">
                <EmailAssessmentMenu changeTitle={changeTitle} />
              </Row>
              <Row>
                <div className="email-page-copyright">
                  <CopyRight />
                </div>
              </Row>
            </Col>
            <Col xs={10} className="email-page-right-side">
              <AssessmentInvite
                type={type}
                candidateList={candidateList}
                workSampleProject={workSampleProject}
                interviewers={interviewers}
                auth={auth}
                title={title}
                isModal={true}
                handleSent={handleSent}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default InviteEmailModal;
