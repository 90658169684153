import React, { useEffect, useRef, useState } from 'react';
import { HorizontalBarChart } from './components/barchartimplmentation/horizontalBarChart';
import { VerticalBarChart } from './components/barchartimplmentation/verticalBarchart';
import { select } from 'd3';
import ChartsBackend from './components/chartsBackend';
import { chartColors } from './components/chartColors';

// information regarding params for this chart can be found in the client-side README.md
// examples can be found in './chartsDemo.jsx'

const barcolors = chartColors;
function colorpicker(length) {
  let i = barcolors.length;
  let colorcode = 0;
  while (i < length) {
    barcolors[i] = `hsla(${colorcode},80%,58%)`;

    if (colorcode % 60 === 0) {
      colorcode = colorcode + 60;
    } else {
      colorcode = colorcode + 120;
    }
    if (colorcode >= 360) {
      colorcode = colorcode - 360 + 15;
      if (colorcode % 60 === 0) {
        colorcode = colorcode + 15;
      }
    }
    i++;
  }
}
function BarChart({
  bountyId,
  WSPId,
  status,
  width,
  height,
  vertical,
  dataType,
  showAxis,
  showTitle,
  dataType2,
}) {
  const svgRef = useRef();
  const titleRef = useRef();
  const mainDivRef = useRef();
  const [isEmpty, setIsEmpty] = useState();

  const { data, axisLabels, axisTitles, chartTitle } = ChartsBackend({
    bountyId,
    WSPId,
    dataType,
    dataType2,
    status,
  });

  // determine if data is empty
  useEffect(() => {
    let sum = data.reduce((a, b) => a + b, 0);
    if (sum === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [data]);

  useEffect(() => {
    const title = select(titleRef.current);
    if (showTitle === true) {
      title.select('h3').remove();
      title.append('h3').text(chartTitle).style('color', barcolors[0]);
    }
    const svg = select(svgRef.current); //Get svg tag
    //Values and Statics arrays Length match then show chart
    const dataJson = [];
    // data is compiled using values and statics
    for (let i = 0; i < axisLabels.length; i++) {
      dataJson.push({
        statistics: data[i],
        values: axisLabels[i],
      });
    }
    // let isDataEmpty = true;
    // for (let i = 0; i < axisLabels.length; i++) {
    //   if (data[i] !== 0) {
    //     isDataEmpty = false;
    //   }
    // }
    colorpicker(data.length);

    if (isEmpty === false && vertical === true) {
      VerticalBarChart(
        dataJson,
        width,
        height,
        svg,
        barcolors,
        showAxis,
        showTitle
      );
    } else if (isEmpty === false) {
      HorizontalBarChart(
        dataJson,
        width,
        height,
        svg,
        barcolors,
        showAxis,
        showTitle
      );
    }
  }, [
    data,
    axisLabels,
    axisTitles,
    chartTitle,
    width,
    height,
    vertical,
    showTitle,
    showAxis,
    isEmpty,
  ]);

  if (isEmpty === true) {
    return <h3>No Data to Display</h3>;
  }

  return (
    <>
      <div>
        <div className="main-chart-div" ref={mainDivRef}>
          <div className="header" ref={titleRef}></div>
          <svg ref={svgRef} id="BarChart" width={width} height={height}>
            <defs>
              <clipPath id="clip-3" clipPathUnits="userSpaceOnUse">
                <rect
                  x="0"
                  y="0"
                  width={width}
                  height={height}
                  id="clip-pathrect"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </>
  );
}

export default BarChart;
