import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CloseIcon from '../../assets/icon/snapbrillia_close_icon.svg';
import Title from './title';

export default function Header({ setShowModal, setStep, message }) {
  return (
    <Row className="align-items-center">
      <Col className="col-10">
        <Title />
      </Col>
      <Col className="text-end">
        <img
          src={CloseIcon}
          alt="close-icon"
          className="pointer"
          onClick={() => {
            setShowModal(false);
            if (typeof setStep !== 'undefined') {
              setStep(1);
            }
          }}
        />
      </Col>

      <span className="primary medium fs-6 my-4">{message}</span>
    </Row>
  );
}
