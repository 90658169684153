import '../css/buttonStyles.css';

export default function DisableTwoFactorButton({ onClickHandler }) {
  return (
    <button
      className="btn-primary-medium"
      type="submit"
      onClick={onClickHandler}
    >
      Disable 2-step verification
    </button>
  );
}
