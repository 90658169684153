import React, { useState, useEffect } from 'react';
//COMPONENTS
import { Container, Col, Row } from 'react-bootstrap';
//CSS
import '../../shared/css/sharedStyles.css';
import '../css/candidateListItem.css';

const ShrinkedCandidateListItem = ({ candidate, active }) => {
  const [ranadomColor, setRandomColor] = useState('');

  const randomBgColor = () => {
    let value = () => Math.floor(Math.random() * 256);
    return `rgb(${value()},${value()},${value()})`;
  };

  useEffect(() => {
    setRandomColor(randomBgColor());
  }, []);
  return (
    <Container>
      <Row
        className={`position-relative my-4 me-4 p-3 bgprimary rounded  ${
          active ? 'cardbg shrinked-active shadow-sm' : ''
        } `}
      >
        <Col
          className="candidate-list-item-profile col-5"
          style={{ backgroundColor: `${ranadomColor}` }}
        >
          <p className="my-auto">
            {candidate.fullName
              .match(/(^\S\S?|\b\S)?/g)
              .join('')
              .match(/(^\S|\S$)?/g)
              .join('')
              .toUpperCase()}
          </p>
          {candidate.assessment?.type &&
            candidate.assessment?.type === 'live' && (
              <span className="status">Live</span>
            )}
        </Col>
        <Col className="col-6 text-center ms-4">
          <span className="h5 primary bold d-block m-0">
            {candidate.fullName}
          </span>
          <span className="label fs-6 text-center">
            {candidate.assessment.status}
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default ShrinkedCandidateListItem;
