import GoogleDrive from '../../assets/icon/snapbrillia_google_drive_icon.svg';
import '../css/buttonStyles.css';

export default function GoogleDriveButton({ onClick }) {
  return (
    <button className="google-drive-button" onClick={onClick}>
      <img src={GoogleDrive} alt="google-drive-icon" /> Google
    </button>
  );
}
