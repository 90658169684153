/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import {
  activeAndDeactivate,
  getOpportunitiesAdmin,
} from '../../api/opportunities';
import { useParams, Link, navigate } from '@reach/router';
import { Typography, Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import { Button, Col, Row } from 'react-bootstrap';
import JobCreateStepsModal from '../../shared/modals/JobCreateStepModal';
import BountyCreateStepsModal from '../../shared/modals/bountyCreateStepsModal';
import { compensation } from '../../shared/utils';

const AllOpportunitiesTable = () => {
  const [bounties, setBounties] = useState([]);
  const [editBounty, setEditBounty] = useState();
  const [editJob, setEditJob] = useState();
  const [showModal, setShowModal] = useState(false);
  const [pay, setPay] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [loadMore, setLoadMore] = useState(false);

  const [bounty, setBounti] = useState({});
  const companyId = useParams()?.companyId;

  const closeModal = () => {
    setShowModal(false);
  };

  const closeJobModal = () => {
    setShowJobModal(false);
  };

  const getBountiesData = async (filter, nextPage = 1) => {
    setLoading(true);
    let newPage = nextPage > page ? nextPage : page;
    await getOpportunitiesAdmin(companyId, {
      page: newPage,
      limit: limit,
      filter: filter,
    })
      .then((response) => {
        setBounties(response.docs);
      })
      .catch((error) => {
        setBounties([]);
      });
    setLoading(false);
  };

  const clickEdit = async (value, index) => {
    setEditBounty({ ...value, index: index });
    setShowModal(true);
  };
  const clickEditJob = async (value, index) => {
    setEditJob({ ...value, index: index });
    setShowJobModal(true);
  };

  const activate = async (id, data) => {
    const loading = toast.loading('Activating...');
    activeAndDeactivate(id, data);
    getBountiesData();
    toast.dismiss(loading);
    toast('Activated Opportunity Successful');
  };

  const deactivate = async (id, data) => {
    const loading = toast.loading('Deactivating...');
    activeAndDeactivate(id, data);
    getBountiesData();
    toast.dismiss(loading);
    toast('Deactivated Opportunity Successful');
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('snapbrillia-opportunity-admin.pdf');
  };

  useEffect(() => {
    getBountiesData();
  }, []);
  //should be memoized or stable
  // var startTime = performance.now();
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          companyId ? (
            <div>
              <Link to={`${row?._id}`}>View</Link> <p>{row?.title}</p>
            </div>
          ) : (
            row?.title
          ), //access nested data with dot notation
        header: 'Title',
        size: 150,
      },
      {
        accessorFn: (row) => row?.type,
        header: 'Type',
        size: 50,
      },
      {
        accessorFn: (row) => row?.companyId?.companyName,
        header: 'Company',
        size: 50,
      },
      // {
      //   accessorFn: (row) => row?.createdBy?.fullName, //normal accessorKey
      //   header: 'Creator Name',
      //   size: 100,
      // },
      {
        accessorFn: (row) => row?.workplaceType,
        header: 'Workplace Type',
        size: 50,
      },
      {
        accessorFn: (row) =>
          new Date(row.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 100,
      },
      {
        accessorFn: (row) =>
          row?.maxCompensation && row?.maxCompensation > 0
            ? row?.minCompensation +
              ' - ' +
              row?.maxCompensation +
              ' ' +
              row?.rewardType +
              ' ' +
              compensation(row)
            : (row?.rewardAmount || row?.minCompensation) +
              ' ' +
              row?.rewardType +
              ' ' +
              compensation(row),
        header: 'Compensation',
        size: 150,
      },
    ],
    []
  );
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={bounties}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 200, //set custom width
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <Typography component="span" variant="h4">
              Opportunities
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
              >
                Export Page Rows
              </Button>
            </Box>
          </>
        )}
        renderDetailPanel={({ row }) => (
          <Row>
            <Col>
              <h5>Description</h5>
              <div>{row.original.taskDescription}</div>
            </Col>
            <Col>
              <h5>Skills Required</h5>
              <ul>
                {row?.original?.skillIds?.map((item) => (
                  <div key={item?.name}>
                    <li>{item?.name}</li>
                  </div>
                ))}
              </ul>
            </Col>
          </Row>
        )}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              onClick={() => {
                row.original.type === 'Job'
                  ? clickEditJob(row.original, row.index)
                  : clickEdit(row.original, row.index);
              }}
            >
              Edit
            </Button>
            {row.original.funded === true ? (
              <Button
                onClick={async () => {
                  deactivate(row.original._id, { funded: false });
                }}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  activate(row.original._id, { funded: true });
                }}
              >
                Activate
              </Button>
            )}
          </Box>
        )}
      />
      {showModal && (
        <div>
          <BountyCreateStepsModal
            show={BountyCreateStepsModal}
            closeModal={(_id, isPrivate) => {
              closeModal();
              getBountiesData();
              if (_id && isPrivate) {
                navigate(`/bounties/${_id}/addCandidate`);
              }
            }}
            gig={editBounty}
            editSteps={pay ? 4 : 1}
            cryptoPay={false}
            setCryptoPay={() => {}}
          />
        </div>
      )}
      {showJobModal && (
        <div>
          <JobCreateStepsModal
            show={JobCreateStepsModal}
            closeJobModal={(_id, isPrivate) => {
              closeJobModal();
              getBountiesData();
              if (_id && isPrivate) {
                navigate(`/jobs/${_id}/addApplicant`);
              }
            }}
            job={editJob}
            editSteps={pay ? 4 : 1}
            cryptoPay={false}
            setCryptoPay={() => {}}
          />
        </div>
      )}
    </>
  );
};

export default AllOpportunitiesTable;
