import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import '../shared/css/typography.css';
import '../shared/css/bgColors.css';
import '../shared/css/sharedStyles.css';
// import GoogleDriveButton from '../shared/button/googleDrive';
// import UploadButton from '../shared/button/upload';
import { toast } from 'react-toastify';

import InviteButton from '../shared/button/invite';
import AddCandidateFields from '../candidateModal/components/addCandidateFields';
import { validateCandidateFields } from '../utils/functions';
import { useOnBoarding } from '../context/onboardingContext';
import * as OpportunitiesApi from '../api/opportunities';

const JobAddCandidateModal = ({
  bounty,
  handleClose,
  showModal,
  handleAddCandidate,
  id,
}) => {
  const { setBounty, addCandidate } = useOnBoarding();
  const [candidateFormErrors, setCandidateFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidateFields, setCandidateFields] = useState({
    fullName: '',
    email: '',
    linkedin: '',
    github: '',
    stackOverflow: '',
  });
  useEffect(() => {
    setBounty(bounty);
  }, [bounty]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorList = validateCandidateFields(candidateFields);
    setCandidateFormErrors(errorList);
    if (Object.keys(errorList).length === 0) {
      try {
        setLoading(true);
        const newCandidate = await OpportunitiesApi.inviteCandidate(
          id,
          candidateFields
        );
        handleAddCandidate(newCandidate);
        toast('Applicant added successfully');
      } catch (err) {
        toast(err.error);
      }
      setLoading(false);
    }
  };
  const handleDownload = () => {
    axios
      .get(`/files/SampleBulkInvite.csv`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'snapbrillia-import-template.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };
  return (
    <Modal onHide={handleClose} backdrop="static" show={showModal}>
      <Container className="add-candidate-modal-container">
        <Row>
          <Col>
            <Modal.Header className="modalHeader" closeButton>
              <Modal.Title>
                <span className="h2">{`Add Applicant`}</span>
              </Modal.Title>
            </Modal.Header>
          </Col>
        </Row>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  {`Here you can add a single applicant or upload a list of
    applicants to invite to your Job.`}
                </p>
              </Col>
            </Row>
            <Row>
              <AddCandidateFields
                candidateFields={candidateFields}
                setCandidateFields={setCandidateFields}
                formErrors={candidateFormErrors}
              />
            </Row>
            <Row>
              <Col>
                {/* <p>
                  You can{' '}
                  <span
                    style={{ color: ' #00ABFE', cursor: 'pointer' }}
                    onClick={handleDownload}
                  >
                    Click Here
                  </span>{' '}
                  and download our template spreadsheet to add all of your
                  candidates at once via the upload option below or import them
                  directly from Google Drive.
                </p> */}
              </Col>
            </Row>
          </Modal.Body>
          <Row>
            <Col xs={3}>{/* <UploadButton /> */}</Col>
            <Col xs={3}>{/* <GoogleDriveButton /> */}</Col>
            <Col xs={3}></Col>
            <Col xs={3}>
              {loading ? (
                <button className="btn-primary candidate-modal-button" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <InviteButton
                  className={`candidate-modal-button ${
                    candidateFields.email === '' ? 'btn-disabled' : ''
                  }`}
                  handleClick={handleSubmit}
                />
              )}
            </Col>
          </Row>
        </form>
      </Container>
    </Modal>
  );
};
export default JobAddCandidateModal;
