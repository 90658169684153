import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../shared/css/bgColors.css';
import './css/policyView.css';
import '../shared/css/sharedStyles.css';
import SnapbrilliaLogo from '../assets/SnapBetaLogo2.svg';
import SnapbrilliaClose from '../assets/icon/snapbrillia_close_icon.svg';
import TermsOfServiceSideMenu from '../shared/menus/termsOfServiceSideMenu';

import TermsOfUseText from './components/termsOfUseText';
import GeneralPolicyText from './components/generalPolicyText';
import CookiePolicyText from './components/cookiePolicyText';
import EuPolicyText from './components/euPolicyText';
import DataProcessPolicyText from './components/dataProcessPolicyText';
import VulnerabilityPolicyText from './components/vulnerabilityPolicyText';
import CaPolicyTest from './components/caPolicyTest';
import MSAPolicy from './components/MSAPolicy';
import { useEffect } from 'react';

const Policy = () => {
  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    history.replaceState(null, null, hash);
  }, [hash]);

  let initPolicy = 0;
  let initTitle = 'Use Policies';

  switch (hash) {
    case '#General-Privacy-Policy':
      initPolicy = 1;
      initTitle = 'General Privacy Policy';
      break;
    case '#Cookie-Policy':
      initPolicy = 2;
      initTitle = 'Cookie Policy';
      break;
    case '#California-Consumer-Privacy-Notice':
      initPolicy = 3;
      initTitle = 'California Consumer Privacy Notice';
      break;
    case '#EU-User-Privacy-Policy':
      initPolicy = 4;
      initTitle = 'EU User Privacy Policy';
      break;
    case '#Data-Processing-and-Security-Addendum':
      initPolicy = 5;
      initTitle = 'Data Processing and Security Addendum';
      break;
    case '#Vulnerability-Disclosure':
      initPolicy = 6;
      initTitle = 'Vulnerability Disclosure';
      break;
    case '#saasmsa':
      initPolicy = 7;
      initTitle = 'SAAS MSA Policy';
      break;
    default:
    case 'Term-of-Use':
      initPolicy = 0;
      initTitle = 'Use Policies';
  }

  const [policy, setPolicy] = useState(initPolicy);
  const [title, setTitle] = useState(initTitle);

  const policyText = [
    <TermsOfUseText key={1} />,
    <GeneralPolicyText key={2} />,
    <CookiePolicyText key={3} />,
    <CaPolicyTest key={4} />,
    <EuPolicyText key={5} />,
    <DataProcessPolicyText key={6} />,
    <VulnerabilityPolicyText key={7} />,
    <MSAPolicy key={8} />,
  ];

  return (
    <>
      <Container className="primarybg" fluid>
        <Row className=" full-page">
          <Col md={2}>
            <Row>
              <Col>
                <img src={SnapbrilliaLogo} alt="snapbrillia logo" />
              </Col>
            </Row>
            <Row className="policy-side-bar">
              <Col>
                <TermsOfServiceSideMenu
                  setTitle={setTitle}
                  setPolicy={setPolicy}
                  setHash={setHash}
                  policy={policy}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </Col>
          <Col className="pagemainbg policy-main-content" md={10}>
            <Row>
              <Col xs={11}>
                <p className="policy-text-left policy-page-title-text">
                  {title}
                </p>
              </Col>
              <Col xs={1} className="policy-close-button">
                <img
                  src={SnapbrilliaClose}
                  alt="close-icon"
                  onClick={() => window.history.back()}
                />
              </Col>
            </Row>
            <Row>
              <Col>{policyText[policy]}</Col>
            </Row>

            {/* <Row>
              <Col></Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Policy;
