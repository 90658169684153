import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/assessmentExplaination.css';
import '../../shared/css/typography.css';
import Finish from '../../shared/button/finish';

const AssessmentFailed = ({
  handleRightArrow,
  handleLeftArrow,
  setPage,
  failed,
  setFailed,
}) => {
  const handleImprovement = (e) =>
    setFailed({ ...failed, improvement: e.target.value });
  const handleSolution = (e) =>
    setFailed({ ...failed, solution: e.target.value });
  return (
    <Container className="assessment-right-component-container">
      <Row>
        <Col className="assessment-text-box-containers">
          <span className="h4 bold">
            Find the difference between two numbers
          </span>
          <p className="assessment-failed">Failed</p>
        </Col>
        <Col className="assessment-text-box-containers" xs={2}>
          <div className="assessment-results-icon-container">
            <div
              onClick={handleLeftArrow}
              className="assessment-icon assessment-forward-icon"
            ></div>
            <div
              onClick={handleRightArrow}
              className="assessment-icon assessment-next-icon"
            ></div>
          </div>
        </Col>
      </Row>
      <form>
        <Row className="assessment-text-box-containers">
          <Col xs={12}>
            <label className="assessment-label-text" htmlFor="explaination-1">
              Why did you try to implement your solution in this way? (Optional)
            </label>
            <textarea
              className="assessment-text-area"
              placeholder="Pro Tip: Candidates who share their thoughts are 3x more likely to hear back from
                hiring teams."
              value={failed.solution}
              onChange={(e) => handleSolution(e)}
            ></textarea>
          </Col>
        </Row>
        <Row>
          <Col className="assessment-text-box-containers ">
            <label className="assessment-label-text">
              How would you improve your code if you had more time? (Optional)
            </label>
            <textarea
              placeholder="Pro Tip: Boost your chances by impressing the hiring team with a clear thought"
              className="assessment-text-area"
              value={failed.improvement}
              onChange={(e) => handleImprovement(e)}
            ></textarea>
          </Col>
        </Row>

        <Row>
          <Col>
            <Finish
              onClick={() => setPage('finished')}
              type="submit"
              className="assessment-submit-button"
            />
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default AssessmentFailed;
