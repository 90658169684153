import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/candidateWaitingRoom.css';
import '../../shared/css/bgColors.css';
import '../../shared/css/textColors.css';
import '../../shared/css/buttonStyles.css';
import InterviewerWelcomingMessage from './interviewerWelcomingMessage';
import InterviewerRoomDisclaimer from './interviewerRoomDisclaimer';

const InterviewerWaitingRoom = () => {
  return (
    <Container className="candidate-waiting-room-container primarybg primary">
      <Row>
        <Col>
          <InterviewerWelcomingMessage />
        </Col>
      </Row>
      <Row>
        <Col className="candidate-waiting-room-row2-col">
          <InterviewerRoomDisclaimer />
        </Col>
      </Row>
      <Row>
        <Col className="candidate-waiting-room-row3-col">
          <button className="btn-primary" style={{ marginTop: '10px' }}>
            CONTACT CANDIDATE
          </button>
          <button className="btn-primary" style={{ marginTop: '10px' }}>
            CONTACT RECRUITER
          </button>
          <button className="btn-primary" style={{ marginTop: '10px' }}>
            LOGIN AS INTERVIEWER
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default InterviewerWaitingRoom;
