import React from 'react';
import './css/notifications.css';
import '../shared/css/typography.css';

const Notifications = () => {
  return (
    <div className="box">
      <div className="h1 noti_title">System Outage</div>
      <div className="note">General Maintenance scheduled for 1/15/22</div>
    </div>
  );
};

export default Notifications;
