import { useEffect, useRef } from 'react';
import '../../shared/css/typography.css';

//COMPONENTS
import { Col, Container, Row } from 'react-bootstrap';
import { navigate } from '@reach/router';

//CSS
import './css/notification.css';
import * as NotificationAPI from '../../api/notification';
import { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { timeAgo } from '../../utils/functions';

const Notification = ({
  showNotifications,
  setShowNotifications,
  loadNotify,
}) => {
  const componentRef = useRef();
  const [notifications, setNotifications] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const { auth } = useAuth();
  const [allNoti, setAllNoti] = useState([]);

  const loadNotifications = async () => {
    try {
      const { docs } = await NotificationAPI.getListNotifications();
      const notiB2b = docs.filter((user) => user.typeUser === 'b2b');
      setNotifications(notiB2b);
      // console.log(docs);
    } catch (err) {}
  };

  const loadOlderNotifications = async () => {
    try {
      const { docs } = await NotificationAPI.getOlderListNotifications();
      const notiB2b = docs.filter((user) => user.typeUser === 'b2b');
      setNotifications(notiB2b);
      const notiNotRead = docs.filter(
        (user) => user.isRead === false && user.typeUser === 'b2b'
      );
      const notReadId = notiNotRead.map((noti) => noti._id);
      setCandidate(notReadId);
    } catch (err) {}
  };

  const readNotification = async (id) => {
    try {
      await NotificationAPI.readNotification(id);
      loadNotifications();
      loadNotify();
    } catch (err) {}
  };

  const readAllNotification = async () => {
    try {
      await NotificationAPI.readAllNotifications(auth.user._id);
      loadNotifications();
      loadNotify();
    } catch (err) {}
  };

  const getAllNotification = async () => {
    try {
      const { docs } = await NotificationAPI.getAllUserNotifications();
      // console.log(docs);
      const notiB2b = docs.filter((user) => user.typeUser === 'b2b');
      setAllNoti(notiB2b);
    } catch (err) {}
  };

  const handleOutsideClick = (componentRef, e) => {
    if (e.target.classList.contains('nav-bar-icon')) {
      return;
    }
    return componentRef.current && !componentRef.current.contains(e.target)
      ? setShowNotifications(false)
      : null;
  };

  useEffect(() => {
    loadNotifications();
    getAllNotification();
    document.addEventListener('onclick', (e) =>
      handleOutsideClick(componentRef, e)
    );
    return () => document.removeEventListener('onclick', handleOutsideClick);
  }, []);

  return (
    <Container
      className="notifications-modal"
      ref={componentRef}
      style={{ display: `${showNotifications ? 'block' : 'none'}` }}
    >
      <Row
        className="notifications-content primarybg notification"
        style={{
          height:
            notifications.length > 2
              ? 500
              : notifications.length <= 0
              ? 200
              : 300,
        }}
      >
        <Row>
          <Col>
            <h5 className="text-start primary mb-3 bold">Notifications</h5>
          </Col>
        </Row>
        {notifications.length == 0 ? (
          <Row>
            <Col className="p-2 mb-4 text-center">
              <span className="d-block h4 bold">
                You have no new notifications.
              </span>
              <span className="grey-title">Check in later!</span>
            </Col>
          </Row>
        ) : (
          notifications.map((item, index) => {
            if (item.isRead === true) {
              return (
                <Row key={index}>
                  <Col
                    className="inputbg rounded-3 text-start p-2 mb-4 pointer"
                    onClick={() => {
                      readNotification(item._id);
                      // eslint-disable-next-line no-undef
                      navigate(item.url);
                    }}
                  >
                    <span className="d-block text-start primary medium mb-2">
                      {item.title}
                    </span>
                    <span className="text-center">{item.content}</span>
                    <span className="d-block text-end mt-2">
                      {timeAgo(item?.createdAt)}
                    </span>
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row key={index}>
                  <Col
                    className="inputbg rounded-3 text-start p-2 mb-4 pointer"
                    onClick={() => {
                      readNotification(item._id);
                      // eslint-disable-next-line no-undef
                      navigate(item.url);
                    }}
                  >
                    <span className="new-notify" />
                    <span className="d-block text-start primary medium mb-2">
                      {item.title}
                    </span>
                    <span className="text-center">{item.content}</span>
                    <span className="d-block text-end mt-2">
                      {timeAgo(item?.createdAt)}
                    </span>
                  </Col>
                </Row>
              );
            }
          })
        )}
        {allNoti.length > 0 &&
          (allNoti.length !== 0 && notifications.length !== 0 ? (
            <Row>
              <Col
                className="text-center"
                onClick={() => {
                  loadOlderNotifications();
                }}
              >
                <span className="pointer link">Show Older</span>
              </Col>
              <Col
                className="text-center"
                onClick={() => {
                  readAllNotification();
                  loadNotifications();
                }}
              >
                <span className="pointer link">Mark All As Read</span>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                className="text-center"
                onClick={() => {
                  loadOlderNotifications();
                }}
              >
                <span className="pointer link">Show Older</span>
              </Col>
              <Col
                className="text-center"
                onClick={() => {
                  readAllNotification();
                  loadNotifications();
                }}
              >
                <span className="pointer link">Mark As Read</span>
              </Col>
            </Row>
          ))}
      </Row>
    </Container>
  );
};

export default Notification;
