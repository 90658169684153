import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SnapbrilliaLogo from '../assets/SnapBetaLogo2.svg';

import '../shared/css/bgColors.css';
import './css/assessmentResults.css';

import AssessmentPassed from './components/assessmentPassed';
import AssessmentFailed from './components/assessmentFailed';
import AssessmentFinished from './components/assessmentFinished';
import AssessmentOverview from './components/assessmentOverview';
import AssessmentResultsHeader from './components/assessmentResultsHeader';

import data from '../__test__/__testData__/testData';
import { useState } from 'react';
import Exit from '../shared/button/exit';

const AssessmentResults = () => {
  const [page, setPage] = useState(0);
  const [passed, setPassed] = useState({ solution: '', improvement: '' });
  const [failed, setFailed] = useState({ solution: '', improvement: '' });

  const handleRightArrow = () => {
    if (page === 1) {
      setPage(0);
    } else {
      setPage(page + 1);
    }
  };
  const handleLeftArrow = () => {
    if (page === 0) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  };

  return (
    <Container className="assessment-main-container" fluid>
      <Row className="assessment-header-row">
        <Col style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}>
          <img src={SnapbrilliaLogo} alt="snapbrillia logo" />
          <AssessmentResultsHeader
            minutes={data.WSProject.duration}
            challenges={data.assessment.workSampleValuationList.length}
          />
        </Col>
      </Row>

      <Row className="assessment-main-content">
        <Col className="assessment-left-col">
          <AssessmentOverview
            WSProject={data.WSProject}
            assessment={data.assessment}
            company={data.company}
          />
        </Col>
        <Col className="assessment-right-col">
          {page === 0 && (
            <AssessmentPassed
              {...{ handleRightArrow, handleLeftArrow, passed, setPassed }}
            />
          )}
          {page === 1 && (
            <AssessmentFailed
              {...{
                handleRightArrow,
                handleLeftArrow,
                setPage,
                failed,
                setFailed,
              }}
            />
          )}
          {page === 'finished' && <AssessmentFinished />}
        </Col>
      </Row>
      <Exit className="assessment-exit-button" />
    </Container>
  );
};

export default AssessmentResults;
