import React from 'react';
import InvoicedBountiesView from './invoicedBountiesView';

export default function CreateBountyView() {
  return (
    <>
      <InvoicedBountiesView></InvoicedBountiesView>
    </>
  );
}
