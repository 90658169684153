import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ChallenageIcon from '../../assets/icon/snapbrillia_challenge_icon.svg';
import ClockIcon from '../../assets/icon/snapbrillia_clock_icon.svg';
import '../css/assessmentResultsHeaderComponents.css';
import '../../shared/css/typography.css';
import data from '../../__test__/__testData__/testData';

// props: { minutes, challenges }
const GetStartedResultsHeader = () => {
  const { WSProject, tasks } = data;
  return (
    <Container className="assessment-header-main">
      <Row>
        <Col className="assessment-title-container">
          <span className="h1">Results</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <img
            className="assessment-header-right-padding"
            src={ClockIcon}
            alt="svg clock"
          />
          <span className="policy_typography">
            {WSProject.duration} Minutes
          </span>
        </Col>
        <Col>
          <img
            className="assessment-header-right-padding"
            src={ChallenageIcon}
            alt="svg challenge"
          />
          <span className="policy_typography">
            {tasks[0].expectedDuration} Challenges
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default GetStartedResultsHeader;
