import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/candidateWaitingRoom.css';
import '../../shared/css/bgColors.css';
import '../../shared/css/textColors.css';
import '../../shared/css/buttonStyles.css';
import WaitingRoomWelcomeMessage from './waitingRoomWelcomeMessage';
import WaitingRoomMessage from './waitingRoomMessage';
import WaitingRoomDisclaimer from './waitingRoomDisclaimer';

const CandidateWaitingRoom = () => {
  return (
    <Container className="candidate-waiting-room-container primarybg primary">
      <Row>
        <Col>
          <WaitingRoomWelcomeMessage />
        </Col>
      </Row>
      <Row>
        <Col className="candidate-waiting-room-row2-col">
          <WaitingRoomMessage />
          <WaitingRoomDisclaimer />
        </Col>
      </Row>
      <Row>
        <Col className="candidate-waiting-room-row3-col">
          <button className="btn-primary" style={{ marginTop: '10px' }}>
            CONTACT RECRUITER
          </button>
          <button className="btn-primary" style={{ marginTop: '10px' }}>
            LOGIN AS INTERVIEWER
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default CandidateWaitingRoom;
