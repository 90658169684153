import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { similarity } from '../utils/functions';
import { isEmpty, find } from 'lodash';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { toast } from 'react-toastify';

import '../shared/css/typography.css';
import '../shared/css/buttonStyles.css';
import './css/candidateModal.css';
import Back from '../shared/button/back';
import UploadButton from '../shared/button/upload';
import AddCandidateFields from './components/addCandidateFields';
import { validateCandidateFields } from '../utils/functions';
import ImportCandidateText from './components/importCandidateText';
import ImportCandidateHeader from './components/importCandidateHeader';
import UploadFiles from './components/uploadFiles';
import ImportCheckBoxes from './components/importCheckBoxes';
import InviteButton from '../shared/button/invite';
import AnalyzingFilesResults from './components/analyzingFilesResults';
// import { useEffect } from 'react';

const CandidateModal = ({
  closeModal,
  workSampleProjectId,
  handleAddCandidate,
  handleAddCandidates,
}) => {
  const [showModal, setShowModal] = useState(true);
  const [importedFile, setImportedFile] = useState(false);
  const [errors, setErrors] = useState();
  const [success, setSuccess] = useState();
  const [checked, setChecked] = useState({ ignore: false, read: false });

  const [csvData, setCSVData] = useState();
  const [columns, setColumns] = useState();
  const [uploadState, setUploadState] = useState(0);
  const [fileNames, setFileNames] = useState();
  const [importResume, setImportResume] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [docId, setDocId] = useState('');
  const [candidateFields, setCandidateFields] = useState([{}]);
  const [listCandidates, setListCandidates] = useState([]);
  // const [sheetNames, setSheetNames] = useState([]);
  // const [sheetId, setSheetId] = useState();

  const [step, setStep] = useState(1);
  const [candidateFormErrors, setCandidateFormErrors] = useState({});
  // const [enableGoogle, setEnableGoogle] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    closeModal();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step === 2) {
      if (listCandidates) {
        const result = await handleAddCandidates(listCandidates);
        if (result.length > 0) {
          toast.success('Successful!');
        } else {
          toast.warn('Candidate already import!');
        }
        handleClose();
      }
    }
    const errorList = validateCandidateFields(candidateFields);
    setCandidateFormErrors(errorList);
    if (Object.keys(errorList).length === 0) {
      try {
        await handleAddCandidate(candidateFields);
        toast('Successful!');
        handleClose();
      } catch (error) {
        toast.error('Failed!');
      }
    }
  };
  const handleDownload = () => {
    axios
      .get(`/files/SampleBulkInvite.csv`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'snapbrillia-import-template.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const processErrors = (cols) => {
    let errors = 0;
    let success = 0;
    for (let i = 1; i < cols.length; i++) {
      if (cols[i].field) {
        success++;
      } else {
        errors++;
      }
    }
    if (errors && success) {
      setUploadState(3);
    } else if (errors && !success) {
      setUploadState(2);
    } else {
      setUploadState(1);
    }

    setErrors(errors);
    setSuccess(success);
  };

  const processData = async (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            // eslint-disable-next-line max-depth
            if (d[0] === '"') {
              d = d.substring(1, d.length - 1);
            }
            // eslint-disable-next-line max-depth
            if (d[d.length - 1] === '"') {
              d = d.substring(d.length - 2, 1);
            }
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    let candidateModelFields = fieldList.map((x) => x.value);
    // prepare columns list from headers
    let columns = headers.map((c) => {
      if (!c) {
        return null;
      }
      const normalizedText = c.replace(/[^a-zA-Z]/g, '').toLowerCase();
      let dbField = find(
        candidateModelFields,
        (x) => x.toLowerCase() === normalizedText
      );

      if (!dbField) {
        dbField = find(
          candidateModelFields,
          (x) => similarity(x, normalizedText) > 0.9
        );
      }
      return {
        name: c,
        selector: c,
        field: dbField,
      };
    });

    if (!isEmpty(columns)) {
      columns = columns
        .map((item) => {
          if (!item) {
            return null;
          }
          const field = fieldList.find(
            (field) => field.value.toLowerCase() === item.name.toLowerCase()
          );
          item.selector = field ? field.field : item.name;
          return item;
        })
        .filter(Boolean);
    } // else if (field === undefined) {
    // }
    setCSVData(list);
    if (list) {
      const candidates = [];
      list.map((item) => {
        const field = {};
        columns.map((column) => {
          field[column.field] = item[column.name];
        });
        candidates.push(field);
      });
      setListCandidates(candidates);
    }
    setColumns(columns);
    processErrors(columns);
  };

  const handleFile = (files) => {
    // if fields is undefined that is an error and we need to store the number of errors
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    setFileNames(files.map((file) => file.name));
    setImportedFile(true);
    reader.onload = (evt) => {
      const isSpreadSheet =
        file.type.includes('csv') ||
        file.type.includes('sheet') ||
        file.type.includes('excel');
      if (isSpreadSheet) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data);
        setImportResume(false);
      } else {
        uploadResume(file);
        setImportResume(true);
      }
    };
    reader.readAsBinaryString(file);
  };

  const uploadResume = (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('workSampleProjectId', workSampleProjectId);
    formData.append('ignoreError', `${checked.ignore}`);
    axios
      .post(`/api/work-sample-project/add-resume`, formData, {
        withCredentials: true,
      })
      .then(() => {
        setLoading(false);
        setStep((prevStep) => prevStep + 1);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
    handleClose();
  };

  return (
    <Modal
      contentClassName="candidate-modal-container import-candidate-text"
      onHide={handleClose}
      backdrop="static"
      show={showModal}
      size="lg"
      fullscreen="lg-down"
    >
      <Container className="">
        <Modal.Header className="candidate-modal-header" closeButton>
          <Row>
            <Col>
              <ImportCandidateHeader className="h2" />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <>
              <Row>
                <Col>
                  <p>
                    {`Here you can add a single candidate or upload a list of
 candidates to invite to your project and take the assessment.`}
                  </p>
                </Col>
              </Row>
              <Row>
                <AddCandidateFields
                  candidateFields={candidateFields}
                  setCandidateFields={setCandidateFields}
                  formErrors={candidateFormErrors}
                />
              </Row>
              <Row>
                <Col>
                  <p>
                    You can{' '}
                    <span
                      style={{ color: ' #00ABFE', cursor: 'pointer' }}
                      onClick={handleDownload}
                    >
                      Click Here
                    </span>{' '}
                    and download our template spreadsheet to add all of your
                    candidates at once via the upload option below or import
                    them directly from Google Drive.
                  </p>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="candidate-modal-main-content">
              <Col xs={12} style={{ padding: '0px' }}>
                <ImportCandidateText />
              </Col>

              <Col
                xs={12}
                style={{
                  margin: '45px 0px',
                  padding: '0px 5px',
                  height: '200px',
                }}
              >
                {!importedFile && (
                  <UploadFiles
                    {...{ setImportedFile, handleFile, setUploadState }}
                  />
                )}
                {importedFile && (
                  <AnalyzingFilesResults
                    {...{ uploadState, fileNames, errors, success }}
                  />
                )}
              </Col>

              <Col xs={12} style={{ padding: '0px 5px' }}>
                <ImportCheckBoxes {...{ checked, setChecked }} />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Container>
          <Row>
            {step === 1 && (
              <Col>
                <UploadButton
                  onClick={() => {
                    setStep(2);
                  }}
                />
              </Col>
            )}
            <Col>
              {step === 2 && (
                <Back
                  onClick={() => {
                    setStep(1);
                  }}
                />
              )}
              <InviteButton
                className="candidate-modal-button"
                handleClick={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </Modal>
  );
};

export default CandidateModal;

const fieldList = [
  {
    field: 'First Name',
    value: 'firstName',
  },
  {
    field: 'Last Name',
    value: 'lastName',
  },
  {
    field: 'Email',
    value: 'email',
  },
  {
    field: 'LinkedIn',
    value: 'linkedin',
  },
  {
    field: 'Hiretual',
    value: 'hiretual',
  },
  {
    field: 'Github',
    value: 'github',
  },
  {
    field: 'Facebook',
    value: 'facebook',
  },
  {
    field: 'Twitter',
    value: 'twitter',
  },
  {
    field: 'Full Name',
    value: 'fullName',
  },
  {
    field: 'Title',
    value: 'title',
  },
  {
    field: 'Company',
    value: 'company',
  },
  {
    field: 'Phone',
    value: 'phone',
  },
  {
    field: 'Extra Email(s)',
    value: 'extraEmails',
  },
  {
    field: 'Expertise',
    value: 'expertise',
  },
  {
    field: 'Skills',
    value: 'skills',
  },
  {
    field: 'City',
    value: 'city',
  },
  {
    field: 'State',
    value: 'state',
  },
  {
    field: 'Country',
    value: 'country',
  },
  {
    field: 'School',
    value: 'school',
  },
  {
    field: 'Degree',
    value: 'degree',
  },
  {
    field: 'Major',
    value: 'major',
  },
  {
    field: 'Graduation Year',
    value: 'graduationYear',
  },
  {
    field: 'Source',
    value: 'source',
  },
  {
    field: 'Tags',
    value: 'tags',
  },
  {
    field: "Don't import this field",
    value: 'dni',
  },
];
