import React from 'react';
import { useState } from 'react';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { getEmail, sendEmailNoti } from '../../api/jobs';
import { useEffect } from 'react';
import '../css/editor.css';
import { toast } from 'react-toastify';

const SendRejectEmail = ({
  sendRejectEmail,
  setSendRejectEmail,
  applicantId,
  jobId,
}) => {
  const convertstHtml = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  const [emailText, setEmailText] = useState(() => EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getEmailTemplate = async () => {
      setIsLoading(true);
      await getEmail(jobId, applicantId, 'reject').then((value) => {
        setEmailText(convertstHtml(value));
      });
      setIsLoading(false);
    };
    getEmailTemplate();
  }, []);

  const handleClose = () => setSendRejectEmail(false);

  const sendAcceptanceEmail = async () => {
    const htmlEncoded = draftToHtml(
      convertToRaw(emailText.getCurrentContent())
    );
    await sendEmailNoti(jobId, applicantId, {
      message: htmlEncoded,
      review: 'Rejected',
    });
    setIsLoading(true);
    setSendRejectEmail(false);
    setIsLoading(false);
    return toast('Rejection Email Sent Successfully');
  };
  return (
    <Modal
      onHide={handleClose}
      backdrop="static"
      size="lg"
      fullscreen="lg-down"
      show={sendRejectEmail}
    >
      <Container className="add-candidate-modal-container">
        <Row>
          <Col>
            <Modal.Header className="modalHeader" closeButton>
              <Modal.Title>
                <span className="h2">Send Rejection Email To Candidate</span>
              </Modal.Title>
            </Modal.Header>
          </Col>
        </Row>
        <Modal.Body>
          {isLoading ? (
            <>
              <Spinner
                className="mt-3"
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            <div className="editor">
              <Editor
                editorState={emailText}
                onEditorStateChange={(emailText) => setEmailText(emailText)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <button
                  onClick={() => setSendRejectEmail(false)}
                  style={{
                    minWidth: '180px',
                    // padding: '5px 20px',
                    backgroundColor: 'red',
                    border: 'none',
                    color: '#fff',
                    borderRadius: 10,
                    height: 30,
                    fontWeight: 'bold',
                  }}
                >
                  Cancel Email
                </button>
              </Col>
              <Col>
                <button
                  onClick={() => sendAcceptanceEmail()}
                  className="btn-primary"
                >
                  Send Rejection Email
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Container>
    </Modal>
  );
};

export default SendRejectEmail;
