import axios from 'axios';

export async function getAllSalesCompanies() {
  const response = await axios.get(`/api/v1/sales/companies`);
  return response.data;
}

export async function createSalesUser(data) {
  const response = await axios.post(`/api/v1/sales/user`, data);
  return response.data;
}

export async function createSalesOpportunity(data) {
  const response = await axios.post(`/api/v1/sales/opportunity`, data);
  return response.data;
}
