import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
// import { Link } from '@reach/router';

import '../../collectionBounties/css/bountySummary.css';
import '../../shared/css/sharedStyles.css';
import { formatDate } from '../../utils/functions';
import * as BountyApi from '../../api/bounties';
import { formatGigOrJobSkills } from '../../shared/utils';

const JobSummary = ({ bounty }) => {
  const [summary, setSummary] = useState({
    pullRequest: 0,
    accepted: 0,
  });
  // useEffect(() => {
  //   const getSummary = async () => {
  //     try {
  //       const response = await BountyApi.getBountySummary(bounty._id);
  //       setSummary(response);
  //     } catch (err) {
  //       //ignore
  //     }
  //   };
  //   getSummary();
  // }, []);

  const bountySummaryAccepted = useMemo(
    () =>
      summary
        ? summary.accepted && summary.accepted
          ? summary.accepted + summary.completed
          : 0
        : 0,
    [summary]
  );
  return (
    <Container fluid className="bounty-summary-container">
      <Row>
        <Col xs={3} md={3}>
          <Col className="">
            <span className="bounty-summary-total-header">
              Total Candidates
            </span>
          </Col>
          <span className="bounty-summary-details-info">
            <span className="bounty-summary-total-data">
              {bounty?.assessmentIds?.length || 0}
            </span>
          </span>

          {/* <Col className="pt-3">
            <span className="bounty-summary-total-header">Pull Request</span>
          </Col>
          <span className="bounty-summary-details-info">
            <span className="boundy-summary-pull-request">
              {summary.pullRequest || '0'}
            </span>
          </span>
          <Col className="pt-3">
            <span className="bounty-summary-total-header">Accepted</span>
          </Col>
          <span className="bounty-summary-details-info">
            <span className="bounty-summary-accepted">
              {bountySummaryAccepted}
            </span>
          </span>*/}
        </Col>

        <Col xs={6} md={9} xl={4}>
          <Row>
            <Col xs={12} md={4} className="col-6">
              <Row className="bounty-summary-areas-float">
                <Col className="col-6">
                  <span className="bounty-summary-details">Skills:</span>
                </Col>
                <Row>
                  <Col xs={6}>
                    <p>{formatGigOrJobSkills({ skillIds: bounty.skillIds })}</p>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col xs={12} md={4} className="col-4">
              {/* <Row className="bounty-summary-areas-float">
                <Col className="col-6">
                  <span className="bounty-summary-details">Difficulty</span>
                </Col>
                <span className="bounty-summary-details-info">
                  <span className="bounty-summary-details-info">
                    {bounty?.level}
                  </span>
                </span>
              </Row> */}
            </Col>
            <Col xs={12} md={4} className="col-4">
              <Row className="bounty-summary-areas-float">
                <Col className="col-6">
                  <span className="bounty-summary-details">Date Created</span>
                </Col>
                <span className="bounty-summary-details-info">
                  <span className="bounty-summary-details-info">
                    {formatDate(bounty.createdAt)}
                  </span>
                </span>
              </Row>
              {/* <Row className="bounty-summary-areas-float">
                <Col className="col-2">
                  <span className="bounty-summary-details">Repo:</span>
                </Col>
                <span className="bounty-summary-details-info">
                  <span className="bounty-summary-details-info">
                    {bounty.useGithupApp === true && (
                      <a href={bounty.url} target="_blank" rel="noreferrer">
                        Github Repo
                      </a>
                    )}
                  </span>
                </span>
              </Row> */}
            </Col>
            <Col xs={12} md={4} className="col-4">
              {/* <Col className="col-6">
                    <span className="bounty-summary-details">Repo Title:</span>
                  </Col>
                  <span className="bounty-summary-details-info">
                    <span className="bounty-summary-details-info">
                      {bounty.repoName}
                    </span>
                  </span>
                </Row> */}
            </Col>
            <Col xs={12} md={4} className="col-4">
              {/* <Row className="bounty-summary-areas-float">
                <Col className="col-2">
                  <span className="bounty-summary-details">Tags:</span>
                </Col>
                <span className="bounty-summary-details-info">
                  {bounty.tags.map((tag, index) => {
                    return (
                      <span className="skillpills" key={index}>
                        {tag}
                      </span>
                    );
                  })}
                </span>
              </Row> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default JobSummary;
