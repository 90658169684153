import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { validateMemberFields } from '../utils/functions';

import './css/initialSetup.css';
import Skipbutton from '../shared/button/skip';
import Nextbutton from '../shared/button/next';

import AddTeamWelcomeMessage from './components/addTeamWelcomeMessage';
import AddMemberFormHeader from './components/addMemberFormHeader';
import AddMemberFields from '../memberModal/components/addMemberFields';

import Logo from '../assets/SnapBetaLogo2.svg';
import Illustrations1 from '../assets/illustrations/snapbrillia_setup_1_illustration.png';

import { useOnBoarding } from '../context/onboardingContext';
import { useAuth } from '../context/authContext';

const SetUpAddTeam = () => {
  const { addMembers, skipOnboarding } = useOnBoarding();
  const { auth } = useAuth();

  const [members, setMembers] = useState(
    Array(3).fill({
      fullName: '',
      email: '',
      blind: true,
      role: 'Member',
    })
  );
  const [formErrors, setFormErrors] = useState(Array(3).fill({}));

  const setMember = (index) => {
    return (member) => {
      const newMembers = [...members];
      newMembers[index] = member;
      setMembers(newMembers);
    };
  };

  const checkForm = () => {
    const arr = members;
    let errorList = [{}, {}, {}];
    for (let i = 0; i < formErrors.length; i++) {
      if (arr[i].fullName && arr[i].email) {
        errorList[i] = validateMemberFields(arr[i]);
      }
    }
    setFormErrors(errorList);
    const errors1 = Object.keys(errorList[0]).length === 0;
    const errors2 = Object.keys(errorList[1]).length === 0;
    const errors3 = Object.keys(errorList[2]).length === 0;
    if (errors1 && errors2 && errors3) {
      addMembers(members);
    }
  };

  useEffect(() => {
    let liveChatButton = document.querySelector('#fc_frame');
    if (liveChatButton) {
      liveChatButton.classList.add('custom_fc_frame');
    } else {
      setTimeout(() => {
        liveChatButton = document.querySelector('#fc_frame');
        liveChatButton.classList.add('custom_fc_frame');
      }, 100);
    }
    return () => {
      liveChatButton.classList.remove('custom_fc_frame');
    };
  }, []);

  return (
    <Container fluid className="setup-team-main-container">
      <Row>
        <Col style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}>
          <img src={Logo} alt="logo" />
        </Col>
      </Row>
      <Row className="setup-add-team-content">
        <Col xs={12} md={6} className="setup-add-project-left-col">
          <AddTeamWelcomeMessage user={auth.user.fullName} />

          <div className="setup-add-team-illus">
            <img src={Illustrations1} />
          </div>
        </Col>
        <Col xs={12} md={6} className="setup-add-project-right-col pe-0">
          <Row>
            <div className="setup-form-member-header">
              <AddMemberFormHeader />
            </div>
            <div className="setup-add-team-container py-3 px-4">
              <AddMemberFields
                memberFields={members[0]}
                setMemberFields={setMember(0)}
                formErrors={formErrors[0]}
                person="1"
              />
              <AddMemberFields
                memberFields={members[1]}
                setMemberFields={setMember(1)}
                formErrors={formErrors[1]}
                person="2"
              />
              <AddMemberFields
                memberFields={members[2]}
                setMemberFields={setMember(2)}
                formErrors={formErrors[2]}
                person="3"
              />
            </div>
          </Row>
          <Row className="flex-fill pt-2 pb-4">
            <Col className="setup-add-project-button">
              <span className="me-4">
                <Skipbutton type="button" onClick={skipOnboarding} />
              </span>
              <Nextbutton type="submit" onClick={checkForm} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SetUpAddTeam;
