/* eslint-disable */
import '../../shared/css/typography.css';
import axios from 'axios';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import GoogleDriveButton from '../../shared/button/googleDrive';
import UploadButton from '../../shared/button/upload';
import GooglePicker from '../../shared/GooglePicker';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useOnBoarding } from '../../context/onboardingContext';

import '../css/initialSetup.css';

const fieldList = [
  {
    field: 'First Name',
    value: 'firstName',
  },
  {
    field: 'Last Name',
    value: 'lastName',
  },
  {
    field: 'Email',
    value: 'email',
  },
  {
    field: 'LinkedIn',
    value: 'linkedin',
  },
  {
    field: 'Hiretual',
    value: 'hiretual',
  },
  {
    field: 'Github',
    value: 'github',
  },
  {
    field: 'Facebook',
    value: 'facebook',
  },
  {
    field: 'Twitter',
    value: 'twitter',
  },
  {
    field: 'Full Name',
    value: 'fullName',
  },
  {
    field: 'Title',
    value: 'title',
  },
  {
    field: 'Company',
    value: 'company',
  },
  {
    field: 'Phone',
    value: 'phone',
  },
  {
    field: 'Extra Email(s)',
    value: 'extraEmails',
  },
  {
    field: 'Expertise',
    value: 'expertise',
  },
  {
    field: 'Skills',
    value: 'skills',
  },
  {
    field: 'City',
    value: 'city',
  },
  {
    field: 'State',
    value: 'state',
  },
  {
    field: 'Country',
    value: 'country',
  },
  {
    field: 'School',
    value: 'school',
  },
  {
    field: 'Degree',
    value: 'degree',
  },
  {
    field: 'Major',
    value: 'major',
  },
  {
    field: 'Graduation Year',
    value: 'graduationYear',
  },
  {
    field: 'Source',
    value: 'source',
  },
  {
    field: 'Tags',
    value: 'tags',
  },
  {
    field: "Don't import this field",
    value: 'dni',
  },
];

const ImportUploadTemplate = () => {
  const hiddenFileInput = useRef(null);
  const [columns, setColumns] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);
  const [sheetId, setSheetId] = useState();
  const [docId, setDocId] = useState('');
  const { uploadResume, bulkUploadCandidate } = useOnBoarding();

  const customPicker = (google, oauthToken) => {
    const googleViewId = google.picker.ViewId.DOCS;
    const docsView = new google.picker.DocsView(googleViewId).setIncludeFolders(
      true
    );
    const builder = new google.picker.PickerBuilder()
      .addView(docsView)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
      .setCallback((data) => {
        if (data.action === 'cancel') {
          picker.dispose();
        } else if (data.action === 'picked') {
          const doc = _.result(data, 'docs[0]');
          if (doc && doc.id) {
            setDocId(doc.id);
            axios
              .get(
                `https://sheets.googleapis.com/v4/spreadsheets/${doc.id}?alt=json&fields=sheets.properties&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
              )
              .then((r) => {
                picker.dispose();
                const sheets = _.result(r, 'data.sheets', []);
                if (sheets.length > 0) {
                  const newSheetNames = sheets.map((x) => ({
                    id: `${_.result(x, 'properties.sheetId')}`,
                    title: _.result(x, 'properties.title'),
                  }));
                  setSheetNames(newSheetNames);
                }
              })
              .catch(() => {
                picker.dispose();
                toast('Please share your file.');
              });
          }
        }
      });
    const picker = builder.build();
    picker.setVisible(true);
    var googleBg = document.querySelector('div.picker-dialog-bg');
    googleBg.addEventListener(
      'click',
      () => {
        picker.dispose();
      },
      {
        once: true,
      }
    );
  };

  const handleDownload = () => {
    axios
      .get(`/files/SampleBulkInvite.csv`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'snapbrillia-import-template.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 2; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            // eslint-disable-next-line max-depth
            if (d[0] === '"') {
              d = d.substring(1, d.length - 1);
            }
            // eslint-disable-next-line max-depth
            if (d[d.length - 1] === '"') {
              d = d.substring(d.length - 2, 1);
            }
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    let candidateModelFields = fieldList.map((x) => x.value);
    // prepare columns list from headers
    let columns = headers.map((c) => {
      if (!c) {
        return null;
      }
      const normalizedText = c.replace(/[^a-zA-Z]/g, '').toLowerCase();
      let dbField = find(
        candidateModelFields,
        (x) => x.toLowerCase() === normalizedText
      );
      if (!dbField) {
        dbField = find(candidateModelFields, (x) => x == normalizedText);
      }
      return {
        name: c,
        selector: c,
        field: dbField,
      };
    });

    if (!_.isEmpty(columns)) {
      columns = columns
        .map((item) => {
          if (!item) {
            return null;
          }
          const field = fieldList.find(
            (field) => field.value.toLowerCase() === item.name.toLowerCase()
          );
          item.selector = field ? field.field : item.name;
          return item;
        })
        .filter(Boolean);
    }
    setCSVData(list);
    setColumns(columns);
  };

  const handleChange = (files) => {
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const isSpreadSheet =
        file.type.includes('csv') || file.type.includes('sheet');
      if (isSpreadSheet) {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data);
      } else {
        handleUploadResume(file);
      }
      hiddenFileInput.current.value = '';
    };

    reader.readAsBinaryString(file);
  };

  const handleUploadResume = async (file) => {
    try {
      await uploadResume(file);
      toast('Import candidates success');
    } catch (err) {
      toast(err.error);
    }
  };

  const handleBulkUploadCandidate = async () => {
    const candidates = csvData.map((item) => {
      let map = {};
      columns.forEach((c) => {
        if (c.field) {
          map[c.field] = item[c.name];
        } else {
          map[c.selector] = item[c.name];
        }
      });
      return map;
    });
    try {
      await bulkUploadCandidate(candidates);
      toast('Import candidates success');
      handleClose();
    } catch (err) {
      toast(err.error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleGoogleUpload = () => {
    axios
      .get(
        `https://sheets.googleapis.com/v4/spreadsheets/${docId}/values/${sheetId}?alt=json&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      )
      .then((r) => {
        const values = _.result(r, 'data.values');
        const headers = values[0];
        const list = [];
        for (let i = 2; i < values.length; i++) {
          const row = values[i];
          const obj = {};
          for (let j = 0; j < headers.length; j++) {
            let d = row[j];
            if (headers[j]) {
              obj[headers[j]] = d;
            }
          }
          // remove the blank rows
          if (Object.values(obj).filter((x) => x).length > 0) {
            list.push(obj);
          }
        }
        let candidateModelFields = fieldList.map((x) => x.value);
        // prepare columns list from headers
        let columns = headers.map((c) => {
          if (!c) {
            return null;
          }
          const normalizedText = c.replace(/[^a-zA-Z]/g, '').toLowerCase();
          let dbField = find(
            candidateModelFields,
            (x) => x.toLowerCase() === normalizedText
          );
          if (!dbField) {
            dbField = find(
              candidateModelFields,
              (x) => x.toLowerCase() === normalizedText
            );
          }
          return {
            name: c,
            selector: c,
            field: dbField,
          };
        });
        if (!_.isEmpty(columns)) {
          columns = columns.map((item) => {
            const field = fieldList.find(
              (field) => field.value.toLowerCase() === item.name.toLowerCase()
            );
            item.selector = field ? field.field : item.name;
            return item;
          });
        }
        setCSVData(list);
        setColumns(columns);
      })
      .catch((err) => toast(err.error));
  };

  const handleClose = () => {
    setColumns([]);
    setCSVData([]);
    setDocId('');
    setSheetId('');
  };

  const handleCloseSheet = () => {
    setSheetNames([]);
  };

  const handleChangeMappingField = (event, row) => {
    const indexOf = columns.indexOf(row);
    const newColumns = [...columns];
    newColumns[indexOf].selector = event.target.value;
    setColumns(newColumns);
  };

  return (
    <div>
      <h3 className="formHeader mt-4 mb-4">
        You can{' '}
        <a className="" onClick={handleDownload}>
          Click Here
        </a>{' '}
        and download our template spreadsheet to add all of your candidates at
        once via the upload option below or import them directly from Google
        Drive.
      </h3>
      <div className="initialSetup-button-spacing">
        <UploadButton onClick={handleClick} />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={(e) => handleChange(e.target.files)}
          style={{ display: 'none' }}
        />
      </div>
      <div className="initialSetup-button-spacing">
        <GooglePicker
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          developerKey={process.env.REACT_APP_GOOGLE_API_KEY}
          scope={['https://www.googleapis.com/auth/drive.readonly']}
          onChange={(data) => console.log('on change:')}
          onAuthFailed={(data) => console.log('on auth failed:')}
          navHidden={true}
          authImmediate={false}
          createPicker={customPicker}
        >
          <GoogleDriveButton onClick={(e) => e.preventDefault()} />
          <div className="google"></div>
        </GooglePicker>
      </div>
      <Modal onHide={handleClose} size="lg" centered show={columns.length > 0}>
        <Modal.Header className="no-border" closeButton />
        <table>
          <thead>
            <tr>
              <td>Column #</td>
              <td>CSV Header</td>
              <td>Field Name</td>
            </tr>
          </thead>
          <tbody>
            {columns.map((column, index) => (
              <tr key={index}>
                <td>Column {index + 1}</td>
                <td>{column.name}</td>
                <td>
                  <select
                    value={column.selector}
                    onChange={(e) => handleChangeMappingField(e, column)}
                  >
                    {fieldList.map((item, index) => (
                      <option key={index} value={item.field}>
                        {item.field}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal.Footer className="no-border">
          <UploadButton onClick={handleBulkUploadCandidate} />
        </Modal.Footer>
      </Modal>
      <Modal
        onHide={handleCloseSheet}
        size="lg"
        centered
        show={sheetNames.length > 0}
      >
        <Modal.Header className="no-border" closeButton />
        <Modal.Body>
          <label className="form_label label_fields" htmlFor="fullName">
            Select your sheet
          </label>
          <select
            value={sheetId}
            onChange={(event) => setSheetId(event.target.value)}
          >
            {sheetNames.map((sheet, index) => (
              <option key={index} value={sheet.title}>
                {sheet.title}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer className="no-border">
          <UploadButton
            onClick={() => {
              handleGoogleUpload();
              handleCloseSheet();
            }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportUploadTemplate;
