import { React, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/dashboardBounties.css';
import AllLineCharts from '../../charts/allLineCharts';
import { forEach } from 'lodash';
import BarChart from '../../charts/barChart';

const DashboardBounties = ({ bounties, bountyAssessment }) => {
  function countDuplicates(arr) {
    if (arr && arr.length > 0) {
      const countsByItem = {};
      for (const item of arr) {
        countsByItem[item] = (countsByItem[item] || 0) + 1;
      }
      return Object.values(countsByItem).filter((val) => val >= 2).length;
    }
    return 0;
  }

  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }

  const [bountiesAnalyze, setBountiesAnalyze] = useState({
    inProgress: 0,
    completed: 0,
    participants: 0,
    activeParticipants: 0,
    teams: 0,
    activeTeams: 0,
  });
  const countBountiesAnalyze = async () => {
    let reAnalyze = {
      inProgress: 0,
      completed: 0,
      participants: 0,
      activeParticipants: 0,
      teams: 0,
      activeTeams: 0,
    };
    await bounties.map((item) => {
      reAnalyze.inProgress += item.accepted;
      reAnalyze.activeParticipants += item.accepted;
      reAnalyze.activeParticipants += item.inProgress;
      reAnalyze.activeParticipants += item.reviewed;
      reAnalyze.completed += item.completed;
      reAnalyze.participants = reAnalyze.activeParticipants;
      reAnalyze.teams += item?.teamIds?.length - countDuplicates(item.teamIds);
    });
    setBountiesAnalyze(reAnalyze);
  };

  const assessment = [];
  const bountyId = bounties.map((bounty) => bounty._id);
  const bountyIdFlatten = flatten(bountyId);
  // for (let index = 0; index < bountyIdFlatten.length; index++) {
  //   const assess = bountyAssessment.filter(
  //     (bounty) => bounty.bountyId === bountyIdFlatten[index]
  //   );
  //   assessment.push(assess);
  // }
  // const assessmentFlaten = flatten(assessment);
  // const totalIndividual = assessmentFlaten.filter(
  //   (value) => value.position === 'individual'
  // );
  // const totalMentors = assessmentFlaten.filter(
  //   (value) => value.position === 'mentor'
  // );
  // const activeMentors = totalMentors.filter(
  //   (value) => value.status === 'accepted'
  // );
  // const activeIndividual = totalIndividual.filter(
  //   (value) => value.status === 'accepted'
  // );

  useEffect(() => {
    countBountiesAnalyze();
  }, [bounties]);

  return (
    <Container className="d-flex flex-column pb-4 dashboard_bounties_component">
      <Row className="h2 p-4">Gigs & Jobs</Row>
      <Row className="d-flex flex-row mb-2 px-4">
        <Col
          xs={12}
          md={12}
          style={{
            justifyContent: 'left',
            alignItems: 'center',
            background: '#FAFAFA',
          }}
        >
          <BarChart bounties={bounties} />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardBounties;
