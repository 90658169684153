import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Modal, Spinner, Form } from 'react-bootstrap';
import axios from 'axios';
import { createCoupon, editCouponForm } from '../../api/coupon';
import { getAllSalesCompanies } from '../../api/sales';
import '../../shared/css/typography.css';
import '../../shared/css/bgColors.css';
import '../../shared/css/sharedStyles.css';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import moment from 'moment';

import InviteButton from '../../shared/button/invite';

const CouponForm = ({
  handleClose,
  showModal,
  handleCreateCoupon,
  setResult,
  setShowResult,
  editCoupon,
  setValues,
  values,
}) => {
  const [loading, setLoading] = useState(false);
  let dateToday = new Date();
  let dateYear = new Date(dateToday.setFullYear(dateToday.getFullYear() + 1));
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(values);
    if (editCoupon._id) {
      try {
        setLoading(true);
        const newCoupon = await editCouponForm(editCoupon._id, { ...values });
        setResult(newCoupon);
        handleCreateCoupon(newCoupon);
        toast('Coupon Edited successfully');
        setShowResult(true);
      } catch (err) {
        toast(err.error);
      }
    } else {
      if (!values.companyId) {
        toast('Please Select a company');
        return null;
      }
      try {
        if (!values.isInvoiceCoupon) {
          if (values.fee > 100 || values.fee < 1) {
            toast('Invalid Percentage');
            setLoading(false);
            return null;
          }
        }
        setLoading(true);
        const newCoupon = await createCoupon({ ...values });
        setResult(newCoupon);
        handleCreateCoupon(newCoupon);
        toast('Coupon Created successfully');
        setShowResult(true);
      } catch (err) {
        toast(err.error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    let result = [];
    let companies = await getAllSalesCompanies();
    // console.log(companies);
    for (let i = 0; i < companies.length; i++) {
      let company = companies[i];
      result.push({ label: company.name, value: company.id });
    }
    return result;
  };

  return (
    <Modal onHide={handleClose} backdrop="static" show={showModal}>
      <Container className="add-candidate-modal-container">
        <Row>
          <Col>
            <Modal.Header className="modalHeader" closeButton>
              <Modal.Title>
                <span className="h2">
                  {editCoupon._id ? `Edit Coupon` : `Create New Coupon`}
                </span>
              </Modal.Title>
            </Modal.Header>
          </Col>
        </Row>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  {!editCoupon._id
                    ? `Here you can create a new coupon and choose if the coupon is invoiced or not`
                    : `Here you can edit coupon`}
                </p>
                {!editCoupon._id && (
                  <Form.Group className="mb-1" controlId="steptwoGroup4">
                    <Form.Label className="form_label bounty-type-label ps-0">
                      Is Coupon Invoiced{' '}
                    </Form.Label>
                    <Form.Control as="checkbox" className="remove-radio-border">
                      <Form.Check
                        inline
                        label={`Select to make coupon invoiced`}
                        type="checkbox"
                        value={values.isInvoiceCoupon}
                        onChange={() =>
                          setValues({
                            ...values,
                            isInvoiceCoupon: !values.isInvoiceCoupon,
                          })
                        }
                        checked={values.isInvoiceCoupon}
                      />
                    </Form.Control>
                  </Form.Group>
                )}

                {!editCoupon._id && (
                  <Form.Group className="mb-4" controlId="stepOneGroup1">
                    <Form.Label className="form_label bounty-type-label ps-0">
                      Company{' '}
                      <span className="required">
                        {values.isInvoiceCoupon ? '*' : ''}
                      </span>
                    </Form.Label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      closeMenuOnSelect={true}
                      maxMenuHeight={500}
                      onChange={(e) =>
                        setValues({ ...values, companyId: e.value })
                      }
                      loadOptions={getCompanies}
                    />
                  </Form.Group>
                )}

                {values.isInvoiceCoupon && (
                  <Form.Group className="mb-4" controlId="steptwoGroup1">
                    <Form.Label className="form_label bounty-type-label ps-0">
                      Add Use Count <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      className="gig-form-field"
                      as="input"
                      rows={3}
                      value={values.useCount}
                      placeholder="Add use count For this invoice"
                      onChange={(e) =>
                        setValues({ ...values, useCount: e.target.value })
                      }
                    />
                    <Form.Text className="text-muted">
                      <i></i> Add use count for the invoice if needed
                    </Form.Text>
                  </Form.Group>
                )}

                {!values.isInvoiceCoupon && (
                  <Form.Group className="mb-4" controlId="steptwoGroup1">
                    <Form.Label className="form_label bounty-type-label ps-0">
                      Discount Percentage (example: 30){' '}
                      <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      className="gig-form-field"
                      as="input"
                      rows={3}
                      value={values.fee}
                      placeholder="Add use count For this invoice"
                      onChange={(e) =>
                        setValues({ ...values, fee: e.target.value })
                      }
                    />
                    <Form.Text className="text-muted">
                      <i></i> Discount coupon needs, discount amount (USD)
                    </Form.Text>
                  </Form.Group>
                )}
                <Form.Group className="mb-4" controlId="steptwoGroup1">
                  <Form.Label className="form_label bounty-type-label ps-0">
                    Expiry Date <span className="required"></span>
                  </Form.Label>
                  <Form.Control
                    className="gig-form-field"
                    as="input"
                    type="date"
                    contentEditable={!editCoupon._id}
                    rows={3}
                    value={moment(
                      editCoupon._id ? values.expiry : dateYear
                    ).format('YYYY-MM-DD')}
                    placeholder="Add use count For this invoice"
                    onChange={(e) => {
                      setValues({ ...values, expiry: e.target.value });
                      console.log(e.target.value);
                    }}
                  />
                  <Form.Text className="text-muted">
                    <i></i> Add expiry date for the invoice if needed
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {loading ? (
                  <button
                    className="btn-primary candidate-modal-button"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <InviteButton
                    className={`candidate-modal-button ${
                      loading ? 'btn-disabled' : ''
                    }`}
                    title={editCoupon._id ? 'Save' : 'Create'}
                    handleClick={handleSubmit}
                  />
                )}
              </Col>
            </Row>
          </Modal.Body>
        </form>
      </Container>
    </Modal>
  );
};
export default CouponForm;
