import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import '../../memberModal/css/memberFields.css';

export default function GreenhouseModalFields() {
  return (
    <Container>
      <Row>
        <Col>
          <label
            className="form_label label_fields"
            htmlFor="greenhouse_account_email"
          >
            Greenhouse Account Email
          </label>

          <input
            className="form_input input_fields"
            type="text"
            id="greenhouse_account_email"
            name="greenhouse_account_email"
          ></input>
        </Col>
      </Row>

      <Row>
        <Col>
          <label className="form_label label_fields" htmlFor="APKey">
            API Key
          </label>

          <input
            className="form_input input_fields"
            id="API Key"
            name="API Key"
          ></input>
        </Col>
      </Row>
    </Container>
  );
}
