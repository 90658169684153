import axios from 'axios';

export async function createProject(data) {
  const response = await axios.post(`/api/work-sample-project/create`, data);

  return response.data;
}

export async function getAllBountyAssessment() {
  const response = await axios.post(`/api/bounty-assessment/get-all`);

  return response.data;
}

export async function addCandidate(data) {
  try {
    const response = await axios.post(
      `/api/work-sample-project/add-candidate`,
      data
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function addCandidates(workSampleProjectId, candidates) {
  try {
    const response = await axios.post(
      `/api/work-sample-project/add-candidates`,
      { workSampleProjectId, candidates }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getProjectInfo(workSampleProjectId) {
  const response = await axios.get(
    `/api/work-sample-project/one/${workSampleProjectId}`
  );

  return response.data;
}

export async function getProjectTasks(workSampleProjectId) {
  const response = await axios.get(
    `/api/work-sample-project/${workSampleProjectId}/tasks`
  );

  return response.data;
}

export async function getProjectCandidates(workSampleProjectId) {
  if (workSampleProjectId) {
    const response = await axios.get(
      `/api/work-sample-project/${workSampleProjectId}/all-candidates`
    );
    return response.data;
  }
}

export async function getProjectSummary(workSampleProjectId) {
  const response = await axios.get(
    `/api/work-sample-project/${workSampleProjectId}/summary`
  );

  return response.data;
}

export async function getProjectDiversity(workSampleProjectId, type) {
  console.log('getProjectDiversity', workSampleProjectId);
  const response = await axios.get(
    `/api/work-sample-project/${type}/${workSampleProjectId}`
  );

  return response.data;
}

export async function getProjectBiasScore(workSampleProjectId) {
  const response = await axios.get(
    `/api/work-sample-project/candidates/biasScore/${workSampleProjectId}`
  );
  return response.data;
}

export async function reviewCandidate(candidate, result) {
  const response = await axios.put('/api/assessment/update-statusresult', {
    id: candidate.assessment._id,
    status: 'reviewed',
    result: result,
  });
  return response.data;
}

export async function deleteProject(workSampleProjectId) {
  await axios.delete(`/api/work-sample-project/${workSampleProjectId}`);
}

export async function editProject(workSampleProject) {
  const response = await axios.put(
    `/api/work-sample-project/${workSampleProject._id}`,
    {
      name: workSampleProject.name,
      jobTitle: workSampleProject.jobTitle,
      jobDescription: workSampleProject.jobDescription,
      duration: workSampleProject.duration,
      daysToComplete: workSampleProject.daysToComplete,
    },
    { withCredentials: true }
  );
  return response.data;
}

export async function getAllProjects({ page = 0, limit = 10 }) {
  const response = await axios.get(
    `/api/work-sample-project/all?page=${page}&limit=${limit}`
  );

  return response.data;
}

export async function getDashboardAssessment() {
  const response = await axios.get(`/api/work-sample-project/get-dashboard`);

  return response.data;
}

export async function uploadResume(formData) {
  try {
    const response = await axios.post(
      `/api/work-sample-project/add-resume`,
      formData
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function bulkCandidateUpload(body) {
  try {
    const response = await axios.post(
      `/api/work-sample-project/add-bulk-candidate`,
      body
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getMembers(workSampleProjectId) {
  const response = await axios.get(
    `/api/work-sample-project/${workSampleProjectId}/members`
  );

  return response.data;
}
