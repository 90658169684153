import { useState, useEffect, useCallback } from 'react';
import * as ProjectApi from '../../api/projects';
import * as BountyApi from '../../api/bounties';

function DiversityRatingData(
  bountyId,
  WSPId,
  dataType,
  dataType2,
  setData,
  setAxisLabels,
  setAxisTitles,
  setChartTitle
) {
  // list assessment phases and the candidates within these phases for response rate
  const [summary, setSummary] = useState();

  // fetch summary of candidates in different diversity categories
  const getDiversityRating = useCallback(async () => {
    try {
      const { data: diversityData } = bountyId
        ? await BountyApi.getBountyDiversity(bountyId, dataType2)
        : await ProjectApi.getProjectDiversity(WSPId, dataType2);
      setSummary(diversityData);
    } catch (err) {}
  }, [WSPId, bountyId, dataType2]);

  useEffect(() => {
    getDiversityRating();
  }, [getDiversityRating]);

  useEffect(() => {
    const count = {
      all: 0,
      new: 0,
      invited: 0,
      viewed: 0,
      accepted: 0,
      inProgress: 0,
      completed: 0,
      reviewed: 0,
      bounced: 0,
      expired: 0,
    };
    for (let i = 0; i < summary?.length; i++) {
      const phase = summary[i]?.assessment?.status;
      if (phase === 'new') {
        count.new += 1;
      } else if (phase === 'invited') {
        count.new += 1;
        count.invited += 1;
      } else if (phase === 'viewed') {
        count.new += 1;
        count.invited += 1;
        count.viewed += 1;
      } else if (phase === 'accepted') {
        count.new += 1;
        count.invited += 1;
        count.viewed += 1;
        count.accepted += 1;
      } else if (phase === 'inProgress') {
        count.new += 1;
        count.invited += 1;
        count.viewed += 1;
        count.accepted += 1;
        count.inProgress += 1;
      } else if (phase === 'completed') {
        count.new += 1;
        count.invited += 1;
        count.viewed += 1;
        count.accepted += 1;
        count.inProgress += 1;
        count.completed += 1;
      } else if (phase === 'reviewed') {
        count.new += 1;
        count.invited += 1;
        count.viewed += 1;
        count.accepted += 1;
        count.inProgress += 1;
        count.completed += 1;
        count.reviewed += 1;
      } else if (phase === 'bounced') {
        count.new += 1;
        count.invited += 1;
        count.bounced += 1;
      } else if (phase === 'expired') {
        count.new += 1;
        count.invited += 1;
        count.expired += 1;
      }
    }

    let newToInvited = Math.round((count.invited / count.new) * 100) || 0;
    let invitedToViewed = Math.round((count.viewed / count.invited) * 100) || 0;
    let viewedToAccepted =
      Math.round((count.accepted / count.viewed) * 100) || 0;
    let acceptedToInProgress =
      Math.round((count.inProgress / count.accepted) * 100) || 0;
    let inProgressToCompleted =
      Math.round((count.completed / count.inProgress) * 100) || 0;
    let completedToReviewed =
      Math.round((count.reviewed / count.completed) * 100) || 0;
    let reviewedOutOfTotal =
      Math.round((count.reviewed / count.new) * 100) || 0;

    let diversityRating = {
      newToInvited: newToInvited,
      invitedToViewed: invitedToViewed,
      viewedToAccepted: viewedToAccepted,
      acceptedToInProgress: acceptedToInProgress,
      inProgressToCompleted: inProgressToCompleted,
      completedToReviewed: completedToReviewed,
      reviewedOutOfTotal: reviewedOutOfTotal,
    };

    setData(Object.values(diversityRating));
    setAxisLabels([
      'New to Invited',
      'Invited to Viewed',
      'Viewed to Accepted',
      'Accepted to In Progress',
      'In Progress to Completed',
      'Completed to Reviewed',
      'Reviewed Out of Total',
    ]);
    setAxisTitles([
      '% of Candidates',
      `Assessment Phases for ${
        dataType2.charAt(0).toUpperCase() + dataType2.slice(1)
      } `,
    ]);
    setChartTitle('Diversity Rating');
  }, [
    dataType,
    dataType2,
    setAxisLabels,
    setAxisTitles,
    setChartTitle,
    setData,
    summary,
  ]);
}

export default DiversityRatingData;
