import '../../shared/css/typography.css';

const BountyTitle = ({ bountyTitle }) => {
  return (
    <h1 className="h1 m-0 bounty_title" data-testid="title">
      {/* {bountyTitle || 'SoftWare Engineer'} */}
      {bountyTitle}
    </h1>
  );
};

export default BountyTitle;
