import React from 'react';
import '../../shared/css/sharedStyles.css';

const EditProjectLink = ({ setOpenEditModal, className }) => {
  return (
    <button
      onClick={() => setOpenEditModal(true)}
      className={`btn-edit-modal-link m-0 ${className}`}
    >
      Edit Project
    </button>
  );
};

export default EditProjectLink;
