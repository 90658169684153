import { Row, Col, Container } from 'react-bootstrap';
//CSS
//import '../../bounties/css/improveToolTip.css';

export default function onChainSkillsView({ skills }) {
  return (
    <Container>
      <Row>
        <h4 className="bold m-0 mb-2">Skills</h4>
        <span className="grey-title  d-block mb-2">Languages</span>
        {skills.map((skill) => (
          <Col
            key={Math.random().toString(32)}
            className="on-chain-skill-pill "
          >
            {skill}
          </Col>
        ))}
      </Row>
    </Container>
  );
}
