import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import { closeCandidateApplications } from '../../api/bounties';
import { toast } from 'react-toastify';

const CloseApplicationsWarning = ({ showModal, closeModal, bounty }) => {
  const onCloseApllications = async (e) => {
    e.preventDefault();
    try {
      const id = e.target.getAttribute('data-bounty');
      if (id) {
        const response = await closeCandidateApplications(id);
        if (response) {
          toast('You have succesfully closed any more applications');
        }
      }
    } catch (e) {
      toast('An error occured while processing your request');
    }
    e.stopPropagation();
  };
  return (
    <Modal show={showModal} onHide={closeModal} centered>
      <ModalHeader closeButton>CAUTION!</ModalHeader>
      <ModalBody>
        You are about to close applications for {bounty.name || bounty.title}!
        Are you sure you want to proceed?
      </ModalBody>
      <ModalFooter>
        <button
          className="responsive-primary-btn"
          data-bounty={bounty._id}
          onClick={onCloseApllications}
        >
          Yes, Close
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default CloseApplicationsWarning;
