import React from 'react';

const ChatUserFooter = ({
  onSendMessage,
  replyTo,
  closeReply,
  message,
  setMessage,
  reply,
  msgEllipsis,
  setShowVideoRecorder,
}) => {
  return (
    <footer className="chatbox-popup__footer">
      <aside
        style={{
          flex: 1,
          color: '#888',
          textAlign: 'center',
        }}
      >
        <i
          className="fa fa-camera"
          aria-hidden="true"
          onClick={() => setShowVideoRecorder(true)}
        ></i>
      </aside>
      <aside
        style={{
          flex: 10,
        }}
      >
        {reply && (
          <p className="msg-page">
            <i
              className="fa fa-close"
              aria-hidden="true"
              onClick={() => closeReply()}
            ></i>
            <p>
              {replyTo[0].recepient} <br />
              {msgEllipsis(replyTo[0].message, 100)}
            </p>
          </p>
        )}
        <textarea
          type="text"
          //contentEditable
          value={message}
          onChange={(e) => {
            e.stopPropagation();
            setMessage(e.target.value);
          }}
          placeholder="Type your message here..."
          style={{
            minHeight: 40,
            maxHeight: 100,
          }}
        ></textarea>
      </aside>
      <aside
        style={{
          padding: 10,
        }}
      >
        <i className="fa fa-paperclip" aria-hidden="true"></i>
      </aside>
      <aside
        style={{
          padding: 10,
        }}
      >
        <i className="fa fa-camera" aria-hidden="true"></i>
      </aside>
      {/* <aside>
      <i className="fa fa-smile-o" aria-hidden="true"></i>
    </aside> */}
      <aside
        onClick={onSendMessage}
        style={{
          flex: 1,
          color: '#888',
          textAlign: 'center',
        }}
      >
        <i
          className="fa fa-paper-plane"
          aria-hidden="true"
          style={{
            fontSize: 25,
            color: '#3F3DC8',
          }}
        ></i>
      </aside>
    </footer>
  );
};

export default ChatUserFooter;
