import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SaveButton from '../../shared/button/save';
import PrivacyPolicyModal from '../../shared/modals/privacyPolicyModal';
import TermsOfUseModal from '../../shared/modals/termsOfUseModal';
import ShowInvitationModal from '../../shared/modals/showInvitationModal';
import { useState, useEffect } from 'react';
import '../../shared/css/typography.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/textColors.css';
import '../css/updateInformationForm.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../context/authContext';
import { navigate } from '@reach/router';

const UpdateInformationFormInput = ({
  id,
  label,
  type = 'text',
  placeholder,
  onChange,
  error,
  touched,
  value,
  disabled,
}) => {
  return (
    <section className="signup-form-section">
      <Row className="mb-1">
        <Col md={12}>
          <label
            htmlFor={label}
            className="form_label label_fields signup-form-label"
          >
            {label}
          </label>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={12}>
          <input
            className="form_input input_fields px-1"
            id={id}
            placeholder={' ' + placeholder}
            type={type}
            onChange={onChange}
            value={value}
            disabled={disabled}
          ></input>
        </Col>
        {touched && Boolean(error) && (
          <span className="error-message">{error}</span>
        )}
      </Row>
    </section>
  );
};

export default function UpdateInformationForm() {
  const [invitation, setInvitation] = useState(null);
  const [invitations, setInvitations] = useState(null);
  const [noInvitation, setNoInvitation] = useState(1);
  const [showInvitation, setShowInvitation] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const {
    update,
    auth,
    getUserInvitation,
    acceptInvitation,
    skipInvitation,
    loading,
  } = useAuth();
  const validationSchema = yup.object({
    fullName: yup
      .string()
      .matches(
        /^((?![^a-zA-Z0-9,:; ./-]).)*$/,
        'Full name is invalid ( should not contain special character )'
      )
      .matches(
        /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/,
        'Full name is invalid ( should not contain only numbers )'
      )
      .required('Full name is required!'),
    title: yup
      .string()
      .matches(
        /^((?![^a-zA-Z0-9,:; ./-]).)*$/,
        'Title is invalid ( should not contain special character )'
      )
      .matches(
        /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/,
        'Title is invalid ( should not contain only numbers )'
      )
      .required('Title is required!'),
    companyName: yup
      .string()
      .matches(
        /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/,
        'Company Name is invalid ( should not contain only numbers )'
      )
      .required('Company Name is required!'),
    acceptTerms: yup
      .bool()
      .oneOf([true], 'Accept Terms & Conditions is required'),
  });

  const checkInvitations = async () => {
    const response = await getUserInvitation(auth.user.email);
    setInvitations(response);
  };

  const selectInvitation = async () => {
    if (invitations) {
      if (noInvitation <= invitations.length) {
        setInvitation(invitations[noInvitation - 1]);
        setNoInvitation(noInvitation + 1);
      }
    }
  };

  const handleJoin = async () => {
    await acceptInvitation(invitation._id);
  };
  const { handleChange, touched, errors, handleSubmit, setFieldValue, values } =
    useFormik({
      initialValues: {
        fullName: auth?.user?.fullName || '',
        title: auth?.user?.title || '',
        companyName: auth?.company?.companyName || '',
        companyEmail: auth?.user?.email || '',
        //address: '',
        acceptTerms: false,
      },
      validationSchema: validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await update(values, true);
        } finally {
          setSubmitting(false);
        }
      },
    });

  const closeModal = () => {
    setTermsOfUse(false);
    setPrivacyPolicy(false);
  };

  const skipInvite = async () => {
    setShowInvitation(false);
    await skipInvitation(invitation._id);
    selectInvitation();
  };

  useEffect(() => {
    try {
      checkInvitations();
    } catch {}
  }, []);

  useEffect(() => {
    if (auth?.company) {
      if (auth.user.role !== 'owner') {
        navigate('/dashboard');
      }
    }
  }, [auth]);

  useEffect(() => {
    selectInvitation();
  }, [invitations]);

  useEffect(() => {
    if (invitation) {
      setShowInvitation(true);
    }
  }, [invitation]);

  return (
    <Row className="mx-auto justify-content-center">
      <PrivacyPolicyModal show={privacyPolicy} closeModal={closeModal} />
      <TermsOfUseModal show={termsOfUse} closeModal={closeModal} />
      <Col xs={12} sm={10} className="mx-3 signup-top-section">
        <section className="signup-header">
          <h1 className="h1">Update Information</h1>
          <form onSubmit={handleSubmit}>
            <UpdateInformationFormInput
              id="fullName"
              label={'Full Name'}
              placeholder="Enter your full name"
              onChange={handleChange}
              value={values.fullName}
              touched={touched.fullName}
              error={errors.fullName}
            />
            <UpdateInformationFormInput
              id="title"
              label={'Job Title'}
              placeholder="Enter your job title"
              onChange={handleChange}
              value={values.title}
              touched={touched.title}
              error={errors.title}
            />
            <UpdateInformationFormInput
              id="companyName"
              label={'Company Name'}
              placeholder="Enter your company name"
              onChange={handleChange}
              value={values.companyName}
              touched={touched.companyName}
              error={errors.companyName}
            />
            <UpdateInformationFormInput
              id="email"
              label={'Email Address'}
              type={'email'}
              placeholder="Enter your email address"
              value={auth ? auth.user.email : ''}
              disabled={true}
            />
            <div>
              <span className="small-text">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="acceptTerms"
                  checked={values.acceptTerms}
                  onChange={(e) =>
                    setFieldValue('acceptTerms', e.target.checked)
                  }
                />{' '}
                I have read and accepted to{' '}
              </span>
              <span
                className="primary bold cursor-pointer p-1"
                onClick={() => setTermsOfUse(true)}
              >
                Terms of Use
              </span>{' '}
              and{' '}
              <span
                className="primary bold cursor-pointer p-1"
                onClick={() => setPrivacyPolicy(true)}
              >
                Privacy Policy
              </span>
            </div>
            {touched.acceptTerms && Boolean(errors.acceptTerms) && (
              <span className="error-message">{errors.acceptTerms}</span>
            )}
            <Col className=" signup-bottom-float ">
              <SaveButton />
            </Col>
          </form>
        </section>
      </Col>
      <ShowInvitationModal
        show={showInvitation}
        invitation={invitation}
        handleSkip={skipInvite}
        handleJoin={handleJoin}
        loading={loading}
      />
    </Row>
  );
}
