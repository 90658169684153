/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PrimarySidebar from '../shared/sidebar/primarySidebar';
import { default as SearchBar } from '../searchNavbar/navbar';
import PrimaryTopBar from '../shared/topbar/PrimaryTopBar';
import './css/bounties.css';
import './css/chat.css';
import AllOpportunityTable from './components/allOpportunityTable';

const Bounties = () => {
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(state ? state.from : false);
  const [close, setClose] = useState(true);

  useEffect(() => {
    function handleResize() {
      let timer = 0;

      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        document
          .getElementById('main-panel')
          .classList.add('bounties-stop-transitions');
        document
          .getElementById('sidebar')
          .classList.add('bounties-stop-transitions');
      }
      timer = setTimeout(() => {
        document
          .getElementById('main-panel')
          .classList.remove('bounties-stop-transitions');
        document
          .getElementById('sidebar')
          .classList.remove('bounties-stop-transitions');
        timer = null;
      }, 100);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Container
      style={{
        overflow: 'hidden',
      }}
      className=""
      fluid
    >
      <Row className="d-flex flex-row flex-nowrap vw-100">
        <PrimarySidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          component="bounties"
        />

        <Col
          id="main-panel"
          xs={12}
          md={10}
          lg={10}
          style={{
            width: collapsed ? '93vw' : null,
            transition: 'all 0.8s ease-in-out',
            zIndex: 100,
          }}
          className="bounties_main_panel primary-main-panel pagemainbg"
        >
          <PrimaryTopBar component="dashboard" />
          <Row>
            <h2 className="primary-title h1"> All Gigs & Jobs</h2>
          </Row>
          {close && (
            <div className="bounty_status_bar d-none d-sm-none d-md-block d-lg-block">
              <SearchBar />
            </div>
          )}

          <Row className="bounties_masked_overflow ">
            <div className="bounties_board ">
              <div className="bounties_table">
                <AllOpportunityTable />
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default Bounties;
