import React, { useState } from 'react';

//COMPONENTS
import { Modal, Row, Col, Container } from 'react-bootstrap';
import CloseIcon from '../assets/icon/snapbrillia_close_icon.svg';
import DeleteButton from '../shared/button/delete';
import DeleteConfirmModal from '../shared/modals/deleteConfirmModal';
import EditProjectFields from './components/editProjectsFields';

//CSS
import './css/editProjectFields.css';

const EditProjectModal = ({
  openEditModal,
  setOpenEditModal,
  workSampleProject,
  handleDeleteProject,
  handleEditProject,
}) => {
  const handleClose = () => setOpenEditModal(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [WSPFields, setWSPFields] = useState(workSampleProject);
  const handleClickDelete = () => {
    setShowConfirm(true);
  };
  const handleSave = async () => {
    await handleEditProject(WSPFields);
    handleClose();
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  const confirmDelete = async () => {
    await handleDeleteProject(workSampleProject);
    setShowConfirm(false);
  };
  return (
    <Container>
      <Modal show={openEditModal} onHide={handleClose}>
        <Row className="align-items-center justify-content-center mt-3">
          <Col className="col-10 ms-4">
            <span className="primary fs-4 medium">Edit Project</span>
          </Col>
          <Col>
            <img
              src={CloseIcon}
              alt="close-icon"
              onClick={() => setOpenEditModal(false)}
              className="edit-modal-close-icon"
            />
          </Col>
        </Row>

        <EditProjectFields
          handleSave={handleSave}
          WSPFields={WSPFields}
          setWSPFields={setWSPFields}
        />
        <div className="edit-project-actions">
          <DeleteButton text="Delete Project" handleClick={handleClickDelete} />
        </div>

        {showConfirm && (
          <DeleteConfirmModal
            show={showConfirm}
            text="Project"
            handleDelete={confirmDelete}
            closeModal={handleCloseConfirm}
          />
        )}
      </Modal>
    </Container>
  );
};

export default EditProjectModal;
