import '../css/sharedStyles.css';
import './css/primarySideMenu.css';

import { Link } from '@reach/router';
import { ReactComponent as DashBoard } from '../../assets/icon/snapbrillia_home_icon.svg';
import { ReactComponent as Analytics } from '../../assets/icon/snapbrillia_analytics_icon.svg';
// import { ReactComponent as Projects } from '../../assets/icon/snapbrillia_projects_icon.svg';
import { ReactComponent as Bounties } from '../../assets/icon/snapbrillia_bounties_icon.svg';
import { ReactComponent as Person } from '../../assets/icon/snapbrillia_profile_personal_icon.svg';

export default function PrimarySideMenu(props) {
  const { component, collapsed } = props;

  return (
    <div className="menulabel">
      <Link
        to="/dashboard"
        state={{ from: collapsed }}
        className="maintain-link-style"
      >
        <div
          style={{
            width: collapsed ? '7vw' : '15vw',
            minWidth: collapsed ? '50px' : '165px',
            maxWidth: collapsed ? '100px' : '195px',
            //transform: collapsed ? 'translate(0vw)' : null,
            transition: 'all 0.8s ease-in-out',
            display: 'flex',
          }}
          className={`${
            component === 'dashboard' ? 'menu_item_selected' : ''
          } ${collapsed ? '' : 'ps-2'} primary-menu-general 
          `}
        >
          <div
            className="menu-item-icon d-flex align-items-center"
            style={{
              transform: collapsed ? `translate(calc(3.5vw))` : null,
              position: 'relative',
              transition: 'all 0.8s ease-in-out',
            }}
          >
            <DashBoard />
          </div>
          <div
            className="menu-item-label d-flex align-items-center"
            style={{
              visibility: collapsed ? 'hidden' : 'visible',
              opacity: collapsed ? '0' : null,
              transition: 'all 0.8s ease-in-out',
              position: 'relative',
              width: collapsed ? 0 : '100%',
              transform: collapsed ? `translate(-2.1vw)` : null,
              paddingLeft: 'min(1vw,18px)',
              fontWeight: '500',
            }}
          >
            Dashboard
          </div>
        </div>
      </Link>

      <Link
        to="/analytics"
        state={{ from: collapsed }}
        className="maintain-link-style"
      >
        <div
          style={{
            width: collapsed ? '7vw' : null,
            minWidth: collapsed ? '50px' : '165px',
            maxWidth: collapsed ? '100px' : '195px',
            //transform: collapsed ? 'translate(3.5vw)' : null,
            transition: 'all 0.8s ease-in-out',
            display: 'flex',
          }}
          className={`${
            component === 'analytics' ? 'menu_item_selected' : ''
          } ${collapsed ? '' : 'ps-2'} primary-menu-general 
            `}
        >
          <div
            className="menu-item-icon d-flex align-items-center"
            style={{
              transform: collapsed ? `translate(calc(3.5vw))` : null,
              position: 'relative',
              transition: 'all 0.8s ease-in-out',
            }}
          >
            <Analytics />
          </div>
          <div
            className="d-flex align-items-center menu-item-label"
            style={{
              visibility: collapsed ? 'hidden' : 'visible',
              opacity: collapsed ? '0' : null,
              transition: 'all 0.8s ease-in-out',
              position: 'relative',
              width: collapsed ? 0 : '100%',
              transform: collapsed ? `translate(-2.1vw)` : null,
              paddingLeft: 'min(1vw,18px)',
              fontWeight: '500',
            }}
          >
            Analytics
          </div>
        </div>
      </Link>
      <Link
        to="/opportunities"
        state={{ from: collapsed }}
        className="maintain-link-style"
      >
        <div
          style={{
            width: collapsed ? '7vw' : null,
            minWidth: collapsed ? '50px' : '165px',
            maxWidth: collapsed ? '100px' : '195px',
            //transform: collapsed ? 'translate(0vw)' : null,
            transition: 'all 0.8s ease-in-out',
            display: 'flex',
          }}
          className={`${component === 'bounties' ? 'menu_item_selected' : ''} ${
            collapsed ? '' : 'ps-2'
          } primary-menu-general 
          `}
        >
          <div
            className="menu-item-icon d-flex align-items-center"
            style={{
              transform: collapsed ? `translate(calc(3.5vw))` : null,
              position: 'relative',
              transition: 'all 0.8s ease-in-out',
            }}
          >
            <Bounties />
          </div>
          <div
            className="menu-item-label d-flex align-items-center w-100"
            style={{
              visibility: collapsed ? 'hidden' : 'visible',
              opacity: collapsed ? '0' : null,
              transition: 'all 0.8s ease-in-out',
              position: 'relative',
              minWidth: '139px',
              transform: collapsed ? `translate(-2.1vw)` : null,
              paddingLeft: collapsed ? '' : 'min(1vw,18px)',
              fontWeight: '500',
            }}
          >
            Opportunities
          </div>
        </div>
      </Link>
      <Link
        to="/personal-profile"
        className="maintain-link-style mobile-header-menu"
      >
        <div
          style={{
            transition: 'all 0.8s ease-in-out',
            display: 'flex',
          }}
          className={`${component === 'bounties' ? 'menu_item_selected' : ''} ${
            collapsed ? '' : 'ps-2'
          } primary-menu-general 
          `}
        >
          <div
            className="menu-item-icon d-flex align-items-center"
            style={{
              transform: collapsed ? `translate(calc(3.5vw))` : null,
              position: 'relative',
              transition: 'all 0.8s ease-in-out',
            }}
          >
            <Person />
          </div>
          <div
            className="menu-item-label d-flex align-items-center w-100"
            style={{
              visibility: collapsed ? 'hidden' : 'visible',
              opacity: collapsed ? '0' : null,
              transition: 'all 0.8s ease-in-out',
              position: 'relative',
              minWidth: '139px',
              transform: collapsed ? `translate(-2.1vw)` : null,
              paddingLeft: collapsed ? '' : 'min(1vw,18px)',
              fontWeight: '500',
            }}
          >
            Personal Profile
          </div>
        </div>
      </Link>
    </div>
  );
}
