import '../../shared/css/typography.css';

export default function WaitingRoomMessage({ isCandidate, position }) {
  return (
    <div>
      <p className="h3">
        Once the {isCandidate ? 'interviewer' : 'candidate'} joins, you will
        {isCandidate ? ' begin your ' : ' be able to view their '}live coding
        {isCandidate ? ' interview' : ' assessment'} for the role of
        <b>{' ' + position}</b>.
      </p>
    </div>
  );
}
