import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './overlays.css';

const OverlayWithChildren = ({ children, text }) => (
  <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="overlay-tooltip">{text}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);

export default OverlayWithChildren;
