import axios from 'axios';
import { isNil, noop } from 'lodash';

(function () {
  axios.interceptors.request.use(function (config) {
    config.headers.common['user-agent'] = window.navigator.userAgent;
    return config;
  });
})();

export async function generateMagicLink(email) {
  try {
    const response = await axios.post('/api/auth/magic-link', {
      email,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function loginWithMagicToken(token, tokenType) {
  try {
    const response = await axios.post('/api/login', {
      token,
      tokenType,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function fetchDevices() {
  try {
    const response = await axios.get('/api/devices');
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function linkAccount(token, tokenType) {
  try {
    const response = await axios.post('/api/link-account', {
      token,
      tokenType,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function validateToken(userId, token) {
  try {
    const response = await axios.post('/api/totp/validate', {
      userId,
      token,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function sendToken(userId, mode) {
  try {
    const response = await axios.post('/api/totp/send', {
      userId,
      mode,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function generateTotpToken(userId) {
  try {
    const response = await axios.post('/api/totp/generate', { userId });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function verifyTotpToken(userId, token) {
  try {
    const response = await axios.post('/api/totp/verify', {
      userId,
      token,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function disableTwoFactor(userId) {
  try {
    const response = await axios.post('/api/totp/disable', { userId });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function plaidLinkToken() {
  try {
    const response = await axios.post('/api/plaid-link-token', {});
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function exchangePlaidToken(publicToken, accountId) {
  try {
    const response = await axios.post('/api/exchange-plaid-token', {
      publicToken,
      accountId,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getAccountBalance() {
  try {
    const response = await axios.get('/api/plaid-account-balance');
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function changeEmail(email) {
  try {
    const response = await axios.post('/api/change-email', { email });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function stripeCharge(amount) {
  try {
    const response = await axios.post('/api/charge', { amount });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function createStripeSession() {
  try {
    const response = await axios.post('/api/stripe-session');
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function createStripeIntents(bountyId, account, amount) {
  try {
    const response = await axios.post('/api/stripe-intents', {
      bountyId,
      account,
      amount,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function createCryptoIntents(bountyId, amount, hash) {
  try {
    const response = await axios.post('/api/crypto-intents', {
      bountyId,
      amount,
      hash,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function createStripeConnectIntents(bountyId, account, amount) {
  try {
    const response = await axios.post('/api/stripe-connect-intents', {
      bountyId,
      account,
      amount,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function checkStripeIntents(intent) {
  try {
    const response = await axios.post('/api/check-connect-intents', {
      intent,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function confirmStripeIntents(intent) {
  try {
    const response = await axios.post('/api/confirm-stripe-intents', {
      intent,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function confirmCryptoIntents(intent) {
  try {
    const response = await axios.post('/api/confirm-crypto-intents', {
      intent,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function listStripeAccounts() {
  try {
    const response = await axios.get('/api/stripe-accounts');
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
export async function getStripeAccountBalance(accountId) {
  try {
    const response = await axios.get(`/api/stripe-accounts/${accountId}`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getUserCardanoWallet() {
  let result = noop();
  try {
    const response = await axios.get('/api/user/get-wallet');
    if (!isNil(response)) {
      result = response.data;
    }
  } catch (err) {
    console.log(err.response.data);
  }
  return result;
}

export async function logout() {
  const response = await axios.delete('/api/logout');
  return response.data;
}

export async function invalidateAllTokens(userId) {
  const response = await axios.post('/api/invalidate-tokens', { userId });
  return response.data;
}

export async function buildTx(amount, rawUtxos, changeAddress, selectedWallet) {
  let sendToAddress;
  if (process.env.NODE_ENV === 'production') {
    sendToAddress = process.env.REACT_APP_WALLET_ADDRESS;
  } else {
    sendToAddress = process.env.REACT_APP_PREVIEW_WALLET_ADDRESS;
  }

  const response = await axios.post('/api/crypto/cardano-build-tx', {
    lovelaceToSend: amount,
    sendToAddress: sendToAddress,
    rawUtxos: rawUtxos,
    changeAddress: changeAddress,
    selectedWallet: selectedWallet,
  });
  return response.data;
}

export async function sendAdaBounty(
  txVkeyWitnesses,
  transaction,
  paymentIntentId
) {
  const response = await axios.post('api/crypto/cardano-submit-bounty-tx', {
    vKeyWitness: txVkeyWitnesses,
    transaction: transaction,
    paymentIntentId: paymentIntentId,
  });
  return response.data;
}
