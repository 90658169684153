import React, { useEffect } from 'react';
import { useAuth } from '../context/authContext';
import { navigate } from '@reach/router';

const Home = () => {
  const { auth } = useAuth();
  useEffect(() => {
    if (auth) {
      return navigate('/dashboard');
    } else {
      return navigate('/signin');
    }
  }, []);
  return <div></div>;
};
export default Home;
