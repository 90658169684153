import { Container, Col, Row } from 'react-bootstrap';
import '../css/challengeResultSection.css';
import '../../shared/css/sharedStyles.css';
import Next from '../../assets/icon/snapbrillia_softwareengineernext_icon.svg';
import Back from '../../assets/icon/snapbrillia_softwareengineerback_icon.svg';

const ChallengeResultSections = ({ task, goNext, goBack }) => {
  return (
    <Container className="challenge-result-container" fluid>
      <Row className="challenge-result-row">
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className="challenge-result-title">{task.title}</h1>
            <div>
              <img
                src={Back}
                alt="back-icon"
                className="result-back-svg"
                onClick={goBack}
              />
              <img
                src={Next}
                alt="next-icon"
                className="result-next-svg"
                onClick={goNext}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="challenge-result-col">
            <h1 className="challenge-result-body">{task.instructions}</h1>
            <hr className="divider" />
          </Col>
        </Row>
        <Row>
          <Col className="challenge-result-col">
            <h1 className="challenge-result-label">Plagiarism Risk:</h1>
            <h1 className="challenge-result-title">{task.totalPeopleTaken}%</h1>
          </Col>
          <Col className="challenge-result-col">
            <h1 className="challenge-result-label">Attempted:</h1>
            <h1 className="challenge-result-title">
              {task.totalPeopleTaken} Times
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="challenge-result-col">
            <h1 className="challenge-result-label">Disparity Risk:</h1>
            <h1 className="challenge-result-title">30%</h1>
          </Col>
          <Col className="challenge-result-col">
            <h1 className="challenge-result-label">Pass Rate:</h1>
            <h1 className="challenge-result-title">
              {task.averagePercentage}%
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="challenge-result-col">
            <h1 className="challenge-result-label">Experience Range:</h1>
            <h1 className="challenge-result-title">
              {task.averagePercentage} Years
            </h1>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default ChallengeResultSections;
