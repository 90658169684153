import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, ListGroup } from 'react-bootstrap';
import GoogleForm from './googleForm';
import '../../shared/css/typography.css';
import '../../shared/css/textColors.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/bgColors.css';
import Gmail from '../../assets/icon/gmail.svg';
import Yahoo from '../../assets/icon/yahoo.svg';
import Outlook from '../../assets/icon/outlook.svg';
import { GithubLoginButton } from 'react-social-login-buttons';
import '../css/signInFormStyles.css';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../context/authContext';
import React, { useEffect, useState } from 'react';
import { Link, navigate, useLocation } from '@reach/router';
import { getGitHubUrl } from '../../utils/getGithubUrl';
import qs from 'query-string';
import RequestDemoModal from '../../requestDemo/requestDemoModal';
import RequestButton from '../../shared/button/useThisEmail';
export default function SignInForm() {
  const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);
  const [step, setStep] = useState(1);
  const { generateLink, login, loading, auth } = useAuth();
  const search = useLocation().search;
  const token = qs.parse(search).token;
  const code = qs.parse(search).code;
  const tokenType = qs.parse(search).type;
  const requestDemoText = 'Request Demo';
  let from = useLocation().state?.from?.pathname || '/';

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Email is not valid!')
      .required('Email is required!'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await generateLink(values.email);
        setStep(2);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const checkAuth = () => {
    setTimeout(() => {
      if (auth) {
        navigate('/dashboard');
      }
    }, 1000);
  };

  useEffect(() => {
    if (token && tokenType) {
      login(token, tokenType);
    }
    if (auth) {
      checkAuth();
    }
  }, []);

  useEffect(() => {
    if (code) {
      login(code, 'github');
    }
    if (auth) {
      checkAuth();
    }
  }, []);

  const handleRequestDemo = () => {
    setShowRequestDemoModal(true);
  };

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center signin-container">
      {loading && (
        <>
          <div className="LoadingWapper">
            <div className="spinner-border text-light" role="status"></div>
          </div>
        </>
      )}
      <Row className="sign-in-heading-row w-100 mx-auto justify-content-center mt-5 md:mt-0">
        <Col md={4} className="d-flex flex-column justify-content-center p-0">
          <h1 className="h1 sign-in-heading extra-bold">
            Let&apos;s Get Started!
          </h1>
          <p className="h4 label w-10 mx-auto sign-in-heading-small">
            Pick your preferred sign in method.
          </p>

          <RequestButton
            text={requestDemoText}
            onClick={handleRequestDemo}
            className="h4 label w-10 mx-auto sign-in-heading-small"
          />
        </Col>
      </Row>
      <Row className="sign-in-heading-row w-100 mx-auto justify-content-center mt-5 md:mt-0">
        {step === 2 ? (
          <>
            <Col
              md={4}
              className="d-flex flex-column justify-content-center p-0"
            >
              <h2 className="h1 extra-bold my-2">Check your email</h2>
              <p>An email was sent to {formik.values.email}</p>
              <a
                target="_blank"
                rel="noreferrer"
                className="ck24 "
                href="https://mail.google.com/mail/u/0/#search/%40snapbrillia"
              >
                <span className="sort ">
                  <img src={Gmail} alt=" Open in Gmail" height={22} />
                </span>
                <span className="sort px-3">Open in Gmail</span>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="ck24 "
                href="https://mail.yahoo.com/d/search/keyword=snapbrillia.com"
              >
                <span className="sort ">
                  <img src={Yahoo} alt=" Open in Gmail" height={22} />
                </span>
                <span className="sort px-3">Open in Yahoo</span>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="ck24 "
                href="https://outlook.office.com/mail/0/inbox"
              >
                <span className="sort ">
                  <img src={Outlook} alt=" Open in Gmail" height={22} />
                </span>
                <span className="sort px-3">Open in Outlook</span>
              </a>
              <input
                className="bg-primary text-white border-0 my-3 py-2 w-75"
                value="Try again"
                type="button"
                onClick={() => {
                  setStep(1);
                }}
              />
            </Col>
          </>
        ) : (
          <>
            <Col
              md={4}
              className="d-flex flex-column justify-content-center p-0"
            >
              <Form style={{ padding: '0' }} onSubmit={formik.handleSubmit}>
                <h2 className="h1 extra-bold my-2">Sign up or log in</h2>
                <Form.Group className="position-relative bounties-time-fields my-2">
                  <input
                    {...formik.getFieldProps('email')}
                    className="border border-dark bg-white"
                    name="email"
                    type="email"
                    placeholder="example@email.com"
                  />
                </Form.Group>
                {formik.touched.name && formik.errors.name && (
                  <p className="bounties-form-error">{formik.errors.name}</p>
                )}
                <Form.Group className="position-relative bounties-time-fields my-2">
                  <input
                    className="bg-primary text-white"
                    value="continue with email"
                    type="button"
                    onClick={formik.handleSubmit}
                  />
                </Form.Group>
              </Form>
              <div className="dividerLogin d-flex align-items-center my-1">
                <p className="text-center fw-bold mx-3 mb-0">OR</p>
              </div>
              <GoogleForm login={login} />
              <GithubLoginButton
                className="mx-0 w-100"
                onClick={() => navigate(getGitHubUrl(from))}
              />
            </Col>
          </>
        )}
      </Row>

      <RequestDemoModal
        show={showRequestDemoModal}
        closeModal={() => {
          setShowRequestDemoModal(false);
        }}
      />
    </Container>
  );
}
