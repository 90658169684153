import React from 'react';
import '../css/importCandidateHeader.css';

const ImportCandidateHeader = ({ className }) => {
  return (
    <p className={`candidate-header-text ${className}`}>Import Candidate</p>
  );
};

export default ImportCandidateHeader;
