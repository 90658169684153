import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { navigate } from '@reach/router';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AllUsersTable from './components/allUsersTable';
import AllCandidatesTable from './components/allCandidatesTable';
import AllCompaniesTable from './components/allCompaniesTable';
import './css/userManagement.css';
import AllCouponTable from './components/allCouponTable';
import AllOpportunitiesTable from './components/allOpportunitiesTable.jsx';

const UserManagement = () => {
  const tabs = [
    {
      title: 'Users',
      component: <AllUsersTable />,
    },
    {
      title: 'Candidates',
      component: <AllCandidatesTable />,
    },
    {
      title: 'Opportunities',
      component: <AllOpportunitiesTable />,
    },
    {
      title: 'Companies',
      component: <AllCompaniesTable />,
    },
    {
      title: 'Coupons',
      component: <AllCouponTable />,
    },
  ];
  const [component, setComponent] = useState(tabs[0]);
  const changeComponent = (index) => {
    setComponent(tabs[index]);
  };
  return (
    <div>
      <Container className="p-0 pt-3" fluid>
        <Row className="management-tab-title-container">
          {tabs.map((tab, index) => {
            return (
              <Col key={index}>
                <label
                  className={
                    tab.title === component.title
                      ? 'management-active-tab-title management-title-view-tab'
                      : 'management-inactive-tab-title management-title-view-tab'
                  }
                  onClick={() => {
                    navigate('/personal-profile');
                    changeComponent(index);
                  }}
                  htmlFor={`radio-${index}`}
                >
                  {tab.title}
                  <span className={`${index > 0 && 'left-curve'}`}></span>
                </label>
              </Col>
            );
          })}
          <div id="tab-glider" />
        </Row>
        <Col
          className={
            component.title === 'Users'
              ? 'management-tabs-view-tab-overview'
              : 'management-tabs-view-tab'
          }
        >
          {' '}
          {component.component}
        </Col>
      </Container>
    </div>
  );
};
export default UserManagement;
