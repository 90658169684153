import { Container, Row, Col } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import {
  formatDate,
  correctlyCap,
  convertDifficulty,
  changeTaskColor,
} from '../../utils/functions';
import EnginerIcon from '../../assets/icon/snapbrillia_softwareengineermoreless_icon.svg';
import Edit from '../../assets/icon/snapbrillia_edit_icon.svg';
import Close from '../../assets/icon/snapbrillia_close_icon.svg';

import '../css/candidateInterviewSections.css';
import '../css/challengeTitleSection.css';

const ChallengeTitleSection = ({ task, isPreffered, closeExpanded }) => {
  return (
    <Container fluid className="challenge-title-container">
      <Row>
        <Col className="challenge-title-top">
          <img
            src={Close}
            alt="close-icon"
            className="challenge-close-icon"
            onClick={closeExpanded}
          />
          <h1 data-testid="title" className="challenge-title">
            {task.title}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="challenge-box">
          <h1 className="box-header">Details</h1>
          <Row>
            <Col>
              <h1 className="text-titles">Date Created:</h1>
              <p data-testid="date" className="text">
                {formatDate(task.updatedAt)}
              </p>
            </Col>
            <Col>
              <h1 className="text-titles">Difficulty:</h1>
              <p
                data-testid="difficulty"
                className="text"
                style={{
                  color: `#${changeTaskColor(task.difficulty)}`,
                }}
              >
                {convertDifficulty(task.difficulty)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="text-titles">Language:</h1>
              <p data-testid="language" className="challenge-title-skillpills">
                {correctlyCap(task.skillTested)}
              </p>
            </Col>
            <Col>
              <h1 className="text-titles">Average Duration</h1>
              <p className="text">
                2 Minutes,
                <br />
                <div style={{ whiteSpace: 'nowrap' }}>40 Seconds</div>
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="side-box" xs={7}>
          <Row>
            <div className="challenge-icons-holder">
              <div className="challenge-icons">
                <img src={EnginerIcon} alt="engineer-icon" />
                <img src={Edit} alt="edit-icon" height={20} width={20} />
                {isPreffered ? (
                  <button className="expanded-challenge-remove">Remove</button>
                ) : (
                  <button className="expanded-challenge-add">Add</button>
                )}
              </div>
            </div>
          </Row>
          <Row>
            <Col className="challenge-title-score-container">
              <div className="testing">
                <div className="challenge-title-score-holder">
                  <h1 className="box-header">Average Score</h1>
                  <h1 className="percentage" data-testid="percentage">
                    60%
                  </h1>
                </div>
                <ProgressBar style={{ height: '10px' }} now={60} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ChallengeTitleSection;
