import './style.css';

export const Modal = ({ children }) => {
  return (
    <>
      {true && (
        <div
          className="modal__background"
          onClick={(e) => {
            e.preventDefault();
            // closeModal();
          }}
        >
          <div className="modal__body">{children}</div>
        </div>
      )}
    </>
  );
};
