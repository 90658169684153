import { select, selectAll, scaleBand, scaleLinear, axisBottom, max } from 'd3';
//This file is implemention of bar chart used for allbarchart.jsx

export function VerticalBarChart(
  data,
  width,
  height,
  svg,
  barcolors,
  showAxis
) {
  // remove all previous elements for fresh re-render
  svg.selectAll('g').remove();
  // X AND Y VALUES OF CHART
  const yValue = (d) => d.statistics;
  const xValue = (d) => d.values;

  const margin = { top: 20, right: 5, bottom: height / 10, left: 20 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  //SET X VALUES
  const yScale = scaleLinear()
    .domain([0, max(data, yValue)])
    .range([0, innerHeight]);
  //SET Y VALUES
  const xScale = scaleBand()
    .domain(data.map(xValue))
    .range([0, innerWidth])
    .padding(0.6);

  //SET BAR FOR DIFFRENT VALUES
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .style('font-size', innerHeight / yScale.domain().length);

  if (showAxis === true) {
    //X AXIS LABLE
    g.append('g')
      .call(axisBottom(xScale)) //Gridline
      .style('font-size', function () {
        //Axis Font-size
        // if (innerHeight / xScale.domain().length < 8)
        //   return innerHeight / xScale.domain().length;
        // else return 8;
        if (width < 350) {
          return 0;
        } else {
          return 10;
        }
      })
      .style('opacity', '0.3')
      .attr('transform', `translate(0,${innerHeight})`);
  }

  g.selectAll('.domain, path').remove(); //remove x-axis Line
  //REMOVE EXTRA AND UNUSED LINES OF Y AXIS
  g.selectAll('.domain, .tick line').remove();

  // CREATE BAR FOR ALL VALUES
  g.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', (d) => xScale(xValue(d)))
    .attr('y', function (d) {
      const height = yScale(yValue(d)) - xScale.bandwidth();
      return yScale.range()[1] - height;
    })
    .attr('id', function (d, i) {
      return 'rect' + i;
    }) //Assign id to rect
    .attr('height', function (d) {
      const height = yScale(yValue(d));
      if (height > xScale.bandwidth()) {
        return height - xScale.bandwidth();
      } else {
        return 0;
      }
    })
    .attr('width', xScale.bandwidth())
    .attr('rx', 7)
    .attr('fill', function (d, i) {
      return barcolors[i];
    }) //Assign different color to rect
    .on('mouseover', function (d, i) {
      tooltip
        .html(`${i.values}: ${i.statistics}`)
        .style('visibility', 'visible');
    })
    .on('mousemove', function () {
      tooltip
        // eslint-disable-next-line no-restricted-globals
        .style('top', event.pageY - 10 + 'px')
        // eslint-disable-next-line no-restricted-globals
        .style('left', event.pageX + 10 + 'px');
    })
    .on('mouseout', function () {
      tooltip.html(``).style('visibility', 'hidden');
    });
  const xParam = [];
  const yParam = [];
  let barHeight = 0;
  // let bary;
  for (let i = 0; i < xScale.domain().length; i++) {
    xParam[i] = +selectAll(`#rect${i}`).attr('x');
    yParam[i] = +selectAll(`#rect${i}`).attr('y') + 1;
    if (+selectAll(`#rect${i}`).attr('height') > barHeight) {
      barHeight = +selectAll(`#rect${i}`).attr('height');
      // commenting out temporarily since it is associated with the commented code below
      // bary = +selectAll(`#rect${i}`).attr('y');
    }
  }

  // commenting out temporarily due to this path not re-rendering correctly when multiple vertical bars are rendered in the same page
  // g.selectAll('path')
  //   .data(data)
  //   .enter()
  //   .append('path')
  //   .attr('clip-path', 'url(#clip-3)')
  //   .attr('d', function (d, i) {
  //     return `M ${
  //       xParam[i]
  //     } ${yParam[i]} C ${xParam[i]} ${yParam[i] - (3 * xScale.bandwidth()) / 4}, ${xParam[i] + xScale.bandwidth()}  ${yParam[i] - (3 * xScale.bandwidth()) / 4}, ${xParam[i] + xScale.bandwidth()} ${yParam[i]}`;
  //   })
  //   .attr('stroke', 'none')
  //   .attr('fill', function (d, i) {
  //     return barcolors[i];
  //   })
  //   .on('click', function (d, i) {
  //     tooltip
  //       .html(`${i.values}: ${i.statistics}`)
  //       .style('visibility', 'visible');
  //   })
  //   .on('mousemove', function () {
  //     tooltip
  //       // eslint-disable-next-line no-restricted-globals
  //       .style('top', event.pageY - 10 + 'px')
  //       // eslint-disable-next-line no-restricted-globals
  //       .style('left', event.pageX + 10 + 'px');
  //   })
  //   .on('mouseout', function () {
  //     tooltip.html(``).style('visibility', 'hidden');
  //   });
  // svg.select('#clip-pathrect').attr('height', `${bary + barHeight}`);

  //------------------------------Toottipbox for hover effect----------------------------
  const tooltip = select('body')
    .append('div')
    .attr('x', innerWidth / 2)
    .attr('y', margin.top / 2)
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('color', ' black')
    .style('padding', '10px')
    .style('border', '1px solid #808080')
    .style('background', 'white')
    .style('border-radius', '5px');
}
export default VerticalBarChart;
