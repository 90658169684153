import axios from 'axios';

export async function getRepositories(githubId = '', githubOrg = '') {
  const response = await axios.get(
    `/api/repositories?githubId=${githubId}&githubOrg=${githubOrg}`
  );

  return response.data;
}

export async function getOrganizations() {
  const response = await axios.get(`/api/organizations`);
  return response.data;
}

export async function getRepository(url) {
  const newUrl = new URL(url);
  const repoName = newUrl.pathname.substring(1);
  const response = await axios.get(
    `/api/repositories/search?repoName=${repoName}`
  );

  return response.data;
}

export async function getBranches(url) {
  const newUrl = new URL(url);
  const repoName = newUrl.pathname.substring(1);
  try {
    const response = await axios.get(
      `https://api.github.com/repos/${repoName}/branches`
    );

    return response.data;
  } catch (err) {
    return [];
  }
}

export async function createRepository(repoName) {
  try {
    const response = await axios.post(`/api/repositories`, {
      repoName: repoName,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
