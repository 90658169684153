import React from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Modal } from 'react-bootstrap';
import '../../shared/css/typography.css';
import '../../shared/css/bgColors.css';
import '../../shared/css/sharedStyles.css';
import { toast } from 'react-toastify';
import InviteButton from '../../shared/button/invite';

const CouponResult = ({ handleClose, showModal, result }) => {
  return (
    <Modal onHide={handleClose} backdrop="static" show={showModal}>
      <Container className="add-candidate-modal-container">
        <Row>
          <Col>
            <Modal.Header className="modalHeader" closeButton>
              <Modal.Title>
                <span className="h2">{`Coupon Creation Result`}</span>
              </Modal.Title>
            </Modal.Header>
            <Row>
              <Col>{result.coupon}</Col>
              <Col>
                <InviteButton
                  className={`candidate-modal-button`}
                  title={'Copy Code'}
                  handleClick={() => {
                    navigator.clipboard.writeText(result.coupon);
                    toast('Coupon code copied');
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
export default CouponResult;
