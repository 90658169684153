/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import { useParams, Link } from '@reach/router';
import { Box, TableRow } from '@mui/material';
import { Button } from 'react-bootstrap';
import * as userAPI from '../../api/users';
import * as bountyAPI from '../../api/bounties';
import AddBountiesFormButton from '../../shared/button/addBountiesForm';
import BountyAddCandidateModal from '../../candidateModal/bountyAddCandidateModal';
import { reInvite } from '../../api/opportunities';
import Back from '../../shared/button/back';

const AllCandidatesTable = () => {
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showAddCandidate, setShowAddCandidate] = useState(false);
  const companyId = useParams()?.companyId;
  const gigId = useParams()?.gigId;

  const getCandidateData = async (search) => {
    setLoading(true);
    await bountyAPI
      .getAllBountyCandidates(companyId, {
        gigId,
        search,
      })
      .then((response) => {
        setCandidates(response);
      })
      .catch((error) => {
        setCandidates([]);
      });
    setLoading(false);
  };

  const reinvite = async (candidateId) => {
    try {
      await reInvite(candidateId);
      toast('Reinvite Successful');
    } catch (err) {
      toast(err.error);
    }
  };

  useEffect(() => {
    getCandidateData();
  }, []);
  //should be memoized or stable
  var startTime = performance.now();
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.fullName || row?.candidateId?.fullName, //access nested data with dot notation
        header: 'Full Name',
        size: 150,
      },
      {
        accessorFn: (row) => row.email || row?.candidateId?.email,
        header: 'Email',
        size: 50,
      },
      {
        accessorFn: (row) =>
          new Date(row?.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 150,
      },
    ],
    []
  );
  var endTime = performance.now();

  console.log(`Call to doSomething took ${endTime - startTime} milliseconds`);
  return (
    <>
      {companyId && (
        <>
          <Back onClick={() => window.history.go(-1)} />
          <div className="float-end">
            <AddBountiesFormButton
              text={'Invite Candidate'}
              handleClick={() => setShowAddCandidate(true)}
            />
          </div>
          {showAddCandidate && (
            <BountyAddCandidateModal
              bounty={{ _id: gigId }}
              handleClose={() => setShowAddCandidate(false)}
              showModal={showAddCandidate}
              handleAddCandidate={() => {
                getCandidateData();
                setShowAddCandidate(false);
              }}
            />
          )}
        </>
      )}
      <MaterialReactTable
        columns={columns}
        data={candidates}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 200, //set custom width
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
          },
        }}
        enableRowActions={!!gigId}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.3rem' }}>
            <Button
              onClick={async () => {
                reinvite(row.original._id);
              }}
            >
              Reinvite
            </Button>
          </Box>
        )}
      />
    </>
  );
};

export default AllCandidatesTable;
