export const colors = {
  gender: ['#FF69B4', '#0000FF', '#FFD700', '#808080'],
  ethnicity: ['#E2725B', '#4AB7A9', '#3B4C70', '#FFD800', '#0B69A5', '#808080'],
  age: ['#A95CF1', '#68D38D', '#FFD700', '#4770B3', '#808080'],
  veteranStatus: ['#0B6623', '#3E4095', '#7E7E7E'],
  impairmentStatus: ['#FF5252', '#00AA55', '#B0B0B0'],
  neurodivergence: ['#FFA500', '#3E4095', '#808080'],
  degree: [
    '#4D8FB4',
    '#4CAF50',
    '#00BCD4',
    '#9C27B0',
    '#FFC107',
    '#673AB7',
    '#FF5722',
    '#B0B0B0',
  ],
};
