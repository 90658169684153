import React from 'react';
import '../css/invitesSent.css';
import '../../shared/css/typography.css';

const InvitesSent = () => {
  return (
    <div className="assessment-bounties-invite-container">
      <h1 className="h1">Invites Sent!</h1>
      <p className="assessment-bounties-invite-paragraph">
        Now that you have team members added lets get started setting up your
        first gig.
      </p>
    </div>
  );
};

export default InvitesSent;
