import React from 'react';
import '../../shared/css/typography.css';

const profileAccessibilityText = () => {
  return (
    <div style={{ lineHeight: 1 }}>
      <p>
        <span className="policy_typography">
          Snapbrillia is committed to ensuring that all persons who may have one
          or more disabilities have easy and complete access to all the services
          which are available to all potential and existing customers.
          Snapbrillia is currently reviewing and updating the Snapbrillia
          platforms to ensure that both the overall website and all
          documents/resources posted thereon, are available and provided in
          alternative, accessible formats.
        </span>
      </p>
      <p>
        <span className="policy_typography">
          Snapbrillia is in the progress to make our products compliant in
          accordance with the guidelines inherited from Section 508 of the
          Rehabilitation Act (29 U.S.C. 794d), as amended in 1998, the European
          accessibility standard En 301 549, and the W3C WCAG (Web Content
          Accessibility Guidelines) 2.1 Level AA requirements.
        </span>
      </p>
      <p>
        <span className="policy_typography">
          Our platforms and web pages are being built using these guidelines. We
          regularly measure our platforms using a variety of methods, such as
          browser plug-ins, external vendor assessments, and individual page
          testing by our own Product Design team with a keen focus on
          accessibility.
        </span>
      </p>
      <p>
        <span className="policy_typography">
          This multifaceted approach helps ensure that accessibility will be
          maintained as guidelines change and technology evolves.
        </span>
      </p>
      <p>
        <span className="policy_typography">
          For more information, questions, or concerns regarding accessibility
          at Snapbrillia or to request communication in an accessible format,
          please contact Snapbrillia Customer Support via:
        </span>
      </p>
      <p>
        <span className="policy_typography">
          By Email: [support@snapbrillia.com]
        </span>
      </p>
      <p>
        <span className="policy_typography">
          By Regular Mail: ATTN: Customer Support, dba Snapbrillia, Inc.
        </span>
      </p>
      <p>
        <span className="policy_typography">1043 Garland Ave</span>
      </p>
      <p>
        <span className="policy_typography">Unit C #764</span>
      </p>
      <p>
        <span className="policy_typography">San Jose, CA 95126-3159</span>
      </p>
    </div>
  );
};
export default profileAccessibilityText;
