import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../../shared/css/bgColors.css';

//COMPONENTS
import BountySummary from './bountySummary';
import DisparityRate from './disparityRate';
import ResponseRate from './responseRate';
import Analytics from './analytics';

const BountiesPageOverview = ({ bounty }) => {
  return (
    <Container fluid>
      <Row
        style={{
          borderRadius: '10px',
          padding: '10px',
          background: '#FFFFFF',
          margin: '25px 0',
        }}
      >
        <BountySummary bounty={bounty} />
      </Row>
      {/* <Row style={{ margin: '25px 0' }}>
        <Col xs={12} md={5}>
          <Row
            className=""
            style={{
              borderRadius: '10px',
              padding: '10px',
              background: '#FFFFFF',
              marginRight: '10px',
            }}
          >
            <DisparityRate bountyId={bounty._id} />
          </Row>
          <Row
            className=""
            style={{
              borderRadius: '10px',
              marginTop: '25px',
              marginBottom: '25px',
              padding: '10px',
              background: '#FFFFFF',
              marginRight: '10px',
            }}
          >
            <ResponseRate bountyId={bounty._id} />
          </Row>
        </Col>
        <Col
          xs={12}
          md={7}
          style={{
            borderRadius: '10px',
            padding: '10px',
            background: '#FFFFFF',
          }}
        >
          <Analytics bountyId={bounty._id} />
        </Col>
      </Row> */}
    </Container>
  );
};

export default BountiesPageOverview;
