import React, { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../css/bountiesForm.css';

//COMPONENTS
import NextButton from '../../shared/button/next';
import BackButton from '../../shared/button/back';
import ExpandIcon from '../../assets/icon/snapbrillia_description_drag.svg';
import { useOnBoarding } from '../../context/onboardingContext';
import { Link } from '@reach/router';
import { RichTextEditor } from '@mantine/rte';
import { SUPPORT_LANGUAGES } from '../../shared/constants';

const BountiesForm = ({ repo }) => {
  const { createBounty } = useOnBoarding();

  const formik = useFormik({
    initialValues: {
      name: '',
      taskDescription: '',
      submissionRequirements: '',
      acceptenceCriteria: '',
      importantLink: '',
      timeEstimation: '',
      officeHour: '',
      officeLink: '',
      rewardAmount: 0,
      languages: repo.languages || {},
      repoName: repo.name,
      repoBranch: repo.repoBranch,
      repoDescription: repo.description,
      teamPreference: 'false',
      permissionLess: 'false',
    },

    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      taskDescription: Yup.string().required('Task Description is required'),
      submissionRequirements: Yup.string().required(
        'Submission Requirements is required'
      ),
      acceptenceCriteria: Yup.string().required(
        'Acceptance Criteria is required'
      ),
      timeEstimation: Yup.string().required('Time Estimation is required'),
      rewardAmount: Yup.number().required('Reward Amount is required'),
      teamPreference: Yup.string().required('Team Preference is required'),
      permissionLess: Yup.string().required('Permission is required'),
      languages: Yup.object().test(
        'notEmptyObject',
        () => 'Languages is required',
        (value) => {
          return Object.keys(value).length > 0;
        }
      ),
    }),

    onSubmit: (values) => {
      createBounty(values);
    },
  });

  const [customTimeEst, setCustomTimeEst] = useState({
    state: false,
    value: '',
  });

  const handleSelectTimeEstChange = (e) => {
    if (e.target.value === 'other') {
      setCustomTimeEst({
        ...customTimeEst,
        state: true,
      });
    } else {
      formik.handleChange(e);
    }
  };

  const handleChangeTimeEst = (e) => {
    setCustomTimeEst({
      ...customTimeEst,
      value: e.target.value,
    });
    formik.handleChange(e);
  };

  const handleKeyDownTimeEst = (e) => {
    if (e.key === 'Enter') {
      setCustomTimeEst({
        ...customTimeEst,
        state: false,
      });
      setTimeout(() => {
        const timeSelectElem = document.getElementById('time-estimation');
        if (!customTimeEst.value) {
          formik.handleChange(e);
          return false;
        } else {
          const otherTime = document.createElement('option');
          otherTime.textContent = `${customTimeEst.value} hours`;
          otherTime.value = customTimeEst.value;
          otherTime.selected = true;
          timeSelectElem.appendChild(otherTime);
          setCustomTimeEst({
            ...customTimeEst,
            state: false,
            value: '',
          });
        }
      }, 100);
    }
  };
  const handleLineBreak = (e) => {
    if (e.key === 'Enter' && e.target.rows < 12) {
      e.target.rows += 1;
    }

    if (e.keyCode === 8 && e.target.rows > 1) {
      if (e.target.value[e.target.selectionStart - 1] === '\n') {
        e.target.rows -= 1;
      }
    }
  };
  // richtexteditor:https://mantine.dev/others/rte/
  return (
    <Container>
      <Form style={{ padding: '0' }} onSubmit={formik.handleSubmit}>
        <p className="primary fs-5 ">
          Tell us a little bit about the bounty and what problem needs to be
          resolved
        </p>
        <Form.Group className="position-relative bounties-time-fields">
          <Form.Label className="label medium my-3 fs-6">
            Name <span className="bounties-required">*</span>
          </Form.Label>
          <input {...formik.getFieldProps('name')} name="name" type="text" />
        </Form.Group>
        {formik.touched.name && formik.errors.name && (
          <p className="bounties-form-error">{formik.errors.name}</p>
        )}

        <Form.Group className="position-relative">
          <Form.Label className="label medium my-3 fs-6">
            Task Description <span className="bounties-required">*</span>
          </Form.Label>
          <RichTextEditor
            {...formik.getFieldProps('taskDescription')}
            name="taskDescription"
            rows="3"
            onChange={(values) => {
              formik.handleChange({
                target: { name: 'taskDescription', value: values },
              });
            }}
          />

          <img
            src={ExpandIcon}
            alt="expand-icon"
            className="bounties-form-expand-icon"
          />
        </Form.Group>
        {formik.touched.taskDescription && formik.errors.taskDescription && (
          <p className="bounties-form-error">{formik.errors.taskDescription}</p>
        )}
        <Form.Group className="position-relative">
          <Form.Label className="label medium my-3 fs-6">
            Submission Requirements <span className="bounties-required">*</span>
          </Form.Label>
          <textarea
            maxLength={150}
            {...formik.getFieldProps('submissionRequirements')}
            rows="1"
            onKeyDown={handleLineBreak}
          ></textarea>
          <img
            src={ExpandIcon}
            alt="expand-icon"
            className="bounties-form-expand-icon"
          />
        </Form.Group>
        {formik.touched.submissionRequirements &&
          formik.errors.submissionRequirements && (
            <p className="bounties-form-error">
              {formik.errors.submissionRequirements}
            </p>
          )}
        <Form.Group className="position-relative">
          <Form.Label className="label medium my-3 fs-6">
            Acceptance Criteria <span className="bounties-required">*</span>
          </Form.Label>
          <textarea
            {...formik.getFieldProps('acceptenceCriteria')}
            name="acceptenceCriteria"
            onKeyDown={handleLineBreak}
            rows="1"
          ></textarea>
          <img
            src={ExpandIcon}
            alt="expand-icon"
            className="bounties-form-expand-icon"
          />
        </Form.Group>
        {formik.touched.acceptenceCriteria &&
          formik.errors.acceptenceCriteria && (
            <p className="bounties-form-error">
              {formik.errors.acceptenceCriteria}
            </p>
          )}
        <Form.Group className="position-relative">
          <Form.Label className="label medium my-3 fs-6">
            Languages *
          </Form.Label>
          <div className="add-project-skills-wrapper">
            {SUPPORT_LANGUAGES.map((skill, index) => (
              <span
                key={index}
                id={index}
                className={`add-project-skill ${
                  formik.getFieldProps('languages').value[skill]
                    ? 'active-skill'
                    : ''
                }`}
                onClick={() => {
                  let languages = formik.getFieldProps('languages').value || {};
                  if (languages[skill]) {
                    delete languages[skill];
                  } else {
                    languages[skill] = 1;
                  }
                  formik.handleChange({
                    target: { name: 'languages', value: languages },
                  });
                }}
              >
                {skill}
              </span>
            ))}
          </div>
        </Form.Group>
        {formik.touched.languages && formik.errors.languages && (
          <p className="bounties-form-error">{formik.errors.languages}</p>
        )}
        <Form.Group className="position-relative">
          <Form.Label className="label medium my-3 fs-6">
            Important Links
          </Form.Label>
          <textarea
            onKeyDown={handleLineBreak}
            rows="1"
            {...formik.getFieldProps('importantLink')}
            name="importantLink"
          ></textarea>
          <img
            src={ExpandIcon}
            alt="expand-icon"
            className="bounties-form-expand-icon"
          />
        </Form.Group>
        <Row className="justify-content-center">
          <Col sx={12} md={5} lg={6}>
            <Form.Group className="position-relative ">
              <label className="bounties-form-label">
                Work Preference <span className="bounties-required">*</span>
              </label>
              <select
                onChange={formik.handleChange}
                className="bounty-form-select-field"
                name="teamPreference"
                {...formik.getFieldProps('teamPreference')}
              >
                <option disabled>Select</option>
                <option value={true}>Team Only</option>
                <option value={false}>Open To All</option>
              </select>
              {formik.touched.teamPreference &&
                formik.errors.teamPreference && (
                  <p className="bounties-form-error">
                    {formik.errors.teamPreference}
                  </p>
                )}
            </Form.Group>
          </Col>
          <Col sx={12} md={5} lg={6}>
            <Form.Group className="position-relative ">
              <label className="bounties-form-label">
                Permission <span className="bounties-required">*</span>
              </label>
              <select
                onChange={formik.handleChange}
                className="bounty-form-select-field"
                name="permissionLess"
                {...formik.getFieldProps('permissionLess')}
              >
                <option disabled>Select</option>
                <option value={false}>Accept All</option>
                <option value={true}>Need Approval</option>
              </select>
              {formik.touched.permissionLess &&
                formik.errors.permissionLess && (
                  <p className="bounties-form-error">
                    {formik.errors.permissionLess}
                  </p>
                )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sx={12} md={5} lg={6}>
            <Form.Group className="position-relative bounties-time-fields">
              <label className="bounties-form-label">
                Time Estimation <span className="bounties-required">*</span>
              </label>

              {!customTimeEst.state ? (
                <select
                  onChange={handleSelectTimeEstChange}
                  className="bounty-form-select-field"
                  name="timeEstimation"
                  id="time-estimation"
                  defaultValue="Select"
                >
                  <option disabled>Select</option>
                  <option value="40">40 hours</option>
                  <option value="60">60 hours</option>
                  <option value="80">80 hours</option>
                  <option value="other">Other</option>
                </select>
              ) : (
                <>
                  <div className="bounty-form-time-est-wrapper">
                    <div className="bounty-form-time-est-placeholder">
                      Hours
                    </div>
                    <input
                      onChange={handleChangeTimeEst}
                      onKeyDown={handleKeyDownTimeEst}
                      className="mb-0 text-center"
                      name="timeEstimation"
                      type="number"
                      min="0"
                      autoFocus
                    />
                  </div>
                  <span className="small-text ms-1 position-absolute">
                    press enter
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-return-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>
                  </span>
                </>
              )}
            </Form.Group>
            {formik.errors.timeEstimation ? (
              <span className="bounties-form-error">
                {formik.errors.timeEstimation}
              </span>
            ) : undefined}
          </Col>
          <Col xs={12} md={5} lg={6}>
            <Form.Group className="position-relative bounties-time-fields">
              <Form.Label className="label medium my-3 fs-6">
                Reward Amount <span className="bounties-required">*</span>
              </Form.Label>
              <Row className="reward">
                <Col>
                  <div className="input-group mb-3">
                    <input
                      type="number"
                      className="form-control"
                      {...formik.getFieldProps('rewardAmount')}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </Col>
              </Row>

              {formik.touched.rewardAmount && formik.errors.rewardAmount && (
                <p className="bounties-form-error">
                  {formik.errors.rewardAmount}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="text-xxl-end align-items-center mt-5 justify-content-md-center text-center justify-content-lg-end">
          <Col xs={6} md={6} lg={4} xl={4}>
            <Link to="/setup-assessment">
              <BackButton />
            </Link>
          </Col>
          <Col xs={4} md={6} lg={4} xl={3} xxl={2}>
            <NextButton type="submit" />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BountiesForm;
