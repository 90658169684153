/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTable, useRowState, useSortBy, useFilters } from 'react-table';
import Select, { components } from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';

import * as LogsAPI from '../../api/logs';
import ViewLogEntries from '../../shared/button/viewentries';
import SortUpSVG from '../../assets/icon/snapbrillia_sort_up_selected.svg';
import SortDownSVG from '../../assets/icon/snapbrillia_sort_down_selected.svg';
import UnselectedSVG from '../../assets/icon/snapbrillia_sort_unselected.svg';
import DropDown from '../../assets/icon/snapbrillia_dropdown_icon.svg';

import '../css/allUsersTable.css';
import '../../shared/css/sharedStyles.css';

const LogsViewer = () => {
  const [counter] = useState(0);
  const [curCol, setCurCol] = useState();
  const [dropdownSort, setDropdownSort] = useState('Alphabetical');
  const [open, setOpen] = useState([-1, -1, -1]);
  const [tdHeight, setTdHeight] = useState();
  const [dataState, setDataState] = useState([]);
  const filter = useRef();
  const active = useRef();
  const sort = useRef();
  // const scroll = useRef();
  const tdCell = useRef();

  const getTableData = async (search) => {
    const tableData = await LogsAPI.getLogs({ search }).then((logs) => {
      return logs.map(async (e) => {
        return {
          col0: e?.service,
          col1: e?.count,
          col2: (
            <>
              <div
                onClick={() => {
                  //TODO:Open modal to view entries
                }}
              >
                <ViewLogEntries />
              </div>
            </>
          ),
        };
      });
    });

    Promise.all(tableData).then((result) => {
      setDataState(result);
    });
  };

  useEffect(() => {
    getTableData();
  }, []);

  const data = useMemo(() => [...dataState], [dataState]);

  const onSorting = (e, column) => {
    if (e.value === 'alphabetical') {
      setDropdownSort('Alphabetical');
      setCurCol();
      column.isSorted = true;
      column.isSortedDesc = false;
    } else {
      setDropdownSort('Alphabetical(Z-A)');
      setCurCol();
      column.isSorted = true;
      column.isSortedDesc = true;
    }

    column.toggleSortBy(column.isSortedDesc);
  };

  const onFiltering = useCallback(
    (e, headerGroups) => {
      const { setFilter } = headerGroups[0].headers[0];
      setFilter(e.value === 'all' ? undefined : e.label);
    },
    [dataState]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Controller/service',
        accessor: 'col0',
      },
      {
        Header: 'Entries Count',
        accessor: 'col1',
        style: { 'white-space': 'unset' },
      },
      {
        Header: 'Entries',
        accessor: 'col2',
      },
    ],
    []
  );

  const options = [
    [
      { value: 'all', label: 'All' },

      { value: 'open', label: 'Open' },
      { value: 'done', label: 'Done' },
    ],
    [{ value: 'all', label: 'All' }],
    [
      { value: 'alphabetical', label: 'Alphabetical' },
      { value: 'reversed', label: 'Alphabetical(Z-A)' },
    ],
  ];

  const DropdownIndicator = (state) => {
    return (
      <components.DropdownIndicator {...state}>
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <img src={DropDown} alt="Drop down" />
        </div>
      </components.DropdownIndicator>
    );
  };
  const customStyles = (id) => {
    return {
      option: (provided, state) => {
        return {
          color: state.isSelected || state.isFocused ? '#2d2b6f' : '#808080',
          padding: 10,
          fontWeight: 500,
          cursor: 'pointer',
        };
      },
      control: () => {
        return {
          borderWidth: 0,
          alignItems: 'center',
          borderRadius: 6,
          backgroundColor: '#f4f4f4',
          height: '30px',
          display: 'flex',
          color: '#2d2b6f',
          width: id === 2 ? '150px' : null,
          zIndex: 999,
          cursor: 'pointer',
        };
      },
      singleValue: (provided) => {
        return {
          ...provided,
          color: '#2d2b6f',
          fontWeight: 500,
          zIndex: 999,
        };
      },
      menu: (provided, state) => {
        return {
          position: 'absolute',
          width: id === 2 ? '150px' : '100%',
          maxWidth: '150px',
          color: state.selectProps.menuColor,
          backgroundColor: '#fff',
          height: open[id] === 1 ? state.options.length * 41 : '0px',
          boxShadow: '0px 0px 20px -5px rgba(0, 0, 0, 0.25)',
          borderRadius: 4,
          transition: 'all 0.8s ease-in-out',
          opacity: open[id] === 1 ? 1 : 0,
          visibility: open[id] === 1 ? 'visible' : 'hidden',
          zIndex: 9999,
          overflow: 'hidden',
        };
      },
      dropdownIndicator: () => {
        return {
          position: 'relative',
          right: '95%',
          animation:
            open[id] === 1
              ? 'bounty-table-rotation 0.8s'
              : open[id] === 0
              ? 'bounty-table-recover 0.8s'
              : null,

          transform: open[id] === 1 ? 'rotate(180deg)' : null,
          top: open[id] === 1 ? '3px' : null,
        };
      },
    };
  };

  const {
    getTableProps,

    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'col0', desc: false }] },
      autoResetSortBy: false,
    },

    useFilters,
    useSortBy,
    useRowState
  );

  let handleCnt = 0;
  const [touched, setTouched] = useState([0, 0, 0]);

  const filterComponent = useMemo(() => {
    return (
      <div
        style={{
          position: 'relative',
          cursor: 'pointer',
          maxWidth: '150px',
        }}
        onTouchStart={() => {
          handleCnt++;
          setOpen([open[0] === 1 ? 0 : 1, -1, -1]);
          setTouched([1, 0, 0]);
          filter.current.focus();
        }}
        onClick={() => {
          if (!touched[0]) {
            handleCnt++;
            setOpen([open[0] === 1 ? 0 : 1, -1, -1]);
            filter.current.focus();
          }
        }}
      >
        <Select
          ref={filter}
          styles={customStyles(0)}
          isSearchable={false}
          onBlur={() => {
            if (handleCnt > 0) {
              handleCnt = 0;
              return;
            }
            if (open[1] === 1 || open[2] === 1) {
              return;
            }
            if (open[0] !== 0) {
              setOpen([open[0] === 1 ? 0 : open[0], -1, -1]);
            }
          }}
          onChange={(e) => {
            onFiltering(e, headerGroups);
          }}
          options={options[0]}
          menuIsOpen
          defaultValue={{ value: 'all', label: 'All' }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          className="bounty-type-select-box"
        ></Select>
      </div>
    );
  }, [open]);

  const lastActiveComponent = useMemo(() => {
    return (
      <div
        style={{
          position: 'relative',
          cursor: 'pointer',
          maxWidth: '150px',
        }}
        className="sort_label"
        onTouchStart={() => {
          handleCnt++;
          setOpen([open[0] === 1 ? 0 : -1, open[1] === 1 ? 0 : 1, -1]);
          setTouched([0, 1, 0]);
          active.current.focus();
        }}
        onClick={() => {
          if (!touched[1]) {
            handleCnt++;
            setOpen([open[0] === 1 ? 0 : -1, open[1] === 1 ? 0 : 1, -1]);
            active.current.focus();
          }
        }}
      >
        <Select
          ref={active}
          styles={customStyles(1)}
          isSearchable={false}
          onBlur={() => {
            if (handleCnt > 0) {
              handleCnt = 0;
              return;
            }
            if (open[0] === 1 || open[2] === 1) {
              return;
            }
            if (open[1] !== 0) {
              setOpen([-1, open[1] === 1 ? 0 : open[1], -1]);
            }
          }}
          options={options[1]}
          menuIsOpen
          defaultValue={{ value: 'all', label: 'All' }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          className="bounty-type-select-box"
        ></Select>
      </div>
    );
  }, [open]);

  const sortComponent = useMemo(() => {
    return (
      <div
        style={{
          position: 'relative',
          cursor: 'pointer',
          maxWidth: '150px',
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
          className=""
        >
          Sort:
        </div>
        <div
          style={{ position: 'relative', marginRight: '900px' }}
          onTouchStart={() => {
            handleCnt++;
            setOpen([-1, -1, open[2] === 1 ? 0 : 1]);
            setTouched([0, 0, 1]);
            sort.current.focus();
          }}
          onClick={() => {
            if (!touched[2]) {
              handleCnt++;
              setOpen([-1, -1, open[2] === 1 ? 0 : 1]);
              sort.current.focus();
            }
          }}
        >
          <Select
            ref={sort}
            styles={customStyles(2)}
            isSearchable={false}
            onBlur={() => {
              if (handleCnt > 0) {
                handleCnt = 0;
                return;
              }
              if (open[2] !== 0) {
                setOpen([-1, -1, open[2] === 1 ? 0 : open[2]]);
              }
            }}
            onChange={(e) => onSorting(e, headerGroups[0].headers[0])}
            options={options[2]}
            menuIsOpen
            defaultValue={{ value: 'alphabetical', label: 'Alphabetical' }}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            className="bounty-type-select-box"
          ></Select>
        </div>
      </div>
    );
  }, [open]);

  useEffect(() => {
    onFiltering(filter.current.getValue()[0], headerGroups);
    if (!tdCell.current) {
      return;
    }
    setTdHeight(tdCell.current.clientHeight);
  }, [dataState, tdCell.current]);

  return (
    <Container className="company_table p-0 pt-3" fluid>
      <div className="bounty-table-container">
        <Row>
          <Col lg={8}>
            <Row className="text-nowrap">
              <Col className="sort_label">
                <div>{sortComponent}</div>
              </Col>
              <Col className="sort_label">
                <div className="">Filter:</div>
                {filterComponent}
              </Col>
              <Col className="sort_label">
                <div style={{ position: 'relative' }}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Input text "
                      aria-describedby="basic-addon1"
                      id="txt-search-phrase"
                    />
                    <Button
                      variant="outline-secondary"
                      id="btn-search"
                      onClick={(e) => {
                        e.preventDefault();
                        let txtsearch =
                          document.getElementById('txt-search-phrase');
                        if (txtsearch) {
                          getTableData(txtsearch.value);
                        }
                        e.stopPropagation();
                      }}
                    >
                      Search
                    </Button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Scrollbars
          className="gx-layout-sider-scrollbar"
          autoHeight
          autoHeightMax={'80vh'}
          autoHideDuration={1500}
          renderThumbVertical={() => <div style={{ display: 'none' }} />}
          renderThumbHorizontal={(props) => (
            <div {...props} className="bounty-table-thumb-horizontal" />
          )}
          style={{
            width: '100%',
            position: 'relative',
            height: 9000,
            marginRight: '17px',
            overflowY: 'hidden',
            overflowX: 'scroll',
            willChange: 'width',
          }}
        >
          <table
            className="pb-5"
            style={{
              borderCollapse: 'separate',
              borderSpacing: 0,
              width: '96%',
            }}
            {...getTableProps()}
          >
            <thead
              className="bg-white position-sticky top-0"
              style={{ zIndex: '999' }}
            >
              <tr {...headerGroups[0].getHeaderGroupProps()}>
                {headerGroups[0].headers.map((column, index) => {
                  return (
                    <th
                      key={index}
                      {...column.getHeaderProps()}
                      className="border-bottom border-dark bounty-table-th-middle pt-2 mt-2"
                      style={{
                        textAlign: 'center',
                        color: '#2d2b6f',
                        fontWeight: '500',
                        verticalAlign: 'bottom',
                        cursor: 'pointer',
                      }}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                      <span>
                        {column.Header === curCol ? (
                          counter === 1 ? (
                            <span className="sort">
                              <img src={SortDownSVG} alt="sort-down-icon" />
                            </span>
                          ) : counter === 2 ? (
                            <span className="sort">
                              <img src={SortUpSVG} alt="sort-up-icon" />
                            </span>
                          ) : (
                            <span className="sort">
                              <img src={UnselectedSVG} alt="unselected-icon" />
                            </span>
                          )
                        ) : (
                          <span className="sort">
                            <img src={UnselectedSVG} alt="unselected-icon" />
                          </span>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="position-relative">
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr key={index}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          style={{
                            textAlign: i === 0 ? '' : 'center',
                            fontWeight: '500',
                            verticalAlign: 'middle',
                            borderBottom: 'groove 2px #ececec',
                            padding: '1rem',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Scrollbars>
      </div>
    </Container>
  );
};

export default LogsViewer;
