import '../../shared/css/typography.css';
import '../css/candidateWaitingRoom.css';

export default function WaitingRoomWelcomeMessage({ isCandidate, company }) {
  return (
    <div>
      <h1 className="h1 candidate-waiting-room-title">
        {isCandidate ? `${company}'s interviewer` : `Your Candidate`} will be
        with you shortly!
      </h1>
    </div>
  );
}
