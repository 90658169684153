import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { Link } from '@reach/router';

import '../css/bountySummary.css';
import '../../shared/css/sharedStyles.css';
import { formatDate } from '../../utils/functions';
import * as BountyApi from '../../api/bounties';
import { formatGigOrJobSkills } from '../../shared/utils';

const BountySummary = ({ bounty }) => {
  const [summary, setSummary] = useState({
    pullRequest: 0,
    accepted: 0,
  });
  useEffect(() => {
    const getSummary = async () => {
      try {
        const response = await BountyApi.getBountySummary(bounty._id);
        setSummary(response);
      } catch (err) {
        //ignore
      }
    };
    getSummary();
  }, []);

  const bountySummaryAccepted = useMemo(
    () =>
      summary
        ? summary.accepted && summary.accepted
          ? summary.accepted + summary.completed
          : 0
        : 0,
    [summary]
  );
  const tooltip = () => {
    return (
      <Tooltip id="tooltip">
        {bounty.skillIds.map((skill) => (
          <strong key={skill.name}>{skill.name}</strong>
        ))}
      </Tooltip>
    );
  };
  return (
    <Container fluid className="bounty-summary-container">
      <Row>
        <Col xs={3} md={3}>
          <Col className="">
            <span className="bounty-summary-total-header">
              Total Candidates
            </span>
          </Col>
          <span className="bounty-summary-details-info">
            <span className="bounty-summary-total-data">
              {bounty?.assessmentIds?.length}
            </span>
          </span>
          <Col className="pt-3">
            <span className="bounty-summary-total-header">Accepted</span>
          </Col>
          <span className="bounty-summary-details-info">
            <span className="bounty-summary-accepted">
              {bountySummaryAccepted}
            </span>
          </span>
        </Col>

        <Col xs={3} md={9}>
          <Row>
            <Col xs={12} md={4} className="col-4">
              <Row className="bounty-summary-areas-float">
                <Col className="col-6">
                  <span className="bounty-summary-details">Skills:</span>
                </Col>
                <Row>
                  <Col xs={6}>
                    <OverlayTrigger placement="bottom" overlay={tooltip}>
                      <p>
                        {formatGigOrJobSkills({ skillIds: bounty.skillIds })}
                      </p>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Row>
            </Col>

            <Col xs={12} md={4} className="col-4">
              <Row className="bounty-summary-areas-float">
                <Col className="col-6">
                  <span className="bounty-summary-details">Date Created</span>
                  <span className="bounty-summary-details-info">
                    <span className="bounty-summary-details-info">
                      {formatDate(bounty.createdAt)}
                    </span>
                  </span>
                </Col>
                <Col className="col-4">
                  <span className="bounty-summary-details">
                    Team Preference:
                  </span>
                  <span className="bounty-summary-details-info">
                    <span className="bounty-summary-details-info">
                      {bounty.teamPreference ? 'Teams' : 'Individual'}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default BountySummary;
