import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../shared/css/sharedStyles.css';
import './css/initialSetup.css';
import { Link } from '@reach/router';

import Backbutton from '../shared/button/back';
import Nextbutton from '../shared/button/next';
import AddProjectWelcomeMessage from './components/addProjectWelcomeMessage';
import ProjectFields from '../projectModal/components/projectFields';

import Logo from '../assets/SnapBetaLogo2.svg';
import Illustration from '../assets/illustrations/snapbrillia_setup_2_illustration.png';
import AddProjectFormHeader from './components/addProjectFormHeader';
import { useState } from 'react';
import { useOnBoarding } from '../context/onboardingContext';

const SetUpAddProject = () => {
  const { createWorkSampleProject, url, repoInfo } = useOnBoarding();
  const [formValues, setFormValues] = useState({
    name: '',
    jobTitle: '',
    jobDescription: '',
    experience: '',
    mandatoryskillsTested: '',
    url: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorList = validateProjectFields(formValues);
    setFormErrors(errorList);
    if (Object.keys(errorList).length === 0) {
      createWorkSampleProject(formValues);
    }
    //navigate('/setup-candidate');
  };

  const validateProjectFields = (form) => {
    let errors = {};

    if (!form.name) {
      errors.name = 'Project Name is required';
    }
    if (!form.jobTitle) {
      errors.jobTitle = 'Job Title is required';
    }
    if (!form.experience) {
      errors.experience = 'Years of Experience is required';
    }
    if (!form.mandatoryskillsTested.length) {
      errors.mandatoryskillsTested =
        'You must select at least 1 skill for the candidate';
    }
    return errors;
  };

  return (
    <Container className="setup-team-main-container" fluid>
      <Row>
        <Col style={{ float: 'left', maxWidth: '193pt', maxHeight: '58pt' }}>
          <img src={Logo} alt="logo" />
        </Col>
      </Row>
      <Row className="setup-add-team-content">
        <Col xs={12} md={6} className="setup-add-project-left-col">
          <AddProjectWelcomeMessage />
          <div className="setup-add-team-illus">
            <img
              src={Illustration}
              alt="illustration"
              width={'80%'}
              height={'80%'}
            />
          </div>
        </Col>

        <Col xs={12} md={6} className="setup-add-project-right-col">
          <AddProjectFormHeader />

          <Row>
            <Col md={12}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <ProjectFields
                  setFormValues={setFormValues}
                  formValues={formValues}
                  formErrors={formErrors}
                  url={url}
                  repoInfo={repoInfo}
                />
                <Row>
                  <Col>
                    <div className="setup-add-project-button">
                      <span className="me-5">
                        <Link to="/setup-assessment">
                          <Backbutton />
                        </Link>
                      </span>
                      <Nextbutton type="submit" to="/setup-assessment" />
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default SetUpAddProject;
