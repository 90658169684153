import axios from 'axios';

export async function createDiscussion(data) {
  const response = await axios.post(`/api/discussion/create`, data);
  return response.data;
}

export async function getAllDiscussions() {
  const response = await axios.get(`/api/discussion/get-all`);
  return response.data;
}

export async function getDiscussionById(id) {
  const response = await axios.get(`/api/discussion/get-one-by-id/${id}`);
  return response.data;
}

export async function getAllDiscussionsByUser(userId) {
  const response = await axios.get(`/api/discussion/get-all-by-user/${userId}`);
  return response.data;
}

export async function updateDiscussionById(id, data) {
  const response = await axios.put(
    `/api/discussion/update-disccusion-by-id/${id}`,
    data
  );
  return response.data;
}

export async function getAllDiscussionByTopic(id) {
  const response = await axios.get(`/api/discussion/get-all-by-topic/${id}`);
  return response.data;
}

export async function getAllDiscussionsByCandidate(id) {
  const response = await axios.get(
    `/api/discussion/get-all-by-candidate/${id}`
  );
  return response.data;
}

export async function deleteDiscussionById(id) {
  const response = await axios.delete(
    `/api/discussion/delete-disccusion-by-id/${id}`
  );
  return response.data;
}

export async function getAllCommentByDiscussion(id) {
  const response = await axios.get(
    `/api/comment/get-all-comments-from-discussion/${id}`
  );
  return response.data;
}

export async function createComment(data) {
  const response = await axios.post(`/api/comment/create`, data);
  return response.data;
}
