/* eslint-disable no-unused-vars */
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect, createContext } from 'react';
import { Container } from 'react-bootstrap';
import * as bountyApi from '../../api/bounties';

import '../../shared/css/buttonStyles.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/typography.css';
import '../css/bountiesAddButtonModal.css';

import BountiesFormModal from './bountiesFormModal';
import BountiesTypeModal from './bountiesTypeModal';

import AddBountiesFormButton from '../../shared/button/addBountiesForm';
import BackButton from '../../shared/button/back';
import NextBountiesTypeButton from '../../shared/button/nextBountiesType';
export const dropdownContext = createContext();
export default function BountiesAddButtonModal({
  editBounty,
  page,
  setPage,
  showModal,
  setShowModal,
  addNewBounty,
  updateBounty,
}) {
  const [bountyField, setBountyField] = useState({
    // rewardAmount: '',
    permissionLess: '',
    teamPreference: '',
    timeEstimation: '',
    repoBranch: 'main',
  });

  const [disable, setDisable] = useState(true);
  const [addButton, setAddButton] = useState('Add Gig');
  const [hideBack, setHideBack] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [errors, setErrors] = useState({});

  // const { walletApiKey } = useWallet();

  function validate() {
    const errors = {};

    if (
      !bountyField.taskDescription ||
      bountyField.taskDescription.length < 1
    ) {
      errors['taskDescription'] = 'This field can not be empty!';
    } else {
      delete errors['taskDescription'];
    }
    if (
      !bountyField.submissionRequirements ||
      bountyField.submissionRequirements.length < 1
    ) {
      errors['submissionRequirements'] = 'This field can not be empty!';
    } else {
      delete errors['submissionRequirements'];
    }
    if (
      !bountyField.acceptenceCriteria ||
      bountyField.acceptenceCriteria.length < 1
    ) {
      errors['acceptenceCriteria'] = 'This field can not be empty!';
    } else {
      delete errors['acceptenceCriteria'];
    }
    if (!bountyField.timeEstimation || bountyField.timeEstimation.length < 1) {
      errors['timeEstimation'] = 'Required';
    } else {
      delete errors['timeEstimation'];
    }
    if (!bountyField.rewardAmount || bountyField.rewardAmount.length < 1) {
      errors['rewardAmount'] = 'Required';
    } else {
      delete errors['rewardAmount'];
    }
    if (!bountyField.teamPreference || bountyField.teamPreference.length < 1) {
      errors['teamPreference'] = 'Required';
    } else {
      delete errors['teamPreference'];
    }
    if (!bountyField.permissionLess || bountyField.permissionLess.length < 1) {
      errors['permissionLess'] = 'Required';
    } else {
      delete errors['permissionLess'];
    }
    if (
      !bountyField.languages ||
      Object.keys(bountyField.languages).length < 1
    ) {
      errors['languages'] = 'Required';
    } else {
      delete errors['languages'];
    }
    if (!bountyField.name || bountyField.name.length < 1) {
      errors['name'] = 'Required';
    } else {
      delete errors['name'];
    }
    return errors;
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const errors = validate();
      if (Object.keys(errors).length !== 0) {
        setErrors(errors);
        return;
      }
      if (bountyField._id) {
        await bountyApi.editBounty(bountyField);
        await updateBounty(bountyField);
      } else {
        const data = await bountyApi.createBounties(bountyField);
        await addNewBounty(data.bounty);
      }
      handleClose();
    } catch (error) {}
  };

  const handleChange = async (e) => {
    if (typeof e !== 'undefined' && typeof e !== 'string') {
      if (e.name) {
        await setBountyField({ ...bountyField, [e.name]: e.value });
      } else {
        await setBountyField({
          ...bountyField,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleNextChange = (value) => {
    setShowNext(value);
  };

  const handleNextDisable = (val) => {
    setDisable(val);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      return;
    }
    setErrors(validate());
  }, [bountyField]);

  useEffect(() => {
    if (editBounty) {
      setBountyField({
        ...bountyField,
        index: editBounty.index,
        _id: editBounty._id,
        type: editBounty.type,
        url: editBounty.url,
        acceptenceCriteria: editBounty.acceptenceCriteria,
        importantLink: editBounty.importantLink,
        name: editBounty.name,
        status: editBounty.status,
        officeHour: editBounty.officeHour,
        officeLink: editBounty.officeLink,
        rewardAmount: editBounty.rewardAmount,
        submissionRequirements: editBounty.submissionRequirements,
        taskDescription: editBounty.taskDescription,
        timeEstimation: editBounty.timeEstimation,
        teamPreference: editBounty.teamPreference,
        permissionLess: editBounty.permissionLess,
        languages: editBounty.languages,
      });
      setAddButton('Edit Gig');
      setHideBack(true);
    } else {
      setBountyField({ ...bountyField, type: 'public' });
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setPage(1);
  };

  const [dropdownValue, setDropdownValue] = useState('public');

  return (
    <div>
      <dropdownContext.Provider value={{ setDropdownValue }}>
        <div style={{ position: 'absolute' }}>
          <Modal
            onHide={handleClose}
            // backdrop="static"
            size="lg"
            centered
            show={showModal}
          >
            <Modal.Header className="no-border" closeButton />
            {page === 1 ? (
              <BountiesTypeModal
                setPage={setPage}
                handleChange={handleChange}
                bountyField={bountyField}
                setBountyField={setBountyField}
                handleNextDisable={handleNextDisable}
                handleNextChange={handleNextChange}
              />
            ) : (
              <BountiesFormModal
                setPage={setPage}
                handleChange={handleChange}
                bountyField={bountyField}
                setBountyField={setBountyField}
                errors={errors}
                handleSubmit={handleSubmit}
              />
            )}
            <Modal.Footer className="bounty-footer no-border">
              <Container>
                {page === 1 &&
                dropdownValue !== 'concierge' &&
                bountyField?.url ? (
                  <div className="button-margin">
                    <NextBountiesTypeButton
                      style={{ float: 'right' }}
                      onClick={() => setPage(2)}
                    />
                  </div>
                ) : page === 2 ? (
                  <>
                    <BackButton onClick={() => setPage(1)} hidden={hideBack} />
                    <span style={{ float: 'right' }}>
                      {hideBack ? (
                        <AddBountiesFormButton
                          text={'Update Gig'}
                          handleClick={handleSubmit}
                        />
                      ) : (
                        <AddBountiesFormButton
                          text={'Add Gig'}
                          handleClick={handleSubmit}
                        />
                      )}
                    </span>
                  </>
                ) : null}
              </Container>
            </Modal.Footer>
          </Modal>
        </div>
      </dropdownContext.Provider>
    </div>
  );
}
