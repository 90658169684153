import '../css/sharedStyles.css';
import { Link } from '@reach/router';

export default function CopyRight() {
  return (
    <div className="copyright ">
      <p>Copyright</p>
      <p className="icon">&nbsp;</p>
      <p>Snapbrillia 2023&nbsp;</p>

      <Link to="/policy">Use Policies</Link>
      <Link to="/policy#SAAS-MSA">SAAS MSA</Link>
    </div>
  );
}
