import React from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../shared/css/typography.css';
import '../../shared/css/buttonStyles.css';
import '../css/cart.css';
import {
  Elements,
  PaymentElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function Cart({
  cart,
  changeCartType,
  clientSecret,
  setElements,
  setLoading,
  setStripe,
  extraAmount,
  setExtraAmount,
}) {
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Container fluid>
        <Modal.Header className="no-border" closeButton>
          <span className="h1">Adding Funds</span>
        </Modal.Header>
        <Modal.Body className="scrollable-cart">
          {cart.map((e, index) => {
            return (
              <Row className="row-border mb-4" key={index}>
                <Col md={7} sm={12} xs={12}>
                  <Row>
                    <Col md={'auto'} xs={'auto'}>
                      <img src={e.logo} className="small-cart-img" />
                    </Col>
                    <Col md={8} xs={8}>
                      <Row>
                        <span className="item-title">{e.name}</span>
                      </Row>
                      <Row>
                        <span className="grey-labels underline">
                          {e.creator}
                        </span>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={5} xs={9}>
                  <Row>
                    <a
                      className="wallet-dropdown-text pointer"
                      onClick={changeCartType}
                    >
                      Select different options
                    </a>
                  </Row>
                  <Row>
                    <label className="form_label label_fields">Amount</label>
                  </Row>
                  <Row>
                    <input
                      value={extraAmount}
                      className="form_input input_fields mb-2"
                      type="number"
                      min="0"
                      id={'input'}
                      onChange={(e) => setExtraAmount(e.target.value)}
                    />
                  </Row>
                </Col>
                <Col className="text-end">
                  Fee (12.9% + 0.3): $ {(extraAmount * 0.129 + 0.3).toFixed(3)}{' '}
                  <br />
                  Total: ${(extraAmount * 1.129 + 0.3).toFixed(3)}
                </Col>
              </Row>
            );
          })}
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => {
                  setElements(elements);
                  setStripe(stripe);
                  return (
                    <form id="payment-form">
                      <PaymentElement
                        id="payment-element"
                        onReady={() => {
                          setLoading(false);
                        }}
                      />
                    </form>
                  );
                }}
              </ElementsConsumer>
            </Elements>
          )}
        </Modal.Body>
      </Container>
    </>
  );
}
