/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
// import { Link } from '@reach/router';
import '../../shared/css/typography.css';
import '../../shared/css/buttonStyles.css';
import Cart from './connectCart';
import Success from './connectSuccess';
import '../css/cartModal.css';
import { toast } from 'react-toastify';
// import {
//   Elements,
//   PaymentElement,
//   ElementsConsumer,
// } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../../context/authContext';

import {
  createStripeConnectIntents,
  checkStripeIntents,
} from '../../api/sessions';
import {
  createStripeIntents,
  confirmStripeIntentsTopUp,
} from '../../api/opportunities';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function CartModal({
  bounty,
  setCartModal,
  reloadTable,
  changeCartType,
}) {
  const [intent, setIntent] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [payment, setPayment] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [elements, setElements] = useState();
  const [stripe, setStripe] = useState();
  const { auth } = useAuth();
  const [extraAmount, setExtraAmount] = useState(0);

  const handleClose = () => {
    setShowModal(false);
    setCartModal(false);
    setPage(1);
  };

  const charge = async () => {
    if (!extraAmount) {
      return toast('Please set extra funds');
    }
    setLoading(true);
    const data = await createStripeIntents(bounty._id, {
      account: payment,
      amount: extraAmount,
    });
    setClientSecret(data.clientSecret);
    setIntent(data.paymentIntentId);
  };

  // const appearance = {
  //   theme: 'stripe',
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  const confirmPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_URL + '/bounties',
        payment_method_data: {
          billing_details: {
            email: auth.user.email,
            name: auth.user.fullName,
          },
        },
      },
      redirect: 'if_required',
    });
    if (result.error) {
      toast(result.error.message);
      setLoading(false);
    } else {
      await confirmStripeIntentsTopUp(intent);
      setLoading(false);
      setPage(2);
      reloadTable();
    }
  };
  return (
    <div style={{ position: 'absolute' }}>
      <Modal
        onHide={handleClose}
        backdrop="static"
        show={showModal}
        centered
        contentClassName="modal-radius"
        size="lg"
        dialogClassName={'small-cart-bg'}
      >
        {page === 1 && (
          <>
            <Cart
              cart={[bounty]}
              setPayment={setPayment}
              changeCartType={changeCartType}
              clientSecret={clientSecret}
              setLoading={setLoading}
              setElements={setElements}
              setStripe={setStripe}
              extraAmount={extraAmount}
              setExtraAmount={setExtraAmount}
            />
            <Container>
              <Row className="footer-padding small-cart-footer-bg">
                <Col className="text-end">
                  {loading && <span>...Loading</span>}
                  {!loading && !clientSecret && (
                    <button className="btn-primary" onClick={charge}>
                      Purchase
                    </button>
                  )}
                  {!loading && clientSecret && (
                    <button className="btn-primary" onClick={confirmPayment}>
                      Confirm
                    </button>
                  )}
                </Col>
              </Row>
            </Container>
          </>
        )}
        {page === 2 && <Success cart={[bounty]} extraAmount={extraAmount} />}
      </Modal>
    </div>
  );
}
