import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AddMemberFields from './components/addMemberFields';
import Save from '../shared/button/save';
import '../shared/css/typography.css';
import './css/teamMemberModal.css';
import '../shared/css/buttonStyles.css';
import CloseIcon from '../assets/icon/snapbrillia_close_icon.svg';
import { toast } from 'react-toastify';
import { MemberModal } from './components/memberModalAPI';
import RemoveTaskButton from '../shared/button/removeTask';
import Deactivate from '../shared/button/deactivate';
import Activate from '../shared/button/activate';

const EditMemberModal = ({ show, setShow }) => {
  const {
    handleRemoveMember,
    setMemberFields,
    editMember,
    getMember,
    formErrors,
    submitForm,
    handleFormSubmit,
    memberFields,
    member,
    handleActiveMember,
    handleDeactiveMember,
  } = useContext(MemberModal);
  const [isChange, setIsChange] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    getMember();
  }, []);
  const removeMember = () => {
    handleRemoveMember(member);
  };
  const activate = () => {
    const toastId = toast.loading('Activating...');
    handleActiveMember(member);
    toast('Activate User Successful');
    toast.dismiss(toastId);
  };
  const deactivate = () => {
    const toastId = toast.loading('Deactivating...');
    handleDeactiveMember(member);
    toast.dismiss(toastId);
    toast('Deactivate User Successful');
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitForm) {
      editMember();
    }
  }, [formErrors]);
  return (
    <Modal
      contentClassName="modal-main-container"
      onHide={handleClose}
      backdrop="static"
      show={true}
      centered
    >
      <Container>
        <Row>
          <Row className="p-0">
            <Col>
              <Modal.Header className="member-modal-header">
                <h2 className="h2">Edit Team Member</h2>
              </Modal.Header>
            </Col>
            <Col
              xs={1}
              className="pe-0 d-flex align-items-center justify-content-center"
            >
              <img
                src={CloseIcon}
                alt="close-icon"
                width={20}
                onClick={() => handleClose()}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          </Row>
          <form onSubmit={handleFormSubmit} className="pt-0 pb-0">
            <AddMemberFields
              {...{
                setMemberFields,
                memberFields,
                formErrors,
                isChange,
                setIsChange,
              }}
            />

            <Row>
              <Col>
                <div className="member-modal-button">
                  <Save />
                </div>
                <div
                  className="member-modal-button"
                  onClick={async () => removeMember()}
                >
                  <RemoveTaskButton />
                </div>
                {(member.status === 'frozen' && (
                  <div
                    className="member-modal-button"
                    onClick={async () => {
                      activate(member._id);
                    }}
                  >
                    <Activate />
                  </div>
                )) || (
                  <div
                    className="member-modal-button"
                    onClick={async () => {
                      deactivate(member._id);
                    }}
                  >
                    <Deactivate />
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </Row>
      </Container>
    </Modal>
  );
};

export default EditMemberModal;
