import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { toast } from 'react-toastify';
import {
  activeAndDeactivate,
  getOpportunitiesAdmin,
} from '../../api/opportunities';
import { useParams, Link, navigate } from '@reach/router';
import { Box } from '@mui/material';
import { Button, Col, Row } from 'react-bootstrap';
import { compensation } from '../../shared/utils';
import InvoiceBountyModal from '../../shared/modals/InvoiceBountyModal';
import AddBountyButton from '../../shared/button/addBounty';

const InvoicedBountiesView = () => {
  const [bounties, setBounties] = useState([]);
  const [editBounty, setEditBounty] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const companyId = useParams()?.companyId;

  const closeModal = () => {
    setShowModal(false);
  };

  const closeJobModal = () => {
    setShowJobModal(false);
  };

  function capitolize(string = '') {
    const str1 = string.slice(1);
    const capitolized = string.charAt(0).toUpperCase() + str1;
    return capitolized;
  }

  const getBountiesData = async (filter, nextPage = 1) => {
    setLoading(true);
    let newPage = nextPage > page ? nextPage : page;
    await getOpportunitiesAdmin(companyId, {
      page: newPage,
      limit: limit,
      filter: filter,
    })
      .then((response) => {
        setBounties(response.docs);
      })
      .catch((error) => {
        setBounties([]);
      });
    setLoading(false);
  };

  const activate = async (id, data) => {
    const loading = toast.loading('Activating...');
    activeAndDeactivate(id, data);
    toast.dismiss(loading);
    toast('Activated Opportunity Successful');
    getBountiesData();
  };

  const deactivate = async (id, data) => {
    const loading = toast.loading('Deactivating...');
    activeAndDeactivate(id, data);
    toast.dismiss(loading);
    toast('Deactivated Opportunity Successful');
    getBountiesData();
  };

  useEffect(() => {
    getBountiesData();
  }, []);
  //should be memoized or stable
  // var startTime = performance.now();
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => (
          <div className="pointer">
            {row.name || row.title}
            <div className="positions">Public</div>
            <div className="bounty_open">
              <span style={{ fontSize: '13px' }}>•</span>
              <span>{row.type}</span>
            </div>
            {row.funded && row.status !== 'pending' && (
              <div className="bounty_open">
                <span style={{ fontSize: '13px' }}>•</span>
                <span>{capitolize(row.status)}</span>
              </div>
            )}
            {!row.funded && row.status !== 'pending' && (
              <div className="bounty_closed">
                <span style={{ fontSize: '13px' }}>•</span>
                <span>{'Not Funded'}</span>
              </div>
            )}
            {row.status === 'pending' && (
              <div className="bounty_closed">
                <span style={{ fontSize: '13px' }}>•</span>
                <span>{'Wait for confirmations'}</span>
              </div>
            )}
          </div>
        ), //access nested data with dot notation
        header: 'Title',
        size: 150,
      },
      {
        accessorFn: (row) => row?.type,
        header: 'Type',
        size: 50,
      },
      {
        accessorFn: (row) => row?.companyId?.companyName,
        header: 'Company',
        size: 50,
      },
      // {
      //   accessorFn: (row) => row?.createdBy?.fullName, //normal accessorKey
      //   header: 'Creator Name',
      //   size: 100,
      // },
      {
        accessorFn: (row) => row?.workplaceType,
        header: 'Workplace Type',
        size: 50,
      },
      {
        accessorFn: (row) =>
          new Date(row.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        header: 'Date',
        size: 100,
      },
      {
        accessorFn: (row) =>
          row?.maxCompensation && row?.maxCompensation > 0
            ? row?.minCompensation +
              ' - ' +
              row?.maxCompensation +
              ' ' +
              row?.rewardType +
              ' ' +
              compensation(row)
            : (row?.rewardAmount || row?.minCompensation) +
              ' ' +
              row?.rewardType +
              ' ' +
              compensation(row),
        header: 'Compensation',
        size: 150,
      },
    ],
    []
  );
  // var endTime = performance.now();
  // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`);

  return (
    <>
      <Col
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '4%',
        }}
      >
        <div onClick={() => setShowModal(true)} className="add-bounty-shrink">
          <AddBountyButton title={'Add Opportunity'} />
        </div>
      </Col>
      <MaterialReactTable
        columns={columns}
        data={bounties}
        state={{
          isLoading,
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            borderRadius: '0',
            border: '1px dashed #fff',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 200, //set custom width
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
          },
        }}
        renderDetailPanel={({ row }) => (
          <Row>
            <Col>
              <h5>Description</h5>
              <div>{row.original.taskDescription}</div>
            </Col>
            <Col>
              <h5>Skills Required</h5>
              <ul>
                {row?.original?.skillIds?.map((item) => (
                  <div key={item?.name}>
                    <li>{item?.name}</li>
                  </div>
                ))}
              </ul>
            </Col>
          </Row>
        )}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            {row.original.funded === true ? (
              <Button
                onClick={async () => {
                  deactivate(row.original._id, { funded: false });
                }}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  activate(row.original._id, { funded: true });
                }}
              >
                Activate
              </Button>
            )}
          </Box>
        )}
      />
      {showModal && (
        <div>
          <InvoiceBountyModal
            show={InvoiceBountyModal}
            gig={editBounty}
            closeModal={() => {
              getBountiesData();
              closeModal();
            }}
          />
        </div>
      )}
    </>
  );
};

export default InvoicedBountiesView;
