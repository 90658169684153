import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../shared/css/typography.css';

import AllPieChart from '../../charts/allPieChart';

const Analytics = ({ bountyId }) => {
  return (
    <Container>
      <Row>
        <Row>
          <Col style={{ marginBottom: '20px' }}>
            <h2
              style={{
                fontSize: '18px',
                fontFamily: 'Roboto',
                color: '#2D2B6F',
              }}
            >
              Analytics
            </h2>
          </Col>
        </Row>
        <Col style={{ margin: '0px', padding: '0px' }}>
          <AllPieChart
            width={415}
            height={365}
            bountyId={bountyId}
            dataType="ethnicity"
            status="all"
            InnerRadius={0}
            alignLegend="left"
            titlePosition="bottom"
          />
        </Col>
        <Col style={{ margin: '0px', padding: '0px' }}>
          <AllPieChart
            width={415}
            height={365}
            bountyId={bountyId}
            dataType="gender"
            status="all"
            InnerRadius={0}
            alignLegend="right"
            titlePosition="bottom"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Analytics;
