export const SUPPORT_LANGUAGES = [
  'JavaScript',
  'Haskell',
  'C++',
  'C',
  'Python',
  'Java',
  'TypeScript',
  'HTML',
  'C#',
  'SQL',
  'Rust',
  'Go',
  'Lua',
  'PHP',
  'Haskell',
  'React',
  'Angular',
  'Ruby',
  'R',
  'Shell',
  'Scala',
  'Nix',
  'Swift',
  'Elixir',
  'Clojure',
  'Perl',
  'Kotlin',
  'Dart',
  'Erlang',
  'Julia',
  'Visual Basic .NET',
  'Assembly',
  'WebAssembly',
  'Fortran',
  'Objective-C',
  'Groovy',
  'SystemVerilog',
  'DM',
  'Emacs Lisp',
  'CodeQL',
  'PowerShell',
  'F#',
];

export const SUPPORT_FIELDS = [
  'Software Developer',
  'Mobile Applications Developer',
  'Network Engineer',
  'Systems Administrator',
  'Data science',
  'Network administrator',
  'Database Administrator',
  'Computer security',
  'Web Developer',
  'Product Manager',
  'Computer Engineer',
  'Architect Developer',
  'Quality Assurance',
  'Cloud computing',
  'Computer programmer',
  'User experience designer',
  'Web administrator',
  'Web Designer',
  'IT manager',
  'Computer network architect',
  'DevOps engineer',
  'Artificial intelligence (AI) Engineer',
  'Tester',
];
