import { useState } from 'react';
import { fetchOnChainResume } from '../controller/onChainResumeController';

const OnChainResumeViewModel = () => {
  const [loaded, setLoaded] = useState(false);
  const [resume, setResume] = useState(null);
  const [error, setError] = useState(null);

  function getOnChainResume(candidate_id) {
    fetchOnChainResume(candidate_id)
      .then((data) => {
        setResume(data);
        setLoaded(true);
      })
      .catch((error) => {
        setError(error);
        setLoaded(false);
      });
  }

  return {
    loaded,
    resume,
    error,
    getOnChainResume,
  };
};
export default OnChainResumeViewModel;
