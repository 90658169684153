import { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import {
  getRepositories,
  getRepository,
  getBranches,
  getOrganizations,
  createRepository,
} from '../api/githubApp';

const useGithubApp = () => {
  const { auth, reloadUserInfo } = useAuth();
  const [repos, setRepos] = useState([]);
  const [orgs, setOrgs] = useState([
    {
      id: '',
      login: 'Personal',
    },
  ]);
  const [branches, setBranches] = useState([]);
  const [url, setUrl] = useState('');
  const [org, setOrg] = useState({
    id: '',
    login: 'Personal',
  });
  const [externalPopup, setExternalPopup] = useState(null);
  let loading = false;

  // const githubAppLoginUrl = `https://githubapp.snapbrillia.com/github/login?userId=${auth.user._id}`;
  const githubAppUrl = `https://github.com/apps/snapbrillia-github-app`;
  let linkGithubUrl = `https://${
    process.env.REACT_APP_STYTCH_PROJECT_ENV === 'live' ? 'api' : 'test'
  }.stytch.com/v1/public/oauth/github/start?public_token=${
    process.env.REACT_APP_STYTCH_PUBLIC_TOKEN
  }`;
  linkGithubUrl +=
    '&login_redirect_url=' +
    encodeURIComponent(process.env.REACT_APP_URL + '/link-account');
  linkGithubUrl +=
    '&signup_redirect_url=' +
    encodeURIComponent(process.env.REACT_APP_URL + '/link-account');
  linkGithubUrl += '&custom_scopes=' + encodeURIComponent('user:email repo');

  const loadRepositories = async () => {
    loading = true;
    const { docs } = await getRepositories(null, org?.id);
    setRepos(docs);
    loading = false;
  };

  const loadRepository = async () => {
    loading = true;
    const data = await getRepository(url);
    loading = false;
    return data;
  };

  const createRepo = async (name) => {
    loading = true;
    const data = await createRepository(name);
    loading = false;
    let repo = data.data;
    repo.github_url = repo.html_url;
    let newRepos = [repo, ...repos];
    setRepos(newRepos);
    return repo;
  };

  const loadOrganizations = async () => {
    try {
      const docs = await getOrganizations();
      setOrgs([
        {
          id: '',
          login: 'Personal',
        },
        ...(docs || []),
      ]);
    } catch (err) {
      setOrgs([
        {
          id: '',
          login: 'Personal',
        },
      ]);
    }
  };

  const loadBranches = async () => {
    const data = await getBranches(url);
    setBranches(data);
  };

  const openPopup = () => {
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const newPopup = window.open(
      linkGithubUrl,
      '',
      `width=${width}, height=${height}, top=${top}, left=${left}`
    );
    newPopup.onunload = async () => {
      await loadRepositories();
    };
    setExternalPopup(newPopup);
  };
  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(async () => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        if (currentUrl.indexOf('https://github.com/') > -1) {
          setExternalPopup(null);
          reloadUserInfo();
          timer && clearInterval(timer);
        }
      } catch (err) {
        setExternalPopup(null);
        timer && clearInterval(timer);
        reloadUserInfo();
      }
    }, 500);
  }, [externalPopup]);

  useEffect(() => {
    if (auth.user.githubId && !loading) {
      loadRepositories();
      loadOrganizations();
    }
  }, [auth]);

  useEffect(() => {
    if (url) {
      loadBranches();
    }
  }, [url]);

  useEffect(() => {
    if (url) {
      loadRepositories();
    }
  }, [org]);

  return {
    githubAppUrl,
    linkGithubUrl,
    openPopup,
    repos,
    org,
    orgs,
    setOrg,
    branches,
    setUrl,
    loadRepository,
    loadRepositories,
    createRepo,
  };
};

export default useGithubApp;
