import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { eeoFields } from './eeoField';
import { colors } from './colors';

export function EEOBarChart({ chartData, pieChartData }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const eeoData = eeoFields?.filter(
    (value) => value.name === pieChartData.category
  );
  const eeoLabel = eeoData[0]?.options?.map((values) => values.value);

  const selectedData = chartData?.counts?.filter(
    (value) => value.label === pieChartData.category
  );

  const dataSet = [];
  selectedData?.map((value) => {
    for (let index = 0; index < eeoLabel.length; index++) {
      for (const key in value.values) {
        if (key === eeoLabel[index]) {
          dataSet.push(value.values[key]);
        } else {
          dataSet.push(0);
        }
      }
    }
  });
  // console.log(dataSet);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Analytics',
      },
    },
  };

  const data = {
    labels: eeoLabel,
    datasets: [
      {
        label: '# participated candidate',
        data: dataSet,
        backgroundColor:
          pieChartData.category == 'gender'
            ? colors.gender
            : pieChartData.category == 'ethnicity'
            ? colors.ethnicity
            : pieChartData.category == 'age'
            ? colors.age
            : pieChartData.category == 'veteranStatus'
            ? colors.veteranStatus
            : pieChartData.category == 'impairmentStatus'
            ? colors.impairmentStatus
            : pieChartData.category == ' neurodivergence'
            ? colors.neurodivergence
            : colors.degree,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
