import Modal from 'react-bootstrap/Modal';
import '../css/sharedStyles.css';
import BountyCreateSteps from '../../bountyCreateSteps/bountyCreateSteps';
import { useState } from 'react';

// editStep is used for editing only the unfunded gigs
// editStep !important

export default function BountyCreateStepsModal({
  show,
  closeModal,
  gig,
  editSteps = 1,
  cryptoPay,
  setCryptoPay,
}) {
  const [step, setStep] = useState(1);
  return (
    <Modal show={show} onHide={closeModal} size="xl" centered>
      <Modal.Header
        className="modal-header-removal-hr"
        closeButton
      ></Modal.Header>
      <Modal.Body>
        <BountyCreateSteps
          closeModal={closeModal}
          gig={gig}
          step={step}
          setStep={setStep}
          editSteps={editSteps}
          cryptoPay={cryptoPay}
          setCryptoPay={setCryptoPay}
        />
      </Modal.Body>
    </Modal>
  );
}
