// Taken from old client side code
// Reduce function sets true || false values for each diversity category, and
// assessment status. Then adds those as key : value pairs to the currentCandidateObject.
function reduceFunction(
  arrayOfAnalyticOptions,
  arrayOfValuesSetToTrue,
  diversityAnalytic,
  currentCandidateObject
) {
  // We reduce the arrayOfAnalyticOptions of options for the diversity analytic.
  arrayOfAnalyticOptions.reduce((acc, current) => {
    if (diversityAnalytic === 'gender') {
      if (arrayOfValuesSetToTrue.length > 1) {
        currentCandidateObject['gender-fluid'] = true;
        currentCandidateObject['male'] = false;
        currentCandidateObject['female'] = false;
        currentCandidateObject['non-binary'] = false;
        currentCandidateObject['another-gender'] = false;
        currentCandidateObject['dnd'] = false;
      } else {
        currentCandidateObject['gender-fluid'] = false;
      }
    }

    if (diversityAnalytic === 'ethnicity') {
      if (arrayOfValuesSetToTrue.length > 1) {
        currentCandidateObject['multi-racial'] = true;
        currentCandidateObject['asian'] = false;
        currentCandidateObject['black'] = false;
        currentCandidateObject['hispanic-or-latinx'] = false;
        currentCandidateObject['pacific-islander'] = false;
        currentCandidateObject['white-european'] = false;
        currentCandidateObject['ethnicityUnknown'] = false;
        currentCandidateObject['native-american'] = false;
      } else {
        currentCandidateObject['multi-racial'] = false;
      }
    }

    if (current === 'dnd') {
      if (
        arrayOfValuesSetToTrue.includes('dnd') ||
        arrayOfValuesSetToTrue.includes('')
      ) {
        currentCandidateObject[diversityAnalytic + 'Unknown'] = true;
      } else {
        currentCandidateObject[diversityAnalytic + 'Unknown'] = false;
      }
    } else if (arrayOfValuesSetToTrue.includes(current)) {
      currentCandidateObject[current] = true;
    } else {
      currentCandidateObject[current] = false;
    }
  }, []);
  return currentCandidateObject;
}

// function that manipulates our candidate objects and returns them into an array.
const getCandidates = (data) => {
  // We will return this array full of candidate objects
  const allCandidatesArray = [];
  // We map through the api data and go through changing each candidate object
  data.map((candidate) => {
    // This will be the first instance of the candidate object.
    let emptyCandidateContainer = {};

    //What is the current candidate's status?
    let statusCandidate = [candidate?.assessment?.status];

    // What are all the options for the assessment status?
    const statusArray = [
      'new',
      'invited',
      'accepted',
      'viewed',
      'in-progress',
      'completed',
      'reviewed',
    ];

    // Now the containerAfterStatus is the candidate object
    const containerAfterStatus = reduceFunction(
      statusArray,
      statusCandidate,
      null,
      emptyCandidateContainer
    );
    // Each diversity analytic needs to have a case for
    // unknown analytics in candidate = { ...ethnicityUnknown : true, }

    // variable to pass the analytic category for categoryUnknown in the candidate object.
    let ageAnalytic = 'age';

    // All diversity analytic options
    const ageOptions = ['<18', '18-39', '40-54', '55<', 'dnd'];

    // Empty array to hold the current candidate's diversity options for this category.
    const ageValue = [];

    // Push each analytic in this category to the array
    for (let i = 0; i < candidate.age.length; i++) {
      ageValue.push(candidate.age[i]);
    }

    // Candidate object is now containerAfterAge
    const containerAfterAge = reduceFunction(
      ageOptions,
      ageValue,
      ageAnalytic,
      containerAfterStatus
    );
    // Same process for ethnicity.
    let ethnicityAnalytic = 'ethnicity';
    const ethnicityOptions = [
      'native-american',
      'asian',
      'black',
      'hispanic-or-latinx',
      'pacific-islander',
      'white-european',
      'dnd',
    ];
    const ethnicityValue = [];
    for (let i = 0; i < candidate.ethnicity.length; i++) {
      ethnicityValue.push(candidate.ethnicity[i]);
    }
    const containerAfterEthnicity = reduceFunction(
      ethnicityOptions,
      ethnicityValue,
      ethnicityAnalytic,
      containerAfterAge
    );

    // Same process for gender.
    let genderAnalytic = 'gender';
    const genderOptions = [
      'female',
      'male',
      'non-binary',
      'gender-fluid',
      'another-gender',
      'dnd',
    ];
    const genderValue = [];
    for (let i = 0; i < candidate.gender.length; i++) {
      genderValue.push(candidate.gender[i]);
    }
    // Candidate object is now containerAfterGender
    const containerAfterGender = reduceFunction(
      genderOptions,
      genderValue,
      genderAnalytic,
      containerAfterEthnicity
    );

    // Same process for veteranStatus
    let veteranAnalytic = 'veteranStatus';
    const veteranStatusOptions = ['veteran', 'non-veteran', 'dnd'];
    const veteranStatusValue = [];
    for (let i = 0; i < candidate.veteranStatus.length; i++) {
      veteranStatusValue.push(candidate.veteranStatus[i]);
    }
    // Candidate object is now containerAfterVeteranStatus
    const containerAfterVeteranStatus = reduceFunction(
      veteranStatusOptions,
      veteranStatusValue,
      veteranAnalytic,
      containerAfterGender
    );

    // New diversity analytic
    let impairmentAnalytic = 'impairmentStatus';
    const impairmentStatusOptions = ['impaired', 'non-impaired', 'dnd'];
    const impairmentStatusValue = [];
    for (let i = 0; i < candidate.impairmentStatus.length; i++) {
      impairmentStatusValue.push(candidate.impairmentStatus[i]);
    }
    // Candidate object is now containerAfterImpairmentStatus
    const containerAfterImpairmentStatus = reduceFunction(
      impairmentStatusOptions,
      impairmentStatusValue,
      impairmentAnalytic,
      containerAfterVeteranStatus
    );

    // New diversity analytic
    let degreeAnalytic = 'degree';
    const degreeOptions = [
      'high-school',
      'trade-certification',
      'bootcamp',
      'associate',
      'bachelor',
      'master',
      'phd',
      'dnd',
    ];
    const degreeValue = [];
    if (candidate.degree !== undefined) {
      for (let i = 0; i < candidate.degree.length; i++) {
        degreeValue.push(candidate.degree[i]);
      }
    }
    // Candidate object is now containerAfterDegree
    const containerAfterDegree = reduceFunction(
      degreeOptions,
      degreeValue,
      degreeAnalytic,
      containerAfterImpairmentStatus
    );

    // New diversity analytic
    let neuroAnalytic = 'neurodivergence';
    const neurodivergenceOptions = ['divergent', 'nonDivergent', 'dnd'];
    const neurodivergenceValue = [];
    for (let i = 0; i < candidate.neurodivergence.length; i++) {
      neurodivergenceValue.push(candidate.neurodivergence[i]);
    }
    // Candidate object is now containerAfterNeurodivergence
    const containerAfterNeurodivergence = reduceFunction(
      neurodivergenceOptions,
      neurodivergenceValue,
      neuroAnalytic,
      containerAfterDegree
    );

    // After last reduce function executed (map method finished,but still inside)
    // the candidate object has been completed. We then push it to the allCandidatesArray.
    allCandidatesArray.push(containerAfterNeurodivergence);

    // Then reset the currentCandidateObject to an empty object
    // for the next candidate in the map method.
    emptyCandidateContainer = {};
  });

  // return the allCandidatesArray
  return allCandidatesArray;
};

// Create two separate functions that will return true or false,
// One function for assessment status and one for diversityCategory.

//Function for assessment status. If true will push candidate to array
function isAssessmentStatus(theStatusString, candidate) {
  switch (theStatusString) {
    case 'new':
      return !!candidate.new;
    case 'in-progress':
      return !!candidate.inProgress;
    case 'completed':
      return !!candidate.completed;
    case 'reviewed':
      return !!candidate.reviewed;
    case 'invited':
      return !!candidate.invited;
    case 'accepted':
      return !!candidate.accepted;
    case 'viewed':
      return !!candidate.viewed;
    default:
      return false;
  }
}

// Function for diversityCategory if true will push candidate to array
function isDiversityCategory(theDiversityCategoryArray, candidate) {
  let isCategory = false;

  for (let i = 0; i < theDiversityCategoryArray.length; i++) {
    if (candidate[theDiversityCategoryArray[i]] === true) {
      isCategory = true;
      break;
    }
  }
  return isCategory;
}

// async function exported for the creation of any candidate list necessary
export const anyCandidateList = (
  candidateDataUnfiltered,
  assessmentStatus,
  diversityCategory
) => {
  //empty array to return the candidate objects
  let returnedCandidateList = [];
  try {
    //call the getCandidates function.
    const candidates = getCandidates(candidateDataUnfiltered);

    // If we are looking for both assessment status and diversity analytics:
    if (assessmentStatus && diversityCategory) {
      //check each candidate to see if they match both the assessment status and diversity analytics
      candidates.map((candidate) => {
        if (
          isAssessmentStatus(assessmentStatus, candidate) &&
          isDiversityCategory(diversityCategory, candidate)
        ) {
          // if both return true, push the candidate to the array
          returnedCandidateList.push(candidate);
        }
      });
    }
    // If we are looking for only assessment status:
    else if (assessmentStatus && !diversityCategory) {
      candidates.map((candidate) => {
        if (isAssessmentStatus(assessmentStatus, candidate)) {
          returnedCandidateList.push(candidate);
        }
      });
    }
    // If we are looking for only diversity analytics:
    else if (!assessmentStatus && diversityCategory) {
      candidates.map((candidate) => {
        if (isDiversityCategory(diversityCategory, candidate)) {
          returnedCandidateList.push(candidate);
        }
      });
    } else {
      // If we are not looking for either assessment status or diversity analytics,
      return 'Not enough information to filter candidates';
    }
    return returnedCandidateList;
  } catch (error) {
    console.log(error);
  }
};
