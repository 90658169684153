import React, { useState, useEffect } from 'react';

import './style.css';

import StepOne from './StepOne';
import { StepTwo } from './StepTwo';

export const Stepper = ({
  closeOrNext,
  closeModal,
  step,
  nextStep,
  type,
  cryptoPay,
}) => {
  return (
    <>
      {step === 1 && (
        <StepOne type={type} closeModal={closeModal} nextStep={nextStep} />
      )}
      {step === 2 && (
        <StepTwo
          type={type}
          closeOrNext={closeOrNext}
          closeModal={closeModal}
          nextStep={nextStep}
          cryptoPay={cryptoPay}
        />
      )}
    </>
  );
};
