import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/userProfile.css';
import '../../shared/css/typography.css';
import '../../shared/css/textColors.css';
import '../../shared/css/bgColors.css';
// import SelectMenu from '../../shared/formItems/selectMenu';
import SnapbrilliaCloseIcon from '../../assets/icon/snapbrillia_close_icon.svg';
import { formatDate, textCapitalizeCase } from '../../utils/functions';
import { timeAgo } from '../../shared/utils';
import SummaryModalPage from '../../collectionBounties/components/candidateProfile/summaryModalPage';
import ButtonComponent from '../../collectionBounties/components/candidateProfile/buttonComponent';

const UserProfile = ({
  userProfile,
  status,
  setUserProfile,
  handleReview,
  setSendEmail,
  setSendRejectEmail,
  setShowProfile,
}) => {
  const [review, setReview] = useState(status);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const getData = async () => {
    setReview(status);
  };
  const canBeAccepted = (status) => {
    let result = false;
    if (typeof status !== 'undefined' && status != null) {
      result =
        textCapitalizeCase(status) === 'Applied' ||
        textCapitalizeCase(status) === 'Invited';
    }
    return result;
  };
  const canBeRejected = (status) => {
    let result = false;
    if (typeof status !== 'undefined' && status != null) {
      result =
        textCapitalizeCase(status) === 'Invited' ||
        textCapitalizeCase(status) === 'Applied';
    }
    return result;
  };
  useEffect(() => {
    getData();
    setShowAccept(canBeAccepted(status));
    setShowReject(canBeRejected(status));
  }, [userProfile]);

  const handleChange = (value) => {
    if (value === status) {
      return;
    }
    setReview(value);
    handleReview(userProfile, value);
  };

  const handleReject = (e) => {
    setSendRejectEmail(true);
    e.stopPropagation();
  };

  const handleApprove = (e) => {
    e.stopPropagation();
    setSendEmail(true);
  };
  return (
    <Container className="user-profile-container">
      <Row className="user-profile-row-1">
        <Col md={2}>
          <span className="users-profile-circle">
            <span>{userProfile.initials}</span>
          </span>
        </Col>
        <Col>
          <Row>
            <Col>
              <p className="h1">
                {userProfile.fullName === '' || userProfile.fullName === ' '
                  ? userProfile.email
                  : userProfile.fullName}{' '}
                {/* <span
                  className="user-profile-view-profile-link"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  View Profile
                </span> */}
              </p>
              {/* <SummaryModalPage
                show={false}
                handleClose={() => setShowProfile(false)}
                candidate={userProfile}
              /> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="users-profile-row1-col2-p2">
                Date Started: {formatDate(userProfile.createdAt)}
              </p>
              {!['Completed', 'Rejected'].includes(
                textCapitalizeCase(status)
              ) ? (
                <div className="user-profile-centered-div">
                  <div>
                    {textCapitalizeCase(status) === 'Submitted' && (
                      <>
                        <button
                          className="users-profile-launch-ide-reject mx-1"
                          onClick={(e) => handleReject(e)}
                        >
                          Reject
                        </button>
                        <button
                          className="users-profile-launch-ide mx-1"
                          onClick={(e) => handleApprove(e)}
                        >
                          Mark as Completed
                        </button>
                      </>
                    )}
                  </div>
                  {(textCapitalizeCase(status) === 'Rejected' ||
                    textCapitalizeCase(status) === 'Completed') && (
                    <div>
                      {textCapitalizeCase(status) === 'Rejected' && (
                        <button className="users-profile-launch-ide-reject mx-1">
                          Reject
                        </button>
                      )}
                      {textCapitalizeCase(status) === 'Completed' && (
                        <>
                          <button className="users-profile-launch-ide mx-1">
                            Mark as Completed
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <span>{textCapitalizeCase(status)}</span>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={2} className="user-profile-row1-col-relative">
          <div
            className="user-profile-close-icon"
            onClick={() => {
              setShowProfile(false);
              setUserProfile({});
            }}
          >
            <img
              src={SnapbrilliaCloseIcon}
              alt="close-icon"
              height={25}
              width={25}
            />
          </div>
        </Col>
      </Row>
      {/* <div className="row-btn">
        {showReject && (
          <div>
            <button
              className="users-profile-launch-ide-reject mx-1"
              onClick={handleReject}
            >
              Reject
            </button>
          </div>
        )}
        {showAccept && (
          <div>
            <button
              className="users-profile-launch-ide mx-1"
              onClick={handleAccept}
            >
              Accept
            </button>
          </div>
        )}
      </div> */}
      <Row className="user-profile-row3-col  cardbg">
        <Col xs={12} className="bg-light">
          <ButtonComponent candidate={{ candidateId: userProfile }} />
        </Col>
      </Row>

      <Row className="user-profile-row3-col  cardbg">
        <Col className="users-profile-row2-cols">
          <div style={{ maxHeight: '300px', height: '200px' }}>
            <h5 className="h5">Activity</h5>
            {/* activities section not yet implemented */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
