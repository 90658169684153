import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../shared/css/typography.css';
import '../../projectPage/css/candidateList.css';
import '../css/discussion.css';
import TopicHeader from './discussion/topicHeader';
import ShrinkedTopicListItem from './discussion/shrinkedTopicListItem';
import TopicListItem from './discussion/topicListItem';
import ExpandedTopicFullView from './discussion/expandedTopicFullView';
import * as topicApi from '../../api/topic';
import { toast } from 'react-toastify';

const DiscussionBounty = ({ bounty }) => {
  const [topics, setTopics] = useState([]);
  const [show, setShow] = useState(false);
  const [expandedTopic, setExpandedTopic] = useState(null);

  const sortByCreatedDate = (topics) =>
    topics.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const createTopic = async (topicField) => {
    topicField = { ...topicField, bountyId: bounty._id };
    const newTopic = await topicApi.createTopic(topicField);
    if (newTopic) {
      setTopics([...topics, newTopic]);
    }
  };

  const getAllTopics = async () => {
    let data = await topicApi.getTopicsByBounty(bounty._id);
    setTopics(sortByCreatedDate(data));
  };

  const handleExpandTopic = (e, topic) => {
    if (expandedTopic) {
      return setExpandedTopic(topic);
    }
    setExpandedTopic(topic);
    setShow(!show);
  };

  const handleAddNew = (data, type) => {};

  const handleUpdateTopic = async (topic, data, type = 'discussion') => {
    if (type === 'discussion') {
      const newTopics = await topics.map((item) => {
        if (item._id === topic._id) {
          item.discussionId.push(data._id);
        }
        return item;
      });
      setTopics(newTopics);
    }
    if (type === 'comment') {
      const newTopics = await topics.map((item) => {
        if (item._id === topic._id) {
          item.commentId.push(data._id);
        }
        return item;
      });
      setTopics(newTopics);
    }
    if (type === 'title') {
      try {
        const topicToUpdate = { ...topic, title: data };
        await topicApi.changeTopic(topic._id, topicToUpdate);
        setTopics(
          sortByCreatedDate([
            ...topics.filter(
              (listTopic) => listTopic._id !== topicToUpdate._id
            ),
            topicToUpdate,
          ])
        );
        toast('Title edited successfully!');
      } catch (error) {
        toast('Error updating topic title.');
      }
    }
  };

  useEffect(() => {
    getAllTopics();
  }, [bounty]);

  return (
    <Container fluid className="p-0">
      <TopicHeader createTopic={createTopic} bounty={bounty} />
      <Row className="discussion-bounty-bg">
        <Col className="candidates-filtered-list mh420 m-0">
          {topics.map((topic) => (
            <Row
              className="mx-0 mb-2 pointer"
              key={topic._id}
              onClick={(e) => handleExpandTopic(e, topic)}
            >
              {show ? (
                <ShrinkedTopicListItem
                  active={!!(expandedTopic && topic._id === expandedTopic._id)}
                  topic={topic}
                  handleExpandTopic={handleExpandTopic}
                />
              ) : (
                <TopicListItem
                  topic={topic}
                  handleExpandTopic={handleExpandTopic}
                />
              )}
            </Row>
          ))}
        </Col>
        {show && (
          <Col md={show && 8} xxl={show && 9}>
            <ExpandedTopicFullView
              topic={expandedTopic}
              setShow={setShow}
              setExpandedTopic={setExpandedTopic}
              handleAddNew={handleAddNew}
              handleUpdateTopic={handleUpdateTopic}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default DiscussionBounty;
