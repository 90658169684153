import { useState, useRef, useEffect } from 'react';
import lodash from 'lodash';
import axios from 'axios';
import { navigate } from '@reach/router';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { inviteEmail } from '../../emailTemplate/candidateInvite';
import { inviteInterviewerEmail } from '../../emailTemplate/inteviewerInvite';
import { Editor } from '@tinymce/tinymce-react';
import Close from '../../assets/icon/snapbrillia_close_icon.svg';
import '../css/emailTinyMCE.css';
import '../../shared/css/sharedStyles.css';
import '../../shared/css/buttonStyles.css';
import Schedulebutton from '../../shared/button/schedule';
import { toast } from 'react-toastify';
import SnapbrilliaDropdownIcon from '../../assets/icon/snapbrillia_dropdown_icon.svg';

const AssessmentInvite = ({
  type,
  interviewers,
  workSampleProject,
  auth,
  candidateList,
  companyLogo,
  title,
  isModal = false,
  handleSent,
}) => {
  const workSampleProjectId = workSampleProject._id;
  const rootRef = useRef(null);
  const editorRef = useRef(null);
  const userInfo = auth.user;
  const companyInfo = auth.company;
  const editorHeaderRef = useRef(null);

  const [disabledSend, setDisabledSend] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [inviteEmailContent, setInviteEmailContent] = useState('');
  const [selectCandidate] = useState(candidateList[0]);
  const [candidateEmailHeader, setCandidateEmailHeader] = useState(
    `${userInfo.fullName}, ${companyInfo.companyName} Invites You to Complete ${workSampleProject.jobTitle} Work Sample`
  );
  const [interviewerEmailHeader, setInterviewerEmailHeader] = useState(
    `${userInfo.fullName}, ${companyInfo.companyName} Invites You to Interview ${workSampleProject.jobTitle} Work Sample`
  );
  const [candidateEmailContent, setCandidateEmailContent] = useState('');
  const [interviewerEmailContent, setInterviewerEmailContent] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [interviewerEmail, setInterviewerEmail] = useState('');

  const setUpEmailList = async () => {
    const listEmail = [];
    if (type === 'candidate') {
      if (candidateList) {
        await candidateList.map((candidate) => {
          listEmail.push(candidate.email);
        });
        setEmailList(listEmail);
      }
    }
    if (type === 'interviewer') {
      if (interviewers) {
        if (!interviewerEmail) {
          await interviewers.map((interviewer, index) => {
            if (index === 0) {
              setInterviewerEmail(interviewer.email);
              createInterviewerEmailContent(interviewer);
            }
          });
        }
      }
    }
  };

  const handleSendButton = (date) => {
    if (date) {
      let [startDay, startHours, startMinutes] = format(date, 'dd:H:mm').split(
        ':'
      );
      let currentHours = new Date(Date.now()).getHours();
      let currentMinutes = new Date(Date.now()).getMinutes();
      let currentDay = new Date(Date.now()).getDate();

      if (startDay === currentDay) {
        if (
          parseInt(startHours) < currentHours ||
          (parseInt(startHours) === currentHours &&
            startMinutes <= currentMinutes)
        ) {
          toast('Invalid Time');
        }
      }
      toast('Email scheduled');
    }

    let html = '';
    let header =
      type === 'candidate' ? candidateEmailHeader : interviewerEmailHeader;
    if (editorRef.current) {
      html = editorRef.current.getContent();
    }
    if (editorHeaderRef.current) {
      const headerText = editorHeaderRef.current.getContent();
      const firstSlice = headerText.slice(3);
      const secondSlice = firstSlice.slice(0, firstSlice.length - 4);
      header = secondSlice;
    }
    if (userInfo.status !== 'active') {
      let message = '';
      if (candidateList.length > 1) {
        message =
          candidateList.length +
          ' Candidate Invitations Pending Due to Email Verification';
      } else {
        message = '1 Candidate Invitation Pending Due to Email Verification.';
      }
      const action = () => (
        <button onClick={handleResendConfirmEmail}>
          Send verification email
        </button>
      );
      toast(message);
      return;
    }
    setDisabledSend(true);
    axios
      .post(
        `/api/work-sample-project/send-bulk-invitation`,
        {
          interviewer: interviewerEmail,
          candidates: emailList.map((x) => ({ email: x })),
          workSampleProjectId: workSampleProjectId,
          html,
          header: header,
          type,
          startDate: date ? format(date, 'yyyy/MM/dd H:mm') : null,
        },
        { withCredentials: true }
      )
      .then((response) => {
        toast('Invite sent successfully.');
        if (isModal) {
          if (response?.data?.listAssessment) {
            response.data.listAssessment.map((assessment) => {
              handleSent(assessment);
            });
          }
        } else {
          navigate('/project-page/' + workSampleProjectId);
        }
        setDisabledSend(false);
      })
      .catch((error) => {
        const message = lodash.result(
          error,
          'response.data.error',
          'Server Error'
        );
        toast(message);
      });
  };

  const handleResendConfirmEmail = async () => {
    axios
      .post(
        `/api/resend-confirm-email`,
        {
          userId: userInfo._id,
        },
        { withCredentials: true }
      )
      .then(() => {
        toast('We have just send you an email to confirm your account.');
      })
      .catch((error) => {
        const message = lodash.result(
          error,
          'response.data.error',
          'Server Error'
        );
        toast(message);
      });
  };

  const replaceAll = (str, map) => {
    for (let key in map) {
      str = str.replaceAll(key, map[key]);
    }
    return str;
  };

  const createEmailHeader = () => {
    setCandidateEmailHeader(
      `${userInfo.fullName}, ${companyInfo.companyName} Invites You to Complete ${workSampleProject.jobTitle} Work Sample`
    );
    setInterviewerEmailHeader(
      `${userInfo.fullName}, ${companyInfo.companyName} Invites You to Interview ${workSampleProject.jobTitle} Work Sample`
    );
  };

  const createEmailContent = async () => {
    let interviewer = {
      fullName: 'fullName',
    };
    if (interviewers) {
      interviewer = interviewers[0];
    }
    const interviewer_new_content = await createInterviewerEmailContent(
      interviewer
    );

    const candidate_new_content = await createCandidateEmailContent();

    if (type === 'candidate') {
      setInviteEmailContent(candidate_new_content);
    } else {
      setInviteEmailContent(interviewer_new_content);
    }
  };

  const createInterviewerEmailContent = (interviewer) => {
    if (type === 'interviewer') {
      let new_content = inviteInterviewerEmail;
      let mapObj = {
        '{{fullName}}': interviewer.fullName,
        '{{jobTitle}}': workSampleProject.jobTitle,
        '{{companyName}}': companyInfo.companyName,
        '{{duration}}': workSampleProject.duration,
        '{{senderName}}': userInfo.fullName,
        '{{title}}': userInfo.title,
      };
      new_content = replaceAll(new_content, mapObj);
      if (companyLogo) {
        new_content = new_content.replace(
          '{{companyLogo}}',
          'data:image/jpeg;base64,' + companyLogo
        );
      } else {
        new_content = new_content.replace(
          '<img src="{{companyLogo}}" alt="company-logo" height="70">',
          ''
        );
      }
      setInterviewerEmailContent(new_content);
      return new_content;
    }
  };

  const createCandidateEmailContent = () => {
    if (type === 'candidate') {
      let new_content = inviteEmail;
      let mapObj = {
        '{{fullName}}': selectCandidate.fullName,
        '{{jobTitle}}': workSampleProject.jobTitle,
        '{{companyName}}': companyInfo.companyName,
        '{{duration}}': workSampleProject.duration,
        '{{senderName}}': userInfo.fullName,
        '{{title}}': userInfo.title,
      };

      new_content = replaceAll(new_content, mapObj);
      if (companyLogo) {
        new_content = new_content.replace(
          '{{companyLogo}}',
          'data:image/jpeg;base64,' + companyLogo
        );
      } else {
        new_content = new_content.replace(
          '<img src="{{companyLogo}}" alt="company-logo" height="70">',
          ''
        );
      }
      setCandidateEmailContent(new_content);
      return new_content;
    }
  };

  const handleSelectInterviewer = async (e) => {
    let selectedEmail = e.target.value;
    let selectedInterviewer = await interviewers.filter(
      (interviewer) => interviewer.email === selectedEmail
    );
    createInterviewerEmailContent(selectedInterviewer[0]);
    setInterviewerEmail(selectedEmail);
  };

  const setUpContent = () => {
    console.log(type);
    if (type === 'candidate') {
      setInviteEmailContent(candidateEmailContent);
    } else {
      setInviteEmailContent(interviewerEmailContent);
    }
  };

  useEffect(() => {
    createEmailHeader();
    createEmailContent();
    setUpEmailList();
  }, []);

  useEffect(() => {
    setUpEmailList();
    setUpContent();
  }, [type]);

  return lodash.isEmpty(workSampleProject) ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }}>
      <div className="email-container">
        <div className="email-root" ref={rootRef}>
          <div>
            <div className="email-to-container">
              <div>
                <div className="email-close-icon">
                  {!isModal && <img src={Close} alt="close-icon" />}
                </div>
                <h1 className="h1">{title}</h1>
                <br />
                <p className="email-label">To:</p>
                <div className="email-list">
                  {type === 'interviewer' ? (
                    <>
                      <select
                        className="p-0 px-2"
                        value={interviewerEmail}
                        onChange={handleSelectInterviewer}
                      >
                        {interviewers.map((item) => {
                          return (
                            <>
                              <option value={item.email}>{item.email}</option>
                            </>
                          );
                        })}
                      </select>
                      <img
                        src={SnapbrilliaDropdownIcon}
                        alt="SnapbrilliaDropdownIcon"
                        className="drop-down-icon"
                      />
                    </>
                  ) : (
                    <p className="px-2">
                      {emailList.length >= 10
                        ? emailList.slice(0, 10).join(', ') + '...'
                        : emailList.join(', ')}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <p className="email-label">Subject:</p>
              <Editor
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                onInit={(evt, editor) => (editorHeaderRef.current = editor)}
                initialValue={
                  type === 'candidate'
                    ? candidateEmailHeader
                    : interviewerEmailHeader
                }
                init={{
                  height: '60',
                  toolbar: false,
                  selector: 'input',
                  menubar: 'custom',
                  external_plugins: {
                    variable: '/tinymce/plugins/variable/plugin.js',
                    mention: '/tinymce/plugins/mention/plugin.js',
                  },
                  setup: (editor) => {
                    setTimeout(() => {
                      editor.getContent();
                    }, 1000);
                  },
                  content_css: '/tinymce/content.css',
                  plugins: ['variable mention'],
                  mentions: {
                    delimiter: '#',
                    source: [
                      { name: 'firstName' },
                      { name: 'lastName' },
                      { name: 'title' },
                      { name: 'jobTitle' },
                      { name: 'senderName' },
                      { name: 'senderLastName' },
                      { name: 'duration' },
                      { name: 'companyName' },
                      { name: 'companyLogo' },
                      { name: 'assessmentLink' },
                    ],
                  },
                }}
              />
            </div>
            <p className="email-label">Message:</p>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              onInit={(evt, editor) => {
                editorRef.current = editor;
                setShowButtons(true);
              }}
              initialValue={inviteEmailContent}
              init={{
                height: 500,
                menubar: 'custom',
                external_plugins: {
                  variable: '/tinymce/plugins/variable/plugin.js',
                },
                menu: {},
                setup: (editor) => {
                  editor.ui.registry.addButton('uploadimage', {
                    text: '',
                    icon: 'image',
                    tooltip: 'Update Load Image',
                    onAction: function () {
                      // create input element, call modal dialog w
                      var fileInput = document.createElement('input');
                      fileInput.setAttribute('type', 'file');
                      fileInput.setAttribute(
                        'accept',
                        'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
                      );
                      // if file is submitted run our key code
                      fileInput.addEventListener('change', () => {
                        if (
                          fileInput.files != null &&
                          fileInput.files[0] != null
                        ) {
                          // create instance of FileReader()
                          let reader = new FileReader();
                          // create event triggered after successful reading operation
                          reader.onload = (e) => {
                            // insert content in TinyMCE
                            editor.insertContent(
                              '<img src="' + e.target.result + '" height="70">'
                            );
                            fileInput.value = '';
                          };
                          reader.readAsDataURL(fileInput.files[0]);
                        }
                      });
                      fileInput.click();
                    },
                  });
                  setTimeout(() => {
                    editor.getContent();
                  }, 1000);
                },
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount variable',
                  'emoticons',
                ],
                toolbar:
                  'formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent |' +
                  'uploadimage | image |' +
                  'removeformat | code | emoticons',
                paste_data_images: true,
                content_css: '/tinymce/content.css',
              }}
            />
            {showButtons && (
              <div className="email-button-container">
                <div className="send-later-button-container">
                  <Schedulebutton />
                  <button
                    onClick={() => handleSendButton()}
                    disabled={disabledSend}
                    className="email-send-button"
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentInvite;
