import '../css/buttonStyles.css';
import React from 'react';

export default function AddBountyButton(props) {
  return (
    <div>
      <button
        style={{
          minWidth: '180px',
          // padding: '5px 20px',
        }}
        className="btn-primary"
      >
        <i
          className="fa-solid fa-circle-info"
          style={{
            color: 'grey',
            marginRight: '10px',
          }}
        ></i>
        {props?.title ? props.title : 'Add Gig'}
      </button>
    </div>
  );
}
